// Libraries
import React, { useContext } from 'react';

// Styles
import './body.scss';
import '../grid.scss';

// Context
import { AppContext } from '../../contexts/AppContext';
import { MapContext } from '../../contexts/MapContext';

// Hooks
import { useForceUpdate } from '../../helpers/CustomHooks';

// Components
import BodyContainer from './BodyContainer';
import InfoIconAndModal from '../../components/informationButton/InfoIconAndModal';
import SalinityBody from '../../components/salinity/SalinityBody';
import SalinityStations from '../../components/salinity/SalinityStations';
import DataQualityLegend from '../../components/generalComponents/dataQuality/DataQualityLegend';

// Helpers
import { replaceWaterSourceLiteral } from '../../helpers/Utils';

// Assets
import text from '../../abstract/IntroText.json';

// Constant
import WaterSourceConstants from '../../constants/WaterSourceConstants';
import DataRefresh from '../../components/dataSummary/DataRefresh';
const container = 'salinity-container';

export default function Salinity() {
  const { waterSource } = useContext(AppContext);
  const { mapExpanded } = useContext(MapContext);
  const isHunterRiver = waterSource.water_source_id === WaterSourceConstants.HUNTER_WSID;
  const [trigger, forceUpdate] = useForceUpdate();

  return (
    <div className={`${container}`}>
      <BodyContainer
        pageTitle={
          <div className={`${container}-pageTop col-lg-8`}>
            <span>
              {isHunterRiver ? '' : `REGISTER OF THE`}
              {`${replaceWaterSourceLiteral(waterSource.water_source_name)} RIVER SALINITY TRADING`}
              &nbsp;
              <InfoIconAndModal
                modalId="salinityTitleModal"
                modalContent={text.salinity.modalContent}
                lastWord={
                  <span className={'page-body-title'}>
                    {isHunterRiver ? '' : 'SCHEME REGISTER '}
                  </span>
                }
              />
            </span>
          </div>
        }
        pageSubIntro={text.salinity.subSections}
      >
        <div className={`${container} row`}>
          <div className={`${container}-body-left col-lg-9 col-sm-12 col-12`}>
            <DataRefresh forceUpdate={forceUpdate} />
            {!mapExpanded && (
              <div className={`${container}-body-left-legend-container`}>
                <DataQualityLegend />
              </div>
            )}
            <SalinityStations className="stations-list" refreshTrigger={trigger} />
          </div>
          <div className={`${container}-body-right col-lg-3 col-sm-12 col-12`}>
            <div className={`scheme-register `}>
              <SalinityBody />
            </div>
          </div>
        </div>
      </BodyContainer>
    </div>
  );
}
