// Library
import React from 'react';
import PropTypes from 'prop-types';

// Style
import './DataSummary.scss';

// Component
import ExpandBox from '../generalComponents/expandBox/ExpandBox';

// Constant
const container = 'data-summary';

const DataSummary = ({
  children,
  id,
  selected,
  header,
  onExpand,
  mobileOnly = false,
  clickable = true,
}) => {
  return (
    <ExpandBox
      clickableSurface={clickable}
      customHeaderContent={header}
      customHeaderContainerStyle={`${container} ${selected ? `${container}-selected` : ''} ${
        clickable ? '' : `${container}-non-clickable`
      }`}
      customArrowColor={`${container}-arrow ${selected ? `${container}-arrow-selected` : ''}`}
      uniqueIdentifier={id}
      expandState={selected}
      handleExpandClick={onExpand}
      mobileOnly={mobileOnly}
      disabled={!clickable}
    >
      <div className={`${container}-content`}>{children}</div>
    </ExpandBox>
  );
};

export default DataSummary;

DataSummary.propTypes = {
  children: PropTypes.any,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selected: PropTypes.bool,
  header: PropTypes.any,
  onExpand: PropTypes.func,
  mobileOnly: PropTypes.bool,
  clickable: PropTypes.bool,
};
