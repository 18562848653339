import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';

// Style
import './yearMultiSelector.scss';

// Component
import GeneralDropdown from '../dropDown/GeneralDropdown';

// Constant
const CURRENT_YEAR_SELECTOR = 'Current Year';

export default function HistoricalYearSelector(props) {
  // Props
  const { listOfYears, onBtnChange, yearSelected, renderCurrentYear } = props;

  const [filteredYears, setFilteredYears] = useState([]);
  const [yearDefaultSelected, setYearDefaultSelected] = useState(yearSelected);

  useEffect(() => {
    setYearDefaultSelected(yearSelected);
    let filtered = listOfYears.map(year => ({
      id: year.year,
      name: year.label,
    }));
    filtered = orderBy(filtered, [year => parseInt(year.id)], ['desc']);
    if (renderCurrentYear) {
      filtered.unshift({ id: 'actual', name: CURRENT_YEAR_SELECTOR });
    }
    setFilteredYears(filtered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfYears, yearSelected]);

  return (
    <>
      <GeneralDropdown
        menuItem={filteredYears}
        onItemClick={item => {
          setYearDefaultSelected(item.id);
          onBtnChange(item.id);
        }}
        selectedItem={filteredYears.find(yearItem => yearItem.id === yearDefaultSelected)}
      />
    </>
  );
}

HistoricalYearSelector.propTypes = {
  listOfYears: PropTypes.array,
  onBtnChange: PropTypes.func,
  yearSelected: PropTypes.string,
  renderCurrentYear: PropTypes.bool,
};
