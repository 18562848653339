// Library
import React from 'react';
import PropTypes from 'prop-types';
import { isNaN } from 'lodash';

// Style
import './stateOverviewTable.scss';

// Helper
import { formatNumberDecimals } from '../../../helpers/Utils';

// Constant
const container = 'state-overview-table';
const StateOverviewTable = ({ columns, rows }) => {
  return (
    <div className={container}>
      {columns.map((column, i) => {
        return (
          <div key={column.type} className={`${container}-column`}>
            <div
              className={`${container}-column-header  ${column.hide && `${container}-column-hide`}`}
            >
              {column.text}
            </div>
            {rows[i].map((row, ind) => (
              <div
                key={ind}
                className={`${container}-row ${i > 0 && `${container}-cell`} ${
                  i === 0 && `${container}-header`
                }`}
              >
                {isNaN(row) ? 0 : formatNumberDecimals(row)}
              </div>
            ))}
          </div>
        );
      })}
    </div>
  );
};

export default StateOverviewTable;

StateOverviewTable.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
};
