/* Library */
import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { Tooltip, LineChart, Line, XAxis, Legend, YAxis, ReferenceLine } from 'recharts';
import { isEmpty, toString, forOwn, cloneDeep } from 'lodash';
import PropTypes from 'prop-types';

/* Component */
import SelectionBtnsGroup from './childProperties/SelectionBtnsGroup';
import StorageChartDropdown from './childProperties/StorageChartDropdown';
import ChartContainer from '../chartProperties/ChartContainer';
import ChartResponsiveContainer from '../chartProperties/ChartResponsiveContainer';

/* Context */
import { AppContext } from '../../contexts/AppContext';
import { MobileContext } from '../../contexts/MobileContext.jsx';

/* Styles */
import './storageChart.scss';

/* Helpers */
import { getCurrentDate, formatDate } from '../../helpers/TimeUtils';
import { formatNumberDecimals } from '../../helpers/Utils';
import { Event } from '../../helpers/GAHelper';
import { getDamVolumeByWaterSourceId } from '../../helpers/ApiHelper';
import { getSurfaceWaterData, resolvePromises } from '../../helpers/WaterDataApiHelper';
/* Constant */
import constants from '../../constants/Constants';
import apiConstants from '../../constants/WaterDataAPIConstants';
import config from '../../configs/featureToggleConfig.json';
const LINE_CHART_PROPS = {
  volume: { name: 'Volume', objKey: 'volume', color: 'black' },
  extremely_dry: { name: 'Extremely dry', objKey: 'extremely_dry', color: '#91b0c1' },
  dry: { name: 'Dry', objKey: 'dry', color: '#bed12a' },
  average: { name: 'Average', objKey: 'average', color: '#0077a6' },
  wet: { name: 'Wet', objKey: 'wet', color: '#0054a6' },
};
const DATE_FORMAT = 'YYYY-MM';
const getDataFromWaterDataApi = config['water-data-api'].dams.active;

export default function MonthlyStorageForecast({ desc, title }) {
  const { waterSource } = useContext(AppContext);
  const { isMobile } = useContext(MobileContext);
  const [fullData, setFullData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [fullVolume, setFullVolume] = useState(waterSource.full_volume);
  const [waterSourceDams, setWaterSourceDams] = useState(waterSource.dams);
  const [activeDams, setActiveDams] = useState([]);
  const [showStorageForecast, setShowStorageForecast] = useState(false);
  const waterSourceId = waterSource.water_source_id;

  useEffect(() => {
    let unmounted = false;
    setChartData([]);
    setActiveDams([]);
    setWaterSourceDams([]);
    (async () => {
      // Fetch forecast data from WI
      const startDate = moment().subtract(1, 'years').format(DATE_FORMAT);
      let volumeData = await getDamVolumeByWaterSourceId(waterSourceId, startDate, null);
      if (volumeData?.all?.some(element => 'volume_forecast' in element)) {
        setShowStorageForecast(true);
      } else {
        setShowStorageForecast(false);
      }
      if (!getDataFromWaterDataApi) setFullData(volumeData);
      if (!isEmpty(volumeData)) {
        forOwn(volumeData, function (value) {
          const lastIndex = value
            .reverse()
            .findIndex(item => Object.prototype.hasOwnProperty.call(item, 'volume'));
          if (value[lastIndex]) {
            value[lastIndex].volume = null;
          }
          if (value[lastIndex + 1]) {
            value[lastIndex + 1].volume_forecast = {
              extremely_dry: value[lastIndex + 1].volume,
              dry: value[lastIndex + 1].volume,
              average: value[lastIndex + 1].volume,
              wet: value[lastIndex + 1].volume,
            };
          }

          for (const k in value.reverse()) {
            if (value[k].volume_forecast) {
              value[k] = {
                extremely_dry: value[k].volume_forecast.extremely_dry,
                dry: value[k].volume_forecast.dry,
                average: value[k].volume_forecast.average,
                wet: value[k].volume_forecast.wet,
                ...value[k],
              };
            }
          }
        });
      }
      let finalResult = volumeData;
      // = getDataFromWaterDataApi ? mergeData(volumeData) : volumeData;
      if (getDataFromWaterDataApi) {
        // Fetch historical data from Water
        const historicalData = await fetchDataFromWaterAPI();

        // Fromat Water data to WI format
        const formattedWaterData = formatWaterData(historicalData);

        // transplant Water data into WI data
        const blended = formattedWaterData;
        for (let [key, value] of Object.entries(volumeData)) {
          const filteredStationData = value.filter(valueItem => {
            return moment(valueItem.date).isAfter(getCurrentDate('YYYY-MM'));
          });

          const targetStationData = blended[key] || [];
          targetStationData.push(...filteredStationData);
          blended[key] = targetStationData;
        }
        finalResult = blended;
      }
      if (!unmounted) {
        setWaterSourceDams(waterSource.dams);
        setActiveDams(waterSource.dams.map(dam => dam.station_id));
        setFullVolume(waterSource.full_volume);
        setChartData(finalResult.all);
        setFullData(finalResult);
      }
    })();
    return function cleanUp() {
      unmounted = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterSource]);

  // Helpers
  const fetchDataFromWaterAPI = async () => {
    const startDateForWater = moment().subtract(1, 'years').format(apiConstants.API_DATE_FORMAT);
    const endDate = getCurrentDate(apiConstants.API_DATE_FORMAT);
    const requests = waterSource.dams.map(async damItem => {
      return await getSurfaceWaterData(
        damItem.station_id,
        'monthly',
        startDateForWater,
        endDate,
        'ActiveStorageVolume',
        'Combined',
      );
    });
    const res = await resolvePromises(requests);

    return res;
  };

  const formatWaterData = waterData => {
    const formatted = {};
    const all = [];
    waterData.forEach(waterDataItem => {
      if (!isEmpty(waterDataItem) && waterDataItem[0]?.siteId) {
        const stationId = waterDataItem[0]?.siteId;
        const stationName = waterSource.dams.find(
          damItem => damItem.station_id === stationId,
        )?.station_name;
        const formattedStationData = waterDataItem.map(stationData => {
          // format data
          const formattedDate = formatDate(
            stationData.timeStamp,
            apiConstants.API_DATE_FORMAT,
            'YYYY-MM',
          );
          const formattedRes = {
            date: formattedDate,
            volume: stationData.value,
          };

          // aggregate all dam data
          const targetDateDataInd = all.findIndex(allItem => {
            return allItem.date === formattedDate;
          });
          if (targetDateDataInd !== -1) {
            const targetDateData = all[targetDateDataInd];
            targetDateData.volume += formattedRes.volume;
          } else {
            all.push(cloneDeep(formattedRes));
          }

          return cloneDeep(formattedRes);
        });

        formatted[stationName] = formattedStationData;
      }
    });

    formatted.all = all;

    return formatted;
  };

  const formatTooltip = (value, name, item) => {
    if (item.payload.volume && name !== 'Volume') return [];
    return [formatNumberDecimals(Number(value / 1000)) + ' GL', name];
  };

  const onDamChange = dams => {
    const selectedDams = dams.split(',');
    setActiveDams(selectedDams);
    if (selectedDams.length > 1) {
      setFullVolume(waterSource.full_volume);
    } else {
      setFullVolume(waterSourceDams.find(dam => dams.includes(dam.station_id)).full_volume);
    }
    Event(
      waterSource.water_source_name,
      `/storage forecast - ` +
        waterSourceDams
          .filter(dam => selectedDams.includes(dam.station_id))
          .map(dam => dam.station_name)
          .join(','),
      constants.INTERACTION_TYPE.button,
    );
    const damName = dams.includes(',')
      ? 'all'
      : waterSourceDams.find(dam => toString(dam.station_id) === toString(selectedDams))
          .station_name;
    setChartData(fullData[damName]);
  };

  const renderReferenceLineLabel = v => {
    const { viewBox } = v;
    const x = isMobile ? viewBox.width / 4 : viewBox.width / 2;
    const y = viewBox.y + 20;
    return (
      <text className="svg-reference-line-label" x={x} y={y}>
        {constants.TOTAL_STORAGE_MENINDEE_LAKES_FLAG
          ? (activeDams.length === 1 &&
              Number(activeDams[0]) === constants.MENINDEE_LAKES_TOTAL_ID) ||
            waterSourceId === constants.LOWER_DARLING_REGULATED_RIVER_WATER_SOURCE
            ? 'Total capacity'
            : activeDams.length > 1 &&
              [constants.MURRAY_REGULATED_RIVER_WATER_SOURCE].includes(waterSourceId)
            ? 'Storage capacity'
            : 'Accessible capacity'
          : 'Accessible capacity'}
      </text>
    );
  };

  let damsSelectionBtn = !isEmpty(waterSourceDams) && (
    <StorageChartDropdown dams={waterSourceDams} onDamChange={onDamChange} />
  );
  if (!showStorageForecast) return <></>;
  return (
    <ChartContainer pageTitle={title} graphTitle={title} graphIntro={desc}>
      <SelectionBtnsGroup left={damsSelectionBtn} />
      <ChartResponsiveContainer
        customHeight={{ desktop: 450, mobile: 350 }}
        isEmptyChart={isEmpty(chartData)}
      >
        <LineChart
          width={800}
          data={chartData}
          margin={{ top: isMobile ? 20 : 10, left: 20, bottom: 50 }}
        >
          <XAxis dataKey="date" tick={{ fill: 'black' }} angle={-60} dy={25} dx={-5} />
          <YAxis
            domain={[0, fullVolume]}
            interval="preserveStartEnd"
            width={isMobile ? 15 : 60}
            angle={isMobile ? -60 : 0}
            label={
              isMobile
                ? ''
                : {
                    value: 'Observed and forecast storage volume (GL)',
                    angle: -90,
                    dx: -35,
                  }
            }
            tickFormatter={v => formatNumberDecimals(Math.round(v / 1000))}
            tick={{ fill: 'black' }}
          />
          {Object.entries(LINE_CHART_PROPS).map((item, index) => (
            <Line
              key={index}
              name={item[1].name}
              strokeWidth={2}
              type="monotone"
              dataKey={item[1].objKey}
              stroke={item[1].color}
              dot={false}
            />
          ))}
          <Tooltip
            labelFormatter={date => moment(date, DATE_FORMAT).format('MMMM YYYY')}
            formatter={(value, name, props) => formatTooltip(value, name, props)}
          />
          {activeDams && (
            <ReferenceLine
              y={fullVolume}
              label={v => renderReferenceLineLabel(v)}
              stroke="red"
              strokeDasharray="3 3"
            />
          )}
          <Legend
            wrapperStyle={{ bottom: isMobile ? 0 : -10 }}
            align="center"
            iconSize={isMobile ? 15 : 30}
          />
        </LineChart>
      </ChartResponsiveContainer>
    </ChartContainer>
  );
}

MonthlyStorageForecast.propTypes = {
  desc: PropTypes.string,
  title: PropTypes.string,
};
