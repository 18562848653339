// Library
import React from 'react';
import PropTypes from 'prop-types';
// Styles
import './button.scss';

export const Button = ({
  customStyles,
  children,
  ariaLabel = 'button',
  onClick,
  isDisabled = false,
  targetModalId = '',
}) => {
  return (
    <button
      aria-label={ariaLabel}
      type="button"
      className={`general-button ${customStyles}`}
      onClick={e => {
        onClick(e);
      }}
      disabled={isDisabled}
      data-target={`#${targetModalId}`}
      data-toggle={targetModalId && 'modal'}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  options: PropTypes.array,
  onClick: PropTypes.func,
  activeDefault: PropTypes.string,
};
