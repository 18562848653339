// Libraries
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// Context
import { UserContext } from '../../contexts/UserContext';
import { MobileContext } from '../../contexts/MobileContext';

// Styles
import './alertLink.scss';

// Components
import AlertSVG from '../../image/icons/AlertSVG';
import ChartIcon from '../chartProperties/ChartIcon';
import Modal from '../modal/Modal';
import LoginModalBody from './LoginModalBody';

export default function AlertLink({
  showManageAlertResponse,
  setShowManageAlertResponse,
  alertModal,
  modalType,
  isMini,
}) {
  const { isLoggedIn } = useContext(UserContext);
  const { isMobile } = useContext(MobileContext);

  return (
    <div>
      {isLoggedIn ? (
        <Modal
          id={modalType}
          title={`${!showManageAlertResponse ? 'Set alert' : ''}`}
          body={alertModal}
        />
      ) : (
        <Modal id={`login-modal`} body={<LoginModalBody />} />
      )}
      <ChartIcon>
        <a
          href="#!"
          id={'alertLink'}
          data-toggle="modal"
          data-target={isLoggedIn ? `#${modalType}` : `#login-modal`}
          onClick={() => {
            showManageAlertResponse === true && setShowManageAlertResponse(false);
          }}
        >
          <AlertSVG />
          {(!isMobile || isMini) && `Set alert`}
        </a>
      </ChartIcon>
    </div>
  );
}

AlertLink.propTypes = {
  alertModal: PropTypes.any,
  customHeaderContainerStyle: PropTypes.string,
  showTitle: PropTypes.bool,
};
