/* Library */
import React, { useState, useEffect, useContext } from 'react';

/* Styles */
import './riverDataSummaryTable.scss';

/* Component */
import Modal from '../modal/Modal';
import Loader from '../../components/loader/Loader';
import RiverDataModalData from '../modal/modalText/RiverDataModalData';
import WeirDataModalText from '../modal/modalText/WeirDataModalText';
import DictionaryModalContent from '../modal/DictionaryModalContent';
import DataSummaryTable from '../dataSummary/DataSummaryTable';
import InfoButton from '../../components/informationButton/InformationButton';

/* Helpers */
import { getSummarySurfaceWaterData } from '../../helpers/WaterDataApiHelper';
import { mergeByDate } from '../../helpers/Utils';
import { dataMapFromWaterDataToStation } from '../../helpers/WaterDataUtils';
import { sortDataArrayBasedOnStructure, fillInDummyData } from './Helper';

/* Context */
import { MobileContext } from '../../contexts/MobileContext';

/* Constants */
import mapFilterConstant from '../../constants/MapFilterConstants';
import hydroDataConstant from '../../constants/HydrometricsConstants';
const { GAUGE, WEIR, BORE } = mapFilterConstant.MAP_POPUP_ITEMS;
const tableHeading = { [GAUGE.name]: 'river', [BORE.name]: 'groundwater' };

const RiverDataSummaryTable = ({ index, stationType, stationData, dataProps }) => {
  const heading = `9am ${
    stationType !== WEIR.name ? tableHeading[stationType] : stationType
  } information`;
  const siteId = stationData.station_id;
  let variables =
    stationType === WEIR.name
      ? 'StorageWaterLevel,TotalStorageVolume'
      : stationData.water_data_variables
      ? stationData.water_data_variables.join(',')
      : '';
  variables = siteId === '206036' ? variables.concat(',ActiveStoragePercentage') : variables;
  const { isMedium } = useContext(MobileContext);
  const [tableData, setTableData] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [riverDataProps, setRiverDataProps] = useState(
    stationData.water_data_variables &&
      stationData.water_data_variables.includes('StorageWaterLevel')
      ? dataProps.filter(item => item.waterDataKey !== 'StreamWaterLevel')
      : dataProps.filter(item => item.waterDataKey !== 'StorageWaterLevel'),
  );

  useEffect(() => {
    if (siteId !== -1) {
      setShowSpinner(true);
      (async () => {
        try {
          let { results } = await getSummarySurfaceWaterData(
            stationData.id,
            'daily',
            variables,
            '7 days',
          );
          results = dataMapFromWaterDataToStation(mergeByDate(results, false, false));
          formatData(results);
        } catch (e) {
          setTableData([]);
        } finally {
          setShowSpinner(false);
        }
      })();
      window.location.hash = `#${siteId}`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProps, setShowSpinner, stationData, stationType]);

  useEffect(() => {
    if (stationData.id) {
      if (
        stationData?.water_data_variables &&
        stationData?.water_data_variables?.includes('StorageWaterLevel')
      ) {
        setRiverDataProps(dataProps.filter(item => item.waterDataKey !== 'StreamWaterLevel'));
      } else {
        setRiverDataProps(dataProps.filter(item => item.waterDataKey !== 'StorageWaterLevel'));
      }
    }
  }, [dataProps, stationData]);

  const formatData = data => {
    const newTableData = sortDataArrayBasedOnStructure(
      fillInDummyData(7, 0, data),
      stationType,
      riverDataProps,
    );
    let slicedSortedData = newTableData.map(({ content: dataContent, dataKey, ...rest }) => ({
      ...rest,
      dataKey: dataKey,
      content: dataContent.slice(0, 7),
      decimals: hydroDataConstant.DATA_LABEL_FORMAT[dataKey]?.decimals,
    }));
    if (stationType !== BORE.name) {
      slicedSortedData = slicedSortedData.filter(
        item =>
          stationData.hydrometric_types
            .map(type => (type !== 'pH' ? type.toLowerCase() : type))
            .includes(item.dataKey) || item.dataKey === 'rainfall',
      );
    }
    setTableData(slicedSortedData);
  };

  return (
    <div className="river-data-summary-table">
      {showSpinner && (
        <Loader width={isMedium ? '5px' : '10px'} height={isMedium ? '20px' : '40px'} />
      )}
      <Modal
        key={index}
        id={`riverDataModalCenter${index}${stationType}`}
        title={`Understanding ${heading.toLowerCase()}`}
        body={
          <DictionaryModalContent
            data={stationType === WEIR.name ? WeirDataModalText : RiverDataModalData}
          />
        }
      />
      <DataSummaryTable
        page="gauges"
        title={heading}
        tableHeader={
          <>
            {heading}
            <InfoButton
              targetDataId={`#riverDataModalCenter${index}${stationType}`}
              iconColor="river-data-info-icon"
            />
          </>
        }
        tableContent={tableData}
        dateFormat="DD MMM"
        stationData={stationData}
        allowAlert={stationType === GAUGE.name}
      />
    </div>
  );
};

export default RiverDataSummaryTable;
