// Library
import React from 'react';

// Style
import './homeMini.scss';
import '../../layout/grid.scss';

// Component
import WaterSite from '../../components/waterSite/WaterSite';
import GlobalSearchBarMini from '../../components/globalSearch/GlobalSearchBarMini';
import Favourites from '../../components/favourites/Favourites';

// Helper
import { createMarkup } from '../../helpers/Utils';

// Constant
import text from '../../abstract/IntroTextMini.json';
const component = 'home-mini';

export default function HomeMini() {
  return (
    <div className={component}>
      <div
        dangerouslySetInnerHTML={createMarkup(text.home.title)}
        className={`${component}-title`}
      />
      <div
        dangerouslySetInnerHTML={createMarkup(text.home.intro)}
        className={`${component}-intro`}
      />
      <div className={`${component}-title`}>Find a water site</div>
      <div className={`${component}-water-site`}>
        <WaterSite />
      </div>
      <div className={`${component}-search`}>
        <GlobalSearchBarMini placeholder="Type a site name or number" />
      </div>
      <Favourites editable={false} page={false} />
    </div>
  );
}
