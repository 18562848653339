// Library
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { startCase } from 'lodash';

// Style
import './damInfo.scss';

// Assest
import mapPinIcon from '../../image/icons/mapPinIcon.svg';
import externalLinkImg from '../../image/icons/external-link.png';
import damsText from './damsDesc.json';

// Component
import DailyUpdates from '../updates/storage/DailyUpdates';
import HourlyUpdates from '../updates/storage/HourlyUpdates';
import Modal from '../modal/Modal';

// Context
import { MapContext } from '../../contexts/MapContext';
import { MobileContext } from '../../contexts/MobileContext';
import { AppContext } from '../../contexts/AppContext';

// Helpers
import { formatDailyUpdate, dateFormatter } from './parserHelper.jsx';

// Constants
import mapFilterConstant from '../../constants/MapFilterConstants';
import featureConstant from '../../configs/featureToggleConfig.json';
import constants from '../../constants/Constants.jsx';
const isHourlyUpdateShown = featureConstant['instantaneous_data_dam_storage'].active;
const { DAM } = mapFilterConstant.MAP_FILTERS_OPTIONS;
const damLinks = {
  410102: 'https://www.waternsw.com.au/nsw-dams/regional-nsw-dams/blowering-dam#Facts-and-History',
  410131: 'https://www.waternsw.com.au/nsw-dams/regional-nsw-dams/burrinjuck-dam#Facts-and-History',
  412106: 'https://www.waternsw.com.au/nsw-dams/regional-nsw-dams/carcoar-dam#Facts-and-History',
  416030: 'https://www.waternsw.com.au/nsw-dams/regional-nsw-dams/pindari-dam#Facts-and-History',
  419080: 'https://www.waternsw.com.au/nsw-dams/regional-nsw-dams/split-rock-dam#Facts-and-History',
  418035: 'https://www.waternsw.com.au/nsw-dams/regional-nsw-dams/copeton-dam#Facts-and-History',
  419041: 'https://www.waternsw.com.au/nsw-dams/regional-nsw-dams/keepit-dam#Facts-and-History',
  419069: 'https://www.waternsw.com.au/nsw-dams/regional-nsw-dams/chaffey-dam#Facts-and-History',
  421078: 'https://www.waternsw.com.au/nsw-dams/regional-nsw-dams/burrendong-dam#Facts-and-History',
  421148: 'https://www.waternsw.com.au/nsw-dams/regional-nsw-dams/windamere-dam#Facts-and-History',
  210117:
    'https://www.waternsw.com.au/nsw-dams/regional-nsw-dams/glennies-creek-dam#Facts-and-History',
  401027: 'https://www.waternsw.com.au/nsw-dams/regional-nsw-dams/hume-dam#Facts-and-History',
  425022: 'https://www.waternsw.com.au/nsw-dams/regional-nsw-dams/menindee-lakes#Facts-and-History',
  203042: 'https://www.waternsw.com.au/nsw-dams/regional-nsw-dams/toonumbar-dam#Facts-and-History',
  219033: '', // cochrane
  '416315A': '', // glenlyon
  219027: 'https://www.waternsw.com.au/nsw-dams/regional-nsw-dams/brogo-dam#Facts-and-History',
  206036: '', //malpas
  210097: 'https://www.waternsw.com.au/nsw-dams/regional-nsw-dams/glenbawn-dam#Facts-and-History',
  '401224A': '', // dartmouth
  210102: 'https://www.waternsw.com.au/nsw-dams/regional-nsw-dams/lostock-dam#Facts-and-History',
  412107: '', // cargelligo
  412108: '', // brewster
  425023: '', // cawndilla
  425046: '', // wetherell
  425047: '', // tandure
  42510036: '', // pamamaroo
  42510037: '', // copi_hollow
  412010: 'https://www.waternsw.com.au/nsw-dams/regional-nsw-dams/lake-wyangala#Facts-and-History',
  425049: 'https://www.waternsw.com.au/nsw-dams/regional-nsw-dams/menindee-lakes#Facts-and-History',
  212220: 'https://www.waternsw.com.au/nsw-dams/greater-sydney-dams/cordeaux-dam#Facts-and-History',
  213210: 'https://www.waternsw.com.au/nsw-dams/greater-sydney-dams/woronora-dam#Facts-and-History',
  215212: 'https://www.waternsw.com.au/nsw-dams/greater-sydney-dams/tallowa-dam#Facts-and-History',
  212287: '', // blue_mountains
  212243:
    'https://www.waternsw.com.au/nsw-dams/greater-sydney-dams/warragamba-dam#Facts-and-History',
  213240: '', // prospect
  212205: 'https://www.waternsw.com.au/nsw-dams/greater-sydney-dams/nepean-dam#Facts-and-History',
  212211: 'https://www.waternsw.com.au/nsw-dams/greater-sydney-dams/avon-dam#Facts-and-History',
  212232: 'https://www.waternsw.com.au/nsw-dams/greater-sydney-dams/cataract-dam#Facts-and-History',
  212212:
    'https://www.waternsw.com.au/nsw-dams/greater-sydney-dams/wingecarribee-reservoir#Facts-and-History',
  215235:
    'https://www.waternsw.com.au/nsw-dams/greater-sydney-dams/fitzroy-falls-reservoir#Facts-and-History',
  4261093: '', // lake_victoria
  421189: 'https://www.waternsw.com.au/nsw-dams/regional-nsw-dams/oberon-dam#Facts-and-History',
};
const component = 'dam-info';

export default function DamInfo({
  damResource,
  station,
  collapsedHourlyUpdateIds,
  updateHourlyUpdateIds,
  inputDateFormat,
  outputDateFormat,
}) {
  const { toggleMarker } = useContext(MapContext);
  const { isMobile } = useContext(MobileContext);
  const { waterSource } = useContext(AppContext);
  const modalId = `dam-info-desc-modal-${station.station_id}`;
  const isGreaterSydney = waterSource.water_source_id === constants.GREATER_SYDNEY_WSID;

  // Component
  const dailyUpdate = () => {
    const timestamp = dateFormatter(damResource, inputDateFormat, outputDateFormat);
    const updateItems = formatDailyUpdate(damResource, station, isGreaterSydney);

    const hourlyUpdateItems = [
      ...updateItems.filter(item =>
        ['Total volume', 'volume', 'inflow', 'release'].includes(item.label),
      ),
      ...(timestamp !== 'Updated: -' && !isMobile
        ? [
            {
              label: 'updated time',
              value: moment(timestamp, 'DD.MM.YYYY hh:mma').format('hh:mm a'),
            },
          ]
        : []),
    ];
    return (
      <div className="dam-info-daily-update">
        <DailyUpdates data={updateItems} timestamp={timestamp} />
        {isHourlyUpdateShown && (
          <HourlyUpdates
            data={hourlyUpdateItems}
            station={station}
            collapsedIds={collapsedHourlyUpdateIds}
            updateHourlyUpdateIds={updateHourlyUpdateIds}
          />
        )}
      </div>
    );
  };

  const mapIcon = () => {
    return (
      <div className={`${component}-title-map-icon-bg`}>
        <img
          className={`${component}-title-map-icon`}
          src={mapPinIcon}
          alt={`Map icon for ${station.station_name}`}
          onClick={() => {
            toggleMarker(station, DAM.type);
            window.scrollTo(0, 0);
          }}
        />
      </div>
    );
  };

  const externalLink = () => {
    return (
      <a
        target="_blank"
        rel="noopener noreferrer"
        className={`${component}-links-desc`}
        href={damLinks[station.station_id]}
      >
        Read more
        <img
          className={`${component}-links-desc-icon`}
          src={externalLinkImg}
          alt="Link icon linking to the description of dams"
        />
      </a>
    );
  };

  const damDesc = () => {
    return (
      <a className={`${component}-links-desc`} data-toggle={'modal'} data-target={`#${modalId}`}>
        Read more
        <img
          className={`${component}-links-desc-icon`}
          src={externalLinkImg}
          alt="Link icon linking to the description of dams"
        />
      </a>
    );
  };

  const desktopLayout = () => {
    console.log(damResource);
    return (
      <>
        <Modal
          id={modalId}
          title={startCase(station.station_name)}
          body={<p>{damsText[station.station_id]}</p>}
        />
        <div className={`${component}`} id={station.station_id}>
          <div className={`${component}-title`}>
            <div className={`${component}-title-text`}>{startCase(station.station_name)}</div>
            {mapIcon()}
          </div>
          <div className={`${component}-links`}>
            <a className={`${component}-links-storage`} href="#AMSRecent">
              View historical dam storage data
            </a>
            {damLinks[station.station_id] ? externalLink() : damDesc()}
          </div>
          {damResource && dailyUpdate()}
        </div>
      </>
    );
  };

  const mobileLayout = () => {
    return (
      <div className={component} id={station.station_id}>
        <div className={`${component}-mobile-title`}>
          {station.station_name}
          {mapIcon()}
        </div>
        {damResource && dailyUpdate()}
      </div>
    );
  };

  return station.station_id !== '4261022' && (isMobile ? mobileLayout() : desktopLayout());
}

DamInfo.propTypes = {
  damResource: PropTypes.shape({
    station_id: PropTypes.string,
    station_name: PropTypes.string,
    volume: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      date: PropTypes.string,
      isLatest: PropTypes.bool,
    }),
    volume_perc: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      date: PropTypes.string,
      isLatest: PropTypes.bool,
    }),
    inflow: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      date: PropTypes.string,
      isLatest: PropTypes.bool,
    }),
    release: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      date: PropTypes.string,
      isLatest: PropTypes.bool,
    }),
    date: PropTypes.string,
  }),
  station: PropTypes.shape({
    lat: PropTypes.number,
    long: PropTypes.number,
    station_id: PropTypes.string,
    station_name: PropTypes.string,
  }),
};
