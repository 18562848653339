/* Library */
import React, { useState, useContext, useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';
import { Link, useLocation } from 'react-router-dom';

// Style
import './tabs.scss';

// Helpers
import { buildUrl, getPathname, waterSourceReplacementInURL } from '../../helpers/UrlGenerator.jsx';
import { getTabItemsByWaterSourceId } from '../../helpers/Utils';
import { Event } from '../../helpers/GAHelper';

// Constants
import constants from '../../constants/Constants';

// Context
import { AppContext } from '../../contexts/AppContext';
import { TabsContext } from '../../contexts/TabsContext ';
import { MobileContext } from '../../contexts/MobileContext';
import { MapContext } from '../../contexts/MapContext';

function Tabs() {
  const createHistory = require('history').createBrowserHistory;
  const history = createHistory();
  const location = useLocation();
  const { hash, pathname } = history.location;
  const tabContainerRef = useRef();
  const tabsRef = useRef([]);
  const { waterSource, setWaterSource } = useContext(AppContext);
  const { setTabsRef, setTabContainerRef } = useContext(TabsContext);
  const { orientation, isLarge, isMobile } = useContext(MobileContext);
  const { expandMap, mapExpanded } = useContext(MapContext);
  const [tabClicked, setTabClicked] = useState();
  const [tabItems, setTabItems] = useState([]);
  const AVERAGE_TAB_WIDTH = 140;
  const FIRST_TAB_MARGIN_LEFT_PIXELS = 40;
  let timer = null;

  // Life Cycle
  useEffect(() => {
    initializeTab();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterSource]);

  const initializeTab = async () => {
    const curPathname = pathname.split('/')[1];
    let tabToSelect = pathname.split('/')[2];
    const tabClick = pathname.split('/')[2];
    var tabSelect = [];
    if (typeof curPathname !== 'undefined' && !isEmpty(curPathname)) {
      const URLWaterSource = waterSourceReplacementInURL(curPathname, waterSource, hash);
      if (waterSource.water_source_id !== URLWaterSource.water_source_id) {
        setWaterSource(URLWaterSource);
      }
    }
    if (!isEmpty(waterSource)) {
      const tabItemsByWaterType = await getTabItemsByWaterSourceId(waterSource);
      setTabItems(tabItemsByWaterType);
      if (tabClick !== undefined && tabClick != null) {
        tabSelect = tabItemsByWaterType.filter(function (option) {
          return option.id === tabClick.toUpperCase();
        });
      }
      if (isEmpty(tabSelect)) {
        tabToSelect = 'UPDATES';
      }
    } else {
      setTabItems([]);
    }
    if (tabSelect.length > 0) {
      setTabClicked(tabSelect[0].id);
      var tabCont = document.getElementById(tabSelect[0].id);
      if (tabCont != null && !mapExpanded) {
        tabCont.click();
      }
    } else {
      let tabToClick = typeof tabToSelect !== 'undefined' ? tabToSelect.toUpperCase() : 'UPDATES';
      setTabClicked(tabToClick);

      const nextTabToClick = tabsRef.current.find(element => element && element.id === tabToClick);
      nextTabToClick && nextTabToClick.click();
    }

    setTabsRef(tabsRef);
    setTabContainerRef(tabContainerRef);
  };

  const setPositionCenter = tab => {
    const offsetLeft = tab.getBoundingClientRect().left;
    let width = tabContainerRef.current.clientWidth;
    let diff = offsetLeft - width / 3;
    tabContainerRef.current.scrollLeft = tabContainerRef.current.scrollLeft + diff;
  };

  useEffect(() => {
    if (typeof tabClicked !== 'undefined') {
      if (!isEmpty(waterSource) && tabsRef.current.every(el => el)) {
        const domTabToSelect = tabsRef.current.find(element => element.id === tabClicked);
        if (domTabToSelect) {
          setPositionCenter(domTabToSelect);
        } else {
          setTabClicked(tabClicked);
        }
      }
      localStorage.setItem('currTabClicked', tabClicked);
    }
  }, [waterSource, tabClicked, isMobile]);

  const onScroll = () => {
    if (timer !== null) {
      clearTimeout(timer);
    }
    // In case the page has already been rendered, we get the value of tabClicked which contains the tab to select in the bar.
    timer = setTimeout(function () {
      let scrollY = tabContainerRef.current.scrollLeft;

      if (scrollY === 0) {
        let tabToClick = tabsRef.current.find(element => element.id === tabClicked);
        tabToClick && tabToClick.click();
        tabToClick && localStorage.setItem('currTabClicked', tabClicked);
      } else {
        let index = Math.round((scrollY - FIRST_TAB_MARGIN_LEFT_PIXELS) / AVERAGE_TAB_WIDTH);
        tabsRef.current[index] && tabsRef.current[index].click();
        tabsRef.current[index] && localStorage.setItem('currTabClicked', tabsRef.current[index].id);
      }
    }, 150);
  };

  return (
    <div
      onClick={() => {
        if (!orientation.includes(constants.LANDSCAPE) || !isLarge) {
          expandMap(false);
        }
      }}
      className={`tabs`}
    >
      <div
        onScroll={() => {
          if (isMobile) {
            onScroll();
          }
        }}
        ref={tabContainerRef}
        className={`tab-container${!isEmpty(waterSource) ? '-visible' : ''} `}
      >
        {tabItems &&
          !isEmpty(waterSource) &&
          tabItems.map((tab, index) => (
            <React.Fragment key={index}>
              <Link
                id={tab.id}
                ref={element => (tabsRef.current[index] = element)}
                onClick={evt => {
                  setTabClicked(evt.currentTarget.id);
                  Event(
                    waterSource.water_source_name,
                    `Tab nav to /${evt.currentTarget.id.toLowerCase()}`,
                    constants.INTERACTION_TYPE.tab,
                  );
                }}
                to={buildUrl(
                  getPathname(waterSource.water_source_id + ' ' + waterSource.water_source_name),
                  tab.link,
                )}
                className={`tab-rectangle${
                  location.pathname.includes(`/${tab.id.toLowerCase()}`)
                    ? ' tab-rectangle-selected'
                    : ''
                }`}
              >
                <div className="tab-text-base">{tab.text}</div>
              </Link>
              <span className="tab-divider" />
            </React.Fragment>
          ))}
      </div>
    </div>
  );
}

export default Tabs;
