/* Library */
import React, { useState, useContext, useEffect } from 'react';
import { isEmpty } from 'lodash';

/* Context */
import { AppContext } from '../../contexts/AppContext';
import { MobileContext } from '../../contexts/MobileContext';
import { MiniAppContext } from '../../contexts/MiniAppContext';

/* Styles */
import './allUpdates.scss';

/* Components */
import PageTop from '../../components/pageTop/PageTop';
import UpdateItem from '../../components/updates/UpdateItem';
import UpdateTabRestrictions from '../../components/restrictions/UpdateTabRestrictions';
import Events from '../../components/events/Events';
import ExpandBox from '../generalComponents/expandBox/ExpandBox';
import ExternalLinkModal from '../../components/modal/ExternalLinkModal';
import Status from '../../components/status/Status';
import GeneralDropdown from '../../components/dropDown/GeneralDropdown';
import AlertModalBody from '../alert/AlertModalBody';
import UpdateAlertLink from './UpdateAlertLink';

/* Helpers */
import {
  sortArrayOfObjectByTimestamp,
  replaceWaterSourceLiteral,
  financialYearsMenuItems,
} from '../../helpers/Utils';
import { startDateOfFinancialYear, endDateOfFinancialYear } from '../../helpers/TimeUtils';
import { getStatusFromWaterSource } from '../../helpers/ApiHelper';
import {
  getAlgaeUpdatesFromWaterSource,
  getRestrictionsFromWaterSource,
  getConstraintsFromWaterSource,
  getWaterSourceDroughtFloodRisk,
  getPriorUpdates,
} from '../../helpers/UpdatesApiHelper.jsx';

/* Constant */
import config from '../../abstract/IntroText.json';
import alertResponseText from '../alert/AlertResponseText.json';
import featureConfig from '../../configs/featureToggleConfig.json';

const container = 'update-general';
const DROPDOWN_ITEMS = [
  { id: '1', name_id: 'restrictions', name: 'Temporary Restrictions' },
  { id: '2', name_id: 'availability', name: 'Water Availability' },
  { id: '3', name_id: 'operations', name: 'Operations' },
  { id: '4', name_id: 'ex_events', name: 'Extreme Events' },
  { id: '5', name_id: 'environmental', name: 'Environmental' },
  { id: '6', name_id: 'sup_events', name: 'Supplementary Events' },
  { id: '8', name_id: 'water_quality', name: 'Water Quality' },
  { id: '7', name_id: 'all', name: 'All' },
];

export default function AllUpdates() {
  const NUMBER_OF_HISTORY_YEARS = 4;
  const YEARS_MENU_ITEMS = financialYearsMenuItems(NUMBER_OF_HISTORY_YEARS);
  const { isMobile } = useContext(MobileContext);
  const { waterSource } = useContext(AppContext);
  const { station } = useContext(MiniAppContext);
  const [externalSiteLink, setExternalSiteLink] = useState('');
  const [updates, setUpdates] = useState([]);
  const [restrictions, setRestrictions] = useState([]);
  const [status, setStatus] = useState([]);
  const [maintenanceEvents, setMaintenanceEvents] = useState([]);
  const [recreationalEvents, setRecreationalEvents] = useState([]);
  const [rosccoEvents, setRosccoEvents] = useState([]);
  const [droughtFloodRisk, setDroughtFloodRisk] = useState({});
  const [selectedItem, setSelectedItem] = useState('All');
  const [selectedFinancialYear, setSelectedFinancialYear] = useState({});
  const [activeUpdates, setActiveUpdates] = useState([]);
  const [dropDownMenuItems, setMenuItems] = useState([]);
  const [showManageAlertResponse, setShowManageAlertResponse] = useState(true);
  const [responseType, setResponseType] = useState();
  const [algaeUpdate, setAlgaeUpdate] = useState([]);

  // Life Cycle
  useEffect(() => {
    setSelectedItem('All');
    setSelectedFinancialYear(YEARS_MENU_ITEMS[0]);
    getRestrictionsFromWaterSource(waterSource.water_source_id, setRestrictions);
    getStatusFromWaterSource(waterSource, setStatus);
    featureConfig.algae_alert.active && getAlgaeUpdatesFromWaterSource(waterSource, setAlgaeUpdate);
    getConstraintsFromWaterSource(waterSource, 'Outage', setMaintenanceEvents);
    getConstraintsFromWaterSource(waterSource, 'Recreation', setRecreationalEvents);
    getConstraintsFromWaterSource(waterSource, 'ROSCCo Events', setRosccoEvents);
    getWaterSourceDroughtFloodRisk(waterSource, setDroughtFloodRisk);
  }, [waterSource]);

  useEffect(() => {
    if (!isEmpty(updates)) {
      setActiveUpdates(
        updates.filter(item => selectedItem === 'All' || item.type === selectedItem),
      );
      setMenuItems(createItemList(updates));
    }
    return () => {
      setActiveUpdates([]);
      setMenuItems([]);
    };
  }, [updates, selectedItem]);

  useEffect(() => {
    if (isEmpty(activeUpdates)) {
      setSelectedItem('All');
    }
  }, [activeUpdates]);

  useEffect(() => {
    (async () => {
      if (!isEmpty(selectedFinancialYear)) {
        const extractedYear = parseInt(selectedFinancialYear.name.split('/')[0]);
        const startDate = startDateOfFinancialYear((extractedYear + 1).toString());
        const endDate = endDateOfFinancialYear((extractedYear + 1).toString());
        let result = await getPriorUpdates(waterSource.water_source_id, null, startDate, endDate);
        result = result.filter(resultItem => resultItem.type !== 'Water Quality');
        result !== null && setUpdates(result);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFinancialYear]);

  // Helpers
  const createItemList = data => {
    let menuItems = [{ id: '0', name: 'All' }];
    let uniqueUpdateTypes = new Set();
    data.forEach(update => {
      const { type } = update;
      if (!uniqueUpdateTypes.has(type)) {
        menuItems.push({ id: menuItems.length, name: type });
      }
      uniqueUpdateTypes.add(type);
    });
    return menuItems;
  };

  const onExternalSiteClick = link => {
    setExternalSiteLink(link);
  };

  const onBtnChange = item => {
    setSelectedItem(item.name);
    if (item.name === 'All') {
      setActiveUpdates(updates);
    } else {
      const newState = updates.filter(updateItem => updateItem.type === item.name);
      setActiveUpdates(newState);
    }
  };

  const onYearChange = item => {
    setSelectedFinancialYear(item);
  };

  const nullMessage = () => {
    return isEmpty(updates)
      ? 'There is no update for this water source'
      : 'There are no updates of that type this year';
  };

  const extractDataFromStatus = (data, status) => {
    const result = [];
    data.forEach(dataItem => {
      if (dataItem[status]) {
        result.push({
          status: dataItem[status],
          ...(dataItem.river && { river: dataItem.river }),
        });
      }
    });

    return result;
  };

  const emptyInMobile = target => {
    return isMobile && isEmpty(target);
  };

  // Component
  const dropdown = () => {
    return (
      <div data-testid={`${container}-dropdown`} className={`${container}-dropdown`}>
        <div className={`${container}-dropdown-type`}>
          {!isEmpty(updates) && (
            <GeneralDropdown
              menuItem={dropDownMenuItems}
              onItemClick={item => onBtnChange(item)}
              selectedItem={
                dropDownMenuItems.find(item => item.name === selectedItem) || {
                  id: '0',
                  name: 'All',
                }
              }
              customArrowColor="expandBox-title-expand-shape-update"
            />
          )}
        </div>

        <div className={`${container}-dropdown-years`}>
          <GeneralDropdown
            menuItem={YEARS_MENU_ITEMS}
            onItemClick={item => onYearChange(item)}
            selectedItem={YEARS_MENU_ITEMS.find(item => item.name == selectedFinancialYear.name)}
            customArrowColor="expandBox-title-expand-shape-update"
          />
        </div>
      </div>
    );
  };

  const mobileContent = (header, content) => {
    return (
      <div className={`${container}-headerContainer`}>
        <ExpandBox
          customHeaderContent={<div>{header}</div>}
          customHeaderContainerStyle={`${container}-header`}
          mobileOnly
        >
          {content}
        </ExpandBox>
      </div>
    );
  };

  const updatesContent = () => {
    const modalBody = (
      <>
        {!showManageAlertResponse && (
          <AlertModalBody
            setResponse={resType => {
              setShowManageAlertResponse(true);
              setResponseType(resType);
            }}
            alertTypeList={DROPDOWN_ITEMS}
            alertType="update"
            notifyIfPersist={true}
            editMode={false}
          />
        )}
      </>
    );

    return (
      <div className={`${container}-body`}>
        {waterSource && (
          <UpdateAlertLink
            showResponse={showManageAlertResponse}
            setShowResponse={setting => setShowManageAlertResponse(setting)}
            modalBody={modalBody}
            alertText={alertResponseText.update[responseType]}
            stationInfo={station}
            customStyle="update"
            modalType="alert-modal"
          />
        )}
        {dropdown()}
        {noticeContent(activeUpdates)}
      </div>
    );
  };

  const noticeContent = contents => {
    return (
      <>
        {!isEmpty(contents) ? (
          contents.map((update, index) => (
            <UpdateItem
              key={index}
              page="tabUpdates"
              onExternalSiteClick={onExternalSiteClick}
              waterSource={{
                water_source_id: update.water_source_id,
                water_source_name: update.water_source_name,
                water_source_type: waterSource.water_source_type,
              }}
              type={update.type}
              title={update.title}
              date={update.date}
              expiryDate={update.expiry_date}
              content={update.body}
              link={update.url}
              linkTitle="View update"
              targetId=""
              shortenBody={true}
            />
          ))
        ) : (
          <div className={`${container}-body-noResult`}>{nullMessage()}</div>
        )}
        <ExternalLinkModal link={externalSiteLink} modalId="externalLinkNSWUpdates" />
      </>
    );
  };

  const algalAlertContent = contents => {
    return (
      <div className={`update-algal-alert`}>
        {!isMobile && <div className={`update-algal-alert-header`}>Algae Alerts</div>}
        {noticeContent(contents)}
      </div>
    );
  };

  return (
    <div className="update-body row">
      <div className="col-lg-7 col-sm-12 col-12">
        <div className="events-container-title">
          <PageTop
            title={`${
              waterSource.water_source_name
                ? replaceWaterSourceLiteral(waterSource.water_source_name)
                : ''
            } ${config.waterSourceUpdatePage.titleTab} `}
          />
        </div>
        {isMobile ? mobileContent('General Notices', updatesContent()) : updatesContent()}
      </div>
      <div className="update-body-right col-lg-5 col-sm-12 col-12">
        {!emptyInMobile(restrictions) && (
          <UpdateTabRestrictions restrictions={restrictions} partialHide />
        )}
        {!isEmpty(droughtFloodRisk) && (
          <Status status={'droughtFloodRisk'} data={droughtFloodRisk} />
        )}
        {!isEmpty(status) && (
          <Status status={'drought'} data={extractDataFromStatus(status, 'drought_status')} />
        )}
        {!isEmpty(status) && (
          <Status status={'water'} data={extractDataFromStatus(status, 'water_quality_status')} />
        )}
        {!isEmpty(algaeUpdate) &&
          (isMobile
            ? mobileContent('Algae Alerts', algalAlertContent(algaeUpdate))
            : algalAlertContent(algaeUpdate))}
        <Events
          recreationalEvents={sortArrayOfObjectByTimestamp(recreationalEvents, 'date', 'desc')}
          maintenanceEvents={sortArrayOfObjectByTimestamp(maintenanceEvents, 'date', 'desc')}
          rosccoEvents={sortArrayOfObjectByTimestamp(rosccoEvents, 'date', 'desc')}
        />
      </div>
    </div>
  );
}
