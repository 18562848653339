// Library
import React from 'react';
import PropTypes from 'prop-types';

// Style
import './dataSummaryBar.scss';

// Component

const container = 'data-summary-bar';
const DataSummaryBar = ({ bars, maximum, noBorder, customizedStyle }) => {
  return (
    <div className={container}>
      <div
        className={`${container}-wrapper ${container}-${noBorder ? 'noBorder' : 'border'} ${
          customizedStyle && `${container}-${customizedStyle}`
        }`}
      >
        {bars.map((bar, index) => {
          return (
            <div
              key={bar.type}
              className={`${container}-cell ${
                bars.length - 1 === index && noBorder ? `${container}-cell-noBorder` : ''
              } `}
              style={{
                backgroundColor: bar.color,
                width: `${(bar.value / maximum) * 100}%`,
              }}
            ></div>
          );
        })}
      </div>
    </div>
  );
};

export default DataSummaryBar;

DataSummaryBar.propTypes = {
  bars: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      value: PropTypes.number,
      color: PropTypes.string,
    }),
  ),
  maximum: PropTypes.number,
  noBorder: PropTypes.bool,
  customizedStyle: PropTypes.string,
};
