// Library
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty, isNil } from 'lodash';

// Styles
import './reportTable.scss';

// Components
import MapLegendLineItem from '../mapProperties/MapLegendLineItem';

// Helper
import { formatNumberDecimals } from '../../helpers/Utils';

// Context
import { MobileContext } from '../../contexts/MobileContext';
import { AppContext } from '../../contexts/AppContext';

// Constant
const container = 'report-table';

export default function ReportTable({
  data,
  headerLabel,
  xAxisHeader = '',
  yAxisHeader = '',
  rowNumber,
  unit = '',
  page = true,
}) {
  const { isMobile } = useContext(MobileContext);
  const { setWaterSource, waterSource } = useContext(AppContext);

  const onLinkClick = item => {
    // Extract data from local cache
    const waterSourceList = JSON.parse(localStorage.getItem('waterSourceList'));
    if (isEmpty(waterSource)) {
      const newWaterSource = waterSourceList.find(
        ws => ws.water_source_id === item.water_source_id,
      );
      if (!isNil(newWaterSource)) {
        setWaterSource(newWaterSource);
      }
    }
  };

  return (
    <div className={`${container} ${!page ? `${container}-component` : ''}`} tabIndex="0">
      {!isMobile && <div className={`${container}-xAxis-header`}>{xAxisHeader}</div>}
      <div className={`${container}-container`}>
        <table>
          <thead>
            <tr>
              {!isMobile && <th />}
              {headerLabel.map((label, index) => (
                <th key={index} id={label.id}>
                  {label.text}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {!isMobile && (
              <tr>
                <td rowSpan={rowNumber.toString()}>
                  <div className={`${container}-yAxis-header`}>{yAxisHeader}</div>
                </td>
              </tr>
            )}
            {data.map((item, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td className={`${container}-water-source`}>
                    {item.color ? (
                      <MapLegendLineItem
                        strokeColor={item.color}
                        strokeWidth={'20'}
                        legendText={item.water_source_name}
                      />
                    ) : (
                      <Link to={item.link} onClick={onLinkClick(item.water_source_id)}>
                        {item.water_source_name}
                      </Link>
                    )}
                  </td>
                  {item.resources.map((resourceItem, index) => (
                    <td headers={resourceItem.label} key={index}>
                      {resourceItem.value || resourceItem.value === 0
                        ? `${formatNumberDecimals(resourceItem.value)}${unit}`
                        : ''}
                    </td>
                  ))}
                </tr>
                {item.dams &&
                  item.dams.map((dam, index) => (
                    <tr key={index}>
                      <td className={`${container}-dam`}>{dam.station_name}</td>
                      <td>{formatNumberDecimals(dam.full_volume / 1000)}</td>
                      <td>{formatNumberDecimals(dam.resources.volume_perc)}%</td>
                      <td>{formatNumberDecimals(dam.resources.volume / 1000)}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

ReportTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      water_source_id: PropTypes.number,
      water_source_name: PropTypes.string,
      full_volume: PropTypes.number,
      resources: PropTypes.array,
      dams: PropTypes.arrayOf(
        PropTypes.shape({
          station_id: PropTypes.string,
          station_name: PropTypes.string,
          full_volume: PropTypes.number,
          resources: PropTypes.object,
        }),
      ),
    }),
  ),
  headerLabel: PropTypes.array,
  xAxisHeader: PropTypes.string,
  yAxisHeader: PropTypes.string,
  rowNumber: PropTypes.number,
  unit: PropTypes.string,
  page: PropTypes.bool,
};
