// Libraries
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// Styles
import './resourcesMapLegend.scss';

// Context
import { MobileContext } from '../../../contexts/MobileContext';

// Constants
import constants from './StateOverviewLegendProp';
import { upperCase } from 'lodash';

const container = 'resources-map-legend';

export default function ResourcesMapLegend({ overviewType }) {
  const PROPS = constants[upperCase(overviewType)];
  const { isLarge } = useContext(MobileContext);

  return (
    <>
      {!isLarge && PROPS.percentageLabel && (
        <div className={`${container}`}>
          <div className={`${container}-percentage`}>
            {PROPS.percentageLabel}
            <div className={`${container}-percentage-scale`}>
              <div className={`${container}-percentage-scale-left`}>
                <div
                  className={`${container}-percentage-scale-left-color${
                    PROPS.percentage[0].className2 ? '-left' : ''
                  }`}
                >
                  {PROPS.percentage.map((prop, index) => (
                    <div
                      key={index}
                      className={`${container}-percentage-scale-left-${prop.className}`}
                    />
                  ))}
                </div>
                {PROPS.percentage[0].className2 && (
                  <div className={`${container}-percentage-scale-left-color-right`}>
                    {PROPS.percentage.map((prop, index) => (
                      <div
                        key={index}
                        className={`${container}-percentage-scale-left-${prop.className2}`}
                      />
                    ))}
                  </div>
                )}
              </div>
              <div className={`${container}-percentage-scale-right`}>
                {PROPS.percentage.map((prop, index) => (
                  <div key={index} className={`${container}-percentage-scale-right-label`}>
                    {prop.label}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={`${container}-volume`}>
            {PROPS.volumeLabel}
            <div className={`${container}-volume-scale`}>
              <div className={`${container}-volume-scale-left`}>
                {PROPS.volume.map((prop, index) => (
                  <div key={index} className={`${container}-volume-scale-left-label`}>
                    {prop.label}
                  </div>
                ))}
              </div>
              <div className={`${container}-volume-scale-right`}>
                {PROPS.volume.map((prop, index) => (
                  <div key={index} className={`${container}-volume-scale-right-circle`}>
                    <div className={`${container}-volume-scale-right-${prop.className}`}>
                      <div className={`${container}-volume-scale-right-${prop.className}-color`} />
                    </div>
                    <hr />
                  </div>
                ))}
              </div>
            </div>
          </div>
          {PROPS.legend.map((prop, index) => (
            <div key={index} className={`${container}-reference`}>
              <div className={`${container}-reference-${prop.className}`} />
              {prop.label}
            </div>
          ))}
        </div>
      )}
    </>
  );
}

ResourcesMapLegend.propTypes = {
  overviewType: PropTypes.string,
};
