// Library
import React from 'react';
import { Link } from 'react-router-dom';

// Style
import './waterSite.scss';

const component = 'water-site';

const ITEM_PROPS = [
  {
    type: 'dams',
    class: 'dam-icon',
    stationType: 'dam',
  },
  {
    type: 'weirs',
    class: 'weir-icon',
    stationType: 'weir',
  },
  {
    type: 'gauges',
    class: 'gauge-icon',
    stationType: 'gauge',
  },
  {
    type: 'bores',
    class: 'bore-icon',
    stationType: 'bore',
  },
];

export default function WaterSite() {
  return (
    <div className={component}>
      {ITEM_PROPS.map((item, index) => (
        <React.Fragment key={index}>
          <Link
            id={index}
            to={{
              pathname: '/m/sites',
              search: `?type=${item.stationType}`,
            }}
          >
            <div className={`${component}-item`} key={index}>
              <div className={`${component}-item-${item.class}`} />
              <div className={`${component}-item-label`}>{item.type}</div>
            </div>
          </Link>
        </React.Fragment>
      ))}
    </div>
  );
}
