// Library
import React, { useState, useContext } from 'react';

// Styles
import './waterSharing.scss';
import '../../layout/grid.scss';

// Component
import PlanSearchResult from './PlanSearchResult';
import PageTop from '../../components/pageTop/PageTop';
import ParagraphSection from '../paragraphSection/ParagraphSection';

// Context
import { MobileContext } from '../../contexts/MobileContext';

// Assets
import config from '../../abstract/IntroText.json';

// Helpers
import { getWaterSharingPlan } from '../../helpers/ArrayUtils';

// Constant
const CONTAINER = 'water-sharing-plans';

const WaterSharing = () => {
  const [userInput, setUserInput] = useState('');
  const { title, subSections } = config.waterSharing;
  const { isMobile } = useContext(MobileContext);
  const [expandUpper, setExpandUpper] = useState(false);

  /* Component */
  const mobileExpandButton = (state, setFnc) => {
    return (
      isMobile && (
        <div className={`${CONTAINER}-expand`}>
          <button className={`${CONTAINER}-expand-button`} onClick={() => setFnc(!state)}>
            {state ? 'See less' : 'See more'}
          </button>
        </div>
      )
    );
  };

  return (
    <div className={`${CONTAINER}`}>
      <div className={`${CONTAINER}-outer`}>
        <div className={`${CONTAINER}-title`}>
          <PageTop title={title} />
        </div>
      </div>
      <div className={`${CONTAINER}-inner`}>
        <div className={`${CONTAINER}-inner-left col-lg-7`}>
          {subSections.map((subSection, index) => (
            <ParagraphSection
              key={`${subSection.title} ${index}`}
              sectionTitle={subSection.title}
              sectionContent={subSection.paragraphs}
              customClass={
                isMobile
                  ? `${CONTAINER}-easein ${
                      index < 1 || expandUpper ? `${CONTAINER}-show` : `${CONTAINER}-hidden`
                    }`
                  : ''
              }
            />
          ))}
          {mobileExpandButton(expandUpper, setExpandUpper)}
        </div>
        <div className={`${CONTAINER}-inner-right col-lg-6`}>
          <div className={`${CONTAINER}-header`}>Water sharing plans</div>
          <div className={`${CONTAINER}-content`}>
            <input
              className={`${CONTAINER}-content-searchbox`}
              placeholder="Search water sharing plans"
              autoComplete="off"
              onChange={e => setUserInput(e.target.value)}
            />
            <PlanSearchResult results={getWaterSharingPlan()} searchKeywords={userInput} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaterSharing;
