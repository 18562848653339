// Libraries
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

//context
import { UserContext } from '../../contexts/UserContext';
import { MobileContext } from '../../contexts/MobileContext';

// Styles
import './loginButton.scss';
import azureB2CHelper from '../../helpers/AzureB2CHelper';

const component = 'login-button';

export default function LoginButton({ customStyle, checkLogingStatus }) {
  const { isLoggedIn, setIsLoggedIn } = useContext(UserContext);
  const { isMobile } = useContext(MobileContext);

  const loginButtonClicked = async () => {
    await azureB2CHelper.redirectToLoginPage();
  };
  const logoutButtonClicked = () => {
    azureB2CHelper.logout();
  };

  useEffect(() => {
    (async () => {
      checkLogingStatus && setIsLoggedIn(await azureB2CHelper.isLoggedIn());
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      data-testid="login-btn"
      className={`${customStyle ? customStyle : component}`}
      onClick={isLoggedIn ? logoutButtonClicked : loginButtonClicked}
    >
      {isLoggedIn ? <>Sign Out</> : isMobile ? <>Sign in</> : <>Sign in or Register</>}
    </div>
  );
}

LoginButton.propTypes = {
  customStyle: PropTypes.string,
  isMini: PropTypes.bool,
};
