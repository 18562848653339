// Libraries
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, toString } from 'lodash';

// Styles
import './MapPopup.scss';

// Context
import { MapContext } from '../../contexts/MapContext';
import { MobileContext } from '../../contexts/MobileContext';

// Component
import ViewDetailsLink from './viewDetailsLink/ViewDetailsLink';
import Loader from '../loader/Loader';
import ChartToDownloadPageLink from '../chartProperties/ChartToDownloadPageLink';
import UpdatedDate from './miniComponents/UpdatedDate';
import PopupBody from './miniComponents/PopupBody';

// Helpers
import { getLatestSurfaceWaterData } from '../../helpers/WaterDataApiHelper';

// Constants
import constants from '../../constants/Constants.jsx';
import mapFilterConstant from '../../constants/MapFilterConstants';
import hydroConstant from '../../constants/HydrometricsConstants';

const { WEIR, GAUGE } = mapFilterConstant.MAP_POPUP_ITEMS;
const { riverData, storage } = constants.TAB_OPTIONS;

const component = 'map-popup';

export default function RiverGaugePopup({ data, type, timestamp, openPopup }) {
  // Context
  const { markerSelected } = useContext(MapContext);
  const { isMobile } = useContext(MobileContext);

  const label =
    type === WEIR.name ? hydroConstant.WEIR_DATA_LABEL_FORMAT : hydroConstant.DATA_LABEL_FORMAT;
  const specialSites = constants.EXCLUDED_SITES.includes(data.station_id);

  // State
  const [popupData, setPopupData] = useState();
  const [dataStatusMsg, setDataStatusMsg] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);
  const [popupItems, setPopupItems] = useState(
    Object.entries(label).map(item => {
      return { ...item[1], dataKey: item[0], title: item[1].name };
    }),
  );

  // Life Cycle
  useEffect(() => {
    setPopupData({});
    if (openPopup && markerSelected.station_id === data.station_id) {
      try {
        (async () => {
          setShowSpinner(true);
          if (data.active === false && data.commentary !== '') {
            setDataStatusMsg(data.commentary);
          } else {
            setDataStatusMsg('Loading...');
            const dataVariables = specialSites
              ? 'DissolvedOxygen0.75MBelowSurface,DissolvedOxygen2.0MBelowSurface,DissolvedOxygen3.0MBelowSurface'
              : data.water_data_variables.join(',');
            const stationData = await getLatestSurfaceWaterData(
              toString(markerSelected.id),
              type,
              dataVariables,
            );
            setPopupData(stationData);
            if (!isEmpty(stationData)) {
              setDataStatusMsg('');
            } else {
              setDataStatusMsg('No data is currently available for this gauge.');
            }
          }
        })();
      } catch (error) {
        console.error(error);
      } finally {
        setShowSpinner(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openPopup, timestamp]);

  useEffect(() => {
    if (!isEmpty(popupData) && type === GAUGE.name) {
      const filtered = popupItems.filter(item => {
        return Object.keys(popupData[0]).includes(item.dataKey);
      });
      setPopupItems(filtered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupData]);

  return (
    <div className={`${component}-container`}>
      {showSpinner && <Loader />}
      <div className={`${component}-body-title`}>{`${data.station_name} (${data.station_id})`}</div>
      {dataStatusMsg}
      {!isEmpty(popupData) && (
        <>
          {popupItems.map((item, index) => {
            const curItem = popupData[0][item.dataKey];
            return (
              curItem && (
                <PopupBody
                  key={index}
                  title={item.title}
                  data={curItem}
                  unit={item.unit}
                  isSameDate={curItem.timeStamp === popupData[0].date}
                  dataKey={item.dataKey}
                  decimals={item.decimals}
                />
              )
            );
          })}
          <UpdatedDate date={popupData[0].date} />
          <div id={`${component}-view-details`} className={`${component}-view-details-gauge`}>
            {specialSites ? (
              !isMobile && (
                <ChartToDownloadPageLink
                  siteId={data.station_id}
                  siteType="gauge"
                  interval="15-minute"
                  showIcon={false}
                  dataType="autoqc"
                  label="View more data"
                  timePeriod="7"
                  hydrometric="SPECIAL"
                />
              )
            ) : (
              <ViewDetailsLink
                elementId={data.station_id}
                activeTab={riverData.text}
                pathname={type === WEIR.name ? storage.link : riverData.link}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}

RiverGaugePopup.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
  timestamp: PropTypes.string,
  openPopup: PropTypes.bool,
};
