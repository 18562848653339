// Libraries
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { isNil, isEmpty, capitalize, get } from 'lodash';

// Styles
import './alertModalBody.scss';

// Context
import { UserContext } from '../../contexts/UserContext';

// Components
import GeneralDropdown from '../dropDown/GeneralDropdown';

// Helpers
import { postSubscription, editAlert } from '../../helpers/SubscriptionsApiHelper';
import { getDatesByPeriod } from '../../helpers/TimeUtils';

// Constants
import config from '../../configs/featureToggleConfig.json';
const newGaugeGraph = config['new_gauge_graph'].active;
const component = 'gauge-alert-modal-body';
const DROPDOWN_ITEMS = [
  { id: 'above', name: 'RISES ABOVE' },
  { id: 'below', name: 'FALLS BELOW' },
];
const DELIVERY_TYPE = [
  { name: 'Mobile', check: false },
  { name: 'Email', check: false },
];

const KEYNAME_MAP = {
  flow_rate: 'Discharge rate',
  level: 'Stream water Level',
  dissolved_oxygen: 'Dissolved Oxygen',
  dissolved_oxygen_saturation: 'Dissolved Oxygen Saturation',
  salinity: 'Electrical conductivity',
  ph: 'pH',
  temperature: 'Water temperature',
  water_level: 'Groundwater depth',
};

const ALERT_TYPE = 'station';

export default function GaugeAlertModalBody({
  stationInfo,
  thresholdInit,
  hydroOptions,
  reminderInit = false,
  limitInit,
  hydrometricInit = '',
  editMode = false,
  alertId,
  setManageAlertResponse,
}) {
  const { user } = useContext(UserContext);
  const hydrometrics =
    newGaugeGraph &&
    !isEmpty(hydroOptions) &&
    hydroOptions.map(item => {
      return {
        id: item.hydroName.toLowerCase(),
        name: item.displayName.toLowerCase() === 'ph' ? 'pH' : capitalize(item.displayName),
        unit: item.unit,
        key_name: KEYNAME_MAP[item.hydroName.toLowerCase()],
      };
    });

  // State
  const [threshold, setThreshold] = useState(thresholdInit);
  const [deliveryType, setDeliveryType] = useState(DELIVERY_TYPE);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [reference, setReference] = useState(
    limitInit ? DROPDOWN_ITEMS.find(reference => reference.id === limitInit) : DROPDOWN_ITEMS[0],
  );
  const [hydrometric, setHydrometric] = useState(hydrometrics[0]);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [dailyReminder, setDailyReminder] = useState(reminderInit);

  const { station_id: stationId, station_name: stationName } = stationInfo;

  // Life Cycle
  useEffect(() => {
    if (newGaugeGraph && !isEmpty(hydrometrics)) {
      setHydrometric(
        hydrometrics.find(element => element.id.toLowerCase() === hydrometricInit.toLowerCase()),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hydrometricInit, hydroOptions]);

  useEffect(() => {
    let isFilled = isFormFilled();
    if (allowSubmit !== isFilled) {
      setAllowSubmit(isFilled);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threshold, deliveryType]);

  useEffect(() => {
    setDailyReminder(reminderInit);
    setThreshold(thresholdInit);
    setDeliveryType(DELIVERY_TYPE);
    setReference(
      limitInit ? DROPDOWN_ITEMS.find(reference => reference.id === limitInit) : DROPDOWN_ITEMS[0],
    );
  }, [stationId, reminderInit, thresholdInit, limitInit, hydrometricInit]);

  const onItemClick = item => {
    setReference(item);
  };

  const onHydroItemClick = item => {
    setHydrometric(item);
  };

  // Helpers
  const isFormFilled = () => {
    let isFilled = true;
    // Checking threhosld
    if (isNil(threshold) || threshold === '') isFilled = false;
    // Checking delivery checkbox
    let isLeastOne = false;
    deliveryType.forEach(type => {
      let checkState = get(type, 'check');
      if (checkState) isLeastOne = true;
    });
    if (!isLeastOne) isFilled = false;

    return isFilled;
  };

  const handleSubmit = async () => {
    const notifyIfPersist = dailyReminder;
    const success = true;
    if (!buttonClicked) {
      setButtonClicked(true);
      let data;
      if (!editMode) {
        data = {
          deliveryType: deliveryType
            .filter(delivery_type => delivery_type.check)
            .map(delivery_type => delivery_type.name)
            .join(),
          notifyIfPersist: notifyIfPersist,
          alertType: ALERT_TYPE,
          stationId: stationId,
          thresholdOperand: reference.id,
          hydrometricName: hydrometric.key_name,
          threshold: Number.parseFloat(threshold),
          userId: user.email,
          expiryDate: getDatesByPeriod(12, 'YYYY-MM-DD', 'months'),
        };
        const response = await postSubscription(data);
        if (response === true) {
          setManageAlertResponse('create', success);
        } else {
          setManageAlertResponse('error');
        }
      } else {
        data = {
          notifyIfPersist: notifyIfPersist,
          alertType: ALERT_TYPE,
          thresholdOperand: reference.id,
          threshold: Number.parseFloat(threshold),
          alertId,
          userId: user.email,
        };
        const response = await editAlert(data);
        if (response === true) {
          setManageAlertResponse('update', success, data);
        } else {
          setManageAlertResponse('error');
        }
      }
      setButtonClicked(false);
    }
  };

  const onCheckBoxChange = target => {
    const { value, checked } = target;
    setDeliveryType(prevState => {
      const target = prevState.findIndex(item => item.name === value);
      if (target !== -1) {
        prevState[target].check = checked;
        return [...prevState];
      } else {
        return [...prevState];
      }
    });
  };

  return (
    <div data-testid={component} className={`${component}`}>
      <div className={`${component}-title`}>{stationName}</div>
      When
      <div className={`${component}-threshold`}>
        <div>The river</div>
        <div className={`${component}-threshold-dropdowns`}>
          <div className={`${component}-hydrometric-dropdown`}>
            {editMode
              ? stationInfo.hydrometric_short_name.replaceAll('_', ' ')
              : !isEmpty(hydrometrics) && (
                  <GeneralDropdown
                    menuItem={hydrometrics}
                    onItemClick={item => {
                      onHydroItemClick(item);
                    }}
                    selectedItem={hydrometric}
                    disabled={editMode}
                  />
                )}
          </div>
          <div className={`${component}-condition-dropdown`}>
            <GeneralDropdown
              menuItem={DROPDOWN_ITEMS}
              onItemClick={item => {
                onItemClick(item);
              }}
              selectedItem={reference}
            />
          </div>
          <div className={`${component}-condition-input`}>
            <input
              aria-label="condition-input"
              placeholder="0.0"
              type="number"
              step="0.1"
              className={`${component}-threshold-value`}
              value={threshold || ''}
              onChange={e => {
                setThreshold(e.target.value);
              }}
            />
            <span>&nbsp;{hydrometric && hydrometric.unit}</span>
          </div>
        </div>
      </div>
      {!editMode && (
        <>
          Send alert to (select at least one)
          <div className={`${component}-type`}>
            {DELIVERY_TYPE.map((typeItem, index) => (
              <div key={index} className={`${component}-type-check col-4`}>
                <input
                  id={`${component}-type-check`}
                  readOnly
                  className="checkbox"
                  type="checkbox"
                  value={typeItem.name}
                  name={typeItem.name}
                  checked={typeItem.check}
                  onChange={e => {
                    onCheckBoxChange(e.target);
                  }}
                />
                <label htmlFor={`${component}-type-check`}></label>
                &nbsp;{typeItem.name}
              </div>
            ))}
          </div>
        </>
      )}
      <>
        If the river condition persists
        <div className={`${component}-type`}>
          <div className={`${component}-type-check`}>
            <input
              id={`${component}-type-check`}
              className="checkbox"
              type="checkbox"
              name={'daily_reminder_option'}
              checked={dailyReminder}
              onChange={() => {
                setDailyReminder(prev => !prev);
              }}
            />
            <label htmlFor={`${component}-type-check`}></label>
            &nbsp;Remind me at 9am each day
          </div>
        </div>
      </>
      <div
        className={`${component}-button ${
          allowSubmit || editMode ? '' : `${component}-button-disabled`
        }`}
        onClick={e => {
          e.preventDefault();
          if (allowSubmit || editMode) handleSubmit();
        }}
      >
        {!editMode ? 'Set alert' : 'Confirm'}
      </div>
    </div>
  );
}

GaugeAlertModalBody.propTypes = {
  stationId: PropTypes.string,
  stationName: PropTypes.string,
  editMode: PropTypes.bool,
  thresholdInit: PropTypes.number,
  reminderInit: PropTypes.bool,
  limitInit: PropTypes.string,
  hydrometricInit: PropTypes.string,
  setManageAlertResponse: PropTypes.func,
  alertId: PropTypes.string,
};
