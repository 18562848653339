// Libraries
import React from 'react';
import PropTypes from 'prop-types';

const DuplicateSVG = ({ customClass }) => {
  return (
    <svg
      width="42.7px"
      height="42.7px"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      className={customClass ? customClass : ' '}
    >
      <path
        fillRule="evenodd"
        d="M14,5 C14.5523,5 15,5.44772 15,6 L15,14 C15,14.5523 14.5523,15 14,15 L6,15 C5.44772,15 5,14.5523 5,14 L5,6 C5,5.44772 5.44772,5 6,5 L14,5 Z M13,7 L7,7 L7,13 L13,13 L13,7 Z M10,1 C10.5523,1 11,1.44772 11,2 L11,4 L9,4 L9,3 L3,3 L3,9 L4,9 L4,11 L2,11 C1.44772,11 1,10.5523 1,10 L1,2 C1,1.44772 1.44772,1 2,1 L10,1 Z"
      />
    </svg>
  );
};

export default DuplicateSVG;

/* Props */
DuplicateSVG.propTypes = {
  customClass: PropTypes.string,
};
