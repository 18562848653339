import React, { useState, createContext } from 'react';

export const TabsContext = createContext();

export default function TabsContextProvider(props) {
  const [tabsRef, setTabsRef] = useState(null);
  const [tabContainerRef, setTabContainerRef] = useState(null);

  const setPositionCenter = target => {
    const targetRef = tabsRef.current.find(tab => {
      const cleanedText = tab.innerText.replace(/\n/gi, '');
      return cleanedText.toLowerCase() === target.toLowerCase();
    });
    const offsetLeft = targetRef.getBoundingClientRect().left;
    let width = tabContainerRef.current.clientWidth;
    let diff = offsetLeft - width / 3;
    tabContainerRef.current.scrollLeft = tabContainerRef.current.scrollLeft + diff;
  };

  return (
    <TabsContext.Provider
      value={{ tabsRef, tabContainerRef, setTabsRef, setTabContainerRef, setPositionCenter }}
    >
      {props.children}
    </TabsContext.Provider>
  );
}
