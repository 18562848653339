// Library
import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

// Styles
import './radioList.scss';

const component = 'radio-list';

const RadioList = ({
  options,
  onChange,
  selectedOption,
  itemFormatter = item => item,
  customStyles,
}) => {
  const onRadioChange = ({ id, checked }) => {
    if (checked) {
      const target = options.find(item => item.id === id);
      target !== -1 && onChange(target);
    } else {
      onChange('');
    }
  };

  return (
    <div className={`${component} ${customStyles}`}>
      {!isEmpty(options) &&
        options.map(option => {
          const isSelected = selectedOption && selectedOption.id === option.id;
          return (
            <div
              key={option.id}
              className={`${component}-option${option.disabled ? '-hide' : ''} ${
                isSelected && `${component}-option-selected`
              }`}
            >
              <input
                className={`${component}-option-btn`}
                type="radio"
                id={option.id}
                value={option.id}
                checked={isSelected}
                onChange={e => onRadioChange(e.target)}
              />
              <label className={`${component}-option-lbl `} htmlFor={option.id}>
                {itemFormatter(`${option.name}${option.subText ? ` ${option.subText}` : ''}`)}
              </label>
            </div>
          );
        })}
    </div>
  );
};

export default RadioList;

RadioList.propTypes = {
  // An array of objects, each with id (a required string), name (a required string),
  // and disabled (an optional boolean) properties. This represents the list of checkbox
  // options that will be rendered.
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  // A function that is called when checkbox is clicked
  onChange: PropTypes.func.isRequired,
  // An array of objects, each with id (a required string), name (a required string),
  // and disabled (an optional boolean) properties. This represents the currently selected
  // checkbox options.
  selectedOption: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
  }),
  // An optional function that formats the label for each checkbox option.
  // It takes an item as its argument and returns a string.
  // If not provided, it defaults to returning the name property of the item.
  itemFormatter: PropTypes.func,
  // An optional number that represents the maximum number of checkboxes that can be selected.
  // Defaults to Infinity.
  limit: PropTypes.number,
};
