// Library
import { isEmpty, startCase, lowerCase } from 'lodash';

// Helper
import { getSiteMetadata } from '../../../../../../helpers/WaterDataApiHelper';
import { getAllStationsByType } from '../../../../../../helpers/ApiHelper';

// Constants
import constants from '../../../../../../constants/WaterDataAPIConstants';

export const filterListByKeyword = (list, keyword) => {
  if (isEmpty(list) || !keyword) return list;
  const filtered = list.filter(item => {
    return item.name.toLowerCase().indexOf(keyword.toLowerCase()) !== -1;
  });

  return filtered;
};

export const fetchBoreStations = async () => {
  return await getAllStationsByType('bore', result => {
    const formatted = result.map(item => {
      return {
        id: item.station_id,
        name: `${item.station_id}${item.station_name !== '' ? ` - ${item.station_name}` : ''}`,
      };
    });
    return formatted;
  });
};

export const fetchStations = async (type, subType, signal) => {
  let result = [];
  if (type) {
    const siteList = await getSiteMetadata(null, constants.SITE_TYPE[type], subType, null, signal);
    if (!isEmpty(siteList)) {
      const filtered = siteList.filter(
        item => !item.siteId.startsWith('WQ') || !item.siteId.startsWith('D'),
      );
      const formatted = filtered.map(item => {
        return {
          id: item.siteId,
          name: `${item.siteId}${
            item.siteName !== ''
              ? ` - ${startCase(lowerCase(item.siteName))
                  .replaceAll(' U S ', ' U/S ')
                  .replaceAll(' D S ', ' D/S ')
                  .replaceAll(' No ', ' No. ')
                  .replaceAll('Gw ', 'GW')
                  .replaceAll(' Km ', ' KM ')}`
              : ''
          }`,
          disabled: false,
          variables: Object.values(item.variablesMonitored),
          active: item.active,
          siteType: item.siteType,
          ...item,
        };
      });
      result = formatted;
    }
  }
  return result;
};

export const findRequestedSite = (
  requestedSiteId,
  stations,
  setSelectedStation,
  setRequestedSiteId,
) => {
  if (requestedSiteId) {
    const requestedSite = stations.find(item => item.id === requestedSiteId);
    requestedSite && setSelectedStation([requestedSite]);
    setRequestedSiteId('');
  }
};
