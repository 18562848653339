// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './mapLegend.scss';

export default function MapLegendLineItem({ strokeColor, strokeWidth, legendText }) {
  return (
    <div className="map-legend-item-wrapper">
      <div className="map-legend-line-base">
        <svg viewBox="0 0 24 10" xmlns="http://www.w3.org/2000/svg">
          <line
            x1="0"
            y1="3"
            x2="24"
            y2="3"
            stroke={strokeColor}
            strokeWidth={strokeWidth}
            strokeDasharray="0"
          />
        </svg>
      </div>
      <div className="map-legend-item">{legendText}</div>
    </div>
  );
}

MapLegendLineItem.propTypes = {
  strokeColor: PropTypes.string,
  strokeWidth: PropTypes.string,
  legendText: PropTypes.string,
};
