// Library
import React from 'react';
import PropTypes from 'prop-types';

// Style
import './hourlyUpdateItem.scss';

// Helper
import { formatNumberDecimals } from '../../../helpers/Utils';

export default function HourlyUpdateItem({
  value,
  textUnit,
  labelText,
  rounded = false,
  isLatest = false,
  singleDecimal = false,
  isUpdatedTime,
}) {
  const container = 'hourly-update-item';
  return (
    <div className={`${container}`}>
      <div className={`row fluid ${container}-content`}>
        <div className={`${container}-label`}>{`${labelText}`}</div>
        <div className={`${container}-unit-div`}>
          <div
            className={`${container}-value${isUpdatedTime ? '-secondary' : ''}`}
          >{`${formatNumberDecimals(value, rounded, singleDecimal)}`}</div>
          {value !== '-' && <div className={`${container}-value-unit`}>&nbsp;{textUnit}</div>}
          {value !== '-' && isLatest && <div className={`${container}-asterisk`}>*</div>}
        </div>
      </div>
    </div>
  );
}

HourlyUpdateItem.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textUnit: PropTypes.string,
  labelText: PropTypes.string,
  isPrimary: PropTypes.bool,
  isForWaterSource: PropTypes.bool,
  isLatest: PropTypes.bool,
};
