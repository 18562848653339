// Library
import React from 'react';
import PropTypes from 'prop-types';

// Style
import './manageBody.scss';

const component = 'manage-body';

const ManageBody = ({ headers, children }) => {
  return (
    <div className={component}>
      <div className={`${component}-header`}>
        {headers.map((item, index) => (
          <div className={`${component}-header-item ${item}`} key={index}>
            {item}
          </div>
        ))}
      </div>
      {children}
    </div>
  );
};

export default ManageBody;

ManageBody.propTypes = {
  headers: PropTypes.array,
  children: PropTypes.any,
};
