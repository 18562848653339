// Libraries
import { uniqBy, groupBy, isEmpty, merge, pick, cloneDeep, toLower } from 'lodash';
import moment from 'moment';

// Helpers
import { isWaterSourceREG, sortArrayByKeyAsc } from './Utils';
import { getCurrentDate } from './TimeUtils';
import { renameKeyStorage, mergedObjectsByKey, mergeObjectsInUnique } from './ArrayUtils';
import { getStationsForWaterSource } from '../helpers/ApiHelper.jsx';

// Assets
import mapFilterConstants from '../constants/MapFilterConstants';

// Constant
import constant from '../constants/Constants';

export const prepareFullWSList = data => {
  let greaterSydneyWS;
  for (let i = 0; i < data.length; i++) {
    const curEl = data[i].water_source_id;
    if (curEl === constant.GREATER_SYDNEY_WSID) {
      greaterSydneyWS = data.splice(i, 1);
      i--;
    } else if (curEl === constant.ALL_NSW_WSID) {
      data.splice(i, 1);
      i--;
    }
  }
  const groupedResult = groupBy(data, 'water_source_type');
  const result = [
    {
      type: 'reg',
      title: 'Regulated Water Sources',
      items: sortArrayByKeyAsc(groupedResult.REG, 'water_source_name'),
      order: 2,
    },
    {
      type: 'all',
      title: 'All Water Sources',
      items: sortArrayByKeyAsc(data, 'water_source_name'),
      order: 3,
    },
  ];
  if (!isEmpty(greaterSydneyWS)) {
    result.unshift({
      type: 'gs',
      title: greaterSydneyWS[0].water_source_name,
      items: greaterSydneyWS,
      order: 1,
    });
  }
  return result;
};

export const prepareNearbyWSList = data => {
  const resultList = getActiveWaterSources(data);
  return resultList;
};

export const getMapFilterItems = async (waterSource, isMobile, type, selected) => {
  const waterType =
    waterSource.water_source_type.length === 1 ? waterSource.water_source_type[0] : 'MIX';
  let result = [];
  if (isWaterSourceREG(waterSource)) {
    result = cloneDeep(mapFilterConstants.MAP_FILTERS_BY_WATER_SOURCE_TYPE['REG']);
    if (waterSource.water_source_id === constant.MURRUMBIDGEE_REGULATED_RIVER_WATER_SOURCE) {
      result = [
        ...result,
        { ...(!isMobile && mapFilterConstants.MAP_FILTERS_FOR_FLOW_RATE) },
        {
          ...(waterSource.order_take && mapFilterConstants.MAP_FILTERS_FOR_ORDER_USAGE),
        },
      ];
    }
  } else {
    result = cloneDeep(mapFilterConstants.MAP_FILTERS_BY_WATER_SOURCE_TYPE[waterType]);
    result = await addMapFilter(waterSource, result, 'DAM');
    result = await addMapFilter(waterSource, result, 'WEIR');
  }
  let filteredResult;
  if (!isEmpty(result)) {
    filteredResult = result.filter(function (element) {
      return element !== undefined && !isEmpty(element);
    });
    filteredResult.forEach((item, index) => {
      if (item.type === type) {
        filteredResult[index].selected = selected;
      }
    });
  }

  return filteredResult;
};

export const getActiveWaterSources = data => {
  let mergedResult = merge(
    data,
    data.map(i => i.attributes),
  );
  let uniqueResult = uniqBy(mergedResult, function (elem) {
    return JSON.stringify(pick(elem, ['WS_WATER_LOCATION_ID']));
  });
  const isGreaterSydney = ws => {
    return ws.layerName.includes('WaterNSW Declared Catchment - Total Area') ? '12964' : undefined;
  };
  const waterSourceIds = uniqueResult.map(
    waterSource => waterSource.WS_WATER_LOCATION_ID || isGreaterSydney(waterSource),
  );
  const fullWaterSourceList = JSON.parse(localStorage.getItem('waterSourceList'));
  let prioritizedWS;
  const filteredList =
    !isEmpty(fullWaterSourceList) && !fullWaterSourceList.message
      ? fullWaterSourceList.filter(waterSource => {
          if (waterSourceIds.includes(waterSource.water_source_id.toString())) {
            const activeWaterSource = uniqueResult.find(
              ws => ws.WS_WATER_LOCATION_ID === waterSource.water_source_id.toString(),
            );
            if (activeWaterSource?.tag === 'prioritized') {
              prioritizedWS = waterSource.water_source_id.toString();
            }
            return waterSource;
          }
        })
      : [];
  const updatedFilteredList = filteredList.map(element => {
    if (element.water_source_id.toString() === prioritizedWS) {
      return { ...element, tag: 'prioritized' };
    }
    return element;
  });

  return updatedFilteredList;
};

export const parseDamsResourcesPerDate = (data, dataType) => {
  let resultFinal = {};

  if (!isEmpty(data)) {
    //Renaming the field value with the variable number
    renameKeyStorage(data);

    const groupByDamName = groupBy(data, 'name');

    const aggregateValues = mergedObjectsByKey(renameKeyStorage(data), 'date', dataType);

    for (let [key, value] of Object.entries(groupByDamName)) {
      resultFinal[key] = mergeObjectsInUnique(value, 'date');
    }

    resultFinal = combineAggregateNoAggregate(aggregateValues, resultFinal);
  }
  return resultFinal;
};

export const parseFlowsResourcesPerDate = (data, varType) => {
  let aggregateValues = {};

  if (!isEmpty(data)) {
    //Remove the current month data so it is not displayed in the graph
    data = data.filter(yearItem => yearItem.date !== getCurrentDate('YYYY-MM'));
    //Renaming the field value with the variable number
    renameKeyStorage(data);
    aggregateValues = mergedObjectsByKey(renameKeyStorage(data), 'date', varType);
  }
  return aggregateValues;
};

export const combineAggregateNoAggregate = (aggregate, noaggregate) => {
  const resources = {
    all: aggregate,
    ...noaggregate,
  };
  return resources;
};

export const genGraphDataForFiscalYear = (data, isShortName) => {
  let defaultData = [
    { month: 'July' },
    { month: 'August' },
    { month: 'September' },
    { month: 'October' },
    { month: 'November' },
    { month: 'December' },
    { month: 'January' },
    { month: 'February' },
    { month: 'March' },
    { month: 'April' },
    { month: 'May' },
    { month: 'June' },
  ];
  defaultData.forEach(item => {
    if (isShortName) {
      item.month = item.month.substring(0, 3);
    }
    if (data) {
      Object.assign(
        item,
        data.find(row => row.month === (isShortName ? item.month.substring(0, 3) : item.month)),
      );
    }
  });
  return defaultData;
};
/**
 * Generate array of dummy data based on length of time
 * @param {number} timePeriod length of time
 * @param {string} timeUnit type of time (days, months, years....)
 * @param {string} dateKey object key of the object
 * @param {string} dateFormat format for the time
 * @return {array}
 */
export const genDummyDataForTimePeriod = ({
  timePeriod = 1,
  timeUnit = 'days',
  startingTime,
  dateKey = 'date',
  dateFormat = 'YYYY-MM-DD',
  interval = 1,
}) => {
  const curDate = startingTime ? moment(startingTime, dateFormat) : moment().format(dateFormat);
  const dummyData = [];
  for (let i = timePeriod; i > 0; i--) {
    dummyData.push({
      [dateKey]: moment(curDate, dateFormat)
        .subtract((i - 1) * interval, timeUnit)
        .format(dateFormat),
    });
  }
  return dummyData;
};

export const mixInDefault = (targetArr, period, timeUnit, timeSubstracted, format) => {
  const defaultData = genDummyDataForTimePeriod({
    timePeriod: period,
    timeUnit: timeUnit,
    dateFormat: format,
    startingTime: moment(getCurrentDate(), 'YYYY-MM-DD').subtract(timeSubstracted, timeUnit),
  });

  const res = defaultData.map(dataPoint => {
    let resultData = dataPoint;
    targetArr.forEach(station => {
      const comparison = moment(station.date, 'YYYY-MM-DD').format('YYYY-MM');
      if (dataPoint.date === comparison) {
        resultData = { ...station, date: station.date };
      }
    });
    return resultData;
  });

  return res;
};

export const addMapFilter = async (waterSource, result, filterOption) => {
  const options = mapFilterConstants.MAP_FILTERS_OPTIONS[filterOption];
  await getStationsForWaterSource(
    waterSource,
    mapFilterConstants.MAP_POPUP_ITEMS[filterOption].name,
    function (resultCall) {
      const grouped = groupBy(resultCall, 'station_type');
      if (!isEmpty(grouped[toLower(filterOption)])) {
        result = [options, ...result];
      }
    },
  );
  return result;
};

/**
 * Filter the dams which have data from the API
 * @param {*} waterSourceDams - List of dams for the specific water source
 * @param {*} damsWithData - list of dams with data from the API
 */
export const getListDamsDropDown = (waterSourceDams, damsWithData) => {
  let damListData = [];
  if (damsWithData && !isEmpty(damsWithData)) {
    const dams = Object.keys(damsWithData.data);
    const damsID = [];
    dams.forEach(damName => {
      const curDamData = damsWithData.data[damName];
      if (!isEmpty(curDamData)) {
        const targetData = curDamData[0];
        targetData.dam_id && damsID.push(targetData.dam_id);
      }
    });
    damListData = waterSourceDams.filter(dam => {
      return damsID.includes(dam.station_id);
    });
  }
  return damListData;
};

export const parserDamsDataPerFY = (resourcesData, activeDams) => {
  Object.keys(resourcesData).forEach(dam => {
    resourcesData[dam].forEach(month => {
      Object.keys(month).forEach(FY => {
        if (FY.includes(':')) {
          month[FY] = month[FY].volume;
        }
      });
    });
  });
  if (activeDams.length > 1) {
    return { data: resourcesData.all }; //aggregated
  }
  const resourcesArray = Object.keys(resourcesData).map(k => resourcesData[k]);

  return {
    data: resourcesArray.find(resource => resource[0].dam_id === activeDams[0]),
  };
};

export const flatObject = object => {
  const flatObject = {};
  const path = [];
  function dig(object) {
    if (object !== Object(object)) {
      return (flatObject[path.join('.')] = object);
    }
    for (let key in object) {
      path.push(key);
      dig(object[key]);
      path.pop();
    }
  }

  dig(object);
  return flatObject;
};

/**
 * Uses the Ramer-Douglas-Peucker algorithm to downsample an array of graph data points.
 * @param {Array<Object>} points - An array of graph data points, each represented as an object containing `x` and `y` values.
 * @param {number|null} epsilon - The maximum distance between the original curve and the simplified curve, or `null` to use a default value.
 * @param {string} xDataKey - The name of the property in each data point object that represents the x-value.
 * @param {function} xValueConverter - A function to convert the x-value to a numeric representation if needed.
 * @param {string} yDataKey - The name of the property in each data point object that represents the y-value.
 * @param {function} [yValueConverter=v => v] - A function to convert the y-value to a numeric representation if needed. Defaults to the identity function.
 * @returns {Array<Object>} An array of downsampled graph data points, each represented as an object containing `x` and `y` values.
 */
export const downSamplingGraphDataByRDP = (
  points,
  epsilon,
  xDataKey,
  xValueConverter,
  yDataKey,
  yValueConverter = v => {
    return v;
  },
) => {
  // Find the point with the maximum distance from the line
  let dmax = 0;
  let index = 0;
  const lastIndex = points.length - 1;

  for (let i = 1; i < lastIndex; i++) {
    const d = perpendicularDistance(
      points[i],
      points[0],
      points[lastIndex],
      xDataKey,
      xValueConverter,
      yDataKey,
      yValueConverter,
    );

    if (d > dmax) {
      index = i;
      dmax = d;
    }
  }

  if (epsilon === null) {
    epsilon = dmax * 0.01;
  }

  // If the maximum distance is greater than epsilon, recursively simplify
  if (dmax > epsilon) {
    // Recursive call
    const left = points.slice(0, index + 1);
    const right = points.slice(index);
    const leftResult = downSamplingGraphDataByRDP(
      left,
      epsilon,
      xDataKey,
      xValueConverter,
      yDataKey,
      yValueConverter,
    );
    const rightResult = downSamplingGraphDataByRDP(
      right,
      epsilon,
      xDataKey,
      xValueConverter,
      yDataKey,
      yValueConverter,
    );

    // Combine the results and return
    return leftResult.slice(0, -1).concat(rightResult);
  } else {
    // Maximum distance is within tolerance, so return the endpoints
    return [points[0], points[lastIndex]];
  }
};

const perpendicularDistance = (
  point,
  lineStart,
  lineEnd,
  xDataKey,
  xValueConverter,
  yDataKey,
  yValueConverter,
) => {
  const px = xValueConverter(point[xDataKey]);
  const py = yValueConverter(point[yDataKey]);
  const sx = xValueConverter(lineStart[xDataKey]);
  const sy = yValueConverter(lineStart[yDataKey]);
  const ex = xValueConverter(lineEnd[xDataKey]);
  const ey = yValueConverter(lineEnd[yDataKey]);

  const numerator = Math.abs((ey - sy) * px - (ex - sx) * py + ex * sy - ey * sx);
  const denominator = Math.sqrt(Math.pow(ey - sy, 2) + Math.pow(ex - sx, 2));

  return numerator / denominator;
};
