/* Library */
import React, { useContext } from 'react';
import moment from 'moment';
import { ComposedChart, Tooltip, Area, Bar, XAxis, Legend, YAxis, ReferenceLine } from 'recharts';
import PropTypes from 'prop-types';
import { isEmpty, isNumber } from 'lodash';

/* Styles */
import '../storageChart.scss';

/* Component */
import ChartLegend from '../../chartProperties/ChartLegend';
import ChartResponsiveContainer from '../../chartProperties/ChartResponsiveContainer';

/* Context */
import { AppContext } from '../../../contexts/AppContext';
import { MobileContext } from '../../../contexts/MobileContext.jsx';

/* Helpers */
import { formatNumberDecimals } from '../../../helpers/Utils';

/* Constant */
import Constants from '../../../constants/Constants';

export default function MonthlyStorageGraph({
  data,
  isDailySelected,
  activeDams,
  fullVolume,
  yearOffset,
  xAxisDateKey,
  isLoading,
}) {
  const { waterSource } = useContext(AppContext);
  const { isMobile } = useContext(MobileContext);
  const fields = isEmpty(data) ? [] : Object.keys(data[0]);
  const isGreaterSydney = waterSource.water_source_id === Constants.GREATER_SYDNEY_WSID;
  const isBarwonDarling =
    waterSource.water_source_id === Constants.BARWON_DARLING_UNREGULATED_RIVER_WATER_SOURCE;
  const greaterSydneyRefLine = [
    { text: '50%', y: fullVolume / 2 },
    { text: '25%', y: fullVolume / 4 },
    { text: '75%', y: (fullVolume / 4) * 3 },
  ];
  const refLineLabel = `${
    Constants.STORAGE_GRAPH_COMMENTARY[activeDams[0]] ? '*' : ''
  }Accessible capacity`;

  const referenceLineLabel = Constants.TOTAL_STORAGE_MENINDEE_LAKES_FLAG
    ? (activeDams.length === 1 && Number(activeDams[0]) === Constants.MENINDEE_LAKES_TOTAL_ID) ||
      waterSource.water_source_id === Constants.LOWER_DARLING_REGULATED_RIVER_WATER_SOURCE
      ? 'Total capacity'
      : activeDams.length > 1 &&
        [Constants.MURRAY_REGULATED_RIVER_WATER_SOURCE].includes(waterSource.water_source_id)
      ? 'Storage capacity'
      : refLineLabel
    : refLineLabel;

  const renderReferenceLineLabel = (v, text = refLineLabel) => {
    const { viewBox } = v;
    let x = viewBox.width / 2;
    if (isMobile) {
      x = viewBox.width / 4;
    } else if (isGreaterSydney) {
      x = viewBox.width / 5;
    }
    const y = viewBox.y + 20;
    return (
      <text className="svg-reference-line-label" x={x} y={y}>
        {text}
      </text>
    );
  };

  const getTickValues = value => [value * 0.25, value * 0.5, value * 0.75, value];

  const renderCusomizedLegend = e => {
    return <ChartLegend payload={e.payload} legendType="square" />;
  };

  const formatTooltip = (value, name, item) => {
    if (
      item.payload &&
      item.payload.volume &&
      item.payload.volume_forecast &&
      item.payload.volume === item.payload.volume_forecast
    ) {
      if (name.includes('forecast')) {
        return [];
      }
    }
    return [formatNumberDecimals(Number(value / 1000)) + ' GL', name];
  };

  return (
    <ChartResponsiveContainer
      customHeight={{ desktop: 450, mobile: 350 }}
      isLoading={isLoading}
      isEmptyChart={isEmpty(data)}
      commentary={Constants.STORAGE_GRAPH_COMMENTARY[activeDams[0]]}
    >
      <ComposedChart
        className="composed-chart"
        width={800}
        data={data}
        margin={{
          top: isMobile ? 35 : 10,
          left: 20,
          bottom: 50,
          right: isMobile ? 10 : 30,
        }}
      >
        <Area
          connectNulls
          type="monotone"
          name="Volume"
          dataKey="volume"
          fill="#cccccc"
          stroke="#cccccc"
          yAxisId="left"
        />
        {fields.includes('inflow') && (
          <Bar
            name="Inflow"
            barSize={30}
            dataKey="inflow"
            fill="#0054a6"
            radius={[5, 5, 0, 0]}
            yAxisId={isDailySelected ? 'right' : 'left'}
          />
        )}
        {((fields.includes('release') && !isGreaterSydney) ||
          (fields.includes('environmental_release') && isGreaterSydney)) && (
          <Bar
            name={isGreaterSydney ? 'Environmental Release' : 'Release'}
            barSize={30}
            dataKey={isGreaterSydney ? 'environmental_release' : 'release'}
            fill={isGreaterSydney ? '#bed12a' : '#f36c21'}
            radius={[5, 5, 0, 0]}
            yAxisId={isDailySelected ? 'right' : 'left'}
          />
        )}
        {isDailySelected && (
          <Area
            type="monotone"
            name="Volume forecast"
            dataKey="volume_forecast"
            fill="#cccccc75"
            stroke="#cccccc75"
            strokeDasharray="5 5"
            yAxisId="left"
          />
        )}
        {!isBarwonDarling && isDailySelected && !isGreaterSydney && (
          <Bar
            name="Release forecast"
            barSize={30}
            dataKey="release_forecast"
            fill="#f36c2180"
            radius={[5, 5, 0, 0]}
            strokeDasharray="5 5"
            yAxisId="right"
          />
        )}
        <XAxis
          interval={
            isMobile
              ? 'preserveStartEnd'
              : isDailySelected
              ? 'preserveEnd'
              : Math.floor(yearOffset / 2)
          }
          dataKey={xAxisDateKey}
          tickFormatter={v =>
            isDailySelected ? moment(v).format('DD MMM') : moment(v).format('YYYY MMM')
          }
          angle={-60}
          dy={25}
          tick={{ fill: 'black' }}
        />
        <YAxis
          width={isMobile ? 15 : 60}
          angle={isMobile ? -60 : 0}
          label={isMobile ? {} : { value: 'Storage volume (GL)', angle: -90, dx: -35 }}
          interval="preserveEnd"
          domain={[0, maxData => maxData * 1.01]}
          tickFormatter={v => formatNumberDecimals(v / 1000, false, true)}
          type="number"
          tick={{ fill: 'black' }}
          ticks={getTickValues(fullVolume)}
          yAxisId="left"
          allowDuplicatedCategory={false}
        />
        {!isBarwonDarling && isDailySelected && (
          <YAxis
            width={isMobile ? 15 : 60}
            angle={isMobile ? -60 : 0}
            label={isMobile ? {} : { value: 'Storage release (GL)', angle: -90, dx: 15 }}
            interval="preserveStartEnd"
            domain={[0, 10000]}
            tickFormatter={v => formatNumberDecimals(v / 1000, false, true)}
            type="number"
            tick={{ fill: 'black' }}
            yAxisId="right"
            orientation="right"
          />
        )}
        <Legend wrapperStyle={{ bottom: isMobile ? 0 : -10 }} content={renderCusomizedLegend} />
        <Tooltip
          labelFormatter={v =>
            moment(v).isValid() && !isNumber(v)
              ? isDailySelected
                ? moment(v).format('DD MMMM YYYY')
                : moment(v).format('MMMM YYYY')
              : ''
          }
          formatter={(value, name, item) => formatTooltip(value, name, item)}
        />
        <ReferenceLine
          y={fullVolume}
          label={v => renderReferenceLineLabel(v, `${referenceLineLabel}`)}
          stroke="red"
          strokeDasharray="3 3"
          yAxisId="left"
        />
        {isGreaterSydney &&
          activeDams.length > 1 &&
          greaterSydneyRefLine.map((item, ind) => (
            <ReferenceLine
              key={ind}
              y={item.y}
              label={v => renderReferenceLineLabel(v, `${item.text} of Accessible capacity`)}
              stroke="red"
              strokeDasharray="3 3"
              yAxisId="left"
            />
          ))}
      </ComposedChart>
    </ChartResponsiveContainer>
  );
}

MonthlyStorageGraph.propTypes = {
  data: PropTypes.array,
  isDailySelected: PropTypes.bool,
  activeDams: PropTypes.array,
  fullVolume: PropTypes.number,
  yearOffset: PropTypes.any,
  xAxisDateKey: PropTypes.string,
};
