/* Library */
import React, { useState, useEffect, useContext, useRef } from 'react';
import moment from 'moment';
import { isEmpty, toString } from 'lodash';
import PropTypes from 'prop-types';

/* Styles */
import './storageChart.scss';

/* Component */
import SelectionBtns from './childProperties/SelectionBtns';
import SelectionBtnsGroup from './childProperties/SelectionBtnsGroup';
import StorageChartDropdown from './childProperties/StorageChartDropdown';
import ChartContainer from '../chartProperties/ChartContainer';
import DownloadLink from '../chartProperties/ChartCSVDownloadLink';
import MonthlyStorageGraph from './monthlyStorageGraphs/WaterDataMonthlyStorageGraph';
import MurrayMonthlyStorageGraph from './monthlyStorageGraphs/MurrayMonthlyStorageGraph';

/* Context */
import { AppContext } from '../../contexts/AppContext';
import { MobileContext } from '../../contexts/MobileContext';

/* Helpers */
import { Event } from '../../helpers/GAHelper';
import { getDatesByPeriod, getCurrentDate } from '../../helpers/TimeUtils';
import { generateUniformFields } from '../../helpers/WaterDataApiHelper';
import { formatData } from '../../helpers/WaterDataUtils';
import {
  getDamStorageForecastByWaterSource,
  getDamResourcesByWaterSourceId,
} from '../../helpers/ApiHelper';
import { sortArrayByKeyAscDateTime } from '../../helpers/Utils';
import { fetchChartDamStorageData } from './parserHelper';

/* Constant */
import Constants from '../../constants/Constants';
import config from '../../configs/featureToggleConfig.json';
const GENERAL_YEAR_LIST = ['1 WEEK', 1, 5, 10];
const DATE_FORMAT = Constants.STORAGE_DATE_FORMAT;
const totalStorageSite = { 12104: ['425049'] };
const getDataFromWaterDataApi = config['water-data-api'].dams.active;

export default function AverageMonthlyStorageRecent({ title, desc }) {
  const { isMobile } = useContext(MobileContext);
  const { waterSource } = useContext(AppContext);
  const wsId = waterSource.water_source_id;
  const endDate = moment().format('YYYY-MM-DD');
  const isMurray = wsId === Constants.MURRAY_REGULATED_RIVER_WATER_SOURCE;
  const isFishRiver = wsId === Constants.FISH_RIVER_WATER_SOURCE;
  const isGreaterSydney = wsId === Constants.GREATER_SYDNEY_WSID;
  const [chartData, setChartData] = useState([]);
  const [fullVolume, setFullVolume] = useState(waterSource.full_volume);
  const [yearOffset, setYearOffset] = useState(1);
  const [activeDams, setActiveDams] = useState(
    waterSource.dams ? waterSource.dams.map(dam => dam.station_id) : [],
  );
  const [activeDamData, setActiveDamData] = useState([]);
  const [damList, setDamList] = useState([]);
  const [loading, setLoading] = useState(false);
  const waterSourceId = useRef();
  const isDaily = yearOffset === GENERAL_YEAR_LIST[0];
  const VARIABLES_LIST = isGreaterSydney
    ? ['ActiveStorageVolume']
    : ['ActiveStorageVolume', 'StorageInflow', 'StorageReleases', 'TotalStorageVolume'];

  useEffect(() => {
    setLoading(true);
    let unmounted = false;
    setChartData([]);
    setActiveDamData([]);
    setFullVolume(waterSource.full_volume);
    calcFullVolume(activeDams);

    (async () => {
      let newChartData = {};
      if (getDataFromWaterDataApi && !isMurray) {
        const numOfMonths = { 1: 11, 5: 59, 10: 119, 30: 359 };
        const startDate = isDaily
          ? getDatesByPeriod(-6, DATE_FORMAT, 'days')
          : getDatesByPeriod(-numOfMonths[yearOffset], DATE_FORMAT, 'months');
        const apiEndDate = getCurrentDate(DATE_FORMAT);
        const interval = !isDaily ? 'monthly' : 'daily';
        const dataType = !isDaily ? 'Combined' : 'AutoQC';
        let chartDataRes = await fetchChartDamStorageData(
          waterSource.dams,
          interval,
          startDate,
          apiEndDate,
          VARIABLES_LIST.join(','),
          dataType,
          isGreaterSydney,
        );
        const forecast = isDaily ? await getDamStorageForecastByWaterSource(wsId) : {};

        await Promise.all(
          waterSource.dams.map(async dam => {
            const damId = dam.station_id;

            let formatted = formatData(chartDataRes, false, false, '', 'siteId', dam.dead_storage);

            const forecastData = forecast[dam.station_name];
            newChartData[damId] = generateUniformFields(formatted, damId, forecastData);

            if (Constants.SPECIAL_SITES.map(el => toString(el)).includes(toString(damId))) {
              const interval = isDaily ? 'daily' : null;
              const from = isDaily
                ? moment().subtract(7, 'days').format('YYYY-MM-DD')
                : moment().subtract(yearOffset, 'years').add(1, 'months').format('YYYY-MM-01');
              const damRes = await getDamResourcesByWaterSourceId(
                wsId,
                from,
                endDate,
                null,
                interval,
              );
              if (damRes && damRes.data) {
                const formattedDateRes = damRes.data[dam.station_name].map(el => {
                  return {
                    ...el,
                    siteId: el.dam_id,
                    timeStamp: moment(el.date, 'YYYY-MM').format('DD-MMM-YYYY 00:00'),
                  };
                });
                newChartData[damId] = formattedDateRes;
              }
            }
          }),
        );
      } else {
        const start = isDaily
          ? moment().subtract(7, 'days').format('YYYY-MM-DD')
          : moment().subtract(yearOffset, 'years').add(1, 'months').format('YYYY-MM-01');
        const interval = isDaily ? 'daily' : null;
        newChartData = await getDamResourcesByWaterSourceId(wsId, start, endDate, null, interval);
      }

      if (!unmounted) {
        setChartData(newChartData);
        setDamList(waterSource.dams.filter(item => item.station_id !== '4261022'));
        calcFullVolume(activeDams);
        if (waterSourceId.current && waterSourceId.current !== wsId) {
          setActiveDams(waterSource.dams ? waterSource.dams.map(dam => dam.station_id) : []);
        }
        setLoading(false);
        waterSourceId.current = wsId;
      }
    })();
    return function cleanUp() {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsId, yearOffset, endDate]);

  useEffect(() => {
    const activeData = isMurray
      ? dataParserForMurray(activeDams, chartData.data)
      : dataParser(activeDams, chartData);
    calcFullVolume(activeDams);
    setActiveDamData(activeData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsId, activeDams, chartData]);

  const getActiveDamData = data => {
    return Object.values(data).reduce((acc, val) => {
      if (Array.isArray(val)) {
        let matches = val.filter(item => item.dam_id === activeDams[0]);
        return acc.concat(matches);
      }
      return acc;
    }, []);
  };

  const dataParser = (activeDams, inputData) => {
    const activeDamList = totalStorageSite[wsId] || activeDams;
    let activeData;
    if (!isEmpty(inputData)) {
      const dateFormat = Constants.WATER_DATA_API_DATE_FORMAT;
      if (activeDamList.length > 1) {
        if (getDataFromWaterDataApi) {
          // Aggregate all dam data into one
          const allData = Object.values(inputData).reduce((prev, curr) => {
            const temp = [...prev];
            curr.forEach(chartDataRow => {
              let targetAggData = temp.find(
                aggItem => aggItem.timeStamp === chartDataRow.timeStamp,
              );
              if (targetAggData) {
                const variables = Object.keys(chartDataRow);
                variables.forEach(varItem => {
                  if (varItem !== 'siteId' && varItem !== 'timeStamp') {
                    const originalVal = targetAggData[varItem] || 0;
                    targetAggData[varItem] = chartDataRow[varItem] + originalVal;
                  }
                  delete targetAggData.siteId;
                });
              } else {
                // Push by deep copy
                delete chartDataRow.siteId;
                temp.push({ ...chartDataRow });
              }
            });
            return sortArrayByKeyAscDateTime(temp, 'timeStamp', dateFormat);
          }, []);
          activeData = allData;
        } else {
          activeData = inputData?.data?.all;
        }
      } else {
        if (getDataFromWaterDataApi) {
          let sorted = inputData[activeDams[0]];
          if (sorted) {
            sorted = sortArrayByKeyAscDateTime(sorted, 'timeStamp', dateFormat);
          }
          activeData = sorted;
        } else {
          activeData = getActiveDamData(inputData.data);
        }
      }
    }

    if (activeData) {
      const lastIndex = activeData.findIndex(item => typeof item.volume === 'undefined');
      if (activeData[lastIndex - 1]) {
        activeData[lastIndex - 1].volume_forecast = activeData[lastIndex - 1].volume;
      }
    }
    return activeData;
  };

  const dataParserForMurray = (activeDams, chartData) => {
    const result = {};
    for (const k in chartData) {
      result[k] = chartData[k].map(x => ({
        'storage.nsw': (x.volume_shares && x.volume_shares.NSW) || (x.volume && x.volume.NSW),
        'storage.vic': (x.volume_shares && x.volume_shares.VIC) || (x.volume && x.volume.VIC),
        'storage.sa': (x.volume_shares && x.volume_shares.SA) || (x.volume && x.volume.SA),
        ...x,
      }));
    }

    if (activeDams.length > 1) {
      if (result.all)
        result.all = result.all.filter(item => {
          return item['storage.nsw'] || item['storage.vic'] || item['storage.sa'];
        });
      return result.all;
    } else {
      return getActiveDamData(result);
    }
  };

  const onDamChange = selectedDams => {
    const activeDams = selectedDams.split(',');
    setActiveDams(activeDams);
    calcFullVolume(activeDams);
    Event(
      waterSource.water_source_name,
      `/storage - ` +
        waterSource.dams
          .filter(dam => activeDams.includes(dam.station_id))
          .map(dam => dam.station_name)
          .join(','),
      Constants.INTERACTION_TYPE.button,
    );
  };

  const calcFullVolume = dams => {
    if (dams.length > 1) {
      setFullVolume(waterSource.full_volume);
    } else {
      const wsDams = waterSource.dams.find(dam => dams.includes(dam.station_id));
      if (wsDams) {
        setFullVolume(wsDams.full_volume);
      }
    }
  };

  const onYearChange = offset => {
    setYearOffset(offset);
    const selected = isDaily ? offset : offset + ' year(s)';
    Event(
      waterSource.water_source_name,
      '/storage - interval selection: ' + selected,
      Constants.INTERACTION_TYPE.button,
    );
  };

  // Mini Component
  const damsSelectionBtn = !isEmpty(damList) && (
    <StorageChartDropdown dams={damList} onDamChange={onDamChange} selected={activeDams.join()} />
  );
  const yearSelectionBtn = (
    <SelectionBtns
      itemList={isMurray || isFishRiver ? [1, 10, 30] : GENERAL_YEAR_LIST}
      onBtnChange={onYearChange}
      defaultValue={yearOffset}
      type="year"
    />
  );

  return (
    <ChartContainer
      divId="AMSRecent"
      customHeight={{ desktop: 450, mobile: 350 }}
      pageTitle={title}
      graphTitle={title}
      graphIntro={desc}
    >
      {!isMobile && (
        <DownloadLink
          data={activeDamData}
          filename={activeDams.join('_')}
          stationType="dam"
          dataType={`monthly storage`}
        />
      )}
      <SelectionBtnsGroup left={damsSelectionBtn} right={yearSelectionBtn} customStyles={''} />
      {isMurray ? (
        <MurrayMonthlyStorageGraph
          data={activeDamData}
          activeDams={activeDams}
          fullVolume={fullVolume}
          isLoading={loading}
        />
      ) : (
        <MonthlyStorageGraph
          data={activeDamData}
          isDailySelected={isDaily}
          activeDams={activeDams}
          fullVolume={fullVolume}
          yearOffset={yearOffset}
          xAxisDateKey={getDataFromWaterDataApi ? 'timeStamp' : 'date'}
          isLoading={loading}
        />
      )}
    </ChartContainer>
  );
}

AverageMonthlyStorageRecent.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
};
