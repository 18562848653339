// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { Surface, Symbols } from 'recharts';

// Styles
import './allocationChartLegend.scss';

const container = 'allocation-chart-legend';

export default function AllocationChartLegend({ payload, header, isMobile }) {
  const content = payload.map(({ dataKey, color, payload }, index) => {
    return (
      color !== 'transparent' && (
        <span key={`${index} ' ' ${dataKey}`} className={`${container}-item`}>
          <Surface
            width={isMobile ? 15 : 20}
            height={isMobile ? 15 : 20}
            className={`${container}-item-surface`}
          >
            <Symbols cx={5} cy={1} type="square" size={!isMobile ? 10000 : 700} fill={color} />
          </Surface>
          <span className={`${container}-label`}>{payload.name}</span>
        </span>
      )
    );
  });

  return (
    <div className={container}>
      {header && header}
      {payload && content}
    </div>
  );
}

AllocationChartLegend.propTypes = {
  payload: PropTypes.array,
  header: PropTypes.any,
  isMobile: PropTypes.bool,
};
