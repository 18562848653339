// Library
import React, { useState, useEffect, useContext } from 'react';
import { isEmpty, isNaN } from 'lodash';
import moment from 'moment';

// Style
import './waterSiteGraph.scss';

// Context
import { MiniAppContext } from '../../contexts/MiniAppContext';

// Components
import DoubleArrowSVG from '../../image/icons/DoubleArrowSVG';
import DotSvg from './DotSvg';
import DottedLineSvg from './DottedLineSvg';
import WaterSiteInfoLineChart from './WaterSiteInfoLineChart';
import ViewDetailsLink from '../mapPopup/viewDetailsLink/ViewDetailsLink';
import Loader from '../loader/Loader';
import DataQualityText from '../generalComponents/dataQuality/DataQualityText';
import GeneralDropdown from '../dropDown/GeneralDropdown';

// Helper
import {
  formatDate,
  getMonthListByRange,
  getDatesByPeriod,
  getCurrentDate,
} from '../../helpers/TimeUtils';
import { formatNumberDecimals, toSentenceCase, mergeByDate } from '../../helpers/Utils';
import { getSurfaceWaterData } from '../../helpers/WaterDataApiHelper';
import { dataMapFromWaterDataToStation } from '../../helpers/WaterDataUtils';

// Constants
import constants from '../../constants/Constants';
import hydroDataConstants from '../../constants/HydrometricsConstants';
const TAB_OPTIONS = constants.TAB_OPTIONS;
const component = 'water-site-graph';

export default function RiverGaugeGraph({ setActiveHydroType, setHydros }) {
  const { station } = useContext(MiniAppContext);
  const { water_source_name, water_source_id, station_id } = station;
  const dataProps = hydroDataConstants.RIVER_PROPS[station.station_type].filter(item =>
    station.water_data_variables
      ? station.water_data_variables.includes(item.waterDataKey)
      : item.waterDataKey,
  );
  const DATA_PROPS = dataProps.map(element => {
    return { ...element, color: '#707070', hydroName: element.dataKey, displayName: element.name };
  });

  const INTERVALS = [
    ...(water_source_id && water_source_name ? [{ title: 'History', label: 'History' }] : []),
    { title: 'Daily data for period', label: '1W', days: 7, type: 'daily' },
    ...(station.station_type === 'gauge'
      ? [
          {
            title: 'Hourly data for the past 24 hours',
            label: '24H',
            days: 1,
            type: 'hourly',
          },
        ]
      : []),
  ];
  const [interval, setInterval] = useState(INTERVALS.find(item => item.label === '1W'));
  const [date, setDate] = useState('');
  const [activeDate, setActiveDate] = useState('');
  const [fullDateRange, setFullDateRange] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [data, setData] = useState();
  const [hydrometrics, setHydrometrics] = useState();
  const [activeHydrometric, setActiveHydrometric] = useState();
  const [graphData, setGraphData] = useState([]);
  const [shownGraphData, setShownGraphData] = useState([]);
  const [value, setValue] = useState({});
  const [hasForecast, setHasForecast] = useState(false);
  const [dateFormat, setDateFormat] = useState('DD-MM 09:00');
  const [shownDateRange, setShownDateRange] = useState([]);
  const [shownDateRangeIndex, setShownDateRangeIndex] = useState(null);
  const [hideLeftArrow, setHideLeftArrow] = useState(false);
  const [hideRightArrow, setHideRightArrow] = useState(false);
  const [maxValue, setMaxValue] = useState(0);
  const [minValue, setMinValue] = useState(0);
  const [showLoader, setShowLoader] = useState(false);

  const isSevenDays = interval.days === 7;
  const apiRespDateFormat = 'DD-MMM-YYYY HH:mm';
  const apiDateFormat = 'YYYY-MM-DD HH:mm:ss';

  // Life Cycle
  useEffect(() => {
    if (interval.days) {
      setDate(getDateRange(interval.days, interval.label));
      const inputDateFormat = isSevenDays ? 'DD-MM 09:00' : 'YYYY-MM-DD';
      let range = getMonthListByRange(
        isSevenDays ? interval.days : 144,
        new Date(),
        isSevenDays ? dateFormat : 'YYYY-MM-DD HH:00:00',
        isSevenDays ? 'day' : 'hours',
        inputDateFormat,
      );
      setFullDateRange(range);
      getData(range);
    } else {
      setActiveDate('');
      setDate('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interval, station.id]);

  useEffect(() => {
    !isEmpty(hydrometrics) && data && setActiveHydrometric(hydrometrics[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hydrometrics]);

  useEffect(() => {
    if (!isEmpty(activeHydrometric) && !isEmpty(data)) {
      processData(data, fullDateRange);
      setActiveHydroType(activeHydrometric.dataKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeHydrometric]);

  useEffect(() => {
    if (!isEmpty(dateRange)) {
      const dateIndex = dateRange.findIndex(
        item => item === formatDate(activeDate, apiRespDateFormat, dateFormat),
      );
      const newIndex = isSevenDays ? dateIndex : dateRange.length - 1;
      setShownDateRangeIndex(newIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  useEffect(() => {
    setHideLeftArrow(false);
    setHideRightArrow(false);
    let start = hasForecast ? shownDateRangeIndex - 3 : shownDateRangeIndex - 6;
    let end = hasForecast ? shownDateRangeIndex + 4 : shownDateRangeIndex + 2;
    if (start >= 0 && end - start >= 8) {
      end = end - 1;
    }
    const newDateRange = dateRange.slice(start >= 0 ? start : 0, end);
    setShownDateRange(newDateRange);
    const newGraphData = newDateRange.map(date => {
      const found = graphData.find(item => {
        const outputDateFormat = isSevenDays ? dateFormat : apiDateFormat;
        const formatted = formatDate(item.date, apiRespDateFormat, outputDateFormat);
        return formatted === date;
      });
      if (found) {
        return { ...found, date: date };
      } else {
        return { date: date };
      }
    });

    setShownGraphData(newGraphData);
    if (!isEmpty(newDateRange) && !isEmpty(dateRange)) {
      if (newDateRange[newDateRange.length - 1] === dateRange[dateRange.length - 1]) {
        setHideRightArrow(true);
      }
      if (newDateRange && newDateRange[0] === dateRange[0]) {
        setHideLeftArrow(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shownDateRangeIndex]);

  const getDateRange = (days, interval) => {
    const dateRangeFormat = 'DD MMMM YYYY';
    let today = formatDate(new Date(), 'YYYY-MM-DD', dateRangeFormat);
    let from = moment(today, dateRangeFormat).subtract(days, 'days').format(dateRangeFormat);
    if (interval === '24H') return today;
    return `${from} - ${today}`;
  };

  const formatLabel = text => {
    return text.toLowerCase() === 'ph' ? 'pH' : toSentenceCase(text);
  };

  const findAvailableHydrometrics = data => {
    const availableDataKeys = data.flatMap(item => Object.keys(item.rawData));
    let matchingDataProps = DATA_PROPS.filter(hydro =>
      availableDataKeys.includes(hydro.waterDataKey),
    );
    matchingDataProps = matchingDataProps.map(v => {
      return {
        ...v,
        name: formatLabel(v.name),
        decimals: hydroDataConstants.DATA_LABEL_FORMAT[v.dataKey]?.decimals,
      };
    });
    return matchingDataProps;
  };

  const updateValue = (value, active, prop) => {
    setValue({
      value: value / prop.division,
      unit: prop.unit,
      qualityCode: active[prop.dataKey]?.qualityCode,
      date: active[prop.dataKey]?.timeStamp,
      decimals: hydroDataConstants.DATA_LABEL_FORMAT[prop.dataKey]?.decimals,
    });
  };

  const activeItemByKey = (data, key, prop) => {
    const filteredByKey = data.filter(item => Object.prototype.hasOwnProperty.call(item, key));
    const active = filteredByKey[0];
    if (!isEmpty(active)) {
      updateValue(active[prop.dataKey]?.value, active, prop);
      setActiveDate(active.date);
    }
  };

  const getGraphMinMaxNum = data => {
    const values = data.map(item => item[activeHydrometric.dataKey]);
    setMaxValue(Math.ceil(Math.max.apply(Math, values)));
    setMinValue(-Math.ceil(-Math.min.apply(Math, values)));
  };

  const processData = (result, range) => {
    let dates = range.map(item => item.name);
    const lastIndexForecast = result.findIndex(
      item => typeof item.flow_rate_forecast !== 'undefined',
    );
    const hasForecastData = result.some(data =>
      Object.prototype.hasOwnProperty.call(data, 'flow_rate_forecast'),
    );
    setHasForecast(hasForecastData);

    if (result[lastIndexForecast]) {
      const forecast = result.slice(lastIndexForecast, result.length);
      const format = isSevenDays ? dateFormat : 'YYYY-MM-DD HH:00:00';
      const dateRangeEnd = getDatesByPeriod(interval.days, format);
      const forecastDates = forecast
        .filter(item =>
          isSevenDays
            ? item
            : moment(dateRangeEnd, 'YYYY-MM-DD').isAfter(moment(item.date, apiDateFormat)),
        )
        .map(item => formatDate(item.date, apiDateFormat, format));
      dates = dates.concat(forecastDates);
    }
    const filtered = result.filter(item => {
      const outputFormat = isSevenDays ? dateFormat : apiDateFormat;
      const formatted = formatDate(item.date, apiRespDateFormat, outputFormat);
      return dates.includes(formatted);
    });
    setGraphData(filtered);
    getGraphMinMaxNum(filtered);
    activeItemByKey(filtered, activeHydrometric.dataKey, activeHydrometric);
    setDateRange(dates);
  };

  const getData = async () => {
    setShowLoader(true);
    const fromDate = getDatesByPeriod(-7, constants.STORAGE_DATE_FORMAT, 'days');
    const toDate = getCurrentDate(constants.WATER_DATA_API_DATE_FORMAT);
    const variables = station.water_data_variables
      ? station.water_data_variables
      : Object.values(station.variablesMonitored);
    let records = await getSurfaceWaterData(
      station.siteId,
      interval.type,
      fromDate,
      toDate,
      variables.filter(item => !['Rainfall', 'Volume'].includes(item)).join(','),
      'Combined',
      true,
    );
    records = dataMapFromWaterDataToStation(mergeByDate(records, false, false));
    const hydros = findAvailableHydrometrics(records);
    setData(records);
    setHydrometrics(hydros);
    if (setHydros !== undefined) {
      setHydros(hydros);
    }
    setShowLoader(false);
  };

  const arrowOnClick = direction => {
    setShownDateRangeIndex(prevState => prevState + direction);
  };

  const onLineClicked = data => {
    if (data && data.activePayload && data.activePayload[0]) {
      const payload = data.activePayload[0].payload;
      const value =
        payload[activeHydrometric.dataKey]?.value ||
        payload[`${activeHydrometric.dataKey}_forecast`]?.value;
      updateValue(value, payload, activeHydrometric);
      setActiveDate(payload.date);
    }
  };

  const onToggleClick = item => {
    setActiveHydrometric(item);
    activeItemByKey(graphData, item.dataKey, item);
  };

  const onIntervalChanged = item => {
    setInterval(item);
    setShownDateRangeIndex(0);
    setActiveDate('');
    setDateRange([]);
    setValue({});
    if (item.days === 7) {
      setDateFormat('DD-MM 09:00');
    } else if (item.days === 1) {
      setDateFormat('DD-MM HH:mm');
    }
  };

  // mini component
  const fullSiteMsg = () => {
    const riverDataTab =
      station.station_type === 'gauge' ? TAB_OPTIONS.riverData : TAB_OPTIONS.storage;
    return (
      <div className={`${component}-content`}>
        <div className={`${component}-message`}>
          <span>
            {`For more historical data, please visit the `}
            <ViewDetailsLink
              elementId={station_id}
              label="full site"
              targetWaterSource={{
                water_source_id: water_source_id,
                water_source_name: water_source_name,
              }}
              pathname={`${riverDataTab.link}`}
            />
          </span>
        </div>
      </div>
    );
  };

  const shownDates = (date, index) => {
    const format = interval.days === 1 ? apiDateFormat : dateFormat;
    return (
      <div
        key={index}
        className={`${component}-gauge-date-item${date === activeDate ? '-selected' : ''}`}
      >
        <div>{formatDate(date, format, dateFormat.split(' ')[0])}</div>
        {dateFormat.split(' ')[1] && (
          <div>{formatDate(date, format, dateFormat.split(' ')[1])}</div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className={component}>
        <div className={`${component}-gauge-title`}>{interval.title}</div>
        <div className={`${component}-date-range`}>{date}</div>
        {interval.label !== 'History' ? (
          <>
            {!isEmpty(hydrometrics) && (
              <GeneralDropdown
                menuItem={hydrometrics}
                selectedItem={activeHydrometric}
                onItemClick={item => onToggleClick(item)}
              />
            )}
            <div className={`${component}-content`}>
              {showLoader && <Loader width={'10px'} height={'40px'} />}
              {!isEmpty(graphData) && !showLoader ? (
                <>
                  <div className={`${component}-subtitle`}>
                    <div>
                      <DotSvg fillColor={activeHydrometric.color} />
                      <span>{formatLabel(activeHydrometric.name)}</span>
                    </div>
                    {hasForecast && (
                      <div>
                        <DottedLineSvg strokeColor={activeHydrometric.color} />
                        <span>Forecast available</span>
                      </div>
                    )}
                  </div>
                  {!isEmpty(value) && (
                    <div className={`${component}-volume`}>
                      <DataQualityText qualityCode={value.qualityCode}>
                        {`${
                          !isNaN(value.value)
                            ? `${formatNumberDecimals(value.value, false, false, value.decimals)} ${
                                value.unit
                              }`
                            : '-'
                        }`}
                      </DataQualityText>
                      {!isNaN(value.value) && (
                        <div className={`${component}-volume-date`}>{`as at ${moment(
                          value.date,
                          apiRespDateFormat,
                        ).format(dateFormat)}`}</div>
                      )}
                    </div>
                  )}
                  {!isEmpty(shownDateRange) && (
                    <div className={`${component}-gauge-date`}>
                      <div onClick={!hideLeftArrow ? () => arrowOnClick(-1) : () => {}}>
                        <DoubleArrowSVG
                          customArrowClass={`${component}-arrow-left ${
                            hideLeftArrow ? `${component}-arrow-disabled` : ''
                          }`}
                        />
                      </div>
                      {shownDateRange.map((date, index) => shownDates(date, index))}
                      <div onClick={!hideRightArrow ? () => arrowOnClick(1) : () => {}}>
                        <DoubleArrowSVG
                          customArrowClass={`${component}-arrow-right ${
                            hideRightArrow ? `${component}-arrow-disabled` : ''
                          }`}
                        />
                      </div>
                    </div>
                  )}
                  <div className={`${component}-graph`}>
                    {!isEmpty(shownGraphData) && (
                      <WaterSiteInfoLineChart
                        margin={{
                          right: isSevenDays ? 45 : 40,
                          left: isSevenDays ? 45 : 40,
                          top: 15,
                          bottom: 15,
                        }}
                        data={shownGraphData}
                        forecastData={shownGraphData}
                        chartProps={{ [activeHydrometric.dataKey]: activeHydrometric }}
                        onLineClicked={onLineClicked}
                        unit={activeHydrometric.unit}
                        division={activeHydrometric.division}
                        yAxisDomain={[minValue, maxValue]}
                        activeValue={value}
                        decimals={activeHydrometric.decimals}
                      />
                    )}
                  </div>
                </>
              ) : (
                !showLoader && (
                  <div className={`${component}-no-data`}>
                    {`No ${interval.type} data available for ${date}`}
                  </div>
                )
              )}
            </div>
          </>
        ) : (
          fullSiteMsg()
        )}
      </div>
      <div className={`${component}-interval`}>
        {INTERVALS.map((item, index) => (
          <div
            key={index}
            className={interval.label === item.label ? `${component}-interval-selected` : ''}
            onClick={() => onIntervalChanged(item)}
          >
            {item.label}
          </div>
        ))}
      </div>
      <div className={`${component}-gap`} />
    </>
  );
}
