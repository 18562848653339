// Library
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, cloneDeep } from 'lodash';

// Styles
import './restrictions.scss';

// Context
import { MobileContext } from '../../contexts/MobileContext';

// Component
import UpdateItem from '../updates/UpdateItem';
import ExpandBox from '../generalComponents/expandBox/ExpandBox';
import ExternalLinkModal from '../../components/modal/ExternalLinkModal';
import ArrowSVG from '../../image/icons/ArrowSVG';

const container = 'restrictions-body';
export default function Restrictions({ restrictions, page, component, partialHide = false }) {
  const { isMobile } = useContext(MobileContext);
  const [externalSiteLink, setExternalSiteLink] = useState('');
  const [defaultShown, setDefaultShown] = useState([]);
  const [defaultHidden, setDefaultHidden] = useState([]);
  const [expand, setExpand] = useState(false);

  // Life Cycle
  useEffect(() => {
    if (!isMobile && partialHide && restrictions.length > 2) {
      const copiedRestrictions = cloneDeep(restrictions);
      const tempForDefaultShown = copiedRestrictions.slice(0, 2);
      const tempForDefaultHidden = copiedRestrictions.slice(2);
      setDefaultShown(tempForDefaultShown);
      setDefaultHidden(tempForDefaultHidden);
    } else {
      setDefaultShown(restrictions);
    }
    return () => {
      setDefaultShown([]);
      setDefaultHidden([]);
    };
  }, [partialHide, restrictions, isMobile]);

  // Component
  const restrictionHeader = () => {
    return <div className={`${container}-container`}>Temporary Restrictions</div>;
  };

  const onExternalSiteClick = link => {
    setExternalSiteLink(link);
  };

  const expandList = () => {
    return (
      <div
        className={`${container}-expand-link`}
        onClick={() => setExpand(prevstate => !prevstate)}
      >
        <div className={`${container}-expand-link-text`}>
          {expand ? 'View less temporary restrictions' : 'View more temporary restrictions'}
        </div>
        <div className={`${container}-expand-link-arrow-container`}>
          <ArrowSVG
            customArrowColor={`${container}-expand-link-arrow ${
              expand && `${container}-expand-link-arrow-expand`
            }`}
          />
        </div>
      </div>
    );
  };

  const restrictionItem = (restriction, index) => {
    return (
      <UpdateItem
        key={index}
        page={page || 'restrictions'}
        component={component}
        onExternalSiteClick={onExternalSiteClick}
        waterSource={{
          water_source_id: restriction.water_source_id,
          water_source_name: restriction.water_source_name,
          water_source_type: ['REG'],
        }}
        type={restriction.type}
        title={restriction.title}
        date={restriction.value_date || restriction.date}
        expiryDate={restriction.expiry_date}
        content={restriction.body}
        link={restriction.url}
        linkTitle="See Rules"
        targetId="Restrictions"
        contentWordsCount={partialHide ? 40 : -1}
        shortenBody={false}
      />
    );
  };

  return (
    <ExpandBox
      customHeaderContent={restrictionHeader()}
      customHeaderContainerStyle={`${container}-header`}
      mobileOnly
      customArrowColor={`${container}-arrow`}
    >
      <div className={`${container}`}>
        {!isEmpty(defaultShown) ? (
          defaultShown.map((restriction, index) => restrictionItem(restriction, index))
        ) : (
          <div className={`${container}-null`}>There are no current restrictions.</div>
        )}
        {!isEmpty(defaultHidden) &&
          expand &&
          defaultHidden.map((restriction, index) => restrictionItem(restriction, index))}
        {!isEmpty(defaultHidden) && expandList()}
        <ExternalLinkModal link={externalSiteLink} modalId="externalLinkNSWUpdatesRestrictions" />
      </div>
    </ExpandBox>
  );
}

Restrictions.propTypes = {
  component: PropTypes.string,
  restrictions: PropTypes.array,
  page: PropTypes.string,
  partialHide: PropTypes.bool,
};
