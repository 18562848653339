// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Modal from './Modal';

export default function ExternalLinkModal({ link, modalId }) {
  const modalFooter = () => {
    return (
      <>
        <div data-dismiss="modal" aria-label="Close">
          Go back
        </div>
        <div onClick={() => window.open(link, '_blank')} data-dismiss="modal">
          Continue
        </div>
      </>
    );
  };

  return (
    <Modal
      id={modalId}
      title={'New tab'}
      body={'This link will open in a new tab'}
      footer={modalFooter()}
      footerClassName="external-sites-modal-footer"
    />
  );
}

ExternalLinkModal.propTypes = {
  link: PropTypes.string,
  modalId: PropTypes.string,
};
