// Library
import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';

// Style
import './availabilityOverview.scss';

// Component
import DataSummaryBar from '../../dataSummary/DataSummaryBar';
import StateOverviewTable from './StateOverviewTable';

// Constant
const container = 'state-overview-availability';
const AVAILABLE = 'available_allocation';
const USED = 'used_allocation';
const text = {
  [AVAILABLE]: {
    total: 'Available',
    'surface_water.regulated': 'Reg. surface',
    'surface_water.unregulated': 'Unreg. surface',
    groundwater: 'Groundwater',
  },
  [USED]: {
    total: 'Used',
    'surface_water.regulated': 'Reg. surface',
    'surface_water.unregulated': 'Unreg. surface',
    groundwater: 'Groundwater',
  },
};

const colors = {
  total: 'black',
  'surface_water.regulated': '#3e7a95',
  'surface_water.unregulated': '#3e7a95',
  groundwater: '#f36c21',
};

const columns = [
  {
    type: 'rowHeader',
    text: 'rowHeader',
    hide: true,
  },
  {
    type: 'volume',
    text: 'Volume (GL)',
  },
];

const AvailabilityOverview = ({ data }) => {
  // Helper
  const calcMaxLength = valueData => {
    if (isNil(valueData)) return 0;
    return valueData[USED].total >= valueData[AVAILABLE].total
      ? valueData[USED].total
      : valueData[AVAILABLE].total;
  };

  // Component
  const rowMaker = (rowData, type) => {
    const curText = text[type];
    const curData = rowData[type];
    return [
      Object.keys(curText).map(key => {
        return (
          <span key={key} className={`${container}-header`} style={{ color: colors[key] }}>
            {curText[key]}
          </span>
        );
      }),
      Object.keys(curText).map(key => {
        return curData[key] / 1000;
      }),
    ];
  };

  const barMaker = (barData, type) => {
    const curText = text[type];
    const curData = barData[type];

    return Object.keys(curText).reduce((acc, key) => {
      if (key !== 'total') {
        acc.push({
          type: curText[key],
          value: curData[key],
          color: colors[key],
        });
      }
      return acc;
    }, []);
  };

  const maxLength = calcMaxLength(data);

  return (
    <div className={container}>
      {!isNil(data) && (
        <>
          <div>
            <StateOverviewTable columns={columns} rows={rowMaker(data, USED)} />
            <DataSummaryBar bars={barMaker(data, USED)} maximum={maxLength} noBorder />
          </div>
          <div>
            <StateOverviewTable columns={columns} rows={rowMaker(data, AVAILABLE)} />
            <DataSummaryBar bars={barMaker(data, AVAILABLE)} maximum={maxLength} noBorder />
          </div>
        </>
      )}
    </div>
  );
};

export default AvailabilityOverview;

AvailabilityOverview.propTypes = {
  data: PropTypes.shape({
    total_allocation: PropTypes.number,
    total_available_allocation: PropTypes.number,
    surface_water: PropTypes.number,
    groundwater: PropTypes.number,
  }),
};
