// Libraries
import React, { useEffect, useState, useContext } from 'react';
import moment from 'moment';
import { isEmpty, toString } from 'lodash';
import util from 'util';
import PropTypes from 'prop-types';

// Styles
import './MapPopup.scss';

// Helpers
import { getStationDataByStationId } from '../../helpers/ApiHelper';
import { getLatestSurfaceWaterData } from '../../helpers/WaterDataApiHelper';
import { formatPopupContentNumber } from '../../helpers/Utils';
import { findLatestItemIndex } from '../../helpers/ArrayUtils';

// Component
import ViewDetailsLink from './viewDetailsLink/ViewDetailsLink';
import DataQualityText from '../generalComponents/dataQuality/DataQualityText';

//Context
import { MapContext } from '../../contexts/MapContext';

// Constants
import constants from '../../constants/Constants';
import config from '../../configs/featureToggleConfig.json';
import apiConstant from '../../constants/WaterDataAPIConstants';

const { storage } = constants.TAB_OPTIONS;
const getDataFromWaterDataApi = config['water-data-api'].dams.active;
const inputDateFormat = getDataFromWaterDataApi ? 'DD-MMM-YYYY HH:mm' : 'YYYY-MM-DD';
const outputDateFormat = getDataFromWaterDataApi ? 'DD MMMM YYYY h:mma' : 'DD MMMM YYYY 9:mma';
const component = 'map-popup';

export default function DamStoragePopup(props) {
  //Context
  const { markerSelected } = useContext(MapContext);

  //State
  const [data, setData] = useState(Array);
  const [dataStatusMsg, setDataStatusMsg] = useState('Loading...');
  const [initialised, setInitialised] = useState(false);

  // Content
  const itemTitle = {
    volume: { title: 'Volume', unit: 'GL' },
    volume_perc: { title: 'Percent full', unit: '%' },
    inflow: { title: 'Inflow', unit: 'GL' },
    release: { title: 'Release', unit: 'GL' },
    siteId: { title: 'Site Id', unit: '', blackListed: true },
  };
  const blackListOfPropertyKeys = Object.keys(itemTitle).filter(
    item => itemTitle[item].blackListed,
  );

  useEffect(() => {
    const siteId = props.data.station_id;
    const variables = apiConstant.DAM_STORAGE_VARIABLES.join(',') + ',TotalStorageVolume';
    if (!initialised || (props.openPopup && markerSelected)) {
      (async () => {
        try {
          setDataStatusMsg('Loading...');
          const result =
            getDataFromWaterDataApi && !constants.SPECIAL_SITES.includes(siteId)
              ? await getLatestSurfaceWaterData(toString(props.data.id), props.type, variables)
              : await getStationDataByStationId(
                  util.format(constants.STATION_DAM_SUMMARY, siteId),
                  'dams',
                );
          if (!isEmpty(result)) {
            let processedResult = Object.entries(result[0]).reduce((acc, [key, value]) => {
              const latestKey = findLatestItemIndex(result, key, null);
              const isLatest = getDataFromWaterDataApi
                ? value.timeStamp
                  ? value.timeStamp === result[0].date
                  : false
                : latestKey === 0;
              const latestItem = result[latestKey];
              if (key === 'date') {
                acc[key] = value;
              } else {
                acc[key] = {
                  value: latestItem ? latestItem[key] : '-',
                  isLatest: isLatest,
                };
              }
              return acc;
            }, {});

            if (!processedResult.volume_perc) {
              const volumeData = processedResult.volume.value;
              processedResult.volume_perc = {
                isLatest: processedResult.volume.isLatest,
                value: {
                  value: (volumeData.value / props.data.full_volume) * 100,
                  qualityCode: volumeData.qualityCode,
                  timeStamp: volumeData.timeStamp,
                },
              };
            }
            processedResult = Object.keys(processedResult)
              .sort()
              .reverse()
              .reduce((obj, key) => {
                obj[key] = processedResult[key];
                return obj;
              }, {});
            setData(processedResult);
            setInitialised(true);
            setDataStatusMsg('');
          } else {
            setDataStatusMsg('No data is currently available for this dam.');
          }
        } catch (error) {
          console.error(error);
          setDataStatusMsg('No data is currently available for this dam.');
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openPopup, props.timestamp]);

  return (
    <div className={`${component}-container`}>
      <div className={`${component}-body-title`}>{props.data.station_name}</div>
      {dataStatusMsg}
      {!isEmpty(data) && (
        <>
          {Object.entries(data).map((item, index) => {
            const itemProp = item[1]?.value;
            const dataValue = getDataFromWaterDataApi ? itemProp?.value : itemProp;
            return (
              item[0] !== 'date' &&
              !blackListOfPropertyKeys.includes(item[0]) && (
                <div className="row station-data-popup-body" key={index}>
                  <div className="col-6">{itemTitle[item[0]].title}</div>
                  <div className={`col-3 ${component}-value`}>
                    <DataQualityText qualityCode={itemProp?.qualityCode} isMarked={false}>
                      {`${
                        dataValue !== '-' && (dataValue !== null || dataValue === 0)
                          ? `${formatPopupContentNumber(dataValue, item[0])}`
                          : '-'
                      }`}
                    </DataQualityText>
                  </div>
                  <div className={`col-2 ${component}-unit`}>
                    <DataQualityText qualityCode={itemProp?.qualityCode} retainOriginal>
                      {dataValue !== '-' && (dataValue !== null || dataValue === 0)
                        ? itemTitle[item[0]].unit
                        : ''}
                      &nbsp;
                      {item[1].isLatest && item[1].value !== '-' ? '*' : ''}
                    </DataQualityText>
                  </div>
                </div>
              )
            );
          })}
          <div className={`${component}-body-timestamp`}>
            *Updated: {moment(data.date, inputDateFormat).format(outputDateFormat)} AEST
          </div>
        </>
      )}
      <div className={`${component}-view-details`}>
        <ViewDetailsLink
          elementId={'AMSRecent'}
          activeTab={storage.text}
          label={'View history'}
          pathname={`${storage.link}`}
          targetWaterSource={props.data.water_source}
        />
      </div>
    </div>
  );
}

DamStoragePopup.propTypes = {
  setShowSpinner: PropTypes.func,
  data: PropTypes.shape({
    station_id: PropTypes.any,
    station_name: PropTypes.string,
    water_source: PropTypes.object,
  }),
  markerRef: PropTypes.object,
  timestamp: PropTypes.string,
  openPopup: PropTypes.bool,
};
