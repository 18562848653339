// Libraries
import React from 'react';
import { isEmpty } from 'lodash';

// Components
import ScrollToComponent from '../components/rules/ScrollToComponent';

// Constant
import constants from '../constants/Constants';
const { SCREEN_SM } = constants;

const { PageView } = require('./GAHelper');
const PAGES_LIST = [
  'nsw-update',
  'archived-updates',
  'water-sharing-plans',
  'snapshot',
  'glossary',
  'site-error',
  'introduction',
  'search',
  'm',
  'favourites',
  'download',
  'my-alert',
  'my-request',
];

/**
 *
 * @param {*} waterSource
 * @param {*} page
 */
export const buildUrl = (waterSource, page) => {
  return `/${waterSource}${page}`;
};

/**
 *
 * @param {*} str
 */
const stripTrailingSlash = str => {
  return str && (str.endsWith('/') || str.endsWith('-')) ? str.slice(0, -1) : str;
};

/**
 *
 * @param {*} waterSource
 */
export const getPathname = waterSource => {
  return `${waterSource
    .toLowerCase()
    .replace(/['/']/g, '')
    .replace(' rivers', '')
    .replace(' Rivers', '')
    .split(' ')
    .join('-')}`;
};

export const checkPathname = (history, waterSource) => {
  const curPathname = history.location.pathname.split('/')[1];

  // Clear the preference store in local storage for users who start all over again
  localStorage.removeItem('preference');

  PageView(window.location.pathname + window.location.search);
  if (PAGES_LIST.includes(curPathname)) {
    if (curPathname === 'm' && window.innerWidth < SCREEN_SM) {
      localStorage.setItem('preference', 'mini-site');
    }
    localStorage.removeItem('curWaterSource');
  } else {
    if (curPathname !== '') {
      waterSource = waterSourceReplacementInURL(curPathname, waterSource, history.location.hash);
    }
    if (!isEmpty(waterSource)) {
      returnPathWithoutLastSlash(waterSource, history.location.hash);
      //After returning the URL without the last slash, we build the new URL
      //with the default tab updates or the one is specified in the URL
      buildURLSecondNavigation(curPathname, waterSource, history.location.hash);
    } else {
      if (curPathname !== '') {
        waterSource = waterSourceReplacementInURL(curPathname, waterSource, history.location.hash);
      }
      if (!isEmpty(waterSource)) {
        returnPathWithoutLastSlash(waterSource, history.location.hash);
        //After returning the URL without the last slash, we build the new URL
        //with the default tab updates or the one is specified in the URL
        buildURLSecondNavigation(curPathname, waterSource, history.location.hash);
      } else {
        //Return the home page directory
        window.history.pushState(null, null, '/');
      }
    }
  }
};

export const waterSourceReplacementInURL = (curPathname, waterSource, hash) => {
  const waterSourceList = JSON.parse(localStorage.getItem('waterSourceList'));
  let pathWaterSource = curPathname.split('-')[0]; //Split and get the first value which it would be the waterSource id
  pathWaterSource =
    !isEmpty(waterSourceList) &&
    waterSourceList.find(i => Number(i.water_source_id) === Number(pathWaterSource));
  if (typeof pathWaterSource !== 'undefined') {
    waterSource = pathWaterSource;
    if (hash) {
      window.location.hash = hash;
    }
    window.location.pathname.replace(
      curPathname,
      getPathname(waterSource.water_source_id + ' ' + waterSource.water_source_name),
    );
  }
  return waterSource;
};

export const returnPathWithoutLastSlash = (waterSource, hash) => {
  if (window.location.pathname[window.location.pathname.length - 1] === '/') {
    // to remove the last '/' from url
    let newPathname = stripTrailingSlash(window.location.pathname);
    window.history.pushState(null, null, newPathname);
  }
  if (hash) {
    window.location.hash = hash;
  }
  return window.location.pathname.replace(
    window.location.pathname,
    getPathname(waterSource.water_source_id + ' ' + waterSource.water_source_name),
  );
};

export const buildURLSecondNavigation = (curPathname, waterSource, hash) => {
  const pathWaterSourceURL = buildUrl(
    getPathname(waterSource.water_source_id + ' ' + waterSource.water_source_name),
    '/',
  );
  if (
    window.location.pathname.split('/').length < 2 ||
    typeof window.location.pathname.split('/')[2] === 'undefined'
  ) {
    if (window.location.pathname !== pathWaterSourceURL) {
      if (curPathname !== pathWaterSourceURL) {
        if (hash) {
          window.location.hash = hash;
        }
        window.location.pathname.replace(
          window.location.pathname.split('/')[1],
          getPathname(waterSource.water_source_id + ' ' + waterSource.water_source_name),
        );
        window.history.pushState(null, null, pathWaterSourceURL + 'updates');
      }
    }
  } else {
    window.history.pushState(
      null,
      null,
      pathWaterSourceURL + window.location.pathname.split('/')[2] + `${hash ? hash : ''}`,
    );
  }
  localStorage.setItem('curWaterSource', JSON.stringify(waterSource));
};

export const createTarget = (setTarget, SECTIONS, location) => {
  const trimmedLocation = formatLocation(location);
  const targetLocation = SECTIONS.find(section => {
    return section.replace('&', 'and') === trimmedLocation;
  });

  // enable pass by reference
  setTarget(
    { key: targetLocation ? targetLocation.replace('&', 'and') : null } || {
      key: null,
    },
  );
};

export const formatLocation = loc => {
  const replaceTarget = /#/gi;
  return loc.replace(replaceTarget, '').toLowerCase();
};

export const scrollToFirstMatch = (loc, targetEl) => {
  loc.key = null;
  return <ScrollToComponent target={targetEl} />;
};

export const isMiniApp = () => {
  return (
    window.location.pathname.split('/')[1] === 'm' ||
    window.location.pathname.split('/')[1] === 'favourites' ||
    window.location.pathname.split('/')[1] === 'sites'
  );
};
