// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Styles
import './pageBodyContainer.scss';

// Components
import PageTop from '../../../components/pageTop/PageTop';

// Constant
import text from '../../../abstract/IntroText.json';
const component = 'user-body-container';

export default function PageBodyContainer({ children, selected }) {
  const textObj = text[selected];
  const MENU_ITEM = [
    { id: 'alert', label: 'My Alerts', link: '/my-alert' },
    { id: 'request', label: 'My Requests', link: '/my-request' },
  ];

  return (
    <div className={component}>
      {/* display: none when in mini version */}
      <div className={`${component}-left`}>
        {MENU_ITEM.map((item, index) => (
          <div
            key={index}
            className={`${component}-left-item ${selected === item.id ? 'selected' : ''}`}
          >
            <Link to={item.link}>{item.label}</Link>
          </div>
        ))}
      </div>
      <div className={`${component}-right`}>
        <PageTop title={textObj.title} intro={textObj.intro} subIntro={textObj.subIntro} />
        {children}
      </div>
    </div>
  );
}

PageBodyContainer.propTypes = {
  children: PropTypes.any,
  selected: PropTypes.string,
};
