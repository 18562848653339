// Libraries
import React, { useState, useContext, useEffect } from 'react';
import { isEmpty } from 'lodash';

// Styles
import './body.scss';
import '../grid.scss';

// Context
import { AppContext } from '../../contexts/AppContext';
import { MobileContext } from '../../contexts/MobileContext';

// Components
import RulesBody from '../../components/rules/Rules';
import BodyContainer from './BodyContainer';
import InfoIconAndModal from '../../components/informationButton/InfoIconAndModal';
import ExternalLinkModal from '../../components/modal/ExternalLinkModal';
import RulesLink from '../../components/rules/RulesLink';

// Helpers
import { getConditionsWaterSource } from '../../helpers/ApiHelper.jsx';
import { getRestrictionsFromWaterSource } from '../../helpers/UpdatesApiHelper.jsx';

// Assets
import rulesModal from '../../components/modal/modalText/RulesTitlesModalText.json';

// Constant
const container = 'rules-container';

export default function Rules() {
  const { waterSource } = useContext(AppContext);
  const { isMobile } = useContext(MobileContext);
  const [conditionsData, setConditionsData] = useState([]);
  const [restrictionsData, setRestrictionsData] = useState([]);
  const [externalSiteLink, setExternalSiteLink] = useState('');
  const { modal } = rulesModal;

  // Life Cycle
  useEffect(() => {
    getConditionsWaterSource(waterSource, setConditionsData);
    getRestrictionsFromWaterSource(waterSource.water_source_id, setRestrictionsData);
  }, [waterSource]);

  // Component
  const linkComponent = () => {
    return (
      <>
        <RulesLink
          link={conditionsData.water_sharing_plan_summary_url}
          linkText=" Rules Summary Sheet"
          text="A summary of the rules applying to this water source is available in the"
          setExternalSiteLink={setExternalSiteLink}
          targetId="#externalLinkRules"
        />
        <RulesLink
          link={conditionsData.water_sharing_plan_url}
          linkText={conditionsData.water_sharing_plan_name}
          text="You can read the complete <a className='drought-body-link' href='/glossary#water-sharing-plan'> water sharing plan</a> for the "
          extraInfo="You can read more about water sharing plans <a className='' href='https://www.industry.nsw.gov.au/water/plans-programs/water-sharing-plans/how-water-sharing-plans-work' target='_blank' rel='noopener noreferrer'> here</a>."
          setExternalSiteLink={setExternalSiteLink}
          targetId="#externalLinkRules"
        />
      </>
    );
  };

  return (
    <BodyContainer
      pageTitle={
        <div className={`${container}-pageTop`}>
          <span>
            {`${waterSource.water_source_name}`}
            &nbsp;
            <InfoIconAndModal
              modalId="rulesTitleModal"
              modalContent={modal}
              lastWord={<span className={'page-body-title'}>RULES &nbsp;</span>}
            />
          </span>
        </div>
      }
    >
      {!isEmpty(conditionsData) && (
        <>
          <div className="rules-intro-text">{linkComponent()}</div>
        </>
      )}
      <RulesBody restrictionsData={restrictionsData} conditionsData={conditionsData} />
      {isMobile && <ExternalLinkModal link={externalSiteLink} modalId="externalLinkRules" />}
    </BodyContainer>
  );
}
