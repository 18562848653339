// Library
import React, { useState, useEffect } from 'react';
import { groupBy, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

// Style
import './favourites.scss';

// Components
import ArrowSVG from '../../image/icons/ArrowSVG';
import TrashIcon from '../../image/icons/trashIcon.png';
import SiteLink from '../siteLink/SiteLink';

// Constants
const component = 'favourites';

export default function Favourites({ editable = true, page = false }) {
  const localFavList = localStorage.getItem('favouritesList');
  const [favouritesList, setFavouritesList] = useState(
    localFavList && localFavList !== 'undefined' ? JSON.parse(localFavList) : undefined,
  );
  const [groupedFavList, setGroupedFavList] = useState([]);
  const [editMode, setEditMode] = useState(false);

  // Life Cycle
  useEffect(() => {
    const grouped = groupBy(favouritesList, 'station_type');
    setGroupedFavList(grouped);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onToggleDeleteFavItem = item => {
    const newFavList = favouritesList.filter(element => element.station_id !== item.station_id);
    const favouriteListGrouped = groupBy(newFavList, 'station_type');
    setGroupedFavList(favouriteListGrouped);
    localStorage.setItem('favouritesList', JSON.stringify(newFavList));
    setFavouritesList(newFavList);
  };

  const listButton = item => {
    return editMode ? (
      <button
        className={`${component}-list-item-remove-button`}
        onClick={event => {
          event.preventDefault();
          onToggleDeleteFavItem(item);
        }}
      >
        <div className={`${component}-list-item-remove-icon`}>
          <img src={TrashIcon} alt="Remove icon" />
        </div>
      </button>
    ) : (
      <button className={`${component}-list-item-button`}>
        <ArrowSVG customArrowColor={`${component}-list-item-arrow`} />
      </button>
    );
  };

  return (
    <div className={`${component} ${page ? `${component}-page` : ''}`}>
      <div className={`${component}-header col-sm-5`}>
        <div className={`${component}-title col-sm-3`}>Favourites</div>
        {editable && (
          <div className={`${component}-subheader-right col-sm-2`}>
            <button
              className={`${component}-button`}
              onClick={() => {
                setEditMode(!editMode);
              }}
            >
              {!editMode ? 'Edit' : 'Done'}
            </button>
          </div>
        )}
      </div>
      {page ? (
        <div className={`${component}-list`}>
          {!isEmpty(groupedFavList) &&
            Object.entries(groupedFavList).map(([key, value], index) => (
              <div key={index} className={`${component}-list-group`}>
                <div className={`${component}-list-type`}>
                  <span>{key}</span>
                </div>
                {value.map((item, index) => (
                  <SiteLink key={`${item.station_type}-${index}`} site={item}>
                    {listButton(item)}
                  </SiteLink>
                ))}
              </div>
            ))}
        </div>
      ) : (
        <div className={`${component}-list`}>
          {!isEmpty(favouritesList) &&
            favouritesList.map((item, index) => <SiteLink key={index} site={item} />)}
        </div>
      )}
    </div>
  );
}

Favourites.propTypes = {
  editable: PropTypes.bool,
  page: PropTypes.bool,
};
