import React from 'react';
import PropTypes from 'prop-types';

const RefreshIcon = ({ customClass }) => {
  return (
    <svg
      id="Icon_Refresh"
      data-name="Icon / Refresh"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={`${customClass ? customClass : ''}`}
    >
      <path
        id="np_refresh_1810373_000000"
        d="M12.97,6a10,10,0,0,0-.007,20,.909.909,0,1,0,0-1.818,8.182,8.182,0,1,1,7.344-11.768l-.937-.547a.909.909,0,1,0-.916,1.562l2.855,1.676a.909.909,0,0,0,1.264-.355l1.669-3.111a.91.91,0,1,0-1.605-.859l-.582,1.087A9.981,9.981,0,0,0,12.97,6Z"
        transform="translate(-1.984 -4)"
        fill="#0054a6"
      />
      <rect id="Rectangle_3809" data-name="Rectangle 3809" width="24" height="24" fill="none" />
    </svg>
  );
};

export default RefreshIcon;

/* Props */
RefreshIcon.propTypes = {
  customClass: PropTypes.string,
};
