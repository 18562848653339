// Libraries
import React, { useContext } from 'react';
import util from 'util';
import moment from 'moment';
import PropTypes from 'prop-types';

// Styles
import './flowClassAnnouncementItem.scss';

// Context
import { AppContext } from '../../contexts/AppContext';
import { MobileContext } from '../../contexts/MobileContext';

// Assets
import externalLink from '../../image/icons/external-link.png';

// Constants
import constants from '../../constants/Constants';

const container = 'announcement-item';

export default function FlowClassAnnouncementItem({
  effectiveDate,
  announcementDate,
  announcementId,
  linkTitle,
  targetId,
  setExternalSiteLink,
}) {
  const { waterSource } = useContext(AppContext);
  const { isMobile } = useContext(MobileContext);
  const link = util.format(
    constants.WATER_SOURCE_ANNOUNCEMENT_ID,
    waterSource.water_source_id,
    announcementId,
  );

  return (
    <div className={`${container}-container row`}>
      <div className={`${container}-row col-lg-7 col-sm-8`}>
        {`${moment(effectiveDate).format('DD MMM YYYY')} (Announced on ${moment(
          announcementDate,
        ).format('DD MMM YYYY')})`}
      </div>
      <div className="col-lg-5 col-sm-4">
        <a
          className="storage-intro-link"
          target="_blank"
          rel="noopener noreferrer"
          href={link}
          data-toggle={isMobile ? 'modal' : ''}
          data-target={isMobile ? targetId : ''}
          onClick={setExternalSiteLink ? () => setExternalSiteLink(link) : null}
        >
          {linkTitle}
          <img
            src={externalLink}
            className="external-link-icon"
            alt="Link icon linking to the source of the rules"
          />
        </a>
      </div>
    </div>
  );
}

FlowClassAnnouncementItem.propTypes = {
  effectiveDate: PropTypes.string,
  announcementDate: PropTypes.string,
  announcementId: PropTypes.number,
  linkTitle: PropTypes.string,
  targetId: PropTypes.string,
  setExternalSiteLink: PropTypes.func,
};
