// Libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Styles
import './expandLink.scss';

// Assets
import arrowIcon from '../../image/icons/arrow.png';

const component = 'expand-link';

export default function ExpandLink({ onExternalSiteClick, title, linkItems }) {
  const [subMenuOpen, setSubMenuOpen] = useState(false);

  return (
    <div className={component}>
      <div
        className={`${component}-nav-item`}
        onClick={event => {
          event.preventDefault();
          setSubMenuOpen(!subMenuOpen);
        }}
      >
        <a data-testid="username" href="#!">
          {title}
        </a>
        <img
          src={arrowIcon}
          alt="Arrow icon"
          className={`${component}-arrow ${subMenuOpen ? 'expandable' : ''}`}
        />
      </div>
      <div className={`${component}-dropdown-menu ${subMenuOpen ? 'expandable' : ''}`}>
        {linkItems.map((item, index) =>
          item.link ? (
            <div
              key={index}
              className={`${component}-item`}
              data-toggle="modal"
              data-target="#externalLinkModal"
              onClick={onExternalSiteClick ? () => onExternalSiteClick(item.link) : () => {}}
            >
              {item.title}
            </div>
          ) : (
            <div key={index} className={`${component}-item`}>
              <Link
                to={item.internalLink}
                onClick={
                  item.onClickFunc
                    ? () => {
                        item.onClickFunc();
                        setSubMenuOpen(false);
                      }
                    : () => setSubMenuOpen(false)
                }
              >
                {item.title}
              </Link>
              <br />
            </div>
          ),
        )}
      </div>
    </div>
  );
}

ExpandLink.propTypes = {
  onExternalSiteClick: PropTypes.func,
  title: PropTypes.string,
  linkItems: PropTypes.array,
};
