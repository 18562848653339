import React from 'react';
import PropTypes from 'prop-types';

const ArrowCircleSVG = ({ customArrowClassName }) => {
  return (
    <svg
      className={`${customArrowClassName ? customArrowClassName : 'neighbouring-circle-arrow'}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      fill="white"
      width="32px"
      height="32px"
    >
      <g fill="#a4a9ac" strokeLinejoin="round">
        <circle cx="16" cy="16" r="15.12" />
        <path stroke="white" strokeWidth="3" d="M16.14 9.93L22.21 16l-6.07 6.07M8.23 16h13.98" />
      </g>
    </svg>
  );
};

export default ArrowCircleSVG;

/* Props */
ArrowCircleSVG.propTypes = {
  /**
   * This change the header arrow's styling
   * It expects string that represent a css class
   * Example can be seen in restrictions.scss, .restrictions-body-arrow
   *
   * Side Note:
   * Change both height and width to control size
   * Change fill to change color
   */
  customArrowColor: PropTypes.string,
};
