// Library
import React from 'react';

// Component
import BodyContainer from '../../layout/body/BodyContainer';
import ManageRequestBody from '../../components/userProfileItems/manageRequest/ManageRequestBody';

const ManageRequests = () => {
  return (
    <BodyContainer page>
      <ManageRequestBody />
    </BodyContainer>
  );
};

export default ManageRequests;
