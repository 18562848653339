import React from 'react';
import './body.scss';
import '../grid.scss';
import PageTop from '../../components/pageTop/PageTop';
import config from '../../abstract/IntroText.json';

export default function Outlook() {
  return (
    <div className="body-container">
      <PageTop title={config.outlook.title} intro={config.outlook.intro} />
      <div className="update-body row">
        <div className="update-body-left col-lg-8 col-sm-12 col-12"></div>
      </div>
    </div>
  );
}
