// Library
import React, { useState, useContext, useEffect } from 'react';

// Styles
import './stationDownloadTypeSelect.scss';

// Context
import { DownloadContext } from '../../../../../../contexts/DownloadContext';

// Component
import SelectionBtns from '../../../../../storage/childProperties/SelectionBtns';

// Constant
import constant from '../../DataDownloadConstants';
const component = 'station-download-type-select';

const StationDownloadTypeSelect = () => {
  const {
    downloadType,
    stationInfoType,
    setStationInfoType,
    setHydrometric,
    setDataType,
    setInterval,
    setStartDate,
    setEndDate,
    requestedSiteId,
    selectedStations,
  } = useContext(DownloadContext);
  const [infoSelections, setInfoSelections] = useState(constant.STATION_INFO_TYPE[downloadType]);

  // Reset filters to default on stationInfoType changes
  useEffect(() => {
    if (!requestedSiteId) {
      setHydrometric([]);
    }
    if (selectedStations.length > 1) {
      setInfoSelections(
        infoSelections.map(type => {
          return { ...type, active: type.id === constant.RATING ? false : type.active };
        }),
      );
    } else {
      setInfoSelections(
        infoSelections.map(type => {
          return { ...type, active: true };
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setDataType,
    setHydrometric,
    setInterval,
    setEndDate,
    setStartDate,
    stationInfoType,
    selectedStations,
  ]);

  useEffect(() => {
    const currentDate = new Date();
    if (!requestedSiteId) {
      setHydrometric([]);
      setDataType(constant.DATA_TYPES[0]);
      setInterval(constant.INTERVAL_SELECTIONS[2]);
      setStartDate(currentDate.setFullYear(currentDate.getFullYear() - 1));
      setEndDate(new Date());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadType]);

  return (
    <div className={component}>
      <SelectionBtns
        itemList={infoSelections}
        onBtnChange={item => {
          setStationInfoType(item);
        }}
        type="download"
        selected={stationInfoType}
      />
    </div>
  );
};

export default StationDownloadTypeSelect;

StationDownloadTypeSelect.propTypes = {};
