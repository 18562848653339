// Libraries
import moment from 'moment';
import React from 'react';
import { isEmpty, isEqual, cloneDeep, isNumber, capitalize, startCase } from 'lodash';

// Helpers
import { getAllStationsByType, getStationForWaterSourceByType } from './ApiHelper';
import { currentFinancialYear } from './TimeUtils';
import { getMinimalFlow, getLossesWaterSource } from './LossesApiHelper.jsx';

// Constants
import constants from '../constants/Constants';
import apiConstants from '../constants/APIResponseConstant';
import waterDataConstant from '../constants/WaterDataAPIConstants';
import flagConfig from '../configs/featureToggleConfig.json';
const MURRUMBIDGEE_REGULATED_RIVER_WATER_SOURCE = 11982;
const WATER_DATA_VARIABLES = waterDataConstant.HYDROMETRIC_VARIABLES_MAP;

export const sortArrayByKeyAsc = (array, key) => {
  array.sort(function (a, b) {
    return a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0;
  });
  return array;
};

export const sortArrayByKeyDesc = (array, key) => {
  array.sort(function (a, b) {
    return a[key] < b[key] ? 1 : b[key] < a[key] ? -1 : 0;
  });
  return array;
};

export const toTitleCase = str => {
  var strSplit = str.toLowerCase().split(' ');
  for (var i = 0; i < strSplit.length; i++) {
    strSplit[i] = strSplit[i].charAt(0).toUpperCase() + strSplit[i].slice(1);
  }
  return strSplit.join(' ');
};

export const toTitleCasePlus = ({
  stringSentence,
  excludedWords = ['the', 'to', 'and', 'or', 'at', 'for', 'in', 'on'],
  allCapsWords = [],
}) => {
  const capitalizedWords = [];
  const words = stringSentence.split(' ');
  for (let i = 0; i < words.length; i++) {
    if (allCapsWords.includes(words[i])) {
      capitalizedWords.push(words[i]);
      continue;
    }
    if (excludedWords.includes(words[i].toLowerCase()) && i !== 0) {
      capitalizedWords.push(words[i].toLowerCase());
      continue;
    }
    capitalizedWords.push(words[i].replace(/\w+/g, capitalize));
  }
  return capitalizedWords.join(' ');
};

export const addSpace = str => {
  var strSplit = str.split(',');
  return strSplit.join(', ');
};

export const setMapHeightById = (id, height) => {
  document.getElementById(id).style.height = height;
};

export const formatNumberDecimals = (
  number,
  alwaysRounded = false,
  singleDecimal = false,
  decimals = null,
  showFraction = true,
) => {
  const parsed = Number(number);
  if (Number.isNaN(parsed)) {
    return number;
  }

  let formatted;
  switch (true) {
    case Math.abs(parsed) >= 1 && singleDecimal:
      formatted = parsed.toLocaleString('en-US', {
        style: 'decimal',
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      });
      break;
    case (Math.abs(parsed) > 10 || alwaysRounded) && decimals === null:
      formatted = Math.round(parsed).toLocaleString('en-US');
      break;
    default:
      formatted = parsed.toLocaleString('en-US', {
        style: 'decimal',
        maximumFractionDigits: decimals !== null && showFraction ? decimals : !showFraction ? 1 : 2,
        minimumFractionDigits: decimals !== null && showFraction ? decimals : !showFraction ? 0 : 2,
      });
  }
  return formatted;
};

export const truncateString = (str, num) => {
  if (!str || str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
};

export const toSentenceCase = str => {
  if (!str) return str;
  const lowerCase = str.toLowerCase();
  const result = lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
  return result;
};

export const genListOfFiscalYears = (type, waterSourceId) => {
  let thisYear = moment().add(6, 'months').year();
  let maxOffset =
    (type === 'storage' || type === 'inflow') &&
    waterSourceId === MURRUMBIDGEE_REGULATED_RIVER_WATER_SOURCE
      ? thisYear - 1980
      : thisYear - 2010;
  var yearList = [];
  for (let x = 0; x <= maxOffset; x++) {
    var yearLabel = thisYear - (x + 1) + '/' + moment(thisYear - x, 'YYYY').format('YY');
    var yearValue = type === 'storage' ? thisYear - (x + 1) + ':' + (thisYear - x) : thisYear - x;
    yearList.push({
      label: yearLabel,
      value: yearValue,
    });
  }
  return yearList;
};

export const replaceWaterSourceLiteral = waterSourceName => {
  return waterSourceName.replace('Regulated Rivers', '').replace('Regulated River', '');
};

export const isWaterSourceREG = waterSource => {
  if (isEmpty(waterSource)) {
    return undefined;
  }
  return (
    isEqual(waterSource.water_source_type.sort(), ['REG']) ||
    waterSource.water_source_id === constants.BARWON_DARLING_UNREGULATED_RIVER_WATER_SOURCE ||
    waterSource.water_source_id === constants.FISH_RIVER_WATER_SOURCE
  );
};

export const formatPopupContentNumber = (number, type, decimals) => {
  if (!isNumber(number)) return '-';
  let result;
  const { DAM, WEIR } = apiConstants;
  const division = [DAM.volume, DAM.release, DAM.inflow, WEIR.storage].includes(type) ? 1000 : 1;
  result =
    type === DAM.volumePerc
      ? formatNumberDecimals(number, false, true)
      : formatNumberDecimals(number / division, false, false, decimals);
  return result;
};

export const addTabItemByWaterSourceId = async waterSource => {
  let tabItem = [];
  const waterSourceId = waterSource.water_source_id;
  if (constants.LOSSES_TAB_WATER_SOURCES.includes(waterSourceId)) {
    const listOfYears = genListOfFiscalYears('losses', waterSourceId);
    const activeYears = listOfYears.slice(0, 2).map(year => year.value);
    let lossesData = [];
    let minimalFlowData = [];
    await getLossesWaterSource(waterSource, activeYears.join(), function (result) {
      lossesData = result;
    });
    await getMinimalFlow(waterSource, activeYears.join(), function (result) {
      minimalFlowData = result;
    });
    if (!isEmpty(lossesData) || !isEmpty(minimalFlowData)) {
      tabItem.push(constants.TAB_OPTIONS.losses);
    }
  }
  if (isActiveMgmtActive(waterSourceId)) {
    tabItem.push(constants.TAB_OPTIONS.flowClass);
  }
  if (waterSourceId === constants.HUNTER_RIVER) {
    tabItem.push(constants.TAB_OPTIONS.salinity);
  }
  if (
    waterSourceId === constants.FISH_RIVER_WATER_SOURCE &&
    flagConfig.duckmaloi_pollution.active
  ) {
    tabItem.push(constants.TAB_OPTIONS.pollution);
  }
  return tabItem;
};

export const getTabItemsByWaterSourceId = async waterSource => {
  const additionalItems = await addTabItemByWaterSourceId(waterSource);
  const weirs = await getStationForWaterSourceByType(waterSource.water_source_id, 'weir');
  const BASE_TAB_ITEMS = [
    constants.TAB_OPTIONS.updates,
    constants.TAB_OPTIONS.allocation,
    ...(!isEmpty(weirs) ? [constants.TAB_OPTIONS.storage] : []),
    constants.TAB_OPTIONS.riverData,
    ...(!isEmpty(additionalItems) ? additionalItems : []),
    constants.TAB_OPTIONS.trading,
    constants.TAB_OPTIONS.research,
    constants.TAB_OPTIONS.rules,
  ];
  const TAB_ITEMS = {
    REG: [
      constants.TAB_OPTIONS.updates,
      constants.TAB_OPTIONS.allocation,
      constants.TAB_OPTIONS.storage,
      constants.TAB_OPTIONS.riverData,
      constants.TAB_OPTIONS.trading,
      ...(!isEmpty(additionalItems) ? additionalItems : []),
      constants.TAB_OPTIONS.research,
      constants.TAB_OPTIONS.rules,
    ],
    GS: [
      constants.TAB_OPTIONS.updates,
      constants.TAB_OPTIONS.storage,
      constants.TAB_OPTIONS.riverData,
    ],
    UNREG: BASE_TAB_ITEMS,
    GW: BASE_TAB_ITEMS,
    MIX: BASE_TAB_ITEMS,
  };
  if (isEmpty(waterSource)) return [];
  if (waterSource.water_source_id === constants.GREATER_SYDNEY_WSID) {
    return TAB_ITEMS['GS'];
  } else if (isWaterSourceREG(waterSource)) {
    return TAB_ITEMS['REG'];
  } else {
    const waterType =
      waterSource.water_source_type.length === 1 ? waterSource.water_source_type[0] : 'MIX';
    return TAB_ITEMS[waterType];
  }
};

export const StrippedBar = props => {
  const { x: oX, y: oY, width: oWidth, height: oHeight, fill } = props;

  let x = oWidth < 0 ? oX + oWidth : oX;
  let y = oHeight < 0 ? oY + oHeight : oY;
  let width = Math.abs(oWidth);
  let height = Math.abs(oHeight);

  return (
    <rect fill={fill} mask="url(#mask-stripe-legend)" x={x} y={y} width={width} height={height} />
  );
};

export const updateDisplayById = (id, style) => {
  var x = document.getElementById(id);
  if (x) x.style.display = style;
};

export const isActiveMgmtActive = (id, checkBarwonDarling = false) => {
  let isBarwonAccountedFor = false;
  if (checkBarwonDarling && id === constants.BARWON_DARLING_UNREGULATED_RIVER_WATER_SOURCE) {
    isBarwonAccountedFor = true;
  } else if (!checkBarwonDarling) {
    isBarwonAccountedFor = true;
  }

  return (
    constants.ACTIVE_MANAGEMENT_WATER_SOURCES.includes(id) &&
    constants.ACTIVATE_ACTIVE_MANAGEMENT &&
    isBarwonAccountedFor
  );
};

export const removeEmptyObj = array => {
  const filteredArray = array.filter(value => Object.keys(value).length !== 0);
  return filteredArray;
};

export const createMarkup = data => {
  return { __html: data };
};

export const replaceOrAddObjectByKey = (arr, obj, key) => {
  if (isEmpty(arr)) return;
  const index = arr.findIndex(element => element[key] === obj[key]);
  if (index === -1) {
    arr.push(obj);
  } else {
    arr[index] = obj;
  }
  return arr;
};

export const sortArrayOfObjectByTimestamp = (arr, dateKey, mode = 'asc', isDate = false) => {
  if (mode === 'asc' || mode === 'desc') {
    const cloneArr = cloneDeep(arr);
    cloneArr.sort(function (a, b) {
      const dateA = isDate ? new Date(a[dateKey]).getDate() : new Date(a[dateKey]);
      const dateB = isDate ? new Date(b[dateKey]).getDate() : new Date(b[dateKey]);
      return mode === 'desc' ? getArrayOrder(dateA, dateB) : getArrayOrder(dateB, dateA);
    });
    return cloneArr;
  }
  return arr;
};

const getArrayOrder = (dateA, dateB) => {
  return dateA < dateB ? 1 : dateB < dateA ? -1 : 0;
};

export const handleGenericError = (err, history) => {
  if (err instanceof URIError) {
    if (process.env.NODE_ENV !== 'production') {
      console.log('Dev Warning!', err);
    }
    return;
  }
  history.replace(history.location.pathname, { errorStatusCode: 500 });
};

export const LightenDarkenColor = (col, amt) => {
  col = col.replace(/^#/, '');
  if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];

  let [r, g, b] = col.match(/.{2}/g);
  [r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt];

  r = Math.max(Math.min(255, r), 0).toString(16);
  g = Math.max(Math.min(255, g), 0).toString(16);
  b = Math.max(Math.min(255, b), 0).toString(16);

  const rr = (r.length < 2 ? '0' : '') + r;
  const gg = (g.length < 2 ? '0' : '') + g;
  const bb = (b.length < 2 ? '0' : '') + b;

  return `#${rr}${gg}${bb}`;
};

export const findLastWord = string => {
  let lastWord = '';
  let notLastWord = '';

  if (!string) {
    return {
      last: lastWord,
      notLast: notLastWord,
    };
  }

  const arrayString = string.split(' ');

  if (arrayString.length > 1) {
    lastWord = arrayString.splice(-1);
  }

  notLastWord = arrayString.join(' ');
  return {
    last: lastWord,
    notLast: notLastWord,
  };
};

export const sortArrayByKeyAscDateTime = (array, key, format) => {
  array.sort(function (a, b) {
    return moment(a[key], format).isAfter(moment(b[key], format))
      ? 1
      : moment(b[key], format).isAfter(moment(a[key], format))
      ? -1
      : 0;
  });
  return array;
};

export const mergeByDate = (array, isDate = false, returnValueOnly = true) => {
  return array.reduce((results, line) => {
    const sameDateLine = results.find(item => item.timeStamp === line.timeStamp);
    if (sameDateLine) {
      let variableName = line['variableName'];
      sameDateLine[variableName] = returnValueOnly ? line['value'] : { ...line };
    } else {
      line['variableName'] &&
        results.push({
          ...line,
          [line['variableName']]: returnValueOnly ? line['value'] : { ...line },
        });
    }
    return sortArrayOfObjectByTimestamp(results, 'timeStamp', 'desc', isDate);
  }, []);
};

export const formatBoreDataResources = (
  data,
  firstValue = false,
  dateFormat = 'DD-MMM-YYYY HH:mm',
  returnValueOnly = true,
) => {
  return sortArrayOfObjectByTimestamp(data, 'timeStamp', 'desc', true).map(record => {
    const value =
      firstValue && data[0].value
        ? data[0].value
        : record.GroundwaterDepthBelowSurfaceLevel || record.value;
    const valueObj = returnValueOnly
      ? {
          value: value,
        }
      : { [record.variableName]: { ...record } };

    return {
      ...valueObj,
      date: moment(record.timeStamp, dateFormat).format('YYYY-MM-DD'),
    };
  });
};

export const mergeBoreDataAndMetaData = (boreData, boreMetaData) => {
  const updatedStructureOfBoreData = boreData.map(bore => {
    return {
      bore_id: bore[0].siteId,
      resources: formatBoreDataResources(bore, false),
      water_level: bore[bore.length - 1],
      date: bore[bore.length - 1]?.timeStamp,
      hydrometric_types: ['GroundwaterDepthBelowSurfaceLevel'],
      water_data_variables: ['GroundwaterDepthBelowSurfaceLevel'],
    };
  });
  let mergedBoreInfo = boreMetaData.map(item => ({
    bore_id: item.siteId,
    id: item.siteId,
    station_id: item.siteId,
    station_name: item.siteName,
    ...(updatedStructureOfBoreData.find(data => data.bore_id === item.siteId) || { resrouces: [] }),
    ...item,
  }));
  mergedBoreInfo = mergedBoreInfo.map(item => ({
    ...item,
    bore_name: item.siteName,
    lat: item.latitude,
    long: item.longitude,
    work_number: item?.bore_id?.split('.')[0],
    work_name: item.siteName.split(/-(.*)/s)[1],
  }));
  return mergedBoreInfo;
};

export const filterStorageVolume = data => {
  let result = data;
  const hasActiveStorage = data.find(el => el.variableName === 'ActiveStorageVolume');
  if (hasActiveStorage) {
    result = data.filter(el => el.variableName !== 'TotalStorageVolume');
  }
  return result;
};

export const formatLatestWaterData = (data, stationType, stationsVariables) => {
  let result = {};
  if (!isEmpty(data)) {
    const filtered =
      stationType === 'gauge' && !isEmpty(stationsVariables)
        ? data.filter(item => stationsVariables.includes(item.variableName))
        : stationType !== 'weir'
        ? filterStorageVolume(data)
        : data;
    filtered.map(dataItem => {
      result.siteId = dataItem.siteId;
      const dateFormat = waterDataConstant.API_DATE_FORMAT;
      if (
        !result.date ||
        (result.date &&
          moment(result.date, dateFormat).isBefore(moment(dataItem.timeStamp, dateFormat)))
      ) {
        result.date = dataItem.timeStamp;
      }
      const duration = Math.abs(
        moment
          .duration(moment(dataItem.timeStamp).diff(moment().format('DD-MMM-YYYY HH:mm')))
          .as('hours'),
      );
      result[WATER_DATA_VARIABLES[dataItem.variableName]] = {
        ...dataItem,
        value: stationType !== 'dam' && duration > 24 ? null : dataItem.value,
      };
    });
  }
  return result;
};

export const addInternalStationData = async stationData => {
  const siteTypes = {
    Weirs: 'weir',
    StreamGauge: 'gauge',
    Groundwater: 'bore',
    Storage: 'dam',
  };
  let finalStationData = stationData;
  await getAllStationsByType(
    siteTypes[stationData.siteType],
    function (result) {
      finalStationData.station_id = stationData.siteId;
      finalStationData.station_name = startCase(stationData?.siteName?.toLowerCase());
      finalStationData.station_type = siteTypes[stationData.siteType];
      finalStationData.id = !isEmpty(result) ? result[0].id : stationData.siteId;
      finalStationData.full_volume = result[0]?.full_volume;
      finalStationData.water_data_variables =
        !isEmpty(result) && result[0].water_data_variables
          ? result[0].water_data_variables
          : Object.values(stationData.variablesMonitored).filter(item => item !== 'Rainfall');
      finalStationData.water_source_id = !isEmpty(result) ? result[0].water_source_id : null;
      finalStationData.water_source_name = !isEmpty(result) ? result[0].water_source_name : null;
    },
    stationData.siteId,
  );
  return finalStationData;
};

export const financialYearsMenuItems = numberOfYears => {
  return Array.from({ length: numberOfYears }, (_, i) => ({
    id: i + 1,
    name: `${currentFinancialYear() - i - 1}/${(currentFinancialYear() - i).toString().slice(-2)}`,
  }));
};
