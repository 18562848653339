// Library
import { isNil } from 'lodash';

export const emailValidation = email => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

export const checkIfNotEmpty = value => {
  if (!isNil(value)) {
    const tempStr = value.trim();
    return Boolean(tempStr);
  }
  return false;
};
