import ReactGA from 'react-ga4';

/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 * @param {string} label
 */
export const Event = (category, action, label) => {
  ReactGA.event({
    category: category || 'Not Selected',
    action: action,
    label: label,
  });
};

export const PageView = path => {
  ReactGA.send({ hitType: 'pageview', page: path });
};
