// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './groundwaterDataFilters.scss';

const component = 'groundwater-data-filters';

const GroundwaterDataFilters = ({ items, pageSource = '' }) => {
  // Mini Component
  const renderFilter = (filterItem, ind) => {
    return (
      <div key={ind} className={`${component}-option`}>
        {filterItem.name}
        {filterItem.open && filterItem.content && (
          <div className={`${component}-item-content`}>{filterItem.content}</div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className={`${component}-container ${pageSource}`}>
        {items.map((item, ind) => renderFilter(item, ind))}
      </div>
    </>
  );
};

export default GroundwaterDataFilters;

GroundwaterDataFilters.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.element,
      content: PropTypes.element,
      open: PropTypes.bool,
    }),
  ),
  pageSource: PropTypes.string,
};
