// Libraries
import React, { useContext } from 'react';
import { toString } from 'lodash';
import PropTypes from 'prop-types';

// Context
import { AppContext } from '../../contexts/AppContext';

// Styles
import './PumpPopup.scss';
import './MapPopup.scss';

// Helpers
import { toSentenceCase, addSpace, formatNumberDecimals } from '../../helpers/Utils';

// Constants
import constants from '../../constants/Constants.jsx';
const SIGNAL_COLOR = { R: '#f32121', G: '#41b22d' };

export default function PumpPopup({ data }) {
  const UNAVAL = 'unavailable';
  const properties = data.properties;
  const { waterSource } = useContext(AppContext);

  const checkFlowStatus = prop => {
    if (!prop.FLOW_CLASS_STATUS) {
      return [];
    }
    return [
      {
        type: 'Permission',
        data: prop.FLOW_CLASS_STATUS,
        className: 'station-data-popup-body',
      },
    ];
  };

  const formatValueForLicenses = value => {
    let formatted = value.split(',');
    let licenseItems = [];
    formatted.forEach(license => {
      if (waterSource.water_source_id === 16001) {
        const splittedLic = license.split(' ');
        const wal = splittedLic[0].split('/');
        const signalColor = SIGNAL_COLOR[splittedLic[2]];
        licenseItems.push({ wal: wal[1], signalColor: signalColor });
      } else {
        licenseItems.push({ wal: license, signalColor: null });
      }
    });
    formatted = licenseItems;
    return formatted;
  };

  const formatValue = (value, type) => {
    if ((!value && value !== 0) || !toString(value).replace(/\s/g, '').length) {
      return UNAVAL;
    }
    let formatted = toString(value).replace(/\/+/g, '/');

    switch (true) {
      case type === 'Purpose':
        return toSentenceCase(addSpace(formatted));
      case type === 'Shares':
        return formatted;
      case type === 'Licenses':
        return formatValueForLicenses(formatted);
      default:
        return formatted;
    }
  };

  const formattedLic =
    properties &&
    formatValue(
      properties.LINKED_LICS_CATS_AND_STATUS || properties.WAL || properties.ACCESS_LICENSE,
      'Licenses',
    );
  const formattedShares = properties && formatValue(properties.TOTAL_VOLUME, 'Shares');
  const isLicAndSharesHidden = formattedLic === UNAVAL && formattedShares === UNAVAL;

  const dataProps = [
    {
      type: 'ESID',
      data: properties && formatValue(properties.EXTRACTION_SITE, 'ESID'),
      className: 'pump-popup-body-esid',
    },
    {
      type: 'Purpose',
      data: properties && formatValue(properties.LICENSED_PURPOSES, 'Purpose'),
      className: 'station-data-popup-body',
    },
    {
      type: 'Approval',
      data: properties && properties.APPROVAL_NO,
      className: 'station-data-popup-body',
      link: true,
    },
    {
      type: 'Lot/DP',
      data: properties && formatValue(properties.LOT_SEC_DP, 'Lot/DP'),
      className: 'station-data-popup-body',
    },
    {
      type: 'Licences',
      data: formattedLic,
      className: `station-data-popup-body ${isLicAndSharesHidden && 'hidden'}`,
    },
    {
      type: 'Shares',
      data: formattedShares,
      className: `station-data-popup-body ${isLicAndSharesHidden && 'hidden'}`,
    },
    ...checkFlowStatus(properties),
  ];

  return (
    <div className="map-popup-container pump-popup-container">
      {dataProps.map((row, index) => (
        <div key={index} className={`row ${row.className}`}>
          <div className="col-5">{row.type}</div>
          <div className={`col-7 ${row.data === 'unavailable' ? 'null-info' : ''}`}>
            {row.link ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${constants.WATER_REGISTER_APPROVAL_INFO.server}?${constants.WATER_REGISTER_APPROVAL_INFO.param}=${row.data}`}
                className="pump-popup-body-link"
              >
                {row.data}
              </a>
            ) : (
              <div className={row.type === 'Licences' ? 'map-popup-right-value' : ''}>
                {Array.isArray(row.data)
                  ? row.data.map((item, index) => (
                      <div key={index} className="pump-popup-license-row">
                        <div>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${constants.WATER_REGISTER_LICENCE_INFO.server}?${constants.WATER_REGISTER_LICENCE_INFO.param}=${item.wal}`}
                            className="pump-popup-body-link"
                          >
                            {item.wal}
                          </a>
                        </div>
                        {item.signalColor && (
                          <div className="pump-popup-license-dot">
                            <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="5" cy="5" r="5" fill={item.signalColor} />
                            </svg>
                          </div>
                        )}
                      </div>
                    ))
                  : row.data}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

PumpPopup.propTypes = {
  data: PropTypes.shape({
    geometry: PropTypes.object,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    properties: PropTypes.object,
    type: PropTypes.string,
  }),
};
