import { useQuery } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import moment from 'moment';

// Helpers
import {
  getBoreIdsForWaterSourceOfType,
  getStationForWaterSourceByType,
  getAllDamsResources,
} from '../helpers/ApiHelper';
import {
  getDataForRiverDataSummary,
  getMultiSiteMetadata,
  getMultiGroundLevelData,
  getMultiSiteData,
} from '../helpers/WaterDataApiHelper';
import { getCurrentDate } from '../helpers/TimeUtils';
import { mergeBoreDataAndMetaData } from '../helpers/Utils';

// Constant
import mapFilterConstant from '../constants/MapFilterConstants';
import waterDataApiConstants from '../constants/WaterDataAPIConstants';

import config from '../configs/featureToggleConfig';
import constants from '../constants/Constants';

const popupItems = mapFilterConstant.MAP_POPUP_ITEMS;
const { GAUGE, DAM, WEIR, BORE } = popupItems;
const getDataFromWaterDataApi = config['water-data-api'].active;
const getDamsDataFromWaterDataApi = config['water-data-api'].dams.active;
const staleTime = 15 * (60 * 1000);

const queryKeyFactory = {
  [GAUGE.name]: [GAUGE.name],
  [WEIR.name]: [WEIR.name],
  [DAM.name]: [DAM.name],
  boreData: [BORE.name, 'monthly', 'recent6Years'],
  gaugesHydrometrics: [GAUGE.name, '15-minute', 'latestData'],
  damsHydrometrics: [DAM.name, '15-minute', 'latestData'],
  weirsHydrometrics: [WEIR.name, '15-minute', 'latestData'],
};

const [SYSTEM, LIST, TABLE] = ['SYSTEM VIEW', 'LIST VIEW', 'TABLE VIEW'];
const RIVER_VARIABLE_MAPPING = {
  gauge: {
    [SYSTEM]: 'FlowRate',
    [LIST]:
      'FlowRate,StreamWaterLevel,DissolvedOxygen,StorageWaterLevel,DissolvedOxygen2.0MBelowSurface',
    [TABLE]: 'FlowRate,StreamWaterLevel,StorageWaterLevel',
  },
  dam: { [SYSTEM]: 'ActiveStorageVolume,ActiveStoragePercentage' },
  weir: { [SYSTEM]: 'TotalStorageVolume' },
};

// Helper
const getBoreData = async waterSourceId => {
  const dateFormat = constants.STORAGE_DATE_FORMAT;
  const startDate = moment()
    .subtract(6, 'years')
    .add(-1, 'months')
    .format(getDataFromWaterDataApi ? dateFormat : 'YYYY-MM');
  const endDate = getCurrentDate(dateFormat);
  try {
    const boreIds = await getBoreIdsForWaterSourceOfType(waterSourceId);
    const boreMetaData = await getMultiSiteMetadata(
      [...boreIds],
      waterDataApiConstants.SITE_TYPE.Groundwater,
    );
    const boreData = await getMultiGroundLevelData(boreIds, startDate, endDate, 'monthly');
    const mergedBoreInfo = mergeBoreDataAndMetaData(boreData, boreMetaData);
    return mergedBoreInfo;
  } catch (error) {
    console.error(error);
    return []; //TODO error hadling need to be added in next update
  }
};

const updateDamData = async damsList => {
  let allDams = await getAllDamsResources();
  if (!isEmpty(allDams)) {
    let updatedDams = damsList.map(dam => {
      let theDam = allDams.find(element => element.id === dam.station_id);
      return {
        ...dam,
        volume_perc: theDam?.resources[0]?.volume_perc,
        volume: theDam?.resources[0]?.volume,
      };
    });
    return updatedDams;
  }
};

const fetchStationsData = async (allGauges, hydrometrics) => {
  const result = await getMultiSiteData(
    allGauges.map(item => item.station_id),
    -7,
    hydrometrics.split(','),
    'daily',
    '09:00',
  );
  allGauges.map(gauge => {
    result.forEach(item => {
      if (item.id === gauge.station_id) {
        item.stationName = `${gauge.station_display_name} (${gauge.station_id})`;
      }
    });
  });
  return result;
};

export const GetBoresData = ({ waterSourceId, enabled }) => {
  return useQuery({
    queryKey: [...queryKeyFactory['boreData'], waterSourceId],
    queryFn: () => getBoreData(waterSourceId),
    staleTime,
    enabled,
  });
};

export const GetGaugesList = ({ waterSourceId, enabled }) => {
  return useQuery({
    queryKey: [...queryKeyFactory[GAUGE.name], waterSourceId],
    queryFn: () => getStationForWaterSourceByType(waterSourceId, GAUGE.name),
    staleTime,
    enabled,
  });
};

export const GetWeirsList = ({ waterSourceId, enabled }) => {
  return useQuery({
    queryKey: [...queryKeyFactory[WEIR.name], waterSourceId],
    queryFn: () => getStationForWaterSourceByType(waterSourceId, WEIR.name),
    staleTime,
    enabled,
  });
};

export const GetDamsList = ({ waterSourceId, enabled }) => {
  return useQuery({
    queryKey: [...queryKeyFactory[DAM.name], waterSourceId],
    queryFn: () => getStationForWaterSourceByType(waterSourceId, DAM.name),
    staleTime,
    enabled,
  });
};

export const GetGaugesHydrometrics = ({ gaugesList, enabled }) => {
  return useQuery({
    queryKey: [...queryKeyFactory['gaugesHydrometrics'], gaugesList],
    queryFn: () =>
      getDataForRiverDataSummary(gaugesList, GAUGE.name, RIVER_VARIABLE_MAPPING.gauge['LIST VIEW']),
    staleTime,
    enabled: enabled && getDataFromWaterDataApi,
  });
};

export const GetWeirsHydrometrics = ({ weirsList, view, enabled }) => {
  return useQuery({
    queryKey: [...queryKeyFactory['weirsHydrometrics'], weirsList],
    queryFn: () =>
      getDataForRiverDataSummary(weirsList, WEIR.name, RIVER_VARIABLE_MAPPING[WEIR.name][view]),
    staleTime,
    enabled: enabled && getDataFromWaterDataApi,
  });
};

export const GetDamsHydrometrics = ({ damsList, view, enabled }) => {
  return useQuery({
    queryKey: [...queryKeyFactory['damsHydrometrics'], damsList],
    queryFn: () =>
      getDamsDataFromWaterDataApi
        ? getDataForRiverDataSummary(damsList, DAM.name, RIVER_VARIABLE_MAPPING[DAM.name][view])
        : updateDamData(damsList),
    staleTime,
    enabled,
  });
};

export const GetGaugesLastWeekHydrometrics = ({ gauges, hydrometrics }) => {
  return useQuery({
    queryKey: 'table',
    queryFn: () => fetchStationsData(gauges, hydrometrics),
    staleTime,
  });
};
