// Library
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './updateTabRestrictions.scss';

// Component
import Restrictions from './Restrictions';

// Constant
const container = 'update-tab-restriction';

const UpdateTabRestrictions = ({ restrictions, partialHide = false }) => {
  return (
    <div className={`${container}`}>
      <Restrictions restrictions={restrictions} partialHide={partialHide} />
    </div>
  );
};

export default UpdateTabRestrictions;

UpdateTabRestrictions.propTypes = {
  restrictions: PropTypes.array,
  partialHide: PropTypes.bool,
};
