// Libraries
import React, { useState, useContext, useLayoutEffect } from 'react';
import moment from 'moment';
import { isEmpty, groupBy, startCase } from 'lodash';
import { ComposedChart, Bar, XAxis, YAxis, Tooltip, Line } from 'recharts';
import PropTypes from 'prop-types';

// Context
import { MobileContext } from '../../contexts/MobileContext';

// Components
import ChartContainer from '../chartProperties/ChartContainer';
import ChartResponsiveContainer from '../chartProperties/ChartResponsiveContainer';
import LicenceCategorySelector from '../allocation/LicenceCategorySelector';
import SelectionBtnsGroup from '../storage/childProperties/SelectionBtnsGroup';
import DownloadLink from '../chartProperties/ChartCSVDownloadLink';

// Assets
import config from '../../abstract/IntroText.json';

// Helpers
import { formatNumberDecimals } from '../../helpers/Utils';
import { formatLabelWaterYear, currentFinancialYear } from '../../helpers/TimeUtils';
import { groupAllocationsByCategory } from '../../components/allocation/ArrayUtilsAllocation';

// Constant
const ALL_CATEGORY_DROPDOWN = 'All licences';
const MOVING_AVERAGE_YEARS = 7;
const HISTORICAL_YEARS_TO_SHOW = 10;

export default function HistoricalUsageUtilisationGraph({ data }) {
  const { isMobile, isXL } = useContext(MobileContext);
  const [usageData, setUsageData] = useState();
  const [licCategorySelected, setLicCategorySelected] = useState(ALL_CATEGORY_DROPDOWN);
  const [licCategoriesDropdown, setLicCategoriesDropdown] = useState();

  // Life Cycle
  useLayoutEffect(() => {
    let parsedResult = [];
    let allResult = [];
    const curYear = currentFinancialYear();
    const historicalData = data.filter(yearItem => Number(yearItem.water_year) !== Number(curYear));
    historicalData.forEach(function (item) {
      allResult.push(aggregateAllLicences(item));
      Array.prototype.push.apply(parsedResult, groupAllocationsByCategory(item.resources));
    }, historicalData);

    //Combine the just created aggregation of all licences ('all licences') by water year with the
    //rest of categories items
    const combinationAllLicences = [...allResult, ...parsedResult];

    // Group the parsed result by category shortname
    let groupedByCategoryName = groupBy(combinationAllLicences, 'category_shortname');

    /**
     * I am not sure what's the purpose of this section of code.
     * If anyone knows, please add some documentation to it.
     *                                                  - Liwei
     */
    Object.entries(groupedByCategoryName).forEach(function ([key, value]) {
      value.forEach(function (item, index) {
        if (index >= value.length - MOVING_AVERAGE_YEARS) {
          item.moving_average = movingAvg(value, MOVING_AVERAGE_YEARS, index);
        }
      }, historicalData);
      groupedByCategoryName[key] = groupedByCategoryName[key].filter(
        yearItem => Number(yearItem.water_year) >= curYear - HISTORICAL_YEARS_TO_SHOW,
      );

      // Delete the property if there is no content
      if (isEmpty(groupedByCategoryName[key])) delete groupedByCategoryName[key];
    }, groupedByCategoryName);

    //Get the list of categories to populate the categories dropdown selector
    const categoriesDropdownList = Object.keys(groupedByCategoryName).map(key => {
      return {
        category_shortname: key,
      };
    });

    setLicCategoriesDropdown(categoriesDropdownList);
    setUsageData(groupedByCategoryName);
  }, [data]);

  // Helpers
  const onCategoryChange = value => {
    if (value) {
      setLicCategorySelected(value);
    }
  };
  const aggregateAllLicences = item => {
    return item.resources.reduce((a, b) => ({
      water_year: a.water_year,
      category_shortname: ALL_CATEGORY_DROPDOWN,
      water_usage: a.water_usage + b.water_usage,
      number_shares: a.number_shares + b.number_shares,
    }));
  };

  const movingAvg = (array, countBefore, countAfter) => {
    let result = 0;
    for (let i = countAfter - countBefore + 1; i <= countAfter; i++) {
      result += (array[i]?.number_shares && array[i]?.water_usage / array[i]?.number_shares) * 100;
    }
    return result / countBefore;
  };

  const getVal = (obj, key1, key2) => {
    if (obj && obj[key2] !== 0) {
      return (obj[key1] / obj[key2]) * 100;
    }
  };

  // Components
  let usageSelectionBtn = !isEmpty(data) && (
    <LicenceCategorySelector
      listOfCategories={licCategoriesDropdown}
      onCategoryChange={onCategoryChange}
      addAllOption={false}
    />
  );

  const renderCustomTooltip = ({ payload }) => {
    if (payload && payload[0] && payload[0].payload) {
      return (
        <div className="ci-recent-chart-tooltip">
          <ul className="ci-recent-chart-tooltip-item-ul">
            <li className="list-no-style-type">
              {`Water year:  ${moment(payload[0].payload.water_year, 'YYYY').format('YYYY')}`}
            </li>
            {payload.map((data, index) => (
              <li
                key={index}
                className="list-no-style-type custom-recharts-tooltip-item"
                style={{ color: data.color }}
              >
                {`${startCase(data.name)}: ${Math.round(data.value)} %`}
              </li>
            ))}
          </ul>
        </div>
      );
    } else {
      return null;
    }
  };

  const refactorDownloadedData = (usageData, licCategorySelected) => {
    let finalData = [];

    if (isEmpty(usageData)) {
      return finalData;
    }

    const categoryItemValues = Object.values(usageData).flat();
    const groupedCategoryItems = groupBy(categoryItemValues, 'water_year');
    const waterYearKeys = Object.keys(groupedCategoryItems);

    finalData = waterYearKeys.map(water_year => {
      const waterYearItem = groupedCategoryItems[water_year];
      let result = {};
      waterYearItem.map(waterYearItemr => {
        if (
          waterYearItemr.category_shortname === licCategorySelected ||
          licCategorySelected === 'All licences'
        ) {
          result[waterYearItemr.category_shortname] = formatNumberDecimals(
            (waterYearItemr.water_usage / waterYearItemr.number_shares) * 100,
          );
        }
        return {
          ...result,
        };
      });
      return {
        water_year,
        ...result,
      };
    });

    return finalData;
  };

  return (
    !isEmpty(usageData) && (
      <ChartContainer
        pageTitle={config.historicalUsageUtilisationGraph.title}
        graphIntro={config.historicalUsageUtilisationGraph.intro}
      >
        {!isMobile && (
          <DownloadLink
            data={refactorDownloadedData(usageData, licCategorySelected)}
            filename={`${licCategorySelected.toLowerCase().replaceAll(' ', '_')}_utilisation`}
            stationType="water_source"
            dataType={`historical usage utilisation`}
          />
        )}
        <SelectionBtnsGroup left={usageSelectionBtn} />
        <ChartResponsiveContainer
          isEmptyChart={isEmpty(usageData[licCategorySelected])}
          customHeight={{ desktop: 400, mobile: 400 }}
        >
          <ComposedChart
            className="composed-chart"
            width={isXL ? 100 : 98}
            data={usageData[licCategorySelected]}
            margin={{ left: isMobile ? 5 : 20, bottom: 30, top: 50 }}
          >
            <XAxis
              dataKey="water_year"
              tickFormatter={v => formatLabelWaterYear(v)}
              type="category"
              dy={25}
              tick={{ fill: 'black' }}
            />
            <YAxis
              interval="preserveStartEnd"
              label={{
                value: 'ML used per share (%)',
                angle: -90,
                position: 'left',
                dx: isMobile ? 15 : 0,
                dy: -80,
              }}
              tickFormatter={v => formatNumberDecimals(v)}
              type="number"
              tick={{ fill: 'black' }}
              domain={[0, 100]}
            />
            <Tooltip
              content={payload => renderCustomTooltip(payload)}
              cursor={{ fill: 'transparent' }}
            />
            <Bar
              key={'category_shortname'}
              type="monotone"
              fillOpacity={1}
              name={licCategorySelected}
              dataKey={val => getVal(val, 'water_usage', 'number_shares')}
              fill="#004ba3"
              stroke="#004ba3"
            />
            <Line
              dataKey={'moving_average'}
              strokeWidth={2}
              stroke="#f36c21"
              name={`${MOVING_AVERAGE_YEARS} year moving average`}
              dot={false}
            />
          </ComposedChart>
        </ChartResponsiveContainer>
      </ChartContainer>
    )
  );
}

HistoricalUsageUtilisationGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};
