// Libraries
import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

// Styles
import './pageTop.scss';

// Context
import { MobileContext } from '../../contexts/MobileContext';
import { StationAlertCtx } from '../../contexts/StationAlertContext';

// Components
import Title from '../title/Title';

// Helpers
import { createMarkup } from '../../helpers/Utils';
import { calAlerts } from '../../helpers/AlertsHelper';

export default function PageTop({
  className,
  title,
  intro,
  subIntro,
  setModalLink,
  modalTargetId,
}) {
  const location = useLocation();
  const { isMobile } = useContext(MobileContext);
  const { stationAlerts } = useContext(StationAlertCtx);

  const [showList, setShowList] = useState(false);
  const [customStyle, setCustomStyle] = useState('');

  const containerElement = document.querySelector('.page-top');
  const innerElement = containerElement
    ? containerElement.querySelectorAll('#storageCommentaryLink')
    : null;

  const { countEmailAlerts, alertEmail, email } = calAlerts(stationAlerts);

  if (isMobile && innerElement) {
    for (var i = 0; i < innerElement.length; i++) {
      innerElement[i].setAttribute('data-toggle', 'modal');
      innerElement[i].setAttribute('data-target', modalTargetId);
    }
  }

  const clickHandler = event => {
    const closestElement = event.target.closest('A');
    if (closestElement instanceof HTMLAnchorElement) {
      const link = closestElement.getAttribute('href');
      setModalLink && setModalLink(link);
    }
  };

  useEffect(() => {
    if (location.pathname.endsWith('/my-alert')) {
      setShowList(true);
    } else if (location.pathname.endsWith('/archived-updates')) {
      setCustomStyle('archived');
    } else {
      setShowList(false);
      setCustomStyle('');
    }
  }, [location.pathname]);

  return (
    <div id={'page-top'} className={`page-top ${className}`}>
      <Title customStyle={customStyle} title={title} />
      {/* show different info when mobile */}
      {isMobile && showList && (
        <ul className="page-top-info-list">
          <li>
            You have{' '}
            <span>
              {countEmailAlerts} email {alertEmail}
            </span>{' '}
            {countEmailAlerts > 0 && `going to ${email}`}
          </li>
        </ul>
      )}
      {intro && !isMobile && (
        <div dangerouslySetInnerHTML={createMarkup(intro)} className="page-top-intro" />
      )}
      {subIntro &&
        subIntro.map((text, index) => (
          <div
            key={index}
            dangerouslySetInnerHTML={createMarkup(text)}
            className={`page-top-subIntro${index === subIntro.length - 1 ? '-lastItem' : ''}`}
            onClick={isMobile ? event => clickHandler(event) : () => {}}
          />
        ))}
    </div>
  );
}

PageTop.propTypes = {
  className: PropTypes.string,
  title: PropTypes.any,
  intro: PropTypes.string,
  subIntro: PropTypes.array,
  setModalLink: PropTypes.func,
  modalTargetId: PropTypes.string,
};
