// Libraries
import { isEmpty, isNil, toString } from 'lodash';

// Helpers
import {
  getAllDamsForOverview,
  getAllStationsByType,
  getLatestResourcesByDamId,
  getDamsByID,
} from '../../../helpers/ApiHelper';
import { getMultiSiteData } from '../../../helpers/WaterDataApiHelper';
import { flatObject } from '../../../helpers/ProcessDataHelper';

// Constant
import constant from '../../../constants/Constants';
const EX_SITES = constant.SPECIAL_SITES.map(item => toString(item));
const EX_DAM_SITES = constant.SPECIAL_DAM_SITES.map(item => toString(item));

const filloutOverviewDams = (overviewDams, storageResources) => {
  if (Array.isArray(overviewDams)) {
    const result = overviewDams.map(dam => {
      const resourceItem = storageResources.find(resource => {
        return resource.id === dam.station_id;
      });
      return {
        ...dam,
        volume: resourceItem?.resources[0]?.volume,
      };
    });
    return result;
  } else {
    return [];
  }
};

/**
 * Calculation of storage overview
 * @param {} damsResources
 */
const calcStorageSummary = damData => {
  const storageSummary = {
    total_capacity: 0,
    total_volume: 0,
  };
  if (Array.isArray(damData)) {
    damData.reduce((summary, damItem) => {
      const { full_volume, volume } = damItem;

      if (!isNil(full_volume)) {
        const vol = volume ? volume : 0;
        summary.total_capacity = summary.total_capacity + full_volume;
        summary.total_volume = summary.total_volume + vol;
      }
      return summary;
    }, storageSummary);
  }
  return storageSummary;
};

export const getLatestStorageResources = async (
  toSum = false,
  includeWeirs = false,
  siteList = [],
  toProcess = true,
) => {
  const dams = await getAllDamsForOverview(toSum);
  const filtered = siteList.filter(item => !EX_SITES.includes(item.station_id)); // Remove lake vic, which is for additional call
  let siteIds = filtered.map(item => item.station_id);
  let stationIds = filtered.map(item => item.id);

  if (dams && isEmpty(siteIds)) {
    const stationKey = toSum ? 'station_id' : 'dam_id';
    const filterDams = dams.filter(item => !EX_SITES.includes(item.station_id));
    siteIds = filterDams.map(item => item[stationKey]);
    stationIds = filterDams.map(item => item.id);
  }
  const variables = ['ActiveStoragePercentage', 'ActiveStorageVolume'];
  const totalStorage = ['TotalStorageVolume', 'ActiveStoragePercentage'];

  let damsResources = await getMultiSiteData(stationIds, variables, '', '', toProcess, true);
  includeWeirs &&
    (await getAllStationsByType('weir', async function (result) {
      if (!isEmpty(result)) {
        const weirs = result.map(item => item.id);
        const weirsResources = await getMultiSiteData(weirs, totalStorage, '', '', toProcess, true);
        damsResources = damsResources.concat(weirsResources);
      }
    }));

  await Promise.all(
    EX_SITES.map(async siteItem => {
      const siteData = await getLatestResourcesByDamId(siteItem);
      if (siteData.data && !isEmpty(siteData.data.resources) && Array.isArray(damsResources)) {
        const { id, resources } = siteData.data;
        damsResources.push({
          id: id,
          resources: [
            {
              volume: resources[0].volume,
              ...(resources[0].volume_perc && { volume_perc: resources[0].volume_perc }),
            },
          ],
        });
      }
    }),
  );

  await Promise.all(
    EX_DAM_SITES.map(async item => {
      const siteData = await getDamsByID(item.split(','));
      const totalStorageVol = await getMultiSiteData(
        [siteData[0].id],
        totalStorage,
        '',
        '',
        toProcess,
        true,
      );
      const isDamExist = damsResources.findIndex(dataItem => dataItem.id === item);

      if (isDamExist === -1) {
        damsResources = damsResources.concat(totalStorageVol);
      } else {
        const storageVol = totalStorageVol[0].resources.find(res => res.volume);
        damsResources[isDamExist].resources[0] = {
          ...damsResources[isDamExist].resources[0],
          volume: storageVol.volume - (siteData ? siteData[0]?.dead_storage : 0),
          total_volume: storageVol.volume - (siteData ? siteData[0]?.dead_storage : 0),
        };
      }
    }),
  );

  if (toSum) {
    const filledDamData = filloutOverviewDams(dams, damsResources);
    damsResources = calcStorageSummary(filledDamData);
  }

  return damsResources;
};

export const parseSurfaceWaterResponse = object => {
  if (isEmpty(object)) {
    return {};
  }
  let { used_allocation, available_allocation } = object;
  used_allocation = flatObject(used_allocation);
  available_allocation = flatObject(available_allocation);
  return { used_allocation, available_allocation };
};
