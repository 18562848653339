import constants from '../../../constants/Constants';
const { TAB_OPTIONS } = constants;

export default Object.freeze({
  STORAGE: {
    type: 'storage',
    text: 'stored',
    title: 'Dam storage',
    dataKey1: 'total_volume',
    dataKey2: 'total_capacity',
    popupContent: [
      {
        key: 'volume_perc',
        title: 'Percent full',
        unit: '%',
        conversion: 1,
        alwaysRounded: true,
      },
      {
        key: 'volume',
        title: 'Volume',
        unit: 'GL',
        conversion: 1 / 1000,
        alwaysRounded: false,
      },
    ],
    popupConfig: {
      scrollTarget: 'AMSRecent',
      tabTarget: TAB_OPTIONS.storage,
      linkLabel: 'View history',
    },
    radius: 'full_volume',
    shade: 'volume_perc',
    threshold: [80, 60, 40, 20],
    unit: '%',
  },
  AVAILABILITY: {
    type: 'availability',
    text: 'available',
    title: 'Available',
    dataKey: 'available_allocation.total',
    popupContent: [
      {
        key: 'allocation_used',
        title: 'Used',
        unit: 'GL',
        conversion: 1 / 1000,
        alwaysRounded: false,
      },
      {
        key: 'allocation_available',
        title: 'Available',
        unit: 'GL',
        conversion: 1 / 1000,
        alwaysRounded: false,
      },
    ],
    popupConfig: {
      scrollTarget: '',
      tabTarget: TAB_OPTIONS.allocation,
      linkLabel: 'View details',
    },
    radius: 'allocation',
    shade: {
      target: 'allocation_available',
      target2: 'allocation',
    },
    threshold: [80, 60, 40, 20],
    unit: ' GL',
  },
  TRADES: {
    type: 'trades',
    text: 'traded',
    title: 'Allocation traded this month',
    dataKey: 'monthly.total_allocation.volume',
    popupContent: [
      {
        key: 'traded_volume',
        title: 'Volume',
        unit: 'ML',
        conversion: 1,
        alwaysRounded: false,
      },
      {
        key: 'average_price',
        title: 'Avg. Price',
        unit: '/ML',
        conversion: 1 / 100,
        alwaysRounded: false,
        monetary: true,
      },
    ],
    popupConfig: {
      scrollTarget: '',
      tabTarget: TAB_OPTIONS.trading,
      linkLabel: 'View details',
    },
    radius: 'traded_volume',
    shade: 'average_price',
    threshold: [80000, 40000, 20000, 10000],
    unit: ' GL',
    monetary: false,
  },
});
