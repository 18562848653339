// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './notification.scss';

// Constant
const component = 'notification';

export default function Notification({ show }) {
  return (
    <div className={`${component}${show ? '-show' : ''}`}>
      This site has been saved to your favourites
    </div>
  );
}

Notification.propTypes = {
  show: PropTypes.bool,
};
