import React from 'react';
import PropTypes from 'prop-types';

const TradeSVG = ({ customClass }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26.561"
      height="26.285"
      viewBox="0 0 26.561 26.285"
      className={customClass ? customClass : ' '}
    >
      <path
        id="np_trade_560173_000000"
        d="M21.914,25.614A1.259,1.259,0,0,1,20.833,27L13.3,27.97a1.615,1.615,0,0,1-1.776-1.853l1.081-7.262a1.233,1.233,0,0,1,1.428-1.043h.038a1.233,1.233,0,0,1,1.043,1.428l-.58,3.939L25.426,12.289A1.284,1.284,0,1,1,27.242,14.1L16.275,25.074l4.209-.54a1.242,1.242,0,0,1,1.43,1.081Zm5.947-.888V24.8a1.233,1.233,0,0,0,1.428,1.043L33,25.306,22.3,36a1.284,1.284,0,0,0,1.815,1.815L35.2,26.7l-.58,4.441a1.289,1.289,0,0,0,1.12,1.428,1.259,1.259,0,0,0,1.39-1.081l.927-7.493A1.615,1.615,0,0,0,36.2,22.217l-7.3,1.081a1.214,1.214,0,0,0-1.044,1.428Z"
        transform="translate(-11.508 -11.912)"
      />
    </svg>
  );
};

export default TradeSVG;

/* Props */
TradeSVG.propTypes = {
  /**
   * This change the header arrow's styling
   * It expects string that represent a css class
   * Example can be seen in restrictions.scss, .restrictions-body-arrow
   *
   * Side Note:
   * Change both height and width to control size
   * Change fill to change color
   */
  customClass: PropTypes.string,
};
