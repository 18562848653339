// Lib
import React from 'react';
import PropTypes from 'prop-types';

// Style
import './generalTabItem.scss';

const component = 'general-tab-item';

const GeneralTabItem = ({ tab, onSelect, isSelected = false, isDisabled = false }) => {
  return (
    <div
      id={tab.id}
      onClick={e => {
        if (!isSelected && !isDisabled) {
          onSelect(e.target.id);
        }
      }}
      className={`${component} ${
        isDisabled ? `${component}-disabled` : isSelected ? `${component}-selected` : ''
      }`}
    >
      {tab.name}
    </div>
  );
};

export default GeneralTabItem;

GeneralTabItem.propTypes = {
  tab: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
};
