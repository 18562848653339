// Library
import React, { useState, useEffect, useContext } from 'react';
import { isEmpty, startCase } from 'lodash';

// Style
import './globalSearchBody.scss';

// Component
import BackLink from '../backLink/BackLink';
import GlobalSearchBar from '../globalSearch/GlobalSearchBar';
import Pagination from '../generalPagination/Pagination';

// Helpers
import { createMarkup } from '../../helpers/Utils';
import { getPathname, buildUrl } from '../../helpers/UrlGenerator';
import { getSearchResult, getSearchSuggestion } from '../../helpers/ApiHelper';

// Context
import { SearchContext } from '../../contexts/SearchContext';

// Constant
const container = 'global-search';
const resultPerPage = 10;
const linksObj = {
  gauge: 'river-data',
  bore: 'river-data',
  dam: 'storage',
  weir: 'storage',
  default: 'updates',
};

const GlobalSearchBody = () => {
  // Context
  const { searchTerm, setSearchTerm, initiateSearch, setInitiateSearch } =
    useContext(SearchContext);

  // State
  const [recommendation, setRecommendation] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [applicableResult, setApplicableResult] = useState([]);
  const [activeResult, setActiveResult] = useState([]);
  const [searchFilters, setSearchFilters] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  // Life Cycle
  useEffect(() => {
    if (initiateSearch) {
      (async () => {
        const results = await getSearchResult(searchTerm);
        const suggestion = await getSearchSuggestion(searchTerm);
        const filters = generateFilters(results);
        setSearchFilters(filters);
        setSearchResult(results);
        setApplicableResult(results);
        setActiveResult(generateActiveResults(results, 1));
        setRecommendation(findBestSuggestion(suggestion));
        setInitiateSearch(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initiateSearch]);

  useEffect(() => {
    if (!isEmpty(activeResult) && !isEmpty(searchFilters)) {
      setActiveResult(generateActiveResults(searchResult, currentPage, searchFilters));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResult, currentPage, searchFilters]);

  // Helpers
  const generateFilters = res => {
    const uniqueType = res.reduce((acc, resItem) => {
      acc.add(resItem._type);
      return acc;
    }, new Set());

    const filters = [...uniqueType].map(type => ({
      type: type,
      checked: false,
    }));

    return filters;
  };

  const generateLinks = res => {
    const waterSource = res._source;
    const linkType = res._type;
    const path = `${buildUrl(
      getPathname(`${waterSource.water_source_id} ${waterSource.water_source_name}`),
      `/${linksObj[linkType] || linksObj.default}`,
    )}`;
    return `${window.location.origin}${path}${
      waterSource.station_id ? `#${waterSource.station_id}` : ''
    }`;
  };

  const generateActiveResults = (res, currentPage, filter = []) => {
    const requirement = filter.reduce((acc, item) => {
      if (item.checked) {
        acc.push(item.type);
      }

      return acc;
    }, []);

    let filteredResult;
    if (isEmpty(requirement)) {
      filteredResult = res;
    } else {
      filteredResult = res.reduce((acc, item) => {
        const target = requirement.findIndex(req => req === item._type);
        if (target !== -1) {
          acc.push(item);
        }
        return acc;
      }, []);
    }

    setApplicableResult(filteredResult);
    const firstPageIndex = (currentPage - 1) * resultPerPage;
    const lastPageIndex = firstPageIndex + resultPerPage;
    return filteredResult.slice(firstPageIndex, lastPageIndex);
  };

  const handleFilterOnChange = target => {
    const { value, checked } = target;
    setCurrentPage(1);
    setSearchFilters(prevState => {
      const target = prevState.findIndex(item => item.type === value);
      if (target !== -1) {
        prevState[target].checked = checked;
        return [...prevState];
      } else {
        return [...prevState];
      }
    });
  };

  const handleClearAll = () => {
    setCurrentPage(1);
    setSearchFilters(prevState => {
      prevState.forEach(filter => {
        filter.checked = false;
      });
      return [...prevState];
    });
  };

  const findBestSuggestion = suggestions => {
    if (isEmpty(suggestions)) return '';
    const res = suggestions[0];
    const originalSearch = res.text;

    if (isEmpty(res.options)) return '';

    const rec = res.options.find(optionItem => {
      if (optionItem.text !== originalSearch) {
        return true;
      }
      return false;
    });
    return (rec && rec.text) || '';
  };

  // Mini Component
  const resultCount = () => {
    return <div className={`${container}-counts`}>About {applicableResult.length} results</div>;
  };

  const textRecommendation = () => {
    return (
      <div className={`${container}-recommendation`}>
        Did you mean:
        <span
          onClick={() => {
            setSearchTerm(recommendation);
            setInitiateSearch(true);
          }}
          className={`${container}-recommendation-content`}
        >
          {recommendation}
        </span>
      </div>
    );
  };

  const filterOptions = () => {
    return (
      <div className={`${container}-body-sort-container`}>
        {!isEmpty(searchFilters) &&
          searchFilters.map(filter => {
            return (
              <div key={filter.type} className={`${container}-body-sort-options`}>
                <input
                  type="checkbox"
                  value={filter.type}
                  name={filter.type}
                  checked={filter.checked}
                  onChange={e => {
                    handleFilterOnChange(e.target);
                  }}
                />
                <label htmlFor={filter.type}>{startCase(filter.type)}</label>
              </div>
            );
          })}
      </div>
    );
  };

  const resultLinks = () => {
    return (
      <>
        {!isEmpty(activeResult) &&
          activeResult.map((res, ind) => {
            return (
              <div className={`${container}-body-result-item`} key={ind}>
                <div
                  className={`${container}-body-result-header`}
                  dangerouslySetInnerHTML={createMarkup(Object.values(res.highlight)[0][0])}
                />
                {res.highlight.water_source_name && Object.values(res.highlight).length === 1 && (
                  <div
                    className={`${container}-body-result-subheader`}
                    dangerouslySetInnerHTML={createMarkup(
                      res.highlight.station_name || res._source.station_title,
                    )}
                  />
                )}

                <a
                  className={`${container}-body-result-link`}
                  // target="_blank"
                  rel="noopener noreferrer"
                  href={generateLinks(res)}
                >
                  {generateLinks(res)}
                </a>
              </div>
            );
          })}
      </>
    );
  };

  return (
    <div className={container}>
      <BackLink />
      <GlobalSearchBar type="global" />
      {!isEmpty(searchResult) ? (
        <>
          {resultCount()}
          {recommendation && textRecommendation()}
          <hr />
          <div className={`${container}-body`}>
            <div className={`${container}-body-sort`}>
              <div className={`${container}-body-sort-title`}>Refine results</div>
              {filterOptions()}
              <div
                onClick={() => {
                  handleClearAll();
                }}
                className={`${container}-body-sort-clear`}
              >
                Clear all
              </div>
            </div>
            <div className={`${container}-body-result`}>
              {resultLinks()}
              <Pagination
                className={'globalSearch'}
                currentPage={currentPage}
                totalCount={applicableResult.length}
                pageSize={resultPerPage}
                onPageChange={page => setCurrentPage(page)}
              />
            </div>
          </div>
        </>
      ) : (
        <div className={`${container}-body-null`}>
          {searchTerm === '' ? 'Start typing to search.' : `There is no result for "${searchTerm}"`}
        </div>
      )}
    </div>
  );
};

export default GlobalSearchBody;
