// Libraries
import React, { useRef, useContext, useState, useEffect } from 'react';
import { GeoJSON } from 'react-leaflet';
import L from 'leaflet';
import { isEmpty } from 'lodash';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';

// Styles
import './riverSection.scss';

// Context
import { MobileContext } from '../../contexts/MobileContext';
import { AppContext } from '../../contexts/AppContext';

// Components
import FlowClassPopup from '../mapPopup/FlowClassPopup';
import Modal from '../modal/Modal';

// Helper
import { getRofCounter } from '../../helpers/ApiHelper';

// Constants
import mapFilterConstants from '../../constants/MapFilterConstants';

export default function FlowClassMap({ data }) {
  const { isMobile } = useContext(MobileContext);
  const { waterSource } = useContext(AppContext);
  const geoJsonRef = useRef(null);
  const [modalFeature, setModalFeature] = useState(null);
  const [modalRofCounter, setModalRofCounter] = useState(null);
  const [rofData, setRofData] = useState(undefined);

  useEffect(() => {
    (async () => {
      const result = await getRofCounter(waterSource.water_source_id);
      if (!isEmpty(result)) {
        setRofData(result.resources);
      } else {
        setRofData([]);
      }
    })();
  }, [waterSource.water_source_id]);

  const setFillColorStyle = feature => {
    let style = { color: 'none' };
    const flowClass = mapFilterConstants.FLOW_CLASS_LEGEND.find(
      i => i.currentFlowClass === feature.properties.FLOW_CLASS,
    );
    if (flowClass) style.color = flowClass.strokeColor;
    return style;
  };

  const setBorderStyle = feature => {
    let style = { color: 'none' };
    let layerId;
    switch ('Y') {
      case feature.properties.RESTRICTED_BY_ROF:
        layerId = '2';
        break;
      case feature.properties.RESTRICTED_BY_S324:
        layerId = '1';
        break;
      default:
        break;
    }
    const prop = mapFilterConstants.RESTRICTION_TYPE_LEGEND.find(i => i.layerId === layerId);
    if (prop) style.color = prop.strokeColor;
    return style;
  };

  const onEachFeature = (feature, layer) => {
    let rofCounter = null;
    if (!isEmpty(rofData)) {
      const result = rofData.find(item => item.wmz_id === feature.properties.WMZ_ID);
      rofCounter = result ? result.counter : null;
    }

    if (feature) {
      const popupOptions = { className: 'order-usage-popup' };
      const popupContentHtml = ReactDOMServer.renderToString(
        <FlowClassPopup feature={feature} rofCounter={rofCounter} />,
      );
      if (isMobile) {
        layer.on('click', () => {
          setModalFeature(feature);
          setModalRofCounter(rofCounter);
          document.getElementById('flow-class-modal-div').click();
        });
      } else {
        layer.bindPopup(popupContentHtml, popupOptions);
      }
    }
  };

  return (
    typeof rofData !== 'undefined' && (
      <div className="river-section">
        {isMobile && (
          <>
            <Modal
              id="flow-class-modal"
              body={
                modalFeature && (
                  <FlowClassPopup feature={modalFeature} rofCounter={modalRofCounter} />
                )
              }
            />
            <div id="flow-class-modal-div" data-toggle="modal" data-target="#flow-class-modal" />
          </>
        )}
        <GeoJSON
          ref={geoJsonRef}
          weight={12}
          data={!isEmpty(data) ? data : {}}
          style={setBorderStyle}
          onEachFeature={onEachFeature}
          renderer={L.svg({ padding: 1 })}
        />
        <GeoJSON
          ref={geoJsonRef}
          weight={7}
          data={!isEmpty(data) ? data : {}}
          style={setFillColorStyle}
          onEachFeature={onEachFeature}
          renderer={L.svg({ padding: 1 })}
        />
      </div>
    )
  );
}

FlowClassMap.prototype = {
  data: PropTypes.array,
};
