// Library
import React, { useContext, useEffect } from 'react';

// Style
import './download.scss';

// Component
import BodyContainer from '../../../layout/body/BodyContainer';
import PageTop from '../../../components/pageTop/PageTop';
import GeneralTabs from '../../../components/generalComponents/tabs/GeneralTabs';
import ArchivedUpdates from '../../update/ArchivedUpdates';
import StationDownload from '../../../components/pageComponents/download/new/StationDownload';
import GroundwaterDownload from '../../../components/pageComponents/download/boreConstructionReport/GroundwaterDownload';

// Context
import { DownloadContext } from '../../../contexts/DownloadContext';
import { MobileContext } from '../../../contexts/MobileContext';

// Constant
import config from '../../../abstract/IntroText.json';
import constants from '../../../components/pageComponents/download/new/DataDownloadConstants';
const text = config.download.new;
const component = 'download';

// Main
const Download = () => {
  // Context
  const { isMobile } = useContext(MobileContext);
  const {
    downloadType,
    setDownloadType,
    setHydrometric,
    setSelectedStations,
    setStationInfoType,
    autoFetch,
  } = useContext(DownloadContext);

  useEffect(() => {
    reset();

    return () => {
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadType]);

  const reset = () => {
    if (!autoFetch) {
      setSelectedStations([]);
      setStationInfoType('');
      setHydrometric([]);
    }
  };

  return (
    <div>
      {!isMobile && (
        <BodyContainer page>
          <PageTop title={<PageTopTitle />} intro={text.intro} />
          <GeneralTabs
            tabs={constants.TAB_OPTIONS}
            onSelect={id => {
              setDownloadType(id);
            }}
            defaultTab={downloadType}
          />
          <PageContent downloadType={downloadType} />
        </BodyContainer>
      )}
    </div>
  );
};

// Mini Component
const PageTopTitle = () => {
  return (
    <div className={`${component}-title`}>
      <span>{text.title}</span>
    </div>
  );
};

const PageContent = ({ downloadType }) => {
  let content;
  switch (downloadType) {
    case constants.UPDATES:
      content = <ArchivedUpdates />;
      break;
    case constants.GROUNDWATER_SITES:
      content = <GroundwaterDownload />;
      break;
    case constants.BORE:
    case constants.GAUGE:
    case constants.DAM:
    case constants.METEOROLOGICAL:
    case constants.WEIR:
    case constants.METRO_DAM:
      content = <StationDownload />;
      break;
    default:
      break;
  }

  return <>{content}</>;
};

export default Download;
