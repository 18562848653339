// Libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

// Styles
import './siteInputDropdown.scss';

// Component
import DownloadCheckBox from './DownloadCheckBox';

const component = 'input-dropdown';

const SiteInputDropdown = ({
  filterList,
  warningMsg,
  placeholder,
  menuList,
  changeItem,
  selected,
  inputReadOnly = false,
  limit,
  toFormat = true,
  pageSource = '',
}) => {
  const [inputValue, setInputValue] = useState('');

  return (
    <div
      className={`${component} dropdown`}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <input
        readOnly={inputReadOnly}
        id="find-input"
        className={`${component}-input expand ${pageSource}`}
        placeholder={placeholder}
        autoComplete="off"
        onChange={event => {
          setInputValue(event.target.value);
          filterList(event.target.value);
        }}
        value={inputValue ? inputValue : ''}
      />
      <div
        className={`${component}-menu-content dropdown-menu ${pageSource}`}
        id="drop-down-content"
      >
        <div className={`${component}-menu-warning`}>{warningMsg}</div>
        {!isEmpty(menuList) && !warningMsg && (
          <DownloadCheckBox
            options={menuList}
            onSelect={changeItem}
            groupName="watersource_group"
            selected={selected}
            limit={limit}
            toFormat={toFormat}
          />
        )}
      </div>
    </div>
  );
};

export default SiteInputDropdown;

SiteInputDropdown.propTypes = {
  filterList: PropTypes.func,
  warningMsg: PropTypes.string,
  placeholder: PropTypes.string,
  menuList: PropTypes.array,
  changeItem: PropTypes.func,
  inputReadOnly: PropTypes.bool,
  selected: PropTypes.array,
  limit: PropTypes.number,
  toFormat: PropTypes.bool,
  pageSource: PropTypes.string,
};
