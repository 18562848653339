// Library
import React from 'react';

// Style
import './favouritesMini.scss';
import '../../layout/grid.scss';

// Component
import Favourites from '../../components/favourites/Favourites';

// Constant
const component = 'favourites-mini';

export default function FavouritesMini() {
  return (
    <div data-testid="favourites-mini" className={component}>
      <Favourites page={true} />
    </div>
  );
}
