// Libraries
import React, { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';

// Styles
import './navBar.scss';

// Context
import { MobileContext } from '../../contexts/MobileContext';

// Components
import NavBarItem from '../../components/navLinkItems/NavBarItem';
import Logo from '../../components/logo/Logo';
import SideDrawer from '../../components/sideDrawer/SideDrawer';
import GlobalSearchBar from '../../components/globalSearch/GlobalSearchBar';

// Assets
import menuIcon from '../../image/icons/menu.png';
import constants from '../../constants/Constants';

// Constant
const container = 'page-nav-bar';

export default function NavBar() {
  const location = useLocation();
  const { isMobile, isLarge } = useContext(MobileContext);
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

  const drawerToggleClickHandler = () => {
    setSideDrawerOpen(!sideDrawerOpen);
  };

  return (
    <>
      {sideDrawerOpen && (
        <div className="side-drawer-overlay" onClick={() => setSideDrawerOpen(false)} />
      )}
      <header className={container}>
        <div className={`${container}-title`}>
          <Logo className="waternsw-logo" />
          <div className={`${container}-title-text`}>WaterInsights</div>
          {!isLarge && location.pathname !== '/search' && <GlobalSearchBar type="global" />}
        </div>
        <div className={`${container}-link-item-group`}>
          {!isMobile ? (
            constants.LINK_ITEMS.map((linkItem, index) => <NavBarItem key={index} {...linkItem} />)
          ) : (
            <button
              data-toggle="slide-collapse"
              href="#"
              data-target="#sideDrawerMenu"
              className="navbar-toggler collapsed cust-navbar-toggler"
              onClick={() => drawerToggleClickHandler()}
            >
              <img src={menuIcon} alt="Menu icon" className="hamburger-menu-icon" />
            </button>
          )}
        </div>
      </header>
      {isMobile && (
        <SideDrawer
          sideDrawerOpen={sideDrawerOpen}
          onCloseBtnClicked={() => drawerToggleClickHandler()}
        />
      )}
    </>
  );
}
