// Libraries
import React from 'react';

// Styles
import './popupBody.scss';

// Component
import DataQualityText from '../../generalComponents/dataQuality/DataQualityText';

// Helpers
import { formatPopupContentNumber } from '../../../helpers/Utils';

const component = 'popup-body';

export default function PopupBody({ title, data, unit, isSameDate, dataKey, decimals }) {
  return (
    <div className={`row ${component}`}>
      <div className="col-6">{title}</div>
      <div className={`col-3 ${component}-value`}>
        <DataQualityText qualityCode={data?.qualityCode} isMarked={false}>
          {formatPopupContentNumber(data?.value, dataKey, decimals)}
        </DataQualityText>
      </div>
      <div className={`col-2 ${component}-unit`}>
        <DataQualityText qualityCode={data?.qualityCode} retainOriginal>
          {`${unit}`}
        </DataQualityText>
        {isSameDate ? '*' : ''}
      </div>
    </div>
  );
}
