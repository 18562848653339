// Libraries
import React from 'react';
import PropTypes from 'prop-types';

export default function OnboardingTooltip({
  steps,
  currentStep,
  onStepClick,
  goToStep,
  onSkipOnboarding,
  setShowOnboarding,
}) {
  return (
    <>
      {steps && currentStep > -1 && (
        <div className="onboarding-tooltipReferenceLayer">
          <div className="onboarding-tooltip" style={{ opacity: '0' }} role="dialog">
            <div className="onboarding-tooltip-header">
              <h1 className="onboarding-tooltip-title"></h1>
            </div>
            <div className="onboarding-tooltiptext">
              {currentStep > -1 && steps[currentStep].text}
            </div>
            <div className="onboarding-bullets">
              <ul role="tablist">
                {steps.map((step, i) => (
                  <li key={i} role="presentation">
                    <a
                      href="#!"
                      className={`${currentStep === i ? 'active' : ''}`}
                      role="button"
                      onClick={() => {
                        goToStep(i);
                      }}
                    >
                      &nbsp;
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div className={`onboarding-arrow`}></div>
            <div className="onboarding-tooltipbuttons">
              {currentStep > -1 && currentStep !== steps.length - 1 && (
                <a
                  data-cy="onboarding"
                  href="#!"
                  className="onboarding-button onboarding-skipbutton onboarding-fullbutton"
                  role="button"
                  tabIndex={currentStep}
                  onClick={() => {
                    setShowOnboarding(false);
                    onSkipOnboarding();
                  }}
                >
                  Skip Tips
                </a>
              )}
              {currentStep > 0 && (
                <a
                  href="#!"
                  className="onboarding-button onboarding-prevbutton onboarding-fullbutton"
                  role="button"
                  tabIndex={currentStep}
                  onClick={() => {
                    onStepClick('backwards');
                  }}
                >
                  Back
                </a>
              )}
              <a
                href="#!"
                className="onboarding-button onboarding-nextbutton onboarding-fullbutton"
                role="button"
                tabIndex={currentStep}
                onClick={() => {
                  onStepClick('forward');
                }}
              >
                {currentStep > -1 && currentStep !== steps.length - 1 ? 'Next' : 'Got it'}
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

OnboardingTooltip.propTypes = {
  steps: PropTypes.array,
  currentStep: PropTypes.number,
  onStepClick: PropTypes.func,
  currentTargetElement: PropTypes.object,
  setcurrentStep: PropTypes.func,
  onClickSkipbutton: PropTypes.func,
  goToStep: PropTypes.func,
  onSkipOnboarding: PropTypes.func,
  setShowOnboarding: PropTypes.func,
};
