// Libraries
import React, { useState, useEffect, useContext, useLayoutEffect } from 'react';

import { isEmpty } from 'lodash';

// Style
import './graphAndDownloadGraph.scss';

// Components
import ChartCSVDownloadLink from '../../../../chartProperties/ChartCSVDownloadLink';
import Loader from '../../../../loader/Loader';
import GraphAndDownloadGraphItem from './GraphAndDownloadGraphItem';
import ResultContent from '../ResultContent';

// Helpers
import { getMultiSiteMetadata } from '../../../../../helpers/WaterDataApiHelper.jsx';
import {
  formatDownloadData,
  formatGraphData,
  genDownloadData,
  formatMetroDamStorageData,
  prepGraphParams,
  prepGraphDataRequestParams,
  fetchGraphData,
} from './graphAndDownloadGraphHelper';
import { formatVariableLabel } from '../filters/graphAndDownload/GraphAndDownloadHelper';

// Context
import { DownloadContext } from '../../../../../contexts/DownloadContext';

// Constant
import constants from '../DataDownloadConstants';
import hydroConstants from '../../../../../constants/HydrometricsConstants';
const component = 'graph-and-download-graph';
const genErrorMsg = statusCode => {
  let errorMsg;
  switch (statusCode) {
    case 204:
      errorMsg = 'No data found for the request criteria provided.';
      break;
    case 418:
      errorMsg = 'You found the teapot. Congrats.';
      break;
    case statusCode >= 500:
      errorMsg = 'We are unable to fetch the data due to server issue. Please try again later';
      break;
    case statusCode >= 400:
      errorMsg =
        "Filters' value are invalid. Please make sure what you select/input are valid value.";
      break;
    default:
      errorMsg = 'The server is unable to process your request. Please refresh and try again.';
      break;
  }
  return errorMsg;
};
const toggleOptions = ['Daily total', 'Cumulative total'];

const GraphAndDownloadGraph = () => {
  // Context
  const {
    selectedStations,
    hydrometric,
    interval,
    startDate,
    endDate,
    downloadType,
    genGraph,
    setGenGraph,
    dataType,
    setAutoFetch,
    autoFetch,
  } = useContext(DownloadContext);
  // State
  const [rawData, setRawData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [gaugesMetaData, setGaugesMetaData] = useState([]);
  const [errorStatus, setErrorStatus] = useState('');
  const [dataShowSpinner, setDataShowSpinner] = useState(false);
  const [graphShowSpinner, setGraphShowSpinner] = useState(false);
  const [showGraph, setShowGraph] = useState(false);
  const [csvData, setCsvData] = useState({});
  const [graphType, setGraphType] = useState('');

  const {
    hydrometricList,
    formattedHydrometricList,
    isFlowData,
    siteNames,
    siteIds,
    toShowFlag,
    graphTitleSite,
  } = prepGraphParams(hydrometric, selectedStations, interval);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    (async () => {
      if (toShowFlag && endDate && startDate && genGraph) {
        setDataShowSpinner(true);
        try {
          const { siteHydrometric, endpoint } = prepGraphDataRequestParams(
            downloadType,
            isFlowData,
            hydrometricList,
            interval,
            siteIds.split(',').length > 1,
          );

          const { data, qualities } = await fetchGraphData(
            siteIds,
            interval,
            siteHydrometric,
            startDate,
            endDate,
            endpoint,
            siteNames,
            dataType,
            isFlowData,
            downloadType,
            signal,
          );

          if (downloadType === 'StreamGauge') {
            const metaData = await getMultiSiteMetadata(siteIds.split(','), 'streamGauge');
            if (!isEmpty(metaData)) {
              setGaugesMetaData(metaData);
            }
          }

          if (!isEmpty(data)) {
            const formatted = isFlowData
              ? formatMetroDamStorageData(
                  data,
                  constants.METRO_DAM_VARIABLE[hydrometricList],
                  hydrometricList.toLowerCase(),
                  selectedStations,
                )
              : data;

            setRawData(formatted);
            formatDownloadData(formatted, qualities, setDownloadData);
          } else {
            setErrorStatus(204);
          }
        } catch (e) {
          setErrorStatus(e.response.status);
        } finally {
          setDataShowSpinner(false);
          setGenGraph(false);
          setGraphShowSpinner(true);
          setShowGraph(true);
          controller.abort();
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genGraph]);

  useEffect(() => {
    setErrorStatus('');
    setDataShowSpinner(false);
    setGraphShowSpinner(false);
    setShowGraph(false);
    setDownloadData([]);
    setGraphData([]);
    setGaugesMetaData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadType, selectedStations, hydrometric, interval, endDate, startDate, dataType]);

  useEffect(() => {
    if (!isEmpty(downloadData.data) && autoFetch) {
      setAutoFetch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadData.data, autoFetch]);

  useEffect(() => {
    if (!isEmpty(rawData) && graphShowSpinner) {
      formatGraphData(rawData, interval.id, setGraphData);
      setGraphShowSpinner(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rawData, graphShowSpinner]);

  useEffect(() => {
    setCsvData(genDownloadData(downloadData, downloadType, gaugesMetaData, interval.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadData.data, downloadType, gaugesMetaData]);

  useLayoutEffect(() => {
    if (graphData.Rainfall) {
      let cumulativeData = [];
      if (graphType === toggleOptions[0]) {
        formatGraphData(rawData, interval.id, setGraphData);
      } else {
        let cumulativeTotal = {};
        selectedStations.map(site => (cumulativeTotal[site.id] = 0));
        graphData.Rainfall.map(data => {
          let newData = { ...data };
          selectedStations.map(site => {
            cumulativeTotal[site.id] += newData[site.id];
            newData[site.id] = Number(
              cumulativeTotal[site.id].toFixed(hydroConstants.DATA_LABEL_FORMAT.rainfall.decimals),
            );
          });
          cumulativeData.push(newData);
        });
        setGraphData({ ...graphData, Rainfall: cumulativeData });
      }
    }
  }, [graphType]);

  return (
    <div className={component}>
      {toShowFlag && !isEmpty(downloadData.data) ? (
        <ResultContent>
          <div className={`${component}-title`}>
            <div className={`${component}-title-label`}>
              {`${formattedHydrometricList
                .split(',')
                .map(item => formatVariableLabel(item))
                .join(', ')} - ${graphTitleSite}`}
            </div>
            <div className={`${component}-title-btns`}>
              <ChartCSVDownloadLink
                data={csvData.resources}
                headers={csvData.headers}
                filename={`${formattedHydrometricList} - ${siteIds}`}
                toFormat={false}
                page="download"
              />
            </div>
          </div>
          {showGraph &&
            (!isEmpty(graphData) && !graphShowSpinner ? (
              Object.entries(graphData).map(([dataKey, dataValue], index) => (
                <GraphAndDownloadGraphItem
                  graphData={graphData}
                  dataKey={dataKey}
                  dataValue={dataValue}
                  siteIds={siteIds}
                  interval={interval}
                  hydrometric={formattedHydrometricList}
                  sites={selectedStations}
                  key={index}
                  setGraphType={setGraphType}
                  toggleOptions={dataKey === 'Rainfall' ? toggleOptions : []}
                />
              ))
            ) : (
              <Loader />
            ))}
        </ResultContent>
      ) : dataShowSpinner ? (
        <>
          <Loader />
        </>
      ) : (
        errorStatus && <div className={`${component}-empty-msg`}>{genErrorMsg(errorStatus)}</div>
      )}
    </div>
  );
};

export default GraphAndDownloadGraph;
