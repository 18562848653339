// Library
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

// Styles
import './interValleyTradeApplications.scss';

// Helper
import { formatNumberDecimals } from '../../../helpers/Utils';

// Context
import { MobileContext } from '../../../contexts/MobileContext';

// Constant
const container = 'ivt-applications-table';

export default function InterValleyTradeApplications({ data }) {
  const { isMobile } = useContext(MobileContext);

  //Constants
  const headerLabel = [
    {
      id: 'application_id',
      text: 'Application Number',
    },
    {
      id: 'volume',
      text: 'Volume',
    },
    {
      id: 'cumulative_volume',
      text: 'Cumulative Volume',
    },
    {
      id: 'status',
      text: 'Status',
    },
  ];

  return (
    !isEmpty(data) && (
      <div className={`${container}`} tabIndex="0">
        <div className={`${container}-title`}>
          <div className={`${container}-title-text`}>IVT APPLICATION QUEUE</div>
        </div>
        <div className={`${container}-container`}>
          <table>
            <thead>
              <tr>
                {!isMobile && <th />}
                {headerLabel.map((label, index) => (
                  <th key={index} id={label.id}>
                    {label.text}
                  </th>
                ))}
                {!isMobile && <th />}
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <React.Fragment key={index}>
                  <tr key={index}>
                    {!isMobile && <td></td>}
                    <td className={`${container}-app-id`}>{item.application_id}</td>
                    <td>{formatNumberDecimals(item.volume / 1000)} GL</td>
                    <td>{formatNumberDecimals(item.cumulative_volume / 1000)} GL</td>
                    <td>{item.status}</td>
                    {!isMobile && <td></td>}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    )
  );
}

InterValleyTradeApplications.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      application_id: PropTypes.string,
      volume: PropTypes.number,
      cumulative_volume: PropTypes.number,
      status: PropTypes.string,
    }),
  ),
  headerLabel: PropTypes.array,
};
