// Libraries
import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

// Styles
import './mandatoryConditions.scss';

// Helpers
import { groupConditionsByCategory } from '../../helpers/ArrayUtils';
import { createMarkup } from '../../helpers/Utils';

// Assets
import config from '../../abstract/IntroText.json';

// Components
import AppModal from '../modal/Modal';
import DictionaryModalContent from '../modal/DictionaryModalContent';
import ConditionsModalData from '../modal/modalText/ConditionsModalData';
import InfoButton from '../informationButton/InformationButton';
import ExpandBox from '../generalComponents/expandBox/ExpandBox';

export default function MandatoryConditions({ conditionsData }) {
  const grouped = groupConditionsByCategory(conditionsData);
  const contentData = [
    {
      header: 'Conditions applying to water access licences',
      description:
        'The conditions that apply to most licences within a licence category in this water source are shown here. The conditions that licence holders are legally required to comply with are the conditions set out in the Statement of Conditions for their licence. You should read your own Statement of Conditions for special conditions that apply only to you, or to see if any of the conditions shown here do not apply to you.',
      data: Object.entries(grouped).filter(item => item[0] !== 'ALL CONDITIONS'),
    },
    {
      header: 'Conditions applying to water supply work approvals',
      description:
        'The conditions that apply to most water supply work approvals in this water source are shown here. The conditions that approval holders are legally required to comply with are the conditions set out in the Statement of Approval for their approval. You should read your own Statement of Approval for special conditions that apply only to you, or to see if any of the conditions shown here do not apply to you.',
      data: Object.entries(grouped).filter(item => item[0] === 'ALL CONDITIONS'),
    },
  ];

  // Mini Component
  const ruleHeader = category => {
    return (
      <>
        <div className="conditions-inner-body-category-name">{category[1][0].toUpperCase()}</div>
      </>
    );
  };

  const ruleContent = (index, category) => {
    return (
      <div key={index}>
        {Object.entries(category[1][1]).map((subClass, indexClass) => (
          <div key={indexClass}>
            <div className="conditions-inner-body-class" key={indexClass}>
              {subClass[0]}
            </div>
            {Object.values(subClass[1]).map((subClassItem, indexSubClass) => (
              <div className="conditions-inner-body-class-content" key={indexSubClass}>
                <div
                  className="conditions-inner-body-class-content-condition"
                  key={subClassItem.number}
                >
                  {subClassItem.number}&nbsp;&nbsp;&nbsp;&nbsp;{subClassItem.text}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  };
  return (
    <div className="conditions">
      <div className="row conditions-title">
        <span>Conditions</span>
        <InfoButton targetDataId="#conditionsModalCenter" />
      </div>
      {
        <AppModal
          id="conditionsModalCenter"
          title="Understanding water rules"
          body={<DictionaryModalContent data={ConditionsModalData} />}
        />
      }
      <div className="conditions-body">
        <div
          dangerouslySetInnerHTML={createMarkup(config.conditions.intro)}
          className="conditions-body-intro"
        />
        {!isEmpty(conditionsData) &&
          contentData.map(
            (item, itemIdx) =>
              item.data &&
              !isEmpty(Object.entries(item.data)) && (
                <div key={itemIdx} className="conditions-inner-body">
                  <div className="conditions-inner-body-header">{item.header}</div>
                  <div className="conditions-inner-body-description">{item.description}</div>
                  <div className="conditions-inner-body-content">
                    {Object.entries(item.data).map((dataItem, dataItemIdx) => (
                      <ExpandBox
                        key={dataItemIdx}
                        clickableSurface
                        customHeaderContent={ruleHeader(dataItem)}
                        customHeaderContainerStyle={`conditions-inner-body-licence`}
                        customArrowColor={`conditions-inner-body-licence-arrow`}
                      >
                        {ruleContent(dataItemIdx, dataItem)}
                      </ExpandBox>
                    ))}
                  </div>
                </div>
              ),
          )}
      </div>
    </div>
  );
}

MandatoryConditions.propTypes = {
  conditionsData: PropTypes.array,
};
