// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

// Styles
import './climateMapLegend.scss';

// Components
import InfoButton from '../informationButton/InformationButton';

const container = 'climate-map-legend';

export default function ClimateMapLegend({
  climateType,
  legendTitle,
  legendScale,
  legendScaleLabel,
  legendScaleColor,
  colorBarClassName,
}) {
  const climateTypeClassName = climateType.split('-')[0];
  const getStyleForBorderRadius = index => {
    switch (index) {
      case 0:
        return 'left-rounded-border';
      case legendScaleColor.length - 1:
        return 'right-rounded-border';
      default:
        return '';
    }
  };

  return (
    <div className={`${container}`}>
      <div className={`${container}-header`}>
        {legendTitle}
        <InfoButton targetDataId="#rainfallLegendModal" iconColor="info-button-water-source" />
      </div>
      <div className={`${container}-color-scale-label`}>
        {!isEmpty(legendScaleLabel) &&
          legendScaleLabel.map((label, index) => (
            <div key={index} className={`${container}-color-scale-label-text`}>
              {label}
            </div>
          ))}
      </div>
      <div className={`${container}-${colorBarClassName}`}>
        {!isEmpty(legendScaleColor) &&
          legendScaleColor.map((color, index) => (
            <div
              key={index}
              className={`${container}-color-box ${getStyleForBorderRadius(index)}`}
              style={{ background: color }}
            />
          ))}
      </div>
      <div className={`${container}-color-scale-${climateTypeClassName}`}>
        {legendScale.map((number, index) => (
          <div key={index}>{number}</div>
        ))}
      </div>
      <div className={`${container}-licence`}>
        {' '}
        This data is sourced from the Bureau of Meteorology.
        <a
          className={`${container}-licence-link`}
          href="http://www.bom.gov.au/data-access/3rd-party-attribution.shtml"
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          Click here for details.
        </a>
      </div>
    </div>
  );
}

ClimateMapLegend.propTypes = {
  climateType: PropTypes.string,
  legendTitle: PropTypes.string,
  legendScale: PropTypes.arrayOf(PropTypes.number),
  legendScaleLabel: PropTypes.arrayOf(PropTypes.string),
  legendScaleColor: PropTypes.arrayOf(PropTypes.string),
  colorBarClassName: PropTypes.string,
};
