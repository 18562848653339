// Libraries
import React from 'react';

// Styles
import './MapPopup.scss';

// Helpers
import { createMarkup } from '../../helpers/Utils';

export default function StationInfoPopup({ data }) {
  return (
    <div className="map-popup-container">
      <div className="map-popup-body-title">{data.title}</div>
      <div className="map-popup-body-content" dangerouslySetInnerHTML={createMarkup(data.body)} />
    </div>
  );
}
