// Libraries
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';

// Styles
import './dropdown.scss';

// Constants
import constants from '../../constants/Constants';

// Context
import { AppContext } from '../../contexts/AppContext';
import { MapContext } from '../../contexts/MapContext';

// Helpers
import { prepareFullWSList, prepareNearbyWSList } from '../../helpers/ProcessDataHelper';
import {
  sortArrayByKeyAsc,
  replaceOrAddObjectByKey,
  getTabItemsByWaterSourceId,
} from '../../helpers/Utils';
import { getWaterSourceByType } from '../../helpers/ApiHelper.jsx';
import { Event } from '../../helpers/GAHelper';

// Assets
import DropDownIcon from '../../image/icons/dropdown.png';

// Components
import InfoButton from '../informationButton/InformationButton';

export default function WaterSourceDropdown(props) {
  // Context
  const { waterSource, setWaterSource } = useContext(AppContext);
  const { expandMap } = useContext(MapContext);
  const history = useHistory();
  const location = useLocation();

  // State
  const [waterSourceSelected, setWaterSourceSelected] = useState('All water sources');
  const [menuList, setMenuList] = useState(Array);
  const [fullMenuList, setFullMenuList] = useState(menuList);
  const [warningMsg, setWarningMsg] = useState('');
  const [expand, setExpand] = useState({
    nearby: false,
    reg: false,
    all: false,
  });
  const [openList, setOpenList] = useState(false);
  const [previousLocation, setPreviousLocation] = useState(history.location);

  const changeWaterSource = el => {
    props.inputRef.current.value = '';
    props.setLocationGeometry({});
    setWaterSourceSelected(el.water_source_name);
    if (el.water_source_id !== waterSource.water_source_id) {
      setWaterSource(el, history);
      Event(el.water_source_name, 'Watersource change', constants.INTERACTION_TYPE.waterSource);
    }
    resetValue();
  };

  const closeList = () => {
    setOpenList(false);
  };

  useEffect(() => {
    if (history.action === 'PUSH') {
      setPreviousLocation(location);
    }
  }, [location, history]);

  useEffect(() => {
    (async () => {
      window.addEventListener('click', closeList);
      const isGreaterSydney = waterSource.water_source_id === constants.GREATER_SYDNEY_WSID;
      if (isEmpty(waterSource)) {
        setWaterSourceSelected('All water sources');
        let updatedList = fullMenuList.filter(item => item.type !== 'nearby');
        setFullMenuList(updatedList);
        setMenuList(updatedList);
      } else {
        setWaterSourceSelected(waterSource.water_source_name);
      }
      const tabItems = await getTabItemsByWaterSourceId(waterSource);
      let tabItemsLinks = tabItems?.map(item => item.link);
      const currentTabLink = isGreaterSydney
        ? '/' + previousLocation.pathname.split('/')[2]
        : '/' + history.location.pathname.split('/')[2];
      if (currentTabLink !== '/undefined' && !tabItemsLinks.includes(currentTabLink)) {
        history.push('/updates');
      }
    })();

    return () => {
      window.removeEventListener('click', closeList);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterSource]);

  useEffect(() => {
    getWaterSourceByType('', function (result) {
      var formattedList = prepareFullWSList(result);
      setFullMenuList(formattedList);
      setMenuList(formattedList);
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(props.nearbyWaterSources)) {
      const resultList = prepareNearbyWSList(props.nearbyWaterSources);
      const sortedListItems = sortArrayByKeyAsc(resultList, 'water_source_name');
      if (!isEmpty(sortedListItems)) {
        props.isWaterSourceFound(true);
        let updatedFullList = fullMenuList;
        const nearbyWSList = {
          type: 'nearby',
          title: 'Nearby Water Sources',
          items: sortedListItems,
          order: 1,
        };
        updatedFullList = replaceOrAddObjectByKey(updatedFullList, nearbyWSList, 'type');
        updatedFullList = sortArrayByKeyAsc(updatedFullList, 'order');
        const defaultSelection = sortedListItems.find(
          waterSource => waterSource?.tag === 'prioritized',
        );
        if (!isEmpty(defaultSelection)) {
          setWaterSourceSelected(defaultSelection.water_source_name);
          setWaterSource(defaultSelection, history);
        } else {
          setWaterSourceSelected(sortedListItems[0].water_source_name);
          setWaterSource(sortedListItems[0], history);
        }
        setFullMenuList(updatedFullList);
        setMenuList(updatedFullList);
      } else {
        props.isWaterSourceFound(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.nearbyWaterSources]);

  const filterList = input => {
    const filtered = fullMenuList
      .map(({ title, items }) => ({
        title,
        items: items.filter(
          ({ water_source_name }) =>
            water_source_name.toLowerCase().indexOf(input.toLowerCase()) !== -1,
        ),
      }))
      .filter(({ items }) => items.length);

    if (!isEmpty(filtered)) {
      setWarningMsg('');
      setMenuList(filtered);
    } else {
      setWarningMsg('Your search returned no results');
      setMenuList(fullMenuList);
    }
    if (input === '') {
      setMenuList(fullMenuList);
    }
  };

  const viewMore = type => {
    setExpand({
      ...expand,
      [type]: !expand[type],
    });
  };

  const resetValue = () => {
    setExpand({ nearby: false, reg: false, all: false });
    if (document.getElementById('find-input')) {
      document.getElementById('find-input').value = '';
    }
    setMenuList(fullMenuList);
  };

  const onDropdownBlur = () => {
    setTimeout(function () {
      resetValue();
    }, 200);
  };

  // component
  const regularListRender = type => {
    return (
      <>
        <div className="dropdown-item-type">{type.title}</div>
        {type.items.map((waterSource, idx) => (
          <React.Fragment key={idx}>
            {idx < 3 && (
              <div
                className="dropdown-item-watersource"
                onClick={() => {
                  changeWaterSource(waterSource);
                  setOpenList(false);
                }}
              >
                {waterSource.water_source_name}
              </div>
            )}
            {idx > 2 && expand[type.type] && (
              <div
                className="dropdown-item-watersource"
                onClick={() => {
                  changeWaterSource(waterSource);
                  setOpenList(false);
                }}
              >
                {waterSource.water_source_name}
              </div>
            )}
          </React.Fragment>
        ))}
        <form>
          <div
            className="view-more-menu-item"
            onMouseDown={e => e.preventDefault()}
            onClick={() => viewMore(type.type)}
          >
            {type.items.length > 3 && (!expand[type.type] ? 'View more' : 'View less')}
          </div>
        </form>
      </>
    );
  };

  const greaterSydneyRender = type => {
    return (
      <>
        <div
          className="dropdown-item-type dropdown-item-clickable"
          onClick={() => {
            changeWaterSource(type.items[0]);
            setOpenList(false);
          }}
        >
          {type.title}
        </div>
      </>
    );
  };

  return (
    <div className="watersource-drop-down">
      <div className="watersource-drop-down-header">
        Water source:
        <InfoButton targetDataId="#waterSourceModal" iconColor="info-button-water-source" />
      </div>
      <div className="water-source-info drop-down-menu dropdown" onClick={e => e.stopPropagation()}>
        <button
          id="dropdown-toggle-btn"
          className="drop-down-menu-btn dropdown-toggle"
          onClick={() => {
            setOpenList(prev => {
              if (!prev) expandMap(!prev);
              return !prev;
            });
          }}
        >
          <span className="water-source-info-menu-btn">{waterSourceSelected}</span>
          <img
            src={DropDownIcon}
            alt="Dropdown menu icon for selecting water source"
            className="drop-down-menu-icon"
          />
        </button>
        <InfoButton
          targetDataId="#waterSourceModal"
          iconColor="info-button-water-source-dropdown"
        />
        {openList && (
          <div
            className="watersource-drop-down-content dropdown-menu"
            id="drop-down-content"
            onBlur={() => onDropdownBlur()}
          >
            <input
              id="find-input"
              className="find-input"
              placeholder="Search water sources"
              autoComplete="off"
              onChange={event => filterList(event.target.value)}
            />
            <div className="warning-msg">{warningMsg}</div>
            {!isEmpty(menuList) &&
              menuList.map((type, index) => (
                <div
                  key={index}
                  className={`dropdown-item-list ${
                    index === menuList.length - 1 ? '' : 'dropdown-item-seperator'
                  }`}
                >
                  {type.type === 'gs' ? greaterSydneyRender(type) : regularListRender(type)}
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

WaterSourceDropdown.propTypes = {
  isWaterSourceFound: PropTypes.func,
  nearbyWaterSources: PropTypes.array,
  setLocationGeometry: PropTypes.func,
  inputRef: PropTypes.object,
};
