// Libraries
import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { Tooltip, BarChart, Bar, XAxis, Legend, YAxis } from 'recharts';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

// Context
import { AppContext } from '../../contexts/AppContext';
import { MobileContext } from '../../contexts/MobileContext';

// Styles
import '../research/cumulativeInflowRecent.scss';

// Components
import SelectionBtns from './childProperties/SelectionBtns';
import SelectionBtnsGroup from './childProperties/SelectionBtnsGroup';
import ChartContainer from '../chartProperties/ChartContainer';
import ChartLegend from '../chartProperties/ChartLegend';
import ChartResponsiveContainer from '../chartProperties/ChartResponsiveContainer';

// Helpers
import { getDamResourcesByWaterSourceId } from '../../helpers/ApiHelper';
import { formatNumberDecimals } from '../../helpers/Utils';

export default function MurrayFlowRecent(props) {
  const endDate = moment().format('YYYY-MM-DD');
  const { graphType } = props;
  const { waterSource } = useContext(AppContext);
  const { isMobile } = useContext(MobileContext);
  const [chartData, setChartData] = useState([]);
  const [yearOffset, setYearOffset] = useState(1);

  useEffect(() => {
    (async () => {
      const startDate = moment()
        .subtract(yearOffset, 'years')
        .add(1, 'months')
        .format('YYYY-MM-01');
      const chartDataRes = await getDamResourcesByWaterSourceId(
        waterSource.water_source_id,
        startDate,
        endDate,
      );
      if (chartDataRes.data && Object.prototype.hasOwnProperty.call(chartDataRes.data, 'all')) {
        if (graphType === 'inflows') {
          const result = chartDataRes.data.all.map(x => ({
            'inflow_shares.nsw': x.inflow_shares && x.inflow_shares.NSW,
            'inflow_shares.vic': x.inflow_shares && x.inflow_shares.VIC,
            'inflow_shares.sa': x.inflow_shares && x.inflow_shares.SA,
            ...x,
          }));
          setChartData(
            isEmpty(result)
              ? []
              : result.filter(item => {
                  return item['inflow_shares.nsw'] || item['inflow_shares.vic'];
                }),
          );
        }
        if (graphType === 'outflows') {
          setChartData(isEmpty(chartDataRes.data.all) ? [] : chartDataRes.data.all);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterSource.water_source_id, yearOffset, endDate]);

  const onYearChange = yearOffset => {
    setYearOffset(yearOffset);
  };

  const drawBarsForFlows = () => {
    const colors = ['#00519B', '#2178B0', '#2196f3'];
    const flowsName = {
      'inflow_shares.nsw': 'NSW',
      'inflow_shares.vic': 'Victoria',
      outflow: 'South Australia',
    };
    //Properties to be included when rendering the graph bars
    const lines =
      graphType === 'inflows' ? ['inflow_shares.nsw', 'inflow_shares.vic'] : ['outflow'];

    const dataSeries = chartData;
    let lineArr = [];
    let count = 0;

    for (let line in dataSeries[0]) {
      // eslint-disable-next-line no-prototype-builtins
      if (dataSeries[0].hasOwnProperty(line) && lines.includes(line)) {
        lineArr.push(
          <Bar
            key={count}
            name={flowsName[line]}
            dataKey={line}
            stackId="a"
            fill={colors[count]}
            radius={[
              line === 'inflow_shares.vic' || line === 'outflow' ? 5 : 0,
              line === 'inflow_shares.vic' || line === 'outflow' ? 5 : 0,
              0,
              0,
            ]}
          />,
        );
        count++;
      }
    }
    return lineArr;
  };

  const renderCustomTooltip = params => {
    if (params && params.payload && params.payload[0] && params.payload[0].payload) {
      return (
        <div className="ci-recent-chart-tooltip">
          <ul className="ci-recent-chart-tooltip-item-ul">
            <li className="list-no-style-type">
              {moment(params.payload[0].payload.date, 'YYYY-MM').format('MMMM YYYY')}
            </li>
            {params.payload.reverse().map((data, index) => (
              <li
                key={index}
                className="list-no-style-type custom-recharts-tooltip-item"
                style={{ color: data.color }}
              >{`${data.name}: ${
                data.value > 10000
                  ? formatNumberDecimals(Math.round(data.value / 1000))
                  : formatNumberDecimals(Number(data.value / 1000).toFixed(1))
              } GL`}</li>
            ))}
          </ul>
        </div>
      );
    } else {
      return null;
    }
  };

  const renderCusomizedLegend = payload => {
    return <ChartLegend payload={payload} legendType="square" />;
  };

  const yAxisTickFormatter = value => {
    return value > 10000
      ? formatNumberDecimals(Math.round(value / 1000))
      : formatNumberDecimals(Number(value / 1000).toFixed(1));
  };

  let yearSelectionBtn = (
    <SelectionBtns
      type="year"
      itemList={[1, 10, 30]}
      onBtnChange={onYearChange}
      defaultValue={yearOffset}
    />
  );

  return (
    <ChartContainer
      divId="AMSRecent"
      pageTitle={props.title}
      graphTitle={props.title}
      graphIntro={props.desc}
    >
      <SelectionBtnsGroup right={yearSelectionBtn} />
      <ChartResponsiveContainer isEmptyChart={isEmpty(chartData)}>
        <BarChart
          width={800}
          data={chartData}
          stackOffset="sign"
          margin={{ top: isMobile ? 20 : 10, left: 20, bottom: 50 }}
        >
          <XAxis
            interval={Math.floor(yearOffset / 2)}
            dataKey="date"
            tickFormatter={v => moment(v, 'YYYY-MM').format('YYYY MMM')}
            type="category"
            angle={-35}
            dy={25}
            tick={{ fill: 'black' }}
          />
          <YAxis
            interval="preserveStartEnd"
            label={{ value: props.yAxisLabel + ' (GL)', angle: -90, dx: -43 }}
            tickFormatter={yAxisTickFormatter}
            type="number"
            tick={{ fill: 'black' }}
          />
          <Tooltip
            content={renderCustomTooltip}
            cursor={!isEmpty(chartData) ? { fill: '#f5f5f5' } : { fill: 'transparent' }}
          />
          <Legend wrapperStyle={{ bottom: 0 }} content={e => renderCusomizedLegend(e.payload)} />
          {drawBarsForFlows()}
        </BarChart>
      </ChartResponsiveContainer>
    </ChartContainer>
  );
}

MurrayFlowRecent.propTypes = {
  dams: PropTypes.array,
  desc: PropTypes.string,
  graphType: PropTypes.string,
  title: PropTypes.string,
  yAxisLabel: PropTypes.string,
};
