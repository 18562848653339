// Library
import React, { useState, useEffect, useContext } from 'react';
import { isEmpty } from 'lodash';
import util from 'util';
import PropTypes from 'prop-types';
import moment from 'moment';

// Components
import Modal from '../../modal/Modal';
import GaugeAlertModalBody from '../../alert/GaugeAlertModalBody';
import RemoveAlertModalBody from '../../alert/RemoveAlertModalBody';
import AlertResponse from '../../alert/AlertResponse';
import ViewDetailsLink from '../../mapPopup/viewDetailsLink/ViewDetailsLink';
import alertResponseText from '../../alert/AlertResponseText.json';
import PageBodyContainer from '../bodyContainer/PageBodyContainer';
import ManageBody from '../ManageBody';
import LoginModalBody from '../../alert/LoginModalBody';
import Pagination from '../../generalPagination/Pagination';

//font-awesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareCheck, faSquare } from '@fortawesome/free-regular-svg-icons';

// Styles
import './manageAlertBody.scss';

// Constant
import constants from '../../../constants/Constants';

// Context
import { MobileContext } from '../../../contexts/MobileContext';
import { StationAlertCtx } from '../../../contexts/StationAlertContext';
import { UserContext } from '../../../contexts/UserContext';

//Helpers
import { editAlert } from '../../../helpers/SubscriptionsApiHelper';
import { sortArrayByKeyDesc, formatNumberDecimals, toSentenceCase } from '../../../helpers/Utils';
import { getDatesByPeriod } from '../../../helpers/TimeUtils';

const TAB_OPTIONS = constants.TAB_OPTIONS;
const riverDataTab = TAB_OPTIONS.riverData;
const component = 'manage-alert';
const STATION_REMOVE_ALERT_MESSAGE =
  'Are you sure you no longer wish to be alerted about changes at the %s (%s) gauge?';
const STATION_REMOVE_ALERT_MESSAGE_TITLE = 'Are you sure?';
const STATION_EDIT_MESSAGE_TITLE = 'Edit alert';
const UPDATE_REMOVE_ALERT_MESSAGE =
  'Are you sure you want to stop receiving updates for %s in the %s?';

export const ALERT_TYPES = ['station', 'update', 'constraint'];
const STATION_ALERT_TYPE = ALERT_TYPES[0];

const HEADER_ITEMS = [
  'Gauges / Water Source',
  'Condition',
  'Mobile',
  'Expires on',
  'Email',
  'Expires on',
];
const HEADER_ITEMS_MINI = ['Gauges / Water Source', 'Mobile', 'Email'];
const RESULT_PER_PAGE = 10;

export const ManageAlertBody = () => {
  const { stationAlerts, updateAlerts, constraintAlerts } = useContext(StationAlertCtx);
  const { isMobile } = useContext(MobileContext);
  const { user, isLoggedIn } = useContext(UserContext);

  const [showManageAlertResponse, setShowManageAlertResponse] = useState(false);
  const [alertList, setAlertList] = useState(stationAlerts?.concat(updateAlerts, constraintAlerts));
  const [alertSelected, setAlertSelected] = useState();
  const [responseType, setResponseType] = useState();
  const [activeAlert, setActiveAlert] = useState({});
  const [curPage, setCurPage] = useState(1);

  // Life Cycle
  useEffect(() => {
    setAlertList(stationAlerts?.concat(updateAlerts, constraintAlerts));
  }, [stationAlerts, updateAlerts, constraintAlerts]);

  useEffect(() => {
    // Checking delivery checkbox
    if (!isEmpty(alertList)) {
      alertList
        .filter(item => item.alert === activeAlert.alert)
        .map(item => {
          (async () => {
            const deliveryType = item.subscriptions
              .filter(type => type.active)
              .map(type => type.delivery_type)
              .join(',');
            const data = {
              alertId: activeAlert.alert,
              deliveryType: deliveryType,
              expiryDate: getDatesByPeriod(12, 'YYYY-MM-DD', 'months'),
              userId: user.email,
            };
            await editAlert(data);
          })();
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertList]);

  const editStationAlertList = alert => {
    if (alert.alertType === STATION_ALERT_TYPE) {
      let newAlert = alertList.findIndex(stationAlert => stationAlert.alert === alert.alertId);
      alertList[newAlert].threshold = alert.threshold;
      alertList[newAlert].notify_if_persist = alert.notifyIfPersist;
      alertList[newAlert].threshold_type = alert.thresholdOperand;
    }
  };

  const removeStationAlertList = alert => {
    setAlertList(alertList.filter(stationAlert => stationAlert.alert !== alert.alert));
  };

  const renderManageAlertResponse = (responseType, success, alert) => {
    if (success) {
      editStationAlertList(alert);
    }
    setShowManageAlertResponse(true);
    setResponseType(responseType);
  };

  const renderRemoveAlertResponse = (responseType, success) => {
    if (success) {
      removeStationAlertList(alertSelected);
    }
    setShowManageAlertResponse(true);
    setResponseType(responseType);
  };

  const renderModals = () => {
    const stationAlertItemText = util.format(
      STATION_REMOVE_ALERT_MESSAGE,
      alertSelected.station_name,
      alertSelected.station_id,
    );
    const updateAlertItemText = util.format(
      UPDATE_REMOVE_ALERT_MESSAGE,
      alertSelected.update_type || alertSelected.constraint_type,
      alertSelected.water_source_name || alertSelected.station_name,
    );
    return (
      <div>
        {alertSelected.alert_type === STATION_ALERT_TYPE && (
          <Modal
            id={`edit-alert-modal`}
            title={!showManageAlertResponse && STATION_EDIT_MESSAGE_TITLE}
            body={
              <>
                <AlertResponse
                  show={showManageAlertResponse}
                  responseBodyText={alertResponseText[STATION_ALERT_TYPE][responseType]}
                />
                {!showManageAlertResponse && (
                  <GaugeAlertModalBody
                    stationInfo={alertSelected}
                    thresholdInit={alertSelected.threshold}
                    reminderInit={alertSelected.notify_if_persist}
                    limitInit={alertSelected.threshold_type}
                    editMode={true}
                    alertId={alertSelected.alert}
                    setManageAlertResponse={renderManageAlertResponse}
                  />
                )}
              </>
            }
          />
        )}
        <Modal
          id={`remove-alert-modal`}
          title={!showManageAlertResponse && STATION_REMOVE_ALERT_MESSAGE_TITLE}
          body={
            <>
              <AlertResponse
                show={showManageAlertResponse}
                responseBodyText={alertResponseText.update[responseType]}
              />
              {!showManageAlertResponse && (
                <RemoveAlertModalBody
                  message={`${
                    alertSelected.alert_type === STATION_ALERT_TYPE
                      ? stationAlertItemText
                      : updateAlertItemText
                  }`}
                  alert={alertSelected}
                  setManageAlertResponse={renderRemoveAlertResponse}
                />
              )}
            </>
          }
        />
      </div>
    );
  };

  const editRemoveButtons = alertItem => {
    return (
      <div className="edit-remove-buttons">
        {alertItem.alert_type === STATION_ALERT_TYPE && (
          <>
            <button
              className={!isMobile ? `${component}-button` : `${component}-button mini`}
              onClick={e => {
                e.preventDefault();
                setShowManageAlertResponse(false);
                setAlertSelected(alertItem);
              }}
            >
              <a data-toggle="modal" data-target={`#edit-alert-modal`}>
                Edit
              </a>
            </button>
            /
          </>
        )}
        <button
          className={`${component}-button`}
          onClick={() => {
            setShowManageAlertResponse(false);
            setAlertSelected(alertItem);
          }}
        >
          <a data-toggle="modal" data-target={`#remove-alert-modal`}>
            Remove
          </a>
        </button>
      </div>
    );
  };

  const onCheckboxChange = (target, alertItem) => {
    const { value, checked } = target;
    setAlertList(prevState => {
      const activeState = prevState;
      const target = activeState.findIndex(item => item.alert === alertItem.alert);
      if (target !== -1) {
        const updateTarget = activeState[target].subscriptions.findIndex(
          item => item.delivery_type === value,
        );
        if (updateTarget !== -1) {
          activeState[target].subscriptions[updateTarget].active = checked;
          activeState[target].subscriptions[updateTarget].expiry_datetime = getDatesByPeriod(
            12,
            'YYYY-MM-DD',
            'months',
          );
        }
      }
      return [...activeState];
    });
  };

  const formatCondition = item => {
    if (item.alert_type === STATION_ALERT_TYPE) {
      return `${toSentenceCase(item.hydrometric_name)} 
      ${item.threshold_type} 
      ${formatNumberDecimals(item.threshold)}${item.unit}`;
    } else {
      return toSentenceCase(`${item.update_type || item.constraint_type} type ${item.alert_type}`);
    }
  };

  const sliceResult = (items, page) => {
    return items.slice((page - 1) * RESULT_PER_PAGE, page * RESULT_PER_PAGE);
  };

  return isLoggedIn ? (
    <PageBodyContainer selected="alert">
      <div data-testid={`${component}-body`} className={component}>
        {alertSelected && renderModals()}
        {/* Issue: render tables for both gauges and updates */}
        <ManageBody headers={!isMobile ? HEADER_ITEMS : HEADER_ITEMS_MINI}>
          {!isEmpty(alertList) &&
            sliceResult(alertList, curPage).map((alertItem, index) => (
              <div className={`${component}-body`} key={index}>
                <div className={`${component}-body-gauges`}>
                  {alertItem.alert_type === STATION_ALERT_TYPE ? (
                    <ViewDetailsLink
                      elementId={alertItem.station_id}
                      label={`${alertItem.station_name} (${alertItem.station_id})`}
                      targetWaterSource={{
                        water_source_id: alertItem.water_source_id,
                        water_source_name: alertItem.water_source_name,
                      }}
                      pathname={`${riverDataTab.link}`}
                    />
                  ) : (
                    <p>{alertItem.water_source_name || alertItem.station_name}</p>
                  )}
                  {isMobile && (
                    <div className="alert-item-threshold">{formatCondition(alertItem)}</div>
                  )}
                  {/* edit remove buttons */}
                  {editRemoveButtons(alertItem)}
                </div>
                {/* no display when mini */}
                <div className={`alert-item-info`}>{formatCondition(alertItem)}</div>
                {sortArrayByKeyDesc(alertItem.subscriptions, 'delivery_type').map(delivery_type => (
                  <>
                    <div className={`${component}-delivery-type`}>
                      <div className="checkbox-container">
                        <input
                          type="checkbox"
                          id="customCheckboxInput"
                          value={delivery_type.delivery_type}
                          name={delivery_type.delivery_type}
                          checked={delivery_type.active}
                          onChange={e => {
                            setActiveAlert(alertItem);
                            onCheckboxChange(e.target, alertItem);
                          }}
                        />
                        <FontAwesomeIcon
                          icon={delivery_type.active ? faSquareCheck : faSquare}
                          className="check-icon"
                        />
                      </div>
                    </div>
                    <div className={`${component}-delivery-type-expiry`}>
                      {delivery_type.active
                        ? moment(delivery_type.expiry_datetime).format('DD-MMM-YY')
                        : '-'}
                    </div>
                  </>
                ))}
              </div>
            ))}
          {alertList.length > 10 && (
            <Pagination
              onPageChange={setCurPage}
              totalCount={alertList.length}
              currentPage={curPage}
              pageSize={RESULT_PER_PAGE}
            />
          )}
        </ManageBody>
      </div>
    </PageBodyContainer>
  ) : (
    <div className={`${component}-not-auth`}>
      <LoginModalBody action="view" />
    </div>
  );
};

ManageAlertBody.propTypes = {
  stationAlerts: PropTypes.object,
  updateAlerts: PropTypes.object,
};
