import React from 'react';

// Components
import BodyContainer from './BodyContainer';
import InfoIconAndModal from '../../components/informationButton/InfoIconAndModal';
import PollutionMonitoringBody from '../../components/pageComponents/pollutionMonitoring/PollutionMonitoringBody';

// Style
import './body.scss';

// Constants
import pollutionModal from '../../components/modal/modalText/PollutionTitleModalText.json';
import { pollution } from '../../abstract/IntroText.json';
const container = 'pollution-container';

const PollutionMonitoring = () => {
  const { title, intro } = pollution;
  const { modal } = pollutionModal;

  return (
    <BodyContainer
      pageTitle={
        <div className={`${container}-pageTop`}>
          <span>
            {`${title}`}
            &nbsp;
            <InfoIconAndModal
              modalId="tradingTitleModal"
              modalContent={modal}
              lastWord={<span className={'page-body-title'}>Plant &nbsp;</span>}
            />
          </span>
        </div>
      }
      pageIntro={intro}
    >
      <PollutionMonitoringBody />
    </BodyContainer>
  );
};

export default PollutionMonitoring;
