import murrumbidgee from './murrumbidgee.json';
import stateWide from './stateWide.json';
import murray from './murray.json';
import macquarie from './macquarie.json';
import barwon from './barwonDarling.json';
import gwydir from './gwydir.json';
import belubula from './belubula.json';
import namoi from './namoi.json';
import lachlan from './lachlan.json';
import hunter from './hunter.json';
import border from './border.json';
import peel from './peel.json';
import richmond from './richmond.json';
import begaBrogo from './begaBrogo.json';
import paterson from './paterson.json';
import lowerDarling from './lowerDarling.json';

export default Object.freeze({
  11982: murrumbidgee,
  11904: murray,
  11984: macquarie,
  16001: barwon,
  11985: gwydir,
  16801: belubula,
  12105: namoi,
  11986: namoi,
  11983: lachlan,
  12801: hunter,
  14681: border,
  15101: peel,
  15324: richmond,
  15481: begaBrogo,
  13802: paterson,
  12104: lowerDarling,
  state: stateWide,
});
