// Libraries
import React, { useState, useContext, useEffect } from 'react';
import { isEmpty } from 'lodash';

// Styles
import './lossesChart.scss';

// Context
import { AppContext } from '../../contexts/AppContext';

// Components
import AnnualLosses from './AnnualLosses.jsx';
import PageTop from '../../components/pageTop/PageTop';
import graphText from './IntroText.json';
import config from '../../abstract/IntroText.json';

// Helpers
import { getMinimalFlow, getLossesWaterSource } from '../../helpers/LossesApiHelper.jsx';
import { genListOfFiscalYears } from '../../helpers/Utils';
import { currentFinancialYear } from '../../helpers/TimeUtils';

export default function LossesChart() {
  const { waterSource } = useContext(AppContext);
  const [envChartData, setEnvChartData] = useState([]);
  const [staticEnvChartData, setStaticEnvChartData] = useState([]);
  const [staticChartData, setStaticChartData] = useState([]);
  const [dynamicChartData, setDynamicChartData] = useState([]);
  const listOfYears = genListOfFiscalYears('losses', waterSource.water_source_id);
  const defaultYears = listOfYears.slice(0, 2);
  const activeYears = defaultYears.map(year => year.value);

  useEffect(() => {
    setStaticChartData([]);
    setDynamicChartData([]);
    setEnvChartData([]);
    setStaticEnvChartData([]);
    (async () => {
      const currYear = currentFinancialYear();
      await getLossesWaterSource(waterSource, currYear, setStaticChartData);
      await getLossesWaterSource(waterSource, activeYears.join(), setDynamicChartData);
      await getMinimalFlow(waterSource, activeYears.join(), setEnvChartData);
      await getMinimalFlow(waterSource, currYear, setStaticEnvChartData);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterSource]);

  return (
    <>
      {!isEmpty(dynamicChartData) &&
        dynamicChartData.map((site, i) => (
          <div key={i}>
            <AnnualLosses
              siteId={i}
              siteName={site.site_name}
              desc={graphText.AnnualLosses.intro}
              title={graphText.AnnualLosses.title}
              chartLabel="Cumulative natural loss (GL)"
              dataKey="actual"
              yearSelector={true}
              chartData={dynamicChartData.find(data => data.site_id === site.site_id)}
              defaultYears={defaultYears}
              type="natural"
              multiplier={1000}
            />
            <AnnualLosses
              siteName={site.site_name}
              siteId={i}
              desc={graphText.BudgetComparison.intro}
              title={graphText.BudgetComparison.title}
              chartLabel="Cumulative natural loss (GL)"
              dataKey="actual/budgeted/projected"
              yearSelector={false}
              chartData={staticChartData.find(data => data.site_id === site.site_id)}
              type="natural"
              multiplier={1000}
            />
          </div>
        ))}
      <div className="eosSubContentHeading">
        <PageTop
          title={config.losses.eosSubContentHeading}
          intro={config.losses.eosSubContentIntro}
        />
      </div>
      {!isEmpty(envChartData) &&
        envChartData.map((site, i) => (
          <div key={i}>
            <AnnualLosses
              siteId={i}
              siteName={site.site_name}
              desc={graphText.AnnualELosses.intro}
              title={graphText.AnnualELosses.title}
              chartLabel="Flow rate (ML/d)"
              dataKey="actual"
              yearSelector={true}
              chartData={envChartData.find(data => data.site_id === site.site_id)}
              defaultYears={defaultYears}
              type="environment"
              multiplier={1}
            />
            <AnnualLosses
              siteName={site.site_name}
              siteId={i}
              desc={graphText.RequiredComparison.intro}
              title={graphText.RequiredComparison.title}
              chartLabel="Flow rate (ML/d)"
              dataKey="actual/required/loss"
              yearSelector={false}
              chartData={staticEnvChartData.find(data => data.site_id === site.site_id)}
              type="environment"
              multiplier={1}
            />
          </div>
        ))}
    </>
  );
}
