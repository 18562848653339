// Libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isNumber, isObject, toString } from 'lodash';

// Styles
import './selectionBtns.scss';

export default function SelectionBtns({ itemList, onBtnChange, type = 'year', selected }) {
  // States
  const [activeItem, setActiveItem] = useState(
    selected ? selected : itemList ? itemList.find(item => item === 1) : '',
  );
  const [showPopup, setShowPopup] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ left: 'auto', top: 'auto' });
  const [popupText, setPopupText] = useState('');

  return (
    <>
      {type === 'download' && (
        <div
          style={{
            display: showPopup ? 'block' : 'none',
            left: popupPosition.left,
            top: popupPosition.top,
          }}
          className="selection-btns-popup-text"
        >
          <span id="PopUpText">{popupText}</span>
        </div>
      )}
      <div className={`btn-group btn-group-toggle dropdown`}>
        {itemList &&
          itemList.map((offset, index) => {
            const value = isObject(offset) ? offset.name : offset;
            return (
              <label
                key={value}
                className={`btn btn-primary selection-btn ${
                  toString(activeItem) === toString(value) ? 'active' : ''
                }`}
                onMouseOver={
                  type === 'download' && offset.popupText
                    ? evt => {
                        setPopupText(offset.popupText);
                        setPopupPosition({
                          left: `${evt.clientX}px`,
                          top: `${evt.clientY + 80}px`,
                        });
                        setShowPopup(true);
                      }
                    : () => {}
                }
                onMouseLeave={() => {
                  setShowPopup(false);
                }}
              >
                <input
                  type="radio"
                  name="options"
                  defaultChecked={type === 'year' ? value === 1 : index === 0}
                  onClick={e => {
                    onBtnChange(e.target.value);
                    setActiveItem(e.target.value);
                  }}
                  value={value}
                />
                {(type !== 'year' || isNumber(value)) && value}
                {type === 'year' && (!isNumber(value) ? value : value === 1 ? ' year' : ' years')}
              </label>
            );
          })}
      </div>
    </>
  );
}

SelectionBtns.propTypes = {
  itemList: PropTypes.array,
  onBtnChange: PropTypes.func,
  intervalList: PropTypes.array,
  type: PropTypes.string,
};
