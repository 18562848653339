// Libraries
import React from 'react';

// Styles
import './waterQualityLegend.scss';

// Assets
import waterQualityIcon from '../../image/icons/waterQuality.png';

const container = 'water-quality-legend';
export default function WaterQualityLegend({ showGauges = true }) {
  return (
    <div className={`${container}`}>
      {/* TODO Close option may be needed for later */}
      {/* <span className="close" onClick={() => console.log('legened closed')}>
      ×
      </span> */}
      {showGauges && (
        <div className={'section'}>
          <div className={'section-title'}>Gauging site</div>
          <div className={'section-body'}>
            <div className={'section-row'}>
              <div className={'icon icon-dark-blue'}>
                <img src={waterQualityIcon} />
              </div>
              <div className={'section-row-text'}>Dissolved oxygen</div>
            </div>
            <div className={'section-row'}>
              <div className={'icon icon-light-blue'}>
                <img src={waterQualityIcon} />
              </div>
              <div className={'section-row-text'}>Electrical Conductivity @ 25deg. C</div>
            </div>
            <div className={'section-row'}>
              <div className={'icon icon-grey'}>
                <img src={waterQualityIcon} />
              </div>
              <div className={'section-row-text'}>Other</div>
            </div>
          </div>
        </div>
      )}
      <div className={'section'}>
        <div className={'section-title'}>Algae site</div>
        <div className={'section-body'}>
          <div className={'section-row'}>
            <div className={'icon icon-red'}>
              <img src={waterQualityIcon} />
            </div>
            <div className={'section-row-text'}>Action level</div>
          </div>
          <div className={'section-row'}>
            <div className={'icon icon-amber'}>
              <img src={waterQualityIcon} />
            </div>
            <div className={'section-row-text'}>Alert level</div>
          </div>
          <div className={'section-row'}>
            <div className={'icon icon-green'}>
              <img src={waterQualityIcon} />
            </div>
            <div className={'section-row-text'}>Monitor level</div>
          </div>
        </div>
      </div>
    </div>
  );
}
