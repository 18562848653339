// Library
import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

// Component
import HomeDashboard from './HomeDashBoard';

// Context
import { MobileContext } from '../../contexts/MobileContext';

const HomeDashboardRoutes = ({ component: Component, ...rest }) => {
  const { isMobile } = useContext(MobileContext);

  return isMobile && rest.path === '/' && localStorage.getItem('preference') !== 'full-site' ? (
    <Redirect
      to="/m"
      {...rest}
      render={props => (
        <HomeDashboard>
          <Component {...props} />
        </HomeDashboard>
      )}
    />
  ) : (
    <Route
      {...rest}
      render={props => (
        <HomeDashboard>
          <Component {...props} />
        </HomeDashboard>
      )}
    />
  );
};

export default HomeDashboardRoutes;

HomeDashboardRoutes.propTypes = {
  component: PropTypes.any,
};
