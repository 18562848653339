import React from 'react';
import PropTypes from 'prop-types';

const DamSVG = ({ customClass }) => {
  return (
    <svg
      id="Component_512"
      data-name="Component 512"
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 90 90"
      className={customClass ? customClass : ' '}
    >
      <path
        id="Path_178"
        data-name="Path 178"
        d="M20,0A20,20,0,1,1,0,20,20,20,0,0,1,20,0Z"
        fill="none"
      />
      <g id="Group_1051" data-name="Group 1051" transform="translate(-10 12.536) scale(4, 4)">
        <g id="dam" transform="translate(10.648)">
          <path
            id="Path_42"
            data-name="Path 42"
            d="M16.791,8.926a2.21,2.21,0,0,1,2.822,0,1.327,1.327,0,0,0,1.763,0,2.21,2.21,0,0,1,2.822,0,1.277,1.277,0,0,0,.882.335.4.4,0,0,0,0-.8c-.165,0-.179-.01-.41-.18a3,3,0,0,0-3.766,0c-.231.17-.245.18-.409.18s-.179-.01-.409-.18A2.9,2.9,0,0,0,18.2,7.621a2.811,2.811,0,0,0-1.883.664c-.2.158-.244.175-.409.18a1.147,1.147,0,0,1-.566-.284C14.8,5.206,14.668,4.512,13.984.33A.4.4,0,0,0,13.592,0H10.077a.4.4,0,0,0-.4.4V14.53a.4.4,0,0,0,.4.4H16.04a.4.4,0,0,0,.392-.466L16.178,13a1.412,1.412,0,0,0,.613-.312,2.209,2.209,0,0,1,2.822,0,1.327,1.327,0,0,0,1.763,0,2.21,2.21,0,0,1,2.822,0,1.278,1.278,0,0,0,.882.335.4.4,0,0,0,0-.8c-.165,0-.179-.01-.409-.18a3,3,0,0,0-3.766,0c-.231.17-.244.18-.409.18s-.179-.01-.409-.18a3,3,0,0,0-3.766,0,.879.879,0,0,1-.277.17l-.52-3a1.294,1.294,0,0,0,.388.052A1.277,1.277,0,0,0,16.791,8.926Zm-1.224,5.206H10.475V.8h2.782Z"
            transform="translate(-9.679)"
          />
        </g>
        <path
          id="Path_112"
          data-name="Path 112"
          d="M16.042,5.891a1.767,1.767,0,0,1,1.335.627,1.05,1.05,0,0,0,1.668,0,1.736,1.736,0,0,1,2.67,0,1.073,1.073,0,0,0,.834.413.445.445,0,0,0,.377-.49.445.445,0,0,0-.377-.49c-.156,0-.169-.012-.388-.222a2.351,2.351,0,0,0-3.564,0c-.218.209-.231.222-.387.222s-.169-.012-.387-.222a2.417,2.417,0,0,0-1.782-.817,3.053,3.053,0,0,0-1.782.817l-.412.361a.887.887,0,0,0-.473.351.584.584,0,0,0,.333.807C14.108,7.407,15.51,5.891,16.042,5.891Z"
          transform="translate(-12.278 0.993)"
        />
      </g>
    </svg>
  );
};

export default DamSVG;

/* Props */
DamSVG.propTypes = {
  /**
   * This change the header arrow's styling
   * It expects string that represent a css class
   * Example can be seen in restrictions.scss, .restrictions-body-arrow
   *
   * Side Note:
   * Change both height and width to control size
   * Change fill to change color
   */
  customClass: PropTypes.string,
};
