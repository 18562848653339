// Library
import { useState } from 'react';

// Helper
import { getCurrentDate } from './TimeUtils';

export const useForceUpdate = () => {
  const [timestamp, timestampUpdate] = useState(getCurrentDate('DD MMM YYYY hh:mm:ss a'));

  const forceUpdate = () => {
    timestampUpdate(getCurrentDate('DD MMM YYYY hh:mm:ss a'));
  };

  return [timestamp, forceUpdate];
};
