// Libraries
import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Event } from '../../helpers/GAHelper';
import PropTypes from 'prop-types';

// Styles
import './navBarItem.scss';

// Context
import { AppContext } from '../../contexts/AppContext';
import { MapContext } from '../../contexts/MapContext';
import { MapFilterContext } from '../../contexts/MapFilterContext';
import { UserContext } from '../../contexts/UserContext';

// Components
import LoginButton from '../login/LoginButton';
import UserProfileButton from '../login/UserProfileButton';

// Helpers
import azureB2CHelper from '../../helpers/AzureB2CHelper';

// Constants
import constants from '../../constants/Constants';
const component = 'nav-link-item';

export default function NavBarItem({ onLinkClick, className, title, link, style = '' }) {
  const { setWaterSource, waterSource } = useContext(AppContext);
  const { setIsLoggedIn, isLoggedIn } = useContext(UserContext);
  const { toggleMarker } = useContext(MapContext);
  const { toggleMapFilter } = useContext(MapFilterContext);
  const [isLoggedInLoaded, setIsLoggedInLoaded] = useState(false);
  const classTitle = `${title.split(' ').join('-')} ${isLoggedIn ? 'User' : ''}`;

  const doClick = title => {
    Event(
      waterSource.water_source_name,
      title + ' nav tab click',
      constants.INTERACTION_TYPE.navigation,
    );

    if (title === 'Home') {
      localStorage.removeItem('curWaterSource');
      setWaterSource({});
    }
    if (title === 'Full site') {
      localStorage.setItem('preference', 'full-site');
    }
    toggleMarker(undefined);
    toggleMapFilter('', {});
    onLinkClick && onLinkClick();
  };

  useEffect(() => {
    async function checkStatus() {
      setIsLoggedIn(await azureB2CHelper.isLoggedIn());
      setIsLoggedInLoaded(true);
    }
    checkStatus();
    //TODO => https://dev.to/elijahtrillionz/cleaning-up-async-functions-in-reacts-useeffect-hook-unsubscribing-3dkk
    //clean up by abortcontroller
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`${className ? className : component} ${classTitle} ${style}`}>
      {title === 'Login' ? (
        isLoggedInLoaded ? (
          <>
            {isLoggedIn ? (
              <UserProfileButton />
            ) : (
              <LoginButton customStyle={`${component}-login`} />
            )}
          </>
        ) : null
      ) : (
        <Link className={`${component}-link`} to={link} onClick={() => doClick(title)}>
          {title}
        </Link>
      )}
    </div>
  );
}

NavBarItem.propTypes = {
  onLinkClick: PropTypes.func,
  className: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
};
