// Library
import React, { useContext } from 'react';

// Style
import './stationDownload.scss';

// Component
import StationDownloadFilters from './filters/StationDownloadFilters';
import GraphAndDownloadGraph from './graphAndDownloadGraph/GraphAndDownloadGraph';
import SiteInfo from './siteInfo/SiteInfo';
import RatingsCurve from './rating/RatingsCurve';

// Context
import { DownloadContext } from '../../../../contexts/DownloadContext';

// Constant
import constant from './DataDownloadConstants';
const component = 'station-download';

const StationDownload = () => {
  return (
    <div className={component}>
      <StationDownloadFilters />
      <StationContent />
    </div>
  );
};

export default StationDownload;

const StationContent = () => {
  const { stationInfoType } = useContext(DownloadContext);
  if (!stationInfoType) return <></>;

  let content;
  switch (stationInfoType) {
    case constant.GRAPH_DOWNLOAD:
      content = <GraphAndDownloadGraph />;
      break;
    case constant.SITE_INFO:
      content = <SiteInfo />;
      break;
    case constant.RATING:
      content = <RatingsCurve />;
      break;
    default:
      break;
  }

  return <div className={`${component}-content`}>{content}</div>;
};

StationDownload.propTypes = {};
