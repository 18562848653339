/* Library */
import React, { useState, useContext, useLayoutEffect, useEffect } from 'react';
import PropTypes from 'prop-types';

/* Styles */
import './expandBox.scss';

/* Context */
import { MobileContext } from '../../../contexts/MobileContext';
import { ExpandBoxContext } from '../../../contexts/ExpandBoxContext';

/* Component */
import ArrowSVG from '../../../image/icons/ArrowSVG';
import ArrowDropDown from '../../../image/icons/ArrowDropDown';

const container = 'expandBox-container';

const ExpandBox = ({
  customHeaderContainerStyle,
  customHeaderContent,
  children,
  customArrowColor,
  disabled = false,
  mobileOnly = false,
  expandState = false,
  handleExpandClick,
  clickableSurface = false,
  uniqueIdentifier = '',
  borderClass,
  dropdown = false,
  customPage = '',
}) => {
  const { hash } = window.location;
  const { isMobile } = useContext(MobileContext);
  const { boxToExpand, toggleExpandBox } = useContext(ExpandBoxContext);
  const [expand, setExpand] = useState(expandState);

  useEffect(() => {
    if (hash && hash.replace('#', '') === uniqueIdentifier) {
      setTimeout(function () {
        if (document.getElementById(uniqueIdentifier)) {
          document.getElementById(uniqueIdentifier).scrollIntoView(true);
        }
      }, 200);
      handleExpandClick(true, uniqueIdentifier);
      setExpand(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  useEffect(() => {
    if (boxToExpand === uniqueIdentifier) {
      handleExpandClick && handleExpandClick(true, uniqueIdentifier);
      setExpand(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boxToExpand]);

  useLayoutEffect(() => {
    setExpand(expandState);
  }, [expandState]);

  const handleOnClick = event => {
    event.stopPropagation();
    event.preventDefault();
    toggleExpandBox(null);
    handleExpandClick && handleExpandClick(!expand, uniqueIdentifier);
    setExpand(prevExpand => !prevExpand);
  };

  return (
    <div className={`${container} ${customPage}`} id={uniqueIdentifier}>
      <div
        className={`expandBox-title ${customHeaderContainerStyle} ${borderClass} ${customPage}`}
        onClick={(clickableSurface || isMobile) && !disabled ? handleOnClick : null}
      >
        <div className="expandBox-title-container">
          <div className="expandBox-title-container-textContainer">{customHeaderContent}</div>
          {(!mobileOnly || isMobile) && !disabled && (
            <button
              data-cy="expandBox-dropdown-btn"
              aria-expanded="false"
              onClick={!clickableSurface && !isMobile ? handleOnClick : null}
              className={`expandBox-title-expand expandBox-title-expand-${
                expand ? (!dropdown ? 'show' : '') : 'collapse'
              }
							`}
            >
              {dropdown ? (
                <ArrowDropDown customStyle={customArrowColor} />
              ) : (
                <ArrowSVG customArrowColor={customArrowColor} />
              )}
            </button>
          )}
        </div>
      </div>
      <div
        className={`expandBox-body ${
          (!mobileOnly || isMobile) &&
          `expandBox-body-animation expandBox-body-${expand ? 'show' : 'hidden'}`
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default ExpandBox;

/* Props */
ExpandBox.propTypes = {
  /**
   * This change the header background 's styling
   * It expects string that represent a css class
   * Example can be seen in restrictions.scss, .restrictions-body-header
   */
  customHeaderContainerStyle: PropTypes.string.isRequired,
  /**
   * This is the contet which you want to put inside the header
   * It expects component
   * Example can be seen in Restrictions.jsx, restrictionHeader()
   */
  customHeaderContent: PropTypes.oneOfType([PropTypes.element.isRequired, PropTypes.any]),
  /**
   * This is the contet which you want to put inside the hiding box
   * It expects component
   * Example can be seen in Restrictions.jsx
   */
  children: PropTypes.any,
  /**
   * This change the header arrow's styling
   * It expects string that represent a css class
   * Example can be seen in restrictions.scss, .restrictions-body-arrow
   *
   * Side Note:
   * Change both height and width to control size
   * Change fill to change color
   */
  customArrowColor: PropTypes.string,
  /**
   * This disable the expand functionality when it is not in mobile device
   * It expects a boolean
   * Example can be seen in Restrictions.jsx, <ExpandBox/>
   */
  mobileOnly: PropTypes.bool,
  /**
   * This send the state of the box, whether it is expanded, back to parent
   * It expects a function
   * Example can be see in Restrictions.jsx, <ExpandBox handleExpandClick/>
   */
  handleExpandClick: PropTypes.func,
  /**
   * This can set the default state and control the expand state of the box
   * It expects a boolean
   * Example can be see in Restrictions.jsx, <ExpandBox handleExpandClick/>
   */
  expandState: PropTypes.bool,
  /**
   * For identifying this component
   * Can be used for setting state of this component in a ExpandBox group
   */
  uniqueIdentifier: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * This disable the click event and hide the arrow, removing any indication for clicking
   */
  disabled: PropTypes.bool,
  /**
   * This apply the click area to the whole header instead of just the arrow
   */
  clickableSurface: PropTypes.bool,
};
