// Lib
import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, startCase } from 'lodash';

// style
import './downloadCheckBox.scss';

// constant
const component = 'download-check-box';

const DownloadCheckBox = ({
  options,
  onSelect,
  groupName,
  selected = [],
  limit,
  toFormat = true,
  testid,
  type = '',
}) => {
  const selectedItems = !isEmpty(selected) ? selected.map(item => item.id) : [];

  // Helper
  const onCheckBoxChange = () => {
    let result = [];
    const foundCheckboxes = document.getElementsByName(groupName);
    for (let checkbox of foundCheckboxes) {
      if (checkbox.checked) {
        result.push(checkbox.value);
      }
    }
    onSelect(result);
  };

  const isDisabled = id => {
    return selectedItems.length > limit - 1 && selectedItems.indexOf(id) === -1;
  };

  return (
    <div data-cy={testid} className={`${component} ${type}`}>
      {options.map(option => {
        return (
          <div
            key={option.id}
            className={`${component}-option${option.disabled ? '-hide' : ''} ${
              selectedItems.includes(option.id) ? 'selected-option' : ''
            }`}
          >
            <input
              className={`${component}-option-btn`}
              type="checkbox"
              id={option.id}
              name={groupName}
              value={option.id}
              checked={selectedItems.includes(option.id)}
              onChange={() => onCheckBoxChange()}
              disabled={isDisabled(option.id)}
            />
            <label className={`${component}-option-lbl`} htmlFor={option.id}>
              {toFormat ? startCase(option.name) : option.name}
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default DownloadCheckBox;
DownloadCheckBox.propTypes = {
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  groupName: PropTypes.string.isRequired,
  selected: PropTypes.array,
  limit: PropTypes.number,
  toFormat: PropTypes.bool,
  testid: PropTypes.string,
  type: PropTypes.string,
};
