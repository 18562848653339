// Library
import React, { useEffect, useState, useContext } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';

// Style
import './tradingLimitVolume.scss';

// Helpers
import { getLatestNetAllocationVolume } from '../../../helpers/ApiHelper';
import { formatNumberDecimals, createMarkup } from '../../../helpers/Utils';

// Context
import { AppContext } from '../../../contexts/AppContext';

// Assets
import text from './tradingText.json';

// Constant
const container = 'trading-limit';
const LACHLAN_TRADING_CONSTRAINT_VOLUME = 82000;

const TradingLimitVolume = () => {
  const { waterSource } = useContext(AppContext);
  const { tradingLimitVolume } = text;
  const [data, setData] = useState({});

  // Life Cycle
  useEffect(() => {
    let unmounted = false;
    setData({});
    (async () => {
      const isLatest = true;
      await getLatestNetAllocationVolume(
        waterSource.water_source_id,
        function (result) {
          if (!unmounted) {
            setData(result[0]);
          }
        },
        isLatest,
      );
    })();
    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterSource.water_source_id]);

  return (
    !isEmpty(data) && (
      <div className={`${container}`}>
        <div className={`${container}-intro`}>
          <div className={`${container}-intro-title`}>{tradingLimitVolume.title}</div>
          <div
            dangerouslySetInnerHTML={createMarkup(tradingLimitVolume.intro)}
            className={`${container}-intro-text`}
          />
        </div>
        <div className={`${container}-limit`}>
          <div className={`${container}-limit-volume`}>
            Remaining
            <div className={`${container}-limit-volume-value`}>
              {formatNumberDecimals(
                LACHLAN_TRADING_CONSTRAINT_VOLUME - data.net_allocation_volume / 1000,
                false,
                false,
              )}
              &nbsp;
              <div className={`${container}-limit-volume-unit`}>ML</div>
            </div>
          </div>
          <div className={`${container}-limit-date`}>
            Updated {moment(data.date).format('DD MMM YYYY')}
          </div>
        </div>
      </div>
    )
  );
};

export default TradingLimitVolume;
