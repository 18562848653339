// Library
import React from 'react';

// Style
import './navigationHelp.scss';

// Component
import Title from '../../../title/Title';
import VideoItem from './VideoItem';
import DownloadSVG from '../../../../image/icons/DownloadSVG';

// Constant
const component = 'navigation-help';
const vids = [
  { id: 'Dd4PMmgc2tw', title: 'WaterInsights overview' },
  { id: 'oY4JRfyP4E4', title: 'How to register for an account' },
  { id: '66y4Owzt3s8', title: 'Snapshot and Water Source page overview' },
  { id: 'lVDs3tLVl1I', title: 'How to set river gauge alerts' },
  { id: 'RAsKRpLj2DM', title: 'How to set water source alerts ' },
  // { id: 'bA6GYMBfok8', title: 'How to access groundwater data' },
  // { id: 'IyWcR4-XyXY', title: 'How to download bore construction reports ' },
];

const NavigationHelp = () => {
  return (
    <div className={component}>
      <Title title={'NEED HELP NAVIGATING THE SITE?'} />
      <div className={`${component}-vids`}>
        {vids.map(vid => (
          <VideoItem key={vid.id} vidId={vid.id} vidTitle={vid.title} />
        ))}
      </div>
      <div className={`${component}-fact-sheet`}>
        Still need more help?{' '}
        <a
          className={`${component}-fact-sheet-link`}
          href={process.env.REACT_APP_FACTSHEET}
          target="_blank"
          rel="noopener noreferrer"
        >
          Download the fact sheet
          <DownloadSVG customStyle={`${component}-fact-sheet-icon`} />
        </a>
      </div>
    </div>
  );
};

export default NavigationHelp;
