import React from 'react';
import './emptyChartMsg.scss';

import InfoSVG from '../../image/icons/InfoIcon';

const container = 'empty-chart';

const EmptyChartMsg = () => {
  return (
    <div className={container}>
      <InfoSVG iconColor={`${container}-icon`} />
      <p className={`${container}-text`}> No data available </p>
    </div>
  );
};

export default EmptyChartMsg;
