import React, { useContext } from 'react';

// Components
import RainfallBody from '../../components/rainfall/RainfallBody';
import BodyContainer from './BodyContainer';
import InfoIconAndModal from '../../components/informationButton/InfoIconAndModal';

// Style
import './body.scss';

// Assests
import gsModalText from '../../components/modal/modalText/GreaterSydneyModalText.json';

// Context
import { AppContext } from '../../contexts/AppContext';

// Helpers
import { findLastWord } from '../../helpers/Utils';

// Constants
import { rainfall } from '../../abstract/IntroText.json';
const container = 'rainfall-container';

const Rainfall = () => {
  const { waterSource } = useContext(AppContext);
  const { water_source_name } = waterSource;
  const { title, intro } = rainfall;
  gsModalText.modal.title = `Understanding ${water_source_name}`;
  const { modal } = gsModalText;

  return (
    <BodyContainer
      pageTitle={
        <div className={`${container}-pageTop`}>
          <span>
            {`${title} ${findLastWord(water_source_name).notLast} `}
            <InfoIconAndModal
              modalId="rainfallTitleModal"
              modalContent={modal}
              lastWord={
                <span className={'page-body-title'}>
                  {findLastWord(water_source_name).last}
                  &nbsp;
                </span>
              }
            />
          </span>
        </div>
      }
      pageIntro={intro}
    >
      <RainfallBody />
    </BodyContainer>
  );
};

export default Rainfall;
