// note on window.msal usage. There is little point holding the object constructed by new Msal.UserAgentApplication
// as the constructor for this class will make callbacks to the acquireToken function and these occur before
// any local assignment can take place. Not nice but its how it works.
// Libraries
import * as Msal from 'msal';
import { jwtDecode } from 'jwt-decode';

const state = {
  stopLoopingRedirect: false,
  launchApp: null,
  accessToken: null,
  scopes: [],
  cacheLocation: null,
};

const config = {
  instance: process.env.REACT_APP_B2C_INSTANCE,
  tenant: process.env.REACT_APP_B2C_TENANT,
  signInPolicy: process.env.REACT_APP_B2C_SIGNIN_SIGNOUT_POLICY,
  signUpPolicy: process.env.REACT_APP_B2C_SIGNUP_POLICY,
  profilePolicy: process.env.REACT_APP_B2C_PROFILE_EDIT_POLICY,
  updatePhoneNumber: process.env.REACT_APP_B2C_UPDATE_PHONE_NUMBER,
  passwordResetPolicy: process.env.REACT_APP_B2C_PASSWORD_RESET_POLICY,
  clientId: process.env.REACT_APP_B2C_SECRET_KEY,
  cacheLocation: process.env.REACT_APP_B2C_CACHE_LOCATION,
  scopes: [process.env.REACT_APP_B2C_SCOPES],
  redirectUri: window.location.origin,
  postLogoutRedirectUri: window.location.origin,
  // redirectUri: process.env.REACT_APP_B2C_REDIRECT_URI,
  // postLogoutRedirectUri: process.env.REACT_APP_B2C_LOGOUT_REDIRECT_URI
};

const LOCAL_STORAGE = 'localStorage';
const tenantSubdomain = config.tenant.split('.')[0];
const instance = `https://${tenantSubdomain}.b2clogin.com/tfp/`;
let authority = `${instance}${config.tenant}/${config.signInPolicy}`;

function authCallback(errorDesc) {
  if (errorDesc && errorDesc.indexOf('AADB2C90118') > -1) {
    redirect();
  } else if (errorDesc && errorDesc.indexOf('AADB2C90091') > -1) {
    window.location = window.location.origin;
  } else if (errorDesc) {
    state.stopLoopingRedirect = true;
  } else {
    acquireToken();
  }
}

function redirect() {
  acquireToken();
}

function acquireToken(successCallback) {
  const user = window.msal.getUser(state.scopes);
  if (!user) {
    window.msal.loginRedirect(state.scopes);
  } else {
    window.msal.acquireTokenSilent(state.scopes).then(
      accessToken => {
        state.accessToken = accessToken;
        if (state.launchApp) {
          state.launchApp();
        }
        if (successCallback) {
          successCallback();
        }
      },
      error => {
        if (error) {
          window.msal.acquireTokenRedirect(state.scopes);
        }
      },
    );
  }
}

class AzureB2CHelper {
  constructor() {
    authority = `${instance}${config.tenant}/${config.signInPolicy}`;

    let scopes = config.scopes;
    if (!scopes || scopes.length === 0) {
      state.stopLoopingRedirect = true;
    }
    state.scopes = scopes;
    state.cacheLocation = config.cacheLocation;
    new Msal.UserAgentApplication(config.clientId, authority, authCallback, {
      cacheLocation: config.cacheLocation,
      redirectUri: `${config.redirectUri}`,
      postLogoutRedirectUri: config.postLogoutRedirectUri,
      validateAuthority: false,
      storeAuthStateInCookie: true,
    });
  }

  /** isLoggedIn method */
  async isLoggedIn() {
    var token = await window.msal.acquireTokenSilent(config.scopes).then(
      accessToken => {
        return accessToken;
      },
      async error => {
        console.log(error);
      },
    );
    if (token) return true;
    return false;
  }

  redirectToLoginPage() {
    window.msal.authority = `${instance}${config.tenant}/${config.signInPolicy}`;
    window.msal.loginRedirect(config.scopes);
  }

  redirectToSignUpPage() {
    window.msal.authority = `${instance}${config.tenant}/${config.signUpPolicy}`;
    //window.msal.loginRedirect(config.scopes);
    window.msal.loginRedirect(config.scopes, {
      forceRefresh: true,
      storeAuthStateInCookie: true,
    });
  }

  redirectToProfileEditPage() {
    window.msal.authority = `${instance}${config.tenant}/${config.profilePolicy}`;

    if (config.cacheLocation === LOCAL_STORAGE) {
      const keys = Object.keys(window.localStorage);
      for (const key of keys) {
        if (key.startsWith('{"authority":')) {
          const val = JSON.parse(window.localStorage.getItem(key));
          if (val != null) {
            window.localStorage.removeItem(key);
          }
        }
      }
    } else {
      const keys = Object.keys(window.sessionStorage);
      for (const key of keys) {
        if (key.startsWith('{"authority":')) {
          const val = JSON.parse(window.sessionStorage.getItem(key));
          if (val != null) {
            window.sessionStorage.removeItem(key);
          }
        }
      }
    }

    window.msal.acquireTokenRedirect(
      state.scopes,
      `${instance}${config.tenant}/${config.profilePolicy}`,
      window.msal.getUser(state.scopes),
      { forceRefresh: true, storeAuthStateInCookie: true },
    );
  }

  redirectToUpdatePhoneNumber() {
    window.msal.authority = `${instance}${config.tenant}/${config.updatePhoneNumber}`;

    if (config.cacheLocation === LOCAL_STORAGE) {
      const keys = Object.keys(window.localStorage);
      for (const key of keys) {
        if (key.startsWith('{"authority":')) {
          const val = JSON.parse(window.localStorage.getItem(key));
          if (val != null) {
            window.localStorage.removeItem(key);
          }
        }
      }
    } else {
      const keys = Object.keys(window.sessionStorage);
      for (const key of keys) {
        if (key.startsWith('{"authority":')) {
          const val = JSON.parse(window.sessionStorage.getItem(key));
          if (val != null) {
            window.sessionStorage.removeItem(key);
          }
        }
      }
    }

    window.msal.acquireTokenRedirect(
      config.scopes,
      `${instance}${config.tenant}/${config.updatePhoneNumber}`,
      window.msal.getUser(config.scopes),
      {
        forceRefresh: true,
        storeAuthStateInCookie: true,
      },
    );
    // window.msal.authority = `${instance}${config.tenant}/${config.updatePhoneNumber}`;
    // window.msal.loginRedirect(config.scopes);
  }

  redirectToPasswordResetPage() {
    window.msal.authority = `${instance}${config.tenant}/${config.passwordResetPolicy}`;
    window.msal.loginRedirect(config.scopes);
  }

  /** logout */
  logout() {
    window.msal.logout();
  }

  async getToken() {
    return await window.msal.acquireTokenSilent(config.scopes).then(
      accessToken => {
        return accessToken;
      },
      // async error => {
      //   console.log(error);
      //   return window.msal.acquireTokenRedirect(config.scopes);
      // },
    );
  }

  /*
    Please note that this sometimes will open a popup to get user info
    */
  async getUserInfoFromToken() {
    const token = await this.getToken();
    const decoded = jwtDecode(token);
    return {
      id: decoded.uid,
      email: decoded.email,
      emailVerified: decoded.email_verified,
      surname: decoded.family_name,
      givenName: decoded.given_name,
      mobileNumber: decoded.phone_number,
      mobileNumberVerified: decoded.phone_number_verified,
    };
  }

  getUserEmail() {
    if (this.isLoggedIn()) {
      var user = window.msal.getUser(config.scopes);
      if (user) return user.idToken.email;
    } else {
      return '';
    }
  }
}

const azureB2CHelper = new AzureB2CHelper();

export default azureB2CHelper;
