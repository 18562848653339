// Libraries
import React, { useContext } from 'react';

// Styles
import './header.scss';

// Context
import { MobileContext } from '../../contexts/MobileContext';

// Components
import TopNavBar from '../navBars/TopNavBar';
import NavBar from '../navBars/NavBar';

export default function Header() {
  const { isMobile } = useContext(MobileContext);

  return (
    <div>
      {!isMobile && <TopNavBar />}
      <NavBar />
    </div>
  );
}
