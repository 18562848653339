import React from 'react';
import PropTypes from 'prop-types';

const ArrowSVG = ({ customArrowColor }) => {
  return (
    <svg
      className={` ${customArrowColor ? customArrowColor : 'expandBox-title-expand-shape'}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="black"
      width="18px"
      height="18px"
    >
      <path d="M0 0h24v24H0V0z" fill="none" opacity=".87" />
      <path d="M17.51 3.87L15.73 2.1 5.84 12l9.9 9.9 1.77-1.77L9.38 12l8.13-8.13z" />
    </svg>
  );
};

export default ArrowSVG;

/* Props */
ArrowSVG.propTypes = {
  /**
   * This change the header arrow's styling
   * It expects string that represent a css class
   * Example can be seen in restrictions.scss, .restrictions-body-arrow
   *
   * Side Note:
   * Change both height and width to control size
   * Change fill to change color
   */
  customArrowColor: PropTypes.string,
};
