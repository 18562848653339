// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './Modal.scss';
import './requestModal.scss';

// Data
import data from './modalText/RequestModalData.json';

// Components
import Modal from './Modal';
import CircleTickSVG from '../../image/icons/CircleTickSVG';
import DuplicateSVG from '../../image/icons/DuplicateSVG';
import FailedCrossSVG from '../../image/icons/FailedCrossSVG';
import Loader from '../loader/Loader';

export const API_STATUS = {
  SUCCESS: 'success',
  FAIL: 'failed',
  DUP: 'duplicate',
  LOAD: 'loading',
  FORBIDDEN: 'forbidden',
};

const RequestModal = ({ id, feedbackStatus }) => {
  const API_STATUS_ICON = {
    success: <CircleTickSVG customClass={`icon-${feedbackStatus}`} />,
    duplicate: <DuplicateSVG customClass={`icon-${feedbackStatus}`} />,
    failed: <FailedCrossSVG customClass={`icon-${feedbackStatus}`} />,
    forbidden: <FailedCrossSVG customClass={`icon-${feedbackStatus}`} />,
    loading: <Loader staticPosition />,
  };

  return (
    <Modal
      id={id}
      body={
        feedbackStatus && (
          <>
            <div data-testid="request-modal-body" className="noVerticalPadding">
              <div className="modal-title centered noVerticalPadding">
                <div className="item">{API_STATUS_ICON[feedbackStatus]}</div>
                <div className={`item title-text icon-${feedbackStatus}`}>
                  {data[feedbackStatus].title}
                </div>
              </div>
            </div>
            <div className={'centered body-text'}>{data[feedbackStatus].body}</div>
          </>
        )
      }
      footer={
        <button className={'okButton '} data-dismiss="modal">
          OK
        </button>
      }
      footerClassName={'request-modal-footer'}
    />
  );
};

export default RequestModal;

RequestModal.propTypes = {
  id: PropTypes.string,
  feedbackStatus: PropTypes.string,
};
