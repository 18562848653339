// Helper
import { checkIfNotEmpty } from './FeedbackValidation';
import { getAPIToken, postFeedback } from '../../helpers/ServicesApiHelper';

export const handleSubmit = async (
  e,
  formStructure,
  honeypot,
  setShowResponseMsg,
  setResponseType,
) => {
  e.preventDefault();

  let formData = {};
  let error = false;

  formStructure.forEach(formItem => {
    let { validated, tempVal, errorMsg } = validateFormItem(formItem);

    if (!validated) {
      error = true;
      formItem.setField(prev => {
        return { ...prev, error: true, errorMsg };
      });
    } else {
      formData[formItem.key] = typeof variable === 'string' ? tempVal?.trim() : tempVal;
    }
  });

  if (!error && !honeypot.value) {
    const { name, email, message, feedbackType } = formData;
    const token = await getAPIToken();
    const success = await postFeedback(
      name,
      email,
      `feedback from ${name}`,
      message,
      feedbackType.type,
      token,
    );
    if (success) {
      response(setShowResponseMsg, setResponseType, 'success');
    } else {
      response(setShowResponseMsg, setResponseType, 'fail');
    }
    cleanUp(formStructure, honeypot);
  }
};

const validateFormItem = formItem => {
  if (!formItem.validate) {
    return { validated: true, tempVal: formItem.field.value, errorMsg: '' };
  }
  let tempVal = formItem.field.value;
  let validated = checkIfNotEmpty(tempVal);
  let errorMsg = validated ? '' : 'This field cannot be blank';

  if (formItem.validation) {
    validated = formItem.validation(tempVal);
    if (!validated && !errorMsg) {
      errorMsg = 'Please enter a valid email';
    }
  }

  return { validated, tempVal, errorMsg };
};

const response = (setShowResponseMsg, setResponseType, resultType) => {
  setShowResponseMsg(true);
  setResponseType(resultType);
};

const cleanUp = (formStructure, honeypot) => {
  formStructure.forEach(formItem => {
    formItem.setField({ value: '', error: false, errorMsg: '' });
  });
  honeypot.setValue('');
};
