/* Library */
import React from 'react';

/* Style */
import './body.scss';
import '../grid.scss';

/* Components */
import AllUpdates from '../../components/updates/AllUpdates';
import BodyContainer from './BodyContainer';

export default function Update() {
  return (
    <BodyContainer>
      <div data-testid="body-container" className="body-container-subcontent">
        <AllUpdates />
      </div>
    </BodyContainer>
  );
}
