// Libraries
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

//contexts
import { UserContext } from '../../contexts/UserContext';

//fontawesone icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

// Styles
import './sideDrawer.scss';

// Assets
import constants from '../../constants/Constants';

// Components
import NavBarItem from '../navLinkItems/NavBarItem';
import Logo from '../logo/Logo';
import ExternalLinkModal from '../modal/ExternalLinkModal';
import ExpandLink from '../expandLink/ExpandLink';
import LoginButton from '../login/LoginButton';

// Helper
import { isMiniApp } from '../../helpers/UrlGenerator.jsx';
import azureB2CHelper from '../../helpers/AzureB2CHelper';

export default function SideDrawer({ onCloseBtnClicked, sideDrawerOpen }) {
  const IS_MINI_APP = isMiniApp();
  const { isLoggedIn, setUser } = useContext(UserContext);
  const [externalSiteLink, setExternalSiteLink] = useState('');
  const [userInfo, setUserInfo] = useState('');

  const LINK_ITEMS = isMiniApp() ? constants.LINK_ITEMS_MINI : constants.LINK_ITEMS_MOBILE;

  useEffect(() => {
    (async () => {
      if (isLoggedIn) {
        const info = await azureB2CHelper.getUserInfoFromToken();
        setUserInfo(info);
        setUser(info);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const onCloseBtnClick = () => {
    onCloseBtnClicked();
  };

  const onExternalSiteClick = link => {
    onCloseBtnClick();
    setExternalSiteLink(link);
  };

  const userName = () => {
    return <span className="drawer-header-name">Hi {userInfo.givenName},</span>;
  };

  return (
    <div className="side-drawer-container">
      <nav className={`side-drawer ${IS_MINI_APP ? 'mini' : ''} ${sideDrawerOpen ? 'open' : ''}`}>
        <div className="side-drawer-links">
          {!IS_MINI_APP ? (
            <div className="drawer-header">
              <Logo />
              <button className="close-menu-button" onClick={() => onCloseBtnClick()}>
                <span className="icon-container">
                  <FontAwesomeIcon
                    icon={faXmark}
                    className={isMiniApp() ? `close-menu-icon mini` : `close-menu-icon`}
                  />
                </span>
              </button>
            </div>
          ) : (
            <div className="drawer-header mini">
              {isLoggedIn ? userName() : <span />}
              <button className="close-menu-button" onClick={() => onCloseBtnClick()}>
                <span className="icon-container">
                  <FontAwesomeIcon
                    icon={faXmark}
                    className={isMiniApp() ? `close-menu-icon mini` : `close-menu-icon`}
                  />
                </span>
              </button>
            </div>
          )}
          {!IS_MINI_APP && isLoggedIn && userName()}
          <div className="drawer-links-container">
            {LINK_ITEMS.map((linkItem, index) => (
              <NavBarItem
                key={index}
                style={!isLoggedIn && linkItem.isPrivate && 'not-show'}
                {...linkItem}
                className={IS_MINI_APP ? 'min-nav-item' : 'nav-item'}
                onLinkClick={() => onCloseBtnClick()}
              />
            ))}
          </div>

          {/* full-version, shows the External sites */}
          {!IS_MINI_APP && (
            <ExpandLink
              onExternalSiteClick={link => onExternalSiteClick(link)}
              title="External Sites"
              linkItems={constants.EXTERNAL_LINK_ITEMS}
            />
          )}
        </div>
        <LoginButton customStyle={isLoggedIn ? 'logout-link' : 'login-link'} isMini={IS_MINI_APP} />
      </nav>
      <ExternalLinkModal link={externalSiteLink} modalId="externalLinkModal" />
    </div>
  );
}

SideDrawer.propTypes = {
  onCloseBtnClicked: PropTypes.func,
  sideDrawerOpen: PropTypes.bool,
};
