// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './updateAlertLink.scss';

// Component
import AlertLink from '../alert/AlertLink';

// Context
import AlertResponse from '../alert/AlertResponse';

const component = 'update-alert-link';

export default function UpdateAlertLink({
  showResponse,
  setShowResponse,
  modalBody,
  alertText,
  stationInfo,
  customStyle,
  modalType,
}) {
  return (
    <div
      className={component}
      onClick={() => {
        setTimeout(function () {
          showResponse === true && setShowResponse(false);
        }, 500);
      }}
    >
      <AlertLink
        alertModal={
          <>
            <AlertResponse show={showResponse} responseBodyText={alertText} />
            {!showResponse && modalBody}
          </>
        }
        showManageAlertResponse={showResponse}
        setShowManageAlertResponse={setShowResponse}
        stationInfo={stationInfo}
        modalType={modalType}
        customStyle={customStyle}
      />
    </div>
  );
}

UpdateAlertLink.propTypes = {
  showResponse: PropTypes.bool,
  setShowResponse: PropTypes.func,
  modalBody: PropTypes.any,
  alertText: PropTypes.string,
  stationInfo: PropTypes.any,
  customStyle: PropTypes.string,
};
