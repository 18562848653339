// Library
import React, { useContext } from 'react';

// Styles
import './stationDownloadFiltersSummary.scss';

// Context
import { DownloadContext } from '../../../../../contexts/DownloadContext';
// Assest
import ArrowSVG from '../../../../../image/icons/ArrowSVG';

const component = 'station-download-filters-summary';
const StationDownloadFiltersSummary = ({ children, onClick, isGraphInit, hideFilters }) => {
  const { selectedStations, stationInfoType, hydrometric } = useContext(DownloadContext);
  const formattedHydrometric = hydrometric.map(item => {
    const newName = item.name.split('.')[0];
    const sliceIndex = item.name.includes('.') ? -1 : newName.length;
    return {
      ...item,
      name: newName.slice(0, sliceIndex).replace('Measured ', ''),
    };
  });

  return (
    <>
      <div className={`${component}`}>
        {hideFilters && isGraphInit && (
          <div className={`${component}-title`}>{`${selectedStations
            .map(item => item.id)
            .join(', ')} - ${stationInfoType} - ${Array.from(
            new Set(formattedHydrometric.map(item => item.name)),
          ).join(', ')}`}</div>
        )}
        <div className={`${component}-content ${hideFilters && `${component}-content-hidden`}`}>
          {children}
        </div>
        {isGraphInit && (
          <div className={`${component}-arrow-container`} onClick={onClick}>
            <ArrowSVG
              customArrowColor={`${component}-arrow ${
                hideFilters && `${component}-arrow-collapse`
              }`}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default StationDownloadFiltersSummary;
