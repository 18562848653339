// Library
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// Components
import { removeAlert } from '../../helpers/SubscriptionsApiHelper';

// Context
import { UserContext } from '../../contexts/UserContext';

// Style
import './alertResponse.scss';

// Constant
const container = 'alert-response';

const RemoveAlertModalBody = ({ message, alert, setManageAlertResponse }) => {
  const { user } = useContext(UserContext);

  const handleSubmit = async alertId => {
    const response = await removeAlert(alertId, user.email);
    if (response === true) {
      setManageAlertResponse('remove', alert);
    } else {
      setManageAlertResponse('error');
    }
  };

  return (
    <>
      <div data-testid={'remove-alert-modal-body'}>
        <div className={`${container}-body-text`}>{message}</div>
      </div>
      <div className={`${container}-confirmation`}>
        <div
          className={`${container}-button remove ${container}-button-ok`}
          onClick={e => {
            e.preventDefault();
            handleSubmit(alert.alert);
          }}
        >
          Yes
        </div>
        <div
          className={`${container}-button remove ${container}-button-cancel`}
          data-dismiss="modal"
        >
          Cancel
        </div>
      </div>
    </>
  );
};

export default RemoveAlertModalBody;

RemoveAlertModalBody.propTypes = {
  messageTitle: PropTypes.string,
  messageBody: PropTypes.string,
  alert: PropTypes.any,
  setManageAlertResponse: PropTypes.func,
};
