// Library
import React from 'react';

// Component
import NewDownload from './new/Download';

const Download = () => {
  return <NewDownload />;
};

export default Download;
