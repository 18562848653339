// Library
import React, { useEffect } from 'react';

// Component
import PageTop from '../../components/pageTop/PageTop';
import GlossaryBody from '../../components/glossary/Glossary';
import BackLink from '../../components/backLink/BackLink';
import BodyContainer from './BodyContainer';

// Helper
import { isMiniApp } from '../../helpers/UrlGenerator.jsx';

export default function Glossary() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <BodyContainer page>
      {!isMiniApp && <BackLink />}
      <PageTop title={`Glossary`} />
      <GlossaryBody />
    </BodyContainer>
  );
}
