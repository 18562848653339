// Library
import React from 'react';
import PropTypes from 'prop-types';

const RiverTown = ({ x, y, name, textX, textY }) => {
  return (
    <>
      <circle cx={x} cy={y} r="10" stroke="#63CBE8" strokeWidth="3" fill="white" />
      <text
        x={textX}
        y={textY}
        style={{
          fontWeight: 'bold',
          color: '#4b555a',
          fontSize: '13px',
        }}
      >
        {name}
      </text>
    </>
  );
};

export default RiverTown;

RiverTown.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  name: PropTypes.string,
  textX: PropTypes.number,
  textY: PropTypes.number,
};
