// Libraries
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// Styles
import './body.scss';
import '../grid.scss';

// Constants
import constants from '../../constants/Constants';

// Context
import { MobileContext } from '../../contexts/MobileContext';
import { MapContext } from '../../contexts/MapContext';

// Components
import PageTop from '../../components/pageTop/PageTop';

const component = 'body-container';
export default function BodyContainer({
  pageTitle,
  pageIntro,
  pageSubIntro,
  children,
  setModalLink,
  modalTargetId = '',
  page = false,
  headerRight,
  menuLeft,
  menuRight,
}) {
  const { orientation, isLarge } = useContext(MobileContext);
  const { expandMap } = useContext(MapContext);

  const onTabClick = () => {
    if (!orientation.includes(constants.LANDSCAPE) || !isLarge) {
      expandMap(false);
    }
    document.getElementById('base-map-container').click();
  };

  const content = () => {
    return (
      <>
        {pageTitle && (
          <div className={`${component}-inner-header`}>
            {(pageTitle || pageIntro || pageSubIntro) && (
              <PageTop
                title={pageTitle}
                intro={pageIntro}
                subIntro={pageSubIntro}
                setModalLink={link => setModalLink(link)}
                modalTargetId={modalTargetId}
              />
            )}
            <div className={`${component}-inner-menu${!menuLeft && !menuRight ? ' empty' : ''}`}>
              {menuLeft}
              {menuRight}
            </div>
            {headerRight}
          </div>
        )}
        {children}
      </>
    );
  };

  return (
    <div className={`${component}-outer`} onClick={page ? () => {} : onTabClick}>
      <div className={`${component}-inner`}>{content()}</div>
    </div>
  );
}

BodyContainer.propTypes = {
  pageTitle: PropTypes.any,
  pageIntro: PropTypes.string,
  pageSubIntro: PropTypes.array,
  children: PropTypes.any,
  setModalLink: PropTypes.func,
  modalTargetId: PropTypes.string,
  page: PropTypes.bool,
  headerRight: PropTypes.any,
  menuLeft: PropTypes.any,
  menuRight: PropTypes.any,
};
