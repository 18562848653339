// Libraries
import React, { useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import { Map, TileLayer, ZoomControl, GeoJSON, Marker, Pane } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { isEmpty, cloneDeep } from 'lodash';
import 'leaflet-gesture-handling';
import * as esri from 'esri-leaflet';

// Styles
import 'leaflet/dist/leaflet.css';
import './baseMap.scss';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';

// Context
import { AppContext } from '../../contexts/AppContext';
import { MapContext } from '../../contexts/MapContext';
import { MobileContext } from '../../contexts/MobileContext';
import { MapFilterContext } from '../../contexts/MapFilterContext';

// Components
import MapFilterMarker from '../mapMarker/MapFilterMarker';
import PumpFlowStatusMarker from '../mapMarker/PumpFlowStatusMarker';
import RiverSection from '../mapSection/RiverSection';
import FlowClassMap from '../mapSection/FlowClassMap';
import MapFilter from '../mapFilter/MapFilter';
import FlowClassMapLegend from '../mapProperties/FlowClassMapLegend';
import OrderUsageMapLegend from '../mapProperties/OrderUsageMapLegend';
import MapGridLayer from '../mapGridLayer/MapGridLayer';
import StateOverviewPopups from '../pageComponents/stateOverview/StateOverviewPopups';
import DroughtStatusRiverSection from '../pageComponents/stateOverview/DroughtStatusRiverSection';
import StateOverviewMapLegend from '../mapProperties/stateOverview/StateOverviewMapLegend';
import FlowRateMapLegend from '../mapProperties/FlowRateMapLegend';
import WaterQualityLegend from '../mapProperties/WaterQualityLegend';
import WaterQualityMarker from '../mapMarker/WaterQualityMarker';
import EventsMarker from '../mapMarker/EventsMarker';
import NeighbouringWaterSources from '../neighbouringNavigationArrow/NeighbouringNavigationArrow';
import DataQualityLegend from '../generalComponents/dataQuality/DataQualityLegend';

// Helpers
import {
  promiseXMLHttpRequestMapService,
  queryEsri,
  queryNSWMapIdentify,
} from '../../helpers/MapServiceApiHelper';
import { getAllEventsFromWaterSource } from '../../helpers/UpdatesApiHelper.jsx';
import { isActiveMgmtActive } from '../../helpers/Utils';
import { setTimeOutForPromise } from '../../helpers/TimeUtils';
import { getMapFilterItems } from '../../helpers/ProcessDataHelper';

// Assets
import locatorIcon from '../../image/icons/locator_filled.png';

// Hooks
import useWindowSize from '../../hooks/WindowSizeHook';

// Constants
import constants from '../../constants/Constants.jsx';
import mapFilterConstant from '../../constants/MapFilterConstants.jsx';
const {
  PUMP,
  RAINFALL,
  ORDER_USAGE,
  FLOW_RATE,
  DROUGHT_STATUS,
  GAUGE,
  WATER_QUALITY,
  EVENTS,
  DAM,
  CONSTRAINTS,
  ENVIRONMENT,
} = mapFilterConstant.MAP_POPUP_ITEMS;

const MAP_SERVICE_PARAMS = {
  REG: { layerId: '2', where: 'WS_WATER_LOCATION_ID=' },
  UNREG: { layerId: '6', where: 'WS_WATER_LOCATION_ID=' },
  GW: { layerId: '7', where: 'WS_WATER_LOCATION_ID=' },
  MZ_FLOW_STATUS: { layerId: '0' },
};

const dropPinIcon = L.icon({
  iconUrl: locatorIcon,
  iconSize: [22, 28],
  iconAnchor: [11, 35],
});

const PROMISE_TIMEOUT = 15000;
const MAP_INITIAL_POSITION = [-34, 143];

export default function BaseMap(props) {
  // Refs
  const mapRef = useRef(null);
  const markerClusterRef = useRef(null);

  // Context
  const { waterSource } = useContext(AppContext);
  const { markerSelected, toggleMarker, expandMap, mapExpanded } = useContext(MapContext);
  const { isMobile, orientation, isLarge } = useContext(MobileContext);
  const { toggleMapFilter } = useContext(MapFilterContext);

  // States
  const [geoJsonData, setGeoJsonData] = useState([]);
  const [bounds, setBounds] = useState();
  const [zoom, setZoom] = useState(10);
  const [markerPosition, setMarkerPosition] = useState(Array);
  // eslint-disable-next-line no-unused-vars
  const [waterSourceEvents, setWaterSourceEvents] = useState([]);
  const [mapCenterPoint, setMapCenterPoint] = useState(MAP_INITIAL_POSITION);
  const [showRiver, setShowRiver] = useState(true);
  const [backupUsed, setBackupUsed] = useState(false);
  const [rainfallRadioItem, setRainfallRadioItem] = useState('observed');
  const [mapFilters, setMapFilters] = useState(Array);
  const [visibleNeighbouringWS, setVisibleNeighbouringWS] = useState(false);
  const [flowRateThreshold, setFlowRateThreshold] = useState([]);
  const [waterQualityFilterEnabled, setWaterQualityFilterEnabled] = useState();

  // Variables
  const size = useWindowSize();
  const BARWON_DARLING_ACTIVE_MGMT = isActiveMgmtActive(waterSource.water_source_id, true);
  const orderUsageFilter = mapFilters.find(
    filter => filter.selected && filter.type === ORDER_USAGE.name,
  );
  const gaugeFilter = mapFilters.find(filter => filter.type === GAUGE.name);
  const waterQualityFilter = mapFilters.find(filter => filter.type === WATER_QUALITY.name);
  const storageFilter = mapFilters.find(filter => filter.type === DAM.name);
  const waterSourceType =
    waterSource.water_source_type?.length > 1 ? 'MIX' : waterSource.water_source_type;

  // Life Cycle
  useEffect(() => {
    viewportUpdate();
    window.addEventListener('resize', viewportUpdate);
    return () => {
      window.removeEventListener('resize', viewportUpdate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const viewportUpdate = () => {
    // loadMapGeoJsonData(false);

    bounds &&
      setVisibleNeighbouringWS(
        bounds.contains(mapRef.current.viewport.center) && zoom >= 8 && zoom <= 10,
      );
  };

  useEffect(() => {
    let unmounted = false;
    let filteredArray = [];
    toggleMapFilter('', {});
    initializeWaterSourceEvents();
    (async () => {
      if (!isEmpty(waterSource)) {
        loadMapGeoJsonData(unmounted);
        filteredArray = await getMapFilterItems(waterSource, isMobile, {});
        if (isMobile) {
          filteredArray = filteredArray.map(filter => {
            if (filter.type === RAINFALL.name) return filter;
            return { ...filter, selected: true };
          });
          updateLeafletComponentStyle('leaflet-bottom', '10vw');
        }
        setMapFilters(filteredArray);
      } else {
        filteredArray.push(
          mapFilterConstant.MAP_FILTERS_OPTIONS.RAINFALL,
          mapFilterConstant.MAP_FILTERS_OPTIONS['WATER-QUALITY'],
          mapFilterConstant.MAP_FILTERS_OPTIONS['DROUGHT-STATUS'],
        );
        setMapCenterPoint(MAP_INITIAL_POSITION);
        setZoom(6);
        if (isEmpty(props.pinLocation)) {
          setMarkerPosition([]);
          setBounds();
        }
        setMapFilters(filteredArray);
        if (isMobile) {
          updateLeafletComponentStyle('leaflet-bottom', '0px');
        }
      }
    })();
    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterSource]);

  useEffect(() => {
    if (markerSelected) {
      zoomAndShowMarkerSelected();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markerSelected]);

  useEffect(() => {
    setVisibleNeighbouringWS(false);
  }, [waterSource]);

  useEffect(() => {
    let unmounted = false;
    initializeWaterSourceEvents();
    if (props.loadMapData.reload) {
      loadMapGeoJsonData(unmounted);
    }
    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.loadMapData.count]);

  useEffect(() => {
    if (orientation.includes(constants.LANDSCAPE) && isLarge) {
      expandMap(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orientation, isLarge]);

  useEffect(() => {
    if (!isEmpty(mapFilters)) {
      const orderDiff = mapFilters.find(
        filter =>
          (filter.type === ORDER_USAGE.name || filter.type === FLOW_RATE.name) && filter.selected,
      );
      setShowRiver(typeof orderDiff === 'undefined');
      const waterQuality = mapFilters.find(filter => filter.type === WATER_QUALITY.name);
      setWaterQualityFilterEnabled(waterQuality?.selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapFilters]);

  useEffect(() => {
    if (!isEmpty(props.pinLocation)) {
      handleLocationFound(props.pinLocation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.pinLocation]);

  useEffect(() => {
    if (props.findLocation) {
      initializeWaterSourceEvents();
      mapRef.current.leafletElement.locate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.findLocation]);

  useEffect(() => {
    setTimeout(function () {
      if (mapRef.current) {
        mapRef.current.leafletElement.invalidateSize();
      }
    }, 400);
  }, [size.width]);

  // Helpers
  const updateLeafletComponentStyle = (className, marginBottom) => {
    let attributionComponent = document.getElementsByClassName(className);
    attributionComponent = Array.from(attributionComponent);
    if (attributionComponent.length > 0) {
      attributionComponent.forEach(component => {
        component.style.marginBottom = marginBottom;
      });
    }
  };

  const loadMapGeoJsonData = unmounted => {
    let queryProps = [];
    if (BARWON_DARLING_ACTIVE_MGMT) {
      const params = MAP_SERVICE_PARAMS.MZ_FLOW_STATUS;
      const backupParams = MAP_SERVICE_PARAMS[waterSource.water_source_type];
      queryProps.push({
        url: constants.BARWON_DARLING_FLOW_CLASS + params.layerId,
        backupURL: constants.NSW_MAP_SERVICE_BASE_URL + backupParams.layerId,
        queryString: '1=1',
        backupQuery: backupParams.where + waterSource.water_source_id,
      });
      setBackupUsed(false);
    } else if (waterSource.water_source_id === 12964) {
      const params = { layerId: '/2' };
      queryProps.push({
        url: constants.GREATER_SYDNEY_MAP + params.layerId,
        queryString: '1=1',
      });
    } else {
      waterSource.water_source_type.forEach(waterType => {
        const params = MAP_SERVICE_PARAMS[waterType];
        queryProps.push({
          url: constants.NSW_MAP_SERVICE_BASE_URL + params.layerId,
          backupURL: '',
          queryString: params.where + waterSource.water_source_id,
          backupQuery: '',
        });
      });
    }
    queryProps.forEach(queryProp => {
      queryEsri(queryProp.url, queryProp.queryString, function (result) {
        if (queryProp.backupURL) {
          queryEsri(queryProp.backupURL, queryProp.backupQuery, function (backupResult) {
            runQuery(result, backupResult);
          });
        } else {
          runQuery(result);
        }
      });
    });

    async function runQuery(query, backupQuery) {
      props.setShowSpinner(true);
      let queryPromise = promiseXMLHttpRequestMapService(query);
      let queryResult = await setTimeOutForPromise(PROMISE_TIMEOUT, queryPromise);
      if (!queryResult.error || isEmpty(queryResult)) {
        const geoJsonLayer = L.geoJSON(queryResult);
        if (!unmounted) {
          if (!isEmpty(queryResult.features)) {
            setGeoJsonData(geoJsonData => [...geoJsonData, queryResult]);
            if (!isEmpty(geoJsonLayer)) {
              updateMapBound(props.locationGeometry, geoJsonLayer);
            }
          } else if (isEmpty(queryResult.features) && backupQuery) {
            setBackupUsed(true);
            runQuery(backupQuery);
          }
          props.getWaterSourceList([waterSource]);
        }
      } else if (backupQuery) {
        setBackupUsed(true);
        runQuery(backupQuery);
      } else {
        if (!unmounted) {
          props.setShowSpinner(false);
          props.setMapErrorMsg(true);
        }
      }
    }
  };

  async function initializeWaterSourceEvents() {
    toggleMarker(undefined);
    setWaterSourceEvents([]);
    setGeoJsonData([]);
    esri.basemapLayer('Topographic').addTo(mapRef.current.leafletElement);
    props.setMapErrorMsg(false);
    if (!isEmpty(waterSource)) {
      getAllEventsFromWaterSource(waterSource, setWaterSourceEvents);
    }
  }

  const onZoomEnd = () => {
    const zoomLevel = mapRef.current.leafletElement.getZoom();
    setZoom(zoomLevel);
  };

  const updateMapBound = (locationGeometry, geoJsonLayer) => {
    const boundCenter = geoJsonLayer.getBounds().getCenter();
    if (!isEmpty(locationGeometry) && !isEmpty(boundCenter)) {
      const locatorMarker = L.latLng(locationGeometry.y, locationGeometry.x);
      const newBounds = L.latLngBounds(locatorMarker, L.latLng(boundCenter.lat, boundCenter.lng));
      setMarkerPosition(locatorMarker);
      updateState(newBounds, locatorMarker, boundCenter);
    } else {
      setMarkerPosition([]);
      updateState(geoJsonLayer.getBounds(), null, boundCenter);
    }
  };

  const updateState = (newBounds, locatorMarker, boundCenter) => {
    if (newBounds.equals(bounds)) {
      props.setShowSpinner(false);
    } else {
      setBounds(newBounds);
      setMapCenterPoint(null);
    }
    if (locatorMarker) setMapCenterPoint(locatorMarker);
    if (isMobile) {
      setZoom(9);
      setMapCenterPoint(boundCenter);
    }
  };

  const handleLocationFound = async e => {
    const latitude = e.latitude || e.lat;
    const longitude = e.longitude || e.lng;
    const locatorMarker = L.latLng(latitude, longitude);
    setMarkerPosition(locatorMarker);
    setMapCenterPoint(locatorMarker);

    const geometry = { x: longitude, y: latitude };
    const identifyResult = await queryNSWMapIdentify(geometry);
    if (identifyResult) {
      props.getWaterSourceList(identifyResult.results);
    }
  };

  const zoomAndShowMarkerSelected = () => {
    expandMap(true);
    checkFilter();
    // setZoom(11);
    const locatorMarker = L.latLng(markerSelected.lat, markerSelected.long);
    setMapCenterPoint(locatorMarker);
    toggleMarker(undefined);
  };

  const checkFilter = () => {
    const cloneMapFilters = cloneDeep(mapFilters);
    const isFilterOn = cloneMapFilters.some(element => {
      return element.type === markerSelected.station_type && element.selected;
    });

    if (!isFilterOn) {
      const targetFilter = cloneMapFilters.find(
        filter => filter.type === markerSelected.station_type,
      );
      if (targetFilter) targetFilter.selected = true;
      setMapFilters(cloneMapFilters);
      toggleMarker(markerSelected);
    }
  };

  const createClusterCustomIcon = cluster => {
    return L.divIcon({
      html: `<div>${cluster.getChildCount()}</div>`,
      className: 'marker-cluster-custom',
      iconSize: L.point(32, 32, true),
    });
  };

  const onRadioOptionChange = (value, type) => {
    if (type === RAINFALL.name) {
      setRainfallRadioItem(value);
    }
    props.setRadioItem(value);
  };

  const onMapFilterToggle = type => {
    const clonedFilters = cloneDeep(mapFilters);
    let currentFilter;
    clonedFilters.forEach((item, index) => {
      if (item.type === type) {
        currentFilter = clonedFilters[index];
        clonedFilters[index].selected = isMobile ? true : !item.selected;
      }
    });
    if (isEmpty(waterSource)) {
      const selectedIndex = clonedFilters.findIndex(item => item.type === type);
      clonedFilters.forEach((item, index) => {
        if (index !== selectedIndex) {
          clonedFilters[index].selected = false;
        }
      });
    }

    const disableTypesMap = {
      [FLOW_RATE.name]: [ORDER_USAGE.name],
      [ORDER_USAGE.name]: [FLOW_RATE.name],
      [GAUGE.name]: [WATER_QUALITY.name],
      [WATER_QUALITY.name]: [GAUGE.name, DAM.name],
      [CONSTRAINTS.name]: [GAUGE.name, DAM.name],
      [EVENTS.name]: [GAUGE.name, DAM.name],
      [ENVIRONMENT.name]: [GAUGE.name, DAM.name],
    };

    if (Object.keys(disableTypesMap).includes(type) && currentFilter.selected) {
      disableTypesMap[type].map(item => {
        let current = clonedFilters.find(element => element.type === item);
        if (current) {
          current.selected = false;
        }
      });
    }

    setMapFilters(clonedFilters);
  };

  // to be revisited
  // const onMapClicked = (e) => {
  //   if (isEmpty(waterSource) && e.latlng) {
  //     props.setPinLocation(e.latlng);
  //     const locatorMarker = L.latLng(e.latlng.lat, e.latlng.lng);
  //     setMarkerPosition(locatorMarker);
  //   }
  // };

  // Mini Component
  const createOrderUsageMapLegend = () => {
    return isMobile && orderUsageFilter && <OrderUsageMapLegend legend={orderUsageFilter.legend} />;
  };

  const createMapFilter = () => {
    return (
      !isMobile && (
        <div className="map-filter-legend-container">
          <div className="map-filter-container">
            {!isEmpty(mapFilters) &&
              mapFilters.map((filter, index) => (
                <MapFilter
                  onRadioOptionChange={value => onRadioOptionChange(value, filter.type)}
                  key={index}
                  climateRadioItem={rainfallRadioItem}
                  onToggle={type => onMapFilterToggle(type)}
                  {...filter}
                  text={filter.type === 'gauge' ? filter.text[waterSourceType] : filter.text}
                />
              ))}
          </div>
        </div>
      )
    );
  };

  return (
    <>
      {createOrderUsageMapLegend()}
      {BARWON_DARLING_ACTIVE_MGMT && <FlowClassMapLegend />}
      {waterQualityFilterEnabled && mapExpanded && (
        <div className={`${isEmpty(waterSource) ? 'state-overview-map-legend' : ''}`}>
          <WaterQualityLegend showGauges={!isEmpty(waterSource)} />
        </div>
      )}
      <Map
        id="base-map-container"
        onclick={() => {
          props.setHideStateSummary(false);
        }}
        center={mapCenterPoint}
        bounds={bounds}
        zoom={zoom}
        className="base-map-container"
        zoomControl={false}
        useFlyTo={true}
        onzoomend={() => onZoomEnd()}
        ref={mapRef}
        onfocus={() => expandMap(true)}
        onlocationfound={handleLocationFound}
        onmoveend={() => {
          bounds &&
            setVisibleNeighbouringWS(
              bounds.contains(mapRef.current.viewport.center) && zoom >= 8 && zoom <= 10,
            );
          props.setShowSpinner(false);
        }}
        attributionControl={false}
        minZoom={6}
        dragging={!isMobile}
        touchZoom={isMobile}
        gestureHandling={true}
        // onclick={(e) => onMapClicked(e)}
      >
        <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}" />
        {createMapFilter()}
        {mapExpanded &&
          (gaugeFilter?.selected || waterQualityFilter?.selected || storageFilter?.selected) &&
          !isEmpty(waterSource) && (
            <span
              className={`base-map-container-quality-legend ${
                waterQualityFilter?.selected &&
                'base-map-container-quality-legend-water-quality-selected'
              }`}
            >
              <DataQualityLegend />
            </span>
          )}
        <NeighbouringWaterSources visible={visibleNeighbouringWS} />
        <MarkerClusterGroup
          ref={markerClusterRef}
          showCoverageOnHover={false}
          maxClusterRadius={0}
          spiderfyDistanceMultiplier={1}
          iconCreateFunction={createClusterCustomIcon}
          spiderfyOnMaxZoom={true}
        >
          {!isEmpty(mapFilters) &&
            mapFilters.map((filter, index) =>
              filter.selected && filter.type === ORDER_USAGE.name ? (
                <RiverSection
                  key={index}
                  type={filter.type}
                  selected={filter.selected}
                  setShowSpinner={props.setShowSpinner}
                />
              ) : filter.selected && filter.type === FLOW_RATE.name ? (
                <>
                  <RiverSection
                    key={index}
                    type={filter.type}
                    selected={filter.selected}
                    setShowSpinner={props.setShowSpinner}
                    setThreshold={setFlowRateThreshold}
                  />
                  {!isEmpty(flowRateThreshold) && (
                    <FlowRateMapLegend threshold={flowRateThreshold} />
                  )}
                </>
              ) : filter.selected && filter.type === PUMP.name && BARWON_DARLING_ACTIVE_MGMT ? (
                <PumpFlowStatusMarker
                  key={index}
                  type={filter.type}
                  selected={filter.selected}
                  filter={filter}
                  setShowSpinner={props.setShowSpinner}
                />
              ) : filter.type === RAINFALL.name ? (
                <MapGridLayer
                  key={index}
                  selected={filter.selected}
                  mapRef={mapRef}
                  type={rainfallRadioItem}
                  setShowSpinner={props.setShowSpinner}
                />
              ) : filter.type === DROUGHT_STATUS.name ? (
                <DroughtStatusRiverSection
                  key={index}
                  setShowSpinner={props.setShowSpinner}
                  selected={filter.selected}
                />
              ) : filter.type === WATER_QUALITY.name ? (
                <WaterQualityMarker
                  key={index}
                  selected={filter.selected}
                  markerClusterRef={markerClusterRef}
                  mapRef={mapRef}
                  filter={filter}
                  setShowSpinner={props.setShowSpinner}
                />
              ) : filter.type === EVENTS.name ? (
                <EventsMarker
                  key={index}
                  selected={filter.selected}
                  markerClusterRef={markerClusterRef}
                  mapRef={mapRef}
                  filter={filter}
                  setShowSpinner={props.setShowSpinner}
                />
              ) : (
                <MapFilterMarker
                  key={index}
                  type={filter.type}
                  selected={filter.selected}
                  text={filter.text}
                  filter={filter}
                  markerClusterRef={markerClusterRef}
                  mapRef={mapRef}
                  setShowSpinner={props.setShowSpinner}
                />
              ),
            )}
          {isEmpty(waterSource) && (
            <>
              <StateOverviewPopups
                markerClusterRef={markerClusterRef}
                mapRef={mapRef}
                markerSelected={markerSelected}
                setShowSpinner={props.setShowSpinner}
                overviewType={props.overviewType}
                setHideStateSummary={props.setHideStateSummary}
              />
              <StateOverviewMapLegend
                overviewType={props.overviewType}
                rainfallDataType={rainfallRadioItem}
              />
            </>
          )}
        </MarkerClusterGroup>
        {!isEmpty(markerPosition) && <Marker position={markerPosition} icon={dropPinIcon} />}
        {!isEmpty(geoJsonData) &&
          showRiver &&
          (BARWON_DARLING_ACTIVE_MGMT && !backupUsed ? (
            <Pane name="flowClassMap" className="flow-class-map">
              <FlowClassMap data={geoJsonData} />
            </Pane>
          ) : (
            geoJsonData.map(
              (geoJson, index) =>
                !isEmpty(geoJson) && (
                  <Pane key={index} className="water-source-map">
                    <GeoJSON
                      color="#63cbe8"
                      weight={5}
                      data={geoJson}
                      renderer={L.svg({ padding: 1 })}
                    />
                  </Pane>
                ),
            )
          ))}
        <ZoomControl position="bottomright" />
      </Map>
    </>
  );
}

BaseMap.propTypes = {
  loadMapData: PropTypes.shape({
    reload: PropTypes.bool,
    count: PropTypes.number,
  }),
  setShowSpinner: PropTypes.func,
  locationGeometry: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  findLocation: PropTypes.bool,
  getWaterSourceList: PropTypes.func,
  setMapErrorMsg: PropTypes.func,
  setRadioItem: PropTypes.func,
  setMapFilters: PropTypes.func,
  pinLocation: PropTypes.object,
  overviewType: PropTypes.string,
};
