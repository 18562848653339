// Libraries
import React, { useState, useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';

// Styles
import './mapDropdownLocator.scss';

// Components
import Locator from '../locator/Locator';
import WaterSourceDropdown from '../dropDown/WaterSourceDropdown';

// Helpers
import { getActiveWaterSources } from '../../helpers/ProcessDataHelper';

export default function MapDropdownLocator(props) {
  const [nearbyWaterSources, setNearbyWaterSources] = useState(props.menuList);
  const inputEl = useRef(null);

  useEffect(() => {
    if (!isEmpty(props.menuList) && props.menuList.length > 1) {
      setNearbyWaterSources(props.menuList);
    }
  }, [props.menuList]);

  const getList = value => {
    // to check if water source found is implemented
    const resultList = getActiveWaterSources(value);
    setNearbyWaterSources(value);
    props.isWaterSourceFound(!isEmpty(resultList));
  };

  const getGeometry = value => {
    props.setLocationGeometry(value);
  };

  const onLocatorClick = value => {
    props.getCurrentLocation(value);
  };

  return (
    <div className="dropdown-locator-container">
      <div className="find-location-title">Find a location</div>
      <Locator
        dropdownList={getList}
        locationGeometry={getGeometry}
        onLocatorClick={onLocatorClick}
        inputRef={inputEl}
        setShowSpinner={props.setShowSpinner}
      />
      <WaterSourceDropdown
        inputRef={inputEl}
        nearbyWaterSources={nearbyWaterSources}
        isWaterSourceFound={props.isWaterSourceFound}
        setLocationGeometry={props.setLocationGeometry}
      />
    </div>
  );
}
