// Libraries
import { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import * as esri from 'esri-leaflet';

// Context
import { AppContext } from '../../contexts/AppContext';

// Constants
import constants from '../../constants/Constants.jsx';
const apiBaseURL = constants.BASE_URL;
const mapOpacity = 0.55;

const OBSERVED_RAINFALL = 'observed';
const OBSERVED_TEMPERATURE = 'observed-temp';
const OUTLOOK_RAINFALL = 'outlook';
const OUTLOOK_TEMPERATURE = 'outlook-temp';

export default function MapGridLayer({ mapRef, selected, type, setShowSpinner }) {
  const { waterSource } = useContext(AppContext);
  const [mapLayer, setMapLayer] = useState(null);
  const [activeType, setActiveType] = useState(null);

  useEffect(() => {
    const map = mapRef.current.leafletElement;
    setActiveType(type);
    if (selected && mapLayer) {
      if (activeType !== type && mapLayer[activeType]) {
        mapLayer[activeType].remove();
      }
      if (mapLayer[type]) {
        mapLayer[type].addTo(map);
        setShowSpinner(false);
      } else {
        switch (type) {
          case OBSERVED_RAINFALL:
          case OBSERVED_TEMPERATURE:
            addWmsLayer(map);
            break;
          case OUTLOOK_RAINFALL:
          case OUTLOOK_TEMPERATURE:
            addDynamicLayer(map);
            break;
          default:
            break;
        }
      }
    }
    if (selected && !mapLayer) {
      switch (type) {
        case OBSERVED_RAINFALL:
        case OBSERVED_TEMPERATURE:
          addWmsLayer(map);
          break;
        case OUTLOOK_RAINFALL:
        case OUTLOOK_TEMPERATURE:
          addDynamicLayer(map);
          break;
        default:
          break;
      }
    }
    if (!selected && mapLayer && mapLayer[type]) {
      mapLayer[type].remove();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, type]);

  useEffect(() => {
    if (mapLayer && mapLayer[type]) {
      mapLayer[type].remove();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterSource]);

  const addWmsLayer = map => {
    const url =
      type === OBSERVED_RAINFALL
        ? constants.OBSERVED_RAINFALL_SERVICES_API
        : constants.OBSERVED_TEMPERATURE_SERVICES_API;
    const wmsLayerProp = {
      [OBSERVED_RAINFALL]: {
        layers: 'precip_percentile',
        styles: 'boxfill/bluered',
        colorScaleRange: '0,100',
      },
      [OBSERVED_TEMPERATURE]: {
        layers: 'tmax',
        styles: 'boxfill/reeftempng_ssta',
        colorScaleRange: '-40,140',
      },
    };

    setShowSpinner(true);
    var wmsLayer = L.tileLayer
      .wms(apiBaseURL + url, {
        layers: wmsLayerProp[type].layers,
        format: 'image/png',
        transparent: true,
        styles: wmsLayerProp[type].styles,
        attribution: 'Bureau of Meteorology',
        opacity: mapOpacity,
        colorscalerange: wmsLayerProp[type].colorScaleRange,
        numcolorbands: 10,
      })
      .addTo(map);
    setShowSpinner(false);
    setMapLayer({ [type]: wmsLayer });
  };

  const addDynamicLayer = map => {
    setShowSpinner(true);
    const dynamicLayer = esri
      .dynamicMapLayer({
        url:
          type === OUTLOOK_RAINFALL
            ? constants.RAINFALL_OUTLOOK_MAP_SERVICE
            : constants.TEMPERATURE_OUTLOOK_MAP_SERVICE,
        layers: [1],
        attribution: 'Bureau of Meteorology',
        opacity: mapOpacity,
        useCors: false,
      })
      .addTo(map);
    setShowSpinner(false);
    setMapLayer({ [type]: dynamicLayer });
  };

  return null;
}

MapGridLayer.propTypes = {
  mapRef: PropTypes.object,
  selected: PropTypes.bool,
  type: PropTypes.string,
};
