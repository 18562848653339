// Library
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, groupBy } from 'lodash';
// Style
import './tradingMarket.scss';

// Context
import { MobileContext } from '../../../contexts/MobileContext';
import { AppContext } from '../../../contexts/AppContext';

// Components
import TradingCategory from './TradingCategory';
import Modal from '../../modal/Modal';
import Loader from '../../../components/loader/Loader';
import ParagraphSection from '../../paragraphSection/ParagraphSection';

// Helper
import { sortArrayByKeyAsc } from '../../../helpers/Utils';

// Assets
import InfoButton from '../../informationButton/InformationButton';
import tradeText from './tradingText.json';

// Constant
import constant from '../../../constants/Constants';
const { allocation } = tradeText;
const { MURRUMBIDGEE_REGULATED_RIVER_WATER_SOURCE } = constant;
const container = 'trading-market';
const tradingZonesWS = {
  11982: '13',
  12104: '14',
  14681: 'BR',
  11985: 'GV',
  11984: 'MV',
  16001: 'BD',
};

const TradingMarket = ({ title, intro, data, text, showSpinner, type }) => {
  const targetID = title.slice(0, title.indexOf(' '));
  const { isMedium } = useContext(MobileContext);
  const { waterSource } = useContext(AppContext);

  // Life Cycle
  useEffect(() => {
    data.map(item => {
      let resByTradZone = [];
      let tradingZones = [];
      const grouped = groupBy(item.resources, 'trading_zone');
      Object.keys(grouped).map(key => {
        tradingZones.push(key);
        resByTradZone.push({ [key]: grouped[key] });
      });
      if (!isEmpty(resByTradZone) && !isEmpty(tradingZones)) {
        item.resources = Object.values(resByTradZone[0])[0];
        resByTradZone.map((zoneItem, index) => {
          const tradingZone =
            Object.keys(zoneItem)[0] !== 'undefined'
              ? Object.keys(zoneItem)[0]
              : tradingZonesWS[waterSource.water_source_id]
              ? tradingZonesWS[waterSource.water_source_id]
              : waterSource.water_source_name
                  .replace(' Regulated River', '')
                  .replace(' Deep Groundwater', '')
                  .replace(' Shallow Groundwater', '')
                  .replace(' Groundwater', '')
                  .replace(' Unregulated', '');

          if (index !== 0) {
            data.push({
              zone: tradingZone,
              available_shares: item.available_shares,
              category_type: item.category_type,
              market_type: item.market_type,
              resources: Object.values(resByTradZone[index])[0],
            });
          } else {
            item.zone = tradingZone;
          }
        });
      }
    });
    // TODO: worth refactoring into using useRef for optimisation
    // eslint-disable-next-line react-hooks/exhaustive-deps
    data = sortArrayByKeyAsc(data, 'zone');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // Helper
  const isMurrumbidgeeAllocation = () => {
    return (
      allocation.title === title &&
      waterSource.water_source_id === MURRUMBIDGEE_REGULATED_RIVER_WATER_SOURCE
    );
  };

  // Component
  const modalMaker = () => {
    const { modal } = text;
    let modalText;
    if (isMurrumbidgeeAllocation()) {
      const { murrumbidgeeModal } = text;
      modalText = [...modal, ...murrumbidgeeModal];
    } else {
      modalText = modal;
    }

    return (
      <>
        {modalText.map((section, index) => {
          return (
            <ParagraphSection
              key={index}
              sectionTitle={section.paragraphTitle}
              sectionContent={section.paragraph}
            />
          );
        })}
      </>
    );
  };

  const marketContent = () => {
    return !isEmpty(data) ? (
      <>
        <TradingCategory header marketType={type} data={data[0]} />
        {data.map(dataItem => {
          return <TradingCategory key={dataItem.category_type} data={dataItem} />;
        })}
      </>
    ) : (
      <div className={`${container}-empty`}>{text.noData}</div>
    );
  };

  return (
    <div className={container}>
      <Modal id={`trading${targetID}`} title={title} body={modalMaker()} />
      <div className={`${container}-title`}>
        <div className={`${container}-title-text`}>{title}</div>
        <InfoButton targetDataId={`#trading${targetID}`} iconColor={`${container}-title-icon`} />
      </div>
      <div className={`${container}-intro`}>{intro}</div>
      {showSpinner ? (
        <Loader width={isMedium ? '5px' : '10px'} height={isMedium ? '20px' : '40px'} />
      ) : (
        marketContent()
      )}
    </div>
  );
};

export default TradingMarket;

TradingMarket.propTypes = {
  title: PropTypes.string,
  intro: PropTypes.string,
  text: PropTypes.object,
  showSpinner: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      category_type: PropTypes.string,
      market_type: PropTypes.string,
      resources: PropTypes.array,
    }),
  ),
  type: PropTypes.string,
};
