// Library
import React, { useContext } from 'react';

// Components
import BodyContainer from './BodyContainer';
import InfoIconAndModal from '../../components/informationButton/InfoIconAndModal';
import ResearchBody from '../../components/research/ResearchBody';

// Style
import './body.scss';

// Assests
import researchModal from '../../components/modal/modalText/ResearchTitleModalText.json';

// Context
import { AppContext } from '../../contexts/AppContext';

// Helper
import { findLastWord } from '../../helpers/Utils';

// Constants
import { research } from '../../abstract/IntroText.json';
const container = 'research-container';

const Research = () => {
  const { waterSource } = useContext(AppContext);
  const { water_source_name } = waterSource;
  const { title, intro } = research;
  const { modal } = researchModal;

  return (
    <BodyContainer
      pageTitle={
        <div className={`${container}-pageTop`}>
          <span>
            {`${title} ${findLastWord(water_source_name).notLast}`}
            &nbsp;
            <InfoIconAndModal
              modalId="researchTitleModal"
              modalContent={modal}
              lastWord={
                <span className={'page-body-title'}>
                  {findLastWord(water_source_name).last}
                  &nbsp;
                </span>
              }
            />
          </span>
        </div>
      }
      pageIntro={intro}
    >
      <ResearchBody />
    </BodyContainer>
  );
};

export default Research;
