// Libraries
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

// Context
import { MobileContext } from '../../contexts/MobileContext';

// Hooks
import useWindowSize from '../../hooks/WindowSizeHook';

//Components
import OnboardingTooltip from './OnboardingTooltip';

// Assets
import constants from '../../constants/Constants';

// Helpers
import {
  addOverlayLayer,
  createElement,
  setStyle,
  checkRight,
  getWinSize,
  getOffset,
  determineAutoPosition,
  exitOnboarding,
} from '../../helpers/OnboardingHelper';

// Styles
import './Onboarding.scss';

export default function Onboarding({ steps, onboardingIntroSkip }) {
  const [onboardingSteps, setOnboardingSteps] = useState();
  const [currentStep, setcurrentStep] = useState();
  const [onboardingTooltipStep, setOnboardingTooltipStep] = useState(0);
  const [showOnboarding, setShowOnboarding] = useState(true);
  const [lastShowElementTimer, setLastShowElementTimer] = useState();
  const { isMobile } = useContext(MobileContext);
  const size = useWindowSize();
  const navItems = constants.LINK_ITEMS;

  useEffect(() => {
    if (!onboardingIntroSkip) {
      if (lastShowElementTimer) {
        window.clearTimeout(lastShowElementTimer);
      }
      setLastShowElementTimer(
        window.setTimeout(function () {
          const stepsVisible = initializeSteps();
          setOnboardingSteps(stepsVisible);
          addOverlayLayer(document.body);
          setcurrentStep(0);
        }, 700),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentStep > -1) {
      nextStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  useEffect(() => {
    if (showOnboarding && steps && currentStep > -1) {
      const stepsVisible = initializeSteps();
      setOnboardingSteps(stepsVisible);
      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size.width]);

  const initializeSteps = () => {
    const stepsVisible = steps.filter(step => {
      const found = document.querySelector(step.element);
      const navItem = navItems.find(item => found && found.className.match(item.title));
      if (found && !(isMobile && navItem)) {
        return true;
      } else return false;
    });
    return stepsVisible;
  };
  const buttonClick = direction => {
    let stepToAddOrRest = 0;

    if (direction === 'backwards') {
      --stepToAddOrRest;
    } else {
      ++stepToAddOrRest;
    }
    if (onboardingSteps.length <= currentStep + stepToAddOrRest) {
      setShowOnboarding(false);
      exitOnboarding();
      return;
    }
    if (typeof currentStep === 'undefined') {
      setcurrentStep(0);
    } else {
      setcurrentStep(currentStep + stepToAddOrRest);
    }
  };

  /**
   * Go to specific step of onboarding
   */
  const goToStep = step => {
    setcurrentStep(step);
  };

  /**
   * Go to next step on onboarding
   */
  const nextStep = () => {
    let nextStep = onboardingSteps[currentStep];
    nextStep = document.querySelector(nextStep.element);

    if (nextStep) {
      showElement(nextStep);
    }
  };

  /**
   * Update the position of the helper layer on the screen
   */
  const setHelperLayerPosition = helperLayer => {
    if (helperLayer) {
      const currentElement = onboardingSteps[currentStep];
      let widthHeightPadding = 15;
      if (!currentElement) return;

      const domCurrentElement = document.querySelector(currentElement.element);
      if (!domCurrentElement) return;
      const elementPosition = getOffset(domCurrentElement);

      setStyle(helperLayer, {
        width: ''.concat(elementPosition.width + widthHeightPadding, 'px'),
        height: ''.concat(elementPosition.height + widthHeightPadding, 'px'),
        top: ''.concat(elementPosition.top - widthHeightPadding / 2, 'px'),
        left: ''.concat(elementPosition.left - widthHeightPadding / 2, 'px'),
      });
    }
  };

  /**
   * Render tooltip box in the page
   */
  const placeTooltip = (targetElement, tooltipLayer, arrowLayer) => {
    let currentStepObj,
      tooltipOffset,
      targetOffset,
      windowSize,
      currentTooltipPosition,
      tooltipLayerStyleLeft;

    setStyle(tooltipLayer, {
      width: null,
      height: null,
      top: null,
      left: null,
    });
    setStyle(arrowLayer, { display: 'inherit' });

    currentStepObj = onboardingSteps[currentStep];
    tooltipLayer.className = 'onboarding-tooltip';
    tooltipLayer.setAttribute('role', 'dialog');
    currentTooltipPosition = currentStepObj.position;

    if (currentTooltipPosition !== 'floating') {
      currentTooltipPosition = determineAutoPosition(
        targetElement,
        tooltipLayer,
        currentTooltipPosition,
      );
    }

    targetOffset = getOffset(targetElement);
    tooltipOffset = getOffset(tooltipLayer);
    windowSize = getWinSize();
    tooltipLayer.className += ' '.concat('onboarding-'.concat(currentTooltipPosition));

    switch (currentTooltipPosition) {
      case 'right':
        setStyle(tooltipLayer, {
          left: ''.concat(targetOffset.width + 40, 'px'),
        });
        if (targetOffset.top + tooltipOffset.height > windowSize.height) {
          arrowLayer.className = 'onboarding-arrow left-bottom';
          setStyle(tooltipLayer, {
            top: '-'.concat(tooltipOffset.height - targetOffset.height - 20, 'px'),
          });
        } else {
          if (targetOffset.height > tooltipOffset.height) {
            setStyle(tooltipLayer, { top: '+'.concat(40, 'px') });
          } else {
            setStyle(tooltipLayer, { top: '-'.concat(10, 'px') });
          }
          arrowLayer.className = 'onboarding-arrow left';
        }
        break;

      case 'left':
        setStyle(tooltipLayer, {
          left: '-'.concat(targetOffset.width + 350, 'px'),
        });
        if (targetOffset.top + tooltipOffset.height > windowSize.height) {
          arrowLayer.className = 'onboarding-arrow right-bottom';
          setStyle(tooltipLayer, {
            top: '-'.concat(tooltipOffset.height - targetOffset.height - 20, 'px'),
          });
        } else {
          if (targetOffset.height > tooltipOffset.height) {
            setStyle(tooltipLayer, { top: '+'.concat(40, 'px') });
          } else {
            setStyle(tooltipLayer, { top: '-'.concat(10, 'px') });
          }
          arrowLayer.className = 'onboarding-arrow right';
        }
        break;
      // Bottom going to follow the default behavior
      default:
        arrowLayer.className = 'onboarding-arrow top';
        tooltipLayerStyleLeft = 0;
        checkRight(targetOffset, tooltipLayerStyleLeft, tooltipOffset, windowSize, tooltipLayer);
        setStyle(tooltipLayer, {
          top: ''.concat(targetOffset.height + 30, 'px'),
        });
    }
  };

  /**
   * Update placement of the onboarding objects on the screen
   */
  const refresh = () => {
    let step = onboardingSteps[currentStep];
    // re-align tooltip and helper layers
    setHelperLayerPosition(document.querySelector('.onboarding-helperLayer'));
    setHelperLayerPosition(document.querySelector('.onboarding-tooltipReferenceLayer'));
    const currentDOMElement = document.querySelector(step.element);

    if (currentDOMElement && currentStep !== undefined && currentStep !== null) {
      const oldArrowLayer = document.querySelector('.onboarding-arrow');
      const oldTooltipContainer = document.querySelector('.onboarding-tooltip');
      step = document.querySelector(step.element);
      placeTooltip(step, oldTooltipContainer, oldArrowLayer);
    } else {
      setOnboardingTooltipStep(0);
      setcurrentStep(0);
    }
    return this;
  };

  /**
   * Show an element on the page
   */
  const showElement = targetElement => {
    let helperLayer = {};
    const oldHelperLayer = document.querySelector('.onboarding-helperLayer');
    const oldReferenceLayer = document.querySelector('.onboarding-tooltipReferenceLayer');
    const referenceLayer = document.querySelector('.onboarding-tooltipReferenceLayer');
    const arrowLayer = document.body.querySelector('.onboarding-arrow');
    let tooltiptextReference = document.body.querySelector('.onboarding-tooltiptext');

    const highlightClass = 'onboarding-helperLayer';

    if (oldHelperLayer !== null) {
      let oldTooltipContainer = oldReferenceLayer.querySelector('.onboarding-tooltip');
      setStyle(oldTooltipContainer, { opacity: '0', display: 'none' });
      setHelperLayerPosition(oldHelperLayer);
      setHelperLayerPosition(oldReferenceLayer);

      //Reset class to 'onboarding-tooltip' (getting rid of positioning classes)
      oldTooltipContainer.className = 'onboarding-tooltip';

      if (lastShowElementTimer) {
        window.clearTimeout(lastShowElementTimer);
      }
      setLastShowElementTimer(
        window.setTimeout(function () {
          setOnboardingTooltipStep(currentStep);
          setStyle(oldTooltipContainer, { opacity: '1', display: 'block' });
          placeTooltip(targetElement, oldTooltipContainer, arrowLayer);
          setStyle(tooltiptextReference, { opacity: '1' });
        }, 350),
      );
    } else {
      helperLayer = createElement('div', { className: highlightClass });
      setStyle(helperLayer, {
        'box-shadow':
          'rgba(226, 216, 216, 0) 0px 0px 1px 2px, rgba(95, 94, 94, 0.8) 0px 0px 0px 5000px',
      });
      //set new position to helper layer
      setHelperLayerPosition(helperLayer);
      setHelperLayerPosition(referenceLayer);
      document.body.appendChild(helperLayer);
      document.body.appendChild(referenceLayer);

      let tooltipLayer = document.body.querySelector('.onboarding-tooltip');
      setLastShowElementTimer(
        window.setTimeout(function () {
          setStyle(tooltipLayer, { opacity: '1', display: 'block' });
          placeTooltip(targetElement, tooltipLayer, arrowLayer);
          setStyle(tooltiptextReference, { opacity: '1' });
        }, 350),
      );
    }
  };

  return (
    <OnboardingTooltip
      steps={onboardingSteps}
      currentStep={onboardingTooltipStep}
      goToStep={goToStep}
      onStepClick={buttonClick}
      onSkipOnboarding={exitOnboarding}
      setShowOnboarding={setShowOnboarding}
    />
  );
}

Onboarding.propTypes = {
  steps: PropTypes.array,
  onboardingIntroSkip: PropTypes.string,
};
