import React from 'react';
import PropTypes from 'prop-types';

const LegendItemSVG = ({ strokeDasharrayProperty, pathProperty, color }) => {
  return (
    <svg className="recharts-surface" width="30" height="30" viewBox="0 0 32 32" version="1.1">
      <path
        strokeWidth="4"
        strokeDasharray={strokeDasharrayProperty ? '4 4' : '0'}
        fill="none"
        stroke={color}
        className="recharts-legend-icon"
        d={
          pathProperty
            ? pathProperty
            : `M0,16h10.666666666666666
            A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16
            H32M21.333333333333332,16
            A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16`
        }
      ></path>
    </svg>
  );
};

export default LegendItemSVG;

/* Props */
LegendItemSVG.propTypes = {
  strokeDasharrayProperty: PropTypes.bool,
  pathProperty: PropTypes.string,
  color: PropTypes.string,
};
