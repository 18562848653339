import React, { useState, createContext } from 'react';

export const MapFilterContext = createContext();

export default function MapFilterContextProvider(props) {
  const [filterSelected, setFilterSelected] = useState();
  const [filterProps, setFilterProps] = useState();

  function toggleMapFilter(filter, propObj = {}) {
    setFilterSelected(filter);
    setFilterProps(propObj);
  }

  return (
    <MapFilterContext.Provider value={{ filterSelected, toggleMapFilter, filterProps }}>
      {props.children}
    </MapFilterContext.Provider>
  );
}
