// Library
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// Styles
import './generalLink.scss';

//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareArrowUpRight } from '@fortawesome/free-solid-svg-icons';

// Context
import { MobileContext } from '../../contexts/MobileContext';

const container = 'general-link';

export default function GeneralLink({ title, link, onLinkClick, targetId = '', newPage = true }) {
  const { isMobile } = useContext(MobileContext);

  return (
    <a
      className={container}
      data-toggle={isMobile && targetId ? 'modal' : ''}
      data-target={isMobile && targetId ? targetId : ''}
      onClick={() => onLinkClick(link)}
      href={link}
      target={newPage ? '_blank' : ''}
      rel="noopener noreferrer"
    >
      {title}
      {!isMobile && <FontAwesomeIcon icon={faSquareArrowUpRight} className={`${container}-icon`} />}
    </a>
  );
}

GeneralLink.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  onLinkClick: PropTypes.func,
  targetId: PropTypes.string,
};
