// Libraries
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// Context
import { DownloadContext } from '../../../../../../contexts/DownloadContext';

// Component
import CheckboxListDropdown from '../../../../../generalComponents/checkboxBtnListDropdown/CheckbostBtnListDropdown';

const StationSelectSuggestion = ({
  stationList,
  stationSelectionLimit = Infinity,
  onSelect,
  onClose,
  onClear,
  isOpen,
}) => {
  const { selectedStations } = useContext(DownloadContext);
  return (
    <CheckboxListDropdown
      options={stationList}
      onChange={onSelect}
      selectedOptions={selectedStations}
      limit={stationSelectionLimit}
      isOpen={isOpen}
      onClear={onClear}
      onClose={onClose}
    />
  );
};

export default StationSelectSuggestion;

StationSelectSuggestion.propTypes = {
  /** An array of objects representing the stations */
  stationList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  /** An limit on how many stations can be selected */
  stationSelectionLimit: PropTypes.number,
  /** A function to set the selected stations */
  onSelect: PropTypes.func.isRequired,
  /** Boolean indicating whether the dropdown is open */
  isOpen: PropTypes.bool,
  /** Function called when "Clear All" button is clicked */
  onClear: PropTypes.func,
  /** Function called when the dropdown is closed */
  onClose: PropTypes.func,
};
