import React, { useContext } from 'react';

// Components
import UsageBody from '../../components/usage/UsageBody';
import BodyContainer from './BodyContainer';

// Style
import './body.scss';

// Assests
import gsModalText from '../../components/modal/modalText/GreaterSydneyModalText.json';

// Context
import { AppContext } from '../../contexts/AppContext';

// Helpers
import { findLastWord } from '../../helpers/Utils';

// Constants
import { usage } from '../../abstract/IntroText.json';

const Rainfall = () => {
  const { waterSource } = useContext(AppContext);
  const { water_source_name } = waterSource;
  const { title, intro } = usage;
  gsModalText.modal.title = `Understanding ${water_source_name}`;

  return (
    <BodyContainer
      pageTitle={`${title} ${findLastWord(water_source_name).notLast} `}
      pageIntro={intro}
    >
      <UsageBody />
    </BodyContainer>
  );
};

export default Rainfall;
