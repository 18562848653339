// Library
import React, { useState, useContext, useEffect } from 'react';
import { isEmpty, isObject } from 'lodash';

// Styles
import './siteInfo.scss';

// Component
import Loader from '../../../../loader/Loader';
import ResultContent from '../ResultContent';

// Context
import { DownloadContext } from '../../../../../contexts/DownloadContext';

// Helper
import { getSiteMetadata } from '../../../../../helpers/WaterDataApiHelper';

// Constant
import waterDataAPIConstants from '../../../../../constants/WaterDataAPIConstants';
import { rowItems } from './SiteInfoConstants';
const component = 'site-info';

const SiteInfo = () => {
  const { downloadType, selectedStations, stationInfoType } = useContext(DownloadContext);
  const [metaData, setMetaData] = useState([]);

  useEffect(() => {
    (async () => {
      const stationInfoArray = await getSiteMetadata(
        selectedStations.map(item => item.id).join(','),
        waterDataAPIConstants.SITE_TYPE[downloadType],
      );
      setMetaData(stationInfoArray);
    })();

    return () => {
      setMetaData([]);
    };
  }, [downloadType, selectedStations, stationInfoType]);

  const genRowItem = (rowItem, metaDataItem) => {
    const formatter = rowItem.condition;
    const value = metaDataItem[rowItem.datakey];
    const isEmptyValue = isObject(value) ? isEmpty(value) : !value;
    const rowValue = !isEmptyValue ? (formatter ? formatter(value) : value) : '-';
    return (
      <tr key={rowItem.rowName}>
        <th>{rowItem.rowName}:</th>
        <td>{rowValue}</td>
      </tr>
    );
  };

  return (
    <div className={`${component}`}>
      {isEmpty(metaData) ? (
        <Loader />
      ) : (
        <>
          {metaData.map(metaDataItem => (
            <ResultContent key={metaDataItem.siteId}>
              <p className={`${component}-title`}> Details - {metaDataItem.siteId}</p>
              <table>
                <tbody>
                  {rowItems.map(rowItem => {
                    return genRowItem(rowItem, metaDataItem);
                  })}
                </tbody>
              </table>
            </ResultContent>
          ))}
        </>
      )}
    </div>
  );
};

export default SiteInfo;
