import { startCase } from 'lodash';
export const rowItems = [
  {
    rowName: 'Site ID',
    datakey: 'siteId',
  },
  {
    rowName: 'Site name',
    datakey: 'siteName',
  },
  {
    rowName: 'Site commence',
    datakey: 'siteCommence',
  },
  {
    rowName: 'Site cease',
    datakey: 'siteCease',
  },
  {
    rowName: 'Zone',
    datakey: 'zone',
  },
  {
    rowName: 'IPART regulated',
    datakey: 'siteId',
    condition: regulated => {
      return regulated === 1 ? 'REG' : 'UNREG';
    },
  },
  {
    rowName: 'Current site hydrometric',
    datakey: 'regulated',
    condition: active => {
      return active === 1 ? 'Active' : 'Not active';
    },
  },
  {
    rowName: 'Latitude',
    datakey: 'latitude',
  },
  {
    rowName: 'Longitude',
    datakey: 'longitude',
  },
  {
    rowName: 'Lat/long datum',
    datakey: 'latLongDatum',
  },
  {
    rowName: 'Elevation',
    datakey: 'elevation',
  },
  {
    rowName: 'Zero gauge',
    datakey: 'zerogauge',
  },
  {
    rowName: 'Datum',
    datakey: 'datum',
  },
  {
    rowName: 'Control',
    datakey: 'control',
  },
  {
    rowName: 'CTF level',
    datakey: 'ctfLevel',
  },
  {
    rowName: 'Variables monitored',
    datakey: 'variablesMonitored',
    condition: variablesMonitored => {
      return Object.values(variablesMonitored)
        .map(item => startCase(item).trim())
        .join(`, `);
    },
  },
  {
    rowName: 'Water source',
    datakey: 'waterSourceName',
    condition: waterSourceName => Object.values(waterSourceName).join(', '),
  },
];
