// Library
import React from 'react';
import PropTypes from 'prop-types';

// Style
import './storageOverview.scss';

// Components
import DataSummaryBar from '../../dataSummary/DataSummaryBar';
import StateOverviewTable from './StateOverviewTable';

// Constants
const container = 'state-overview-storage';

const StorageOverview = ({ data }) => {
  const text = {
    total_capacity: 'Maximum capacity',
    total_volume: 'Current volume',
  };

  const columns = [
    {
      type: 'rowHeader',
      text: 'rowHeader',
      hide: true,
    },
    {
      type: 'volume',
      text: 'Volume (GL)',
    },
  ];

  const rows = [
    Object.keys(text).map(key => {
      return (
        <span
          key="capacity"
          className={`${container}-header${text[key] === text.total_volume ? '-storage' : ''}`}
        >
          {text[key]}
        </span>
      );
    }),
    Object.keys(text).map(key => {
      return data[key] / 1000;
    }),
  ];

  return (
    <div className={container}>
      <StateOverviewTable columns={columns} rows={rows} />
      <DataSummaryBar
        bars={[
          {
            type: text.total_volume,
            value: data.total_volume,
            color: '#0054a6',
          },
        ]}
        maximum={data.total_capacity}
      />
    </div>
  );
};

export default StorageOverview;

StorageOverview.propTypes = {
  data: PropTypes.shape({
    total_capacity: PropTypes.number,
    total_volume: PropTypes.number,
  }),
};
