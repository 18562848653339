import React from 'react';

import './title.scss';

export default function Title(props) {
  const { customStyle, title, subTitle } = props;
  return (
    <div>
      <div className={`page-body-title ${customStyle}`}>{title}</div>
      {subTitle && <div className="title-sub-title">{subTitle}</div>}
    </div>
  );
}
