import React from 'react';
import PropTypes from 'prop-types';

import config from '../../configs/featureToggleConfig.json';

export const FeatureToggle = ({ children, feature }) => {
  const enabled = config[feature] ? config[feature].active : true;

  const toggledChildren = React.Children.map(children, child =>
    React.cloneElement(child, {
      enabled,
    }),
  );

  return <React.Fragment>{toggledChildren}</React.Fragment>;
};

export const On = ({ children, enabled }) => <React.Fragment>{enabled && children}</React.Fragment>;

export const Off = ({ children, enabled }) => (
  <React.Fragment>{!enabled && children}</React.Fragment>
);

FeatureToggle.propTypes = {
  children: PropTypes.any,
  feature: PropTypes.string,
};

On.propTypes = {
  children: PropTypes.any,
  enabled: PropTypes.bool,
};

Off.propTypes = {
  children: PropTypes.any,
  enabled: PropTypes.bool,
};
