// Library
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { AreaChart, Area, Tooltip, XAxis, Legend, YAxis } from 'recharts';

// Styles
import './riverLateralHydrograph.scss';

// Context
import { AppContext } from '../../contexts/AppContext';
import { MobileContext } from '../../contexts/MobileContext';

// Components
import ChartResponsiveContainer from '../chartProperties/ChartResponsiveContainer';
import ChartLegend from '../chartProperties/ChartLegend';
import Loader from '../loader/Loader';

// Helpers
import {
  getRiverVolumeWaterSource,
  getStationForWaterSourceByType,
} from '../../helpers/ApiHelper.jsx';
import { formatNumberDecimals } from '../../helpers/Utils';
import { formatDate } from '../../helpers/TimeUtils';
import { getMultiSiteData } from '../../helpers/WaterDataApiHelper';

import chainage from './chainage.json';
const container = 'lateral-hydrograph';

export default function RiverLateralHydrograph({ duration }) {
  const isLatest = duration === 'latest';
  const { waterSource } = useContext(AppContext);
  const { isMobile, isMedium } = useContext(MobileContext);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const AREA_CHARTS = [
    {
      name: 'Total volume',
      dataKey: 'volume',
      color: '#386ba8',
    },
    {
      name: 'Active environmental water',
      dataKey: 'env_volume',
      color: '#ccdf39',
    },
  ];
  const QUERY_PERIOD = {
    latest: '7 days',
    30: '30 days',
    365: '1 years',
  };

  // Life Cycle
  useEffect(() => {
    const waterSourceID = waterSource.water_source_id;
    (async () => {
      setIsLoading(true);
      const sites = await getStationForWaterSourceByType(waterSourceID, 'gauge', true);
      if (!isEmpty(sites)) {
        const siteIds = sites.map(site => site.id);
        const timeSpan = QUERY_PERIOD[duration];
        const volume = await getMultiSiteData(siteIds, ['Volume'], 'daily', timeSpan, true);
        const envVolume = await getRiverVolumeWaterSource(waterSourceID, duration);

        const result = sites.map(siteItem => {
          const siteId = siteItem.station_id;
          const match = chainage[waterSourceID].find(value => value.station_id === siteId);
          const matchVolume = volume.find(item => item.id === siteId);
          const matchEnvVolume = envVolume.find(item => item.station_id === siteId);
          const matchData = {
            volume:
              duration === 'latest'
                ? matchVolume?.resources[0].volume
                : matchVolume?.resources.map(item => item.volume).reduce((sum, num) => sum + num),
            envVolume: matchEnvVolume?.env_volume,
          };
          return {
            ...siteItem,
            chainage: match?.chainage,
            volume: matchData?.volume,
            env_volume: matchData?.envVolume,
            date: matchVolume?.resources[0].date,
          };
        });
        setData(result.sort((a, b) => (a.chainage >= b.chainage ? 1 : -1)));
        setIsLoading(false);
      }
    })();
  }, [waterSource, duration]);

  const renderCusomizedLegend = e => {
    return <ChartLegend payload={e.payload} legendType="square" />;
  };

  const labelFormatter = (value, isTooltip) => {
    const matched = data && data.find(item => item.chainage === value);
    return (
      matched &&
      `${matched.station_display_name} ${
        isLatest && isTooltip ? `(Updated: ${formatDate(matched.date, null, 'DD MMMM YYYY')})` : ``
      }`
    );
  };

  return (
    <div className={container}>
      {isLoading ? (
        <Loader />
      ) : (
        !isEmpty(data) && (
          <ChartResponsiveContainer
            customHeight={{ desktop: 1000, mobile: 750 }}
            customWidth={{ desktop: '90%', tablet: '90%', mobile: '100%' }}
          >
            <AreaChart
              width={200}
              data={data}
              margin={{
                top: isMobile ? 100 : 120,
                bottom: isMobile ? 30 : 50,
                left: isMobile ? 10 : isMedium ? 0 : 60,
              }}
              layout="vertical"
            >
              <XAxis
                label={{
                  value: `Volume (${isLatest ? 'ML' : 'GL'})`,
                  dy: isMobile ? -30 : -50,
                }}
                type="number"
                dataKey={'volume'}
                domain={[0, 'auto']}
                tickFormatter={v =>
                  isLatest
                    ? formatNumberDecimals(Number(v))
                    : v / 1000 <= 1
                    ? formatNumberDecimals(Math.round(v) / 1000)
                    : formatNumberDecimals(Math.round(v / 1000))
                }
                scale="linear"
                orientation="top"
                style={{ fontSize: isMobile ? '0.8rem' : '1rem' }}
              />
              <YAxis
                label={isMobile ? '' : { value: 'Gauges', angle: -90, dx: -70 }}
                style={{ fontSize: isMobile ? '0.8rem' : '1rem' }}
                width={isMobile ? 100 : 200}
                dx={-20}
                dataKey={'chainage'}
                ticks={data.map(item => item.chainage)}
                interval={0}
                tickCount={10}
                tickFormatter={v => labelFormatter(v, false)}
              />
              <Tooltip
                itemStyle={{ paddingTop: '0px', paddingBottom: '0px' }}
                formatter={(value, name) => [
                  isLatest
                    ? formatNumberDecimals(Number(value)) + ' ML'
                    : formatNumberDecimals(Number(value) / 1000) + ' GL',
                  name,
                ]}
                labelFormatter={v => labelFormatter(v, true)}
              />
              <Legend
                wrapperStyle={{
                  top: isMobile ? 0 : 20,
                  left: isMobile ? 20 : isMedium ? 190 : 250,
                }}
                align="left"
                content={renderCusomizedLegend}
              />
              {AREA_CHARTS.map((chart, index) => (
                <Area
                  key={index}
                  type="monotone"
                  dataKey={chart.dataKey}
                  name={chart.name}
                  stroke={chart.color}
                  fill={chart.color}
                  fillOpacity={1}
                  strokeWidth={0}
                  isAnimationActive={false}
                />
              ))}
            </AreaChart>
          </ChartResponsiveContainer>
        )
      )}
    </div>
  );
}

RiverLateralHydrograph.propTypes = {
  duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
