// Library
import React, { useEffect, useState, useContext } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, Tooltip } from 'recharts';

// Style
import './interValleyTransfer.scss';

// Component
import Modal from '../../modal/Modal';
import ParagraphSection from '../../paragraphSection/ParagraphSection';
import InfoButton from '../../informationButton/InformationButton';
import ChartContainer from '../../chartProperties/ChartContainer';

// Helpers
import { getTradeIvt } from '../../../helpers/ApiHelper';
import { formatNumberDecimals } from '../../../helpers/Utils';
import { getDatesByPeriod, formatDate } from '../../../helpers/TimeUtils';

// Context
import { AppContext } from '../../../contexts/AppContext';
import { MobileContext } from '../../../contexts/MobileContext';

// Assets
import text from './tradingText.json';

// Constant
const container = 'inter-valley-transfer';
const DEST_WS = {
  11982: 11904,
  11904: 11982,
};

const InterValleyTransfer = () => {
  const { waterSource } = useContext(AppContext);
  const { isMobile } = useContext(MobileContext);
  const destWaterSource = DEST_WS[waterSource.water_source_id];
  const { ivt } = text;
  const [data, setData] = useState({});
  const [chartData, setChartData] = useState([]);

  // Life Cycle
  useEffect(() => {
    let unmounted = false;
    (async () => {
      await getTradeIvt(
        waterSource.water_source_id,
        function (result) {
          if (!unmounted) {
            const selected = result.find(item => item.dest_water_source_id === destWaterSource);
            setData(selected);
          }
        },
        'latest',
      );
      await getTradeIvt(
        waterSource.water_source_id,
        function (result) {
          if (!unmounted) {
            setChartData(result);
          }
        },
        null,
        getDatesByPeriod(-14),
        getDatesByPeriod(-1),
      );
    })();
    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterSource.water_source_id]);

  const status = [
    {
      label: 'Murrumbidgee to Murray',
      value: formatNumberDecimals(data.trade_out_value / 1000),
      status: data.trade_out_open ? 'open' : 'closed',
      statusText: 'out of the',
    },
    {
      label: 'Murray to Murrumbidgee',
      value: formatNumberDecimals(data.trade_in_value / 1000),
      status: data.trade_in_open ? 'open' : 'closed',
      statusText: 'into',
    },
  ];

  // Component
  const modalMaker = modal => {
    return (
      <>
        {modal.map((section, index) => {
          return (
            <ParagraphSection
              key={index}
              sectionTitle={section.paragraphTitle}
              sectionContent={section.paragraph}
            />
          );
        })}
      </>
    );
  };

  const statusItem = (item, index) => {
    return (
      <div className={`${container}-content-item`} key={index}>
        <div className={`${container}-status`}>
          <div className={`${container}-status-header`}>{item.label}</div>
          <div className={`${container}-status-value ${item.status}`}>{item.status}</div>
        </div>
        <div className={`${container}-text`}>
          There is capacity for {item.value}
          GL to be transferred {item.statusText} Murrumbidgee valley.
        </div>
      </div>
    );
  };

  return (
    !isEmpty(data) && (
      <div className={`${container}`}>
        <Modal id={`interValleyTransfer`} title={ivt.modalTitle} body={modalMaker(ivt.modal)} />
        <Modal
          id={`ivtBalance`}
          title={ivt.ivtBalance.modalTitle}
          body={modalMaker(ivt.ivtBalance.modal)}
        />
        <div className={`${container}-title`}>
          <div className={`${container}-title-text`}>{ivt.title}</div>
          <InfoButton targetDataId={`#interValleyTransfer`} iconColor={`${container}-title-icon`} />
        </div>
        <div className={`${container}-intro`}>{ivt.intro}</div>
        <div className={`${container}-content`}>
          <div className={`${container}-content-item`}>
            <div className={`${container}-volume`}>
              <div className={`${container}-volume-label`}>
                IVT Balance
                <InfoButton targetDataId={`#ivtBalance`} iconColor={`${container}-volume-icon`} />
              </div>
              <div className={`${container}-volume-value`}>
                {formatNumberDecimals(data.trade_in_value / 1000, false, true)}
                &nbsp;
                <div className={`${container}-volume-unit`}>GL</div>
              </div>
            </div>
            <div className={`${container}-date`}>
              Updated {moment(data.value_date).format('DD MMM YYYY h:mm a')}
            </div>
          </div>
          {status.map((item, index) => statusItem(item, index))}
        </div>
        <ChartContainer
          pageTitle="IVT Account Balance graph"
          graphIntro="This graph shows how the Murray Murrumbidgee inter-valley transfer account balance has changed over the last 2 weeks."
        >
          <ResponsiveContainer minHeight={350} width={'100%'}>
            <AreaChart
              className="composed-chart"
              width={100}
              data={chartData}
              margin={{
                left: isMobile ? 0 : 20,
                bottom: isMobile ? 30 : 50,
                right: isMobile ? 20 : 30,
              }}
            >
              <XAxis
                dataKey="value_date"
                tickFormatter={v => formatDate(v, null, 'DD MMM')}
                type="category"
                dy={25}
                dx={-10}
                tick={{ fill: 'black' }}
                angle={-35}
                interval={0}
              />
              <YAxis
                interval="preserveStartEnd"
                label={{
                  value: 'Balance (GL)',
                  angle: -90,
                  position: 'left',
                  dx: isMobile ? 15 : 0,
                  dy: -50,
                }}
                ticks={[0, 50000, 100000]}
                tickFormatter={v => formatNumberDecimals(v / 1000)}
                type="number"
                tick={{ fill: 'black' }}
              />
              <Tooltip
                labelFormatter={v => formatDate(v, null, 'YYYY-MM-DD')}
                formatter={(value, name) => [
                  formatNumberDecimals(Number(value / 1000)) + 'GL',
                  name,
                ]}
              />
              <Area
                type="monotone"
                fillOpacity={1}
                name="Balance"
                dataKey="trade_in_value"
                fill="#cccccc"
                stroke="#cccccc"
              />
            </AreaChart>
          </ResponsiveContainer>
        </ChartContainer>
      </div>
    )
  );
};

export default InterValleyTransfer;
