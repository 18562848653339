// Library
import React from 'react';
import PropTypes from 'prop-types';
// Asset
import CancelSVG from '../../../image/icons/CancelSVG';

// Style
import './chip.scss';

// Constant
const component = 'chip';

/**
 * Chip component to display a station or other information with an optional delete button
 * @param {string} displayName - The text to display in the chip
 * @param {boolean} isDeletable - Whether or not the chip has a delete button
 * @param {function} onDelete - Callback function to remove the chip when the delete button is clicked
 * @param {string} customStyles - Optional custom CSS class name to add to the chip container
 */
export const Chip = ({ displayName, isDeletable, onDelete, customStyles }) => {
  return (
    <div className={`${component} ${customStyles}`}>
      <p className={`${component}-container`}>
        {displayName}
        {isDeletable && (
          <CancelSVG
            customClass={`${component}-delete-btn`}
            onBtnClick={e => {
              onDelete(displayName, e);
            }}
          />
        )}
      </p>
    </div>
  );
};

Chip.propTypes = {
  displayName: PropTypes.string.isRequired,
  isDeletable: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  customStyles: PropTypes.string,
};
