// Libraries
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Marker, Popup } from 'react-leaflet';
import PropTypes from 'prop-types';
import L from 'leaflet';

// Context
import { AppContext } from '../../contexts/AppContext';
import { MobileContext } from '../../contexts/MobileContext';
import { MapContext } from '../../contexts/MapContext';

// Styles
import './MapPopup.scss';

// Components
import MonitoringBorePopup from './MonitoringBorePopup';
import StationInfoPopup from './StationInfoPopup';
import RiverGaugePopup from './RiverGaugePopup';
import DamStoragePopup from './DamStoragePopup';
import PumpPopup from './PumpPopup';
import GeneralPopup from './GeneralPopup';
import Modal from '../modal/Modal';
import WaterQualityPopup from './WaterQualityPopup';
import GaugeAlertModalBody from '../alert/GaugeAlertModalBody';
import AlertResponse from '../alert/AlertResponse';

// Assets
import pumpIcon from '../../image/icons/mapMarker/pump.png';
import maintenanceIcon from '../../image/icons/mapMarker/maintenance.png';
import eventsIcon from '../../image/icons/mapMarker/events.png';
import constraintsIcon from '../../image/icons/mapMarker/constraints.png';
import damStorageIcon from '../../image/icons/mapMarker/dam.png';
import riverGaugeIcon from '../../image/icons/mapMarker/gauge.svg';
import environmentIcon from '../../image/icons/mapMarker/environment.png';
import boreIcon from '../../image/icons/mapMarker/bore.png';
import dischargeSiteIcon from '../../image/icons/mapMarker/discharge-site.png';

// Helpers
import { isWaterSourceREG } from '../../helpers/Utils';
import { generateMarkerIcon } from '../../helpers/MapServiceApiHelper';
import { useForceUpdate } from '../../helpers/CustomHooks';

// Constant
import constants from '../../constants/Constants.jsx';
import mapFilterConstant from '../../constants/MapFilterConstants';
import alertResponseText from '../alert/AlertResponseText.json';
import { MiniAppContext } from '../../contexts/MiniAppContext';

const { WEIR, BORE, GAUGE, DAM, OUTAGE, RECREATION, CONSTRAINTS, ENVIRONMENT, PUMP } =
  mapFilterConstant.MAP_POPUP_ITEMS;

export default function MapPopup({
  openPopup,
  markerType,
  data,
  type,
  mapRef,
  markerClusterRef,
  content,
  config,
  contentStyle,
  title,
  custIcon,
  position,
  setHideStateSummary,
}) {
  const markerRef = useRef(null);
  const { waterSource } = useContext(AppContext);
  const { isMobile } = useContext(MobileContext);
  const { station } = useContext(MiniAppContext);
  const { toggleMarker } = useContext(MapContext);
  const [timestamp, forceUpdate] = useForceUpdate();
  const [showManageAlertResponse, setShowManageAlertResponse] = useState(false);
  const [responseType, setResponseType] = useState();

  const isDischargeSite = data?.station_name?.split(' ')[0] === 'HRSTS';

  const stationSwap = dataType => {
    switch (markerType) {
      case BORE.name:
        return {
          name: BORE.label,
          element: (
            <MonitoringBorePopup
              data={data}
              timestamp={timestamp}
              markerRef={markerRef}
              openPopup={openPopup}
            />
          ),
        }[dataType];
      case WEIR.name:
        return {
          name: WEIR.label,
          element: (
            <RiverGaugePopup
              data={data}
              type={WEIR.name}
              timestamp={timestamp}
              markerRef={markerRef}
              openPopup={openPopup}
            />
          ),
        }[dataType];
      default:
        return {
          name: GAUGE.name,
          element: (
            <RiverGaugePopup
              data={data}
              type={GAUGE.name}
              timestamp={timestamp}
              markerRef={markerRef}
              openPopup={openPopup}
            />
          ),
        }[dataType];
    }
  };

  const mapFilterProps = {
    'monitoring bore': {
      name: BORE.label,
      icon: 'leaflet/dist/images/marker-icon.png',
    },
    outage: { name: OUTAGE.label, icon: maintenanceIcon },
    recreation: { name: RECREATION.label, icon: eventsIcon },
    constraints: { name: CONSTRAINTS.label, icon: constraintsIcon },
    dam: {
      name: markerType === WEIR.name ? WEIR.label : DAM.label,
      icon: [damStorageIcon, damStorageIcon],
    },
    gauge: {
      name: stationSwap('name'),
      icon: [
        isDischargeSite ? dischargeSiteIcon : riverGaugeIcon,
        waterSource && (isWaterSourceREG(waterSource) ? riverGaugeIcon : boreIcon),
      ],
    },
    environment: {
      name: ENVIRONMENT.label,
      icon: [environmentIcon, environmentIcon],
    },
    pump: { name: PUMP.label, icon: [pumpIcon, pumpIcon] },
    algal: { name: title, icon: custIcon },
    dissolved_oxygen: { name: title, icon: custIcon },
  };

  const iconUrl = type && mapFilterProps[type] ? mapFilterProps[type].icon[0] : '';
  const sizeConfig = isMobile ? constants.MAP_MARKER_SIZE_MOBILE : constants.MAP_MARKER_SIZE;
  const icon = generateMarkerIcon(
    iconUrl,
    sizeConfig,
    `${isDischargeSite ? 'map-marker-discharge-site' : ''} map-marker-base`,
  );

  useEffect(() => {
    if (openPopup) {
      mapRef.current.leafletElement.once('zoomend', function () {
        let layer = null;
        if (
          mapRef &&
          mapRef.current &&
          !mapRef.current?.leafletElement?.hasLayer(markerRef.current.leafletElement)
        ) {
          const markerClusterRefParent = markerClusterRef.current.leafletElement.getVisibleParent(
            markerRef.current.leafletElement,
          );
          mapRef.current.leafletElement.eachLayer(function (layerItem) {
            if (layerItem instanceof L.Marker) {
              if (
                markerClusterRefParent &&
                layerItem._leaflet_id === markerClusterRefParent._leaflet_id
              ) {
                layer = layerItem;
              }
            }
          });
        }

        setTimeout(function () {
          layer && layer.spiderfy();
          if (!markerRef.current.leafletElement.isPopupOpen()) {
            layer && layer.spiderfy();
            markerRef.current.leafletElement.openPopup();
          }
          markerRef.current.leafletElement.openPopup();
        }, 300);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openPopup]);

  const popupContentBody = {
    'monitoring bore': (
      <div className="map-popup-body">
        <MonitoringBorePopup data={data} timestamp={timestamp} openPopup={openPopup} />
      </div>
    ),
    outage: <StationInfoPopup data={data} />,
    recreation: <StationInfoPopup data={data} />,
    constraints: <StationInfoPopup data={data} />,
    dam:
      markerType === WEIR.name ? (
        <RiverGaugePopup
          data={data}
          type={WEIR.name}
          timestamp={timestamp}
          markerRef={markerRef}
          openPopup={openPopup}
        />
      ) : (
        <DamStoragePopup
          data={data}
          type={DAM.name}
          timestamp={timestamp}
          markerRef={markerRef}
          openPopup={openPopup}
        />
      ),
    gauge: stationSwap('element'),
    environment: <StationInfoPopup data={data} type={type} />,
    pump: <PumpPopup data={data} />,
    overview: <GeneralPopup data={data} content={content} config={config} />,
    algal: <WaterQualityPopup data={data} type={type} openPopup={openPopup} />,
    'dissolved-oxygen': (
      <WaterQualityPopup data={data} type={type} timestamp={timestamp} openPopup={openPopup} />
    ),
  }[type];

  const renderManageAlertResponse = responseType => {
    setShowManageAlertResponse(true);
    setResponseType(responseType);
  };

  return (
    <>
      <div
        onClick={() => {
          setTimeout(function () {
            showManageAlertResponse === true && setShowManageAlertResponse(false);
          }, 500);
        }}
      >
        {data && (
          <Modal
            id={`gauge-alert-modal-${data.station_id}`}
            body={
              <>
                <AlertResponse
                  show={showManageAlertResponse}
                  responseTitleText={alertResponseText.station[responseType]}
                />
                {!showManageAlertResponse && openPopup && (
                  <GaugeAlertModalBody
                    stationId={data.station_id}
                    stationName={data.station_name}
                    setManageAlertResponse={renderManageAlertResponse}
                    stationInfo={station}
                  />
                )}
              </>
            }
            title={`${!showManageAlertResponse ? 'Set alert' : ''}`}
          />
        )}
      </div>
      <div className={`map-popup`}>
        {isMobile && (
          <Modal
            id={`map-popup-modal-${data.station_id}-${type}`}
            title={<div className={contentStyle}>{title ? title : mapFilterProps[type].name}</div>}
            body={popupContentBody}
          />
        )}
        <div
          id={`map-popup-modal-div-${data.station_id}-${type}`}
          data-toggle="modal"
          data-target={`#map-popup-modal-${data.station_id}-${type}`}
        />

        <Marker
          ref={markerRef}
          icon={isDischargeSite ? icon : custIcon || icon}
          position={position}
          riseOnHover={true}
          onClick={() => {
            setHideStateSummary && setHideStateSummary(true);
            isMobile &&
              document.getElementById(`map-popup-modal-div-${data.station_id}-${type}`).click();
            toggleMarker(data, data.station_type);
            openPopup && forceUpdate();
          }}
        >
          {!isMobile && (
            <Popup
              className="map-popup"
              minWidth={
                type === BORE.label || type === 'algal' || markerType === BORE.name ? 400 : 350
              }
            >
              <div className={contentStyle}>{title ? title : mapFilterProps[type].name}</div>
              {popupContentBody}
            </Popup>
          )}
        </Marker>
      </div>
    </>
  );
}

MapPopup.propTypes = {
  data: PropTypes.shape({
    lat: PropTypes.number,
    long: PropTypes.number,
    station_id: PropTypes.any,
    station_name: PropTypes.string,
  }),
  custIcon: PropTypes.object,
  mapRef: PropTypes.object,
  markerClusterRef: PropTypes.object,
  markerType: PropTypes.string,
  openPopup: PropTypes.any,
  contentStyle: PropTypes.string,
  position: PropTypes.array,
  setShowSpinner: PropTypes.func,
  type: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.array,
  config: PropTypes.shape({
    scrollTarget: PropTypes.string,
    tabTarget: PropTypes.object,
    linkLabel: PropTypes.string,
  }),
};
