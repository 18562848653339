// Library
import React from 'react';
import PropTypes from 'prop-types';

// Style
import './dataSummaryHeader.scss';

// Component
import ExpandBox from '../generalComponents/expandBox/ExpandBox';

// Constant
const container = 'data-summary-header';

const DataSummaryHeader = ({ header }) => {
  return (
    <>
      <ExpandBox customHeaderContent={header} customHeaderContainerStyle={`${container}`} />
    </>
  );
};

export default DataSummaryHeader;

DataSummaryHeader.propTypes = {
  header: PropTypes.any,
};
