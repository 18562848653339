import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

// Style
import './body.scss';

// Components
import Loader from '../../components/loader/Loader';
import SiteLink from '../../components/siteLink/SiteLink';

// Helpers
import { getAllStationsByType } from '../../helpers/ApiHelper.jsx';
import { combineSiteMetadata } from '../../helpers/WaterDataApiHelper.jsx';
import GlobalSearchBarMini from '../../components/globalSearch/GlobalSearchBarMini';

//Constants
const component = 'sites';
const wrapper = 'favourites';
const siteTypes = {
  weir: 'Weirs',
  gauge: 'StreamGauge',
  bore: 'Groundwater',
  dam: 'Storage',
};

const Sites = () => {
  const [stationsData, setStationsData] = useState([]);
  const [filteredStations, setFilteredStations] = useState([]);
  const urlParams = new URLSearchParams(window.location.search);
  const type = urlParams.get('type');

  // Life Cycle
  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      if (type) {
        if (type === 'dam') {
          await getAllStationsByType(type, setStationsData);
        } else {
          const siteSubType = type === 'bore' ? 'MonitoringBore' : '';
          let siteList = await combineSiteMetadata(null, siteTypes[type], siteSubType);
          siteList =
            type === 'bore'
              ? siteList.filter(item => item.variables.includes('BoreWaterLevelBelowMeasuringPt'))
              : siteList;
          setStationsData(siteList);
        }
      }
    })();
  }, [type]);

  return (
    <div className={component}>
      {isEmpty(stationsData) && <Loader width={'10px'} height={'40px'} />}
      {!isEmpty(stationsData) && (
        <>
          <div className={`${wrapper}-header`}>
            <div className={`${wrapper}-title`}>{type}</div>
          </div>
          <div className="home-mini-search">
            <GlobalSearchBarMini
              stations={stationsData}
              setFilteredStations={setFilteredStations}
              placeholder={`search ${type} by id or name`}
            />
          </div>
          <div className={`${wrapper}-list`}>
            {isEmpty(filteredStations)
              ? stationsData.map((station, index) => <SiteLink key={index} site={station} />)
              : filteredStations.map((station, index) => <SiteLink key={index} site={station} />)}
          </div>
        </>
      )}
    </div>
  );
};

export default Sites;
