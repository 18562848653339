const dotenv = require('dotenv');
dotenv.config();

const confg = {
  waterRegister: {
    server: process.env.REACT_APP_WREGISTRY_SERVER,
    param1: process.env.REACT_APP_WREGISTRY_PARAM1,
    param2: process.env.REACT_APP_WREGISTRY_PARAM2,
  },
  mapService: {
    server: process.env.REACT_APP_SERVICE_SERVER,
    flowClassServer: process.env.REACT_APP_FLOW_CLASS_MAP_SERVICE_SERVER,
    pumpStatusServer: process.env.REACT_APP_BARWON_PUMP_STATUS_MAP_SERVICE_SERVER,
    greaterSydneyServer: process.env.REACT_APP_GREATER_SYDNEY_MAP_SERVICE_SERVER,
    climateOutlookServer: process.env.REACT_APP_CLIMATE_OUTLOOK_MAP_SERVICE_SERVER,
    telemeteredBoresServer: process.env.REACT_APP_TELEMETERED_BORES_MAP_SERVICE_SERVER,
    algalAlertsServer: process.env.REACT_APP_ALGAL_ALERTS_MAP_SERVICE_SERVER,
  },
  googleAnalytics: {
    gTagId: process.env.REACT_APP_GTAG_ID,
    gtmTag: process.env.REACT_APP_GTM_TAG,
  },
};

module.exports = confg;
