import React, { useState, useEffect, useContext, useRef, createRef } from 'react';
/* Style */
import './update.scss';
import '../../layout/grid.scss';

/* Library */
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import {
  getUpdatesFromWaterSource,
  getRestrictionsFromWaterSource,
} from '../../helpers/UpdatesApiHelper.jsx';

/* Component */
import UpdateTabRestrictions from '../../components/restrictions/UpdateTabRestrictions';
import PageTop from '../../components/pageTop/PageTop';
import UpdateItem from '../../components/updates/UpdateItem';
import BackLink from '../../components/backLink/BackLink';
import ExpandBox from '../../components/generalComponents/expandBox/ExpandBox';
import ExternalLinkModal from '../../components/modal/ExternalLinkModal';
import BodyContainer from '../../layout/body/BodyContainer';

/* Constant */
import config from '../../abstract/IntroText.json';
import constants from '../../constants/Constants';

/* Context */
import { MobileContext } from '../../contexts/MobileContext';

export default function Update() {
  const { pathname } = useLocation();
  const { isMobile } = useContext(MobileContext);
  const [updates, setUpdates] = useState([]);
  const [restrictions, setRestrictions] = useState([]);
  const [externalSiteLink, setExternalSiteLink] = useState('');

  const { hash } = useLocation();
  const scrolledRefs = useRef([]);
  const scrollToElement = index => {
    if (scrolledRefs.current[index] && scrolledRefs.current[index].current) {
      const element = scrolledRefs.current[index].current;
      element.scrollIntoView({ behavior: 'smooth', alignToTop: true });
    }
  };
  useEffect(() => {
    scrollToElement(hash.replace('#', ''));
  }, [hash]);

  useEffect(() => {
    getUpdatesFromWaterSource(constants.ALL_NSW_WSID, setUpdates);
    getRestrictionsFromWaterSource(constants.ALL_NSW_WSID, setRestrictions);
    const refsArray = Array(updates.length)
      .fill(null)
      .map(() => createRef());
    scrolledRefs.current = refsArray;
  }, [pathname, updates.length]);

  const onExternalSiteClick = link => {
    setExternalSiteLink(link);
  };

  const noticeContent = () => {
    console.log(updates);
    return (
      <>
        {!isEmpty(updates) &&
          updates.map((update, index) => (
            <UpdateItem
              key={index}
              page="nswUpdates"
              ref={scrolledRefs.current[index]}
              onExternalSiteClick={onExternalSiteClick}
              type={update.type}
              title={update.title}
              date={update.date}
              content={update.body}
              link={update.url}
              linkTitle="View update"
              targetId=""
              shortenBody={true}
            />
          ))}
        <ExternalLinkModal link={externalSiteLink} modalId="externalLinkNSWUpdates" />
      </>
    );
  };

  const mobileGeneralNotice = () => {
    return (
      <div className={`update-general-body update-general-headerContainer`}>
        <ExpandBox
          customHeaderContent={<div>General Notices</div>}
          customHeaderContainerStyle={`update-general-header`}
          mobileOnly
        >
          {noticeContent()}
        </ExpandBox>
      </div>
    );
  };

  const desktopGeneralNotice = () => {
    return <div className="update-general-body">{noticeContent()}</div>;
  };

  return (
    <BodyContainer page>
      <BackLink />
      <div className="update-body update-general row">
        <div className="col-lg-7 col-sm-12 col-12">
          <PageTop title={config.updatePage.title} />
          {isMobile ? mobileGeneralNotice() : desktopGeneralNotice()}
        </div>
        <div className="update-body-right col-lg-5 col-sm-12 col-12">
          <UpdateTabRestrictions restrictions={restrictions} />
        </div>
      </div>
    </BodyContainer>
  );
}
