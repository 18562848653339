// Libraries
import React, { useState, useEffect, useContext } from 'react';
import { isEmpty } from 'lodash';
import { useHistory, useRouteMatch } from 'react-router-dom';

// Styles
import 'leaflet/dist/leaflet.css';
import './map.scss';

// Components
import BaseMap from '../../components/baseMap/BaseMap';
import MapDropdownLocator from '../../components/mapDropdownLocator/MapDropdownLocator';
import Loader from '../../components/loader/Loader';
import AppModal from '../../components/modal/Modal';
import DictionaryModalContent from '../../components/modal/DictionaryModalContent';
import ErrorModal from '../../components/generalComponents/error/ErrorModal';
import MapPinLocation from '../../components/mapProperties/MapPinLocation';
import StateOverviewSummary from '../../components/pageComponents/stateOverview/StateOverviewSummary';
import ParagraphSection from '../../components/paragraphSection/ParagraphSection';

// Context
import { AppContext } from '../../contexts/AppContext';

// Assets
import WaterSourceTypesModalData from '../../components/modal/modalText/WaterSourceTypesModalData.json';
import text from '../../abstract/IntroText.json';
import overviewText from '../../components/pageComponents/stateOverview/stateOverview.json';
import constants from '../../constants/Constants';

// Helpers
import { updateDisplayById, createMarkup } from '../../helpers/Utils';
import { Event } from '../../helpers/GAHelper';

// Constant
import overviewConstant from '../../components/pageComponents/stateOverview/StateOverviewConstants';

export default function Map() {
  const { setWaterSource, waterSource } = useContext(AppContext);
  const [waterSourceList, setWaterSourceList] = useState(Array);
  const [geometry, setGeometry] = useState(Object);
  const [showSpinner, setShowSpinner] = useState(false);
  const [findLocation, setFindLocation] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [showMapErrorMsg, setShowMapErrorMsg] = useState(false);
  const [loadMapData, setLoadMapData] = useState({ reload: false, count: 0 });
  const [rainfallModalContent, setRainfallModalContent] = useState(text.climateMapLegend.observed);
  const [pinLocation, setPinLocation] = useState({});
  const [navigateLocation, setNavigateLocation] = useState({});
  const [hideStateSummary, setHideStateSummary] = useState(false);
  const [overviewType, setOverViewType] = useState(overviewConstant.STORAGE.type);
  const history = useHistory();
  const HomeURLMatch = useRouteMatch({
    path: '/',
    strict: true,
  });

  useEffect(() => {
    if (isEmpty(waterSource)) {
      setFindLocation(false);
      setPinLocation({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterSource.water_source_id]);

  const getWaterSourceList = value => {
    setWaterSourceList(value);
  };

  const setLocationGeometry = value => {
    setGeometry(value);
  };

  const getCurrentLocation = () => {
    setGeometry({});
    setFindLocation(true);
    Event(
      waterSource.water_source_name,
      'User tried to find their location',
      constants.INTERACTION_TYPE.location,
    );
  };

  const isWaterSourceFound = isFound => {
    setShowErrorMsg(!isFound);
    if (!isFound) {
      history.push('/');
      localStorage.removeItem('curWaterSource');
      setWaterSource({});
      updateDisplayById('map-error-modal', 'block');
    }
  };

  const onRefreshClick = () => {
    const newState = { reload: true, count: loadMapData.count + 1 };
    setLoadMapData(newState);
  };

  const genRainfallModalContentByType = value => {
    const content = text.climateMapLegend[value];
    setRainfallModalContent(content ? content : {});
  };

  const onNavigateClick = () => {
    setNavigateLocation(pinLocation);
  };

  const modalMaker = modalContent => {
    return (
      <>
        {modalContent.map((content, index) => {
          return (
            <ParagraphSection
              key={index}
              sectionTitle={content.header}
              sectionContent={content.paragraph}
            />
          );
        })}
      </>
    );
  };

  return (
    <div className="map-container" id="map-container">
      <AppModal
        id="waterSourceModal"
        title="Water source"
        body={<DictionaryModalContent data={WaterSourceTypesModalData} />}
      />
      <AppModal id="disclaimerModal" title="Disclaimer" body={text.flowClass.disclaimer} />
      <AppModal
        id="rainfallLegendModal"
        title={rainfallModalContent.title}
        body={<div dangerouslySetInnerHTML={createMarkup(rainfallModalContent.intro)} />}
      />
      <AppModal
        id="algalLegendModal"
        title={text.algalAlertMapLegend.title}
        body={modalMaker(text.algalAlertMapLegend.modalContent)}
      />
      <AppModal
        id={`stateOverviewModal`}
        title={overviewText.modal.title}
        body={modalMaker(overviewText.modal.content)}
      />
      {showSpinner && <Loader />}
      {(showErrorMsg || showMapErrorMsg) && (
        <div id="map-error-modal" className="map-error-modal">
          <div className="grey-layer" />
          <ErrorModal
            type={showErrorMsg ? 'noWaterSourceError' : 'mapServiceError'}
            onRefreshClick={onRefreshClick}
          />
        </div>
      )}
      <BaseMap
        getWaterSourceList={getWaterSourceList}
        locationGeometry={geometry}
        setShowSpinner={setShowSpinner}
        findLocation={findLocation}
        setMapErrorMsg={setShowMapErrorMsg}
        loadMapData={loadMapData}
        setRadioItem={genRainfallModalContentByType}
        setPinLocation={setPinLocation}
        pinLocation={navigateLocation}
        overviewType={overviewType}
        setHideStateSummary={setHideStateSummary}
      />
      <div className="map-widget-container">
        <MapDropdownLocator
          menuList={waterSourceList}
          setLocationGeometry={setLocationGeometry}
          getCurrentLocation={getCurrentLocation}
          isWaterSourceFound={isWaterSourceFound}
          setShowSpinner={setShowSpinner}
        />
        {HomeURLMatch.isExact && isEmpty(waterSource) && (
          <StateOverviewSummary
            overviewType={overviewType}
            setOverViewType={setOverViewType}
            hide={hideStateSummary}
          />
        )}
      </div>
      {!isEmpty(pinLocation) && isEmpty(waterSource) && (
        <MapPinLocation pinLocation={pinLocation} onNavigateClick={() => onNavigateClick()} />
      )}
    </div>
  );
}
