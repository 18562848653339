/* Library */
import React, { useContext } from 'react';
import moment from 'moment';
import { Tooltip, Area, XAxis, Legend, YAxis, AreaChart, ReferenceLine } from 'recharts';
import PropTypes from 'prop-types';
import { isEmpty, isNumber } from 'lodash';

/* Styles */
import '../storageChart.scss';

/* Component */
import ChartLegend from '../../chartProperties/ChartLegend';
import ChartTooltip from '../../chartProperties/ChartTooltip';
import ChartResponsiveContainer from '../../chartProperties/ChartResponsiveContainer';

/* Context */
import { AppContext } from '../../../contexts/AppContext';
import { MobileContext } from '../../../contexts/MobileContext.jsx';

/* Helpers */
import { formatNumberDecimals } from '../../../helpers/Utils';

/* Constant */
import Constants from '../../../constants/Constants';

export default function MurrayMonthlyStorageGraph({ data, activeDams, fullVolume, isLoading }) {
  const { waterSource } = useContext(AppContext);
  const { isMobile } = useContext(MobileContext);

  const renderReferenceLineLabel = ({ viewBox }) => {
    const x = viewBox.width / 2;
    const y = viewBox.y + 20;
    return (
      <text className="svg-reference-line-label" x={x} y={y}>
        {Constants.TOTAL_STORAGE_MENINDEE_LAKES_FLAG
          ? activeDams.length === 1 && Number(activeDams[0]) === Constants.MENINDEE_LAKES_TOTAL_ID
            ? 'Total capacity'
            : activeDams.length > 1 &&
              [
                Constants.MURRAY_REGULATED_RIVER_WATER_SOURCE,
                Constants.LOWER_DARLING_REGULATED_RIVER_WATER_SOURCE,
              ].includes(waterSource.water_source_id)
            ? 'Storage capacity'
            : 'Accessible capacity'
          : 'Accessible capacity'}
      </text>
    );
  };

  const renderCusomizedLegend = ({ payload }) => {
    return <ChartLegend payload={payload} legendType="square" />;
  };

  const renderCustomTooltip = ({ payload }) => {
    return (
      <ChartTooltip
        payload={payload}
        isPayloadArray={false}
        titleKey="date"
        murrayGraph={true}
        subElementKey="storage"
      />
    );
  };

  return (
    <ChartResponsiveContainer
      customHeight={{ desktop: 450, mobile: 350 }}
      isLoading={isLoading}
      isEmptyChart={isEmpty(data)}
    >
      <AreaChart
        className="composed-chart"
        width={800}
        data={data}
        margin={{
          top: isMobile ? 30 : 10,
          left: 20,
          bottom: isMobile ? 10 : 50,
        }}
      >
        <XAxis
          dataKey="date"
          tickFormatter={v =>
            moment(v).isValid() && !isNumber(v) ? moment(v, 'YYYY-MM').format('YYYY MMM') : ''
          }
          type="category"
          angle={-35}
          dy={25}
          tick={{ fill: 'black' }}
        />
        <YAxis
          interval="preserveStartEnd"
          domain={[0, fullVolume]}
          label={{
            value: 'Storage volume (GL)',
            angle: -90,
            dx: -43,
            dy: -55,
          }}
          tickFormatter={v => formatNumberDecimals(Math.round(v / 1000))}
          type="number"
          tick={{ fill: 'black' }}
        />
        <Legend wrapperStyle={{ bottom: 0 }} content={renderCusomizedLegend} />
        <Tooltip
          content={payload => renderCustomTooltip(payload)}
          cursor={{ fill: 'transparent' }}
        />
        <Area
          type="monotone"
          fillOpacity="1"
          name="NSW"
          stackId="1"
          dataKey="storage.nsw"
          fill="#95B3D7"
          stroke="#95B3D7"
        />
        <Area
          type="monotone"
          fillOpacity="1"
          name="Victoria"
          stackId="1"
          dataKey="storage.vic"
          fill="#0054A6"
          stroke="#0054A6"
        />
        <Area
          type="monotone"
          fillOpacity="1"
          name="South Australia"
          stackId="1"
          dataKey="storage.sa"
          fill="#007AB5"
          stroke="#007AB5"
        />
        <ReferenceLine
          y={fullVolume}
          label={renderReferenceLineLabel}
          stroke="red"
          strokeDasharray="3 3"
        />
      </AreaChart>
    </ChartResponsiveContainer>
  );
}

MurrayMonthlyStorageGraph.propTypes = {
  data: PropTypes.array,
  activeDams: PropTypes.array,
  fullVolume: PropTypes.number,
};
