// Library
import React from 'react';

// Style
import './historicalDataLayout.scss';

const component = 'historical-data';

export default function HistoricalDataLayout({ left, right }) {
  return (
    <div className={`${component}-container`}>
      {left}
      {right}
    </div>
  );
}
