// Library
import React, { useState, useEffect, useContext } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';

// Styles
import './ratingsCurve.scss';

// Component
import Loader from '../../../../loader/Loader';
import RatingsCurveGraphItem from './RatingsCurveGraphItem';
import RatingsCurveTableItem from './RatingsCurveTableItem';
import ResultContent from '../ResultContent';
import ChartCSVDownloadLink from '../../../../chartProperties/ChartCSVDownloadLink';

// Helper
import { getRatingTablesData } from '../../../../../helpers/WaterDataApiHelper';

// Context
import { DownloadContext } from '../../../../../contexts/DownloadContext';

// Constant
import constants from './RatingsCurveConstants';
import apiConstants from '../../../../../constants/WaterDataAPIConstants';
import config from '../../../../../configs/featureToggleConfig.json';
const component = 'ratings-curve';
const active = config.ratings_curve_table.active;

const RatingsCurve = () => {
  const { downloadType, selectedStations, stationInfoType, ratingTableFormat } =
    useContext(DownloadContext);
  const [ratingData, setRatingData] = useState([]);
  const [csvData, setCsvData] = useState({});
  const [levelRange, setLevelRange] = useState({});

  useEffect(() => {
    (async () => {
      try {
        if (selectedStations.length < 2) {
          const request = selectedStations.map(selectedItem =>
            getRatingTablesData(selectedItem.id),
          );
          const result = await Promise.all(request);
          const namedResult = result.map(item => {
            if (!isEmpty(item)) {
              const minDataInd = item.findIndex(item => Number(item['Value1']) > 0);
              let data = item.slice(minDataInd - 1);
              data = data.map(item => {
                return {
                  ...item,
                  varFrom: 100,
                  varTo: 141,
                  ratingTableNo: `${item.TableNo}.${String(item.ReleaseNo).padStart(2, '0')}`,
                  quality: 130,
                };
              });
              return {
                id: item[0].SiteId,
                data: data.filter(item =>
                  item.Level.includes('.') ? item.Level.split('.')[1]?.length < 3 : item.Level,
                ),
              };
            }
            return { data: [] };
          });
          if (!isEmpty(namedResult[0].data)) {
            setCsvData({
              graph: namedResult[0].data,
              table: genCsvFileTableFormat(namedResult[0].data),
            });
          }
          setRatingData(namedResult);
        } else {
          setRatingData([]);
        }
      } catch (e) {
        console.error(e);
        setRatingData([]);
      }
    })();

    return () => {
      setRatingData([]);
    };
  }, [downloadType, selectedStations, stationInfoType]);

  const genCsvFileTableFormat = data => {
    let range = [];
    for (let i = Number(data[0].Level); i <= Number(data[data.length - 1].Level); i += 0.01) {
      const numLength = Number(i.toFixed(2)) < 0 ? 4 : 3;
      range.push(i.toFixed(2).slice(0, numLength));
    }
    range = range.filter((value, index, array) => array.indexOf(value) === index);
    setLevelRange(range);

    const metaData = [
      `Site: ${selectedStations[0].siteId} ${selectedStations[0].siteName}`,
      `Rating Table: ${data[0].ratingTableNo}`,
      `Converting: 100 (Stream Water Level in Metres)`,
      `Into: 141 (Discharge Rate in Megalitres/Day)`,
      `Period of Applicability: ${data[0].PointOfApplicability}`,
    ];
    let result = [];
    range.map((levelItem, idx) => {
      let resultObj = {};
      constants.TABLE_HEADERS.Tabular2.map(headerItem => {
        const level =
          Number(levelItem) < 0 || levelItem.includes('-')
            ? Number((Number(levelItem) - headerItem).toFixed(2))
            : Number((Number(levelItem) + headerItem).toFixed(2));
        resultObj[headerItem] =
          levelItem === '-0.0' && headerItem === 0
            ? '-'
            : Number(data.find(dataItem => Number(dataItem.Level) === level)?.Value1) || '-';
      });
      resultObj.Level = levelItem;
      resultObj.MetaData = metaData[idx];
      result.push(resultObj);
    });
    return result;
  };

  const metaData = data => {
    return (
      <table>
        <tbody>
          <tr>
            <th>Site:</th>
            <td>{`${selectedStations[0].siteId} ${selectedStations[0].siteName}`}</td>
          </tr>
          <tr>
            <th>Rating Table:</th>
            <td>{`${data.TableNo}.${String(data.ReleaseNo).padStart(2, '0')} (Interpolation: Log${
              active ? `, CTF: ${selectedStations[0].ctfLevel}` : ''
            })`}</td>
          </tr>
          <tr>
            <th>Converting:</th>
            <td>100 (Stream Water Level in Metres)</td>
          </tr>
          <tr>
            <th>Into:</th>
            <td>141 (Discharge Rate in Megalitres/Day)</td>
          </tr>
          <tr>
            <th>Period of applicability:</th>
            <td>
              {data.PointOfApplicability
                ? moment(data.PointOfApplicability, apiConstants.API_DATE_FORMAT).format(
                    'DD-MMM-YYYY',
                  )
                : '-'}
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <div className={`${component}`}>
      {selectedStations.length > 1 ? (
        <ResultContent>
          <div className={`${component}-msg`}>Please select only one site at a time.</div>
        </ResultContent>
      ) : isEmpty(ratingData) && !isEmpty(ratingTableFormat) ? (
        <Loader />
      ) : (
        <>
          {ratingData.map(
            ratingDataItem =>
              !isEmpty(ratingDataItem) && (
                <ResultContent key={ratingDataItem.id}>
                  {!isEmpty(ratingDataItem.data) ? (
                    <>
                      {!isEmpty(ratingTableFormat) && active && (
                        <div className={`${component}-btns`}>
                          <ChartCSVDownloadLink
                            data={
                              ratingTableFormat.id === 'Tabular2' ? csvData.table : csvData.graph
                            }
                            headers={constants.FILE_HEADERS[ratingTableFormat.id]}
                            filename={`Ratings Table - ${ratingDataItem.id}`}
                            toFormat={false}
                            page="download"
                          />
                        </div>
                      )}
                      <div className={`${component}-site-data`}>
                        {metaData(ratingDataItem.data[0])}
                      </div>
                      <Content
                        ratingData={ratingDataItem}
                        setRatingData={setRatingData}
                        levelRange={levelRange}
                      />
                    </>
                  ) : (
                    <div className={`${component}-msg`}>
                      There is no related rating tables for the selected site.
                    </div>
                  )}
                </ResultContent>
              ),
          )}
        </>
      )}
    </div>
  );
};

export default RatingsCurve;

const Content = ({ ratingData, setRatingData, levelRange }) => {
  const { ratingTableFormat } = useContext(DownloadContext);
  if (!ratingTableFormat) return <></>;

  let content;
  switch (ratingTableFormat.id) {
    case 'Graph':
      content = <RatingsCurveGraphItem ratingData={ratingData} setRatingData={setRatingData} />;
      break;
    case 'Tabular1':
    case 'Tabular2':
      content = <RatingsCurveTableItem ratingData={ratingData} levelRange={levelRange} />;
      break;
    default:
      content = <></>;
      break;
  }

  return content;
};
