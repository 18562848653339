// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './Modal.scss';

// Components
import Modal from './Modal';

// Constant
const component = 'confirm-modal';

export default function ConfirmationModal({ onYesClicked, modalId }) {
  const modalFooter = () => {
    return (
      <div className={`${component}-footer`}>
        <div className={`${component}-no`} data-dismiss="modal" aria-label="Close">
          No
        </div>
        <div className={`${component}-yes`} onClick={() => onYesClicked()} data-dismiss="modal">
          Yes
        </div>
      </div>
    );
  };

  return (
    <div className={component}>
      <Modal
        id={modalId}
        title="Are you sure?"
        body="Are you sure you want to remove this from your saved sites?"
        footer={modalFooter()}
      />
    </div>
  );
}

ConfirmationModal.propTypes = {
  onYesClicked: PropTypes.func,
  modalId: PropTypes.string,
};
