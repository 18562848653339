// Libraries
import React, { useEffect, useContext, useState, useRef } from 'react';
import { Marker, Popup } from 'react-leaflet';
import { isEmpty } from 'lodash';

// Styles
import './pumpFlowStatusMarker.scss';
import './MapFilterMarker.scss';
import '../mapPopup/MapPopup.scss';

// Context
import { AppContext } from '../../contexts/AppContext';
import { MobileContext } from '../../contexts/MobileContext';

// Components
import PumpPopup from '../mapPopup/PumpPopup';

// Assets
import constants from '../../constants/Constants.jsx';
import pumpRedIcon from '../../image/icons/mapMarker/pump-flow-red.png';
import pumpGreenIcon from '../../image/icons/mapMarker/pump-flow-green.png';
import alertIcon from '../../image/icons/alert.png';

// Helpers
import {
  promiseXMLHttpRequestMapService,
  queryEsri,
  generateMarkerIcon,
} from '../../helpers/MapServiceApiHelper';

export default function PumpFlowStatusMarker(props) {
  const markerRef = useRef(null);
  const { waterSource } = useContext(AppContext);
  const { isMobile } = useContext(MobileContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    let unmounted = false;
    setData([]);
    if (waterSource.water_source_id && props.selected) {
      const url = constants.BARWON_DARLING_PUMP_STATUS + '0';
      queryEsri(url, '1=1', function (result) {
        if (!unmounted) {
          runQuery(result);
        }
      });
    }

    async function runQuery(query) {
      props.setShowSpinner(true);
      let queryResult = await promiseXMLHttpRequestMapService(query);
      props.setShowSpinner(false);
      if (!isEmpty(queryResult.features)) {
        setData(queryResult.features);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type, props.selected, waterSource.water_source_id]);

  const setMarkerIcon = feature => {
    const sizeConfig = isMobile ? constants.MAP_MARKER_SIZE_MOBILE : constants.MAP_MARKER_SIZE;
    const className =
      feature.properties.SIGNAL === 'R' ? 'map-marker-pump-red' : 'map-marker-pump-green';
    const iconUrl = feature.properties.SIGNAL === 'R' ? pumpRedIcon : pumpGreenIcon;
    const markerIcon = generateMarkerIcon(iconUrl, sizeConfig, className);

    return markerIcon;
  };

  return (
    !isEmpty(data) &&
    data.map(
      (feature, index) =>
        feature.geometry && (
          <Marker
            key={index}
            ref={markerRef}
            icon={setMarkerIcon(feature)}
            position={[feature.geometry.coordinates[1], feature.geometry.coordinates[0]]}
            riseOnHover={true}
          >
            <Popup maxWidth="380" minWidth="280" maxHeight="560" minHeight="auto">
              <div className="marker-popup-header map-marker-pump">
                <span>Pump</span>
                <button
                  type="button"
                  className="disclaimer-button"
                  data-toggle="modal"
                  data-target="#disclaimerModal"
                >
                  <img src={alertIcon} alt="Disclaimer" className="disclaimer-icon" />
                </button>
              </div>
              <PumpPopup data={feature} />
            </Popup>
          </Marker>
        ),
    )
  );
}
