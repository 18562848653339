// Libraries
import React, { useContext, useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// Style
import './storage.scss';
import '../generalComponents/textReadMore/textReadMore.scss';

// Context
import { AppContext } from '../../contexts/AppContext';

//Components
import RiverDataSummary from '../../components/riverData/RiverDataSummary';
import Loader from '../../components/loader/Loader';

// Helper
import { getStationsForWaterSource } from '../../helpers/ApiHelper';
import { getDataForRiverDataSummary } from '../../helpers/WaterDataApiHelper';

// Constants
import constants from '../../constants/Constants';
import mapFilterConstant from '../../constants/MapFilterConstants';
const { WEIR } = mapFilterConstant.MAP_POPUP_ITEMS;
const WEIRS_LIMIT_NUMBER = 2;

export default function InlineStorageWeir({ setHasWeirs }) {
  const history = useHistory();
  const { hash } = history.location;
  const { waterSource } = useContext(AppContext);
  const { water_source_type, water_source_id } = waterSource;
  const { regulated, unregulated } = constants.WATER_SOURCE_TYPE;
  const [weirs, setWeirs] = useState([]);
  const [weirsListLimit, setWeirsListLimit] = useState(WEIRS_LIMIT_NUMBER);
  const [showHiddenItems, setShowHiddenItems] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  // Life cycle
  useEffect(() => {
    const getData = async stations => {
      const data = await getDataForRiverDataSummary(
        stations,
        'weir',
        'StorageWaterLevel,TotalStorageVolume',
        'daily',
      );
      return data;
    };

    if (water_source_type.includes(regulated) || water_source_type.includes(unregulated)) {
      (async () => {
        setShowSpinner(true);
        let weirsMetadata;
        await getStationsForWaterSource(
          waterSource,
          WEIR.name,
          function (response) {
            setHasWeirs(!isEmpty(response));
            weirsMetadata = response;
            if (hash) {
              const stationdIndex = response.findIndex(
                element => element.station_id === hash.slice(1),
              );
              if (stationdIndex > WEIRS_LIMIT_NUMBER) {
                setShowHiddenItems(true);
              }
            }
          },
          true,
        );
        const data = await getData(weirsMetadata);
        setWeirs(data);
        setShowSpinner(false);
      })();
    }

    return () => {
      setWeirs([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterSource]);

  useEffect(() => {
    if (showHiddenItems) {
      setWeirsListLimit();
    } else {
      setWeirsListLimit(WEIRS_LIMIT_NUMBER);
    }
  }, [showHiddenItems]);

  return (
    <>
      {showSpinner && <Loader staticPosition />}
      {!isEmpty(weirs) && (
        <>
          <RiverDataSummary
            stations={weirs}
            type={WEIR.name}
            showHiddenText={showHiddenItems}
            dataListLimit={weirsListLimit}
          />
          {weirs.length > WEIRS_LIMIT_NUMBER && (
            <button
              className="text-read-more-button"
              onClick={() => setShowHiddenItems(prev => !prev)}
            >
              {showHiddenItems ? 'Show less' : 'Show more'}
            </button>
          )}
        </>
      )}
    </>
  );
}

InlineStorageWeir.propTypes = {
  setHasWeirs: PropTypes.func,
};
