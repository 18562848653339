// Library
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, toString } from 'lodash';

// Style
import './toggleButton.scss';

// Constant
const container = 'toggle-button';

const ToggleButton = ({ options, onClick, activeDefault }) => {
  const firstOptionActive = !isEmpty(options) && toString(options[0]);
  const [active, setActive] = useState('');

  useEffect(() => {
    if (!isEmpty(activeDefault)) {
      setActive(activeDefault);
    } else {
      setActive(firstOptionActive);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDefault]);

  return (
    <div className={`${container}${options.length === 1 ? '-single' : ''}`}>
      {options.map(option => {
        const strOption = toString(option);
        return (
          <button
            key={strOption}
            value={strOption}
            className={`${container}-button ${
              active === strOption ? `${container}-button-active` : ''
            }`}
            onClick={e => {
              onClick(e.target.value);
              setActive(e.target.value);
            }}
          >
            {strOption.replace('_', ' ')}
          </button>
        );
      })}
    </div>
  );
};

export default ToggleButton;

ToggleButton.propTypes = {
  options: PropTypes.array,
  onClick: PropTypes.func,
  activeDefault: PropTypes.string,
};
