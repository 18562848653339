// Libraries
import React, { useState, useEffect, useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { groupBy, isEmpty, isObject } from 'lodash';

// Styles
import '../../../components/mapMarker/MapFilterMarker.scss';
import './stateOverviewPopups.scss';

// Components
import MapPopup from '../../mapPopup/MapPopup';

// Helpers
import {
  getOveviewResources,
  getAllDamsForOverview,
  getLatestResourcesByDamId,
} from '../../../helpers/ApiHelper';
import { generateDivIcon } from '../../../helpers/MapServiceApiHelper';
import { mergedObjectsByKey } from '../../../helpers/ArrayUtils';
import { getLatestStorageResources } from './DataHelper';

// Context
import { AppContext } from '../../../contexts/AppContext';
import { MobileContext } from '../../../contexts/MobileContext';
import { MapFilterContext } from '../../../contexts/MapFilterContext';

// Constant
import MAPFILTERCONSTANT from '../../../constants/MapFilterConstants';
import OVERVIEWCONSTANT from './StateOverviewConstants';
import constants from '../../../constants/Constants';
import config from '../../../configs/featureToggleConfig.json';
const { STORAGE, AVAILABILITY, TRADES } = OVERVIEWCONSTANT;
const container = 'state-overview-popups';
const getDataFromWaterDataApi = config['water-data-api'].dams.active;

const popupsProps = {
  [STORAGE.type]: STORAGE,
  [AVAILABILITY.type]: AVAILABILITY,
  [TRADES.type]: TRADES,
};

const StateOverviewPopups = ({
  markerClusterRef,
  mapRef,
  markerSelected,
  setShowSpinner,
  overviewType,
  setHideStateSummary,
}) => {
  // Variables
  const HomeURLMatch = useRouteMatch({
    path: '/',
    strict: true,
  });
  const { STATIONS, WATER_QUALITY } = MAPFILTERCONSTANT.MAP_POPUP_ITEMS;

  // States
  const { waterSource } = useContext(AppContext);
  const { isLarge } = useContext(MobileContext);
  const { filterSelected } = useContext(MapFilterContext);
  const [popups, setPopups] = useState([]);

  // Life Cycles
  useEffect(() => {
    let unmounted = false;
    loadPopupsData(unmounted, overviewType);
    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overviewType]);

  // Helpers
  const loadPopupsData = async (unmounted, type) => {
    if (!unmounted) {
      let overviewResourceData;
      if (getDataFromWaterDataApi && type === 'storage') {
        let overviewDams = await getAllDamsForOverview();
        let damsResources = await getLatestStorageResources(false, false, [], false);
        damsResources = groupBy(damsResources, 'siteId');
        overviewDams = await Promise.all(
          overviewDams.map(async item => {
            const damData = damsResources[item.dam_id];
            const resource = {};
            if (constants.SPECIAL_SITES.includes(Number(item.dam_id))) {
              const siteData = await getLatestResourcesByDamId(item.dam_id);
              if (siteData.data && !isEmpty(siteData.data.resources)) {
                resource.date = siteData.data.resources[0].date;
                resource.volume = siteData.data.resources[0].volume;
                resource.volume_perc = siteData.data.resources[0].volume_perc;
              }
            } else {
              if (damData) {
                resource.date = damData[0].timeStamp;
                resource.volume = damData.find(
                  e => e.variableName === 'ActiveStorageVolume',
                )?.value;
                resource.volume_perc = damData.find(
                  e => e.variableName === 'ActiveStoragePercentage',
                )?.value;
              }
              if (!resource.volume) {
                resource.volume =
                  damData?.find(e => e.variableName === 'TotalStorageVolume')?.value -
                  item.dead_storage;
              }
              if (!resource.volume_perc) {
                resource.volume_perc = (resource.volume / item.full_volume) * 100;
              }
            }
            return {
              id: item.dam_id,
              name: item.dam_name,
              lat: item.lat,
              long: item.long,
              full_volume: item.full_volume,
              water_source: item.prime_water_source,
              date: resource.date,
              volume: resource.volume,
              volume_perc: resource.volume_perc,
            };
          }),
        );
        overviewResourceData = overviewDams;
      } else {
        overviewResourceData = await getOveviewResources(type);
      }
      const formatted = formatPopupData(overviewResourceData);
      if (overviewType === TRADES.type) {
        const grouped = mergedObjectsByKey(formatted, 'id', overviewType);
        setPopups(grouped);
      } else {
        setPopups(formatted);
      }
    }
  };

  const formatPopupData = data => {
    const radiusProp = popupsProps[overviewType].radius;
    const shadeProp = popupsProps[overviewType].shade;
    const formatted = !isEmpty(data)
      ? data.map(popup => {
          return {
            ...popup,
            radius: calcRadius(popup[radiusProp]),
            perc_stage: calcShades(
              isObject(shadeProp)
                ? (popup[shadeProp.target] / popup[shadeProp.target2]) * 100
                : popup[shadeProp],
            ),
          };
        })
      : [];
    return formatted;
  };

  const calcRadius = value => {
    let result = 0;
    value = overviewType === TRADES.type ? value * 1000 : value;
    switch (true) {
      case value > 0 && value < 150001:
        result = 20;
        break;
      case value > 150000 && value < 500001:
        result = 20 + (20 / 350000) * (value - 150000);
        break;
      case overviewType === AVAILABILITY.type && value > 500000 && value < 1000001:
        result = 40 + (20 / 500000) * (value - 500000);
        break;
      case overviewType === STORAGE.type && value > 500000 && value < 1500001:
        result = 40 + (20 / 1000000) * (value - 500000);
        break;
      case overviewType === TRADES.type && value > 500000 && value < 5000001:
        result = 40 + (20 / 3500000) * (value - 500000);
        break;
      case overviewType === AVAILABILITY.type && value > 1000000 && value < 2500001:
        result = 60 + (20 / 1500000) * (value - 1000000);
        break;
      case overviewType === STORAGE.type && value > 1500000 && value < 4000001:
        result = 60 + (20 / 2500000) * (value - 1500000);
        break;
      case overviewType === TRADES.type && value > 5000000 && value < 25000001:
        result = 60 + (20 / 25000000) * (value - 5000000);
        break;
      default:
        result = 20;
        break;
    }
    return result;
  };

  const calcShades = value => {
    switch (true) {
      case value >= popupsProps[overviewType].threshold[0]:
        return '1';
      case value >= popupsProps[overviewType].threshold[1]:
        return '2';
      case value >= popupsProps[overviewType].threshold[2]:
        return '3';
      case value >= popupsProps[overviewType].threshold[3]:
        return '4';
      default:
        return '5';
    }
  };

  return (
    HomeURLMatch.isExact &&
    !isLarge &&
    isEmpty(waterSource) &&
    filterSelected !== WATER_QUALITY.name && (
      <>
        {!isEmpty(popups) &&
          popups.map((popup, index) => (
            <MapPopup
              contentStyle={`map-marker-dam-storage map-popup-header`}
              title={popupsProps[overviewType].title}
              content={popupsProps[overviewType].popupContent}
              config={popupsProps[overviewType].popupConfig}
              markerClusterRef={markerClusterRef}
              mapRef={mapRef}
              key={index}
              type={'overview'}
              openPopup={
                markerSelected &&
                markerSelected.id === popup.id &&
                markerSelected.type === 'overview'
              }
              position={[popup.lat, popup.long]}
              data={popup}
              custIcon={generateDivIcon(
                '',
                {
                  iconSize: [popup.radius, popup.radius],
                },
                `${container}-icons-${overviewType}${
                  popup.water_category
                    ? '-' + popup.water_category.toLowerCase().replace(/ /gi, '')
                    : ''
                }-${popup.perc_stage} map-marker-base`,
              )}
              markerType={STATIONS.name}
              setShowSpinner={setShowSpinner}
              setHideStateSummary={setHideStateSummary}
            />
          ))}
      </>
    )
  );
};

export default StateOverviewPopups;

StateOverviewPopups.propTypes = {
  markerClusterRef: PropTypes.object,
  mapRef: PropTypes.object,
  markerSelected: PropTypes.any,
  setShowSpinner: PropTypes.func,
  overviewType: PropTypes.string,
};
