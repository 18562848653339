// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './selectionBtnsGroup.scss';

const container = 'btns-inner-container';
export default function SelectionBtnsGroup({ left, right, customStyles }) {
  return (
    <div className={customStyles ? customStyles : container}>
      {(left || right) && (
        <>
          <div className={`chart-div-left ${!left ? `${container}-mobile-hidden` : ''}`}>
            {left}
          </div>
          <div className={`chart-div-right ${!right ? `${container}-mobile-hidden` : ''}`}>
            {right}
          </div>
        </>
      )}
    </div>
  );
}

SelectionBtnsGroup.propTypes = {
  left: PropTypes.any,
  right: PropTypes.any,
  customStyles: PropTypes.string,
};
