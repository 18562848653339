// Library
import React, { useEffect, useContext } from 'react';

// Component
import BodyContainer from '../../layout/body/BodyContainer';
import {
  ManageAlertBody,
  ALERT_TYPES,
} from '../../components/userProfileItems/manageAlert/ManageAlertBody';
import azureB2CHelper from '../../helpers/AzureB2CHelper';

// context
import { StationAlertCtx } from '../../contexts/StationAlertContext';
import { UserContext } from '../../contexts/UserContext';

/* Helpers */
import { getSubscriptionsByUserID } from '../../helpers/SubscriptionsApiHelper';

const ManageAlerts = () => {
  const { isLoggedIn } = useContext(UserContext);
  const { setStationAlerts, setUpdateAlerts, setConstraintAlerts } = useContext(StationAlertCtx);

  useEffect(() => {
    isLoggedIn &&
      (async () => {
        const email = await azureB2CHelper.getUserEmail();
        await getSubscriptionsByUserID(email, ALERT_TYPES[0], setStationAlerts);
        await getSubscriptionsByUserID(email, ALERT_TYPES[1], setUpdateAlerts);
        await getSubscriptionsByUserID(email, ALERT_TYPES[2], setConstraintAlerts);
      })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  return (
    <BodyContainer page>
      <ManageAlertBody />
    </BodyContainer>
  );
};

export default ManageAlerts;
