import React, { useState, createContext } from 'react';

//helpers
import PropTypes from 'prop-types';

export const StationAlertCtx = createContext();

export default function StationAlertCtxProvider({ children }) {
  const [stationAlerts, setStationAlerts] = useState([]);
  const [updateAlerts, setUpdateAlerts] = useState([]);
  const [constraintAlerts, setConstraintAlerts] = useState([]);

  return (
    <StationAlertCtx.Provider
      value={{
        stationAlerts,
        updateAlerts,
        constraintAlerts,
        setStationAlerts,
        setUpdateAlerts,
        setConstraintAlerts,
      }}
    >
      {children}
    </StationAlertCtx.Provider>
  );
}

StationAlertCtxProvider.propTypes = {
  children: PropTypes.any,
};
