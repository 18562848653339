// Libraries
import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { isArray, isEmpty } from 'lodash';
import { ComposedChart, Area, Bar, Tooltip, XAxis, Legend, YAxis, Label } from 'recharts';

// Context
import { AppContext } from '../../contexts/AppContext';
import { MobileContext } from '../../contexts/MobileContext';

// Helpers
import { genListOfFiscalYears } from '../../helpers/Utils';
import { getCumulativeInflowsByWaterSource } from '../../helpers/ApiHelper';
import { genGraphDataForFiscalYear } from '../../helpers/ProcessDataHelper';
import { Event } from '../../helpers/GAHelper';

// Components
import SelectionBtnsGroup from '../storage/childProperties/SelectionBtnsGroup';
import YearMultiSelector from '../yearMultiSelector/YearMultiSelector';
import ChartContainer from '../chartProperties/ChartContainer';
import ChartLegend from '../chartProperties/ChartLegend';
import ChartResponsiveContainer from '../chartProperties/ChartResponsiveContainer';

// Constants
import constants from '../../constants/Constants';
import text from './research.json';
export default function CumulativeInflowPercentile() {
  const { waterSource } = useContext(AppContext);
  const { isMobile } = useContext(MobileContext);
  const [listOfYears, setListOfYears] = useState(
    genListOfFiscalYears('inflow', waterSource.water_source_id),
  );
  const defaultYears = listOfYears.slice(0, 2);
  const [emptyChart, setEmptyChart] = useState();
  const [chartData, setChartData] = useState([]);
  const [activeYears, setActiveYears] = useState(defaultYears.map(year => year.value));

  useEffect(() => {
    (async () => {
      const yearsParam = listOfYears.map(year => {
        return year.value;
      });
      let chartDataRes = await getCumulativeInflowsByWaterSource(
        waterSource.water_source_id,
        yearsParam.join(),
        null,
        null,
      );
      if (!(chartDataRes instanceof Error)) {
        Object.values(chartDataRes.data).forEach(item => {
          item['Dry years'] = [0, 20];
          item['Wet years'] = [80, 100];
          for (const k in item.percentile) {
            item[`percentile.${k}`] = item.percentile[k];
          }
        });
        const formattedData = genGraphDataForFiscalYear(chartDataRes.data, true);
        if (isArray(formattedData)) {
          setListOfYears(
            listOfYears.filter(year =>
              Object.prototype.hasOwnProperty.call(formattedData[0], `percentile.${[year.value]}`),
            ),
          );
        }
        setChartData(formattedData);
        setEmptyChart(false);
      } else {
        setChartData([]);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterSource.water_source_id]);

  const onYearChange = value => {
    if (value && value.length) {
      setActiveYears(value.map(year => year.value));
    } else {
      setEmptyChart(true);
    }
    Event(
      waterSource.water_source_name,
      '/storage - year selection: ' + value.map(FY => FY.label).join(',') + ' years',
      constants.INTERACTION_TYPE.button,
    );
  };

  const drawBarsForTributary = () => {
    let dataSet = !emptyChart ? chartData[0] || [] : [];
    let barArr = [];
    let count = 0;
    let colorCodes = [
      '#0054a6',
      '#f36c21',
      '#bed12a',
      '#194378',
      '#ffcd34',
      '#63cbe8',
      '#4e9132',
      '#0054a6',
      '#f36c21',
      '#bed12a',
      '#194378',
      '#ffcd34',
      '#63cbe8',
      '#4e9132',
    ];
    for (let dataKey in dataSet.percentile) {
      for (let yearKey in activeYears) {
        if (Number(dataKey) === Number(activeYears[yearKey])) {
          const colorIndex = activeYears.indexOf(Number(dataKey));
          const fiscalYear = !Number.isNaN(dataKey) ? `${dataKey - 1}/${dataKey}` : dataKey;
          barArr.push(
            <Bar
              name={fiscalYear}
              dataKey={`percentile.${dataKey}`}
              fill={colorCodes[colorIndex]}
              key={`line-chart-${count}`}
              radius={[5, 5, 0, 0]}
            />,
          );
          count++;
        }
      }
    }
    return barArr;
  };

  const renderCusomizedLegend = ({ payload }) => {
    return <ChartLegend payload={payload} />;
  };

  const formatTooltip = (value, name) => {
    if (name.includes('years')) {
      return [];
    }
    return [moment.localeData().ordinal(value.toFixed(0)) + ' percentile', name];
  };

  return (
    <ChartContainer
      divId="AMSRecent"
      pageTitle={text.cumulativeInflowPercentile.title}
      graphTitle={text.cumulativeInflowPercentile.title}
      graphIntro={text.cumulativeInflowPercentile.intro}
    >
      <SelectionBtnsGroup
        right={
          <YearMultiSelector
            defaultYears={defaultYears}
            listOfYears={listOfYears}
            onYearChange={onYearChange}
          />
        }
      />
      <ChartResponsiveContainer
        customHeight={{ desktop: 450, mobile: 350 }}
        isEmptyChart={isEmpty(chartData)}
      >
        <ComposedChart
          width={800}
          data={chartData}
          margin={{
            top: isMobile ? 30 : 10,
            left: 20,
            bottom: isMobile ? 30 : 50,
          }}
        >
          <XAxis
            interval={isMobile ? 'preserveStartEnd' : 'preserveEnd'}
            dataKey="month"
            tick={{ fill: 'black' }}
            angle={-60}
            dy={25}
          />
          <YAxis
            interval="preserveStartEnd"
            width={isMobile ? 15 : 60}
            angle={isMobile ? -60 : 0}
            tick={{ fill: 'black' }}
            ticks={[0, 20, 80, 100]}
          >
            {!isMobile && (
              <Label
                value="Cumulative inflows percentile rank"
                offset={0}
                position="center"
                angle={-90}
                dx={-35}
              />
            )}
            {!isMobile && <Label value="Wet years" offset={30} position="insideTop" width={10} />}
            {!isMobile && (
              <Label value="Dry years" offset={30} position="insideBottom" width={10} />
            )}
          </YAxis>
          <Tooltip
            itemStyle={{ paddingTop: '0px', paddingBottom: '0px' }}
            formatter={(value, name) => formatTooltip(value, name)}
            labelFormatter={date => moment(date, 'MMM').format('MMMM')}
          />
          <Legend wrapperStyle={{ bottom: isMobile ? 0 : -10 }} content={renderCusomizedLegend} />
          <Area
            dataKey="Dry years"
            stroke="#ed931b"
            fillOpacity={0.3}
            fill="#ed931b"
            strokeWidth={0}
            isAnimationActive={false}
            activeDot={false}
          />
          <Area
            dataKey="Wet years"
            stroke="#91b0c1"
            fillOpacity={0.3}
            fill="#91b0c1"
            strokeWidth={0}
            isAnimationActive={false}
            activeDot={false}
          />
          {drawBarsForTributary()}
        </ComposedChart>
      </ChartResponsiveContainer>
    </ChartContainer>
  );
}
