// Libraries
import React, { useContext } from 'react';

// Styles
import './topNavBar.scss';

// Components
import NavLinkItem from '../../components/navLinkItems/NavLinkItem';

// Assets
import constants from '../../constants/Constants';

import { AppContext } from '../../contexts/AppContext';
import { Event } from '../../helpers/GAHelper';

export default function TopNavBar() {
  const { waterSource } = useContext(AppContext);

  return (
    <header className="page-header row">
      {constants.EXTERNAL_LINK_ITEMS.map((item, index) => (
        <NavLinkItem
          key={index}
          title={item.title}
          link={item.link}
          onLinkClick={() =>
            Event(
              waterSource.water_source_name,
              item.title + ' nav tab click',
              constants.INTERACTION_TYPE.navigation,
            )
          }
        />
      ))}
    </header>
  );
}
