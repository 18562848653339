// Helper
import { buildUrl, getPathname } from './UrlGenerator.jsx';

// Constant
import constants from '../constants/Constants';

export const saveToLocalStorage = (waterSource, history) => {
  localStorage.setItem('curWaterSource', JSON.stringify(waterSource));
  const isGreaterSydney = waterSource.water_source_id === constants.GREATER_SYDNEY_WSID;

  if (waterSource && waterSource.water_source_id) {
    let newPathname = window.location.pathname.replace(
      window.location.pathname.split('/')[1],
      getPathname(waterSource.water_source_id + ' ' + waterSource.water_source_name),
    );

    window.history.pushState(null, null, newPathname + window.location.hash);
    if (history) {
      const splittedPath = newPathname.split('/');
      if (splittedPath.length >= 2) {
        const secondNavigation = isGreaterSydney
          ? 'storage'
          : window.location.pathname.split('/')[2];
        history.push(
          buildUrl(
            getPathname(waterSource.water_source_id + ' ' + waterSource.water_source_name),
            `/${secondNavigation ? secondNavigation : 'updates'}`,
          ),
        );
      }
    }
  }
};
