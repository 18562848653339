// Library
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil, capitalize } from 'lodash';
import moment from 'moment';

// Style
import './dataSummaryTable.scss';

// Helper
import { formatNumberDecimals } from '../../helpers/Utils';
import { getCurrentDate } from '../../helpers/TimeUtils';

// Context
import { MobileContext } from '../../contexts/MobileContext';
import { MapContext } from '../../contexts/MapContext';
import { MiniAppContext } from '../../contexts/MiniAppContext';

// Component
import MapIcon from '../dataSummary/MapIcon';
import AlertLink from '../alert/AlertLink';
import AlertResponse from '../alert/AlertResponse';
import GaugeAlertModalBody from '../alert/GaugeAlertModalBody';
import DataQualityText from '../generalComponents/dataQuality/DataQualityText';

// Constant
import mapFilterConstant from '../../constants/MapFilterConstants';
import alertResponseText from '../../components/alert/AlertResponseText.json';
import apiConstants from '../../constants/WaterDataAPIConstants';

const { GAUGE, DAM, WEIR } = mapFilterConstant.MAP_POPUP_ITEMS;
const container = 'data-summary-table';
const MONETARY = 'price';

const DataSummaryTable = ({
  title = '',
  tableHeader,
  tableContent,
  dateFormat = 'MMM YYYY',
  allowAlert = false,
  stationData,
  page,
}) => {
  const { isMobile, isMedium } = useContext(MobileContext);
  const { toggleMarker } = useContext(MapContext);
  const { station } = useContext(MiniAppContext);

  const [showManageAlertResponse, setShowManageAlertResponse] = useState(false);
  const [responseType, setResponseType] = useState();

  const renderManageAlertResponse = responseType => {
    setShowManageAlertResponse(true);
    setResponseType(responseType);
  };

  const rowValue = (rowItem, item) => {
    if (item.value || !isNil(item.value)) {
      if (rowItem.dataKey && rowItem.dataKey.includes(MONETARY)) {
        return `$${formatNumberDecimals(item.value)}`;
      } else if (rowItem.rounded === false) {
        return Number(item.value.toFixed(2));
      } else {
        return formatNumberDecimals(item.value, false, false, rowItem.decimals);
      }
    }
    return '-';
  };

  const renderRowItem = (rowItem, index) => {
    const headerName = isMobile && rowItem.abbreviation ? rowItem.abbreviation : rowItem.name;
    const headerLabel =
      (page !== 'gauges' && (rowItem.noCap || rowItem.abbreviation)) ||
      headerName.toLowerCase() === 'ph'
        ? headerName
        : capitalize(headerName);
    const unit =
      rowItem.dataKey && rowItem.dataKey.includes(MONETARY) ? `$/${rowItem.unit}` : rowItem.unit;
    const headerUnit = rowItem.unit ? `(${unit})` : '';

    return (
      <div
        key={index}
        className={`${container}-content ${container}-grid ${
          rowItem.mobileHide && `${container}-mobile-hide`
        }`}
      >
        <div className={`${container}-content-header`}>{`${headerLabel} ${headerUnit}`}</div>
        {!isEmpty(rowItem.content) &&
          rowItem.content.map((item, index) => {
            return (
              <div key={`${item.date} ${index}`}>
                <DataQualityText qualityCode={item?.qualityCode}>
                  {rowValue(rowItem, item)}
                </DataQualityText>
              </div>
            );
          })}
      </div>
    );
  };

  const renderGraphHeader = () => {
    const valueDate = station?.date
      ? moment(station?.date, apiConstants.API_DATE_FORMAT).format('HH:mm DD MMM YY')
      : !isEmpty(station?.resources)
      ? moment(station?.resources[0].date, 'YYYY-MM-DD').format('MMM YY')
      : stationData?.date || getCurrentDate('MMM YY');
    const stationType = station?.station_type === WEIR.name ? DAM.name : GAUGE.name;

    return (
      <div className={`${container}-title`}>
        <div className={`${container}-title-main`}>
          {title}
          <br />
          <div className={`${container}-title-main-date`}>{`Updated: ${valueDate}`}</div>
        </div>
        <div className={`${container}-title-icon-block`}>
          {allowAlert && (
            <AlertLink
              modalType={'gauge-modal'}
              showManageAlertResponse={showManageAlertResponse}
              setShowManageAlertResponse={setShowManageAlertResponse}
              alertModal={
                <>
                  <AlertResponse
                    show={showManageAlertResponse}
                    responseTitleText={alertResponseText.update[responseType]}
                  />
                  {!showManageAlertResponse && (
                    <GaugeAlertModalBody
                      setManageAlertResponse={renderManageAlertResponse}
                      stationInfo={station}
                    />
                  )}
                </>
              }
            />
          )}
          <MapIcon
            onClick={() => {
              toggleMarker(station, stationType);
              window.scrollTo(0, 0);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={`${container}-background`}>
      {isMobile && page === 'gauges' && renderGraphHeader()}
      {isMobile && title !== '' && page !== 'gauges' && (
        <div className={`${container}-title-1`}>{title}</div>
      )}
      {tableContent && !isEmpty(tableContent) && (
        <div className={`${container}`}>
          <div className={`${container}-header ${container}-grid`}>
            <div className={`${container}-header-title`}>{!isMedium && tableHeader}</div>
            {!isEmpty(tableContent[0]) &&
              tableContent[0].content.map((item, itemIndex) => (
                <div key={`${item.date} ${itemIndex}`}>
                  {moment(item.date, apiConstants.API_DATE_FORMAT, true).isValid()
                    ? moment(item.date, apiConstants.API_DATE_FORMAT).format(dateFormat)
                    : moment(item.date, 'YYYY-MM-DD').format(dateFormat)}
                </div>
              ))}
          </div>
          {!isEmpty(tableContent) &&
            tableContent.map((rowItem, index) => renderRowItem(rowItem, index))}
        </div>
      )}
    </div>
  );
};

export default DataSummaryTable;

DataSummaryTable.propTypes = {
  title: PropTypes.string,
  tableHeader: PropTypes.any,
  tableContent: PropTypes.array,
  dateFormat: PropTypes.string,
  stationData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  allowAlert: PropTypes.bool,
  page: PropTypes.string,
};
