/* Library */
import React from 'react';
import PropTypes from 'prop-types';

/* Style */
import './statusItem.scss';

const container = 'status-item';
const StatusItem = ({ data, river, type, snapshotView = false }) => {
  const description = data && data.description;
  const id = data && data.id;
  const name = data && data.name;

  return (
    <div className={`${container}`}>
      {river && <div className={`${container}-title`}>{river}</div>}
      {id && (
        <div className={`${container}-status-container`}>
          <div
            className={`${container}-status-${
              type === 'droughtFloodRisk' ? id : id.charAt(id.length - 1)
            }`}
          >
            {name}
          </div>
        </div>
      )}
      {!snapshotView && description && <div className={`${container}-desc`}>{description}</div>}
    </div>
  );
};

export default StatusItem;

StatusItem.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  river: PropTypes.string,
  type: PropTypes.string,
  snapshotView: PropTypes.bool,
};
