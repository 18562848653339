// Libraries
import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';

// Styles
import './salinityBody.scss';

// Context
import { AppContext } from '../../contexts/AppContext';

// Components
import GeneralDropdown from '../dropDown/GeneralDropdown';
import Pagination from '../../components/generalPagination/Pagination';

// Helpers
import { getSalinityReports } from '../../helpers/ApiHelper';
import { getWaterYearList } from '../../helpers/TimeUtils';
import { sortArrayByKeyDesc } from '../../helpers/Utils';

// Assets
import externalLink from '../../image/icons/external-link.png';

// Constant
const container = 'salinity-body';
const RESULT_PER_PAGE = 10;

export default function SalinityBody() {
  const { waterSource } = useContext(AppContext);
  const [reports, setReports] = useState([]);
  const [activeYear, setActiveYear] = useState();
  const [message, setMessage] = useState('');
  const [curPage, setCurPage] = useState(1);
  const yearList = sortArrayByKeyDesc(getWaterYearList(2010), 'year').map(item => {
    return { id: item.year, name: item.year };
  });

  const getReports = async year => {
    setMessage('');
    const result = await getSalinityReports('hunter', year);
    if (isEmpty(result)) setMessage('No reports found for the selected year.');
    setReports(result);
  };

  // Life Cycle
  useEffect(() => {
    (async () => {
      getReports(moment().year());
    })();
  }, [waterSource]);

  const onYearChange = e => {
    setActiveYear(e);
    getReports(e.id);
  };

  const sliceResult = (items, page) => {
    return items.slice((page - 1) * RESULT_PER_PAGE, page * RESULT_PER_PAGE);
  };

  return (
    <div className={container}>
      <div className={`${container}-reports`}>
        <div className={`${container}-scheme-title`}>SCHEME REGISTER</div>
        <div className={`${container}-dropdown`}>
          <GeneralDropdown
            menuItem={yearList}
            onItemClick={e => onYearChange(e)}
            selectedItem={yearList.find(item =>
              activeYear ? item.id === activeYear.id : item.id === moment().year(),
            )}
          />
        </div>
        {sliceResult(reports, curPage).map((report, index) => (
          <div key={`report-link-${index}`} className={`${container}-reports-item row`}>
            <div className="col-6 ">{report.report_name.replace('.pdf', '')}</div>
            <div className="col-6 view-report">
              <a href={report.report_url} target="_blank" rel="noopener noreferrer">
                View report
                <img
                  src={externalLink}
                  className="external-link-icon"
                  alt="Link icon linking to the source of the rules"
                />
              </a>
            </div>
          </div>
        ))}
        <div className={`${container}-msg`}>{message}</div>
        {reports.length > 10 && (
          <Pagination
            onPageChange={setCurPage}
            totalCount={reports.length}
            currentPage={curPage}
            pageSize={RESULT_PER_PAGE}
          />
        )}
      </div>
    </div>
  );
}
