// Libraries
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isEmpty, isArray } from 'lodash';
import { Tooltip, XAxis, Legend, YAxis, ReferenceLine, LineChart, Line } from 'recharts';

// Context
import { AppContext } from '../../contexts/AppContext';
import { MobileContext } from '../../contexts/MobileContext';

// Styles
import './averageMonthlyStorageFinancialYear.scss';
import '../storage/storageChart.scss';

// Helper
import { Event } from '../../helpers/GAHelper';
import { getDamResourcesByWaterSourceId } from '../../helpers/ApiHelper';
import {
  genGraphDataForFiscalYear,
  getListDamsDropDown,
  parserDamsDataPerFY,
} from '../../helpers/ProcessDataHelper';
import { genListOfFiscalYears, formatNumberDecimals } from '../../helpers/Utils';

// Components
import StorageChartDropdown from '../storage/childProperties/StorageChartDropdown';
import YearMultiSelector from '../yearMultiSelector/YearMultiSelector';
import SelectionBtnsGroup from '../storage/childProperties/SelectionBtnsGroup';
import ChartContainer from '../chartProperties/ChartContainer';
import ChartResponsiveContainer from '../chartProperties/ChartResponsiveContainer';
import DownloadLink from '../chartProperties/ChartCSVDownloadLink';

// Constants
import constants from '../../constants/Constants';
import text from './research.json';

export default function AverageMonthlyStorageFinancialYear(props) {
  const { waterSource } = useContext(AppContext);
  const { isMobile } = useContext(MobileContext);

  const waterSourceId = waterSource.water_source_id;
  const isMurray = [constants.MURRAY_REGULATED_RIVER_WATER_SOURCE].includes(waterSourceId);

  const [listOfYears, setListOfYears] = useState(genListOfFiscalYears('storage', waterSourceId));
  const defaultYears = listOfYears.slice(0, 2);
  const [emptyChart, setEmptyChart] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [activeYears, setActiveYears] = useState(defaultYears.map(year => year.value));
  const [activeDams, setActiveDams] = useState();
  const [fullVolume, setFullVolume] = useState();
  const [damListData, setDamListData] = useState([]);
  const [refLineLabel, setRefLineLabel] = useState('Accessible capacity');

  useEffect(() => {
    if (props) {
      const allDams = waterSource.dams.map(dam => dam.station_id);
      setActiveDams(allDams);
      setFullVolume(props.aggregatedFullVolume);
      if (allDams.length === 1) {
        setRefLineLabel(
          `${constants.STORAGE_GRAPH_COMMENTARY[allDams[0]] ? '*' : ''}Accessible capacity`,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterSourceId]);

  useEffect(() => {
    (async () => {
      if (!isEmpty(listOfYears) && !isEmpty(activeDams)) {
        const yearsParam = listOfYears.map(year => {
          return year.value.split(':')[1];
        });
        const chartDataRes = await getDamResourcesByWaterSourceId(
          waterSourceId,
          null,
          null,
          yearsParam.join(),
        );
        const parsedDamsData = parserDamsDataPerFY(chartDataRes.data, activeDams);
        const formattedData = genGraphDataForFiscalYear(
          parsedDamsData instanceof Error ? [] : parsedDamsData.data,
          true,
        );
        if (isArray(formattedData)) {
          setListOfYears(
            listOfYears.filter(year =>
              Object.prototype.hasOwnProperty.call(formattedData[0], [year.value]),
            ),
          );
        }
        setChartData(formattedData);
        setEmptyChart(false);
        setDamListData(getListDamsDropDown(waterSource.dams, chartDataRes));
      } else {
        setEmptyChart(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterSourceId, activeDams]);

  const onDamChange = selectedDams => {
    const activeDams = selectedDams.split(',');
    setActiveDams(activeDams);
    setRefLineLabel(
      `${constants.STORAGE_GRAPH_COMMENTARY[activeDams[0]] ? '*' : ''}Accessible capacity`,
    );
    if (activeDams.length > 1) {
      setFullVolume(props.aggregatedFullVolume);
    } else {
      setFullVolume(waterSource.dams.find(dam => activeDams.includes(dam.station_id)).full_volume);
    }
    Event(
      waterSource.water_source_name,
      `/storage - ` +
        waterSource.dams
          .filter(dam => activeDams.includes(dam.station_id))
          .map(dam => dam.station_name)
          .join(','),
      constants.INTERACTION_TYPE.button,
    );
  };

  const renderReferenceLineLabel = ({ viewBox }) => {
    const x = isMobile ? viewBox.width / 4 : viewBox.width / 2;
    const y = viewBox.y + 20;
    return (
      <text className="svg-reference-line-label" x={x} y={y}>
        {constants.TOTAL_STORAGE_MENINDEE_LAKES_FLAG
          ? (activeDams.length === 1 &&
              Number(activeDams[0]) === constants.MENINDEE_LAKES_TOTAL_ID) ||
            waterSourceId === constants.LOWER_DARLING_REGULATED_RIVER_WATER_SOURCE
            ? 'Total capacity'
            : activeDams.length > 1 && isMurray
            ? 'Storage capacity'
            : refLineLabel
          : refLineLabel}
      </text>
    );
  };

  const onYearChange = async value => {
    if (value && value.length) {
      setActiveYears(value.map(year => year.value));
      Event(
        waterSource.water_source_name,
        '/storage - year selection: ' + value.map(FY => FY.label).join(',') + ' years',
        constants.INTERACTION_TYPE.button,
      );
    } else {
      setActiveYears([]);
    }
  };

  const drawLinesForStorage = () => {
    let dataSet = !emptyChart ? chartData[0] || [] : [];
    let lineArr = [];
    let colorCodes = [
      '#0054a6',
      '#f36c21',
      '#bed12a',
      '#ffcd34',
      '#63cbe8',
      '#91b0c1',
      '#0054a6',
      '#f36c21',
      '#bed12a',
      '#ffcd34',
      '#63cbe8',
      '#91b0c1',
    ];
    Object.keys(dataSet).forEach(yearKey => {
      for (let j in activeYears) {
        const year = activeYears[j];
        if (yearKey === activeYears[j]) {
          const colorIndex = activeYears.indexOf(yearKey);
          lineArr.push(
            <Line
              name={year.replace(':', '/')}
              strokeWidth={2}
              type="monotone"
              dataKey={year}
              stroke={colorCodes[colorIndex]}
              key={`line-chart-${colorIndex}`}
              dot={false}
            />,
          );
        }
      }
    });

    return lineArr;
  };

  let damsSelectionBtn = !isEmpty(damListData) && (
    <StorageChartDropdown
      dams={damListData}
      onDamChange={onDamChange}
      selected={activeDams.join(',')}
    />
  );
  let fySelectionBtn = (
    <YearMultiSelector
      defaultYears={defaultYears}
      listOfYears={listOfYears}
      onYearChange={onYearChange}
    />
  );

  return (
    <ChartContainer
      pageTitle={text.averageMonthlyStorageFinancialYear.title}
      graphTitle={text.averageMonthlyStorageFinancialYear.title}
      graphIntro={
        isMurray
          ? `The dams store water for NSW, Victoria and South Australia. But ${text.averageMonthlyStorageFinancialYear.intro.toLowerCase()}`
          : text.averageMonthlyStorageFinancialYear.intro
      }
    >
      {!isMobile && (
        <DownloadLink
          data={chartData}
          filename={activeDams && activeDams.join('_')}
          stationType="dam"
          dataType="DAM RESOURCES"
        />
      )}
      <SelectionBtnsGroup left={damsSelectionBtn} right={fySelectionBtn} />
      <ChartResponsiveContainer
        customHeight={{ desktop: 450, mobile: 350 }}
        isEmptyChart={isEmpty(chartData)}
        commentary={
          activeDams && activeDams.length > 0
            ? constants.STORAGE_GRAPH_COMMENTARY[activeDams[0]]
            : ''
        }
      >
        <LineChart
          width={800}
          data={chartData}
          margin={{ top: isMobile ? 20 : 10, left: 20, bottom: 50 }}
        >
          <XAxis dataKey="month" tick={{ fill: 'black' }} angle={-60} dy={25} dx={-5} />
          <YAxis
            interval="preserveStartEnd"
            domain={[0, props.aggregatedFullVolume]}
            width={isMobile ? 15 : 60}
            angle={isMobile ? -60 : 0}
            label={isMobile ? '' : { value: 'Storage volume (GL)', angle: -90, dx: -35 }}
            tickFormatter={v => formatNumberDecimals(Math.round(v / 1000))}
            tick={{ fill: 'black' }}
          />
          {drawLinesForStorage()}
          <Tooltip
            labelFormatter={date => moment(date, 'YYYY-MMM').format('MMMM')}
            formatter={(value, name) => [formatNumberDecimals(Number(value / 1000)) + ' GL', name]}
          />
          <Legend wrapperStyle={{ bottom: isMobile ? 0 : -10 }} align="center" iconSize={30} />
          <ReferenceLine
            y={fullVolume}
            label={renderReferenceLineLabel}
            stroke="red"
            strokeDasharray="3 3"
          />
        </LineChart>
      </ChartResponsiveContainer>
    </ChartContainer>
  );
}

AverageMonthlyStorageFinancialYear.propTypes = {
  aggregatedFullVolume: PropTypes.number,
  dams: PropTypes.array,
  desc: PropTypes.string,
};
