// Library
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import * as d3 from 'd3';
import { isEmpty, isNil } from 'lodash';

// Style
import './interValleyTrade.scss';

// Component
import MapLegendLineItem from '../../mapProperties/MapLegendLineItem';
import Modal from '../../modal/Modal';
import ParagraphSection from '../../paragraphSection/ParagraphSection';
import ArrowSVG from '../../../image/icons/ArrowSVG';

// Helpers
import { formatNumberDecimals, replaceWaterSourceLiteral } from '../../../helpers/Utils';

// Context
import { AppContext } from '../../../contexts/AppContext';
import { MobileContext } from '../../../contexts/MobileContext';

// Assest
import InfoButton from '../../informationButton/InformationButton';
import text from './tradingText.json';

// Constant
const container = 'inter-valley-trade';
const CHORD_RADIUS = 180;

const InterValleyTrade = ({ data, colors, setIsInterValley, setSortedNameList }) => {
  const [chordMatrix, setChordMatrix] = useState([]);
  const [nameList, setNameList] = useState([]);
  const [sourceWS, setSourceWS] = useState(null);
  const [targetWS, setTargetWS] = useState(null);
  const [curWS, setCurWS] = useState(-1);
  const { waterSource } = useContext(AppContext);
  const { isLarge } = useContext(MobileContext);
  const { interValley } = text;

  // Life Cycle
  useEffect(() => {
    sortMatrixData(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (!isEmpty(chordMatrix) && !isEmpty(nameList) && curWS !== -1) {
      // create the svg area
      const svg = d3
        .select(`.${container}-chord`)
        .append('svg')
        .attr('width', 480)
        .attr('height', 440)
        .append('g')
        .attr('transform', 'translate(240,220)');

      // give this matrix to d3.chord(): it will calculates all the info we need to draw arc and ribbon
      const res = d3.chord().padAngle(0.05).sortSubgroups(d3.descending).sortChords(d3.descending)(
        chordMatrix,
      );

      // On Click Event
      const mouseover = function () {
        let source;
        let target;
        d3.select(this).style('opacity', d => {
          source = d.source.index;
          target = d.target.index;
          return isMainWaterSource(d, curWS) ? 0.75 : 0;
        });

        if (source === curWS || target === curWS) {
          setSourceWS(source);
          setTargetWS(target);
        }
      };

      const mouseleave = function () {
        d3.select(this).style('opacity', d => (isMainWaterSource(d, curWS) ? 0.5 : 0));
      };

      // Add the links between groups
      svg
        .datum(res)
        .append('g')
        .selectAll('path')
        .data(d => d)
        .enter()
        .append('path')
        .attr('d', d3.ribbon().radius(CHORD_RADIUS))
        .style('fill', function (d) {
          return d3.color(colors[[d.source.index]]);
        })
        .style('stroke', 'none')
        .style('opacity', d => (isMainWaterSource(d, curWS) ? 0.5 : 0))
        .on('mouseover', mouseover)
        .on('mouseleave', mouseleave);

      /// this group object use each group of the data.groups object
      const group = svg
        .datum(res)
        .append('g')
        .selectAll('g')
        .data(function (d) {
          return d.groups;
        })
        .enter();

      // add the group arcs on the outer part of the circle
      group
        .append('g')
        .append('path')
        .style('fill', function (d, i) {
          return colors[i];
        })
        .style('stroke', 'none')
        .attr(
          'd',
          d3
            .arc()
            .cornerRadius(2)
            .innerRadius(CHORD_RADIUS)
            .outerRadius(CHORD_RADIUS + 20),
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterSource, chordMatrix, nameList, curWS]);

  // Helpers
  const sortMatrixData = (data, unmounted) => {
    const waterSourceIndexList = {};
    const matrixWidth = data.length;
    let minVolume;
    let maxVolume;

    const matrix = new Array(matrixWidth).fill([]);

    data.forEach(ws => {
      const { water_source_name, resources } = ws;
      if (isNil(waterSourceIndexList[water_source_name])) {
        waterSourceIndexList[water_source_name] = Object.keys(waterSourceIndexList).length;
      }
      const matrixRow = new Array(matrixWidth).fill(0);

      resources.forEach(res => {
        const { dest_water_source_name, export_volume = 0 } = res;
        if (isNil(waterSourceIndexList[dest_water_source_name])) {
          waterSourceIndexList[dest_water_source_name] = Object.keys(waterSourceIndexList).length;
        }
        matrixRow[waterSourceIndexList[dest_water_source_name]] = export_volume;

        if (isNil(maxVolume) || export_volume > maxVolume) maxVolume = export_volume;
        if (isNil(minVolume) || export_volume < minVolume) minVolume = export_volume;
      });

      matrix[waterSourceIndexList[water_source_name]] = matrixRow;
    });
    const waterSourceNames = Object.keys(waterSourceIndexList).sort(
      (a, b) => waterSourceIndexList[a] - waterSourceIndexList[b],
    );

    if (!unmounted) {
      setChordMatrix(matrix);
      const formattedNameList = waterSourceNames.map(item => {
        return replaceWaterSourceLiteral(item);
      });
      setNameList(formattedNameList);
      setSortedNameList(formattedNameList);
      const targetWS = waterSourceNames.findIndex(name => name === waterSource.water_source_name);
      setIsInterValley(targetWS !== -1);
      setCurWS(targetWS);
    }
  };

  const isMainWaterSource = (d, curWSIndex) => {
    return d.source.index === curWSIndex || d.target.index === curWSIndex;
  };

  // Component
  const modalMaker = () => {
    return (
      <>
        {interValley.modal.map((section, index) => {
          return (
            <ParagraphSection
              key={index}
              sectionTitle={section.paragraphTitle}
              sectionContent={section.paragraph}
            />
          );
        })}
      </>
    );
  };

  return (
    <>
      {!isLarge && curWS !== -1 && (
        <div className={`${container}`}>
          <div className={`${container}-header`}>
            <Modal id={`interValleyTrade`} title={interValley.title} body={modalMaker()} />
            <div className={`${container}-header-title`}>
              <div className={`${container}-header-title-text`}>{interValley.title}</div>
              <InfoButton
                targetDataId={`#interValleyTrade`}
                iconColor={`${container}-header-title-icon`}
              />
            </div>
          </div>
          <div className={`${container}-intro`}>{interValley.intro}</div>
          <div className={`${container}-content`}>
            {!isEmpty(chordMatrix) && !isEmpty(nameList) && (
              <div className={`${container}-chart`}>
                <div className={`${container}-chord`}></div>
              </div>
            )}
            <div className={`${container}-desc`}>
              {!isNil(sourceWS) && !isNil(targetWS) && (
                <div className={`${container}-desc-rel`}>
                  <div className={`${container}-desc-rel-item`}>
                    <div className={`${container}-desc-rel-left`}>
                      <div
                        className={`${container}-desc-rel-label`}
                        style={{
                          color: colors[nameList.indexOf(nameList[sourceWS])],
                        }}
                      >
                        {nameList[sourceWS]}
                      </div>
                      <ArrowSVG customArrowColor={`${container}-desc-rel-arrow`} />
                      <div
                        className={`${container}-desc-rel-label`}
                        style={{
                          color: colors[nameList.indexOf(nameList[targetWS])],
                        }}
                      >
                        {nameList[targetWS]}
                      </div>
                    </div>
                    <div className={`${container}-desc-rel-right`}>
                      <div className={`${container}-desc-rel-value`}>
                        {`${formatNumberDecimals(chordMatrix[sourceWS][targetWS])} ML`}
                      </div>
                    </div>
                  </div>
                  <div className={`${container}-desc-rel-item`}>
                    <div className={`${container}-desc-rel-left`}>
                      <div
                        className={`${container}-desc-rel-label`}
                        style={{
                          color: colors[nameList.indexOf(nameList[targetWS])],
                        }}
                      >
                        {nameList[targetWS]}
                      </div>
                      <ArrowSVG customArrowColor={`${container}-desc-rel-arrow`} />
                      <div
                        className={`${container}-desc-rel-label`}
                        style={{
                          color: colors[nameList.indexOf(nameList[sourceWS])],
                        }}
                      >
                        {nameList[sourceWS]}
                      </div>
                    </div>
                    <div className={`${container}-desc-rel-right`}>
                      <div className={`${container}-desc-rel-value`}>
                        {`${formatNumberDecimals(chordMatrix[targetWS][sourceWS])} ML`}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!isEmpty(nameList) && (
                <div className={`${container}-desc-legends`}>
                  {nameList.map((name, i) => {
                    return (
                      <MapLegendLineItem
                        key={name}
                        strokeColor={colors[i]}
                        strokeWidth={'12'}
                        legendText={name}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InterValleyTrade;

InterValleyTrade.propTypes = {
  data: PropTypes.array,
  colors: PropTypes.array,
  setIsInterValley: PropTypes.func,
  setSortedNameList: PropTypes.func,
};
