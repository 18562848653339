// Library
import React from 'react';
import PropTypes from 'prop-types';

// Style
import './feedbackResponse.scss';

// Constant
const container = 'feedback-form-response';
const successMsg =
  'Thank you for submitting your feedback. This will help us to provide a better experience for you in the future.';
const failMsg =
  'We are experiencing some technical difficulties, and we are unable to submit your feedback. Please come back later and try again. Sorry for the inconvenience.';

const FeedbackResponse = ({ show, responseType }) => {
  return (
    <>
      {show && (
        <div className={`${container} ${show && `${container}-show`}`}>
          <div className={`${container}-text`}>
            {responseType === 'success' ? successMsg : failMsg}
          </div>
        </div>
      )}
    </>
  );
};

export default FeedbackResponse;

FeedbackResponse.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func,
  responseType: PropTypes.string,
};
