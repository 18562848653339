// Libraries
import React from 'react';

// Images
import InfoIconLarge from '../../../image/icons/infoLarge.svg';

// Data
import data from './data.json';

// Styles
import './error.scss';

export default function Error() {
  return (
    <div className="row error-container">
      <img className="error-img" src={InfoIconLarge} alt={`Error for ${data.genericError.title}`} />
      <div className="row error-body">
        <div className="error-title">{data.genericError.title}</div>
        {data.genericError.text.map((line, index) => (
          <p key={index} className="error-text">
            {line}
          </p>
        ))}
        <a
          className="error-link"
          href={`https://${data.genericError.link}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {data.genericError.link}
        </a>
      </div>
    </div>
  );
}
