// Library
import React from 'react';
import PropTypes from 'prop-types';

// Style
import './dailyUpdateItem.scss';

// Helper
import { formatNumberDecimals } from '../../../helpers/Utils';

export default function DailyUpdateItem({
  value,
  valueUnit,
  labelText,
  isPrimary = false,
  isForWaterSource = false,
  rounded = false,
  isLatest = false,
  singleDecimal = false,
}) {
  const container = isForWaterSource ? 'ws-daily-update-item' : 'daily-update-item';

  return (
    <div className={`${container}`}>
      <div className={`row fluid ${container}-content`}>
        <div className={`${container}-unit-div`}>
          <div
            className={`${container}-value${isPrimary && !isForWaterSource ? '-primary' : ''}`}
          >{`${formatNumberDecimals(value, rounded, singleDecimal)}`}</div>
          {value !== '-' && (
            <div
              className={`${container}-value${
                isPrimary && !isForWaterSource ? '-primary' : ''
              }-unit`}
            >
              &nbsp;{valueUnit}
            </div>
          )}
          {value !== '-' && isLatest && <div className={`${container}-asterisk`}>*</div>}
        </div>
        <div
          className={`${container}-label${isPrimary && !isForWaterSource ? '-primary' : ''}`}
        >{`${labelText}`}</div>
      </div>
    </div>
  );
}

DailyUpdateItem.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueUnit: PropTypes.string,
  labelText: PropTypes.string,
  isPrimary: PropTypes.bool,
  isForWaterSource: PropTypes.bool,
  isLatest: PropTypes.bool,
};
