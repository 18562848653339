// Libraries
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// Styles
import './socialLinkItem.scss';

// Context
import { MobileContext } from '../../contexts/MobileContext';

export default function SocialLinkItem({ link, imgAlt, icon, onLinkClick }) {
  const { isMobile } = useContext(MobileContext);

  return (
    <div className="social-link-item">
      <a
        className="social-link-link"
        data-toggle={isMobile ? 'modal' : ''}
        data-target={isMobile ? '#externalLinkFooter' : ''}
        onClick={onLinkClick ? () => onLinkClick(link) : () => {}}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img alt={imgAlt} src={icon} />
      </a>
    </div>
  );
}

SocialLinkItem.propTypes = {
  link: PropTypes.string,
  imgAlt: PropTypes.string,
  icon: PropTypes.string,
  onLinkClick: PropTypes.func,
};
