// Libraries
import moment from 'moment';

// Assets
import DamIcon from '../image/icons/dam.png';
import GaugeIcon from '../image/icons/mapMarker/gauge.svg';
import EnvironmentIcon from '../image/icons/environment.png';
import ConstraintIcon from '../image/icons/mapMarker/constraints.png';
import RainfallIcon from '../image/icons/rainfall.png';
import DifferenceIcon from '../image/icons/difference.png';
import PumpIcon from '../image/icons/pump.png';
import constants from './Constants';
import DroughtStatusIcon from '../image/icons/droughtStatus.png';
import FlowRateIcon from '../image/icons/flowRate.png';
import WaterQualityIcon from '../image/icons/waterQuality.png';
import EventsIcon from '../image/icons/mapMarker/events.png';

const addMapFilter = () => {
  let result = {};
  const urlParams = new URLSearchParams(window.location.search);
  const date = urlParams.get('display_pumps');
  const isParamDateToday = moment(date, 'YYYYMMDD').isSame(moment().format('YYYYMMDD'));

  if (constants.SHOW_PUMPS || isParamDateToday) {
    result = {
      PUMP: {
        type: 'pump',
        selected: false,
        icon: PumpIcon,
        text: 'PUMP',
        className: 'map-filter-pumps',
      },
    };
  }
  return result;
};

const MAP_FILTERS = {
  WEIR: {
    name: 'weir',
    label: 'weir',
    siteType: 'Weirs',
    variables: ['TotalStorageVolume', 'StorageWaterLevel'],
  },
  BORE: {
    name: 'bore',
    label: 'monitoring bore',
    siteType: 'Groundwater',
    variables: ['GroundwaterDepthBelowSurfaceLevel'],
  },
  STATIONS: { name: 'stations', label: 'stations' },
  GAUGE: {
    name: 'gauge',
    label: 'gauge',
    type: '1',
    siteType: 'StreamGauge',
    variables: ['FlowRate', 'StreamWaterLevel', 'pH', 'Conductivity', 'WaterTemperature'],
  },
  DAM: {
    name: 'dam',
    label: 'dam',
    type: '4',
    siteType: 'Storage',
    variables: ['volume', 'level', 'inflow', 'Percent full'],
  },
  OUTAGE: { name: 'outage', label: 'maintenance' },
  RECREATION: { name: 'recreation', label: 'recreational event' },
  CONSTRAINTS: { name: 'constraints', label: 'constraint', type: '4' },
  ENVIRONMENT: { name: 'environment', label: 'operational flow target', type: '3' },
  PUMP: { name: 'pump', label: 'pump' },
  RAINFALL: { name: 'rainfall', label: 'rainfall', type: '5' },
  ORDER_USAGE: { name: 'order-usage', label: 'order and usage' },
  FLOW_RATE: { name: 'flow-rate', label: 'flow rate' },
  DROUGHT_STATUS: { name: 'drought-status', label: 'drought status' },
  WATER_QUALITY: { name: 'water-quality', label: 'water quality' },
  EVENTS: { name: 'events', label: 'events' },
};

const MAP_FILTERS_OPTIONS = {
  DAM: {
    type: MAP_FILTERS.DAM.name,
    selected: true,
    icon: DamIcon,
    text: 'STORAGE',
    className: 'map-filter-dam-storage',
  },
  WEIR: {
    type: MAP_FILTERS.DAM.name,
    selected: true,
    icon: DamIcon,
    text: 'STORAGE',
    className: 'map-filter-dam-storage',
  },
  GAUGE: {
    type: MAP_FILTERS.GAUGE.name,
    selected: true,
    icon: GaugeIcon,
    text: { REG: 'GAUGE', UNREG: 'GAUGE', GW: 'BORE', MIX: 'GAUGE / BORE' },
    className: 'map-filter-river-gauge',
  },
  ...addMapFilter(),
  ENVIRONMENT: {
    type: MAP_FILTERS.ENVIRONMENT.name,
    selected: false,
    icon: EnvironmentIcon,
    text: 'OPERATIONAL FLOW TARGET',
    className: 'map-filter-environment',
  },
  CONSTRAINTS: {
    type: MAP_FILTERS.CONSTRAINTS.name,
    selected: false,
    icon: ConstraintIcon,
    text: 'MAX FLOWS',
    className: 'map-filter-constraints',
  },
  RAINFALL: {
    type: MAP_FILTERS.RAINFALL.name,
    selected: false,
    icon: RainfallIcon,
    text: 'CLIMATE',
    className: 'map-filter-rainfall',
  },
  'ORDER-USAGE': {
    type: MAP_FILTERS.ORDER_USAGE.name,
    selected: false,
    icon: DifferenceIcon,
    text: 'ORDER AND USAGE',
    className: 'map-filter-order-usage',
    legend: [
      { text: 'Excess water', strokeColor: '#004ba3' },
      { text: 'Normal', strokeColor: '#0087ad' },
      { text: 'Water deficit', strokeColor: '#76dcf8' },
    ],
  },
  'FLOW-RATE': {
    type: MAP_FILTERS.FLOW_RATE.name,
    selected: false,
    icon: FlowRateIcon,
    text: 'FLOW RATE',
    className: 'map-filter-flow-rate',
  },
  'DROUGHT-STATUS': {
    type: MAP_FILTERS.DROUGHT_STATUS.name,
    selected: false,
    icon: DroughtStatusIcon,
    text: 'DROUGHT STATUS',
    className: 'map-filter-drought-status',
  },
  'WATER-QUALITY': {
    type: MAP_FILTERS.WATER_QUALITY.name,
    selected: false,
    icon: WaterQualityIcon,
    text: 'WATER QUALITY',
    className: 'map-filter-water-quality',
  },
  EVENTS: {
    type: MAP_FILTERS.EVENTS.name,
    selected: false,
    icon: EventsIcon,
    text: 'EVENTS',
    className: 'map-filter-events',
  },
};

const BASE_MAP_FILTERS_OPTIONS = [
  MAP_FILTERS_OPTIONS.GAUGE,
  MAP_FILTERS_OPTIONS.PUMP,
  MAP_FILTERS_OPTIONS.RAINFALL,
];

export default Object.freeze({
  MAP_FILTERS_OPTIONS: MAP_FILTERS_OPTIONS,
  MAP_FILTERS_BY_WATER_SOURCE_TYPE: {
    REG: [
      MAP_FILTERS_OPTIONS.DAM,
      ...BASE_MAP_FILTERS_OPTIONS,
      MAP_FILTERS_OPTIONS['WATER-QUALITY'],
      MAP_FILTERS_OPTIONS.CONSTRAINTS,
      MAP_FILTERS_OPTIONS.EVENTS,
      MAP_FILTERS_OPTIONS.ENVIRONMENT,
    ],
    UNREG: [...BASE_MAP_FILTERS_OPTIONS, MAP_FILTERS_OPTIONS['WATER-QUALITY']],
    GW: BASE_MAP_FILTERS_OPTIONS,
    MIX: [...BASE_MAP_FILTERS_OPTIONS, MAP_FILTERS_OPTIONS['WATER-QUALITY']],
  },
  MAP_FILTERS_FOR_ORDER_USAGE: MAP_FILTERS_OPTIONS['ORDER-USAGE'],
  MAP_FILTERS_FOR_FLOW_RATE: MAP_FILTERS_OPTIONS['FLOW-RATE'],
  MAP_FILTERS_FOR_RAINFALL: MAP_FILTERS_OPTIONS.RAINFALL,
  FLOW_CLASS_LEGEND: [
    {
      text: 'No flow class',
      strokeColor: '#9c9c9c',
      currentFlowClass: 'NO_FLOW_CLASS',
      flowClassStatus: 'Do Not Pump',
      strokeWidth: '20',
    },
    {
      text: 'Low flow class',
      strokeColor: '#be7950',
      currentFlowClass: 'LOW_FLOW_CLASS',
      flowClassStatus: 'Basic Rights',
      strokeWidth: '20',
    },
    {
      text: 'A class',
      strokeColor: '#ffa367',
      currentFlowClass: 'A_CLASS',
      flowClassStatus: 'UNREGULATED RIVER (A CLASS)',
      strokeWidth: '20',
    },
    {
      text: 'B class',
      strokeColor: '#ffda65',
      currentFlowClass: 'B_CLASS',
      flowClassStatus: 'UNREGULATED RIVER (B CLASS)',
      strokeWidth: '20',
    },
    {
      text: 'C class',
      strokeColor: '#82dd73',
      currentFlowClass: 'C_CLASS',
      flowClassStatus: 'UNREGULATED RIVER (C CLASS)',
      strokeWidth: '20',
    },
  ],
  RESTRICTION_TYPE_LEGEND: [
    {
      text: 'S324',
      strokeColor: 'black',
      layerId: '1',
      strokeWidth: '4',
    },
    {
      text: 'Resumption of Flows',
      strokeColor: '#0070ff',
      layerId: '2',
      strokeWidth: '4',
    },
  ],
  MAP_POPUP_ITEMS: MAP_FILTERS,
  VOLUME: 'volume',
  RAINFALL_MAP_LEGEND_CONFIG: {
    observed: {
      title: 'Observed rainfall',
      scale: [0, 5, 10],
      scaleLabel: ['Very much below average', 'Very much above average'],
      colorBarClassName: 'observed-color-bar',
    },
    outlook: {
      title: 'Probability of above median rainfall (%)',
      scale: [20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80],
      scaleLabel: [],
      colorBarClassName: 'outlook-color-bar',
      scaleColor: [
        'rgb(150, 55, 30)',
        'rgb(180, 74, 39)',
        'rgb(206, 101, 47)',
        'rgb(221, 149, 73)',
        'rgb(254, 205, 101)',
        'rgb(255, 255, 255)',
        'rgb(255, 255, 255)',
        'rgb(255, 255, 255)',
        'rgb(255, 255, 255)',
        'rgb(235, 245, 236)',
        'rgb(158, 210, 187)',
        'rgb(114, 165, 169)',
        'rgb(65, 122, 152)',
        'rgb(17, 79, 140)',
      ],
    },
    'observed-temp': {
      title: 'Observed temperature',
      scale: [0, 5, 10],
      scaleLabel: ['Very much below average', 'Very much above average'],
      colorBarClassName: 'observed-temp-color-bar',
    },
    'outlook-temp': {
      title: 'Probability of above median temperature (%)',
      scale: [20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80],
      scaleLabel: [],
      colorBarClassName: 'outlook-color-bar',
      scaleColor: [
        'rgb(69,159,177)',
        'rgb(111,172,191)',
        'rgb(141,185,196)',
        'rgb(171,198,207)',
        'rgb(198,217,220)',
        'rgb(235,240,243)',
        'rgb(255, 255, 255)',
        'rgb(255, 255, 255)',
        'rgb(241,237,236)',
        'rgb(227,186,192)',
        'rgb(212,147,154)',
        'rgb(199,110,119)',
        'rgb(189,74,84)',
        'rgb(178,32,59)',
      ],
    },
  },
});
