// Libraries
import React, { useContext, useState } from 'react';

// Styles
import './footer.scss';

// Components
import NavLinkItem from '../../components/navLinkItems/NavLinkItem';
import SocialLinkItem from '../../components/navLinkItems/SocialLinkItem';
import ExternalLinkModal from '../../components/modal/ExternalLinkModal';

// Context
import { MobileContext } from '../../contexts/MobileContext';

// Constants
import constants from '../../constants/Constants';

export default function Footer() {
  const { isMobile } = useContext(MobileContext);
  const [externalSiteLink, setExternalSiteLink] = useState('');

  return (
    <footer className="footer-bar">
      <div className="footer-link-item-group">
        {constants.FOOTER_LINK_ITEMS.map((i, index) => (
          <NavLinkItem
            key={index}
            title={i.title}
            link={i.link}
            isExternal={i.external}
            onLinkClick={link => setExternalSiteLink(link)}
          />
        ))}
      </div>
      <div className="footer-social-link-item-group">
        {constants.FOOTER_SOCIAL_LINK_ITEMS.map((i, index) => (
          <SocialLinkItem
            key={index}
            icon={i.icon}
            link={i.link}
            onLinkClick={link => setExternalSiteLink(link)}
          />
        ))}
      </div>
      {isMobile && <ExternalLinkModal link={externalSiteLink} modalId="externalLinkFooter" />}
    </footer>
  );
}
