// Library
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isNil, isObject } from 'lodash';

// Component
import DataQualityText from '../../generalComponents/dataQuality/DataQualityText';

// Assests
import gaugeIcon from '../../../image/icons/mapMarker/gauge.svg';
import damIcon from '../../../image/icons/dam.svg';
import weirIcon from '../../../image/icons/mapMarker/weir.svg';
import dischargeIcon from '../../../image/icons/mapMarker/discharge-site.png';

// Helpers
import { formatNumberDecimals } from '../../../helpers/Utils';

// Constant
import apiDataConstants from '../../../constants/WaterDataAPIConstants';
import hydroDataConstants from '../../../constants/HydrometricsConstants';
const GAUGE_LOCATION_TYPE = 'gauge';

const STATIONTYPE = {
  gauge: {
    color: '#4b555a',
    icon: gaugeIcon,
    adjustment: {
      x: 1,
      y: 0,
    },
  },
  dam: {
    color: '#0054a6',
    icon: damIcon,
    adjustment: {
      x: -2,
      y: -8,
    },
  },
  weir: {
    color: '#0054a6',
    icon: weirIcon,
    adjustment: {
      x: 5,
      y: 0,
    },
  },
  discharge: {
    color: '#3680A0',
    icon: dischargeIcon,
    adjustment: {
      x: 5,
      y: 0,
    },
  },
};

const RiverStation = ({
  x,
  y,
  name,
  value,
  unit,
  fullPercentage,
  locationType,
  url,
  background,
  detailsLoading,
  stateView = false,
}) => {
  // Ref
  const nameEl = useRef(null);
  const valueEl = useRef(null);
  // State
  const [nameWidth, setNameWidth] = useState(0);
  const [valueWidth, setValueWidth] = useState(0);
  const [totalWidth, setTotalWidth] = useState(0);
  // Constant
  const curStationType = STATIONTYPE[locationType];
  const { icon, color, adjustment } = curStationType;
  const height = 100;
  const buffer = 20;
  const iconWidth = 25;
  const coord = { x: x - totalWidth / 2, y: y - height / 4 };
  const isValueObject = isObject(value);
  const isInvalidNumber = isValueObject
    ? isNil(value?.value) || isNaN(value?.value)
    : isNil(value) || isNaN(value);

  // Helper
  const getDisplayValue = (percentageValue, value, dataType) => {
    if (stateView && percentageValue) {
      return `${formatNumberDecimals(percentageValue, null, true)}%`;
    }
    if (isInvalidNumber) {
      return ' - ';
    }
    const decimals =
      hydroDataConstants.DATA_LABEL_FORMAT[apiDataConstants.HYDROMETRIC_VARIABLES_MAP[dataType]]
        ?.decimals;
    const formattedValue = stateView && locationType === 'dam' ? value / 1000 : value;
    let valueString = `${formatNumberDecimals(formattedValue, false, false, decimals)} ${unit}`;
    if (locationType === 'dam' && percentageValue) {
      valueString += ` | ${formatNumberDecimals(percentageValue, null, true)}%`;
    }
    return valueString;
  };

  // Life Cycle
  useEffect(() => {
    const nWidth = nameEl.current ? nameEl.current.getBBox().width + buffer : 0;
    const vWidth = isValueObject
      ? valueEl.current.offsetWidth + buffer
      : valueEl.current.getBBox().width + buffer;
    const iWidth = iconWidth;
    setNameWidth(nWidth);
    setValueWidth(vWidth);
    setTotalWidth(iWidth + vWidth + nWidth);
  }, [name, value, detailsLoading, isValueObject, fullPercentage]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={'100%'}
      height={'100%'}
      style={{ overflow: 'visible' }}
    >
      {!(stateView && locationType === GAUGE_LOCATION_TYPE) && (
        <>
          <rect
            fill={
              locationType === GAUGE_LOCATION_TYPE && isInvalidNumber
                ? '#D0D0D0'
                : background
                ? background
                : color
            }
            width={totalWidth}
            height="28"
            rx="10"
            x={coord.x}
            y={coord.y}
          />
          <image x={coord.x + adjustment.x} y={coord.y + adjustment.y} href={icon} />
          {!stateView ? (
            <a target="_blank" rel="noopener noreferrer" href={!stateView ? url : ''}>
              <text
                ref={nameEl}
                fill="white"
                x={coord.x + totalWidth - nameWidth - valueWidth + buffer / 2}
                y={coord.y + 18}
              >
                {name}
              </text>
            </a>
          ) : (
            <text
              ref={nameEl}
              fill="white"
              x={coord.x + totalWidth - nameWidth - valueWidth + buffer / 2}
              y={coord.y + 18}
            >
              {name}
            </text>
          )}
        </>
      )}
      <rect
        fill={'white'}
        style={
          stateView && locationType === GAUGE_LOCATION_TYPE
            ? { stroke: 'black', strokeWidth: 1 }
            : {}
        }
        x={coord.x + totalWidth - valueWidth}
        y={coord.y + 1}
        width={valueWidth - 1 > 0 ? valueWidth - 1 : 0}
        height="26"
        rx="9"
      />
      {isValueObject ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={'100%'}
          height={'100%'}
          x={coord.x + totalWidth - valueWidth + buffer / 2}
          y={coord.y}
          style={{ overflow: 'visible' }}
        >
          <foreignObject
            x={0}
            y={2}
            width={valueWidth - 1 > 0 ? valueWidth - 1 : 0}
            height={26}
            style={{ overflow: 'visible', boxSizing: 'unset' }}
            id="tooltip"
          >
            <div ref={valueEl} style={{ display: 'inline', whiteSpace: 'nowrap' }}>
              <DataQualityText qualityCode={value.qualityCode}>
                {getDisplayValue(fullPercentage, value.value, value.variableName)}
              </DataQualityText>
            </div>
          </foreignObject>
        </svg>
      ) : (
        <text
          ref={valueEl}
          fill={locationType === GAUGE_LOCATION_TYPE && isInvalidNumber ? '#D0D0D0' : '#4b555a'}
          x={coord.x + totalWidth - valueWidth + buffer / 2}
          y={coord.y + 19}
        >
          {detailsLoading ? (
            'loading...'
          ) : (
            <DataQualityText qualityCode={value.qualityCode}>
              {getDisplayValue(fullPercentage, value)}
            </DataQualityText>
          )}
        </text>
      )}
    </svg>
  );
};

export default RiverStation;

RiverStation.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  unit: PropTypes.string,
  locationType: PropTypes.string,
  url: PropTypes.string,
  background: PropTypes.string,
  stateView: PropTypes.bool,
  fullPercentage: PropTypes.number,
};
