// Library
import React from 'react';
import PropTypes from 'prop-types';

// Component
import InfoButton from './InformationButton';
import ParagraphSection from '../paragraphSection/ParagraphSection';
import AppModal from '../modal/Modal';

// Style
import './infoIconAndModal.scss';

// Constant
const container = 'info-icon-modal';
const InfoIconAndModal = ({ modalId, modalContent, btnClassName, lastWord = '' }) => {
  const modalMaker = () => {
    return (
      <>
        {modalContent.modalContent.map((content, index) => {
          return (
            <ParagraphSection
              key={index}
              sectionTitle={content.header}
              sectionContent={content.paragraph}
              customClass={`${container}-content`}
            />
          );
        })}
      </>
    );
  };

  return (
    <div className={container}>
      <AppModal id={modalId} title={modalContent.title} body={modalMaker()} />
      <span className={`${container}-text`}>
        {lastWord}
        <InfoButton
          targetDataId={`#${modalId}`}
          btnClassName={btnClassName ? btnClassName : `${container}-icon`}
        />
      </span>
    </div>
  );
};

export default InfoIconAndModal;

InfoIconAndModal.propTypes = {
  modalId: PropTypes.string,
  modalContent: PropTypes.shape({
    title: PropTypes.string,
    modalContent: PropTypes.arrayOf(
      PropTypes.shape({
        header: PropTypes.string,
        paragraph: PropTypes.arrayOf(PropTypes.any),
      }),
    ),
  }),
  btnClassName: PropTypes.string,
  lastWord: PropTypes.any,
};
