import React from 'react';
import PropTypes from 'prop-types';

const DoubleArrowSVG = ({ customArrowClass }) => {
  return (
    <svg className={customArrowClass} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M11.46,8.29a1,1,0,0,0-1.42,0l-3,3a1,1,0,0,0,0,1.42l3,3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.16,12l2.3-2.29A1,1,0,0,0,11.46,8.29ZM14.66,12,17,9.71a1,1,0,0,0-1.42-1.42l-3,3a1,1,0,0,0,0,1.42l3,3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
    </svg>
  );
};

export default DoubleArrowSVG;

/* Props */
DoubleArrowSVG.propTypes = {
  /**
   * This change the header arrow's styling
   * It expects string that represent a css class
   * Example can be seen in restrictions.scss, .restrictions-body-arrow
   *
   * Side Note:
   * Change both height and width to control size
   * Change fill to change color
   */
  customArrowColor: PropTypes.string,
};
