// Libraries
import React, { useEffect, useContext, useState } from 'react';
import { isEmpty } from 'lodash';

// Styles
import './userProfileButton.scss';

// Context
import { UserContext } from '../../contexts/UserContext';

// Helpers
import azureB2CHelper from '../../helpers/AzureB2CHelper';

// Components
import ExpandLink from '../expandLink/ExpandLink';

const component = 'user-profile-btn';

export default function UserProfileButton() {
  const { setUser } = useContext(UserContext);
  const [userInfo, setUserInfo] = useState({});
  const { isLoggedIn, setIsLoggedIn } = useContext(UserContext);
  const [isLoggedInLoaded, setIsLoggedInLoaded] = useState(false);
  const DROP_DOWN_ITEMS = [
    { title: 'My Alerts', internalLink: '/my-alert' },
    {
      title: 'My Requests',
      internalLink: '/my-request',
    },
    {
      title: 'Update Phone Number',
      internalLink: '',
      onClickFunc: () => azureB2CHelper.redirectToUpdatePhoneNumber(),
    },
    {
      title: 'Update My Profile',
      internalLink: '',
      onClickFunc: () => azureB2CHelper.redirectToProfileEditPage(),
    },
    {
      title: 'Update My Password',
      internalLink: '',
      onClickFunc: () => azureB2CHelper.redirectToPasswordResetPage(),
    },
    {
      title: 'Sign out',
      internalLink: '',
      onClickFunc: () => azureB2CHelper.logout(),
    },
  ];

  useEffect(() => {
    (async () => {
      var loggedIn = await azureB2CHelper.isLoggedIn();
      setIsLoggedIn(loggedIn);
      if (loggedIn) {
        const userInfo = await azureB2CHelper.getUserInfoFromToken();
        setUser(userInfo);
        setUserInfo(userInfo);
      }
      setIsLoggedInLoaded(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isLoggedInLoaded) return <div className={component}></div>;

  return (
    <div className={component}>
      {isLoggedIn && !isEmpty(userInfo) && (
        <ExpandLink title={`Hi ${userInfo.givenName}`} linkItems={DROP_DOWN_ITEMS} />
      )}
    </div>
  );
}
