// Library
import React, { useState } from 'react';

// Style
import './feedback.scss';

// Component
import FeedbackButton from '../../components/feedback/FeedbackButton';
import FeedbackForm from '../../components/feedback/FeedbackForm';

const Feedback = () => {
  const [showFeedback, setShowFeedBack] = useState(false);

  return (
    <div className="feedback">
      <FeedbackButton onBtnClick={setShowFeedBack} formState={showFeedback} />
      <FeedbackForm show={showFeedback} setShow={setShowFeedBack} />
    </div>
  );
};

export default Feedback;
