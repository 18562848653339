import React from 'react';

// Component
import WaterIntroduction from '../../components/introduction/WaterIntroduction';
import WaterSharing from '../../components/waterSharing/WaterSharing';
import BodyContainer from '../../layout/body/BodyContainer';

export default function Learn() {
  return (
    <BodyContainer page>
      <WaterIntroduction />
      <WaterSharing />
    </BodyContainer>
  );
}
