// Libraries
import React, { useContext, useState, useEffect } from 'react';
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  ReferenceLine,
  Legend,
} from 'recharts';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

// Components
import ChartContainer from '../chartProperties/ChartContainer';
import ChartLegend from '../chartProperties/ChartLegend';
import ChartTooltip from '../chartProperties/ChartTooltip';

// Assets
import text from './research.json';

// Helper
import { formatLabelWaterYear } from '../../helpers/TimeUtils';
import { formatNumberDecimals } from '../../helpers/Utils';

//Context
import { MobileContext } from '../../contexts/MobileContext.jsx';

//Costants
import constants from '../../constants/HydrometricsConstants';
const COLOR_CODES = ['#ffcd34', '#63cbe8', '#bed12a', '#91b0c1', '#DC7520', '#801D34', '#56801D'];

export default function TotalUsageGraph({ data }) {
  const { isMobile } = useContext(MobileContext);
  const extraction_limit = data[0].extraction_limit;
  const [boreKeys, setBoreKeys] = useState([]);

  // LifeCycle
  useEffect(() => {
    if (!isEmpty(data)) {
      const dataSet = data[0].resources || [];
      let keys = [];
      Object.keys(dataSet[0]).forEach(key => {
        if (key !== 'usage' && key !== 'water_year') {
          keys.push(key);
        }
      });
      setBoreKeys(keys);
    }
  }, [data]);

  // Component
  const renderCustomTooltip = ({ payload }) => {
    return (
      <ChartTooltip
        payload={payload}
        isPayloadArray={true}
        showTotal={false}
        subElementKey="resources"
        titleKey="water_year"
        titleText="Water year"
        graphType="annual_extraction"
        decimals={constants.BORE_DATA_LABEL_FORMAT.water_level.decimals}
      />
    );
  };

  const renderCustomizedLegend = ({ payload }) => {
    const additionalLegend = [
      {
        type: 'line',
        dataKey: 'extraction_limit',
        color: 'red',
        labelName: 'LTAAEL (LHS)',
        strokeDashArray: true,
        pathProperty: 'M5 20 l215 0',
      },
    ];
    return (
      <ChartLegend
        payload={payload}
        isMobile={isMobile}
        additionalLegend={extraction_limit ? additionalLegend : []}
      />
    );
  };

  const drawLinesForBores = () => {
    let count = 0;
    const lineArr = boreKeys.map((key, index) => {
      count++;
      return (
        <Line
          name={`${key} (RHS)`}
          type="monotone"
          strokeWidth={3}
          dataKey={key}
          stroke={COLOR_CODES[count]}
          yAxisId={1}
          key={`line-chart-${index}`}
          dot={{ strokeWidth: 2 }}
        />
      );
    });
    return lineArr;
  };

  return (
    <ChartContainer
      pageTitle={text.totalUsageGraph.title}
      graphTitle={text.totalUsageGraph.title}
      graphIntro={text.totalUsageGraph.intro}
    >
      <ResponsiveContainer minHeight={400} maxWidth={1280} width="100%">
        <ComposedChart data={data[0].resources} margin={{ left: 35, bottom: 70 }}>
          <XAxis
            dataKey="water_year"
            tickFormatter={v => formatLabelWaterYear(v)}
            type="category"
            dy={25}
            tick={{ fill: 'black' }}
          />
          <YAxis
            type="number"
            interval="preserveStartEnd"
            label={{ value: 'Total extraction (GL)', angle: -90, dx: -55 }}
            tickFormatter={v => formatNumberDecimals(v / 1000)}
            domain={[0, Math.round(extraction_limit + 5)]}
            yAxisId={0}
          />
          {!isEmpty(boreKeys) && (
            <YAxis
              label={{ value: 'Water level (m below ground)', angle: -90, dx: 20 }}
              orientation="right"
              tickFormatter={v => v}
              domain={[dataMin => Math.round(dataMin - 5), dataMax => Math.round(dataMax + 5)]}
              reversed={true}
              yAxisId={1}
            />
          )}
          <Bar name="Usage" dataKey="usage" fill="#004ba3" yAxisId={0} />
          <ReferenceLine y={extraction_limit} stroke="red" isFront={true} strokeDasharray="10 10" />
          {drawLinesForBores()}
          <Tooltip
            content={payload => renderCustomTooltip(payload)}
            cursor={{ fill: 'transparent' }}
          />
          <Legend wrapperStyle={{ position: 'relative' }} content={renderCustomizedLegend} />
        </ComposedChart>
      </ResponsiveContainer>
    </ChartContainer>
  );
}

TotalUsageGraph.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      water_year: PropTypes.number,
      extraction_limit: PropTypes.number,
      resources: PropTypes.array,
    }),
  ),
  payload: PropTypes.array,
};
