// Library
import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

// Style
import './globalSearchBar.scss';

// Assets
import historyIcon from '../../image/icons/historyIcon.svg';
import searchGreyIcon from '../../image/icons/search_grey.svg';

// Helper
import { getSearchResult } from '../../helpers/ApiHelper';
import { sortArrayByKeyDesc } from '../../helpers/Utils';

// Context
import { SearchContext } from '../../contexts/SearchContext';

// Constant
const container = 'global-search-bar';

const GlobalSearchBar = ({
  placeholder = 'Find a telemetered site or monitoring bore',
  updates,
  setFilteredUpdates,
  stations,
  setFilteredStations,
  setOpenFilter = null,
  localSearchTerm,
  type = '',
}) => {
  // Context
  const location = useLocation();
  const history = useHistory();
  const { searchTerm, setSearchTerm, setInitiateSearch } = useContext(SearchContext);

  // State
  const [recommended, setRecommended] = useState([]);
  const [openRecommend, setOpenRecommend] = useState(false);
  const [archivedSearchTerm, setArchivedSearchTerm] = useState('');

  // Life Cycle
  useEffect(() => {
    if (location.pathname !== '/search') {
      setSearchTerm('');
      setOpenRecommend(true);
      setInitiateSearch(false);
    }
    if (location.pathname === '/search') setOpenRecommend(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //global search site
    if (searchTerm && !updates) {
      (async () => {
        const data = await getSearchResult(searchTerm);
        setRecommended(
          formatAutocompleteRes(
            [
              ...new Set(sortArrayByKeyDesc(data, '_type').map(item => item._source.station_title)),
            ].slice(0, 10),
          ),
        );
      })();
    }

    //search stations
    if (searchTerm && !updates && stations) {
      const filteredStations = stations.filter(station => {
        let lowerStationName = station.station_name.toLowerCase();
        let lowerSearchTerm = searchTerm.toLowerCase();
        return (
          lowerStationName.includes(lowerSearchTerm) || station.station_id.includes(searchTerm)
        );
      });
      setFilteredStations(filteredStations);
    }

    //search archived updates
    if (archivedSearchTerm && updates && !isEmpty(updates) && !stations) {
      setOpenFilter(true);
      const filteredUpdates = updates.filter(update => {
        let lowerSourceName = update?.water_source_name?.toLowerCase();
        let lowerSourceTitle = update?.title?.toLowerCase();
        let waterSourceId = update.water_source_id.toString();
        let lowerSearchTerm = archivedSearchTerm.toLowerCase();
        return (
          lowerSourceName?.includes(lowerSearchTerm) ||
          lowerSourceTitle?.includes(lowerSearchTerm) ||
          waterSourceId.includes(archivedSearchTerm)
        );
      });
      setFilteredUpdates(filteredUpdates);
    }
    //not archived searchterm on archived updates
    if (isEmpty(archivedSearchTerm) && updates) {
      setOpenFilter(false);
      setFilteredUpdates([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, updates, stations, archivedSearchTerm]);

  // Helpers
  const formatAutocompleteRes = res => {
    if (isEmpty(res)) return [];

    const formatted = res.map(item => {
      return {
        type: 'suggestion',
        content: item,
      };
    });
    return formatted;
  };

  const handleKeypress = e => {
    if (e.key === 'Enter') {
      setInitiateSearch(true);
      setOpenRecommend(false);
      history.push('/search');
    }
  };

  return (
    <div className={container}>
      {type !== 'archived' && (
        <input
          id={`${container}-input`}
          className={`${container}-input`}
          placeholder={placeholder}
          value={searchTerm ? searchTerm : localSearchTerm}
          autoComplete="off"
          onChange={event => {
            setRecommended([]);
            setSearchTerm(event.target.value);
            setOpenRecommend(type === 'global' && location.pathname !== '/search');
          }}
          onKeyPress={handleKeypress}
        />
      )}
      {type === 'archived' && (
        <input
          id={`${container}-input-archived`}
          className={`${container}-input`}
          placeholder={placeholder}
          value={archivedSearchTerm}
          autoComplete="off"
          onChange={event => {
            setArchivedSearchTerm(event.target.value);
            setOpenFilter(true);
          }}
        />
      )}
      {searchTerm && openRecommend && !isEmpty(recommended) && (
        <div className={`${container}-suggestion`}>
          {recommended.map((suggestion, index) => {
            return (
              <div
                key={index}
                className={`${container}-suggestion-item`}
                onClick={() => {
                  setSearchTerm(suggestion.content);
                  setInitiateSearch(true);
                  setOpenRecommend(false);
                  history.push('/search');
                }}
              >
                <img
                  alt="suggestion icon"
                  className={`${container}-suggestion-icon`}
                  src={suggestion.type === 'history' ? historyIcon : searchGreyIcon}
                />
                <div
                  className={`${container}-suggestion-text`}
                  style={{
                    fontWeight: suggestion.type === 'history' ? 'bold' : 'normal',
                  }}
                >
                  {suggestion.content}
                </div>
                {suggestion.type === 'history' && (
                  <div className={`${container}-suggestion-remove`}>Remove</div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default GlobalSearchBar;

GlobalSearchBar.propTypes = {
  placeholder: PropTypes.string,
  items: PropTypes.array,
  setFilteredItems: PropTypes.func,
  setOpenFilter: PropTypes.func,
  localSearchTerm: PropTypes.string,
  type: PropTypes.string,
};
