// Library
import React from 'react';

// Styles
import './dataQualityLegend.scss';

// Constant
// import { STATUS } from '../../../constants/DataQualityConstant';
import config from '../../../configs/featureToggleConfig.json';
const toShow = config['quality_code'].active;

const component = 'data-quality-legend';

const DataQualityLegend = () => {
  return (
    toShow && (
      <div className={component}>
        <div>
          <div className={`${component}-title`}>
            <span>Data legend</span>
          </div>
          <div className={`${component}-status`}>
            {/* <span className={`${component}-status-normal`}>{STATUS.NORMAL.statusName}</span>
          <span className={`${component}-status-anomaly`}>{STATUS.ANOMALY.statusName}</span> */}
            <span className={`${component}-status-anomaly`}>REQUIRES FURTHER USER VALIDATION</span>
          </div>
        </div>
        <div>
          <div className={`${component}-note`}>
            <span>
              *<a href="/glossary#data-quality-coding">Read here</a> to understand data quality
            </span>
          </div>
        </div>
      </div>
    )
  );
};

export default DataQualityLegend;
