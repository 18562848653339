import { useEffect } from 'react';

const ScrollToComponent = ({ target }) => {
  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: target.current && target.current.offsetTop,
    });
  }, [target]);
  return null;
};

export default ScrollToComponent;
