import React, { useState, useEffect, useContext } from 'react';

// Libraries
import { isEmpty } from 'lodash';
import moment from 'moment';

// Context
import { AppContext } from '../../contexts/AppContext';

// Helpers
import { getMultiSiteData } from '../../helpers/WaterDataApiHelper';
import { getStationsByID } from '../../helpers/ApiHelper';
import { formatNumberDecimals } from '../../helpers/Utils';

// Styles
import './riverDataReportTable.scss';

//Components
import Loader from '../loader/Loader';
import DataQualityText from '../generalComponents/dataQuality/DataQualityText';

// Constants
import apiConstants from '../../constants/WaterDataAPIConstants';
import hydroDataConstants from '../../constants/HydrometricsConstants';
import mappingConfig from './water_source_stations.json';
const container = 'river-data-report-table';

function RiverDataReportTable({ hydrometrics, refreshTrigger }) {
  const { waterSource } = useContext(AppContext);
  const [stationData, setStationData] = useState({});
  const [gauges, setGauges] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const dayList = Array.from({ length: 7 }, (_, i) =>
    moment().subtract(i, 'days').format('YYYY-MM-DD'),
  );

  useEffect(() => {
    if (waterSource.water_source_id && mappingConfig[waterSource.water_source_id]) {
      setGauges([...mappingConfig[waterSource.water_source_id]]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterSource.water_source_id]);

  useEffect(() => {
    if (mappingConfig[waterSource.water_source_id]) {
      fetchStationsData([...mappingConfig[waterSource.water_source_id]]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gauges, refreshTrigger]);

  const fetchStationsData = async gaugesList => {
    setIsLoading(true);
    const siteListResult = await getStationsByID(gaugesList);
    const variables = hydrometrics.split(',');
    if (!isEmpty(siteListResult)) {
      const siteIds = siteListResult.map(item => item.id);
      const result = await getMultiSiteData(siteIds, variables, 'daily', '7 days', true);
      if (!isEmpty(result)) {
        siteListResult.map(site => {
          result.forEach(item => {
            if (item.id === site.station_id) {
              item.stationName = `${site.station_shortname} (${site.station_id})`;
              item.hydrometric_types = site.hydrometric_types;
            }
          });
        });
        setStationData(result);
      }
    }
    setIsLoading(false);
  };

  const renderDataCell = (stationItem, day) => {
    let stationValue = stationItem?.resources?.find(
      elm => day === moment(elm.date, apiConstants.API_DATE_FORMAT).format('YYYY-MM-DD'),
    );
    if (stationValue) {
      return (
        <>
          {['level', 'flow_rate'].map(key => {
            const decimals = hydroDataConstants.DATA_LABEL_FORMAT[key]?.decimals;
            return (
              <td key={key}>
                {typeof stationValue?.[key] === 'number' ? (
                  <DataQualityText qualityCode={stationValue?.rawData?.qualityCode}>
                    {formatNumberDecimals(stationValue?.[key], false, false, decimals)}
                  </DataQualityText>
                ) : (
                  '-'
                )}
              </td>
            );
          })}
        </>
      );
    } else {
      return (
        <>
          <td>{'-'}</td>
          <td>{'-'}</td>
        </>
      );
    }
  };

  return (
    <>
      <div className={`${container}-info`}>Each daily value is taken at 9am.</div>
      <div className={container}>
        {isLoading ? (
          <Loader />
        ) : !isEmpty(stationData) ? (
          <table className="table">
            <thead>
              <tr>
                <th rowSpan="3" className={`${container}-first-header`}>
                  Stations / Date
                </th>
              </tr>
              <tr>
                {dayList.map((day, index) => (
                  <th key={index} colSpan="2">
                    {moment(day, 'YYYY-MM-DD').format('DD MMM')}
                  </th>
                ))}
              </tr>
              <tr>
                {Array.from({ length: 7 }, (_, index) => (
                  <React.Fragment key={index}>
                    <th>Level (m)</th>
                    <th>Flow (ML/d)</th>
                  </React.Fragment>
                ))}
              </tr>
            </thead>
            <tbody>
              {stationData.map((element, index) => {
                return (
                  <tr key={index}>
                    <th scope="row">{element.stationName}</th>
                    {dayList.map(day => {
                      return renderDataCell(element, day);
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default RiverDataReportTable;
