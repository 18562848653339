import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'jquery';
import 'popper.js';
import App from './App.jsx';
import * as serviceWorker from './serviceWorker';
// import { loadInitialData } from './helpers/ApiHelper.jsx';

//this is to ensure the localstorage is populated for users who use bookmark to visit the site
// loadInitialData().then(() => {
//   ReactDOM.render(<App />, document.getElementById('root'));
// });

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
