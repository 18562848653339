// Library
import React, { useState, useRef } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

// Style
import './graphAndDownloadDropdown.scss';

// Component
import ExpandBox from '../../../../../generalComponents/expandBox/ExpandBox';
import CheckboxListDropdown from '../../../../../generalComponents/checkboxBtnListDropdown/CheckbostBtnListDropdown';
import RadioList from '../../../../../generalComponents/radioList/RadioList';

// Helper
import { useOutsideClickDetect } from '../../../../../../helpers/UIBehaviourHelper';

// Constant
const component = 'graph-and-download-dropdown';
const GraphAndDownloadDropdown = ({ options, selectedOptions, onChange, listType }) => {
  const [expand, setExpand] = useState(false);
  const dropdownRef = useRef(null);
  useOutsideClickDetect(dropdownRef, () => {
    setExpand(false);
  });

  const genBtnText = () => {
    if (listType === 'checkbox') {
      return isEmpty(selectedOptions)
        ? 'Select'
        : selectedOptions.map(item => item.name).join(', ');
    } else {
      return selectedOptions?.name;
    }
  };

  return (
    <div className={`${component}`}>
      <ExpandBox
        expandState={expand}
        handleExpandClick={setExpand}
        clickableSurface
        customHeaderContent={<div className={`${component}-button-text`}>{genBtnText()}</div>}
        customHeaderContainerStyle={`${component}-button`}
      >
        {listType === 'checkbox' ? (
          <CheckboxListDropdown
            customStyles={`${component}-checkbox`}
            options={options}
            onChange={onChange}
            selectedOptions={selectedOptions}
            isOpen={expand}
            onClose={() => {
              setExpand(false);
            }}
            onClear={() => {
              onChange([]);
            }}
          />
        ) : (
          <span ref={dropdownRef}>
            <RadioList
              customStyles={`${component}-radio`}
              options={options}
              selectedOption={selectedOptions}
              onChange={v => {
                setExpand(false);
                onChange(v);
              }}
            />
          </span>
        )}
      </ExpandBox>
    </div>
  );
};

export default GraphAndDownloadDropdown;

GraphAndDownloadDropdown.propTypes = {
  // An array of objects, each with id (a required string), name (a required string),
  // and disabled (an optional boolean) properties. This represents the list of checkbox
  // options that will be rendered.
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }),
  ).isRequired,
  // An array of objects or object, each with id (a required string), name (a required string),
  // and disabled (an optional boolean) properties. This represents the currently selected
  // checkbox options.
  selectedOptions: PropTypes.oneOfType([
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      disabled: PropTypes.bool,
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        disabled: PropTypes.bool,
      }),
    ),
  ]),
  // A function that is called when checkbox is clicked
  onChange: PropTypes.func.isRequired,
  // A string that specifies the type of list to render. This should be either 'checkbox' or 'radio'.
  listType: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
};
