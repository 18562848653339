// Libraries
import React from 'react';

// Styles
import './mapLegend.scss';

// Components
import MapLegendLineItem from './MapLegendLineItem';

// Constant
const container = 'drought-status-map-legend';

export default function DroughtStatusMapLegend() {
  const mapLegendItems = [
    { text: 'Stage 2: Emerging', strokeColor: '#fcbe00' },
    { text: 'Stage 3: Severe', strokeColor: '#ff5c00' },
    { text: 'Stage 4: Critical', strokeColor: '#d12800' },
  ];

  return (
    <div className={`${container}`}>
      <div className={`${container}-title`}>Drought status</div>
      {mapLegendItems.map((legend, index) => (
        <MapLegendLineItem
          key={index}
          strokeColor={legend.strokeColor}
          strokeWidth="7"
          legendText={legend.text}
        />
      ))}
    </div>
  );
}
