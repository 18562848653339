// Libraries
import moment from 'moment';
import { isEmpty } from 'lodash';

// Helpers
import { currentFinancialYear } from '../../helpers/TimeUtils';
import { formatNumberDecimals } from '../../helpers/Utils';

/**
 * Create a empty allocation item
 *
 * @param {*} category - the short name category
 * @param {*} licence - the lincence
 */
const createInitialAllocationItem = (category, licence) => ({
  category_name: category,
  category_shortname: category,
  licence: licence,
  number_access_license: 0,
  number_shares: 0,
  water_allocated: 0,
  water_available: 0,
  allocation_percentage: 0,
  water_usage: 0,
  carryover: 0,
  adjustments: 0,
  water_year: 0,
});

/**
 * Function to return the allocations array grouped by category
 */
export const groupAllocationsByCategory = allocations => {
  let resultAllocations = [];

  if (isEmpty(allocations)) {
    return resultAllocations;
  }
  allocations.reduce(function (res, value) {
    if (!res[value.category_shortname]) {
      res[value.category_shortname] = createInitialAllocationItem(
        value.category_shortname,
        value.licence,
      );
      resultAllocations.push(res[value.category_shortname]);
    }
    const currentItem = res[value.category_shortname];
    const allocPercentage = Math.max(
      Number(value.allocation_percentage),
      currentItem.allocation_percentage,
    );

    currentItem.number_access_license += Number(value.number_access_license);
    currentItem.number_shares += Number(value.number_shares);
    currentItem.water_allocated += Number(value.water_allocated);
    currentItem.water_available += Number(value.water_available);
    currentItem.allocation_percentage = allocPercentage;
    currentItem.water_usage += Number(value.water_usage);
    currentItem.carryover += Number(value.carryover);
    currentItem.adjustments += Number(value.adjustments);
    currentItem.water_year = Number(value.water_year);
    currentItem.environmental = value.environmental;

    return res;
  }, {});
  return resultAllocations;
};

/**
 * Function to sort an array based on another array
 */
export const sortArrayBasedOnAnotherArray = (sortingArray, arrayToBeSorted, keyToBeFiltered) => {
  let result = [];
  let resultItemsFound = [];
  let resultItemsNotFound = [];

  resultItemsFound = filterArrayBasedOnAnotherArray(sortingArray, arrayToBeSorted, keyToBeFiltered);

  resultItemsNotFound = getArraysDifference(sortingArray, arrayToBeSorted);
  result = resultItemsFound.concat(resultItemsNotFound);

  return result;
};

/**
 * Function to get the difference (items) between the second array and the first one.
 * Will return the elements from the second array which are not included in the first array
 */
export const getArraysDifference = (sortingArray, arrayToBeSorted) => {
  let resultItemsNotFound = [];

  arrayToBeSorted.forEach(key => {
    const value = sortingArray.find(item => item.category_shortname === key.category_shortname);
    if (!value) {
      resultItemsNotFound.push(key);
    }
  });
  return resultItemsNotFound;
};

export const addAllocationResource = (categoryElement, resourceName) => {
  return {
    category_shortname: categoryElement.category_shortname,
    water_year: categoryElement.water_year,
    total_water_usage: 0,
    resources: {
      [resourceName]: {
        water_usage: 0,
        water_available: 0,
      },
    },
  };
};

/**
 * Function to return the allocations array grouped by category
 */
export const groupDistributionsByCategory = allocations => {
  let resultAllocations = [];

  if (isEmpty(allocations)) {
    return resultAllocations;
  }

  allocations.reduce(function (res, value) {
    if (!res[value.category_shortname]) {
      if (value.resources.consumptive && value.resources.environmental) {
        res[value.category_shortname] = buildLicencedCategoryObject(
          value.category_shortname,
          value.water_year,
        );
      } else if (value.resources.operational) {
        res[value.category_shortname] = addAllocationResource(value, 'operational');
      } else if (value.resources.consumptive) {
        res[value.category_shortname] = addAllocationResource(value, 'consumptive');
      } else if (value.resources.environmental) {
        res[value.category_shortname] = addAllocationResource(value, 'environmental');
      } else if (value.resources.inflow) {
        res[value.category_shortname] = addAllocationResource(value, 'inflow');
      }

      resultAllocations.push(res[value.category_shortname]);
    }

    if (value.resources.consumptive && value.resources.environmental) {
      res[value.category_shortname].resources.consumptive.water_usage += Number(
        value.resources.consumptive.water_usage,
      );
      res[value.category_shortname].resources.consumptive.water_available +=
        value.category_shortname !== 'Supplementary water' &&
        value.resources.consumptive.water_available
          ? Number(value.resources.consumptive.water_available)
          : 0;

      res[value.category_shortname].resources.environmental.water_usage += Number(
        value.resources.environmental.water_usage,
      );
      res[value.category_shortname].resources.environmental.water_available +=
        value.category_shortname !== 'Supplementary water' &&
        value.resources.environmental.water_available
          ? Number(value.resources.environmental.water_available)
          : 0;

      res[value.category_shortname].total_water_usage += Number(value.total_water_usage);
    } else if (value.resources.operational) {
      res[value.category_shortname].resources.operational.water_usage += Number(
        value.resources.operational.water_usage,
      );
      res[value.category_shortname].resources.operational.water_available += value.resources
        .operational.water_available
        ? Number(value.resources.operational.water_available)
        : 0;
      res[value.category_shortname].total_water_usage += Number(value.total_water_usage);
    } else if (value.resources.environmental) {
      res[value.category_shortname].resources.environmental.water_usage += Number(
        value.resources.environmental.water_usage,
      );
      res[value.category_shortname].resources.environmental.water_available += value.resources
        .environmental.water_available
        ? Number(value.resources.environmental.water_available)
        : 0;
      res[value.category_shortname].total_water_usage += Number(value.total_water_usage);
    } else if (value.resources.consumptive) {
      res[value.category_shortname].resources.consumptive.water_usage += Number(
        value.resources.consumptive.water_usage,
      );
      res[value.category_shortname].resources.consumptive.water_available += value.resources
        .consumptive.water_available
        ? Number(value.resources.consumptive.water_available)
        : 0;
      res[value.category_shortname].total_water_usage += Number(value.total_water_usage);
    } else if (value.resources.inflow) {
      res[value.category_shortname].resources.inflow.water_usage += Number(
        value.resources.inflow.water_usage,
      );
      res[value.category_shortname].resources.inflow.water_available += value.resources.inflow
        .water_available
        ? Number(value.resources.inflow.water_available)
        : 0;
      res[value.category_shortname].total_water_usage += Number(value.total_water_usage);
    }

    return res;
  }, {});

  return resultAllocations;
};

/**
 * Function to return the array removing the property name and value passed down
 */
export const removeCategoryElement = (array, propertyName, propertyValue) => {
  let results = [];
  if (isEmpty(array)) {
    return results;
  }
  results = array.filter(filter => {
    return filter[propertyName] !== propertyValue;
  });
  return results;
};

/**
 * Function to return an array removing the element with the propertyName and propertyValue passed into
 */
export const removeElement = (array, propertyName, propertyValue) => {
  let results = [];
  if (isEmpty(array)) {
    return results;
  }

  results = array.map(elementYear => {
    return {
      ...elementYear,
      resources: removeCategoryElement(elementYear.resources, propertyName, propertyValue),
    };
  });
  return results;
};

/**
 * Function to return the current year element within allocations response
 */
export const getCurrentYearElementFromAllocations = allocations => {
  let resultAllocations = [];
  if (!isEmpty(allocations)) {
    resultAllocations = allocations.find(
      yearItem => Number(yearItem.water_year) === Number(currentFinancialYear()),
    );
  }
  if (isEmpty(resultAllocations)) return [];
  return resultAllocations.resources ? resultAllocations.resources : [];
};

/**
 * Function to filter array based on another array elements
 */
export const filterArrayBasedOnAnotherArray = (array, arrayToBeFiltered, keyToBeFiltered) => {
  let result = [];

  array.forEach(function (key) {
    var found = false;
    arrayToBeFiltered.filter(item => {
      if (!found && item[keyToBeFiltered] === key[keyToBeFiltered]) {
        result.push(item);
        found = true;
        return false;
      } else return true;
    });
  });

  return result;
};

/**
 * Function to filter array based on another array elements
 */
export const populatePayloadLicencedWaterBalance = (array, arrayToBeFiltered, keyToBeFiltered) => {
  let result = [];

  array.forEach(function (key) {
    const consumptiveResources = arrayToBeFiltered.resources.consumptive;
    const environmentalResources = arrayToBeFiltered.resources.environmental;

    const consumptiveItemFound = consumptiveResources[key[keyToBeFiltered]];
    const environmentalItemFound = environmentalResources[key[keyToBeFiltered]];

    if (
      typeof consumptiveItemFound !== 'undefined' ||
      typeof environmentalItemFound !== 'undefined'
    ) {
      result.push({
        type: key.type,
        category_name: key.category,
        consumptive: consumptiveItemFound,
        environmental: environmentalItemFound,
        total_value: consumptiveItemFound + environmentalItemFound,
      });
    }
  });
  return result;
};

/**
 * Function to create the object for waterBalanceGraph payload
 */

export const createPayloadLicencedWaterBalance = (balanceData, activeCategory) => {
  let result = [];
  const sortingArray = createLicencedBalanceSortingArray();

  //Get the balanceData sorted by the predetermined sorting array
  result = populatePayloadLicencedWaterBalance(
    sortingArray,
    balanceData[activeCategory],
    'category',
  );

  //Aaggregate cumulative key for the transparent bar in the balance graph
  result = aggregateCumulativeKeyLicencedWaterBalancePayload(result);

  return result;
};

/**
 * Function to create the object for waterBalanceGraph payload
 */

export const createPayloadWaterBalance = (balanceData, activeYear) => {
  let result = [];

  const sortingArray = createBalanceSortingArray(activeYear);
  //Get the balanceData sorted by the predetermined sorting array
  result = filterArrayBasedOnAnotherArray(sortingArray, balanceData, 'type');

  //Filter out the Other outflows element when the total_water_usage is 0
  //and then aggregate cumulative key for the transparent bar in the balance graph
  result = result.filter(
    elementWaterBalancePayload =>
      !(
        elementWaterBalancePayload &&
        elementWaterBalancePayload.type === 'Other outflows' &&
        elementWaterBalancePayload.total_volume === 0
      ),
  );
  result = aggregateCumulativeKeyWaterBalancePayload(result);

  return result;
};

/**
 * Function to create a cumulative key in every object to create this waterfall view in the graph
 */
export const aggregateCumulativeKeyLicencedWaterBalancePayload = waterBalanceArray => {
  let result = [];
  let cumulativeValue = 0;

  if (isEmpty(waterBalanceArray)) {
    return result;
  }

  waterBalanceArray.forEach(categoryElement => {
    if (
      categoryElement &&
      (categoryElement.category_name === 'carryover' ||
        categoryElement.category_name === 'water_available')
    ) {
      categoryElement.cumulative = 0;
      cumulativeValue += categoryElement.total_value;
    } else if (
      categoryElement &&
      (categoryElement.category_name === 'water_allocated' ||
        categoryElement.category_name === 'adjustments' ||
        categoryElement.category_name === 'net_trading_volume' ||
        categoryElement.category_name === 'drought_suspension_volume')
    ) {
      categoryElement.cumulative = cumulativeValue;
      cumulativeValue += categoryElement.total_value;
    } else if (
      categoryElement &&
      (categoryElement.category_name === 'water_usage' ||
        categoryElement.category_name === 'forfeited_volume')
    ) {
      cumulativeValue -= categoryElement.total_value;
      categoryElement.cumulative = cumulativeValue;
    }
  });
  return waterBalanceArray;
};

/**
 * Function to create a cumulative key in every object to create this waterfall view in the graph
 */
export const aggregateCumulativeKeyWaterBalancePayload = waterBalanceArray => {
  let result = [];
  let cumulativeValue = 0;

  if (isEmpty(waterBalanceArray)) {
    return result;
  }

  waterBalanceArray.forEach(categoryElement => {
    if (categoryElement && categoryElement.resources.storage) {
      categoryElement.cumulative = 0;
      cumulativeValue += categoryElement.total_volume;
    } else if (categoryElement && categoryElement.resources.inflow) {
      categoryElement.cumulative = cumulativeValue;
      cumulativeValue += categoryElement.total_volume;
    } else if (categoryElement) {
      categoryElement.cumulative = cumulativeValue;
      cumulativeValue -= categoryElement.total_volume;
    }
  });

  return waterBalanceArray;
};

/**
 * Function to create a dummy object for the water balance payload categories
 */
export const buildLicencedCategoryObject = (category_shortname, water_year) => {
  return {
    category_shortname: category_shortname,
    water_year: water_year,
    total_water_usage: 0,
    resources: {
      consumptive: {
        water_usage: 0,
        water_available: 0,
      },
      environmental: {
        water_usage: 0,
        water_available: 0,
      },
    },
  };
};

/**
 * Function to create the sorting array for the water balance graph
 */
export const createLicencedBalanceSortingArray = () => {
  let result = [
    { type: 'Carried over', category: 'carryover' },
    { type: 'Allocated', category: 'water_allocated' },
    { type: 'Trading', category: 'net_trading_volume' },
    { type: 'Forfeits', category: 'forfeited_volume' },
    { type: 'Drought', category: 'drought_suspension_volume' },
    { type: 'Other', category: 'adjustments' },
    { type: 'Used', category: 'water_usage' },
    { type: 'Available', category: 'water_available' },
  ];
  return result;
};

/**
 * Function to create the sorting array for the water balance graph
 */
export const createBalanceSortingArray = activeYear => {
  let result = [
    { type: `Storage (${moment(activeYear - 1, 'YYYY').format('30 Jun YY')})` },
    { type: 'Storage inflows' },
    { type: 'Tributary inflows' },
    { type: 'Storage net evaporation' },
    { type: 'Losses' },
    { type: 'Other outflows' },
    { type: 'Basic landholder rights' },
    { type: 'Licenced water' },
    { type: 'Planned environmental' },
    { type: `Storage (${moment(activeYear, 'YYYY').format('30 Jun YY')})` },
  ];
  return result;
};

/**
 * Function to return an array removing the element with the propertyName and propertyValue passed into
 */
export const removeAllocationForecast = (array, propertyName, propertyValue) => {
  let results = [];
  if (isEmpty(array)) {
    return results;
  }

  results = array.filter(filterItem => filterItem.resources[0][propertyName] !== propertyValue);
  return results;
};

const parserCarryoverData = info => {
  const negativeOrPositive =
    info.carryover > 0
      ? `${formatNumberDecimals(info.carryover)} ML has been carried over into this year${
          info.environmental && info.environmental.carryover
            ? `, or an average carryover of ${formatNumberDecimals(
                info.carryover / info.number_shares,
              )} ML/share. Of the carryover, ${formatNumberDecimals(
                info.environmental.carryover,
              )}ML is for the environment.`
            : '.'
        }`
      : ` there was over use of ${formatNumberDecimals(info.carryover)} ML last year.`;
  const finalText =
    info.carryover !== 0 ? negativeOrPositive : 'Nothing was carried over into this year.';
  return finalText;
};

export const detailsInfoParser = info => {
  if (info) {
    return {
      cat_name: info.category_shortname,
      access_license: formatNumberDecimals(info.number_access_license),
      shares: formatNumberDecimals(info.number_shares),
      water_allocated: formatNumberDecimals(info.water_allocated),
      carryover: parserCarryoverData(info),
      water_usage: formatNumberDecimals(info.water_usage),
      water_available: formatNumberDecimals(info.water_available),
      ...(!isEmpty(info.environmental) && {
        env_access_license: formatNumberDecimals(info.environmental.number_access_license),
        env_shares: formatNumberDecimals(info.environmental.number_shares),
        env_water_available: formatNumberDecimals(info.environmental.water_available),
        env_water_allocated: formatNumberDecimals(info.environmental.water_allocated),
        env_carryover: formatNumberDecimals(info.environmental.carryover),
      }),
    };
  }
};
