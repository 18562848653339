// Libraries
import React from 'react';
import moment from 'moment';

// Styles
import './updatedDate.scss';

// Constants
import { dateFormat } from '../constants.json';

export default function UpdatedDate({ date }) {
  return (
    <div className={`updated-date`}>
      *Updated: {moment(date, dateFormat.input).format(dateFormat.output)} AEST
    </div>
  );
}
