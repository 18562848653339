// Libraries
import React, { useState, useContext, useLayoutEffect } from 'react';
import { isEmpty, cloneDeep } from 'lodash';
import { AreaChart, Area, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import PropTypes from 'prop-types';

// Styles
import './historicalUsageGraph.scss';

// Context
import { MobileContext } from '../../contexts/MobileContext';
import { AppContext } from '../../contexts/AppContext';

// Components
import ChartContainer from '../chartProperties/ChartContainer';
import ChartResponsiveContainer from '../chartProperties/ChartResponsiveContainer';
import ChartLegend from '../chartProperties/AllocationChartLegend';
import LicenceCategorySelector from '../allocation/LicenceCategorySelector';
import SelectionBtnsGroup from '../storage/childProperties/SelectionBtnsGroup';
import DownloadLink from '../chartProperties/ChartCSVDownloadLink';

// Assets
import config from '../../abstract/IntroText.json';

// Helpers
import { formatNumberDecimals, LightenDarkenColor } from '../../helpers/Utils';
import { groupDistributionsByCategory } from '../allocation/ArrayUtilsAllocation';
import { formatLabelWaterYear, currentFinancialYear } from '../../helpers/TimeUtils';
import ChartTooltip from '../chartProperties/ChartTooltip';

export default function HistoricalUsageGraph({ data }) {
  // Context
  const { isMobile, isXL } = useContext(MobileContext);
  const { waterSource } = useContext(AppContext);

  // States
  const [usageData, setUsageData] = useState();
  const [licCategorySelected, setLicCategorySelected] = useState('all');
  const [licCategoryMultiSelected, setLicCategoryMultiSelected] = useState(['all']);

  // Constents
  const LICENCE_CATEGORY_TRIBUT_INFLOWS = 'Tributary inflows';
  const baseColors = {
    consumptive: '#003470',
    operational: '#ffc107',
    environmental: '#bed12a',
  };

  // Life Cycle
  useLayoutEffect(() => {
    const actualYear = currentFinancialYear();
    const finalDataWithoutCurrentYear = data.filter(
      element => Number(element.water_year) !== actualYear,
    );
    let finalRes = finalDataWithoutCurrentYear;

    if (!isEmpty(finalDataWithoutCurrentYear)) {
      // Group the data based on category, combining duplicated ones
      const groupedDistributeData = finalDataWithoutCurrentYear.map(yearElement => {
        return {
          ...yearElement,
          resources: groupDistributionsByCategory(yearElement.resources),
        };
      });

      // Sort the data and split the data based on usage type
      const sortedDistributeData = groupedDistributeData.map(yearElement => {
        return {
          ...yearElement,
          resources: sortAndSplitBasedOnType(yearElement.resources),
        };
      });

      // Group years worth of data by category
      const accumulated = accumulatePastData(sortedDistributeData);

      // Filter categories based on its years' usage,
      // leave out categories with 0 through out the years
      const filteredAccumulate = filterCategoriesBasedOnUsage(accumulated);

      // Sort the category based on usage type and name
      const validField = sortCategoryBasedOnType(filteredAccumulate);

      // Fitler the actual data based on the validField
      // Fill up missing category for each year
      const filteredDistributeData = filterDistributeData(
        sortedDistributeData,
        validField,
        filteredAccumulate,
      );

      // Color each license and return the result
      finalRes = filteredDistributeData.map(elementYear => {
        const copiedElement = cloneDeep(elementYear);
        copiedElement.resources = colorLicense(copiedElement.resources);
        return copiedElement;
      });
    }

    setUsageData(finalRes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // Helpers
  const onCategoryChange = value => {
    if (value) {
      let listItemsSelected = licCategoryMultiSelected;

      if (!listItemsSelected.includes(value)) {
        if (value === 'all') {
          listItemsSelected = [];
        } else {
          listItemsSelected = listItemsSelected.filter(listItem => listItem !== 'all');
        }
        listItemsSelected.push(value);
      } else {
        if (value !== 'all') {
          listItemsSelected = listItemsSelected.filter(listItem => listItem !== value);
        } else {
          listItemsSelected = ['all'];
        }
      }

      if (isEmpty(listItemsSelected)) {
        listItemsSelected = ['all'];
        setLicCategorySelected('all');
      } else {
        setLicCategorySelected(value);
      }
      setLicCategoryMultiSelected(listItemsSelected);
    }
  };

  const colorLicense = arrayData => {
    let count = {
      consumptive: 0,
      operational: 0,
      environmental: 0,
    };
    const coloredArray = arrayData.map(category => {
      const { type } = category;
      const coloredCategory = {
        ...category,
        color: LightenDarkenColor(baseColors[type], 14 * count[type]),
      };
      count[type]++;
      return coloredCategory;
    });

    return coloredArray;
  };

  const sortAndSplitBasedOnType = arrayData => {
    const consumptiveArr = [];
    const environmentalArr = [];
    const operationalArr = [];

    arrayData.forEach(categoryObj => {
      const { resources, category_shortname } = categoryObj;
      if (!isEmpty(resources) && category_shortname !== LICENCE_CATEGORY_TRIBUT_INFLOWS) {
        const { consumptive, operational, environmental } = resources;
        const consumptiveRes = makeFieldObj(consumptive, consumptiveArr, 'consumptive');
        const operationalRes = makeFieldObj(operational, operationalArr, 'operational');
        const environmentalRes = makeFieldObj(environmental, environmentalArr, 'environmental');
        const resArr = [consumptiveRes, operationalRes, environmentalRes];
        const { water_year, category_shortname } = categoryObj;

        resArr.forEach(res => {
          const { data, empty, target, type } = res;

          if (!empty) {
            target.push(
              makeCategoryObj(water_year, category_shortname, data.water_usage, type, {
                [type]: data,
              }),
            );
          }
        });
      }
    });
    return [...consumptiveArr, ...environmentalArr, ...operationalArr];
  };

  const accumulatePastData = arrayData => {
    const result = arrayData.reduce((acc, yearElement) => {
      const { resources } = yearElement;
      if (!isEmpty(resources)) {
        resources.forEach(category => {
          const { category_shortname, total_water_usage, type } = category;
          if (category_shortname && !acc[category_shortname]) {
            acc[category_shortname] = {
              water_usage: total_water_usage,
              type: type,
            };
          } else {
            acc[category_shortname].water_usage += total_water_usage;
          }
        });
      }
      return acc;
    }, {});

    return result;
  };

  const filterCategoriesBasedOnUsage = accumulatedData => {
    const filtered = Object.keys(accumulatedData).reduce((acc, categoryKey) => {
      const curObj = accumulatedData[categoryKey];
      const { water_usage } = curObj;
      if (water_usage > 0) {
        acc[categoryKey] = curObj;
      }
      return acc;
    }, {});

    return filtered;
  };

  const sortCategoryBasedOnType = filteredData => {
    const sorted = Object.keys(filteredData).sort((a, b) => {
      const aType = filteredData[a].type;
      const bType = filteredData[b].type;
      if (aType === bType) {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
      }

      const measure = {
        consumptive: 1,
        environmental: 2,
        operational: 3,
      };
      return measure[aType] - measure[bType];
    });

    return sorted;
  };

  const filterDistributeData = (sortedData, fields, filteredCategory) => {
    const filtered = sortedData.reduce((acc, yearElement) => {
      const { water_year, resources } = yearElement;
      const categoryMenu = {};
      resources.forEach(category => {
        const { category_shortname } = category;
        categoryMenu[category_shortname] = category;
      });

      // Fill up missing data
      const filteredResource = fields.reduce((accField, field) => {
        const curCategory = categoryMenu[field];
        if (curCategory) {
          accField.push(curCategory);
        } else {
          const curType = filteredCategory[field].type;
          const fakeResource = {
            [curType]: {
              water_usage: 0,
              water_available: 0,
            },
          };
          const newObj = makeCategoryObj(parseInt(water_year), field, 0, curType, fakeResource);
          accField.push(newObj);
        }
        return accField;
      }, []);

      acc.push({ ...yearElement, resources: filteredResource });
      return acc;
    }, []);

    return filtered;
  };

  const makeFieldObj = (data, target, type) => {
    return {
      data: data,
      type: type,
      empty: isEmpty(data),
      target: target,
    };
  };

  const makeCategoryObj = (year, name, usage, type, resources) => {
    return {
      water_year: year,
      category_shortname: `${name} (${type})`,
      display_name: name,
      total_water_usage: usage,
      type: type,
      resources: resources,
    };
  };

  const getVal = (obj, key) => {
    if (obj) {
      return obj[key];
    }
  };

  // Components
  const renderCusomizedLegendMobile = payload => {
    return <ChartLegend payload={payload} isMobile={isMobile} />;
  };

  let usageSelectionBtn = !isEmpty(usageData) && (
    <LicenceCategorySelector
      listOfCategories={usageData[0].resources}
      onCategoryChange={onCategoryChange}
      selectedItems={licCategoryMultiSelected}
      multipleSelection
    />
  );

  const renderCustomTooltip = payload => {
    return (
      <ChartTooltip
        payload={payload}
        isPayloadArray={true}
        subElementKey="resources"
        titleKey="water_year"
        titleText="Water year"
        noStartCase
      />
    );
  };

  const refactorDownloadedData = (usageData, licCategorySelected) => {
    let finalData = [];
    if (isEmpty(usageData)) {
      return finalData;
    }
    finalData = usageData.map(water_year => {
      let result = {};
      water_year.resources.map(category => {
        if (
          licCategorySelected.includes(category.category_shortname) ||
          licCategorySelected.includes('all')
        ) {
          result[category.category_shortname] = category.total_water_usage;
        }
      });
      return {
        water_year: water_year.water_year,
        ...result,
      };
    });
    return finalData;
  };

  return (
    !isEmpty(usageData) && (
      <div className="historical-usage">
        <ChartContainer
          pageTitle={config.historicalUsageGraph.title}
          graphIntro={config.historicalUsageGraph.intro}
        >
          {!isMobile && (
            <DownloadLink
              data={refactorDownloadedData(usageData, licCategoryMultiSelected)}
              filename={`usage_${
                licCategoryMultiSelected.length > 1
                  ? `multiple`
                  : licCategoryMultiSelected[0]
                      .toLowerCase()
                      .replaceAll(' ', '_')
                      .replaceAll('(', '')
                      .replaceAll(')', '')
              }_licences_for_${waterSource.water_source_name.toLowerCase().replaceAll(' ', '_')}`}
              dataType={`historical usage`}
            />
          )}
          <SelectionBtnsGroup left={usageSelectionBtn} />
          <ChartResponsiveContainer
            isEmptyChart={isEmpty(usageData)}
            customHeight={{ desktop: 400, mobile: 400 }}
            customWidth={{ desktop: isXL ? '100%' : '98%', tablet: '98%', mobile: '98%' }}
          >
            <AreaChart
              className="composed-chart"
              width={isXL ? 100 : 78}
              data={usageData}
              margin={{ left: isMobile ? 5 : 20, bottom: 30, top: 50 }}
            >
              <XAxis
                dataKey="water_year"
                tickFormatter={v => formatLabelWaterYear(v)}
                type="category"
                dy={25}
                tick={{ fill: 'black' }}
              />
              <YAxis
                interval="preserveStartEnd"
                label={{
                  value: 'Water used (GL)',
                  angle: -90,
                  position: 'left',
                  dx: isMobile ? 5 : 0,
                  dy: -35,
                }}
                tickFormatter={v => formatNumberDecimals(v / 1000)}
                type="number"
                tick={{ fill: 'black' }}
              />
              <Tooltip
                content={e => renderCustomTooltip(e.payload)}
                cursor={{ fill: 'transparent' }}
              />
              {usageData[0].resources.map(
                (i, index) =>
                  (licCategoryMultiSelected.includes(i.category_shortname) ||
                    licCategorySelected === 'all') && (
                    <Area
                      key={i.category_shortname}
                      type="monotone"
                      fillOpacity={1}
                      name={i.display_name}
                      stackId="1"
                      dataKey={val => getVal(val.resources[index], 'total_water_usage')}
                      fill={i.color}
                      stroke={i.color}
                    />
                  ),
              )}

              <Legend
                wrapperStyle={{ bottom: -10 }}
                content={({ payload }) => renderCusomizedLegendMobile(payload)}
              />
            </AreaChart>
          </ChartResponsiveContainer>
        </ChartContainer>
      </div>
    )
  );
}

HistoricalUsageGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
};
