// Libraries
import _ from 'lodash';
import { isEmpty } from 'lodash';
import moment from 'moment';

// Helpers
import { sortArrayByKeyAsc, filterStorageVolume } from './Utils';
import { getCurrentDate, formatDate } from './TimeUtils';

// Constants
import constants from '../constants/Constants';
import apiConstants from '../constants/WaterDataAPIConstants';
import hydroDataConstants from '../constants/HydrometricsConstants';

/**
 * Function to return the conditions array grouped first by category and then by class
 */
export const groupConditionsByCategory = conditions => {
  let resultConditions = [];

  if (!_.isEmpty(conditions)) {
    resultConditions = conditions.reduce((result, item) => {
      let category;
      if (item.category) {
        category = result[item.category] = result[item.category] || {};
      } else {
        category = result['ALL CONDITIONS'] = result['ALL CONDITIONS'] || {};
      }
      let classCondition = (category[item.class] = category[item.class] || []);
      classCondition.push(item);
      return result;
    }, []);
  }
  return resultConditions;
};

/**
 * Function to return the conditions array grouped first by category and then by class
 */
export const groupStorageByDateStationId = data => {
  let resultStorage = [];

  if (!_.isEmpty(data)) {
    resultStorage = data.reduce((result, item) => {
      let date;
      if (item.date) {
        date = result[item.date] = result[item.date] || {};
      }
      let varNum = (date[item.varnum] = date[item.varnum] || {});
      varNum.push(item);
      return result;
    }, []);
  }
  return resultStorage;
};

export const renameKeyStorage = data => {
  if (!_.isEmpty(data)) {
    data.forEach(row => {
      let varNum = row.varnum;
      row[varNum] = row['value'];
      delete row['value'];
      delete row['varnum'];
      delete row['varnum_description'];
    });
  }
  return data;
};

/**
 * Function to sort the allocations array for the graph
 */
export const sumValuesFromMap = obj => {
  if (!_.isEmpty(obj)) {
    let sum = 0;
    for (let subElement in obj.resources) {
      for (let category in obj.resources[subElement]) {
        sum += obj.resources[subElement][category];
      }
    }
    return sum;
  }
};

/**
 * Function to sort the allocations array for the graph
 */
export const sumValuesFromArrayMap = array => {
  let totalSum = 0;

  if (_.isEmpty(array)) {
    return totalSum;
  }
  array.map(element => (totalSum += sumValuesFromMap(element)));
  return totalSum;
};

/**
 * Function to sort the allocations array for the graph
 */
export const sortAllocationGraph = data => {
  if (!_.isEmpty(data)) {
    return data.sort((a, b) => {
      return sumValuesFromMap(b) - sumValuesFromMap(a);
    });
  }
};

/**
 * Function to move the Reserves, Losses and Planned environmental water to the end of the graph
 */
export const moveReservesLossesToEnd = data => {
  let result = [];
  let licencedArray = [];
  let nonLicencedArray = [];
  if (_.isEmpty(data)) {
    return result;
  }

  data.forEach(element => {
    if (
      element.category_shortname === 'Reserves' ||
      element.category_shortname === 'Losses' ||
      element.category_shortname === 'Planned e-water'
    ) {
      nonLicencedArray.push(element);
    } else {
      licencedArray.push(element);
    }
  });

  return licencedArray.concat(nonLicencedArray);
};

/**
 * Function to sort the allocations array for the table by  Number of Shares
 */
export const sortAllocationTable = data => {
  if (_.isEmpty(data)) {
    return [];
  } else {
    return data.sort((a, b) => {
      return a.category_name === 'Supplementary water' ? 1 : b.number_shares - a.number_shares;
    });
  }
};

/**
 *
 * @param {*} array
 * @param {*} propName
 */
export const mergedObjectsByKey = (array, propName, varType) => {
  const grouped = _(array).groupBy(propName);
  switch (varType) {
    case 'storage':
      return grouped
        .map((objs, key) => ({
          station_id: objs.map(x => x.station_id).join(),
          name: objs.map(x => x.name).join(),
          136.61: _.sumBy(objs, '136.61'),
          136.62: _.sumBy(objs, '136.62'),
          136.63: _.sumBy(objs, '136.63'),
          date: key,
        }))
        .value();
    case 'inflows':
      return grouped
        .map((objs, key) => ({
          station_id: objs.map(x => x.station_id).join(),
          name: objs.map(x => x.name).join(),
          421.62: _.sumBy(objs, '421.62'),
          421.61: _.sumBy(objs, '421.61'),
          date: key,
        }))
        .value();
    case 'outflows':
      return grouped
        .map((objs, key) => ({
          station_id: objs.map(x => x.station_id).join(),
          name: objs.map(x => x.name).join(),
          421.63: _.sumBy(objs, '421.63'),
          date: key,
        }))
        .value();
    case 'trades':
      return grouped
        .map(objs => {
          return {
            id: objs[0].id,
            name: objs[0].name,
            lat: objs[0].lat,
            long: objs[0].long,
            perc_stage: objs[0].perc_stage,
            radius: objs[0].radius,
            water_category: objs[0].water_category,
            values: objs.map(x => {
              return {
                ...(x.trading_zone && { trading_zone: x.trading_zone }),
                average_price: x.average_price,
                traded_volume: x.traded_volume,
              };
            }),
          };
        })
        .value();
    default:
      return undefined;
  }
};

export const mergeObjectsInUnique = (array, propName) => {
  return _(array)
    .groupBy(function (item) {
      return item[propName];
    })
    .map(function (group) {
      return _.mergeWith.apply(
        _,
        [{}].concat(group, function (obj) {
          if (Array.isArray(obj)) {
            return obj;
          }
        }),
      );
    })
    .values()
    .value();
};

export const formatBoresData = array => {
  const groupedResult = _.groupBy(array, 'work_number');
  let formattedResult = [];
  for (var key in groupedResult) {
    const item = groupedResult[key][0];
    const emptyResource = { value: '-', date: null };
    formattedResult.push({
      station_id: item.work_number,
      station_name: item.work_name,
      lat: item.lat,
      long: item.long,
      date: !isEmpty(item.resources) ? item.resources[0].date : emptyResource.date,
      resources: !isEmpty(item.resources)
        ? sortArrayByKeyAsc(groupedResult[key], 'bore_id')
        : [emptyResource],
    });
  }
  return formattedResult;
};

export const findLastIndex = (array, searchKey, searchValue) => {
  var index = array
    .slice()
    .reverse()
    .findIndex(item => Object.values(item)[0][searchKey] !== searchValue);
  var count = array.length - 1;
  var finalIndex = index >= 0 ? count - index : index;
  return finalIndex;
};

export const findLatestItemIndex = (array, key, value) => {
  if (isEmpty(array)) {
    return -1;
  }
  return array.findIndex(item => item[key] !== value);
};

export const valueCalcInGL = value => {
  const valueInGL = value / 1000;
  return Number(valueInGL.toFixed(2));
};

export const formatStationData = (station, id, name, dateFormat = '') => {
  if (station && !isEmpty(station.data) && !isEmpty(station.data.resources)) {
    const dataEntries = Object.entries(station.data.resources[0]);
    const processedData = dataEntries.reduce((acc, [key, value]) => {
      const latestKey = findLatestItemIndex(station.data.resources, key, null);
      const latestItem = station.data.resources[latestKey];
      const valueDate = latestItem
        ? dateFormat
          ? moment(latestItem.date).format(dateFormat)
          : latestItem.date
        : '-';

      switch (key) {
        case 'inflow':
        case 'release':
        case 'volume':
          acc[key] = {
            value:
              latestItem && (latestItem[key] || latestItem[key] === 0)
                ? valueCalcInGL(latestItem[key])
                : '-',
            isLatest: latestKey === 0,
            date: valueDate,
            unit: 'GL',
          };
          return acc;
        case 'volume_perc':
        case 'level':
        case 'flow_rate':
          acc[key] = {
            value:
              latestItem && (latestItem[key] || latestItem[key] === 0)
                ? Number(latestItem[key].toFixed(2))
                : '-',
            isLatest: latestKey === 0,
            date: valueDate,
            unit: key === 'volume_perc' ? '%' : key === 'level' ? 'm' : 'ML',
          };
          return acc;
        case 'date':
          acc[key] = dateFormat ? moment(value).format(dateFormat) : value;
          return acc;
        default:
          return acc;
      }
    }, {});
    return { ...processedData, station_id: id, station_name: name };
  } else {
    return {
      date: getCurrentDate(dateFormat),
      volume: { value: '-', isLatest: false, date: '-' },
      volume_perc: { value: '-', isLatest: false, date: '-' },
      inflow: { value: '-', isLatest: false, date: '-' },
      release: { value: '-', isLatest: false, date: '-' },
      station_id: id,
      station_name: name,
    };
  }
};

export const formatSurfaceWaterData = (data, id, name, fullVolume = null, dateFormat = '') => {
  const hydroKeyMap = apiConstants.HYDROMETRIC_VARIABLES_MAP;
  const defaultValue = { value: '-', isLatest: false };
  const result = { station_id: id, station_name: name };
  const unitConversion = { ML: 'GL', 'Percentage (%)': '%' };

  if (data && !isEmpty(data.resources)) {
    let clonedData = filterStorageVolume(data.resources);
    clonedData.map(item => {
      if (Object.keys(hydroKeyMap).includes(item.variableName)) {
        if (
          !result.date ||
          moment(item.timeStamp, dateFormat).valueOf() > moment(result.date, dateFormat).valueOf()
        ) {
          result.date = item.timeStamp;
        }
        const dataKey = hydroKeyMap[item.variableName];
        if (!result[dataKey]) {
          result[dataKey] = {
            value: item.unitOfMeasure === 'ML' ? item.value / 1000 : item.value,
            isLatest: result.date === item.timeStamp,
            date: formatDate(item.timeStamp, constants.STORAGE_DATE_FORMAT),
            unit: unitConversion[item.unitOfMeasure] || item.unitOfMeasure,
            qualityCode: item?.qualityCode,
            decimals: hydroDataConstants.DATA_LABEL_FORMAT[dataKey]?.decimals,
          };
        }
      }
    });
  }
  apiConstants.DAM_STORAGE_VARIABLES.map(variableItem => {
    const resultKey = Object.keys(result);
    if (!resultKey.includes(hydroKeyMap[variableItem])) {
      if (variableItem === 'ActiveStoragePercentage' && fullVolume && result.volume.value !== '-') {
        result[hydroKeyMap[variableItem]] = {
          ...defaultValue,
          value: ((result.volume.value * 1000) / fullVolume) * 100,
          unit: '%',
        };
      } else {
        result[hydroKeyMap[variableItem]] = defaultValue;
      }
    }
  });
  return result;
};

export const getWaterSharingPlan = () => {
  // Extract data from local cache
  const waterSourceList = JSON.parse(localStorage.getItem('waterSourceList'));
  const uniqueNum = new Set();
  const resultArr = [];
  if (waterSourceList.name === 'Error') {
    return resultArr;
  }

  waterSourceList.forEach(waterSource => {
    const { id, name, url } = !isEmpty(waterSource) && waterSource.water_sharing_plan;
    if (id && !uniqueNum.has(id)) {
      uniqueNum.add(id);
      resultArr.push({
        id: id,
        name: name,
        title: name,
        url: url,
      });
    }
  });
  return resultArr;
};
