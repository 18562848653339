// Library
import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import { LineChart, Line, XAxis, YAxis, Tooltip } from 'recharts';
import { isEmpty } from 'lodash';

// Component
import ChartContainer from '../chartProperties/ChartContainer';
import ChartResponsiveContainer from '../chartProperties/ChartResponsiveContainer';
import SelectionBtnsGroup from '../storage/childProperties/SelectionBtnsGroup';
import SelectionBtns from '../storage/childProperties/SelectionBtns';

// Context
import { MobileContext } from '../../contexts/MobileContext';
import { AppContext } from '../../contexts/AppContext';

// Assest
import { chartText } from './usageText.json';

// Helper
import { getUsageReleaseDataById } from '../../helpers/ApiHelper';
import { formatNumberDecimals } from '../../helpers/Utils';
import { getCurrentDate } from '../../helpers/TimeUtils';

/* Constants */
const INTERVAL_PROPS = [
  { name: 'daily', dataKey: 'DAILY', content: [] },
  { name: 'monthly', dataKey: 'MONTHLY', content: [] },
  { name: 'yearly', dataKey: 'YEARLY', content: [] },
];

const UsageGraph = () => {
  const dataProps = INTERVAL_PROPS;
  const toggleOptions = dataProps.map(item => item.name.toUpperCase());
  const [activeInterval, setActiveInterval] = useState(toggleOptions[0]);
  const [activeYear, setActiveYear] = useState(1);
  const [rawData, setRawData] = useState({});
  const [graphData, setGraphData] = useState([]);
  const { isMobile } = useContext(MobileContext);
  const { waterSource } = useContext(AppContext);

  // Helper
  const loadInitialData = data => {
    setRawData(data);
    filterAndSetData(data, activeYear);
  };

  const filterAndSetData = (data, year) => {
    const endTime = getCurrentDate(`YYYY ${activeInterval !== 'YEARLY' ? 'MM' : ''}`);
    const startTime = moment()
      .subtract(year, 'years')
      .format(`YYYY ${activeInterval !== 'YEARLY' ? 'MM' : ''}`);
    const resultData = data.filter(dateItem => {
      const curDate = dateItem.value_date;
      if (
        curDate &&
        moment(curDate).isSameOrBefore(endTime) &&
        moment(curDate).isSameOrAfter(startTime)
      ) {
        return true;
      }
      return false;
    });
    setGraphData(resultData);
  };

  // Lifecycle
  useEffect(() => {
    let unmounted = false;
    let intervalPropsItem = dataProps.find(interval => interval.dataKey === activeInterval);
    if (isEmpty(intervalPropsItem.content)) {
      (async () => {
        const data = await getUsageReleaseDataById(
          waterSource.water_source_id,
          activeInterval.toLowerCase(),
        );

        if (!unmounted && !isEmpty(data)) {
          intervalPropsItem.content = data;
          loadInitialData(data);
        }
      })();
    } else {
      loadInitialData(intervalPropsItem.content);
    }

    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeInterval]);

  return (
    <ChartContainer
      pageTitle={chartText.title}
      graphTitle={chartText.title}
      graphIntro={chartText.desc}
    >
      <SelectionBtnsGroup
        left={
          <SelectionBtns
            type="interval"
            itemList={toggleOptions}
            onBtnChange={interval => {
              setActiveInterval(interval);
            }}
            defaultValue={activeInterval}
          />
        }
        right={
          <SelectionBtns
            type="year"
            itemList={[1, 5, 10]}
            onBtnChange={year => {
              setActiveYear(year);
              filterAndSetData(rawData, year);
            }}
            defaultValue={activeYear}
          />
        }
      />
      <ChartResponsiveContainer isEmptyChart={isEmpty(graphData)}>
        <LineChart width={700} margin={{ left: 30, bottom: 50 }} data={graphData}>
          <XAxis
            interval={'preserveStartEnd'}
            dataKey="value_date"
            tickFormatter={v => moment(v, 'YYYY-MM').format('YYYY MMM')}
            angle={-60}
            dy={25}
          />
          <YAxis
            interval="preserveStartEnd"
            width={isMobile ? 15 : 60}
            angle={isMobile ? -60 : 0}
            label={isMobile ? '' : { value: `Usage (ML)`, angle: -90, dx: -50 }}
            tickFormatter={v => formatNumberDecimals(v)}
          />
          <Line
            strokeWidth={2}
            stroke="#0054a6"
            dot={!(activeInterval === 'YEARLY' && activeYear === 1) ? false : true}
            name="Usage"
            dataKey="value"
          />
          <Tooltip
            labelFormatter={v =>
              moment(v, 'YYYY-MM-DD').format(`YYYY MMM ${activeInterval === 'DAILY' ? 'DD' : ''}`)
            }
            formatter={(value, name) => [formatNumberDecimals(Number(value)) + ' ML', name]}
            cursor={{ fill: 'transparent' }}
          />
        </LineChart>
      </ChartResponsiveContainer>
    </ChartContainer>
  );
};

export default UsageGraph;
