// Library
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { isNil, isEmpty, capitalize } from 'lodash';
import moment from 'moment';

// Style
import './tradingCategory.scss';

// Asset
import TRADETEXT from './tradingText.json';

// Component
import DataSummaryTable from '../../dataSummary/DataSummaryTable';
import DataSummaryGraph from '../../dataSummary/DataSummaryGraph';
import DataSummary from '../../dataSummary/DataSummary';
import DataSummaryHeader from '../../dataSummary/DataSummaryHeader';
import Modal from '../../modal/Modal';
import InfoButton from '../../informationButton/InformationButton';
import ParagraphSection from '../../paragraphSection/ParagraphSection';

// Context
import { MobileContext } from '../../../contexts/MobileContext';
import { AppContext } from '../../../contexts/AppContext';

// Helpers
import { formatNumberDecimals } from '../../../helpers/Utils';
import { mixInDefault } from '../../../helpers/ProcessDataHelper';

// Constant
import constant from '../../../constants/Constants';
const { MURRUMBIDGEE_REGULATED_RIVER_WATER_SOURCE } = constant;
const container = 'trading-category';
const ENTITLEMENT = 'Entitlement';
const ALLOCATION = 'Allocation';
const ALLOCATIONTEXT = TRADETEXT.allocation.header;
const ALLOCATIONTEXTMODAL = TRADETEXT.allocation.modal;
const ENTITLEMENTTEXTMODAL = TRADETEXT.entitlement.modal;
const ENTITLEMENTTEXT = TRADETEXT.entitlement.header;
const TRADE_CONSTANT = {
  VOLUME: {
    category: 'VOLUME',
    name: ALLOCATIONTEXT.volume,
    abbreviation: ALLOCATIONTEXT.volume_abbreviation,
    dataKey: 'volume',
    unit: 'ML',
    mobileHide: false,
    color: '#0054a6',
  },
  SHARES: {
    category: 'SHARES',
    name: ENTITLEMENTTEXT.shares,
    abbreviation: ENTITLEMENTTEXT.shares_abbreviation,
    dataKey: 'shares',
    mobileHide: false,
    color: '#0054a6',
  },
  PRICE: {
    category: 'AVERAGE PRICE',
    name: ALLOCATIONTEXT.price,
    abbreviation: ALLOCATIONTEXT.price_abbreviation,
    dataKey: 'average_price',
    mobileHide: false,
    color: '#0054a6',
    enti_unit: 'share',
    alloc_unit: 'ML',
  },
  TRADES: {
    category: 'TRADE',
    name: ALLOCATIONTEXT.trade,
    abbreviation: ALLOCATIONTEXT.trade_abbreviation,
    dataKey: 'valid_trades',
    mobileHide: false,
    color: '#0054a6',
  },
  ZERO_DOLLAR_VOL_SHARES: {
    category: 'SHARES',
    name: ENTITLEMENTTEXT.zeroDollarVolume,
    dataKey: 'zero_dollar_volume',
    mobileHide: true,
    color: '#f36c21',
  },
  ZERO_DOLLAR_VOL: {
    category: 'VOLUME',
    name: ALLOCATIONTEXT.zeroDollarVolume,
    dataKey: 'zero_dollar_volume',
    unit: 'ML',
    mobileHide: true,
    color: '#f36c21',
  },
  ZERO_DOLLAR_TRADE: {
    category: '$0 TRADE',
    name: ALLOCATIONTEXT.zeroDollarTrade,
    dataKey: 'zero_dollar_trades',
    mobileHide: true,
    color: '#f36c21',
  },
  IRR_VOLUME: {
    category: 'VOLUME',
    name: ALLOCATIONTEXT.irrigationVolume,
    dataKey: 'irrigation_volume',
    unit: 'ML',
    mobileHide: true,
    color: '#bed12a',
    noCap: true,
  },
  IRR_TRADE: {
    category: 'IRRIGATION TRADE',
    name: ALLOCATIONTEXT.irrigationTrade,
    dataKey: 'irrigation_trades',
    mobileHide: true,
    noCap: true,
  },
};

const TradingCategory = ({ header, data, marketType }) => {
  const {
    VOLUME,
    SHARES,
    PRICE,
    TRADES,
    ZERO_DOLLAR_VOL_SHARES,
    ZERO_DOLLAR_VOL,
    ZERO_DOLLAR_TRADE,
    IRR_VOLUME,
    IRR_TRADE,
  } = TRADE_CONSTANT;
  const toggleOptions =
    data.market_type === ALLOCATION
      ? [PRICE.category, VOLUME.category]
      : [PRICE.category, SHARES.category];
  const { isLarge, isMobile } = useContext(MobileContext);
  const { waterSource } = useContext(AppContext);
  const [latest, setLatest] = useState({});
  const [sortedData, setSortedData] = useState([]);
  const [graphDisplay, setGraphDisplay] = useState(PRICE.category);
  const [graphContent, setGraphContent] = useState({});
  const [selected, setSelected] = useState(false);

  // Lifecycle
  useEffect(() => {
    if (data) {
      const tempLatestData = getLatestValidData(data);
      setLatest(tempLatestData);
      setSortedData(sortContentData(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLarge, isMobile]);

  useEffect(() => {
    setGraphContent(getGraphData(sortedData, graphDisplay));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedData, graphDisplay]);

  // Helper
  const getLatestValidData = arrData => {
    const { resources } = arrData;
    for (let i = resources.length - 1; i > -1; i--) {
      const cur = resources[i];
      if (!isNil(cur.date)) {
        return cur;
      }
    }
  };

  const monthRange = () => {
    return isLarge ? (isMobile ? 13 : 7) : 13;
  };

  const addEmptyContent = targetObj => {
    return {
      ...targetObj,
      content: [],
    };
  };

  const getGraphData = (arrData, target) => {
    if (target === VOLUME.category || target === SHARES.category) {
      let resultObj = target === VOLUME.category ? VOLUME : SHARES;
      const resultContent = [];
      const resultKeys = [];

      // Blending data into one array
      arrData.forEach(categoryItem => {
        if (categoryItem.category === target && !isEmpty(categoryItem.content)) {
          resultKeys.push({
            name: categoryItem.name,
            color: categoryItem.color,
          });
          if (isEmpty(resultContent)) {
            categoryItem.content.forEach(dateItem => {
              resultContent.push({
                date: dateItem.date,
                [categoryItem.name]: dateItem.value,
              });
            });
          } else {
            categoryItem.content.forEach(dateItem => {
              const target = resultContent.find(resDateItem => resDateItem.date === dateItem.date);
              target[categoryItem.name] = dateItem.value;
            });
          }
        }
      });
      resultObj.content = resultContent;
      resultObj.stackKeys = resultKeys;
      return resultObj;
    }

    const targetItem = arrData.find(
      dataItem => dataItem.category.toLowerCase() === target.toLowerCase(),
    );
    return targetItem;
  };

  const sortContentData = data => {
    const { market_type, category_type } = data;
    const completeData = mixInDefault(data.resources, monthRange(), 'months', 0, 'YYYY-MM');
    const sorted = [
      addEmptyContent({
        ...PRICE,
        unit: market_type === ENTITLEMENT ? PRICE.enti_unit : PRICE.alloc_unit,
      }),
      addEmptyContent(TRADES),
    ];

    if (market_type === ENTITLEMENT) {
      sorted.push(addEmptyContent(SHARES));
      sorted.push(addEmptyContent(ZERO_DOLLAR_VOL_SHARES));
    } else {
      sorted.push(addEmptyContent(VOLUME));
      sorted.push(addEmptyContent(ZERO_DOLLAR_VOL));
      if (
        category_type === ALLOCATION &&
        waterSource.water_source_id === MURRUMBIDGEE_REGULATED_RIVER_WATER_SOURCE
      ) {
        sorted.push(addEmptyContent(IRR_VOLUME));
        sorted.push(addEmptyContent(IRR_TRADE));
      }
    }
    sorted.splice(3, 0, addEmptyContent(ZERO_DOLLAR_TRADE));

    completeData.forEach(dateItem => {
      sorted.forEach(sortedItem => {
        sortedItem.content.push({
          date: dateItem.date,
          value:
            sortedItem.dataKey === PRICE.dataKey
              ? convertCentsToDollar(dateItem[sortedItem.dataKey])
              : dateItem[sortedItem.dataKey],
        });
      });
    });
    return sorted;
  };

  const convertCentsToDollar = price => {
    const result = price / 100;
    return result ? result : null;
  };

  const returnZeroIfNull = data => {
    return data ? data : '0';
  };

  const formatTooltip = (value, unit, display, name, itemName) => {
    const valueToolTip =
      display === PRICE.category
        ? `$${formatNumberDecimals(value, false, false)}/${unit}`
        : `${formatNumberDecimals(value, false, true)} ${unit ? unit : ''}`;
    return [valueToolTip, display === PRICE.category ? itemName : name];
  };

  // Component
  const tradeCategory = () => {
    if (!isEmpty(latest)) {
      const { date, average_price, volume, shares, valid_trades } = latest;
      return (
        <div className={`${container}-grid`}>
          <div className={`${container}-grid-first ${container}-grid-row-head`}>{data.zone}</div>
          <div className={`${container}-grid-first ${container}-grid-row-head`}>
            {data.category_shortname || data.category_type.replace(/Regulated River |[()]/g, '')}
          </div>
          <div className={`${container}-grid-item ${container}-lg-hide`}>
            {volume
              ? returnZeroIfNull(formatNumberDecimals(volume))
              : returnZeroIfNull(formatNumberDecimals(shares))}
          </div>
          <div className={`${container}-grid-item`}>
            {average_price ? `$${formatNumberDecimals(average_price / 100)}` : 'N/A'}
          </div>
          <div className={`${container}-grid-item ${container}-mobile-hide`}>
            {returnZeroIfNull(formatNumberDecimals(valid_trades))}
          </div>
          {!isMobile && (
            <div className={`${container}-grid-item`}>
              {moment(date, 'YYYY-MM-DD').format('MMM YYYY')}
            </div>
          )}
        </div>
      );
    }
  };

  // Component
  const modalMaker = (type, modal_type) => {
    const paragraph = modal_type.find(paragraph => paragraph.paragraphTitle === type);
    if (paragraph) {
      return <ParagraphSection sectionTitle="" sectionContent={paragraph.paragraph} />;
    }
  };

  const tradeCategoryHeader = () => {
    if (!isEmpty(latest)) {
      const { zone, type, volume, price, trade, time, shares } = TRADETEXT[marketType].header;
      const { market_type } = data;
      const { volume_unit } = latest;
      const pricePerUnit = `$/${volume_unit}`;

      return (
        <>
          <div className={`${container}-grid`}>
            <Modal id="trading-zone" title={zone} body={modalMaker(zone, ALLOCATIONTEXTMODAL)} />
            <div className={`${container}-grid-first`}>
              {zone}
              <InfoButton targetDataId="#trading-zone" iconColor={`${container}-title-icon`} />
            </div>
            <Modal id="trading-type" title={type} body={modalMaker(type, ALLOCATIONTEXTMODAL)} />
            <div className={`${container}-grid-first`}>
              {type}
              <InfoButton targetDataId="#trading-type" iconColor={`${container}-title-icon`} />
            </div>
            <Modal
              id={`${market_type}-trading-volume-unit`}
              title={market_type === 'Entitlement' ? shares : volume}
              body={modalMaker(
                market_type === 'Entitlement' ? shares : volume,
                market_type === 'Entitlement' ? ENTITLEMENTTEXTMODAL : ALLOCATIONTEXTMODAL,
              )}
            />
            <div className={`${container}-lg-hide`}>
              {`${market_type === 'Entitlement' ? shares : volume} ${
                volume_unit ? `(${volume_unit})` : ''
              }`}
              <InfoButton
                targetDataId={`#${market_type}-trading-volume-unit`}
                iconColor={`${container}-title-icon`}
              />
            </div>
            <Modal
              id={`${market_type}-trading-price-unit`}
              title={`${price} ${volume_unit ? `(${pricePerUnit})` : ''}`}
              body={modalMaker(
                price,
                market_type === 'Entitlement' ? ENTITLEMENTTEXTMODAL : ALLOCATIONTEXTMODAL,
              )}
            />
            <div>
              {`${price} ${volume_unit ? `(${pricePerUnit})` : ''}`}
              <InfoButton
                targetDataId={`#${market_type}-trading-price-unit`}
                iconColor={`${container}-title-icon`}
              />
            </div>
            <Modal
              id={`${market_type}-trading-trade`}
              title={trade}
              body={modalMaker(
                trade,
                market_type === 'Entitlement' ? ENTITLEMENTTEXTMODAL : ALLOCATIONTEXTMODAL,
              )}
            />
            <div className={`${container}-mobile-hide`}>
              {trade}
              <InfoButton
                targetDataId={`#${market_type}-trading-trade`}
                iconColor={`${container}-title-icon`}
              />
            </div>
            {!isMobile && <div>{time}</div>}
          </div>
        </>
      );
    }
  };

  return (
    <div className={container}>
      {!isEmpty(latest) && (
        <>
          {header ? (
            <DataSummaryHeader header={tradeCategoryHeader()} />
          ) : (
            <DataSummary
              header={tradeCategory()}
              id={data.category_type}
              selected={selected}
              onExpand={state => {
                setSelected(state);
              }}
            >
              <div className={`${container}-details`}>
                <DataSummaryTable
                  page="trading"
                  tableHeader={
                    data.category_shortname ||
                    data.category_type.replace(/Regulated River |[()]/g, '')
                  }
                  tableContent={sortedData}
                />
                {!isEmpty(graphContent) && (
                  <DataSummaryGraph
                    chartTitle={`${graphDisplay.toUpperCase()} - PAST ${monthRange()} MONTHS`}
                    chartContent={graphContent.content}
                    toggle
                    toggleOptions={toggleOptions}
                    onToggleClick={e => {
                      setGraphDisplay(e);
                    }}
                    YAxisName={`${capitalize(graphContent.category)}${
                      graphContent.unit
                        ? ` (${graphDisplay === PRICE.category ? '$/' : ''}${graphContent.unit})`
                        : ''
                    }`}
                    graphType={`${graphDisplay === PRICE.category ? 'line' : 'bar'}`}
                    toolTipFormatter={(value, name) =>
                      formatTooltip(value, graphContent.unit, graphDisplay, name, graphContent.name)
                    }
                    stacked
                    stackKeys={graphContent.stackKeys}
                  />
                )}
              </div>
            </DataSummary>
          )}
        </>
      )}
    </div>
  );
};

export default TradingCategory;

TradingCategory.propTypes = {
  header: PropTypes.bool,
  data: PropTypes.shape({
    zone: PropTypes.string,
    category_type: PropTypes.string,
    category_shortname: PropTypes.string,
    market_type: PropTypes.string,
    available_shares: PropTypes.number,
    resources: PropTypes.array,
  }),
  marketType: PropTypes.string,
};
