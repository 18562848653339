import React, { useState, createContext } from 'react';

export const ExpandBoxContext = createContext();

export default function ExpandBoxContextProvider(props) {
  const [boxToExpand, setBoxToExpand] = useState();

  function toggleExpandBox(targetId) {
    setBoxToExpand(targetId);
  }

  return (
    <ExpandBoxContext.Provider value={{ boxToExpand, toggleExpandBox }}>
      {props.children}
    </ExpandBoxContext.Provider>
  );
}
