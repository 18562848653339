import React, { useState, createContext } from 'react';

//helpers
import PropTypes from 'prop-types';

// Constant
import constants from '../components/pageComponents/download/new/DataDownloadConstants';
export const DownloadContext = createContext();
export default function DownloadContextProvider({ children }) {
  // Shared
  const [requestedSiteId, setRequestedSiteId] = useState('');
  const [requestedHydrometric, setRequestedHydrometric] = useState('');
  const [autoFetch, setAutoFetch] = useState(false);

  // Context
  const [downloadType, setDownloadType] = useState(constants.TAB_OPTIONS[0].id);
  const [selectedStations, setSelectedStations] = useState([]);
  const [stationInfoType, setStationInfoType] = useState('');
  const [hydrometric, setHydrometric] = useState([]);
  const [newDataType, setNewDataType] = useState(constants.DATA_TYPES[1]);
  const [newInterval, setNewInterval] = useState(constants.INTERVAL_SELECTIONS[2]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [genGraph, setGenGraph] = useState(false);
  const [ratingTableFormat, setRatingTableFormat] = useState({});
  const [timePeriod, setTimePeriod] = useState(constants.TIME_PERIOD[4]);

  const context = {
    downloadType,
    setDownloadType,
    selectedStations,
    setSelectedStations,
    stationInfoType,
    setStationInfoType,
    hydrometric,
    setHydrometric,
    dataType: newDataType,
    setDataType: setNewDataType,
    interval: newInterval,
    setInterval: setNewInterval,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    requestedSiteId,
    setRequestedSiteId,
    requestedHydrometric,
    setRequestedHydrometric,
    genGraph,
    setGenGraph,
    autoFetch,
    setAutoFetch,
    ratingTableFormat,
    setRatingTableFormat,
    timePeriod,
    setTimePeriod,
  };

  return <DownloadContext.Provider value={context}>{children}</DownloadContext.Provider>;
}

DownloadContextProvider.propTypes = {
  children: PropTypes.any,
};
