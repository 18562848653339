// Library
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// Styles
import './mandatoryConditions.scss';

// Context
import { MobileContext } from '../../contexts/MobileContext';

// Components
import ExternalLinkModal from '../../components/modal/ExternalLinkModal';

// Helpers
import { createMarkup } from '../../helpers/Utils';

// Assets
import externalLink from '../../image/icons/external-link.png';

const RulesLink = ({
  link,
  linkText,
  text,
  extraInfo,
  setExternalSiteLink,
  targetId,
  disableMobileCheck,
}) => {
  const { isMobile } = useContext(MobileContext);

  return link ? (
    <>
      {isMobile && <ExternalLinkModal link={link} modalId={targetId} />}
      <div className="conditions-dealing-sentence">
        <span dangerouslySetInnerHTML={createMarkup(text)} />
        <a
          target="_blank"
          rel="noopener noreferrer"
          className="rules-intro-wsp"
          href={link}
          data-toggle={isMobile && !disableMobileCheck ? 'modal' : ''}
          data-target={isMobile && !disableMobileCheck ? `#${targetId}` : ''}
          onClick={setExternalSiteLink ? () => setExternalSiteLink(link) : null}
        >
          {linkText}
          <img
            src={externalLink}
            className="external-link-icon"
            alt="Link icon linking to the source of the rules"
          />
        </a>
        {extraInfo && <span dangerouslySetInnerHTML={createMarkup(extraInfo)} />}
      </div>
    </>
  ) : (
    <>{''}</>
  );
};

export default RulesLink;

RulesLink.propTypes = {
  link: PropTypes.string,
  linkText: PropTypes.string,
  text: PropTypes.string,
  extraInfo: PropTypes.string,
  setExternalSiteLink: PropTypes.func,
  targetId: PropTypes.string,
  disableMobileCheck: PropTypes.bool,
};
