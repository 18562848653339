// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './dailyUpdates.scss';

// Components
import DailyUpdateItem from './DailyUpdateItem';
import InfoButton from '../../informationButton/InformationButton';

export default function DailyUpdates({ isForWaterSource = false, data, timestamp }) {
  const container = 'daily-updates';
  const subContainer = isForWaterSource ? 'ws-daily-updates' : 'daily-updates';

  return (
    <div className={`${container}-container`}>
      <div className={`row ${subContainer}-title`}>
        <span>{isForWaterSource ? 'All Dams' : 'Daily Update'}</span>
        {!isForWaterSource && (
          <div className={`${subContainer}-icon`}>
            <InfoButton targetDataId="#dailyUpdatesModal" iconColor={`${subContainer}-icon-info`} />
          </div>
        )}
      </div>
      <div className={`${subContainer}-body`}>
        {data.map((item, index) => (
          <DailyUpdateItem
            key={index}
            labelText={item.label}
            value={item.value}
            valueUnit={item.unit}
            isPrimary={item.label === 'pct full'}
            isForWaterSource={isForWaterSource}
            rounded={item.rounded}
            isLatest={item.isLatest}
            singleDecimal={item.singleDecimal}
          />
        ))}
      </div>
      <div className={`${subContainer}-timestamp`}>*{timestamp}</div>
    </div>
  );
}

DailyUpdates.propTypes = {
  isForWaterSource: PropTypes.bool,
  timestamp: PropTypes.string,
  data: PropTypes.array,
};
