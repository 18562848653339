// Libraries
import axios from 'axios';
import { isEmpty, groupBy, isNil, keyBy, merge, cloneDeep, startCase } from 'lodash';
import moment from 'moment';

// Helpers
import { formatDate, roundDownTimeStamp } from './TimeUtils.jsx';
import { mergeByDate, formatLatestWaterData } from './Utils';
import { dataMapFromWaterDataToStation } from './WaterDataUtils';
import { getAllStationsByType } from './ApiHelper.jsx';

// Constants
import apiConstants from '../constants/WaterDataAPIConstants';
import constants from '../constants/Constants';

const apiBaseURL = constants.BASE_URL;
const wnswApiBaseURL = constants.WNSW_API_BASE_URL;
const TIMEOUT = 60000;
const endpointProps = apiConstants.WATER_DATA_TIMESERIES;
const dateFormat = apiConstants.API_DATE_FORMAT;
const dateLimit = apiConstants.AUTOQC_DATE_RESTRICTION;

/**

 ---------- Water API General Util ----------
 
 */
const getHeader = () => {
  return {
    'Ocp-Apim-Subscription-Key': constants.WNSW_SERVICES_API_KEY,
  };
};

const getDownloadHeader = () => {
  return {
    'Ocp-Apim-Subscription-Key': constants.WNSW_SERVICES_DATA_API_KEY,
  };
};

/**
 * This is meant to be the foundation of all GET Water Api call
 *
 * @param {*} params, params for the api call
 * @param {*} url, target endpoint
 * @param {*} dataKey, property name of the data field in API response
 * @returns compilation of data of all pages
 */
const getWaterAPIResult = async (
  params,
  endpoint,
  dataKey,
  squashError = true,
  isDownload = false,
  siteType,
  signal,
) => {
  const newParams =
    params.frequency === '15-minute' ? { ...params, frequency: 'instantaneous' } : params;
  try {
    const baseUrl =
      endpoint.includes('rating-tables') ||
      (siteType === 'groundwater' && !endpoint.includes('download')) ||
      (endpoint.includes('meteorological') && endpoint.includes('download')) ||
      endpoint.includes('services/water-data') ||
      dataKey === 'sites'
        ? apiBaseURL
        : wnswApiBaseURL;
    const res = await axios.get(`${baseUrl}${endpoint}`, {
      timeout: TIMEOUT,
      params: newParams,
      headers: isDownload ? getDownloadHeader() : getHeader(),
      crossdomain: true,
      ...(signal && { signal }),
    });

    const data = dataKey && dataKey !== '' ? res?.data[dataKey] : res?.data;
    if (isEmpty(data) && !squashError) {
      const err = new Error('No Data');
      err.response = { status: 204 };

      throw err;
    }
    return data;
  } catch (e) {
    if (squashError) {
      return [];
    }
    throw e;
  }
};

/**
 * This is for making sure all object in a array all have the same properties
 *
 * Note:
 * This function exists because Rechart will not graph certain line
 * if the first object of the array does not have that property.
 * Therefore, ensuring all object have the same properties,
 * will make it easier for Rechart to graph correctly
 * @param {*} resource, an array of objects
 * @param {*} damId, dam unique ID
 * @returns an array with uniform properties
 */
export const generateUniformFields = (resource, damId, forecastResource) => {
  const uniqueField = new Set();
  resource.forEach(resourceItem => {
    const fields = Object.keys(resourceItem);
    fields.forEach(item => uniqueField.add(item));
  });

  const res = resource
    .filter(item => item.siteId === damId)
    .map(resourceItem => {
      const filled = { ...resourceItem };
      uniqueField.forEach(fieldItem => {
        if (isNil(filled[fieldItem])) {
          filled[fieldItem] = null;
        }
      });
      return filled;
    });
  if (forecastResource) {
    forecastResource.forEach(item => {
      res.push({
        timeStamp: moment(item.date).format(constants.STORAGE_DATE_FORMAT),
        ...(item.volume_forecast && { volume_forecast: item.volume_forecast }),
        ...(item.release_forecast && { release_forecast: item.release_forecast }),
        siteId: damId,
      });
    });
  }

  return res;
};

/**
 * Aggregate all daily data from Water API, turning it into cumulative monthly data
 *
 * Note:
 * This function is needed solely becuase the Water API does not support this functionality
 * If the Water API starts to return accumulated daily data instead of just returning data of the last day of each month
 * Then, this can be removed.
 * @param {*} data (result of Water API with daily interval)
 * @returns monthly data, accumulated from the daily data
 */
export const aggregateDailyintoMonthly = data => {
  // Only inflow and release data need to be aggregated
  const APIFIELDS = apiConstants.API_FIELDS;
  const flowFields = [
    APIFIELDS.Inflow,
    APIFIELDS.InflowSurface,
    APIFIELDS.Release,
    APIFIELDS.ReleaseSurface,
    APIFIELDS.Volume,
  ];

  const groupedByStation = groupBy(data, 'siteId');
  const formattedResources = [];

  // Iterate each station's data
  for (const entries of Object.values(groupedByStation)) {
    const latestDateByVariable = {};
    const aggregatedStationData = entries.reduce((prev, entryItem) => {
      // Find item's month, name, and corresponding object in accumulating object
      const targetMonth = formatDate(entryItem.timeStamp, dateFormat, '01-MMM-YYYY 00:00');
      const targetItemName = `${targetMonth}_${entryItem.variableName}`;
      const targetMonthItem = prev[targetItemName];

      // Aggregate if flow data
      // Pick the latest if anything else
      if (targetMonthItem && flowFields.includes(entryItem.variableName)) {
        const curMonthItem = prev[targetItemName];
        const aggregatedVal = curMonthItem.value + entryItem.value;
        prev[targetItemName] = { ...curMonthItem, value: aggregatedVal };
      } else if (targetMonthItem) {
        const latestTimestamp = latestDateByVariable[targetItemName];
        if (!latestTimestamp || moment(entryItem.timeStamp).isAfter(latestTimestamp)) {
          prev[targetItemName] = { ...entryItem, timeStamp: targetMonth };
          latestDateByVariable[targetItemName] = entryItem.timeStamp;
        }
      } else {
        prev[targetItemName] = { ...entryItem, timeStamp: targetMonth };
      }
      return prev;
    }, {});
    formattedResources.push(...Object.values(aggregatedStationData));
  }
  return formattedResources;
};

export const resolvePromises = async promises => {
  const response = await Promise.allSettled(promises);
  const finalResponse = response.reduce((acc, res) => {
    if (res.status === 'fulfilled' && !isEmpty(res.value)) {
      acc.push(res.value);
    }
    return acc;
  }, []);
  return finalResponse;
};

/**

 ---------- Site Meta Data Util ----------
 
 */
export const getSiteMetadata = async (siteId, siteType, siteSubType, siteName, signal) => {
  const endpoint = apiConstants.WATER_DATA_API_SITE_METADATA;
  const params = { siteId, siteType, siteSubType, siteName };
  const result = await getWaterAPIResult(params, endpoint, 'sites', true, false, '', signal);

  if (siteId) {
    const siteIds = siteId.split(',');
    return result.filter(resultItem => {
      return siteIds.includes(resultItem.siteId);
    });
  }

  return result;
};

export const getMultiSiteMetadata = async (siteIds, siteType, siteSubType) => {
  const cloned = [...siteIds];
  let siteIdsList = [];
  while (cloned.length) {
    siteIdsList.push(cloned.splice(0, 20).join(','));
  }
  const sitesPromises = siteIdsList.map(siteId => {
    return getSiteMetadata(siteId, siteType, siteSubType);
  });

  let results = await resolvePromises(sitesPromises);

  return results.flat();
};

export const combineSiteMetadata = async (siteId, siteType, siteSubType, siteName, signal) => {
  const siteTypes = {
    Weirs: 'weir',
    StreamGauge: 'gauge',
    Groundwater: 'bore',
    Storage: 'dam',
  };
  const params = { siteId, siteType, siteSubType, siteName };
  const endpoint = apiConstants.WATER_DATA_API_SITE_METADATA;
  let result = await getWaterAPIResult(params, endpoint, 'sites', true, false, '', signal);
  result = result.filter(item => item.active === 1 && !isEmpty(item.variablesMonitored));
  if (siteType !== 'Groundwater') {
    result = result.filter(item => item.siteId.charAt(0).match(/^\d/));
  }

  result = result.map(item => {
    if (!item.station_id) {
      return {
        ...item,
        station_id: item.siteId,
        station_name: startCase(item.siteName.toLowerCase()),
        station_type: siteTypes[item.siteType],
      };
    }
    return {
      ...item,
      station_type: siteTypes[item.siteType],
    };
  });

  return result.filter(item => item.active === 1);
};

export const findSiteIdWithoutType = async (siteId, signal) => {
  let data = [];
  await Promise.all(
    ['StreamGauge', 'Weirs', 'Groundwater'].map(async item => {
      const siteSubType = item === 'Groundwater' ? 'MonitoringBore' : null;
      try {
        let siteData = await combineSiteMetadata(siteId, item, siteSubType, siteId, signal);
        if (siteData) data = data.concat(siteData);
      } catch (e) {
        return null;
      }
    }),
    ['dam'].map(async item => {
      await getAllStationsByType(item, function (result) {
        const dam = result.find(
          stationItem =>
            stationItem.station_id.toLowerCase().includes(siteId) ||
            stationItem.station_name.toLowerCase().includes(siteId),
        );
        if (dam) data = data.concat(dam);
      });
    }),
  );
  return data;
};

export const getMetadataForMutiSiteTypes = async siteTypes => {
  const sitesPromises = siteTypes.map(siteType => {
    const metaData = getSiteMetadata(null, siteType, null);
    return metaData;
  });
  let results = await resolvePromises(sitesPromises);
  return results.flat();
};

/**

 ---------- Site Download Data Util ----------
 
 */
export const getSiteDownloadData = async (
  siteId,
  frequency,
  variable,
  startDate,
  endDate,
  requestId = null,
  endPoint,
  storageName = '',
  dataType = 'AutoQC',
  signal,
  type,
  dataKey = '',
) => {
  const offsetHour =
    siteId.split(',').length > 1 && ['hourly', '15-minute'].includes(frequency) ? 1 : 0;
  const newStartDate =
    dataType.toLowerCase() === 'autoqc' &&
    new Date(startDate).getTime() < new Date(dateLimit).getTime()
      ? dateLimit
      : moment(startDate, dateFormat).add(offsetHour, 'hours').format(dateFormat);
  const params = {
    siteId,
    frequency,
    variable,
    startDate: newStartDate,
    endDate,
    requestId,
    storageName,
    dataType,
  };
  const result = await getWaterAPIResult(params, endPoint, dataKey, false, true, type, signal);
  return result;
};

/**

 ---------- Surface Water Data Util ----------
 
 */
export const getSurfaceWaterData = async (
  siteId = '',
  frequency,
  startDate,
  endDate,
  variables = '',
  dataType = '',
  isMini = false,
) => {
  const variablesArr = variables.replaceAll('AHD', '').split(',');
  const variablesList = [];
  const dataStartDate =
    startDate && ['15-minute'].includes(frequency)
      ? roundDownTimeStamp(startDate, dateFormat, frequency)
      : startDate;
  const dataSetType =
    startDate && moment(startDate, dateFormat).isBefore(moment(dateLimit, dateFormat))
      ? 'Combined'
      : dataType
      ? dataType
      : 'AutoQC';
  let results;
  while (variablesArr.length) {
    variablesList.push(variablesArr.splice(0, 4).join(','));
  }
  const endpoint =
    (siteId.split(',').length < 2 && frequency !== 'latest') || (frequency === 'latest' && isMini)
      ? endpointProps.site.download
      : endpointProps.site[frequency];
  const promises = variablesList.map(variable => {
    const apiParams = {
      siteId,
      frequency,
      ...(dataStartDate && { startDate: dataStartDate }),
      ...(endDate && {
        endDate: roundDownTimeStamp(endDate, dateFormat, frequency),
      }),
      variable,
      dataType: dataSetType,
    };
    return getWaterAPIResult(apiParams, endpoint, 'records', true, isMini);
  });
  results = await resolvePromises(promises);
  results = results.flat();
  if (frequency === 'latest' && dataType === 'AutoQC') {
    return results.filter(
      dataItem => dataItem.variableName === 'Rainfall' || dataItem.dataType === 'autoqc',
    );
  }
  return results;
};

export const getSummarySurfaceWaterData = async (
  siteIds = '',
  frequency,
  variables = '',
  timeSpan,
) => {
  const variablesArr = Array.isArray(variables)
    ? cloneDeep(variables)
    : cloneDeep(variables).split(',');
  const variablesList = [];
  let results = [];
  let qualities = {};
  while (variablesArr.length) {
    variablesList.push(variablesArr.splice(0, 4).join(','));
  }
  await resolvePromises(
    variablesList.map(async variable => {
      const apiParams = { siteId: siteIds, frequency, timeSpan, variable };
      const result = await getWaterAPIResult(apiParams, endpointProps.site.summary);
      results = results.concat(result.records);
      qualities = { ...qualities, ...result.qualities };
    }),
  );
  return { results, qualities };
};

export const getMultiSiteData = async (
  sites,
  variablesList,
  frequency,
  timeSpan,
  toProcess = false,
  isLatest = false,
) => {
  const siteList = cloneDeep(sites);
  const siteIdsList = [];
  while (siteList.length) {
    siteIdsList.push(siteList.splice(0, 20).join(','));
  }
  const variables = !isEmpty(variablesList) ? variablesList.join(',') : '';
  const sitesPromises = siteIdsList.map(siteId => {
    if (isLatest) {
      return getLatestSurfaceWaterData(siteId, null, variables);
    } else {
      return getSummarySurfaceWaterData(siteId, frequency, variables, timeSpan);
    }
  });
  let results = await resolvePromises(sitesPromises);
  let flatResult = isLatest ? results.flat() : results.map(item => item.results).flat();
  let processedResults = flatResult.filter(
    item => isEmpty(variablesList) || variablesList.includes(item.variableName),
  );
  if (toProcess) {
    processedResults = Object.entries(groupBy(processedResults, 'siteId')).map(([key, value]) => {
      const mergedData = dataMapFromWaterDataToStation(mergeByDate(value, 'variableName'));
      return { id: key, resources: mergedData };
    });
  }
  return processedResults;
};

/**
 *
 * @param {*} stationId
 * @param {*} stationType
 * @param {*} variable
 *
 */
export const getLatestSurfaceWaterData = async (stationId, stationType, variable) => {
  let customisedData = {};
  try {
    let data = await getSurfaceWaterData(stationId, 'latest', null, null, variable, 'AutoQC');
    customisedData = formatLatestWaterData(data, stationType, variable.split(','));
    return !stationType ? data : isEmpty(customisedData) ? [] : [customisedData];
  } catch (error) {
    return [];
  }
};

/**
 *
 * @param {*} stationIds
 * @param {*} variables
 *
 */
export const getLatestSurfaceWaterDataForMultiSites = async (
  stationIds,
  variables,
  stationType,
  stationsMetadata = [],
) => {
  let stationList = [];
  while (stationIds.length) {
    stationList.push(stationIds.splice(0, 20).join(','));
  }
  const sitesPromises = stationList.map(stationId => {
    return getSurfaceWaterData(stationId, 'latest', null, null, variables, 'AutoQC');
  });
  let latestData = await resolvePromises(sitesPromises);
  let latestDataflated = latestData.flat();
  let sitesData = [];
  const groupedSiteData = groupBy(latestDataflated, 'siteId');
  const groupedSiteDataValues = Object.values(groupedSiteData);
  for (let index = 0; index < groupedSiteDataValues.length; index++) {
    const stationData = groupedSiteDataValues[index];
    const stationMetadata = !isEmpty(stationsMetadata)
      ? stationsMetadata.find(item => item.station_id === stationData[0].siteId)
      : {};
    sitesData.push(
      formatLatestWaterData(stationData, stationType, stationMetadata.water_data_variables),
    );
  }
  return !isEmpty(sitesData) ? sitesData : [];
};

export const getDataForRiverDataSummary = async (stations, stationType, variables) => {
  const stationIds = stations ? stations.map(item => item.id) : [];
  const hydroData = await getLatestSurfaceWaterDataForMultiSites(
    [...stationIds],
    variables,
    stationType,
    stations,
  );
  const mergedInfo = merge(keyBy(stations, 'station_id'), keyBy(hydroData, 'siteId'));
  const result = Object.values(mergedInfo).map(item => {
    return {
      ...item,
      station_name: item.station_name,
      station_type: stationType,
      variables: item.water_data_variables?.join(','),
    };
  });
  return result;
};

/**

 ---------- Flow Data Util ----------
 
 */
export const getFlowData = async (
  storageName,
  frequency,
  startDate,
  endDate,
  flowType = 'inflow',
) => {
  const apiParams = {
    storageName,
    frequency,
    startDate,
    endDate,
  };
  const result = await getWaterAPIResult(apiParams, endpointProps[flowType], 'records');

  return result;
};

/**

 ---------- Ground Water Data Util ----------
 
 */
export const getGroundWaterData = async (
  siteId,
  frequency,
  startDate,
  endDate,
  variable = 'GroundwaterDepthBelowSurfaceLevel',
  dataType = 'Combined',
) => {
  const apiParams = {
    siteId,
    frequency,
    startDate,
    endDate: roundDownTimeStamp(endDate, dateFormat, frequency),
    variable,
    dataType,
  };
  const result = await getWaterAPIResult(
    apiParams,
    endpointProps.groundwater[frequency],
    'records',
  );
  return result ? result : [];
};

export const getMultiGroundLevelData = async (
  siteList,
  startDate,
  endDate,
  frequency = 'daily',
) => {
  const siteIdsList = [];
  let categorisedResult = [];
  while (siteList.length) {
    siteIdsList.push(siteList.splice(0, 20).join(','));
  }
  const sitesPromises = siteIdsList.map(siteId => {
    return getGroundWaterData(
      siteId,
      frequency,
      startDate,
      endDate,
      'GroundwaterDepthBelowSurfaceLevel',
      'Combined',
    );
  });
  let results = await resolvePromises(sitesPromises);
  let flatResult = results.flat();
  let groupedData = groupBy(flatResult, 'siteId');
  Object.values(groupedData).forEach(bore => {
    categorisedResult.push(mergeByDate(bore, true));
  });
  return categorisedResult;
};

/**

 ---------- Ratings Table Util ----------
 
 */
export const getRatingTablesData = async siteId => {
  const result = await getWaterAPIResult(
    { siteId },
    apiConstants.WATER_DATA_API_RATING_TABLE,
    'records',
  );

  return result;
};
