// Library
import React from 'react';

// Component
import RainfallGraph from './RainfallGraph';

const RainfallBody = () => {
  return (
    <div>
      <RainfallGraph />
    </div>
  );
};

export default RainfallBody;
