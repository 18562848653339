// Library
import axios from 'axios';

// Constants
import constants from '../constants/Constants';

// Helpers
import azureB2CHelper from '../helpers/AzureB2CHelper';

const apiBaseURL = constants.BASE_URL;

/**
 * Returns the token
 */
export const getAPIToken = async () => {
  try {
    const res = await axios.get(`${apiBaseURL}${constants.API_TOKEN}`);
    if (res.data && res.data.success) {
      return res.data.token;
    } else {
      throw new Error(res.data ? res.data.message : 'API Error');
    }
  } catch (error) {
    return error;
  }
};

/**
 * make the post call to send feedback
 * avilable types are 1 for 'General queries' and 2 for 'Report a data issue / Raise a data request'
 */
export const postFeedback = async (name, email, subject, message, type, token) => {
  try {
    const res = await axios({
      method: 'post',
      url: `${apiBaseURL}${constants.SEND_EMAIL}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        name,
        email,
        subject,
        message,
        type,
        htmlTemplate: 'feedback',
      },
    });
    if (res.data && res.data.success) {
      return true;
    } else {
      throw new Error(res.data ? res.data.message : 'API Error');
    }
  } catch (error) {
    return false;
  }
};

/**
 * make the post call to submit download request for heavy data
 */
export const submitDataDownloadRequest = async (
  email,
  stationIds,
  variables,
  frequency,
  fromDate,
  toDate,
  dataType,
) => {
  const userToken = await azureB2CHelper.getToken();
  const requestStatus = 'RECEIVED';
  try {
    const res = await axios({
      method: 'post',
      url: `${apiBaseURL}${constants.DATA_DOWNLOAD_REQUEST_HDD}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: {
        email,
        stationIds,
        variables,
        frequency,
        fromDate,
        toDate,
        requestStatus,
        dataType,
      },
    });
    if (res.data && res.data.success) {
      return true;
    } else {
      throw new Error(res.data ? res.data.message : 'API Error');
    }
  } catch (error) {
    return false;
  }
};

export const getDataDownloadRequest = async email => {
  const userToken = await azureB2CHelper.getToken();
  try {
    const res = await axios({
      method: 'get',
      url: `${apiBaseURL}${constants.DATA_DOWNLOAD_REQUEST}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      params: { email },
    });
    if (res.data) {
      return res.data.requests;
    } else {
      throw new Error(res.data ? res.data.message : 'API Error');
    }
  } catch (error) {
    return [];
  }
};

export const submitBoreReportRequest = async (email, boreIds) => {
  const requestStatus = 'RECEIVED';
  const userToken = await azureB2CHelper.getToken();
  try {
    const res = await axios({
      method: 'post',
      url: `${apiBaseURL}${constants.DATA_DOWNLOAD_BORE_REPORTS_REQUEST}`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: {
        email,
        boreIds: boreIds,
        requestStatus,
      },
    });
    if (res.data && res.data.success) {
      return true;
    } else {
      throw new Error(res.data ? res.data.message : 'API Error');
    }
  } catch (error) {
    return false;
  }
};
/**
 * make the post call to send email alert
 */
export const sendNotificationEmail = async email => {
  const token = await getAPIToken();
  try {
    const res = await axios({
      method: 'post',
      url: `${apiBaseURL}${constants.SEND_HTML_EMAIL}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        name: email,
        email,
        subject: 'Data request',
        htmlTemplate: 'text_only',
        message: `Thank you for your data request.\nYou will receive an email with access instructions for the data in the next 24 hours.`,
      },
    });
    if (res.data && res.data.success) {
      return true;
    } else {
      throw new Error(res.data ? res.data.message : 'API Error');
    }
  } catch (error) {
    return false;
  }
};

export const getWebsiteStatus = async () => {
  try {
    const res = await axios({
      method: 'get',
      url: `${apiBaseURL}${constants.WEBSITE_NOTIFICATION}`,
    });
    if (res.data && res.status === 200) {
      return res.data;
    } else {
      throw new Error(res.statusText ? res.statusText : 'API Error');
    }
  } catch (error) {
    return [];
  }
};
