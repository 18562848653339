//libraries
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

//style
import '../download/old/multiSitesSelections.scss';

//component
import WaterInputDropdown from '../download/old/SiteInputDropdown';
import Loader from '../../loader/Loader';

// helpers
import { getAllWaterSources } from '../../../helpers/ApiHelper';

//constant
import constants from '../../../constants/Constants';
const component = 'site-selections';

const WaterSourcesSelections = ({ setSelectedSources, limit, selected, type }) => {
  const [waterSourceList, setWaterSourceList] = useState([]);
  const [warningMsg, setWarningMsg] = useState('');

  useEffect(() => {
    (async () => {
      const waterSourceList = await getAllWaterSources();
      const formattedList = formatWaterSourceList(waterSourceList);
      setWaterSourceList(formattedList);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Search for watersources
  const filterList = input => {
    if (type === 'waterSource' && input && !isEmpty(input)) {
      const filteredSources = waterSourceList.filter(({ id, name }) => {
        let lowerInput = input.toLowerCase();
        return name.toLowerCase().includes(lowerInput) || id.toString().includes(input);
      });
      const formatWaterSources = waterSourceList.map(source => ({
        ...source,
        disabled: !filteredSources.includes(source),
      }));

      setWaterSourceList(formatWaterSources);

      if (!isEmpty(filteredSources)) {
        setWarningMsg('');
      } else {
        setWarningMsg('Your search returned no results');
        setWaterSourceList(waterSourceList);
      }
    }
    if (isEmpty(input)) {
      setWaterSourceList(waterSourceList);
    }
  };

  const changeWaterSource = ids => {
    const sources = waterSourceList.filter(item => ids.includes(item.id.toString()));
    setSelectedSources(sources);
  };

  const formatWaterSourceList = waterSourceList => {
    const sortedList = sortWaterSourceList(waterSourceList);
    return Array.isArray(sortedList)
      ? sortedList.map(item => {
          return {
            id: item.water_source_id,
            name: item.water_source_name,
          };
        })
      : [];
  };

  const sortWaterSourceList = waterSourceList => {
    return waterSourceList.sort((a, b) => {
      const aOrder = determineWaterSourceTypeOrder(a.water_source_type);
      const bOrder = determineWaterSourceTypeOrder(b.water_source_type);
      if (aOrder === bOrder) {
        if (a.water_source_name < b.water_source_name) {
          return -1;
        } else if (a.water_source_name > b.water_source_name) {
          return 1;
        } else {
          return 0;
        }
      } else {
        return aOrder - bOrder;
      }
    });
  };

  const determineWaterSourceTypeOrder = watersourceTypeList => {
    const { WATER_SOURCE_TYPE } = constants;
    const order = {
      [WATER_SOURCE_TYPE.regulated]: 1,
      [WATER_SOURCE_TYPE.unregulated]: 2,
      [WATER_SOURCE_TYPE.groundwater]: 3,
      other: 4,
    };
    if (watersourceTypeList.includes(WATER_SOURCE_TYPE.regulated)) {
      return order[WATER_SOURCE_TYPE.regulated];
    } else if (watersourceTypeList.includes(WATER_SOURCE_TYPE.unregulated)) {
      return order[WATER_SOURCE_TYPE.unregulated];
    } else if (watersourceTypeList.includes(WATER_SOURCE_TYPE.groundwater)) {
      return order[WATER_SOURCE_TYPE.groundwater];
    } else {
      return order.other;
    }
  };

  const sourceContent = () => {
    return (
      <div data-cy="water-source-test" className={component}>
        {!isEmpty(waterSourceList) ? (
          <WaterInputDropdown
            filterList={filterList}
            warningMsg={warningMsg}
            placeholder="Search water sources"
            menuList={waterSourceList}
            changeItem={changeWaterSource}
            selected={selected}
            inline
            limit={limit}
            pageSource={'archived'}
          />
        ) : (
          <Loader width={'8px'} height={'25px'} />
        )}
      </div>
    );
  };

  return <>{type === 'waterSource' && sourceContent()}</>;
};

export default WaterSourcesSelections;

WaterSourcesSelections.propTypes = {
  setSelectedSources: PropTypes.func,
  setSelectedPlans: PropTypes.func,
  limit: PropTypes.number,
  selected: PropTypes.array,
  type: PropTypes.string,
};
