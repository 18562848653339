import React, { useState, createContext } from 'react';

//helpers
import PropTypes from 'prop-types';

export const UserContext = createContext();

export default function UserContextProvider({ children }) {
  const [user, updateUser] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  function setUser(newUser) {
    updateUser(newUser);
  }

  return (
    <UserContext.Provider value={{ user, setUser, setIsLoggedIn, isLoggedIn }}>
      {children}
    </UserContext.Provider>
  );
}

UserContextProvider.propTypes = {
  children: PropTypes.any,
};
