// Library
import React, { useState, useEffect, useContext, useRef } from 'react';
import { isEmpty } from 'lodash';

// Style
import './stationSelect.scss';

// Component
import StationSelectInput from './StationSelectInput';
import StationSelectSuggestion from './StationSelectSuggestion';
import Loader from '../../../../../loader/Loader';

// Helper
import { filterListByKeyword, fetchStations, findRequestedSite } from './StationSelectHelper';

// Context
import { DownloadContext } from '../../../../../../contexts/DownloadContext';
import { UserContext } from '../../../../../../contexts/UserContext';

// Constant
import constants from '../../../../../../constants/WaterDataAPIConstants';
const component = 'station-selections';

const StationSelect = () => {
  const inputRef = useRef(null);
  const { isLoggedIn } = useContext(UserContext);
  const { downloadType, setSelectedStations, requestedSiteId, setRequestedSiteId } =
    useContext(DownloadContext);
  const [stationList, setStationList] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState('');
  const isGroundwaterBore = downloadType === constants.GROUNDWATER;
  const isMetroDams = downloadType === constants.METRO_DAMS_STORAGE;
  const menuList = filterListByKeyword(stationList, searchKeyword);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    (async () => {
      let stations = [];
      if (isMetroDams) {
        stations = constants.METRO_DAMS.map(item => {
          return { ...item, name: `${item.id} - ${item.name}`, active: 1 };
        });
      } else {
        const siteSubType = isGroundwaterBore ? 'MonitoringBore' : '';
        stations = await fetchStations(downloadType, siteSubType, signal);
      }
      // Only show active sites
      if (!isGroundwaterBore && !isMetroDams) {
        stations = stations.filter(siteItem => siteItem.active === 1);
      }
      findRequestedSite(requestedSiteId, stations, setSelectedStations, setRequestedSiteId);
      setStationList(stations);
    })();

    return () => {
      reset();
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadType]);

  // Helpers
  const reset = () => {
    setStationList([]);
    setSearchKeyword('');
  };

  return (
    <div className={component}>
      {!isEmpty(stationList) ? (
        <div className={`${component}-container`}>
          <StationSelectInput
            inputRef={inputRef}
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
          />
          <>
            <StationSelectSuggestion
              stationList={menuList}
              stationSelectionLimit={isLoggedIn ? 9999 : 20}
              onSelect={newSelect => {
                setSelectedStations(newSelect);
              }}
              isOpen={Boolean(searchKeyword)}
              onClose={() => {
                inputRef.current.value = '';
                setSearchKeyword('');
              }}
              onClear={() => {
                setSelectedStations([]);
              }}
            />
          </>
        </div>
      ) : (
        <Loader width={'8px'} height={'25px'} />
      )}
    </div>
  );
};

export default StationSelect;
