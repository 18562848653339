// Library
import React from 'react';
import PropTypes from 'prop-types';

// Style
import './dataRefresh.scss';

// Helper
import { getCurrentDate } from '../../helpers/TimeUtils';

// Asset
import RefreshIcon from '../../image/icons/RefreshIcon';

// Container
const container = 'data-refresh-button';

const DataRefresh = ({ forceUpdate, timestamp = getCurrentDate('DD MMM YYYY hh:mm a') }) => {
  return (
    <div className={container}>
      <div
        className={`${container}-button`}
        onClick={() => {
          forceUpdate();
        }}
      >
        <RefreshIcon customClass={`${container}-button-icon`} />
        <span className={`${container}-button-text`}>REFRESH</span>
      </div>
      <div className={`${container}-timestamp`}>Last refreshed {timestamp}</div>
    </div>
  );
};

export default DataRefresh;

DataRefresh.propTypes = {
  forceUpdate: PropTypes.func,
  timestamp: PropTypes.string,
};
