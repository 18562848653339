// Libraries
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { groupBy, isEmpty, merge, keyBy, values } from 'lodash';
import { Tooltip, BarChart, Bar, XAxis, Legend, YAxis } from 'recharts';

// Context
import { AppContext } from '../../contexts/AppContext';
import { MobileContext } from '../../contexts/MobileContext';

// Components
import SelectionBtns from '../storage/childProperties/SelectionBtns';
import SelectionBtnsGroup from '../storage/childProperties/SelectionBtnsGroup';
import StorageChartDropdown from '../storage/childProperties/StorageChartDropdown';
import ChartContainer from '../chartProperties/ChartContainer';
import ChartLegend from '../chartProperties/ChartLegend';
import ChartResponsiveContainer from '../chartProperties/ChartResponsiveContainer';
import DownloadLink from '../chartProperties/ChartCSVDownloadLink';

// Helpers
import {
  getSurfaceWaterData,
  aggregateDailyintoMonthly,
  getMultiSiteData,
} from '../../helpers/WaterDataApiHelper';
import { formatNumberDecimals } from '../../helpers/Utils';
import { Event } from '../../helpers/GAHelper';
import { getDatesByPeriod, getCurrentDate, formatDate } from '../../helpers/TimeUtils';
import {
  getCumulativeInflowsByWaterSource,
  getStationForWaterSourceByType,
} from '../../helpers/ApiHelper';

// Styles
import './cumulativeInflowRecent.scss';

// Constants
import constants from '../../constants/Constants';
import apiConstants from '../../constants/WaterDataAPIConstants';
import text from '../research/research.json';
import config from '../../configs/featureToggleConfig.json';
const graphDesc = text.cumulativeInflowRecent;
const getDataFromWaterDataApi = config['water-data-api'].dams.active;
const graphDateFormat = 'YYYY-MM';

export default function CumulativeInflowRecent() {
  const endDate = getCurrentDate(graphDateFormat);
  const { isMobile } = useContext(MobileContext);
  const { waterSource } = useContext(AppContext);
  const waterSourceId = waterSource.water_source_id;
  const isBarwon = constants.BARWON_DARLING_UNREGULATED_RIVER_WATER_SOURCE === waterSourceId;
  const isLowerDarling = constants.LOWER_DARLING_REGULATED_RIVER_WATER_SOURCE === waterSourceId;
  const isLachlan = constants.LACHLAN_REGULATED_RIVER_WATER_SOURCE === waterSourceId;
  const [chartData, setChartData] = useState([]);
  const [yearOffset, setYearOffset] = useState(1);
  const [activeDams, setActiveDams] = useState(['total_inflow']); //default for barwon darling
  const [barwonDarlingDams, setBarwonDarlingDams] = useState([]);

  useEffect(() => {
    (async () => {
      let surfaceWaterData;
      if (getDataFromWaterDataApi && !isLowerDarling) {
        surfaceWaterData = await fetchDataFromSurfacewater();
        const tributaries = await getStationForWaterSourceByType(waterSourceId, 'tributary');
        if (tributaries) {
          let tributariesData = await getMultiSiteData(
            tributaries.map(site => site.station_id),
            -1,
            ['Volume'],
            'daily',
            '09:00',
            'years',
            false,
          );
          tributariesData = Object.entries(groupBy(tributariesData, 'timeStamp')).map(
            ([key, value]) => {
              return {
                timeStamp: key,
                value: value.reduce((acc, o) => acc + parseInt(o.value), 0),
                siteId: 'Tributaries',
                variableName: 'Volume',
              };
            },
          );
          const formatted = formatSurfaceWaterData(tributariesData);
          surfaceWaterData = values(
            merge(keyBy(surfaceWaterData, 'date'), keyBy(formatted, 'date')),
          );
          surfaceWaterData = surfaceWaterData.map(dataItem => {
            return {
              ...dataItem,
              total_cumulative_inflow:
                (dataItem.total_cumulative_inflow ? dataItem.total_cumulative_inflow : 0) +
                (dataItem.Tributaries ? dataItem.Tributaries : 0),
            };
          });
        }
      } else {
        surfaceWaterData = await getCumulativeInflowsByWaterSource(
          waterSourceId,
          null,
          moment().subtract(yearOffset, 'years').add(1, 'months').format(graphDateFormat),
          endDate,
        );
      }
      let chartDataRes = surfaceWaterData;
      if (!isEmpty(chartDataRes) && !(chartDataRes instanceof Error)) {
        let parserData = chartDataRes;
        if (isBarwon && isEmpty(barwonDarlingDams) && !isEmpty(chartDataRes.data)) {
          const filtered = chartDataRes[0].dams.map(({ dam_id, dam_name }) => {
            return { station_id: dam_id, station_name: dam_name };
          });
          setBarwonDarlingDams(filtered);
        }

        if (isLowerDarling) {
          const filtered = chartDataRes[0].dams.filter(
            item => Number(item.dam_id) === constants.MENINDEE_LAKES_TOTAL_ID,
          );
          const remapped = chartDataRes.map(dataItem => {
            return {
              [constants.MENINDEE_LAKES_TOTAL_ID]: dataItem[constants.MENINDEE_LAKES_TOTAL_ID],
              date: dataItem.date,
              total_inflow: dataItem.total_inflow,
              total_cumulative_inflow: dataItem.total_cumulative_inflow,
              dams: filtered,
            };
          });
          parserData = remapped;
        }

        if (isLachlan && !isEmpty(chartDataRes)) {
          const remapped = chartDataRes.map(dataItem => {
            const filtered = dataItem.dams.filter(
              item =>
                ![constants.LAKE_CARGELLIGO_ID, constants.LAKE_BREWSTER_ID].includes(
                  Number(item.dam_id),
                ),
            );
            return {
              [constants.LAKE_WYANGALA_ID]: dataItem[constants.LAKE_WYANGALA_ID],
              date: dataItem.date,
              total_inflow: dataItem.total_inflow,
              total_cumulative_inflow: dataItem.total_cumulative_inflow,
              tributary_inflow: dataItem.tributary_inflow,
              tributary_cumulative_inflow: dataItem.tributary_cumulative_inflow,
              dams: filtered,
            };
          });
          parserData = remapped;
        }
        setChartData(parserData);
      } else {
        setChartData([]);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterSourceId, yearOffset, endDate]);

  const fetchDataFromSurfacewater = async () => {
    const damIds = waterSource?.dams.map(damItem => damItem.station_id);
    const startingDate = getDatesByPeriod(-yearOffset, apiConstants.API_DATE_FORMAT, 'years');
    const endingDate = getCurrentDate(apiConstants.API_DATE_FORMAT);
    const res = await getSurfaceWaterData(
      damIds.join(','),
      'monthly',
      startingDate,
      endingDate,
      'StorageInflow',
      'Combined',
    );
    return formatSurfaceWaterData(res);
  };

  const formatSurfaceWaterData = data => {
    const monthlyData = aggregateDailyintoMonthly(data);
    const groupedByDate = groupBy(monthlyData, 'timeStamp');
    const formattedResources = [];
    let cumulativeInflow = 0;
    let perDamCumulativeInflow = {};
    for (const [timeStamp, entries] of Object.entries(groupedByDate)) {
      const resetDate = formatDate(timeStamp, apiConstants.API_DATE_FORMAT, '01-MMM');
      if (resetDate === '01-Jul') {
        cumulativeInflow = 0;
        perDamCumulativeInflow = {};
      }
      const damsResources = entries.map(entryItem => {
        const damName = waterSource?.dams.find(
          damItem => damItem.station_id === entryItem.siteId,
        )?.station_name;
        cumulativeInflow += entryItem.value;
        perDamCumulativeInflow[entryItem.siteId] = perDamCumulativeInflow[entryItem.siteId]
          ? perDamCumulativeInflow[entryItem.siteId] + entryItem.value
          : entryItem.value;
        return {
          dam_id: entryItem.siteId,
          dam_name: damName || '',
          cumulative_inflow: perDamCumulativeInflow[entryItem.siteId],
        };
      });

      const stations = damsResources.reduce((prevObj, resItem) => {
        const temp = { ...prevObj };
        temp[resItem.dam_id] = resItem.cumulative_inflow;
        return temp;
      }, {});
      formattedResources.push({
        date: formatDate(timeStamp, apiConstants.API_DATE_FORMAT, graphDateFormat),
        ...(damsResources[0].dam_id !== 'Tributaries' && { dams: damsResources }),
        ...(damsResources[0].dam_id !== 'Tributaries' && {
          total_cumulative_inflow: cumulativeInflow,
        }),
        ...stations,
      });
    }
    return formattedResources;
  };

  const onYearChange = yearOffset => {
    setYearOffset(yearOffset);
    Event(
      waterSource.water_source_name,
      '/storage - year selection: ' + yearOffset + ' years',
      constants.INTERACTION_TYPE.button,
    );
  };

  const onDamChange = selectedDams => {
    const activeDams = selectedDams.split(',');
    setActiveDams(activeDams.length === barwonDarlingDams.length ? ['total_inflow'] : activeDams);
  };

  const colors = isBarwon
    ? ['#003470', '#0e427e', '#1c508c', '#2a5e9a', '#386ca8', '#467ab6', '#5488c4', '#6296d2']
    : ['#00519B', '#2178B0', '#2196f3', '#64b5f6'];

  const numberFormatter = value => {
    return value > 10000
      ? formatNumberDecimals(Math.round(value / 1000))
      : formatNumberDecimals(Number(value / 1000).toFixed(1));
  };

  const renderCustomTooltip = params => {
    if (params && params.payload && params.payload[0] && params.payload[0].payload) {
      const payload = params.payload[0].payload;
      const date = moment(payload.date, graphDateFormat).format('MMMM YYYY');
      const value = isBarwon
        ? numberFormatter(payload.total_inflow)
        : numberFormatter(payload.total_cumulative_inflow);

      return (
        <div className="ci-recent-chart-tooltip">
          <ul className="ci-recent-chart-tooltip-item-ul">
            <li
              className="list-no-style-type"
              style={{ color: isLowerDarling ? '#0054a6' : 'default' }}
            >{`${date}${`: ${formatNumberDecimals(value)} GL`}`}</li>
            {!isLowerDarling &&
              params.payload
                .reverse()
                .map((data, index) => (
                  <li
                    key={index}
                    className="list-no-style-type custom-recharts-tooltip-item"
                    style={{ color: data.color }}
                  >{`${data.name}: ${numberFormatter(data.value)} GL`}</li>
                ))}
          </ul>
        </div>
      );
    } else {
      return null;
    }
  };

  const renderCusomizedLegend = e => {
    return <ChartLegend payload={e.payload} />;
  };

  let damsSelectionBtn = !isEmpty(barwonDarlingDams) && (
    <StorageChartDropdown
      dams={barwonDarlingDams}
      onDamChange={onDamChange}
      allText="All Tributaries"
    />
  );
  let yearSelectionBtn = (
    <SelectionBtns
      type="year"
      itemList={[1, 5, 10]}
      onBtnChange={onYearChange}
      defaultValue={yearOffset}
    />
  );

  const stackedBar = (item, index) => {
    return (
      <Bar
        key={item.dam_id}
        name={item.dam_name}
        dataKey={item.dam_id}
        stackId="a"
        fill={colors[index]}
        radius={[
          index === chartData[0].dams.length - 1 ? 5 : 0,
          index === chartData[0].dams.length - 1 ? 5 : 0,
          0,
          0,
        ]}
      />
    );
  };

  const title = isBarwon ? `Inflow` : `Cumulative inflows`;
  const desc = isBarwon
    ? `This graph shows how much water has entered the Barwon Darling Unregulated water source over the last few years from its major tributaries that have gauges.`
    : graphDesc;

  return (
    <ChartContainer divId="AMSRecent" pageTitle={title} graphTitle={title} graphIntro={desc}>
      {!isMobile && (
        <DownloadLink
          // eslint-disable-next-line no-unused-vars
          data={chartData.map(({ dams, ...rest }) => {
            return rest;
          })}
          filename={!isEmpty(chartData) ? chartData[0]?.dams?.map(dam => dam.dam_id).join('_') : ''}
          stationType="dam"
          dataType="CUMULATIVE INFLOW"
        />
      )}
      <SelectionBtnsGroup left={isBarwon ? damsSelectionBtn : false} right={yearSelectionBtn} />
      <ChartResponsiveContainer
        customHeight={{ desktop: 450, mobile: 350 }}
        isEmptyChart={isEmpty(chartData)}
      >
        <BarChart
          width={800}
          data={chartData}
          stackOffset="sign"
          margin={{ top: isMobile ? 20 : 10, left: 20, bottom: 50 }}
        >
          <XAxis
            interval={isMobile ? 'preserveStartEnd' : Math.floor(yearOffset / 2)}
            dataKey="date"
            tickFormatter={v => moment(v, graphDateFormat).format('YYYY MMM')}
            type="category"
            angle={-60}
            dy={25}
            tick={{ fill: 'black' }}
          />
          <YAxis
            interval="preserveStartEnd"
            width={isMobile ? 15 : 60}
            angle={isMobile ? -60 : 0}
            label={isMobile ? '' : { value: 'Cumulative inflows (GL)', angle: -90, dx: -35 }}
            tickFormatter={numberFormatter}
            type="number"
            tick={{ fill: 'black' }}
          />
          <Tooltip content={renderCustomTooltip} cursor={{ fill: 'transparent' }} />
          <Legend wrapperStyle={{ bottom: 0 }} content={renderCusomizedLegend} />
          {chartData && chartData[0] && !isBarwon && !isLowerDarling && (
            <Bar
              name="Tributaries"
              dataKey={getDataFromWaterDataApi ? 'Tributaries' : 'tributary_cumulative_inflow'}
              stackId="a"
              fill="#194378"
              radius={[0, 0, 0, 0]}
            />
          )}
          {chartData &&
            chartData[0] &&
            !isBarwon &&
            chartData[0].dams.map((i, index) => stackedBar(i, index))}
          {isBarwon &&
            (activeDams[0] !== 'total_inflow' ? (
              <Bar name="Inflow" dataKey={activeDams[0]} fill="#0054a6" radius={[5, 5, 0, 0]} />
            ) : (
              chartData && chartData[0] && chartData[0].dams.map((i, index) => stackedBar(i, index))
            ))}
        </BarChart>
      </ChartResponsiveContainer>
    </ChartContainer>
  );
}

CumulativeInflowRecent.propTypes = {
  desc: PropTypes.string,
};
