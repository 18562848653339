// Library
import React, { useState, useContext, useEffect } from 'react';
import { isEmpty } from 'lodash';

// Style
import './researchBody.scss';

// Context
import { AppContext } from '../../contexts/AppContext';

// Component
import WaterBalanceGraph from './WaterBalanceGraph';
import CumulativeInflowRecent from './CumulativeInflowRecent';
import CIPercentile from './CumulativeInflowPercentile';
import TotalUsageGraph from './TotalUsageGraph';
import HistoricalUsageGraph from './HistoricalUsageGraph';
import HistoricalUsageUtilisationGraph from './HistoricalUsageUtilisationGraph';
import AllocBarChart from '../allocation/AllocationGraph';
import AverageMonthlyStorageFinancialYear from './AverageMonthlyStorageFinancialYear';

// Helpers
import {
  getAllocDataWaterSource,
  getBalanceDataWaterSource,
  getUsageByWaterSourceFY,
  getDistributionDataWaterSource,
} from '../../helpers/ApiHelper.jsx';
// Helpers
import { currentFinancialYear } from '../../helpers/TimeUtils';
import {
  removeElement,
  removeCategoryElement,
} from '../../components/allocation/ArrayUtilsAllocation';

// Constant
import constants from '../../constants/Constants';
const container = 'research-body';
const {
  GREATER_SYDNEY_WSID,
  MURRAY_REGULATED_RIVER_WATER_SOURCE,
  MURRUMBIDGEE_REGULATED_RIVER_WATER_SOURCE,
  WATER_SOURCE_TYPE,
  BARWON_DARLING_UNREGULATED_RIVER_WATER_SOURCE,
} = constants;
const BASIC_LANDHOLDER_RIGHTS_CATEGORY = 'Basic landholder rights';

const ResearchBody = () => {
  // Context
  const { waterSource } = useContext(AppContext);

  // State
  const [allocationData, setAllocationData] = useState();
  const [balanceData, setBalanceData] = useState();
  const [finalBalanceData, setFinalBalanceData] = useState();
  const [usageData, setUsageData] = useState();
  const [distributionData, setDistributionData] = useState();
  const [finalDistributionData, setFinalDistributionData] = useState();
  const noReservesAllocationData = removeElement(
    finalDistributionData,
    'category_shortname',
    'Reserves',
  );

  // Life Cycle
  useEffect(() => {
    const from = 2010;
    const to = currentFinancialYear();

    getBalanceDataWaterSource(waterSource.water_source_id, setBalanceData);
    getUsageByWaterSourceFY(waterSource.water_source_id, setUsageData);
    getDistributionDataWaterSource(waterSource.water_source_id, setDistributionData, from);
    getAllocDataWaterSource(waterSource.water_source_id, setAllocationData, from, to);

    return () => {
      setBalanceData();
      setUsageData();
      setDistributionData();
    };
  }, [waterSource]);

  useEffect(() => {
    if (balanceData && distributionData) {
      const year = currentFinancialYear() - 1;
      const financialYearData = distributionData.find(
        yearItem => Number(yearItem.water_year) === Number(year),
      );
      const checkBasicLandordCategory = financialYearData?.resources.find(
        categoryItem => categoryItem.category_shortname === BASIC_LANDHOLDER_RIGHTS_CATEGORY,
      );

      if (!checkBasicLandordCategory) {
        const filteredBalanceData = removeCategoryElement(
          balanceData,
          'water_year',
          `${currentFinancialYear()}`,
        );
        const filteredDistributionData = removeCategoryElement(
          distributionData,
          'water_year',
          `${currentFinancialYear()}`,
        );
        setFinalBalanceData(filteredBalanceData);
        setFinalDistributionData(filteredDistributionData);
      } else {
        setFinalBalanceData(balanceData);
        setFinalDistributionData(distributionData);
      }
    }
  }, [balanceData, distributionData]);

  return (
    <>
      {waterSource && (
        <div className={`${container}`}>
          {!isEmpty(distributionData) && <AllocBarChart data={distributionData} page="research" />}
          {!isEmpty(noReservesAllocationData) && (
            <HistoricalUsageGraph data={noReservesAllocationData} />
          )}
          {!isEmpty(allocationData) && <HistoricalUsageUtilisationGraph data={allocationData} />}
          {!isEmpty(finalBalanceData) && waterSource.water_balance && (
            <WaterBalanceGraph balanceData={finalBalanceData} />
          )}
          {!isEmpty(waterSource.water_source_type) &&
            (waterSource.water_source_type.includes(WATER_SOURCE_TYPE.regulated) ||
              waterSource.water_source_id === BARWON_DARLING_UNREGULATED_RIVER_WATER_SOURCE) && (
              <>
                {![
                  GREATER_SYDNEY_WSID,
                  MURRAY_REGULATED_RIVER_WATER_SOURCE,
                  BARWON_DARLING_UNREGULATED_RIVER_WATER_SOURCE,
                ].includes(waterSource.water_source_id) && <CumulativeInflowRecent />}
                {waterSource.water_source_id === MURRUMBIDGEE_REGULATED_RIVER_WATER_SOURCE && (
                  <CIPercentile />
                )}
                {waterSource.water_source_id !== GREATER_SYDNEY_WSID && (
                  <AverageMonthlyStorageFinancialYear
                    aggregatedFullVolume={waterSource.full_volume}
                    dams={waterSource.dams}
                  />
                )}
              </>
            )}
          {!isEmpty(usageData) &&
            waterSource.water_source_type.includes(WATER_SOURCE_TYPE.groundwater) && (
              <TotalUsageGraph data={usageData} />
            )}
        </div>
      )}
    </>
  );
};

export default ResearchBody;
