// Library
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';

// Styles
import './currentUpdates.scss';

// Component
import Title from '../title/Title';
import UpdateTabs from './UpdateTabs';

// Helper
import { getLatestUpdates } from '../../helpers/UpdatesApiHelper.jsx';

// Assests
import availability from '../../image/availability.jpg';
import environment from '../../image/environment.jpg';
import operations from '../../image/operations.jpg';
import restrictions from '../../image/restrictions.jpg';
import extremeEvents from '../../image/extreme_events.jpg';
import WaterDroplet from '../../image/icons/WaterDropletSVG.jsx';

// Context
import { MobileContext } from '../../contexts/MobileContext';

// Object Map
const homeText = {
  mobile: {
    header: 'NSW UPDATES',
    link: 'See all',
  },
  desktop: {
    header: 'CURRENT NSW UPDATES',
    link: 'See all NSW updates',
  },
};

export default function CurrentUpdates() {
  const [selectedTabType, setSelectedTabType] = useState('water availability');
  const [latestUpdates, setlatestUpdates] = useState([]);
  const [textVersion, setTextVersion] = useState('desktop');
  const { isMobile } = useContext(MobileContext);

  useEffect(() => {
    getLatestUpdates(setlatestUpdates);
    setTextVersion(isMobile ? 'mobile' : 'desktop');
  }, [isMobile]);

  function renderImage() {
    switch (selectedTabType) {
      case 'temporary restrictions':
        return restrictions;
      case 'water availability':
        return availability;
      case 'operations':
        return operations;
      case 'extreme events':
        return extremeEvents;
      case 'environmental':
        return environment;
      case 'supplementary events':
        return availability;
      default:
        return availability;
    }
  }

  return (
    <div className="updates">
      <div className="updates-title">
        <Title title={homeText[textVersion].header} />
        {!isEmpty(latestUpdates) && (
          <Link className="all-updates-link" to="/nsw-update">
            {homeText[textVersion].link}
          </Link>
        )}
      </div>
      {!isEmpty(latestUpdates) ? (
        <div className="updates-container">
          <div className="updates-left">
            <UpdateTabs latestUpdates={latestUpdates} setSelectedTabType={setSelectedTabType} />
          </div>
          <div className="updates-right">
            <img className="updates-img" src={renderImage()} alt={`${selectedTabType}`}></img>
          </div>
        </div>
      ) : (
        <div className="updates-empty-container">
          {isMobile && <WaterDroplet customClass={`updates-empty-item-icon`} />}
          <div className="updates-empty-item-text">
            There are no updates available at the moment
          </div>
        </div>
      )}
    </div>
  );
}
