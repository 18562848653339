// Libraries
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Styles
import './navLinkItem.scss';

// Context
import { AppContext } from '../../contexts/AppContext';
import { MobileContext } from '../../contexts/MobileContext';

// Assets
import constants from '../../constants/Constants';

// Helpers
import { Event } from '../../helpers/GAHelper';

const component = 'nav-link-item';
export default function NavLinkItem({
  title,
  link,
  className = '',
  isExternal = false,
  onLinkClick,
}) {
  const { waterSource } = useContext(AppContext);
  const { isMobile } = useContext(MobileContext);

  if (title === 'Glossary') {
    return (
      <div className={component}>
        <Link
          to={link}
          className={`${component}-link`}
          onClick={() =>
            Event(
              waterSource.water_source_name,
              title + ' nav tab click',
              constants.INTERACTION_TYPE.navigation,
            )
          }
        >
          {title}
        </Link>
      </div>
    );
  }
  return (
    <div className={`${className ? className : component}`}>
      <a
        className={`${component}-link`}
        data-toggle={isMobile && isExternal ? 'modal' : ''}
        data-target={isMobile && isExternal ? '#externalLinkFooter' : ''}
        onClick={onLinkClick ? () => onLinkClick(link) : () => {}}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {title}
      </a>
    </div>
  );
}

NavLinkItem.propTypes = {
  title: PropTypes.string,
  link: PropTypes.string,
  className: PropTypes.string,
  isExternal: PropTypes.bool,
  onLinkClick: PropTypes.func,
};
