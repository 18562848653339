import { fetchDataFromApi } from './ApiHelper';
import constants from '../constants/Constants';
import { isEmpty } from 'lodash';
import util from 'util';
import axios from 'axios';

const apiBaseURL = constants.BASE_URL;
const TIMEOUT = 15000;

export const getUpdatesFromWaterSource = async (waterSourceId, setUpdates) => {
  const updatesType = [
    'Water Availability',
    'Operations',
    'Extreme Events',
    'Environmental',
    'Supplementary Events',
  ];
  const params = {
    url: util.format(constants.WATER_SOURCE_NEWS, waterSourceId),
    params: { type: updatesType.join() },
  };
  fetchDataFromApi(params, 'updates').then(
    result => {
      if (result) {
        setUpdates(!isEmpty(result) && typeof result !== 'undefined' ? result[0].resources : []);
      } else {
        setUpdates([]);
      }
    },
    error => {
      return error;
    },
  );
};

export const getAlgaeUpdatesFromWaterSource = async (waterSource, setUpdates) => {
  const params = {
    url: util.format(constants.WATER_SOURCE_NEWS, waterSource.water_source_id),
    params: { type: 'Water Quality' },
  };
  fetchDataFromApi(params, 'updates').then(
    result => {
      if (result) {
        setUpdates(!isEmpty(result) && typeof result !== 'undefined' ? result[0].resources : []);
      } else {
        setUpdates([]);
      }
    },
    error => {
      console.error(error);
      setUpdates([]);
    },
  );
};

export const getRestrictionsFromWaterSource = async (waterSourceId, setRestrictions) => {
  const params = {
    url: util.format(constants.WATER_SOURCE_NEWS, waterSourceId),
    params: { type: 'Temporary Restrictions' },
  };
  fetchDataFromApi(params, 'updates').then(
    result => {
      if (result) {
        setRestrictions(
          !isEmpty(result) && typeof result !== 'undefined' ? result[0].resources : [],
        );
      } else {
        setRestrictions([]);
      }
    },
    error => {
      return error;
    },
  );
};

export const getConstraintsFromWaterSource = async (waterSource, type, setData) => {
  const params = {
    url: util.format(constants.WATER_SOURCE_CONSTRAINTS, waterSource.water_source_id),
    params: { type },
  };
  fetchDataFromApi(params, 'water_sources').then(
    result => {
      if (result) {
        setData(!isEmpty(result) && typeof result !== 'undefined' ? result[0].resources : []);
      } else {
        setData([]);
      }
    },
    error => {
      return error;
    },
  );
};

export const getLatestUpdates = async setlatestUpdates => {
  const updatesType = [
    'Water Availability',
    'Operations',
    'Extreme Events',
    'Environmental',
    'Supplementary Events',
  ];
  const params = {
    url: constants.LATEST_UPDATES,
    params: { type: updatesType.join() },
  };
  const response = await axios
    .get(apiBaseURL + params.url, { timeout: TIMEOUT, params: params.params })
    .then(response => {
      const result = response.data.updates || response.data;
      if (result && !isEmpty(result)) {
        setlatestUpdates(
          !isEmpty(result) && typeof result !== 'undefined' ? result[0].resources : [],
        );
      }
    })
    .catch(() => {
      return null;
    });
  return response;
};

export const getNSWRestrictions = async setRestrictions => {
  const params = {
    url: constants.NSW_RESTRICTIONS,
    params: { type: 1 },
  };
  const response = await axios
    .get(apiBaseURL + params.url, { timeout: TIMEOUT, params: params.params })
    .then(response => {
      const result = response.data.updates || response.data;
      if (result) {
        setRestrictions(!isEmpty(result) && typeof result !== 'undefined' ? result : []);
      }
    })
    .catch(() => {
      return null;
    });
  return response;
};

export const getAllEventsFromWaterSource = async (waterSource, setRecreationalEvents) => {
  const updatesType = ['Outage', 'Recreation'];
  const params = {
    url: util.format(constants.WATER_SOURCE_CONSTRAINTS, waterSource.water_source_id),
    params: { type: updatesType.join() },
  };
  fetchDataFromApi(params, 'water_sources').then(
    result => {
      if (result) {
        setRecreationalEvents(
          !isEmpty(result) && typeof result !== 'undefined'
            ? result[0].resources.map(event => {
                if (event.type === 'recreation') delete event.date;
                return event;
              })
            : [],
        );
      }
    },
    error => {
      return error;
    },
  );
};

export const getWaterSourceDroughtFloodRisk = async (waterSource, setData, all = false) => {
  const params = {
    url: util.format(
      constants.WATER_SOURCE_DROUGHT_FLOOD_RISK,
      !all ? waterSource.water_source_id : 'all',
    ),
  };
  fetchDataFromApi(params, 'water_sources').then(
    result => {
      if (result) {
        setData(
          !isEmpty(result) && typeof result !== 'undefined'
            ? !all
              ? result[0].resources
              : result
            : [],
        );
      } else {
        setData([]);
      }
    },
    error => {
      return error;
    },
  );
};

export const getPriorUpdates = async (waterSourceId, type, startDate, endDate, years) => {
  const params = {
    url: constants.PRIOR_UPDATES,
    params: { waterSourceId, type, startDate, endDate, years },
  };
  try {
    const res = await axios.get(apiBaseURL + params.url, {
      timeout: TIMEOUT,
      params: params.params,
    });
    const result = res.data.updates || res.data;
    if (result) {
      return result;
    } else if (res.status === 204) {
      return [];
    } else {
      throw new Error(res.data ? res.data.message : 'API Error');
    }
  } catch (error) {
    return null;
  }
};
