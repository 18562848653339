// Libraries
import React, { useState, useEffect, useContext } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

// Styles
import './MapFilter.scss';

// Components
import OrderUsageMapLegend from '../mapProperties/OrderUsageMapLegend';
import ClimateMapLegend from '../mapProperties/ClimateMapLegend';
import RadioSelectionBox from '../mapProperties/RadioSelectionBox';
import InfoButton from '../informationButton/InformationButton';

// Context
import { AppContext } from '../../contexts/AppContext';
import { MobileContext } from '../../contexts/MobileContext';
import { MapFilterContext } from '../../contexts/MapFilterContext';
import { MapContext } from '../../contexts/MapContext';

// Constant
import mapFilterConstant from '../../constants/MapFilterConstants';
const { RAINFALL } = mapFilterConstant.MAP_POPUP_ITEMS;
const container = 'map-filter';
const CLIMATE_RADIO_ITEMS = [
  {
    label: 'Rainfall outlook',
    value: 'outlook',
  },
  {
    label: 'Observed rainfall',
    value: 'observed',
  },
  {
    label: 'Temperature outlook',
    value: 'outlook-temp',
  },
  {
    label: 'Observed temperature',
    value: 'observed-temp',
  },
];

export default function MapFilter({
  type,
  selected,
  className,
  text,
  icon,
  legend,
  onRadioOptionChange,
  onToggle,
  climateRadioItem,
}) {
  const { waterSource } = useContext(AppContext);
  const { isMobile } = useContext(MobileContext);
  const { toggleMapFilter } = useContext(MapFilterContext);
  const { expandMap } = useContext(MapContext);
  const [filterState, setFilterState] = useState(Object);
  const isRainfall = type === RAINFALL.name;
  let mapLegendProps = isRainfall
    ? mapFilterConstant.RAINFALL_MAP_LEGEND_CONFIG[climateRadioItem]
    : {};

  const updateMapFilterContext = state => {
    const mapFilterContext = state.selected ? type : '';
    const mapFilterProps = state.selected && isRainfall ? mapLegendProps : {};
    toggleMapFilter(mapFilterContext, mapFilterProps);
  };

  useEffect(() => {
    const newState = {
      ...filterState,
      selected: selected,
      showLabel: selected,
    };
    setFilterState(newState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, type]);

  useEffect(() => {
    if (!isEmpty(waterSource)) {
      const newState = {
        selected: selected,
        showLabel: selected,
        filterType: type,
      };
      setFilterState(newState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterSource]);

  useEffect(() => {
    if (isMobile) {
      mouseClicked();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  useEffect(() => {
    if (isRainfall && selected) {
      updateMapFilterContext(filterState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [climateRadioItem]);

  const mouseClicked = () => {
    expandMap(true);
    const newState = {
      ...filterState,
      selected: isMobile ? true : !filterState.selected,
      showLabel: isMobile ? true : !filterState.selected,
    };
    onToggle(type);
    setFilterState(newState);
    updateMapFilterContext(newState);
  };

  const mouseOver = () => {
    if (!filterState.selected) {
      const newState = { ...filterState, showLabel: true };
      setFilterState(newState);
    }
  };

  const mouseLeave = () => {
    const newState = { ...filterState, showLabel: filterState.selected };
    setFilterState(newState);
  };

  const rainfallElement = () => {
    return (
      <div className={`${container}-rainfall-items`}>
        <RadioSelectionBox
          onRadioOptionChange={onRadioOptionChange}
          dataType={climateRadioItem}
          radioItems={CLIMATE_RADIO_ITEMS}
        />
        {!isEmpty(waterSource) && mapLegendProps && (
          <ClimateMapLegend
            climateType={climateRadioItem}
            legendTitle={mapLegendProps.title}
            legendScale={mapLegendProps.scale}
            legendScaleLabel={mapLegendProps.scaleLabel}
            legendScaleColor={mapLegendProps.scaleColor}
            colorBarClassName={mapLegendProps.colorBarClassName}
          />
        )}
      </div>
    );
  };

  const filterItems = {
    dam: <div className={`${container}-space`} />,
    gauge: <div className={`${container}-space`} />,
    rainfall: isRainfall && selected && rainfallElement(),
    'order-usage': <OrderUsageMapLegend legend={legend} />,
    environment: <div className={`${container}-space`} />,
    constraints: <div className={`${container}-space`} />,
    pump: <div className={`${container}-space`} />,
    'flow-rate': <div className={`${container}-space`} />,
    'water-quality': <div className={`${container}-space`} />,
    events: <div className={`${container}-space`} />,
  }[type];

  return (
    <div className={container}>
      <div className={`${container}-button`}>
        {type === 'water-quality' && (
          <InfoButton targetDataId="#algalLegendModal" iconColor="water-quality-info-icon" />
        )}
        <div
          className={`${container}-button-wrapper ${className}`}
          onClick={() => mouseClicked()}
          onMouseEnter={mouseOver}
          onMouseLeave={mouseLeave}
        >
          <div className={`${container}-button-label${filterState.showLabel ? ' show' : ''}`}>
            {text}
          </div>
          <div className={`${container}-button-icon`}>
            <img src={icon} alt={text} />
          </div>
        </div>
      </div>
      {selected ? filterItems : <div className={`${container}-space`} />}
    </div>
  );
}

MapFilter.propTypes = {
  type: PropTypes.string,
  selected: PropTypes.bool,
  className: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
  legend: PropTypes.array,
  onRadioOptionChange: PropTypes.func,
  waterQualityRadioItem: PropTypes.string,
  climateRadioItem: PropTypes.string,
  onToggle: PropTypes.func,
};
