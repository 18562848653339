// Libraries
import React, { useRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './glossary.scss';
import data from './data.json';

// Helpers
import { createTarget, scrollToFirstMatch, isMiniApp } from '../../helpers/UrlGenerator';

/* Constant */
import Constants from '../../constants/Constants';
import config from '../../configs/featureToggleConfig.json';
const toShowQC = config['quality_code'].active;

const SECTIONS = [
  'access-licence',
  'account',
  'active-management',
  'allocation',
  'allocation-account',
  'allocation-trading',
  'approval',
  'available-water',
  'available-water-determination-(awd)',
  'basic-landholder-rights',
  'carryover',
  'condition',
  'consumptive-water',
  'conveyance',
  'dealing-rule',
  'end-of-system-losses',
  'entitlement',
  'entitlement-trading',
  'flow-class',
  'groundwater-source',
  'held-environmental-water',
  'individual-daily-extraction-component-(idec)',
  'licence',
  'losses',
  'management-zone',
  'natural-(or-transmission)-losses',
  'operational-water',
  'planned-environmental-water',
  'regulated-river-water-source',
  'reserves',
  'share-trading',
  'storage-capacity',
  'storage-volume',
  'supplementary-water',
  'temporary-restriction',
  'translucent-release',
  'uncontrolled-flow',
  'unregulated-water-source',
  'water-access-licence',
  'water-sharing-plan',
  'water-source',
  'works-approval',
];

export default function Glossary() {
  const targetEl = useRef(null);
  const location = decodeURI(useLocation().hash);
  const [target, setTarget] = useState({});

  useEffect(() => {
    createTarget(setTarget, SECTIONS, location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {data
        .sort((a, b) => (a.title.toUpperCase() >= b.title.toUpperCase() ? 1 : -1))
        .map(
          (item, i) =>
            ((isMiniApp && item.miniApp) || !isMiniApp()) &&
            (item.title === 'Data Quality Coding' && !toShowQC ? null : (
              <div key={i} className="glossary">
                <h5
                  ref={
                    item.title.replace(/\s+/g, '-').toLowerCase() === target.key ? targetEl : null
                  }
                  id={item.title.replace(/\s+/g, '-').toLowerCase()}
                  className="glossary-title"
                >
                  {item.title}
                </h5>
                {item.title.replace(/\s+/g, '-').toLowerCase() === target.key &&
                  scrollToFirstMatch(target, targetEl)}
                <p
                  className="glossary-text"
                  dangerouslySetInnerHTML={{
                    __html:
                      Constants.TOTAL_STORAGE_MENINDEE_LAKES_FLAG &&
                      ['Storage capacity', 'Storage volume'].includes(item.title)
                        ? item.textDeadStorageVolumeCase
                        : item.text,
                  }}
                ></p>
              </div>
            )),
        )}
    </>
  );
}
