import React from 'react';

// Styles
import './body.scss';

// Components
import CurrentUpdates from '../../components/updates/CurrentUpdates';
import NavigationHelp from '../../components/pageComponents/home/navigationHelp/NavigationHelp';
import BodyContainer from './BodyContainer';

// Assets
import config from '../../abstract/IntroText.json';
import featureConfig from '../../configs/featureToggleConfig.json';

export default function HomeBody({ context }) {
  return (
    <BodyContainer pageTitle={config.home.title} pageIntro={config.home.intro}>
      <CurrentUpdates context={context} />
      {featureConfig.navigation_help.active && <NavigationHelp />}
    </BodyContainer>
  );
}
