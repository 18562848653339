// Library
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

// Style
import './allocation.scss';

// Component
import AllocBarChart from './AllocationGraph';
import AllocationMobile from './AllocationMobile';
import Modal from '../modal/Modal';
import InfoButton from '../informationButton/InformationButton';
import AppModalDealing from '../modal/Modal';
import DictionaryModalContent from '../modal/DictionaryModalContent';
import AllocationTableAvailableHeaderModalData from '../modal/modalText/AllocationTableAvailableHeaderModalData';
import LicencedWaterBalanceGraph from './LicencedWaterBalanceGraph';
import ForecastGraph from './ForecastGraph';
import AllocationContent from './AllocationInfoContent';

// Assets
import headerDesc from './allocationHeader.json';
import licenses from './allocationLicense.json';

// Helpers
import { buildUrl, getPathname } from '../../helpers/UrlGenerator.jsx';
import { formatNumberDecimals, createMarkup } from '../../helpers/Utils';
import { detailsInfoParser } from './ArrayUtilsAllocation';

// Context
import { MobileContext } from '../../contexts/MobileContext';
import { AppContext } from '../../contexts/AppContext';

const infoComponent = `allocation-info`;

export default function Allocation({
  allocationData,
  distributionData,
  licencedWaterBalance,
  forecastData,
  isTotallyNullData,
}) {
  const { waterSource } = useContext(AppContext);
  const { isMobile } = useContext(MobileContext);
  const [selectedRow, setSelectedRow] = useState(0);
  const [detailsInfo, setDetailsInfo] = useState(allocationData ? allocationData[0] : []);
  const rulePathName = buildUrl(
    getPathname(waterSource.water_source_id + ' ' + waterSource.water_source_name),
    `/rules`,
  );

  // Life Cycle
  useEffect(() => {
    if (allocationData) {
      const detailsItem = detailsInfoParser(allocationData[selectedRow]);
      setDetailsInfo(detailsItem);
    }
  }, [allocationData, selectedRow]);

  // Components
  const headerModalMaker = (name, text) => {
    return <div dangerouslySetInnerHTML={createMarkup(text)} className={name} />;
  };

  const bodyModalMaker = (category, licenses) => {
    return (
      <div
        dangerouslySetInnerHTML={createMarkup(licenses[category] || 'Description unavailable.')}
        className={'allocation-modal-body'}
      />
    );
  };

  return (
    <>
      {isTotallyNullData ? (
        <div className={`update-null-state`}>
          {`There are currently no allocations for ${
            waterSource ? waterSource.water_source_name : 'this water source'
          }`}
        </div>
      ) : (
        <>
          {!isEmpty(allocationData) && (
            <div className="div-allocation-table">
              <Modal
                id={'allocationHeaderModal'}
                title={headerModalMaker(
                  'div-header1-title-icon-modal-title',
                  headerDesc.allocationTitle,
                )}
                body={headerModalMaker(
                  'div-header1-title-icon-modal-content',
                  headerDesc.allocationContent,
                )}
              />
              <AppModalDealing
                id="avaliableHeaderModal"
                title={headerModalMaker(
                  'div-header1-title-icon-modal-title',
                  headerDesc.avaliableTitle,
                )}
                body={<DictionaryModalContent data={AllocationTableAvailableHeaderModalData} />}
              />
              <div className="div-container">
                <div className="div-header1">
                  <div className="div-header1-title">Licence Category</div>
                  <div className="div-header1-title">
                    <div className="div-header1-title-text">
                      Allocation (%) &nbsp;
                      <InfoButton
                        targetDataId="#allocationHeaderModal"
                        iconColor="div-header1-title-icon"
                      />
                    </div>
                  </div>
                  <div className="div-header1-title">
                    <div className="div-header1-title-text">
                      Available (ML) &nbsp;
                      <InfoButton
                        targetDataId="#avaliableHeaderModal"
                        iconColor="div-header1-title-icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="div-container1">
                {!isEmpty(detailsInfo) && (
                  <Modal
                    id="allocationModal"
                    title={detailsInfo.cat_name}
                    body={bodyModalMaker(detailsInfo.cat_name, licenses)}
                  />
                )}
                <div className="div-left-container">
                  {isMobile ? (
                    <AllocationMobile
                      allocationData={allocationData}
                      rulePathName={rulePathName}
                      onItemClick={itemIndex => setSelectedRow(itemIndex)}
                    />
                  ) : (
                    allocationData.map((row, index) => (
                      <div
                        key={index}
                        onClick={() => setSelectedRow(index)}
                        className={`allocation-row${selectedRow === index ? '-selected' : ''}${
                          allocationData.length === index ? '-selected' : ''
                        }`}
                      >
                        <div className={`${infoComponent}-title`}>{row.category_shortname}</div>
                        <div className={`${infoComponent}-title`}>
                          <div className="div-perc">
                            {Math.round(row.allocation_percentage) + ' %'}
                          </div>
                        </div>
                        <div className={`${infoComponent}-title`}>
                          <div className="div-usage">
                            {row.category_shortname === 'Supplementary water'
                              ? ''
                              : formatNumberDecimals(row.water_available)}
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
                {!isMobile && !isEmpty(detailsInfo) && (
                  <div className={infoComponent}>
                    <AllocationContent info={detailsInfo} pathName={rulePathName} />
                  </div>
                )}
              </div>
            </div>
          )}
          {!isEmpty(distributionData) && <AllocBarChart data={distributionData} noYearPicker />}
          {!isEmpty(licencedWaterBalance) && (
            <LicencedWaterBalanceGraph data={licencedWaterBalance} />
          )}
          {!isEmpty(forecastData) && <ForecastGraph data={forecastData} />}
        </>
      )}
    </>
  );
}

Allocation.propTypes = {
  allocationData: PropTypes.arrayOf(
    PropTypes.shape({
      adjustments: PropTypes.number,
      allocation_percentage: PropTypes.number,
      carryover: PropTypes.number,
      category_shortname: PropTypes.string,
      licence: PropTypes.string,
      number_access_license: PropTypes.number,
      number_shares: PropTypes.number,
      water_allocated: PropTypes.number,
      water_available: PropTypes.number,
      water_usage: PropTypes.number,
      environmental: PropTypes.object,
    }),
  ),
  distributionData: PropTypes.arrayOf(
    PropTypes.shape({
      water_year: PropTypes.string,
      resources: PropTypes.array,
    }),
  ),
  licencedWaterBalance: PropTypes.arrayOf(
    PropTypes.shape({
      water_year: PropTypes.string,
      resources: PropTypes.object,
    }),
  ),
  forecastData: PropTypes.arrayOf(
    PropTypes.shape({
      water_year: PropTypes.string,
      resources: PropTypes.array,
    }),
  ),
  isTotallyNullData: PropTypes.bool,
};
