import React, { useContext } from 'react';
import './home.scss';
import Map from '../../layout/map/Map';
import Onboarding from '../../components/onboarding/Onboarding';
import Steps from '../../components/onboarding/OnboardingSteps.json';
import Tabs from '../../layout/tabs/Tabs';
import { AppContext } from '../../contexts/AppContext';

const HomeDashboard = props => {
  const context = useContext(AppContext);
  const onboardingIntroSkip = localStorage.getItem('onboardingSkip');

  return (
    <div className="home-container">
      <div className="top-container">
        <Onboarding steps={Steps} onboardingIntroSkip={onboardingIntroSkip} />
        <Tabs data={context} />
        <Map data={context} />
      </div>
      {props.children}
    </div>
  );
};

export default HomeDashboard;
