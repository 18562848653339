// Libraries
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';

// Styles
import './viewDetailsLink.scss';

// Context
import { AppContext } from '../../../contexts/AppContext';

// Helpers
import { buildUrl, getPathname } from '../../../helpers/UrlGenerator';

const component = 'view-details';

export default function ViewDetailsLink({
  elementId = '',
  label = 'View details',
  pathname,
  targetWaterSource,
}) {
  const { setWaterSource, waterSource } = useContext(AppContext);

  const closeModal = () => {
    const modals = document.getElementsByClassName('modal');
    for (let i = 0; i < modals.length; i++) {
      modals[i].classList.remove('show');
      modals[i].setAttribute('aria-hidden', 'true');
      modals[i].setAttribute('style', 'display: none');
    }

    const modalsBackdrops = document.getElementsByClassName('modal-backdrop');

    for (let i = 0; i < modalsBackdrops.length; i++) {
      document.body.removeChild(modalsBackdrops[i]);
    }

    const body = document.getElementsByTagName('BODY');
    for (let i = 0; i < body.length; i++) {
      body[i].classList.remove('modal-open');
      body[i].style.removeProperty('padding-right');
    }

    // Extract data from local cache
    const waterSourceList = JSON.parse(localStorage.getItem('waterSourceList'));
    if (isEmpty(waterSource)) {
      const newWaterSource = waterSourceList.find(
        ws => ws.water_source_id === targetWaterSource.water_source_id,
      );
      setWaterSource(newWaterSource);
    }

    !elementId && window.scrollTo(0, 0);
  };

  return (
    <div id={component} className={component}>
      {(!isEmpty(waterSource) || !isEmpty(targetWaterSource)) && (
        <Link
          className={`${component}-link`}
          onClick={() => closeModal()}
          to={`${buildUrl(
            getPathname(
              !isEmpty(targetWaterSource)
                ? `${targetWaterSource.water_source_id} ${targetWaterSource.water_source_name}`
                : `${waterSource.water_source_id} ${waterSource.water_source_name}`,
            ),
            pathname,
          )}${elementId ? `#${elementId}` : ``}`}
        >
          {label}
        </Link>
      )}
    </div>
  );
}

ViewDetailsLink.propTypes = {
  elementId: PropTypes.string,
  activeTab: PropTypes.string,
  label: PropTypes.string,
  pathname: PropTypes.string,
  targetWaterSource: PropTypes.shape({
    water_source_id: PropTypes.number,
    water_source_name: PropTypes.string,
  }),
};
