// Library
import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

// Styles
import './checkboxBtnList.scss';

const component = 'checkbox-list';

const CheckboxBtnList = ({
  options,
  onChange,
  selectedOptions,
  itemFormatter = item => item,
  limit = Infinity,
  customStyles,
}) => {
  const onCheckBoxChange = ({ id, checked }) => {
    if (checked) {
      const target = options.find(item => item.id === id);
      target !== -1 && onChange([...selectedOptions, target]);
    } else {
      const filtered = selectedOptions.filter(item => item.id !== id);
      onChange(filtered);
    }
  };

  const isDisabled = id => {
    return (
      selectedOptions.length > limit - 1 && selectedOptions.find(item => item.id === id) === -1
    );
  };

  return (
    <div className={`${component} ${customStyles}`}>
      {!isEmpty(options) ? (
        options.map(option => {
          const isSelected = selectedOptions.findIndex(item => item.id === option.id) !== -1;
          return (
            <div
              key={option.id}
              className={`${component}-option${option.disabled ? '-hide' : ''} ${
                isSelected && `${component}-option-selected`
              } ${isDisabled(option.id) && `${component}-option-disabled`}`}
            >
              <input
                className={`${component}-option-btn`}
                type="checkbox"
                id={option.id}
                value={option.id}
                checked={isSelected}
                onChange={e => onCheckBoxChange(e.target)}
                disabled={isDisabled(option.id)}
              />
              <label className={`${component}-option-lbl `} htmlFor={option.id}>
                {itemFormatter(option.name)}
              </label>
            </div>
          );
        })
      ) : (
        <div className={`${component}-msg`}>Your search returned no results</div>
      )}
    </div>
  );
};

export default CheckboxBtnList;

CheckboxBtnList.propTypes = {
  // An array of objects, each with id (a required string), name (a required string),
  // and disabled (an optional boolean) properties. This represents the list of checkbox
  // options that will be rendered.
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  // A function that is called when checkbox is clicked
  onChange: PropTypes.func.isRequired,
  // An array of objects, each with id (a required string), name (a required string),
  // and disabled (an optional boolean) properties. This represents the currently selected
  // checkbox options.
  selectedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  // An optional function that formats the label for each checkbox option.
  // It takes an item as its argument and returns a string.
  // If not provided, it defaults to returning the name property of the item.
  itemFormatter: PropTypes.func,
  // An optional number that represents the maximum number of checkboxes that can be selected.
  // Defaults to Infinity.
  limit: PropTypes.number,
};
