// Libraries
import React, { useState, useContext, useEffect } from 'react';
import { isEmpty } from 'lodash';

// Styles
import '../updates/updateItem.scss';
import './flowClassAnnouncements.scss';

// Context
import { AppContext } from '../../contexts/AppContext';
import { MobileContext } from '../../contexts/MobileContext';

// Components
import FlowClassAnnouncementItem from './FlowClassAnnouncementItem';
import ExternalLinkModal from '../modal/ExternalLinkModal';
import Pagination from '../generalPagination/Pagination';
import Loader from '../loader/Loader';
import GeneralDropdown from '../dropDown/GeneralDropdown';

// Helpers
import { getAnnouncement } from '../../helpers/ApiHelper.jsx';
import { sortArrayByKeyDesc, financialYearsMenuItems } from '../../helpers/Utils';
import { startDateOfFinancialYear, endDateOfFinancialYear } from '../../helpers/TimeUtils';

const RESULT_PER_PAGE = 10;
const component = 'flow-class-anncmnts';

export default function FlowClassAnnouncements() {
  const NUMBER_OF_HISTORY_YEARS = 4;
  const YEARS_MENU_ITEMS = financialYearsMenuItems(NUMBER_OF_HISTORY_YEARS);
  const { waterSource } = useContext(AppContext);
  const { isMobile } = useContext(MobileContext);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [externalSiteLink, setExternalSiteLink] = useState('');
  const [curPage, setCurPage] = useState(1);
  const [selectedYear, setSelectedYear] = useState(YEARS_MENU_ITEMS[0]);

  useEffect(() => {
    if (!isEmpty(waterSource) && selectedYear.name) {
      setData([]);
      setIsLoading(true);
      const extractedYear = parseInt(selectedYear.name.split('/')[0]);
      const startDate = startDateOfFinancialYear((extractedYear + 1).toString());
      const endDate = endDateOfFinancialYear((extractedYear + 1).toString());
      getAnnouncement(waterSource.water_source_id, startDate, endDate, function (result) {
        if (!isEmpty(result)) {
          const sortedResult = sortArrayByKeyDesc(result, 'effectiveDateTime');
          setData(sortedResult);
        }
        setIsLoading(false);
      });
    }
  }, [waterSource, selectedYear]);

  const sliceResult = (items, page) => {
    return items.slice((page - 1) * RESULT_PER_PAGE, page * RESULT_PER_PAGE);
  };

  return (
    <div className={component}>
      <div className={`${component}-dropdown`}>
        <GeneralDropdown
          menuItem={YEARS_MENU_ITEMS}
          onItemClick={item => setSelectedYear(item)}
          selectedItem={YEARS_MENU_ITEMS.find(item => item.name == selectedYear.name)}
        />
      </div>
      {isLoading ? (
        <Loader />
      ) : !isEmpty(data) ? (
        sliceResult(data, curPage).map((announcement, index) => (
          <>
            {isMobile && <ExternalLinkModal link={externalSiteLink} modalId="externalLinkRules" />}
            <FlowClassAnnouncementItem
              key={index}
              effectiveDate={announcement.effectiveDateTime}
              announcementDate={announcement.announcedOnDateTime}
              announcementId={announcement.announcementId}
              linkTitle="View notice"
              setExternalSiteLink={setExternalSiteLink}
              targetId="#externalLinkRules"
            />
          </>
        ))
      ) : (
        <div className="no-data-message">
          No announcements have been found for this Water Source.
        </div>
      )}
      {data.length > 10 && !isLoading && (
        <Pagination
          onPageChange={setCurPage}
          totalCount={data.length}
          currentPage={curPage}
          pageSize={RESULT_PER_PAGE}
        />
      )}
    </div>
  );
}
