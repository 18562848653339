// Library
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil, startCase, isNumber, cloneDeep } from 'lodash';

// Style
import './snapshotBody.scss';

// Component
import DataSummary from '../dataSummary/DataSummary';
import DataSummaryBar from '../dataSummary/DataSummaryBar';
import Loader from '../loader/Loader';
import ViewDetailsLink from '../mapPopup/viewDetailsLink/ViewDetailsLink';
import Modal from '../modal/Modal';
import ParagraphSection from '../paragraphSection/ParagraphSection';
import StatusItem from '../../components/status/StatusItem';

// Helper
import { formatNumberDecimals, createMarkup } from '../../helpers/Utils';

// Context
import { MobileContext } from '../../contexts/MobileContext';

// Assets
import InfoButton from '../informationButton/InformationButton';

// Constant
import constant from '../../constants/Constants';
import { stored, available, traded, droughtFloodRisk } from './snapshotText.json';
import statusText from '../../components/status/statusText.json';
const container = 'snapshot-body';

const SnapshotBody = ({ data }) => {
  const { isMobile } = useContext(MobileContext);
  const [input, setInput] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [waterSourceIdSelected, setWaterSourceIdSelected] = useState(-1);
  const [expandAll, setExpandAll] = useState(false);
  let toggleId = 'id-1';

  const tableHeaderLabel = [
    {
      stored: [
        { text: stored.header1, id: '', percentage: false },
        {
          text: stored.header2,
          id: 'volume',
          alternative_id: 'last_week_volume',
          percentage: false,
        },
        ...(!isMobile
          ? [
              {
                text: stored.header3,
                id: 'full_volume',
                percentage: false,
              },
            ]
          : []),
        { text: stored.header4, id: 'volume_perc', percentage: true },
      ],
      available: [
        {
          text: available.header1,
          id: '',
          percentage: false,
          modal: available.modal,
        },
        { text: available.header2, id: '', percentage: false },
      ],
      traded: [
        {
          text: traded.header1,
          id: '',
          percentage: false,
          modal: traded.modal,
        },
      ],
      droughtFloodRisk: [
        {
          text: droughtFloodRisk.header1,
        },
      ],
    },
  ];

  const headerLabel = [
    {
      text: 'Gen Sec.',
      id: 'General security',
      unit: '%',
      hideIfNull: true,
    },
    {
      text: 'Gen. Sec. A',
      id: 'General security A',
      unit: '%',
      hideIfNull: true,
    },
    {
      text: 'Gen. Sec. B',
      id: 'General security B',
      unit: '%',
      hideIfNull: true,
    },
    { text: 'High Sec.', id: 'High security', unit: '%' },
    { text: 'Stored', id: 'Stored', unit: 'GL' },
  ];
  const headerLabelGW = [
    { text: 'Aquifer', id: 'Aquifer', unit: '%' },
    { text: '', id: '', unit: '' },
    { text: '', id: '', unit: '' },
  ];

  // Life Cycle
  useEffect(() => {
    setFilteredData(filterData(data, input));
  }, [data, input]);

  // Helper
  const filterData = (target, keyword) => {
    if (!Array.isArray(target) || !keyword) return target;
    const result = [];
    const reg = new RegExp(keyword, 'gi');
    target.forEach(element => {
      const names = [element.water_source_name];
      if (!isEmpty(element.dams)) {
        element.dams.forEach(dam => names.push(dam.station_name));
      }

      const isMatch = names.reduce((acc, name) => {
        const matchWord = name ? name.match(reg) : null;
        if (matchWord) {
          acc = true;
        }
        return acc;
      }, false);

      if (keyword && !isMatch) {
        return;
      }
      result.push(element);
    });
    return result;
  };

  const checkIsNumber = (value, divisor) => {
    if (isNumber(value) && !Number.isNaN(value)) {
      return value / divisor;
    }
    if (Number.isNaN(value) || !isNumber(value) || typeof value === 'undefined') {
      return '-';
    }
    return value;
  };

  // Component
  const modalMaker = text => {
    const paragraph = text;

    if (paragraph) {
      return <ParagraphSection sectionTitle="" sectionContent={paragraph} />;
    }
  };

  const waterSourceHeader = waterSource => {
    const { water_source_type, water_source_data } = waterSource;
    const headerLabelList = water_source_type.includes(constant.WATER_SOURCE_TYPE.groundwater)
      ? headerLabelGW
      : headerLabel;
    let labels = headerLabelList.map(label => {
      const valueObj = water_source_data.find(resource => resource.label === label.id);
      return {
        ...label,
        value: isNil(valueObj) ? undefined : valueObj.value,
      };
    });
    const hasBothGenSec = labels.find(
      item =>
        item.text.includes('Gen. Sec. ') &&
        (!isNil(item.value) || typeof item.value !== 'undefined'),
    );

    return (
      <div className={`${container}-header`}>
        <div className={`${container}-header-left`}>
          <div className={`${container}-header-left-title`}>
            <span>{waterSource.water_source_name}</span>
          </div>
          {!isMobile && (
            <div className={`${container}-header-left-link`}>
              <ViewDetailsLink
                pathname={constant.TAB_OPTIONS.updates.link}
                targetWaterSource={{
                  water_source_id: waterSource.water_source_id,
                  water_source_name: waterSource.water_source_name,
                }}
              />
            </div>
          )}
        </div>
        <div className={`${container}-header-right`}>
          {labels.map((label, ind) => {
            return !label.hideIfNull || !isNil(label.value) ? (
              isMobile && hasBothGenSec && label.text === 'High Sec.' ? (
                ''
              ) : (
                <div className={`${container}-header-right-container`} key={ind}>
                  <div className={`${container}-header-right-value`}>{`${
                    isNil(label.value) || typeof label.value === 'undefined'
                      ? '-'
                      : `${
                          isMobile || label.text.includes('Gen. Sec. ') || label.text === 'Aquifer'
                            ? `${label.text}${'   '}`
                            : ''
                        }${formatNumberDecimals(
                          label.unit === 'GL' ? label.value / 1000 : label.value,
                        )}${label.unit}`
                  }`}</div>
                </div>
              )
            ) : (
              <React.Fragment key={ind}></React.Fragment>
            );
          })}
        </div>
      </div>
    );
  };

  const getDroughtFloodData = values => {
    const rangeKey = Object.keys(statusText.droughtFloodRiskIndex).find(index => {
      const minMaxRange = index.split('~');
      return values.total_score >= Number(minMaxRange[0]) &&
        values.total_score <= Number(minMaxRange[1])
        ? index
        : false;
    });
    const statusItem = statusText.droughtFloodRiskIndex[rangeKey];
    return statusItem;
  };

  const expandCollapseButtons = () => {
    return (
      <div className={`${container}-subheader-right col-lg-3`}>
        <button
          className={`${container}-button`}
          onClick={() => {
            setExpandAll(true);
            setWaterSourceIdSelected(-1);
          }}
        >
          Expand all
        </button>
        |
        <button
          className={`${container}-button`}
          onClick={() => {
            setExpandAll(false);
            setWaterSourceIdSelected(-1);
          }}
        >
          Collapse all
        </button>
      </div>
    );
  };

  const waterSourceStationItem = (station, values, type, stationType, index) => {
    const itemClass = `${container}-content-title-item ${container}-content-value-item row`;
    return (
      <div className={`${container}-content-value`} key={`${index}${stationType}`}>
        {type === 'stored' ? (
          <>
            {values.map((label, index) => {
              const valueDivisor = !label.percentage ? 1000 : 1;
              const value = station[label.id]
                ? checkIsNumber(station[label.id], valueDivisor)
                : station.resources && station.resources[label.id]
                ? checkIsNumber(station.resources[label.id], valueDivisor)
                : station.resources && station.resources[label.alternative_id]
                ? checkIsNumber(station.resources[label.alternative_id], valueDivisor)
                : '-';
              return (
                <div key={index} className={itemClass}>
                  {index === 0 && startCase(station.station_name)}
                  {index !== 0 && (
                    <>
                      <div className="col-7">
                        {formatNumberDecimals(value, label.percentage, label.percentage)}
                      </div>
                      {label.percentage ? (
                        <div className="col-5">%</div>
                      ) : (
                        <div className="col-5">&nbsp;GL</div>
                      )}
                    </>
                  )}
                </div>
              );
            })}
            {!isMobile && (
              <div className={`${container}-content-value-bar`}>
                <DataSummaryBar
                  bars={[
                    {
                      type: 'storage_percentage',
                      value: station.resources
                        ? station.resources.volume_perc || station.resources.last_week_volume
                        : 0,
                      color: '#a4a9ac',
                      width: '50',
                    },
                  ]}
                  maximum={100}
                  customizedStyle={
                    stationType === 'dam' ? 'full' : stationType === 'weir' ? 'quarter' : ''
                  }
                />
              </div>
            )}
          </>
        ) : (
          <>
            <div className={itemClass}>{startCase(values.label)}</div>
            <div className={itemClass}>
              <div className="col-7">
                {`${values.label === 'average_price' ? '$' : ''}${formatNumberDecimals(
                  values.label === 'average_price'
                    ? checkIsNumber(values.value, 100)
                    : checkIsNumber(values.value, 1),
                )}`}
              </div>
              <div className="col-5">&nbsp;{values.unit}</div>
            </div>
          </>
        )}
      </div>
    );
  };

  const waterSourceTradedItem = (values, listKey) => {
    const itemClass = `${container}-content-title-item ${container}-content-value-item row`;
    return (
      <div key={listKey} className={`${container}-content-value`}>
        <div className={itemClass}>{startCase(values.label)}</div>
        {values.value.map((item, index) => {
          const value =
            values.label === 'average_price'
              ? `$${formatNumberDecimals(checkIsNumber(item.value, 100))}`
              : `${formatNumberDecimals(checkIsNumber(item.value, 1))}`;
          return (
            <div key={index} className={itemClass}>
              <div className="col-7">{value}</div>
              <div className="col-5">&nbsp;{values.unit}</div>
            </div>
          );
        })}
      </div>
    );
  };

  const waterSourceDroughtFloodRiskItem = values => {
    const itemClass = `${container}-content-title-item-drought ${container}-content-value-item-drought row`;
    return (
      <div className={`${container}-content-value`}>
        <div className={`${itemClass}`}>
          <div className={`${itemClass} col-12`}>
            <StatusItem
              data={getDroughtFloodData(values.resources)}
              type={'droughtFloodRisk'}
              snapshotView={true}
            />
          </div>
        </div>
      </div>
    );
  };

  const waterSourceContent = (
    headers,
    dams,
    weirs,
    water_source_type,
    usage,
    traded,
    droughtFloodRisk,
  ) => {
    return Object.entries(headers).map(([key, value], index) => {
      const filteredDams = dams.filter(item => !isEmpty(item));
      let showHeader = water_source_type.includes(constant.WATER_SOURCE_TYPE.groundwater)
        ? key !== 'stored'
        : true;
      switch (key) {
        case 'stored':
          showHeader = !isEmpty(filteredDams) || !isEmpty(weirs);
          break;
        case 'available':
          showHeader = !isEmpty(usage);
          break;
        case 'traded':
          showHeader = !isEmpty(traded);
          break;
        case 'droughtFloodRisk':
          showHeader = !isEmpty(droughtFloodRisk);
          break;
        default:
          break;
      }
      return (
        showHeader && (
          <div className={`${container}-content`} key={`${index}`}>
            <div className={`${container}-content-title`}>
              {value.map((label, itemIndex) => {
                toggleId += '1';
                return (
                  <React.Fragment key={itemIndex}>
                    <div className={`${container}-content-title-item`}>
                      <div
                        key={`${itemIndex}-content-title-item-header`}
                        className={`${container}-content-title-item-header`}
                        dangerouslySetInnerHTML={createMarkup(label.text)}
                      />
                      {label.modal && (
                        <>
                          <Modal
                            id={`${toggleId}`}
                            title={label.modal.title}
                            body={modalMaker(label.modal.paragraph)}
                          />
                          <InfoButton
                            targetDataId={`#${toggleId}`}
                            iconColor={`${container}-content-title-icon`}
                          />
                        </>
                      )}
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
            {key === 'stored' && (
              <>
                {filteredDams.map((dam, index) => {
                  return waterSourceStationItem(dam, value, key, 'dam', index);
                })}
                {weirs.map((weir, index) => {
                  return waterSourceStationItem(weir, value, key, 'weir', index);
                })}
              </>
            )}
            {key === 'available' &&
              usage &&
              usage.map((item, index) => waterSourceStationItem(null, item, key, index))}
            {key === 'traded' &&
              traded &&
              traded.map((item, index) => waterSourceTradedItem(item, index))}
            {key === 'droughtFloodRisk' &&
              !isEmpty(droughtFloodRisk) &&
              waterSourceDroughtFloodRiskItem(droughtFloodRisk[0])}
          </div>
        )
      );
    });
  };

  const waterSourceSummary = (waterSource, index) => {
    const { dams, weirs, water_source_type, usage, traded, droughtFloodRisk } = waterSource;
    const headerLabels = cloneDeep(tableHeaderLabel);
    return (
      <DataSummary
        key={index}
        header={waterSourceHeader(waterSource)}
        selected={expandAll || waterSource.water_source_id === waterSourceIdSelected}
        onExpand={state => {
          state
            ? setWaterSourceIdSelected(waterSource.water_source_id)
            : setWaterSourceIdSelected(-1);
        }}
      >
        {headerLabels.map(label => {
          if (traded) {
            traded.map(x =>
              x.value.map(y => {
                if (y.zone) {
                  const matched = label.traded.find(
                    tradeLabel => tradeLabel.text === y.zone.split(' – ')[0],
                  );
                  if (!matched) {
                    label.traded.push({
                      id: '',
                      percentage: false,
                      text: y.zone.split(' – ')[0],
                    });
                  }
                }
              }),
            );
          }
          return waterSourceContent(
            label,
            dams,
            weirs,
            water_source_type,
            usage,
            traded,
            droughtFloodRisk,
          );
        })}
      </DataSummary>
    );
  };

  return (
    <>
      <div className={`${container}-subheader`}>
        <div className={`${container}-subheader-left col-lg-9`}>
          <input
            className={`${container}-search-box`}
            placeholder="Search water source or dam…"
            autoComplete="off"
            onChange={e => setInput(e.target.value)}
          />
        </div>
        {!isMobile && expandCollapseButtons()}
      </div>
      <div className={container}>
        {isMobile && expandCollapseButtons()}
        {!isMobile && (
          <div className={`${container}-table-header`}>
            <div className={`${container}-table-header-left`}>Water Source</div>
            <div className={`${container}-table-header-right`}>
              <div className={`${container}-table-header-right-title`}>
                General Security Allocation
              </div>
              <div className={`${container}-table-header-right-title`}>
                High Security Allocation
              </div>
              <div className={`${container}-table-header-right-title`}>Stored</div>
            </div>
          </div>
        )}
        {isEmpty(filteredData) && <Loader width={'10px'} height={'40px'} />}
        {filteredData.map((waterSource, index) => waterSourceSummary(waterSource, index))}
      </div>
    </>
  );
};

export default SnapshotBody;

SnapshotBody.propTypes = {
  data: PropTypes.array,
};
