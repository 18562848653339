// Library
import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

// Style
import './body.scss';
import './siteInfo.scss';

// Components
import FavouriteBtn from '../../components/favourites/FavouriteBtn';
import ConfirmationModal from '../../components/modal/ConfirmationModal';
import Notification from '../../components/modal/Notification';
import WaterSiteInfo from '../../components/waterSiteInfo/WaterSiteInfo';
import StorageGraph from '../../components/waterSiteInfo/StorageGraph';
import RiverGaugeGraph from '../../components/waterSiteInfo/RiverGaugeGraph';
import BoreGraph from '../../components/waterSiteInfo/BoreGraph';
import AlertLink from '../../components/alert/AlertLink';
import DataQualityMobileLegend from '../../components/generalComponents/dataQuality/DataQualityMobileLegend';

// Context
import { MiniAppContext } from '../../contexts/MiniAppContext';
import AlertResponse from '../../components/alert/AlertResponse';

//Constants
import alertResponseText from '../../components/alert/AlertResponseText.json';
import GaugeAlertModalBody from '../../components/alert/GaugeAlertModalBody';
const component = 'site-info';

const SiteInfo = () => {
  const location = useLocation();
  const { station } = useContext(MiniAppContext);
  const [siteRemoved, setSiteRemoved] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [isMini, setIsMini] = useState(false);
  const [showManageAlertResponse, setShowManageAlertResponse] = useState(false);
  const [responseType, setResponseType] = useState();
  const [hydros, setHydros] = useState([]);
  const [activeHydroType, setActiveHydroType] = useState('');

  const onYesClicked = () => {
    setSiteRemoved(true);
  };

  setTimeout(function () {
    setShowMsg(false);
  }, 2000);

  const graphItems = {
    dam: <StorageGraph />,
    gauge: <RiverGaugeGraph setActiveHydroType={setActiveHydroType} setHydros={setHydros} />,
    weir: <RiverGaugeGraph setActiveHydroType={setActiveHydroType} />,
    bore: <BoreGraph />,
  }[station?.station_type];

  useEffect(() => {
    if (location.pathname.startsWith('/m/')) {
      setIsMini(true);
    } else {
      setIsMini(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderManageAlertResponse = responseType => {
    setShowManageAlertResponse(true);
    setResponseType(responseType);
  };

  return (
    <div className={component}>
      <Notification show={showMsg} />
      <ConfirmationModal modalId="removeSiteConfirm" onYesClicked={() => onYesClicked()} />
      <div className={`${component}-upper`}>
        <div
          className={`${component}-title`}
        >{`${station.station_id} - ${station.station_name} `}</div>
        <div className={`${component}-buttons-container`}>
          <div className={`${component}-icon-container`}>
            <FavouriteBtn
              siteRemoved={siteRemoved}
              setSiteRemoved={state => setSiteRemoved(state)}
              setSiteAdded={state => setShowMsg(state)}
            />
            {/* only appear when staionType=gauge */}
            {station.station_type === 'gauge' && (
              <AlertLink
                modalType={'gauge-modal'}
                showManageAlertResponse={showManageAlertResponse}
                setShowManageAlertResponse={setShowManageAlertResponse}
                stationInfo={station}
                isMini={isMini}
                alertModal={
                  <>
                    <AlertResponse
                      show={showManageAlertResponse}
                      responseBodyText={alertResponseText.update[responseType]}
                    />
                    {!showManageAlertResponse && (
                      <GaugeAlertModalBody
                        setManageAlertResponse={renderManageAlertResponse}
                        stationInfo={station}
                        hydrometricInit={activeHydroType}
                        hydroOptions={hydros}
                      />
                    )}
                  </>
                }
              />
            )}
          </div>
          {station.station_type !== 'dam' && <DataQualityMobileLegend />}
        </div>
        <WaterSiteInfo />
      </div>
      {station && station.station_type && graphItems}
    </div>
  );
};

export default SiteInfo;

SiteInfo.propTypes = {
  location: PropTypes.object,
};
