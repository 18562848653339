export const STATUS = {
  NORMAL: { statusName: 'Validated', statusDesc: '' },
  ANOMALY: { statusName: 'Unvalidated', statusDesc: 'There may be an issue with the data.' },
  SUSPECT: {
    statusName: 'Invalidated',
    statusDesc: 'There is a problem with the data. Do not use.',
  },
};

export default Object.freeze({
  53: { ...STATUS.NORMAL },
  55: { ...STATUS.ANOMALY, quailtyDesc: 'Flat line data, >8 data points identical​' },
  56: { ...STATUS.ANOMALY, quailtyDesc: 'Instrument not calibrated​' },
  59: { ...STATUS.ANOMALY, quailtyDesc: 'Spike, data not replaced' },
  125: { ...STATUS.NORMAL },
  130: { ...STATUS.NORMAL },
  135: { ...STATUS.NORMAL },
  136: { ...STATUS.NORMAL },
  156: { ...STATUS.NORMAL },
  215: { ...STATUS.SUSPECT, quailtyDesc: 'Data Gap​' },
});
