// Libraries
import React, { useEffect, useContext, useState } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

// Styles
import './eventsMarker.scss';

// Components
import MapPopup from '../mapPopup/MapPopup';

// Context
import { AppContext } from '../../contexts/AppContext';
import { MobileContext } from '../../contexts/MobileContext';
import { MapContext } from '../../contexts/MapContext';

// Helpers
import { generateDivIcon } from '../../helpers/MapServiceApiHelper';
import { getAllEventsFromWaterSource } from '../../helpers/UpdatesApiHelper.jsx';

// Constants
import constants from '../../constants/Constants.jsx';
const component = 'events-marker';

export default function EventsMarker({ setShowSpinner, selected, markerClusterRef, mapRef }) {
  const { waterSource } = useContext(AppContext);
  const { isMobile } = useContext(MobileContext);
  const { markerSelected } = useContext(MapContext);
  const [waterSourceEvents, setWaterSourceEvents] = useState([]);

  // Lifecycle
  useEffect(() => {
    setWaterSourceEvents([]);
    if (selected) {
      getAllEventsFromWaterSource(waterSource, setWaterSourceEvents);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const setMarkerIcon = () => {
    const sizeConfig = isMobile ? constants.MAP_MARKER_SIZE_MOBILE : constants.MAP_MARKER_SIZE;
    const className = `${component}-popup-icon`;
    const markerIcon = generateDivIcon('', sizeConfig, className);
    return markerIcon;
  };

  const getPosition = feature => {
    if (feature.geometry) {
      return [feature.geometry.coordinates[1], feature.geometry.coordinates[0]];
    } else {
      return [feature.lat, feature.long];
    }
  };

  return (
    <>
      {!isEmpty(waterSourceEvents) &&
        waterSourceEvents.map((feature, index) => (
          <MapPopup
            contentStyle={`marker-popup-header ${component}-popup`}
            markerClusterRef={markerClusterRef}
            mapRef={mapRef}
            key={index}
            type={feature.type}
            openPopup={
              markerSelected &&
              markerSelected.station_id === feature.station_id &&
              markerSelected.type === feature.type
            }
            position={getPosition(feature)}
            data={feature}
            title={feature.type}
            custIcon={setMarkerIcon()}
            markerType={feature.type}
            setShowSpinner={setShowSpinner}
          />
        ))}
    </>
  );
}

EventsMarker.propTypes = {
  setShowSpinner: PropTypes.func,
  type: PropTypes.string,
  selected: PropTypes.bool,
  markerClusterRef: PropTypes.object,
  mapRef: PropTypes.object,
};
