// Library
import React, { useState, useEffect, useContext } from 'react';
import { isEmpty, isNil } from 'lodash';
import moment from 'moment';

// Style
import './waterSiteGraph.scss';

// Context
import { MiniAppContext } from '../../contexts/MiniAppContext';

// Components
import GeneralDropdown from '../dropDown/GeneralDropdown';
import DotSvg from './DotSvg';
import WaterSiteInfoLineChart from './WaterSiteInfoLineChart';
import DataQualityText from '../generalComponents/dataQuality/DataQualityText';

// Helper
import { formatDate, getMonthListByRange } from '../../helpers/TimeUtils';
import { formatNumberDecimals, formatBoreDataResources } from '../../helpers/Utils';
import { getGroundWaterData } from '../../helpers/WaterDataApiHelper';

// import config from '../../configs/featureToggleConfig.json';
const component = 'water-site-graph';
const getDataFromWaterDataApi = true;

export default function BoreGraph() {
  const DATA_PROPS = {
    value: { dataKey: 'GroundwaterDepthBelowSurfaceLevel', color: '#707070' },
  };
  const currentMonth = {
    id: formatDate(new Date(), 'YYYY-MM-DD', 'YYYY-MM-DD'),
    name: formatDate(new Date(), 'YYYY-MM-DD', 'MMMM YYYY'),
  };
  const { station } = useContext(MiniAppContext);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [activeMonth, setActiveMonth] = useState(currentMonth);
  const [monthRange, setMonthRange] = useState([]);
  const [dropdownItems, setDropdownItems] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [valueItems, setValueItems] = useState({});

  // Life Cycle
  useEffect(() => {
    const yearRange = getMonthListByRange(7, currentMonth.id, 'MMMM YYYY', 'year', 'YYYY-MM-DD');
    const monthRange = getMonthListByRange(12, activeMonth.id, 'MMMM YYYY', 'month', 'YYYY-MM-DD');
    getData(monthRange);
    setDropdownItems(yearRange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [station]);

  // Helpers
  const getData = range => {
    (async () => {
      const dateFormat = getDataFromWaterDataApi ? 'DD-MMM-YYYY 00:00' : 'YYYY-MM';
      const from = formatDate(range[0].id, 'YYYY-MM-DD', dateFormat);
      const to = formatDate(range[range.length - 1].id, 'YYYY-MM-DD', dateFormat);
      let result = await getGroundWaterData(station.station_id, 'monthly', from, to);
      result = result.map(item => {
        return {
          ...item,
          timeStamp: moment(item.timeStamp, 'DD-MMM-YYYY HH:mm').format('YYYY-MM-DD'),
        };
      });

      result = formatBoreDataResources(result, false, 'YYYY-MM-DD', false);
      const finalResult = getDataFromWaterDataApi ? result : result.resources;
      if (finalResult) {
        const res = finalResult;
        const newGraphData = range.map(({ id }) => {
          const formatted = formatDate(id, 'YYYY-MM-DD', 'YYYY-MM');
          const found = res.find(item => {
            return formatDate(item.date, 'YYYY-MM-DD', 'YYYY-MM') === formatted;
          });
          if (found) {
            return { ...found, date: id };
          } else {
            return { date: id };
          }
        });
        setGraphData(newGraphData);
        setMonthRange(range);
        getVolume(finalResult, to);
      }
      setSelectedMonth(range[range.length - 1]);
      setActiveMonth(range[range.length - 1]);
    })();
  };

  const getVolume = (resourceData, date) => {
    const value = resourceData.find(
      item =>
        formatDate(item.date, 'YYYY-MM-DD', 'YYYY-MM') ===
        formatDate(date, 'YYYY-MM-DD', 'YYYY-MM'),
    );
    setValueItems({
      label: `Depth`,
      value: value ? value[DATA_PROPS.value.datakey] : '-',
      color: DATA_PROPS.value.color,
    });
  };

  const onMonthSelected = month => {
    const range = getMonthListByRange(12, month.id, 'MMMM YYYY', 'month', 'MMMM YYYY');
    setSelectedMonth(month);
    getData(range);
  };

  const onLineClicked = data => {
    if (data && data.activePayload && data.activePayload[0]) {
      const payload = data.activePayload[0].payload;
      setValueItems({
        label: `Depth`,
        value: payload[DATA_PROPS.value.dataKey],
        color: DATA_PROPS.value.color,
      });
      const dateItem = {
        id: formatDate(payload.date, 'YYYY-MM-DD', 'YYYY-MM-DD'),
        name: formatDate(payload.date, 'YYYY-MM-DD', 'MMMM YYYY'),
      };
      setActiveMonth(dateItem);
    }
  };

  return (
    <>
      <div className={component}>
        <div className={`${component}-storage-title`}>12 months prior to</div>
        {!isEmpty(dropdownItems) && (
          <GeneralDropdown
            menuItem={dropdownItems}
            selectedItem={selectedMonth}
            onItemClick={item => onMonthSelected(item)}
          />
        )}
        <div className={`${component}-subtitle`}>
          <div>
            <DotSvg fillColor={valueItems.color} />
            <span>{valueItems.label}</span>
          </div>
        </div>
        <div className={`${component}-volume`}>
          <DataQualityText qualityCode={valueItems?.value?.qualityCode}>
            {`${
              !isNil(valueItems?.value?.value) ? formatNumberDecimals(valueItems.value.value) : '-'
            } m below ground`}
          </DataQualityText>
        </div>
        <div className={`${component}-storage-date`}>
          {monthRange.map((month, index) => (
            <div key={index}>
              {(index === 0 || index === monthRange.length - 1) &&
                formatDate(month.id, 'YYYY-MM-DD', 'YYYY')}
              <div
                className={
                  formatDate(month.id, 'YYYY-MM-DD', 'YYYY-MM') ===
                  formatDate(activeMonth.id, 'YYYY-MM-DD', 'YYYY-MM')
                    ? `${component}-storage-date-selected`
                    : ''
                }
              >
                {formatDate(month.id, 'YYYY-MM-DD', 'MMM')}
              </div>
            </div>
          ))}
        </div>
        <div className={`${component}-graph`}>
          {!isEmpty(graphData) && (
            <WaterSiteInfoLineChart
              margin={{ right: 15, left: 15, top: 15, bottom: 15 }}
              data={graphData}
              chartProps={DATA_PROPS}
              onLineClicked={onLineClicked}
              unit="m below ground"
              toRound={false}
              reversed
            />
          )}
        </div>
      </div>
      <div className={`${component}-gap`} />
    </>
  );
}
