// Libraries
import React from 'react';
import moment from 'moment';
import { Bar, XAxis, YAxis, CartesianGrid, Legend, Label, BarChart } from 'recharts';
import PropTypes from 'prop-types';

// Styles
import './MapPopup.scss';
import './orderUsagePopup.scss';

const component = 'order-usage';

export default function OrderUsagePopup({ feature, data, dataStatusMsg }) {
  return (
    <div className={`${component}-popup`}>
      <div className={`map-popup-header ${component}`}>River Section</div>
      <div className={`map-popup-container ${component}-popup-container`}>
        <div className="map-popup-body-title">{feature.properties.CAIRO_SECTION_DESCRIPTION}</div>
        {data && data.values ? (
          <>
            <div className={`${component}-chart`}>
              <BarChart
                width={320}
                height={160}
                data={data.values}
                margin={{ right: 15, left: -5 }}
                barGap={0}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis tick={{ fontSize: 10 }} tickFormatter={() => ''}>
                  <Label value="-7 Days" position="insideBottomLeft" dy={-5} dx={-15} />
                  <Label value="Today" position="insideBottom" dy={-5} />
                  <Label value="+7 Days" position="insideBottomRight" dy={-5} dx={15} />
                </XAxis>
                <YAxis
                  tickFormatter={tick => (tick % 1 !== 0 ? tick.toFixed(2) : tick)}
                  label={{
                    value: 'Volume (ML)',
                    angle: -90,
                    dx: -15,
                    className: 'chart-yaxis-label',
                  }}
                />
                <Legend wrapperStyle={{ bottom: -35 }} iconSize={20} iconType="plainline" />
                <Bar
                  isAnimationActive={false}
                  dataKey="order"
                  fill="#004ba3"
                  name="Ordered volume"
                />
                <Bar isAnimationActive={false} dataKey="usage" fill="#63cbe8" name="Taken volume" />
              </BarChart>
            </div>
            <div className={`${component}-popup-timestamp`}>
              Updated: {moment().format('DD MMM YYYY')} 8:00am AEST
            </div>
          </>
        ) : (
          dataStatusMsg
        )}
      </div>
    </div>
  );
}

OrderUsagePopup.prototype = {
  feature: PropTypes.object,
  data: PropTypes.object,
  dataStatusMsg: PropTypes.string,
};
