// Libraries
import React, { useState, useContext, useEffect } from 'react';
import util from 'util';

// Styles
import './body.scss';
import '../grid.scss';

// Context
import { AppContext } from '../../contexts/AppContext';

// Components
import BodyContainer from './BodyContainer';
import FlowClassAnnouncements from '../../components/flowClass/FlowClassAnnouncements';
import UpdateTabRestrictions from '../../components/restrictions/UpdateTabRestrictions';
import PageTop from '../../components/pageTop/PageTop';
import InfoIconAndModal from '../../components/informationButton/InfoIconAndModal';

// Helpers
import { getRestrictionsFromWaterSource } from '../../helpers/UpdatesApiHelper.jsx';
import { findLastWord } from '../../helpers/Utils';

// Assets
import config from '../../abstract/IntroText.json';
import flowModal from '../../components/modal/modalText/FlowClassTitleModalText.json';

// Constant
const container = 'flow-class-container';

export default function FlowClass() {
  const { waterSource } = useContext(AppContext);
  const [restrictions, setRestrictions] = useState([]);
  const { modal } = flowModal;

  useEffect(() => {
    getRestrictionsFromWaterSource(waterSource.water_source_id, setRestrictions);
  }, [waterSource]);

  return (
    <BodyContainer>
      <div className="update-body row">
        <div className="col-lg-8 col-sm-12 col-12">
          <PageTop
            title={
              <div className={`${container}-pageTop`}>
                <span>
                  {
                    findLastWord(util.format(config.flowClass.title, waterSource.water_source_name))
                      .notLast
                  }
                  &nbsp;
                  <InfoIconAndModal
                    modalId="flowTitleModal"
                    modalContent={modal}
                    lastWord={
                      <span className={'page-body-title'}>
                        {
                          findLastWord(
                            util.format(config.flowClass.title, waterSource.water_source_name),
                          ).last
                        }
                        &nbsp;
                      </span>
                    }
                  />
                </span>
              </div>
            }
            intro={config.flowClass.intro}
          />
          <FlowClassAnnouncements />
        </div>
        <div className=" col-lg-4 col-sm-12 col-12">
          <UpdateTabRestrictions restrictions={restrictions} />
        </div>
      </div>
    </BodyContainer>
  );
}
