// Libraries
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';

// Styles
import './alertModalBody.scss';

// Components
import GeneralDropdown from '../dropDown/GeneralDropdown';

// Helpers
import { getDatesByPeriod } from '../../helpers/TimeUtils';
import {
  getSubscriptionsByUserID,
  postSubscription,
  editAlert,
} from '../../helpers/SubscriptionsApiHelper';
import azureB2CHelper from '../../helpers/AzureB2CHelper';

/* Context */
import { AppContext } from '../../contexts/AppContext';

/* Constant */
import responseText from './AlertResponseText.json';
import { ALERT_TYPES } from '../userProfileItems/manageAlert/ManageAlertBody';

const component = 'alert-modal-body';
const DELIVERY_TYPE = [
  { name: 'Mobile', check: false },
  { name: 'Email', check: false },
];
const { created, error, duplicated } = responseText.APIResponse;

export default function AlertModalBody({
  setResponse,
  alertTypeList,
  alertType,
  stationList = [],
  notifyIfPersist,
  editMode = false,
  alertId,
}) {
  // State
  const [deliveryTypes, setDeliveryTypes] = useState(DELIVERY_TYPE);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [selectedItem, setSelectedItem] = useState(alertTypeList[0].id);
  const [selectedStation, setSelectedStation] = useState(stationList[0]);
  const { waterSource } = useContext(AppContext);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [alertList, setAlertList] = useState([]);
  const userId = azureB2CHelper.getUserEmail();

  // Life Cycle
  useEffect(() => {
    (async () => {
      const userEmail = azureB2CHelper.getUserEmail();
      if (userEmail) {
        await getSubscriptionsByUserID(userEmail, alertType, setAlertList);
      }
    })();
    setDeliveryTypes(DELIVERY_TYPE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertType]);

  useEffect(() => {
    if (!isEmpty(stationList)) {
      setSelectedStation(stationList[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stationList]);

  useEffect(() => {
    // Checking delivery checkbox
    let isLeastOne = false;
    deliveryTypes.forEach(type => {
      let checkState = get(type, 'check');
      if (checkState) isLeastOne = true;
    });
    if (allowSubmit !== isLeastOne) setAllowSubmit(isLeastOne);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryTypes]);

  const onCheckBoxChange = target => {
    const { value, checked } = target;
    setDeliveryTypes(prevState => {
      const target = prevState.findIndex(item => item.name === value);
      if (target !== -1) {
        prevState[target].check = checked;
        return [...prevState];
      } else {
        return [...prevState];
      }
    });
  };

  const validateDuplicatedAlerts = selected => {
    const key = alertType === 'station' ? '' : `${alertType}_type`;
    const duplicated = false;
    if (!isEmpty(alertList)) {
      const dropdownItem = alertTypeList.find(item => item.id === selectedItem);
      if (dropdownItem) {
        const exist = alertList.find(
          alert =>
            alert[key] === selected.name &&
            (alertType === 'update'
              ? alert.water_source_id === waterSource.water_source_id
              : alert.station_id === selectedStation?.id),
        );
        return !isEmpty(exist);
      } else return duplicated;
    }
  };

  const formatDataByType = item => {
    const deliveryType = deliveryTypes
      .filter(type => type.check)
      .map(type => type.name)
      .join(',');
    const expiryDate = getDatesByPeriod(12, 'YYYY-MM-DD', 'months');
    const wsId = waterSource.water_source_id;
    const baseFormat = {
      deliveryType,
      notifyIfPersist,
      alertType,
      wsId,
      userId,
      expiryDate,
    };
    const dataFormat = {
      [ALERT_TYPES[0]]: {
        ...baseFormat,
        stationId: selectedStation?.id,
        constraintType: item.name_id,
      },
      [ALERT_TYPES[1]]: {
        ...baseFormat,
        updateTypeId: Number(item.id),
      },
      [ALERT_TYPES[2]]: {
        ...baseFormat,
        stationId: selectedStation?.id,
        constraintType: item.name_id,
      },
    };
    return dataFormat[alertType];
  };

  const handleSubmit = async () => {
    let typeList = [];
    const type = alertTypeList.find(item => item.id === selectedItem);

    if (type && type.name_id !== 'all') {
      typeList = alertTypeList.filter(item => item.id === selectedItem);
    } else {
      typeList = alertTypeList.filter(item => item.id !== selectedItem);
    }

    if (!buttonClicked) {
      setButtonClicked(true);

      const updateAlertsPromises = typeList.map(async typeItem => {
        const error = validateDuplicatedAlerts(typeItem);
        if (!error) {
          let data;
          if (!editMode) {
            data = formatDataByType(typeItem);
            const success = await postSubscription(data);
            if (success) return created;
            return error;
          } else {
            data = {
              notifyIfPersist: notifyIfPersist,
              alertType: alertType,
              alertId,
              userId,
            };
            const response = await editAlert(data);
            if (response === true) {
              setResponse('update');
            } else {
              setResponse('error');
            }
          }
        } else {
          return duplicated;
        }
      });
      const res = await Promise.all(updateAlertsPromises);

      if (!res.includes(duplicated)) {
        if (res.includes(created)) {
          setResponse('create');
        } else if (res.includes(error)) {
          setResponse('error');
        }
      } else {
        if (res.length > 1 && res.includes(created)) {
          setResponse('halfDuplicate');
        } else {
          setResponse('duplicate');
        }
      }
      setButtonClicked(false);
    }
    setDeliveryTypes(DELIVERY_TYPE);
  };

  return (
    <div data-testid={component} className={component}>
      <div className={`${component}-type`}>
        <div className={`${component}-type-item`}>
          <span className={`${component}-type-text`}>When </span>
          <div className={`${component}-dropdown-types`}>
            <GeneralDropdown
              menuItem={alertTypeList}
              onItemClick={item => {
                setSelectedItem(item.id);
              }}
              selectedItem={alertTypeList[0]}
            />
          </div>
          <span>is/are updated{isEmpty(stationList) ? '.' : ''}</span>
        </div>
        {!isEmpty(stationList) && (
          <div className={`${component}-type-item`}>
            <span className={`${component}-type-text`}> for </span>
            <div className={`${component}-dropdown-sites`}>
              <GeneralDropdown
                menuItem={stationList}
                onItemClick={item => {
                  setSelectedStation(item);
                }}
                selectedItem={selectedStation}
              />
            </div>
          </div>
        )}
      </div>
      Send alert to (select at least one)
      <div className={`${component}-type`}>
        {deliveryTypes.map((typeItem, index) => (
          <div key={index} className={`${component}-type-check col-4`}>
            <input
              id={`${component}-type-check`}
              readOnly
              className="checkbox"
              type="checkbox"
              value={typeItem.name}
              name={typeItem.name}
              checked={typeItem.check}
              onChange={e => {
                onCheckBoxChange(e.target);
              }}
            />
            <label htmlFor={`${component}-type-check`}></label>
            &nbsp;{typeItem.name}
          </div>
        ))}
      </div>
      <div
        className={`${component}-button ${allowSubmit ? '' : `${component}-button-disabled`}`}
        onClick={e => {
          e.preventDefault();
          if (allowSubmit) handleSubmit();
        }}
      >
        Set alert
      </div>
    </div>
  );
}

AlertModalBody.propTypes = {
  setResponse: PropTypes.func,
  alertTypeList: PropTypes.array,
  alertType: PropTypes.string,
  stationList: PropTypes.array,
  notifyIfPersist: PropTypes.bool,
  editMode: PropTypes.bool,
};
