// Libraries
import React, { useState, useContext, useEffect } from 'react';
import { isEmpty } from 'lodash';

// Styles
import './body.scss';
import '../grid.scss';

// Context
import { AppContext } from '../../contexts/AppContext';

// Components
import BodyContainer from './BodyContainer';
import AllocationBody from '../../components/allocation/Allocation';
import InfoIconAndModal from '../../components/informationButton/InfoIconAndModal';

// Helpers
import {
  getAllocDataWaterSource,
  getDistributionDataWaterSource,
  getCommentaryByWSId,
  getLicencedWaterBalanceByWaterSource,
  getAllocForecastByWaterSource,
} from '../../helpers/ApiHelper.jsx';
import { sortAllocationTable } from '../../helpers/ArrayUtils';
import {
  groupAllocationsByCategory,
  removeCategoryElement,
  getCurrentYearElementFromAllocations,
} from '../../components/allocation/ArrayUtilsAllocation';
import { findLastWord } from '../../helpers/Utils';
import { currentFinancialYear } from '../../helpers/TimeUtils';

// Assets
import config from '../../abstract/IntroText.json';
import allocationModalText from '../../components/modal/modalText/AllocationTitleModalText.json';

const container = 'allocation-container';
const FROM_YEAR = currentFinancialYear();

export default function Allocation() {
  const { waterSource } = useContext(AppContext);
  const [allocationData, setAllocationData] = useState();
  const [distributionData, setDistributionData] = useState();
  const [forecastData, setForecastData] = useState([]);
  const [licencedWaterBalance, setLicencedWaterBalance] = useState();
  const [commentary, setCommentary] = useState(null);
  const { modal } = allocationModalText;

  //Parsing and decorating the allocation information for the allocation table
  const dataParser = data => {
    const financialYearData = getCurrentYearElementFromAllocations(data);
    const noNumberSharesAllocData = removeCategoryElement(financialYearData, 'number_shares', 0);
    const allocInfoTableGroupedByCategory = groupAllocationsByCategory(noNumberSharesAllocData);
    const sortedData = sortAllocationTable(allocInfoTableGroupedByCategory);
    return sortedData;
  };

  useEffect(() => {
    setForecastData([]);
    setCommentary(null);
    getDistributionDataWaterSource(waterSource.water_source_id, setDistributionData, FROM_YEAR);
    getAllocDataWaterSource(
      waterSource.water_source_id,
      function (result) {
        const parsedData = dataParser(result);
        setAllocationData(parsedData);
      },
      FROM_YEAR,
    );
    getLicencedWaterBalanceByWaterSource(waterSource.water_source_id, setLicencedWaterBalance);
    getAllocForecastByWaterSource(waterSource.water_source_id, setForecastData);

    (async () => {
      try {
        const commentary = await getCommentaryByWSId(waterSource.water_source_id);
        const latestCommentary = commentary.resources
          .filter(r => r.type === 'Allocation')
          .reverse()[0];
        setCommentary(latestCommentary.body);
      } catch (error) {
        setCommentary(null);
      }
    })();
  }, [waterSource]);

  const isTotallyNullData = () => {
    return (
      Array.isArray(allocationData) &&
      isEmpty(allocationData) &&
      Array.isArray(distributionData) &&
      isEmpty(distributionData) &&
      Array.isArray(forecastData) &&
      isEmpty(forecastData)
    );
  };

  return (
    <BodyContainer
      pageTitle={
        <div className={`${container}-pageTop`}>
          <span className={`${container}-pageTop-text`}>
            {`${config.allocation.title} ${findLastWord(waterSource.water_source_name).notLast}`}
            &nbsp;
            <InfoIconAndModal
              modalId="allocationTitleModal"
              modalContent={modal}
              lastWord={
                <span className={'page-body-title'}>
                  {findLastWord(waterSource.water_source_name).last}
                  &nbsp;
                </span>
              }
            />
          </span>
        </div>
      }
      pageIntro={commentary}
    >
      <AllocationBody
        allocationData={allocationData}
        distributionData={distributionData}
        licencedWaterBalance={licencedWaterBalance}
        forecastData={forecastData}
        isTotallyNullData={isTotallyNullData()}
      />
    </BodyContainer>
  );
}
