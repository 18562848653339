/* Library */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

/* Style */
import './status.scss';

/* Context */
import { MobileContext } from '../../contexts/MobileContext';

/* Component */
import StatusItem from '../../components/status/StatusItem';
import ExternalLinkModal from '../../components/modal/ExternalLinkModal';
import ExpandBox from '../generalComponents/expandBox/ExpandBox';
import InfoButton from '../informationButton/InformationButton';
import Modal from '../modal/Modal';

/* Constant */
import statusText from './statusText.json';
const { statusType, statusInfo } = statusText;
const container = 'status';

const Status = ({ status, data }) => {
  const { isMobile } = useContext(MobileContext);

  const statusHeader = () => {
    return (
      <div>
        {statusType[status]}&nbsp;
        {statusInfo[status] && <InfoButton targetDataId="#statusModal" />}
      </div>
    );
  };

  const getDroughtFloodData = () => {
    const rangeKey = Object.keys(statusText.droughtFloodRiskIndex).find(index => {
      const minMaxRange = index.split('~');
      return data.total_score >= Number(minMaxRange[0]) &&
        data.total_score <= Number(minMaxRange[1])
        ? index
        : false;
    });
    const statusItem = statusText.droughtFloodRiskIndex[rangeKey];
    return statusItem;
  };

  const modalBody = () => {
    return (
      <div>
        {statusInfo[status].text}
        <ul>
          {statusInfo[status].lists.map((item, index) => {
            return <li key={index}>{item}</li>;
          })}
        </ul>
      </div>
    );
  };

  return (
    <div className={`${container}`}>
      {statusInfo[status] && (
        <Modal id={'statusModal'} title={statusInfo[status].title} body={modalBody()} />
      )}
      <ExpandBox
        customHeaderContent={statusHeader()}
        customHeaderContainerStyle={`${container}-title`}
        mobileOnly
        customArrowColor={`${container}-arrow`}
      >
        {status === 'droughtFloodRisk' && <StatusItem data={getDroughtFloodData()} type={status} />}
        {data.length === 1 &&
        data[0].status &&
        (data[0].status.id !== 'd0' || data[0].status.id !== 'q0') ? (
          data.map((item, index) => (
            <StatusItem key={index} data={item.status} river={item.river} type={status} />
          ))
        ) : (
          <StatusItem data={{ description: 'None' }} type={status} />
        )}
        <div className={`${container}-link`}>
          {(status !== 'water' || isMobile) && status !== 'droughtFloodRisk' && (
            <a
              className={`${container}-link-text`}
              data-toggle={isMobile ? 'modal' : ''}
              data-target={isMobile ? '#externalLinkStatus' : ''}
              href="https://www.industry.nsw.gov.au/__data/assets/pdf_file/0008/187703/Extreme-Events-policy.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read the Extreme Events Policy
            </a>
          )}
        </div>
        {isMobile && status === 'drought' && (
          <ExternalLinkModal
            link="https://www.industry.nsw.gov.au/__data/assets/pdf_file/0008/187703/Extreme-Events-policy.pdf"
            modalId="externalLinkStatus"
          />
        )}
      </ExpandBox>
    </div>
  );
};

export default Status;

Status.propTypes = {
  status: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};
