import React, { useEffect } from 'react';
import Error from '../../components/generalComponents/error/Error';

export default function ErrorBody() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="body-container">
      <Error />
    </div>
  );
}
