// Library
import React, { useState, useEffect } from 'react';
import { isNil, isNumber } from 'lodash';
import { LineChart, Line, Tooltip, YAxis, CartesianGrid, XAxis, ReferenceLine } from 'recharts';
import { formatNumberDecimals } from '../../helpers/Utils';
import PropTypes from 'prop-types';
import './waterSiteInfoLineChart.scss';

// Constant
import DataQualityConstant, { STATUS } from '../../constants/DataQualityConstant';
import DataQualityText from '../generalComponents/dataQuality/DataQualityText';
import config from '../../configs/featureToggleConfig.json';
const toShowQC = config['quality_code'].active;
const component = 'water-site-info-line-chart';

export default function WaterSiteInfoLineChart({
  data,
  chartProps,
  onLineClicked,
  margin,
  unit,
  yAxisWidth = 10,
  tickOverflow = false,
  division = 1,
  yAxisDomain = ['auto', 'auto'],
  toRound = true,
  reversed = false,
  decimals,
}) {
  const [refLinePos, setRefLinePos] = useState(null);

  useEffect(() => {
    setRefLinePos(null);
  }, [data]);
  const CustomizedDot = props => {
    const { cx, cy, payload, isActive } = props;
    const r = isActive ? '8' : '4';
    const activeResource = Object.entries(chartProps)[0][1];
    if (toShowQC && payload[activeResource.dataKey]?.qualityCode) {
      const { qualityCode } = payload[activeResource.dataKey];
      const qualityObj = DataQualityConstant[qualityCode] || {
        ...STATUS.NORMAL,
      };

      if (qualityObj?.statusName === STATUS.ANOMALY.statusName) {
        return <circle cx={cx} cy={cy} r={r} stroke="#f21d1d" strokeWidth="2" fill="white" />;
      }
    }
    return cx && cy && payload[activeResource.dataKey]?.value ? (
      <circle cx={cx} cy={cy} r={r} stroke="#707070" strokeWidth="2" fill="white" />
    ) : (
      <></>
    );
  };

  return (
    <LineChart
      width={window.innerWidth - 30}
      height={200}
      data={data}
      margin={margin}
      onClick={data => {
        if (!isNil(data) && isNumber(data.activeLabel)) {
          setRefLinePos(data.activeLabel);
        }
        onLineClicked(data);
      }}
    >
      <CartesianGrid strokeDasharray="3 3" vertical={false} />
      <YAxis
        width={yAxisWidth}
        tickLine={false}
        orientation="right"
        axisLine={{ stroke: 'none' }}
        tick={{ fontSize: '11px' }}
        tickFormatter={v => {
          const formatted = formatNumberDecimals(v / division, false, false, decimals);
          const tickText =
            formatted.length > 6 && tickOverflow ? `${formatted.slice(0, 4)}..` : formatted;
          return tickText;
        }}
        domain={yAxisDomain}
        reversed={reversed}
      />
      <XAxis hide={true} padding={{ left: 0, right: 15 }} />
      <ReferenceLine x={refLinePos} stroke="black" strokeDasharray="3 3" />
      {Object.entries(chartProps).map((item, index) => (
        <>
          <Line
            key={index}
            dataKey={`${item[1].dataKey}.value`}
            stroke={item[1].color}
            dot={<CustomizedDot />}
            activeDot={<CustomizedDot isActive />}
          />
          {item[1].forecast && (
            <Line
              key={`forecast-${index}`}
              dataKey={item[1].forecastDataKey}
              stroke={item[1].color}
              dot={{
                stroke: item[1].color,
                strokeWidth: 1,
                r: 3,
                strokeDasharray: '',
              }}
              strokeDasharray="5 5"
              activeDot={{
                r: 8,
              }}
            />
          )}
        </>
      ))}
      <Tooltip
        cursor={false}
        content={({ payload }) => {
          let data = payload[0];
          let value = !isNil(data) && isNumber(data.value) ? data.value : '-';
          const qualityCode = data?.payload[data.dataKey.split('.')[0]]?.qualityCode;

          return (
            <div className={`${component}-tooltip`}>
              <DataQualityText qualityCode={qualityCode}>
                <span className={`${component}-tooltip-value`}>
                  {toRound
                    ? formatNumberDecimals(value / division, false, false, decimals)
                    : formatNumberDecimals(value, false, true, decimals)}
                </span>
                {unit}
              </DataQualityText>
            </div>
          );
        }}
      />
    </LineChart>
  );
}

WaterSiteInfoLineChart.propTypes = {
  data: PropTypes.array,
  chartProps: PropTypes.object,
  onLineClicked: PropTypes.func,
  margin: PropTypes.object,
  unit: PropTypes.string,
  yAxisWidth: PropTypes.number,
  tickOverflow: PropTypes.bool,
  division: PropTypes.number,
  yAxisDomain: PropTypes.array,
  reversed: PropTypes.bool,
};
