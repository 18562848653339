// Libraries
import React, { useEffect, useState } from 'react';
// import moment from "moment";
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

// Components
import DataFilters from '../DataFilters';
import DownloadCheckBox from '../download/old/DownloadCheckBox';
import WaterSourcesSelections from './WaterSourcesSelections';

//styles
import './UpdateFilter.scss';

// Helpers
import { getPriorUpdates } from '../../../helpers/UpdatesApiHelper.jsx';
import { getWaterYearList } from '../../../helpers/TimeUtils';
import Loader from '../../loader/Loader';

const typeSelections = [
  { id: '0', name: 'All' },
  { id: '1', name: 'Temporary Restrictions' },
  { id: '2', name: 'Water Availability' },
  { id: '3', name: 'Operations' },
  { id: '4', name: 'Extreme Events' },
  { id: '5', name: 'Environmental' },
  { id: '6', name: 'Supplementary Events' },
];

const UpdateFilters = ({ waterSources, setWaterSources, setUpdates, setHasCallApi }) => {
  const [openType, setOpenType] = useState(false);
  const [openWaterSource, setOpenWaterSource] = useState(false);
  const [openWaterYear, setOpenWaterYear] = useState(false);
  const waterYearSelections = getWaterYearList(2018);
  const waterYearSelectionsAndAll = [{ id: '0', name: 'All' }, ...waterYearSelections];

  //first option as default
  const [waterYear, setWaterYear] = useState([]);
  const [types, setTypes] = useState([]);
  const [viewDisabled, setViewDisabled] = useState(true);
  const [warnMsg, setWarnMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setViewDisabled(isEmpty(waterYear) || isEmpty(waterSources) || isEmpty(types));
  }, [waterYear, types, waterSources]);

  /**
   * Handles selection changes for different categories by updating the state.
   *
   * @param {Array} el - The current selected elements.
   * @param {Array} prevSelectedItems - The previously selected items.
   * @param {Array} allSelections - All available selection options.
   * @param {Function} setSelection - The function to update the state with the new selections.
   * @param {Number} fullLength - The length that triggers selection of all items.
   */
  const onSelectionChange = (el, prevSelectedItems, allSelections, setSelection) => {
    const prevSelected = prevSelectedItems.map(item => item.id);
    let selected;
    if (el.includes('0')) {
      selected = allSelections;
      if (prevSelected.includes('0')) {
        selected = allSelections.filter(item => item.id !== '0' && el.includes(item.id));
      }
    } else {
      selected = allSelections.filter(item => el.includes(item.id));

      if (prevSelected.includes('0')) {
        selected = [];
      } else if (el.length === allSelections.length) {
        selected = allSelections;
      }
    }
    setSelection(selected);
  };

  const onTypeChange = element => {
    onSelectionChange(element, types, typeSelections, setTypes);
  };

  const onWaterYearChange = element => {
    onSelectionChange(element, waterYear, waterYearSelectionsAndAll, setWaterYear);
  };

  const onMultiSourcesChange = sources => {
    setWaterSources(sources);
  };

  const FILTER_OPTIONS = [
    {
      name: 'Type',
      content: (
        <DownloadCheckBox
          options={typeSelections}
          onSelect={onTypeChange}
          groupName="update_type_group"
          selected={types}
          testid="archived-type"
          type="type"
        />
      ),
      open: openType,
      setOpen: setOpenType,
    },
    {
      name: 'Water Sources',
      content: (
        <WaterSourcesSelections
          hasType={!isEmpty(types) ? true : false}
          hasSource={!isEmpty(waterSources) ? true : false}
          setSelectedSources={onMultiSourcesChange}
          selected={waterSources}
          limit={20}
          type="waterSource"
        />
      ),
      open: openWaterSource,
      setOpen: setOpenWaterSource,
    },
    {
      name: 'Water Years',
      content: (
        <DownloadCheckBox
          options={waterYearSelectionsAndAll}
          onSelect={onWaterYearChange}
          groupName="interval_group"
          selected={waterYear}
          testid="archived-waterYear"
        />
      ),
      open: openWaterYear,
      setOpen: setOpenWaterYear,
    },
  ];

  const submitRequest = async () => {
    setWarnMsg('');
    const wsIds = !isEmpty(waterSources) ? waterSources.map(item => item.id).join(',') : null;
    const typeNames = types.find(type => type.id === '0')
      ? null
      : types.map(type => type.name).join(',');
    const years = `${waterYear.map(element => element.year).join(',')}`;
    setIsLoading(true);
    const result = await getPriorUpdates(wsIds, typeNames, null, null, years);
    setIsLoading(false);
    if (isEmpty(result)) {
      setWarnMsg('There is no update for the selected parameters');
    }
    setUpdates(result);
    setHasCallApi(true);
  };

  return (
    <>
      <DataFilters
        items={FILTER_OPTIONS}
        onBtnClick={() => {
          if (waterYear && !isEmpty(waterSources) && !isEmpty(types)) {
            submitRequest();
            setOpenType(false);
            setOpenWaterSource(false);
            setOpenWaterYear(false);
          }
        }}
        disabled={viewDisabled}
        warningMsg={''}
        btnLabel={'VIEW'}
        pageSource={'archived'}
      />
      {!isEmpty(warnMsg) && !isLoading && (
        <p className="filter-warning-msg">
          <span>{warnMsg}</span>
        </p>
      )}
      {isLoading && isEmpty(warnMsg) && <Loader />}
    </>
  );
};

export default UpdateFilters;

UpdateFilters.propTypes = {
  startDate: PropTypes.string,
  setStartDate: PropTypes.func,
  endDate: PropTypes.string,
  setEndDate: PropTypes.func,
  waterSources: PropTypes.array,
  setWaterSources: PropTypes.func,
  waterPlans: PropTypes.array,
  setWaterPlans: PropTypes.func,
  setUpdates: PropTypes.func,
  setHasCallApi: PropTypes.func,
};
