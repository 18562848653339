export const calAlerts = alerts => {
  let countMobileAlerts = 0;
  let countEmailAlerts = 0;
  let alertMobile;
  let alertEmail;
  let email;
  alerts.forEach(alert => {
    email = alert.user_email;
    alert.subscriptions.forEach(sub => {
      if (sub.delivery_type === 'Mobile' && sub.active) countMobileAlerts++;
      if (sub.delivery_type === 'Email' && sub.active) countEmailAlerts++;
    });
  });
  if (countMobileAlerts === 0 || countMobileAlerts === 1) {
    alertMobile = 'alert';
  } else {
    alertMobile = 'alerts';
  }

  if (countEmailAlerts === 0 || countEmailAlerts === 1) {
    alertEmail = 'alert';
  } else {
    alertEmail = 'alerts';
  }
  return {
    countMobileAlerts,
    countEmailAlerts,
    alertEmail,
    alertMobile,
    email,
  };
};
