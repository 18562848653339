// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './MapPopup.scss';
import './generalPopup.scss';

// Helpers
import { formatNumberDecimals } from '../../helpers/Utils';

// Component
import ViewDetailsLink from './viewDetailsLink/ViewDetailsLink';

// Constants
import constants from '../../constants/Constants';
import APIConstant from '../../constants/APIResponseConstant';
const { TAB_OPTIONS } = constants;
const component = 'map-popup';

export default function GeneralPopup({ data, content, config }) {
  const isMenindee = Number(data.id) === constants.MENINDEE_LAKES_TOTAL_ID;
  const showMenindeeTotal = constants.TOTAL_STORAGE_MENINDEE_LAKES_FLAG;

  const formatNumber = (item, keyObj) => {
    const number = item[keyObj.key] * keyObj.conversion;
    return formatNumberDecimals(number, keyObj.rounded, keyObj.key === APIConstant.DAM.volumePerc);
  };

  const rowItem = (title, item, keyObj) => {
    const isNumber = item[keyObj.key] && item[keyObj.key] !== '-';
    const number = formatNumber(item, keyObj);
    return (
      <>
        <div className="col-7">{title}</div>
        <div className={`col-3 ${component}-value`}>
          {`${isNumber ? `${keyObj.monetary ? '$ ' : ''}${number}` : '-'}`}
        </div>
        <div className={`col-1 ${component}-unit`}>{`${isNumber ? `${keyObj.unit}` : ''}`}</div>
      </>
    );
  };

  return (
    <div className={`${component}-container`}>
      <div className={`${component}-body-title`}>{data.name}</div>
      <div className="row station-data-popup-body">
        {data.values
          ? data.values.map((item, index) => {
              return (
                <div key={index} className={`row general-${component}-grouped`}>
                  <div className={`col-12 general-${component}-grouped-sub-title`}>
                    {item.trading_zone}
                  </div>
                  {content.map(keyObj => {
                    return rowItem(keyObj.title, item, keyObj);
                  })}
                </div>
              );
            })
          : content.map(keyObj => {
              const isVolume = keyObj.key === 'volume';
              const title =
                showMenindeeTotal && isMenindee && isVolume
                  ? 'Total volume'
                  : isVolume
                  ? 'Accessible volume'
                  : keyObj.title;
              return rowItem(title, data, keyObj);
            })}
      </div>
      <div className={`${component}-view-details general-${component}-view-details`}>
        <ViewDetailsLink
          elementId={config.scrollTarget ? config.scrollTarget : ''}
          activeTab={config.tabTarget ? config.tabTarget.text : TAB_OPTIONS.updates.text}
          label={config.linkLabel}
          pathname={`${config.tabTarget.link}`}
          targetWaterSource={
            data.water_source
              ? data.water_source
              : {
                  water_source_id: data.id,
                  water_source_name: data.name,
                }
          }
        />
      </div>
    </div>
  );
}

GeneralPopup.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.any,
    name: PropTypes.string,
    date: PropTypes.string,
    water_source: PropTypes.object,
    values: PropTypes.array,
  }),
  content: PropTypes.array,
  config: PropTypes.shape({
    scrollTarget: PropTypes.string,
    tabTarget: PropTypes.object,
    linkLabel: PropTypes.string,
  }),
};
