// Library
import React, { useContext, useState, useEffect } from 'react';
import { isEmpty } from 'lodash';

// Styles
import './stationDownloadFilters.scss';

// Context
import { DownloadContext } from '../../../../../contexts/DownloadContext';

// Component
import StationSelect from './stationSelect/StationSelect';
import StationDownloadTypeSelect from './downloadType/StationDownloadTypeSelect';
import GraphAndDownloadSelect from './graphAndDownload/GraphAndDownloadSelect';
import GraphAndDownloadDropdown from './graphAndDownload/GraphAndDownloadDropdown';
import StationDownloadFiltersSummary from './StationDownloadFiltersSummary';

// Constants
import constant from '../DataDownloadConstants';
import config from '../../../../../configs/featureToggleConfig.json';
const component = 'station-download-filters';
const active = config.ratings_curve_table.active;
const RATING_TABLE_FORMAT = [{ id: 'Graph', name: 'Graph' }];
if (active) {
  RATING_TABLE_FORMAT.push({ id: 'Tabular1', name: 'Flat CSV Rating Table' });
  RATING_TABLE_FORMAT.push({ id: 'Tabular2', name: 'Formatted Rating Table' });
}

const StationDownloadFilters = () => {
  const [hideFilters, setHideFilters] = useState(false);
  const [isGraphInit, setIsGraphInit] = useState(false);
  const {
    selectedStations,
    stationInfoType,
    genGraph,
    downloadType,
    hydrometric,
    interval,
    endDate,
    startDate,
    ratingTableFormat,
    setRatingTableFormat,
  } = useContext(DownloadContext);

  useEffect(() => {
    if (genGraph) {
      setHideFilters(true);
      setIsGraphInit(true);
    }
  }, [genGraph, setHideFilters]);

  useEffect(() => {
    setIsGraphInit(false);
    setHideFilters(false);
  }, [downloadType, selectedStations, hydrometric, interval, endDate, startDate]);

  return (
    <div className={component}>
      <StationDownloadFiltersSummary
        onClick={() => {
          setHideFilters(prev => !prev);
        }}
        isGraphInit={isGraphInit}
        hideFilters={hideFilters}
      >
        <StationSelect />

        {!isEmpty(selectedStations) && (
          <>
            <div className={`${component}-title`}>Select to define what you need</div>
            <StationDownloadTypeSelect />
          </>
        )}
        {stationInfoType === constant.GRAPH_DOWNLOAD && !isEmpty(selectedStations) && (
          <>
            <div className={`${component}-title`}>I want to view the</div>
            <GraphAndDownloadSelect />
          </>
        )}
        {stationInfoType === constant.RATING && !isEmpty(selectedStations) && (
          <>
            <div className={`${component}-title`}>
              I want to view the data in
              <GraphAndDownloadDropdown
                options={RATING_TABLE_FORMAT}
                selectedOptions={
                  isEmpty(ratingTableFormat)
                    ? { id: 'empty', name: 'Please select' }
                    : ratingTableFormat
                }
                onChange={setRatingTableFormat}
                listType="radio"
              />
              format.
            </div>
          </>
        )}
      </StationDownloadFiltersSummary>
    </div>
  );
};

export default StationDownloadFilters;

StationDownloadFilters.propTypes = {};
