/* eslint-disable react/prop-types */
// Library
import React, { useState, useContext, useLayoutEffect, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend, LabelList } from 'recharts';
import { isEmpty } from 'lodash';

// Styles
import './waterBalanceGraph.scss';

// Components
import ChartContainer from '../chartProperties/ChartContainer';
import ChartResponsiveContainer from '../chartProperties/ChartResponsiveContainer';
import ChartLegend from '../chartProperties/AllocationChartLegend';
import ChartTooltip from '../chartProperties/ChartTooltip';
import SelectionBtnsGroup from '../storage/childProperties/SelectionBtnsGroup';
import HistoricalYearSelector from '../yearMultiSelector/HistoricalYearSelector';
import InfoButton from '../informationButton/InformationButton';
import AppModalDealing from '../modal/Modal';
import DictionaryModalContent from '../modal/DictionaryModalContent';
import BalanceGraphModalData from '../modal/BalanceGraphModalData';

// Assets
import text from './research.json';

// Helpers
import { sumValuesFromMap } from '../../helpers/ArrayUtils';
import { createPayloadWaterBalance } from '../allocation/ArrayUtilsAllocation';
import { genListOfHistoricalYears, currentFinancialYear } from '../../helpers/TimeUtils';
import { formatNumberDecimals } from '../../helpers/Utils';

// Context
import { AppContext } from '../../contexts/AppContext';
import { MobileContext } from '../../contexts/MobileContext';

// Constant
import constants from '../../constants/Constants';
const container = 'water-balance';

export default function WaterBalanceGraph({ balanceData }) {
  const { waterSource } = useContext(AppContext);
  const { isMobile, isLarge } = useContext(MobileContext);
  const [chartData, setChartData] = useState(balanceData);
  const [yearList, setYearList] = useState(genListOfHistoricalYears(balanceData));
  const [activeYear, setActiveYear] = useState(
    !isEmpty(yearList) ? yearList[yearList.length - 1].year : currentFinancialYear() - 1,
  );
  let showXAxis = chartData.length - 1;

  const barGroupDomainWaterAvailableLimit = chartData.map(i => Math.round(sumValuesFromMap(i)));

  const getVal = (obj, key) => {
    const barKey = 'volume';

    if (
      obj.resources &&
      obj.resources[key] &&
      obj.resources[key][barKey] &&
      (key === 'storage' || key === 'inflow')
    ) {
      return obj.resources[key][barKey];
    } else if (
      obj.resources &&
      obj.resources[key] &&
      obj.resources[key][barKey] &&
      !(key === 'storage' || key === 'inflow')
    ) {
      return obj.resources[key][barKey] * -1;
    }
    return null;
  };
  useEffect(() => {
    const updatedYearList = genListOfHistoricalYears(balanceData);
    setYearList(updatedYearList);
    const yearToSelect = !isEmpty(updatedYearList)
      ? updatedYearList[updatedYearList.length - 1].year
      : currentFinancialYear() - 1;
    setActiveYear(yearToSelect);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balanceData]);

  useLayoutEffect(() => {
    const financialYearData = balanceData.find(
      yearItem => Number(yearItem.water_year) === Number(activeYear),
    );

    if (!isEmpty(financialYearData)) {
      const payloadWaterBalance = createPayloadWaterBalance(
        financialYearData.resources,
        activeYear,
      );
      setChartData(payloadWaterBalance);
    } else {
      setChartData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeYear, balanceData]);

  const onYearChange = value => {
    if (value) {
      setActiveYear(value);
    }
  };

  const renderCustomizedLegend = payload => {
    const header = (
      <>
        <div className={`${container}-legend-item`}>
          <span>LEGEND</span>
          <InfoButton
            targetDataId="#balanceModalCenter"
            iconColor="info-button-dist-graph-legend"
          />
        </div>
        {
          <AppModalDealing
            id="balanceModalCenter"
            title="Legend"
            body={<DictionaryModalContent data={BalanceGraphModalData} />}
          />
        }
      </>
    );

    return <ChartLegend payload={payload} header={header} isMobile={isMobile} />;
  };

  const renderCustomizedLabel = fill => {
    // eslint-disable-next-line react/display-name
    return props => {
      const { x, y, width, value } = props;

      if (value) {
        return (
          <g>
            <rect width={width} height={30} x={x} y={y - 30} fill="transparent" />
            <text
              x={x + width / 2 + 1}
              y={y - 18}
              fill={fill}
              fontSize={!isMobile ? '17px' : '12px'}
              textAnchor="middle"
              dominantBaseline="middle"
            >
              {formatNumberDecimals((value / waterSource.full_volume) * 100)}%
            </text>
          </g>
        );
      } else {
        return null;
      }
    };
  };

  const renderCustomTooltip = payload => {
    return (
      <ChartTooltip
        payload={payload}
        graphType={constants.WATER_BALANCE_GRAPH}
        isPayloadArray={false}
        titleKey="type"
        setAbsoluteValues={true}
      />
    );
  };

  return (
    <div className={container}>
      <ChartContainer
        pageTitle={text.waterBalanceGraph.title}
        graphIntro={text.waterBalanceGraph.intro}
      >
        <SelectionBtnsGroup
          left={
            <HistoricalYearSelector
              listOfYears={yearList}
              onBtnChange={onYearChange}
              isLarge={isLarge}
              yearSelected={activeYear}
              renderCurrentYear={false}
            />
          }
        />
        <ChartResponsiveContainer
          customHeight={{ desktop: 450, mobile: 350 }}
          isEmptyChart={isEmpty(chartData)}
        >
          <BarChart
            data={chartData}
            layout={'horizontal'}
            margin={{
              bottom: showXAxis ? 15 : 0,
              top: 10,
              left: !isMobile ? 25 : 0,
            }}
            barSize={isMobile ? 20 : 140}
          >
            {!isMobile && <CartesianGrid strokeDasharray="3" horizontal={false} />}
            <YAxis
              interval={isMobile ? 'preserveStartEnd' : 'preserveEnd'}
              label={{
                value: 'Volume (GL)',
                angle: -90,
                dx: isMobile ? -25 : -35,
              }}
              type="number"
              domain={[0, Math.ceil(Math.max(...barGroupDomainWaterAvailableLimit))]}
              tickFormatter={v => formatNumberDecimals(v / 1000)}
              scale="linear"
            />

            <XAxis
              type="category"
              angle={-60}
              dy={70}
              height={150}
              dx={-30}
              dataKey={'type'}
              allowDecimals={false}
              interval={0}
              tick={{
                fontSize: isMobile ? 12 : 14,
                width: isMobile ? 200 : 150,
              }}
            />
            <Tooltip
              content={e => renderCustomTooltip(e.payload)}
              cursor={{ fill: 'transparent' }}
            />
            <Bar dataKey="cumulative" stackId="a" fill="transparent" />
            <Bar
              dataKey={val => getVal(val, 'storage')}
              name={'Storage'}
              stackId="a"
              fill="#707070"
            >
              <LabelList
                dataKey={val => getVal(val, 'storage')}
                content={renderCustomizedLabel('#3e3e3e')}
              />
            </Bar>
            <Bar
              dataKey={val => getVal(val, 'consumptive')}
              name={'Consumptive'}
              stackId="a"
              fill="#004ba3"
            />
            <Bar
              dataKey={val => getVal(val, 'operational')}
              name={'Operational'}
              stackId="a"
              fill="#ffc107"
            />
            <Bar
              dataKey={val => getVal(val, 'environmental')}
              name={'Environmental'}
              stackId="a"
              fill="#bed12a"
            />
            <Bar
              dataKey={val => getVal(val, 'inflow')}
              name={'Inflow'}
              stackId="a"
              fill="#d9d9d9"
            />
            <Legend
              wrapperStyle={{
                position: 'relative',
                bottom: isMobile ? '70px' : window.innerWidth < 1300 ? '50px' : '30px',
              }}
              content={e => renderCustomizedLegend(e.payload)}
            />
          </BarChart>
        </ChartResponsiveContainer>
      </ChartContainer>
    </div>
  );
}

WaterBalanceGraph.propTypes = {
  balanceData: PropTypes.arrayOf(
    PropTypes.shape({
      water_year: PropTypes.string,
      resources: PropTypes.array,
    }),
  ),
};
