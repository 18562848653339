// Library
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isObject, isSet, isNaN } from 'lodash';

// Styles
import './riverSchematic.scss';

// Component
import RiverLocation from './RiverLocation.jsx';
import RiverSchematicLegend from './RiverSchematicLegend';

// Context
import { AppContext } from '../../contexts/AppContext';

// Assest
import schematics from './schematics/index.jsx';

//Helpers
import { getPathname, buildUrl } from '../../helpers/UrlGenerator';

// Constant
import config from '../../configs/featureToggleConfig.json';

const container = 'river-schematic';
const damDataSource = config['water-data-api'].dams.active;

const RiverSchematic = ({ gauges, dams, weirs, detailsLoading, stateView = false }) => {
  // Context
  const { waterSource } = useContext(AppContext);
  const { water_source_id } = waterSource;
  // State
  const [locationData, setlocationData] = useState([]);
  // Constant
  const curSchematic = schematics[water_source_id ? water_source_id : 'state'];
  const {
    title,
    riverNodes,
    startingNode,
    viewHeight,
    viewWidth,
    locations,
    legendPosition,
    areas,
  } = curSchematic;
  const gaugeConfig = {
    dataInsertion: true,
    valueKey: 'flow_rate',
    nameKey: 'station_name',
    stationKey: 'station_id',
    targetDataSet: gauges.filter(item =>
      !isEmpty(item.resources)
        ? !isNaN(item.resources[0]['flow_rate'])
        : item.hydrometric_types
        ? item.hydrometric_types.includes('flow_rate')
        : item.hydrometric_types,
    ),
    unit: 'ML/d',
  };
  const damConfig = {
    dataInsertion: true,
    valueKey: 'volume',
    percentageKey: 'volume_perc',
    stationKey: `${!stateView ? 'station_id' : 'id'}`,
    nameKey: `${!stateView ? 'station_name' : 'name'}`,
    targetDataSet: dams,
    unit: 'GL',
    modifier: 0.001,
  };
  const weirConfig = {
    dataInsertion: true,
    valueKey: 'volume',
    nameKey: 'station_name',
    stationKey: 'station_id',
    targetDataSet: weirs,
    unit: 'GL',
    modifier: 0.001,
  };
  const riverConfig = {
    dataInsertion: false,
  };
  const townConfig = {
    dataInsertion: false,
  };
  const locationTypes = {
    gauge: gaugeConfig,
    river: riverConfig,
    town: townConfig,
    dam: damConfig,
    weir: weirConfig,
    discharge: gaugeConfig,
  };
  const linksObj = {
    gauge: 'river-data',
    bore: 'river-data',
    dam: 'storage',
    weir: 'storage',
    discharge: 'river-data',
    default: 'updates',
  };

  // Life Cycle
  useEffect(() => {
    if (!isEmpty(gauges)) {
      const insertedStation = locations.map(station => {
        const { locationID, locationType } = station;
        const config = locationTypes[locationType];
        if (config.dataInsertion) {
          const { valueKey, nameKey, stationKey, targetDataSet, unit, modifier, percentageKey } =
            config;
          const targetStation = targetDataSet.filter(station =>
            stateView
              ? locationID.includes(station[stationKey])
              : locationID === station[stationKey],
          );

          let targetValue, fullPercentage;
          targetValue = targetStation.reduce((acc, item) => {
            return acc + (!stateView ? item[valueKey] : item.resources[0][valueKey]);
          }, 0);

          if (!isEmpty(targetStation) && percentageKey && valueKey !== 'flow_rate') {
            fullPercentage = stateView
              ? targetStation[0]?.resources[0][percentageKey]
              : damDataSource
              ? targetStation[0][percentageKey]?.value
              : targetStation[0][percentageKey];
          }

          const value = isEmpty(targetStation)
            ? { value: '-' }
            : isObject(targetStation[0][valueKey])
            ? {
                ...targetStation[0][valueKey],
                value: modifier
                  ? targetStation[0][valueKey].value * modifier
                  : targetStation[0][valueKey].value,
              }
            : modifier
            ? targetValue * modifier
            : targetValue;

          return {
            ...station,
            locationName: station.locationName || (targetStation && targetStation[nameKey]),
            value: targetStation[0]?.resources
              ? { ...targetStation[0]?.resources[0]?.rawData }
              : value,
            unit: unit,
            fullPercentage: fullPercentage,
            url: generateLinks({
              _source: waterSource,
              _type: locationType,
              _stationId: locationID,
            }),
          };
        } else {
          return {
            ...station,
            url: generateLinks({
              _source: waterSource,
              _type: locationType,
              _stationId: locationID,
            }),
          };
        }
      });
      setlocationData(insertedStation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gauges, dams, weirs, locations]);

  // Helper
  const returnCurveSVGPath = (direction, x1, y1, y2) => {
    let curveX1 = x1;
    let curveY1 = y1;
    let curvePath = '';
    switch (direction) {
      case 'left-up':
        curveX1 = x1 - 28;
        curveY1 = y1 - 0.1;
        x1 -= 25;
        curvePath = `M${curveX1},${curveY1} q29,2 28,25`;
        break;
      case 'left-down':
        curveX1 = x1 - 28;
        curveY1 = y1 - 0.35;
        x1 -= 25;
        curvePath = `M${curveX1},${curveY1} q29,2 28,-25`;
        break;
      case 'bottom-right-corner':
        curveX1 = x1 - 28;
        y1 -= 25;
        curvePath = `M${curveX1},${curveY1} q29,1 28,-25`;
        break;
      case 'right-up':
        curveY1 = y1 + 25;
        x1 += 25;
        curvePath = `M${curveX1},${curveY1} q2,-25 28,-25`;
        break;
      case 'right-down':
        curveY1 = y1 - 25;
        x1 += 25;
        curvePath = `M${curveX1},${curveY1} q2,25 28,25`;
        break;
      case 'right-down-edge':
        x1 += 30;
        y1 += 30;
        curvePath = `M${curveX1},${curveY1} q2,30 33,30`;
        break;
      case 'right-down-corner':
        curveY1 = y1 - 0.35;
        y1 += 20;
        x1 += 25;
        curvePath = `M${curveX1},${curveY1} q25,1 25,25`;
        break;
      case 'right-up-corner':
        x1 += 25;
        y1 -= 25;
        curvePath = `M${curveX1},${curveY1} q2,-25 28,-25`;
        break;
      case 'left-down-corner':
        curveX1 = x1 - 28;
        curveY1 = y1 + 28;
        x1 -= 25;
        y1 += 28;
        curvePath = `M${curveX1},${curveY1} q29,2 28,-25`;
        break;
      case 'bottom-left-corner':
        x1 -= 25;
        y1 -= 25;
        curvePath = `M${curveX1},${curveY1} q-25,2 -25,-25`;
        break;
      case 'top-left-corner':
        x1 -= 30;
        y1 += 30;
        curvePath = `M${curveX1},${curveY1} q-30,-2 -30,30`;
        break;
      case 'right-corner-up':
        x1 += 28;
        y1 -= 25;
        curvePath = `M${curveX1},${curveY1} q29,2 28,-25`;
        break;
      default:
        break;
    }
    return { curvePath, x1, y1, y2 };
  };

  const renderAreas = () => {
    const areasToRender = areas;
    if (areasToRender) {
      return areasToRender.map((area, index) => {
        let { x, y, height, width, color, border, borderColor } = area;

        return (
          <path
            key={index}
            d={`M${x},${y} h${width} a20,20 0 0 1 20,20 v${height} a20,20 0 0 1 -20,20 h-${width} a20,20 0 0 1 -20,-20 v-${height} a20,20 0 0 1 20,-20 z`}
            fill={color}
            strokeWidth={border}
            stroke={borderColor}
          />
        );
      });
    }
  };

  const iterateNode = () => {
    const iterationQueue = [riverNodes[startingNode]];
    const edgesPairs = {};
    const visitedNode = new Set();
    const flowNodesRendered = new Set();
    let linePositions = [];
    while (iterationQueue.length !== 0) {
      let adjacentColor;
      const curNode = iterationQueue.shift();
      if (!visitedNode.has(curNode.riverName)) {
        visitedNode.add(curNode.riverName);
        const adjacents = curNode.adjacent;
        adjacents.forEach(riverName => {
          const adjNode = riverNodes[riverName];
          if (!isSet(edgesPairs[curNode.riverName])) edgesPairs[curNode.riverName] = new Set();
          if (!isSet(edgesPairs[riverName])) edgesPairs[riverName] = new Set();
          const hasEdge =
            edgesPairs[riverName].has(curNode.riverName) ||
            edgesPairs[curNode.riverName].has(riverName);
          const adjacentFlowConnection =
            adjNode.flow && adjNode.flow.adjacent === curNode.riverName;
          if (curNode.color) {
            adjacentColor = curNode.color.find(
              colorItem => colorItem.adjacent === adjNode.riverName,
            );
          }

          if (!adjacentFlowConnection && !hasEdge) {
            linePositions.push({
              x1: curNode.x,
              y1: curNode.y,
              x2: adjNode.x,
              y2: adjNode.y,
              name1: curNode.riverName,
              name2: adjNode.riverName,
              flow:
                typeof curNode.flow !== 'undefined' && curNode.flow.adjacent === adjNode.riverName
                  ? true
                  : false,
              direction:
                typeof curNode.flow !== 'undefined' && curNode.flow.adjacent === adjNode.riverName
                  ? curNode.flow.direction
                  : null,
              color: adjacentColor ? adjacentColor.color : undefined,
              dashedLine: curNode.dashedLine ? curNode.dashedLine : undefined,
              straightLine: curNode.straightLine ? curNode.straightLine : undefined,
            });
            edgesPairs[riverName].add(curNode.riverName);
            edgesPairs[curNode.riverName].add(riverName);
          }
          if (typeof curNode.flow !== 'undefined' && curNode.flow.adjacent === adjNode.riverName)
            flowNodesRendered.add(curNode.riverName);
          if (!visitedNode.has(riverName)) iterationQueue.push(adjNode);
        });
      }
    }

    //Re-order the line positions since we want to have first the flowing rivers
    //and then the main ones overlapped.
    linePositions = linePositions.reduce((acc, element) => {
      if (typeof element.color !== 'undefined') {
        return [element, ...acc];
      }
      return [...acc, element];
    }, []);

    return linePositions.map(position => {
      let { x1, y1, x2, y2, name1, name2, flow, direction, color, dashedLine, straightLine } =
        position;
      let curvePath = '';
      if (flow) {
        ({ curvePath, x1, y1, y2 } = returnCurveSVGPath(direction, x1, y1, y2));
      }
      return (
        <React.Fragment key={`${name1} ${name2}`}>
          <line
            className={`${typeof color === 'undefined' ? 'main' : 'flow'}`}
            key={`${name1} ${name2}`}
            strokeLinecap={dashedLine ? 'square' : 'round'}
            x1={x1}
            y1={y1}
            x2={x2}
            y2={y2}
            stroke={`${typeof color !== 'undefined' ? color : '#63CBE8'}`}
            strokeDasharray={
              dashedLine && color === '#3680A0' ? (straightLine ? '1 10' : '2 10') : ''
            }
            strokeWidth={straightLine && color === '#3680A0' ? '5' : '8'}
          />
          {flow && (
            <path
              d={straightLine ? '' : curvePath}
              stroke={`${typeof color !== 'undefined' ? color : '#63CBE8'}`}
              strokeDasharray={dashedLine ? (straightLine ? '1 10' : '2 10') : ''}
              strokeLinecap="square"
              fill="transparent"
              strokeWidth={straightLine ? '6' : '8'}
            />
          )}
        </React.Fragment>
      );
    });
  };

  const generateLinks = res => {
    const waterSource = res._source;
    const station_id = res._stationId;
    const linkType = res._type;
    const path = `${buildUrl(
      getPathname(`${waterSource.water_source_id} ${waterSource.water_source_name}`),
      `/${linksObj[linkType] || linksObj.default}`,
    )}`;
    return `${window.location.origin}${path}${station_id ? `#${station_id}` : ''}`;
  };

  return (
    <div className={container}>
      <svg
        className={`${container}-svg`}
        viewBox={`0 0 ${viewWidth ? viewWidth : 1000} ${viewHeight}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        {title && (
          <text x={title.x} y={title.y} className={`${container}-title`}>
            {title.name}
          </text>
        )}
        {renderAreas()}
        {iterateNode()}
        {legendPosition && (
          <RiverSchematicLegend
            x={legendPosition.x}
            y={legendPosition.y}
            dataLegendX={legendPosition.dataLegendX}
            dataLegendY={legendPosition.dataLegendY}
          />
        )}
        {locationData.map((location, index) => (
          <RiverLocation
            key={`${location.locationType}-${location.locationName}-${index}`}
            x={location.x}
            y={location.y}
            rotate={location.rotate}
            name={location.locationName}
            value={location.value}
            unit={location.unit}
            locationType={location.locationType}
            style={location.style}
            textX={location.textX}
            textY={location.textY}
            url={location.url}
            background={location.background}
            stateView={stateView}
            detailsLoading={detailsLoading}
            fullPercentage={location.fullPercentage}
          />
        ))}
      </svg>
    </div>
  );
};

export default RiverSchematic;

RiverSchematic.propTypes = {
  gauges: PropTypes.array,
  dams: PropTypes.array,
  weirs: PropTypes.array,
  stateView: PropTypes.bool,
};
