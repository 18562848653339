// Libraries
import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

//Context
import { MapContext } from '../../contexts/MapContext';

// Styles
import './MapPopup.scss';

// Component
import ViewDetailsLink from './viewDetailsLink/ViewDetailsLink';

// Helper
import { formatPopupContentNumber, sortArrayByKeyDesc } from '../../helpers/Utils';

// Constants
import constants from '../../constants/Constants.jsx';
const { riverData } = constants.TAB_OPTIONS;
const valueUnit = ' m below ground';

export default function MonitoringBorePopup({ data, timestamp, openPopup }) {
  //Context
  const { markerSelected } = useContext(MapContext);

  // State
  const [popupData, setPopupData] = useState();
  const [initialised, setInitialised] = useState(false);
  const [updatedDate, setUpdatedDate] = useState(data.date);

  // Life Cycle
  useEffect(() => {
    if (!initialised || (openPopup && markerSelected)) {
      (async () => {
        let lastUpdateDate;
        const formatted = data.resources.map(item => {
          const resources = sortArrayByKeyDesc(item.resources, 'date');
          lastUpdateDate = lastUpdateDate
            ? moment(resources[0].date, 'YYYY-MM-DD').isAfter(lastUpdateDate)
              ? resources[0].date
              : lastUpdateDate
            : resources[0].date;
          return { ...item, resources };
        });
        setUpdatedDate(lastUpdateDate);
        setPopupData(formatted);
        if (!isEmpty(formatted)) {
          setInitialised(true);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openPopup, timestamp]);

  return (
    <div className="map-popup-container">
      <div className="map-popup-body-title">{data.station_name}</div>
      {!isEmpty(popupData) ? (
        <>
          {popupData.map((bore, index) =>
            bore.bore_id ? (
              <div key={index}>
                <div className="row station-data-popup-body">
                  <div className="col-5">
                    <ViewDetailsLink
                      elementId={bore.bore_id}
                      activeTab={riverData.text}
                      pathname={riverData.link}
                      label={bore.bore_id}
                    />
                  </div>
                  <div className="col-7">
                    {!isEmpty(bore.resources) &&
                      formatPopupContentNumber(bore.resources[0].value) + valueUnit}
                  </div>
                </div>
              </div>
            ) : (
              'No data is currently available for this bore.'
            ),
          )}
          <div className="map-popup-body-timestamp">
            {data.date && `Updated: ${moment(updatedDate, 'YYYY-MM-DD').format('MMMM YYYY')} AEST`}
          </div>
        </>
      ) : (
        'No data is currently available for this bore.'
      )}
    </div>
  );
}

MonitoringBorePopup.propTypes = {
  data: PropTypes.shape({
    date: PropTypes.string,
    lat: PropTypes.number,
    long: PropTypes.number,
    resources: PropTypes.array,
    station_id: PropTypes.string,
    station_name: PropTypes.string,
  }),
  timestamp: PropTypes.string,
  openPopup: PropTypes.bool,
};
