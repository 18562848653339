// Libraries
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { isEmpty } from 'lodash';
import moment from 'moment';

// Styles
import './chartCSVDownloadLink.scss';

// Components
import DownloadSVG from '../../image/icons/DownloadSVG';
import ChartIcon from '../chartProperties/ChartIcon';

// Helpers
import { Event } from '../../helpers/GAHelper';

// Context
import { AppContext } from '../../contexts/AppContext';

// Constants
import constants from '../../constants/Constants';
import mapFilterConstant from '../../constants/MapFilterConstants';
const { GAUGE, BORE, WEIR } = mapFilterConstant.MAP_POPUP_ITEMS;

const component = 'chart-download-link';
const dataKey = {
  gauge: [
    { type: 'FLOW_RATE', oriKey: 'value', newKey: 'Flow Rate' },
    { type: 'LEVEL', oriKey: 'value', newKey: 'River Level' },
    { type: 'TEMPERATURE', oriKey: 'value', newKey: 'Temperature' },
    { type: 'DISSOLVED_OXYGEN', oriKey: 'value', newKey: 'Dissolved Oxygen' },
    { type: 'SALINITY', oriKey: 'value', newKey: 'Salinity' },
    { type: 'DISSOLVED_OXYGEN_SATURATION', oriKey: 'value', newKey: 'Dissolved Oxygen Saturation' },
    { type: 'pH', oriKey: 'value', newKey: 'pH' },
    {
      type: 'FLOW_RATE',
      oriKey: 'forecastValue',
      newKey: 'Forecast Flow Rate',
    },
  ],
  weir: [
    { type: 'VOLUME', oriKey: 'value', newKey: 'Volume' },
    {
      type: 'RESERVOIR LEVEL',
      oriKey: 'value',
      newKey: 'Reservoir level',
    },
  ],
  bore: [{ type: 'water level', oriKey: 'value', newKey: 'Water Level' }],
};
export default function ChartCSVDownloadLink({
  data = [],
  filename,
  stationType,
  dataType,
  page = 'river data',
  toFormat = true,
  headers = [],
  additionalData = {},
}) {
  const { waterSource } = useContext(AppContext);

  let csvData =
    !isEmpty(data) && toFormat
      ? data
          .filter(item => !item.filler)
          .map(({ date, ...rest }) => {
            dataKey[stationType] &&
              dataKey[stationType].map(item => {
                if (item.type === dataType) {
                  rest[item.newKey] = rest[item.oriKey];
                  delete rest[item.oriKey];
                }
              });
            if (rest['Forecast Flow Rate'] === undefined) {
              delete rest['Forecast Flow Rate'];
            }

            delete rest.forecastValue;
            delete rest.tributary_inflow;
            delete rest.total_inflow;
            if (rest.qualityCode) rest['Quality code'] = rest.qualityCode;
            delete rest.qualityCode;
            if (!date) {
              return { ...rest };
            } else {
              return {
                Date:
                  stationType === GAUGE.name ||
                  stationType === BORE.name ||
                  stationType === WEIR.name
                    ? moment(date, moment.ISO_8601).format('YYYY-MM-DD HH:mm')
                    : date,
                ...rest,
              };
            }
          })
      : data;
  if (!isEmpty(additionalData)) {
    Object.entries(additionalData).map(([key, value], index) => {
      if (csvData[index]) {
        csvData[index]['Quality code description'] = `${key}: ${value}`;
      } else {
        csvData.push({ 'Quality code description': `${key}: ${value}` });
      }
    });
  }
  const props = { data: csvData };
  if (!isEmpty(headers)) {
    props.headers = headers;
  }

  return (
    <ChartIcon disabled={isEmpty(csvData)}>
      <CSVLink
        {...props}
        filename={`${filename}.csv`}
        onClick={() => {
          Event(
            waterSource.water_source_id,
            `Data download from ${page} page`,
            constants.INTERACTION_TYPE.dataDownload,
          );
          return !isEmpty(csvData);
        }}
        className={isEmpty(csvData) ? `${component}-disabled` : ''}
      >
        <DownloadSVG />
        Download{' '}
      </CSVLink>
    </ChartIcon>
  );
}

ChartCSVDownloadLink.propTypes = {
  data: PropTypes.array,
  filename: PropTypes.string,
  stationType: PropTypes.string,
  dataType: PropTypes.string,
};
