import React from 'react';
import PropTypes from 'prop-types';

const AvailabilitySVG = ({ customClass }) => {
  return (
    <svg
      id="Group_4372"
      data-name="Group 4372"
      xmlns="http://www.w3.org/2000/svg"
      width="18.468"
      height="26.284"
      viewBox="0 0 18.468 26.284"
      className={customClass ? customClass : ' '}
    >
      <g id="np_water_1478810_000000">
        <path
          id="Path_2242"
          data-name="Path 2242"
          d="M53.415,59.5a.931.931,0,0,0-.932.932,3.852,3.852,0,0,1-3.851,3.82.932.932,0,1,0,0,1.863,5.716,5.716,0,0,0,5.715-5.683.932.932,0,0,0-.932-.932Z"
          transform="translate(-39.181 -43.402)"
        />
        <path
          id="Path_2243"
          data-name="Path 2243"
          d="M30.836,8.451a1.552,1.552,0,0,0-2.7,0L21.488,20.1a9.237,9.237,0,1,0,16.025,0ZM33.165,31.09h0A7.392,7.392,0,0,1,23.1,21.028l6.4-11.18,6.4,11.18a7.393,7.393,0,0,1-2.733,10.063Z"
          transform="translate(-20.267 -7.664)"
        />
      </g>
    </svg>
  );
};

export default AvailabilitySVG;

/* Props */
AvailabilitySVG.propTypes = {
  /**
   * This change the header arrow's styling
   * It expects string that represent a css class
   * Example can be seen in restrictions.scss, .restrictions-body-arrow
   *
   * Side Note:
   * Change both height and width to control size
   * Change fill to change color
   */
  customClass: PropTypes.string,
};
