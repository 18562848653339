// Library
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

// Component
import GeneralDropdown from '../dropDown/GeneralDropdown';

// Constant
const ALL_CATEGORY_DROPDOWN = 'All';

export default function LicenceCategorySelector({
  listOfCategories,
  onCategoryChange,
  addAllOption = true,
  multipleSelection,
}) {
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState({});

  useEffect(() => {
    if (!isEmpty(listOfCategories)) {
      let filtered = listOfCategories.map(category => {
        return {
          id: category.category_shortname,
          name: category.category_shortname,
        };
      });

      addAllOption && filtered.unshift({ id: 'all', name: ALL_CATEGORY_DROPDOWN });
      setFilteredCategories(filtered);
      setSelectedCategories(filtered[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfCategories]);

  return (
    <GeneralDropdown
      menuItem={filteredCategories}
      onItemClick={item => {
        setSelectedCategories(item);
        onCategoryChange(item.id);
      }}
      selectedItem={
        multipleSelection || isEmpty(selectedCategories)
          ? filteredCategories[0]
          : selectedCategories
      }
      multipleSelection={multipleSelection}
    />
  );
}

LicenceCategorySelector.propTypes = {
  listOfCategories: PropTypes.arrayOf(
    PropTypes.shape({
      category_shortname: PropTypes.string,
      resources: PropTypes.object,
      total_water_usage: PropTypes.number,
    }),
  ),
  onCategoryChange: PropTypes.func,
  addAllOption: PropTypes.bool,
  multipleSelection: PropTypes.bool,
};
