import { toString } from 'lodash';

const TABLE_HEADERS = {
  Tabular1: ['Site', 'Varfrom', 'Varto', 'Rating Table No.', 'Level', 'Flow', 'Quality'],
  Tabular2: [0, 0.01, 0.02, 0.03, 0.04, 0.05, 0.06, 0.07, 0.08, 0.09],
};

export default Object.freeze({
  TABLE_HEADERS: TABLE_HEADERS,
  FILE_HEADERS: {
    Graph: [
      { label: 'Site ID', key: 'SiteId' },
      { label: 'Level', key: 'Level' },
      { label: 'CTF', key: 'CeaseToFlowValue' },
      { label: 'Period of Applicability', key: 'PointOfApplicability' },
      { label: 'Table No.', key: 'TableNo' },
      { label: 'Release No.', key: 'ReleaseNo' },
      // { label: 'Release Date', key: 'ReleaseDate' },
      { label: 'Rating Table', key: 'RatingTableName1' },
      { label: 'Flow (ML/d)', key: 'Value1' },
    ],
    Tabular1: [
      { label: 'Site ID', key: 'SiteId' },
      { label: 'Varfrom', key: 'varFrom' },
      { label: 'Varto', key: 'varTo' },
      { label: 'Rating Table No.', key: 'ratingTableNo' },
      // { label: 'Release Date', key: 'ReleaseDate' },
      { label: 'Level', key: 'Level' },
      { label: 'Flow', key: 'Value1' },
      { label: 'Quality', key: 'quality' },
      { label: 'Period of Applicability', key: 'PointOfApplicability' },
    ],
    Tabular2: [
      { label: 'Level', key: 'Level' },
      ...TABLE_HEADERS.Tabular2.map(item => {
        return { label: item, key: toString(item) };
      }),
      { label: '', key: 'MetaData' },
    ],
  },
});
