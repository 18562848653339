// Libraries
import React from 'react';
import PropTypes from 'prop-types';

const CircleTickSVG = ({ customClass }) => {
  return (
    <svg
      width="42.7px"
      height="42.7px"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      className={customClass ? customClass : ' '}
    >
      <path d="m14.25 8.75c-.5 2.5-2.3849 4.85363-5.03069 5.37991-2.64578.5263-5.33066-.7044-6.65903-3.0523-1.32837-2.34784-1.00043-5.28307.81336-7.27989 1.81379-1.99683 4.87636-2.54771 7.37636-1.54771" />
      <polyline points="5.75 7.75,8.25 10.25,14.25 3.75" />
    </svg>
  );
};

export default CircleTickSVG;

/* Props */
CircleTickSVG.propTypes = {
  customClass: PropTypes.string,
};
