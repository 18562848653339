// Library
import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { isEmpty, toString } from 'lodash';

// Styles
import './snapshot.scss';

// Component
import BodyContainer from '../../layout/body/BodyContainer';
import PageTop from '../../components/pageTop/PageTop';
import BackLink from '../../components/backLink/BackLink';
import SnapshotBody from '../../components/snapshot/SnapshotBody';
import RiverSchematic from '../../components/riverSchematic/RiverSchematic';
import DataRefresh from '../../components/dataSummary/DataRefresh';
import { getWaterSourceDroughtFloodRisk } from '../../helpers/UpdatesApiHelper.jsx';

// Context
import { AppContext } from '../../contexts/AppContext';

// Helper
import {
  getAllDamsResources,
  getAllocDataWaterSource,
  getWaterSourceByType,
  getOveviewResources,
  getAllStationsByType,
  getStationsByID,
} from '../../helpers/ApiHelper.jsx';
import { currentFinancialYear } from '../../helpers/TimeUtils.jsx';
import { groupAllocationsByCategory } from '../../components/allocation/ArrayUtilsAllocation';
import { sortArrayByKeyAsc } from '../../helpers/Utils.jsx';
import { buildUrl, getPathname } from '../../helpers/UrlGenerator.jsx';
import { useForceUpdate } from '../../helpers/CustomHooks';
import { getMultiSiteData } from '../../helpers/WaterDataApiHelper';
import { getLatestStorageResources } from '../../components/pageComponents/stateOverview/DataHelper';

// Constant
import text from '../../abstract/IntroText.json';
import constant from '../../constants/Constants';
import config from '../../configs/featureToggleConfig.json';
import stateWide from '../../components/riverSchematic/schematics/stateWide.json';
import DataQualityLegend from '../../components/generalComponents/dataQuality/DataQualityLegend';

const component = 'snapshot';
const snapshotOrder = {
  15324: 0,
  14681: 1,
  11985: 2,
  11986: 3,
  11887: 3.1,
  12105: 4,
  15101: 5,
  11984: 6,
  12801: 7,
  13802: 8,
  12104: 9,
  11983: 10,
  16801: 11,
  11884: 11.1,
  16443: 11.2,
  11982: 12,
  12103: 12.1,
  11904: 13,
  13682: 13.1,
  16281: 13.2,
  15481: 14,
};
const inlineWeirs = {
  11985: ['418043'],
  11983: ['412048', '412172'],
  11982: [
    '41010904',
    '410190',
    '41010981',
    '41010922',
    '41010971',
    '41010928',
    '41010941',
    '41010966',
  ],
  11986: ['419062', '419060'],
  14681: ['416065'],
};
const lowerDarlingDam = ['425049'];
const allocCategories = [
  'General security',
  'General security A',
  'General security B',
  'High security',
  'Aquifer',
];
const getDamDataFromWaterDataApi = config['water-data-api'].dams.active;
const systemViewGauges = stateWide?.locations
  ?.filter(item => item?.locationType === 'gauge')
  ?.map(item => item?.locationID)
  ?.flat();

export default function Snapshot() {
  const { pathname } = useLocation();
  const [resources, setResources] = useState([]);
  const [waterSourceId, setWaterSourceId] = useState([]);
  const [waterSourceList, setWaterSourceList] = useState([]);
  const [damInfoList, setDamInfoList] = useState([]);
  const { setWaterSource } = useContext(AppContext);
  const [gauges, setGauges] = useState([]);
  const [weirs, setWeirs] = useState([]);
  const [damResources, setDamResources] = useState([]);

  // Hooks
  const [trigger, forceUpdate] = useForceUpdate();

  useEffect(() => {
    window.scrollTo(0, 0);
    (async () => {
      const wsList = Object.keys(snapshotOrder);
      await getWaterSourceByType(null, function (result) {
        const list = result.filter(item => wsList.includes(toString(item.water_source_id)));
        const regulated = list.filter(item => item.water_source_type.includes('REG'));
        let dams = [];
        regulated.map(item => item.dams.map(damItem => dams.push(damItem)));
        setDamInfoList(dams);
        setWaterSourceId(list.map(item => item.water_source_id));
        setWaterSourceList(list);
      });
    })();
  }, [pathname]);

  useEffect(() => {
    (async () => {
      if (!isEmpty(waterSourceId) && !isEmpty(waterSourceList)) {
        let allWeirs = [];
        Object.values(inlineWeirs).map(weirItem => {
          allWeirs = allWeirs.concat(weirItem);
        });
        const weirsMetadata = await getStationsByID(allWeirs);

        let damsResources;
        if (getDamDataFromWaterDataApi) {
          damsResources = await getLatestStorageResources(false, true, damInfoList, true);
        } else {
          damsResources = await getAllDamsResources('volume');
        }
        setDamResources(damsResources);

        let weirsResources = await getMultiSiteData(
          weirsMetadata.map(item => item.id),
          ['TotalStorageVolume'],
          null,
          null,
          true,
          true,
        );
        if (!isEmpty(weirs)) {
          weirsResources = weirsResources.map(item => {
            const weir = weirs.find(weir => weir.station_id === item.id);
            return {
              ...item,
              station_id: item.id,
              full_volume: weir.full_volume,
              station_name: weir.station_name,
            };
          });
        }

        const gaugesMetadata = await getStationsByID([...systemViewGauges]);
        let gaugesResources = await getMultiSiteData(
          gaugesMetadata.map(item => item.id),
          ['FlowRate'],
          null,
          null,
          true,
          true,
        );
        gaugesResources = gaugesResources.map(item => {
          return {
            ...item,
            station_id: item.id,
          };
        });

        let filteredWeirs;
        await getAllStationsByType('weir', function (results) {
          filteredWeirs = results.filter(item => allWeirs.includes(item.station_id));
          setWeirs(filteredWeirs);
          weirsResources = results.map(item => {
            const weir = weirsResources.find(weir => weir.id === item.station_id);
            if (weir) {
              return {
                ...item,
                ...weir,
                station_id: item.station_id,
                full_volume: item.full_volume,
                station_name: item.station_name,
              };
            } else {
              return item;
            }
          });
        });
        setGauges(gaugesResources);

        const getAllResult = true;
        let droughtFloodRiskAll = [];
        const usageSummary = await getOveviewResources('availability');
        const tradeSummary = await getOveviewResources('trades');

        await getWaterSourceDroughtFloodRisk(
          null,
          function (result) {
            if (result) {
              droughtFloodRiskAll = result;
            }
          },
          getAllResult,
        );
        await getAllocDataWaterSource(
          waterSourceId.join(','),
          function (result) {
            const joinedWaterSourceList = joinAllData(
              waterSourceList,
              damsResources,
              result,
              usageSummary,
              weirsResources,
              tradeSummary,
              droughtFloodRiskAll,
              filteredWeirs,
            );
            const reorderedWaterSourceList = reorderWaterSources(joinedWaterSourceList);
            setResources(reorderedWaterSourceList);
          },
          currentFinancialYear(),
          currentFinancialYear(),
          getAllResult,
        );
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterSourceId, waterSourceList, trigger, damInfoList]);

  useEffect(() => {
    setWaterSource({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterSourceId, waterSourceList]);

  const tradingValue = (data, key) => {
    return sortArrayByKeyAsc(data, 'trading_zone').map(e => {
      return { zone: e.trading_zone, value: e[key] };
    });
  };

  const joinAllData = (
    waterSources,
    damData,
    allocationData,
    usageSummary,
    weirsData,
    tradeSummary,
    droughtFloodRiskData,
    filteredWeirs,
  ) => {
    const result = waterSources.map(
      ({ water_source_id, water_source_name, water_source_type, full_volume, dams }) => {
        const allocation = formatAllocationData(allocationData, water_source_id);
        let damList = dams;
        if (water_source_id === constant.LOWER_DARLING_REGULATED_RIVER_WATER_SOURCE) {
          damList = dams.filter(dam => lowerDarlingDam.includes(dam.station_id));
        }
        const damsItems = processStorageData(damData, damList);
        const targetWeirList = inlineWeirs[water_source_id]
          ? getDamDataFromWaterDataApi
            ? filteredWeirs.filter(item => inlineWeirs[water_source_id].includes(item.station_id))
            : inlineWeirs[water_source_id]
          : [];
        const weirItems = inlineWeirs[water_source_id]
          ? processWeirsData(weirsData, targetWeirList)
          : [];
        const usageData = !isEmpty(usageSummary)
          ? usageSummary.find(usageItem => usageItem.id === water_source_id)
          : [];
        const tradesData = !isEmpty(tradeSummary)
          ? tradeSummary.filter(tradeItem => tradeItem.id === water_source_id)
          : [];
        const droughtFloodRisk = !isEmpty(droughtFloodRiskData)
          ? droughtFloodRiskData.filter(
              droughtFloodRiskItem =>
                Number(droughtFloodRiskItem.water_source_id) === water_source_id,
            )
          : {};
        let totalStored = !isEmpty(damsItems)
          ? damsItems.map(item => {
              const currItem = item.resources || {};
              return currItem.volume || 0;
            })
          : [];
        totalStored = !isEmpty(weirItems)
          ? totalStored.concat(
              weirItems.map(item => {
                const currItem = item.resources || {};
                return currItem.volume || 0;
              }),
            )
          : totalStored;
        const waterSourceData = allocation;
        !water_source_type.includes(constant.WATER_SOURCE_TYPE.groundwater) &&
          waterSourceData.push({
            label: 'Stored',
            value: !isEmpty(totalStored)
              ? totalStored
                  .filter(function (element) {
                    return element !== undefined;
                  })
                  .reduce((a, b) => a + b)
              : null,
            unit: 'GL',
          });
        return {
          water_source_id,
          water_source_name,
          water_source_type,
          link: buildUrl(
            getPathname(water_source_id + ' ' + water_source_name),
            constant.TAB_OPTIONS.updates.link,
          ),
          full_volume,
          dams: damsItems,
          weirs: weirItems,
          droughtFloodRisk: droughtFloodRisk,
          water_source_data: waterSourceData,
          allocation: allocation,
          ...(usageData !== -1 &&
            typeof usageData !== 'undefined' && {
              usage: [
                {
                  label: 'available',
                  value: usageData.allocation_available / 1000,
                  unit: 'GL',
                },
                {
                  label: 'used',
                  value: usageData.allocation_used / 1000,
                  unit: 'GL',
                },
              ],
            }),
          ...(tradesData !== -1 &&
            !isEmpty(tradesData) &&
            typeof tradesData !== 'undefined' && {
              traded: [
                {
                  label: 'traded',
                  title_lable: 'Traded this month',
                  value: tradingValue(tradesData, 'traded_volume'),
                  unit: 'ML',
                },
                {
                  label: 'average_price',
                  title_lable: 'Average traded price',
                  value: tradingValue(tradesData, 'average_price'),
                  unit: '/ML',
                },
              ],
            }),
        };
      },
    );
    return result;
  };

  const formatAllocationData = (data, waterSourceId) => {
    let result = data.find(data => data.water_source_id === waterSourceId);
    if (isEmpty(result) || !result.resources) return [];

    result.resources[0].resources = groupAllocationsByCategory(result.resources[0].resources);
    const filtered = result.resources[0].resources.filter(data =>
      allocCategories.includes(data.category_shortname),
    );
    result = filtered.map(({ category_shortname, allocation_percentage, number_shares }) => {
      return {
        label: category_shortname,
        value: allocation_percentage,
        number_shares: category_shortname === allocCategories[2] ? 0 : number_shares,
      };
    });

    result = sortArrayByKeyAsc(result, 'label').map(({ label, value }) => ({
      label,
      value,
    }));
    return result;
  };

  const reorderWaterSources = waterSourceList => {
    const result = [];
    waterSourceList.forEach(waterSource => {
      const curWaterSource = waterSource.water_source_id;
      const curWaterSourcePriority = snapshotOrder[curWaterSource];
      const insertIndex = result.findIndex(
        item => snapshotOrder[item.water_source_id] > curWaterSourcePriority,
      );
      if (insertIndex === -1) {
        result.push(waterSource);
      } else {
        result.splice(insertIndex, 0, waterSource);
      }
    });

    return result;
  };

  const processStorageData = (siteData, sitesList) => {
    const result = sitesList.map(({ station_id, station_name, full_volume }) => {
      const siteResources = siteData.find(data => data.id === station_id);
      if (!siteResources || isEmpty(siteResources)) return {};
      const siteName = station_name || siteResources.station_name;
      const resourcesObj = siteResources.resources[0];
      if (!resourcesObj.volume_perc) {
        resourcesObj.volume_perc = siteResources.full_volume
          ? (resourcesObj.volume / siteResources.full_volume) * 100
          : 0;
      }
      return {
        station_id,
        station_name: siteName,
        full_volume,
        stored: resourcesObj.volume,
        resources: resourcesObj,
      };
    });

    return result;
  };

  const processWeirsData = (weirsData, weirsList) => {
    const result = weirsList.map(weir => {
      const weirResources = weirsData.find(site => {
        if (getDamDataFromWaterDataApi) return site.station_id === weir.station_id;
        return site.station_id === weir;
      });
      if (!weirResources || isEmpty(weirResources)) return {};
      const resourcesObj = weirResources.resources ? weirResources.resources[0] : {};
      if (!resourcesObj.volume_perc) {
        resourcesObj.volume_perc = weirResources.full_volume
          ? (resourcesObj.volume / weirResources.full_volume) * 100
          : 0;
      }
      return {
        station_id: weirResources.station_id,
        station_name: weirResources.station_name,
        full_volume: weirResources.full_volume,
        stored: resourcesObj.volume,
        resources: resourcesObj,
      };
    });

    return result;
  };

  return (
    <BodyContainer page>
      <BackLink />
      <PageTop title={text.snapshot.title} />
      {!isEmpty(damResources) && !isEmpty(gauges) && (
        <>
          <DataRefresh forceUpdate={forceUpdate} />
          <div className={`${component}-quality-legend`}>
            <DataQualityLegend />
          </div>
          <RiverSchematic gauges={gauges} dams={damResources} stateView={true} />
        </>
      )}
      <SnapshotBody data={resources} />
    </BodyContainer>
  );
}
