// Libraries
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// Styles
import './ChartHeader.scss';

// Assets
import DownloadIcon from '../../image/downloadIcon.svg';

// Helpers
import { createMarkup } from '../../helpers/Utils';

/* Context */
import { MobileContext } from '../../contexts/MobileContext';

export default function ChartHeader(props) {
  const { isMobile } = useContext(MobileContext);

  return (
    <div className="row chart-header">
      {!isMobile && (
        <div dangerouslySetInnerHTML={createMarkup(props.desc)} className="chart-header-desc" />
      )}
      <a className="chart-header-download-link" href="# ">
        <img
          className="download-icon"
          src={DownloadIcon}
          alt={`Icon for download link for "${props.title}"`}
        />
        <span>Download</span>
      </a>
    </div>
  );
}

ChartHeader.propTypes = {
  desc: PropTypes.string,
  title: PropTypes.string,
};
