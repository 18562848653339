/* Library */
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

/* Style */
import './events.scss';

/* Component */
import Title from '../title/Title';
import EventItem from './EventItem';
import ExpandBox from '../generalComponents/expandBox/ExpandBox';
import AlertResponse from '../alert/AlertResponse';
import AlertModalBody from '../alert/AlertModalBody';
import UpdateAlertLink from '../updates/UpdateAlertLink';

/* Context */
import { MobileContext } from '../../contexts/MobileContext';
import { AppContext } from '../../contexts/AppContext';

/* Helpers */
import { getStationForWaterSourceByType } from '../../helpers/ApiHelper';

/* Constant */
import alertResponseText from '../alert/AlertResponseText.json';
import featureConfig from '../../configs/featureToggleConfig.json';

const DROPDOWN_ITEMS = [
  { id: '1', name_id: 'Outage', name: 'Outage' },
  { id: '2', name_id: 'Recreation', name: 'Recreation' },
  { id: '3', name_id: 'Flow Target', name: 'Flow Target' },
  { id: '4', name_id: 'Constraints', name: 'Constraints' },
  // { id: '5', name_id: 'ROSCCo Events', name: 'ROSCCo Events' },
];

export default function Events({ recreationalEvents, maintenanceEvents, rosccoEvents }) {
  const { waterSource } = useContext(AppContext);
  const { isMobile } = useContext(MobileContext);
  const [showManageAlertResponse, setShowManageAlertResponse] = useState(false);
  const [responseType, setResponseType] = useState();
  const [stationList, setStationList] = useState();

  // Life Cycle
  useEffect(() => {
    (async () => {
      const stations = await getStationForWaterSourceByType(waterSource.water_source_id, 'gauge');
      const adjustedStationList = stations.map(station => {
        return {
          id: station.station_id,
          name: `${station.station_id} ${station.station_display_name}`,
        };
      });
      setStationList(adjustedStationList);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const eventContent = () => {
    const modalBody = (
      <>
        <AlertResponse
          show={showManageAlertResponse}
          responseBodyText={alertResponseText.station[responseType]}
        />
        {!showManageAlertResponse && (
          <AlertModalBody
            setResponse={resType => {
              setShowManageAlertResponse(true);
              setResponseType(resType);
            }}
            alertTypeList={DROPDOWN_ITEMS}
            alertType="constraint"
            notifyIfPersist={true}
            editMode={false}
            stationList={stationList}
          />
        )}
      </>
    );
    return (
      <>
        {featureConfig.constraint_alert.active && (
          <UpdateAlertLink
            showResponse={showManageAlertResponse}
            setShowResponse={setting => setShowManageAlertResponse(setting)}
            modalBody={modalBody}
            alertText={alertResponseText.station[responseType]}
            customStyle="update"
            modalType="event-alert-modal"
          />
        )}
        {!(isEmpty(recreationalEvents) && isEmpty(maintenanceEvents) && isEmpty(rosccoEvents)) ? (
          <React.Fragment>
            {!isEmpty(recreationalEvents) && (
              <div className="events-container-subTitle">
                {recreationalEvents.map((restriction, index) => (
                  <EventItem
                    key={index}
                    station_id={restriction.station_id}
                    date={restriction.date}
                    location={restriction.station_name}
                    type={restriction.type}
                    long={restriction.long}
                    lat={restriction.lat}
                    title={restriction.title}
                    body={restriction.body}
                    link={restriction.link}
                  />
                ))}
              </div>
            )}
            {!isEmpty(maintenanceEvents) && (
              <div className="events-container-subTitle">
                {maintenanceEvents.map((restriction, index) => (
                  <EventItem
                    key={index}
                    station_id={restriction.station_id}
                    date={restriction.date}
                    location={restriction.station_name}
                    type={restriction.type}
                    long={restriction.long}
                    lat={restriction.lat}
                    title={restriction.title}
                    body={restriction.body}
                    link={restriction.link}
                  />
                ))}
              </div>
            )}
            {!isEmpty(rosccoEvents) && (
              <div className="events-container-subTitle">
                {rosccoEvents.map((restriction, index) => (
                  <EventItem
                    key={index}
                    title={'ROSCCo Meeting'}
                    date={restriction.meeting_date}
                    type={restriction.type}
                    body={restriction.title}
                    link={restriction.url}
                  />
                ))}
              </div>
            )}
          </React.Fragment>
        ) : (
          <div className="events-container-noEvents">There are no current events.</div>
        )}
      </>
    );
  };

  const mobileEvent = () => {
    return (
      <ExpandBox
        customHeaderContent={<div>Scheduled Events</div>}
        customHeaderContainerStyle={`events-container-header`}
        mobileOnly
      >
        {eventContent()}
      </ExpandBox>
    );
  };

  const desktopHeader = () => {
    return (
      <>
        <div className="events-container-btn-grp">
          <Title title="Scheduled Events" />
        </div>
        {eventContent()}
      </>
    );
  };

  return <div className="events-container">{isMobile ? mobileEvent() : desktopHeader()}</div>;
}

Events.propTypes = {
  recreationalEvents: PropTypes.array,
  maintenanceEvents: PropTypes.array,
  rosccoEvents: PropTypes.array,
};
