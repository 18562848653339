// Libraries
import React, { useContext, useEffect } from 'react';
import { toString } from 'lodash';

// Style
import './storageChart.scss';

// Context
import { AppContext } from '../../contexts/AppContext';
import { MobileContext } from '../../contexts/MobileContext';

// Components
import AverageMonthlyStorageRecent from './AverageMonthlyStorageRecent';
import MonthlyStorageForecast from './MonthlyStorageForecast';
import MurrayFlowRecent from './MurrayFlowRecent';
import ViewDetailsLink from '../mapPopup/viewDetailsLink/ViewDetailsLink';
import CumulativeInflowRecent from '../research/CumulativeInflowRecent';

// Constants
import Constants from '../../constants/Constants';
import TEXT from './storageChartText.json';
const container = 'storage-chart';

export default function StorageChart() {
  const { isMobile } = useContext(MobileContext);
  const { hash } = window.location;
  const { waterSource } = useContext(AppContext);
  const waterSourceId = waterSource.water_source_id;
  const isMurray = waterSourceId === Constants.MURRAY_REGULATED_RIVER_WATER_SOURCE;
  const isGreaterSydney = waterSourceId === Constants.GREATER_SYDNEY_WSID;
  const isFishRiver = waterSourceId === Constants.FISH_RIVER_WATER_SOURCE;
  const isLowerDarling = waterSourceId === Constants.LOWER_DARLING_REGULATED_RIVER_WATER_SOURCE;
  const avgMonthlyStorageText =
    TEXT.AverageMonthlyStorageRecent[toString(waterSourceId)] ||
    TEXT.AverageMonthlyStorageRecent.other;

  // Life Cycle
  useEffect(() => {
    if (hash) {
      setTimeout(function () {
        if (document.getElementById(hash.replace('#', ''))) {
          document.getElementById(hash.replace('#', '')).scrollIntoView(true);
        }
      }, 1300);
    }
  }, [hash]);

  // Component
  const headerMaker = () => {
    return (
      <div className={`${container}-header-title`}>
        <div className={`${container}-header-title-text-container`}>
          <div className={`${container}-header-title-text`}>{TEXT.chartHeader.title}</div>
        </div>
        <ViewDetailsLink
          pathname={Constants.TAB_OPTIONS.research.link}
          label={TEXT.chartHeader.tabLabel}
        />
      </div>
    );
  };

  return (
    <>
      {waterSource && (
        <>
          {!isMurray && !isMobile && headerMaker()}
          <AverageMonthlyStorageRecent
            desc={
              Constants.TOTAL_STORAGE_MENINDEE_LAKES_FLAG && (isLowerDarling || isMurray)
                ? avgMonthlyStorageText.descTotalStorage
                : avgMonthlyStorageText.desc
            }
            title={avgMonthlyStorageText.title}
          />
          {!isMurray && !isGreaterSydney ? (
            <>
              {!isFishRiver && <CumulativeInflowRecent />}
              <MonthlyStorageForecast
                desc={TEXT.MonthlyStorageForecast.desc}
                title={TEXT.MonthlyStorageForecast.title}
              />
            </>
          ) : (
            !isGreaterSydney && (
              <div className="murray-inflows-outflows-graphs">
                <MurrayFlowRecent
                  graphType={'inflows'}
                  yAxisLabel="Inflows"
                  title={TEXT.ShareSystemInflows.title}
                  dams={waterSource.dams}
                  desc={TEXT.ShareSystemInflows.desc}
                />
                <MurrayFlowRecent
                  graphType={'outflows'}
                  yAxisLabel="Volume"
                  title={TEXT.FlowSouthAustralia.title}
                  dams={waterSource.dams}
                  desc={TEXT.FlowSouthAustralia.desc}
                />
              </div>
            )
          )}
        </>
      )}
    </>
  );
}
