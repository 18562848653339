import { useEffect, useCallback } from 'react';

/**
 * Custom React hook that provides functionality for responding to click outside of provided reference
 *
 * @param {Element} ref - reference for elements that you wish to exclude for the click event
 * @param {function} onClick - Callback function that is the event handler for the click event
 *
 */
export const useOutsideClickDetect = (ref, onClick) => {
  const handleClickOutside = useCallback(
    event => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClick(event);
      }
    },
    [onClick, ref],
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [ref, handleClickOutside]);
};
