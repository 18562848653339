// Library
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

// Style
import './siteLink.scss';

// Components
import ArrowSVG from '../../image/icons/ArrowSVG';

// Context
import { MiniAppContext } from '../../contexts/MiniAppContext';

//Constants
const component = 'site-link';

export default function SiteLink({ key, site, children }) {
  const { setStation } = useContext(MiniAppContext);

  const onSiteClicked = async () => {
    setStation(site);
  };

  return site ? (
    <div key={key} className={component}>
      <Link
        id={key}
        onClick={() => onSiteClicked()}
        to={{ pathname: `/m/site/${site.station_id}` }}
      >
        <div className={`${component}-item`}>
          <span>{`${site.station_id} - ${site.station_name}`}</span>
          {children ? (
            children
          ) : (
            <button className={`${component}-item-button`}>
              <ArrowSVG customArrowColor={`${component}-item-arrow`} />
            </button>
          )}
        </div>
      </Link>
    </div>
  ) : null;
}

SiteLink.propTypes = {
  key: PropTypes.string,
  site: PropTypes.object,
  children: PropTypes.any,
};
