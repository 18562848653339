// Lib
import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, debounce } from 'lodash';

// Style
import './groundwaterDownloadFilters.scss';

// Context
import { UserContext } from '../../../../contexts/UserContext';

// Components
import RequestModal, { API_STATUS } from '../../../modal/RequestModal';
import DataFilters from '../../DataFilters';
import GroundwaterDataFilters from './GroundwaterDataFilters';

// Image
import CancelSVG from '../../../../image/icons/CancelSVG';

// Util
import {
  submitRequest,
  suggestQuery,
  convertAddressToCoord,
  convertCoordinateToBoreId,
  controlDrawControl,
  convertLicenceToBoreIds,
  STATUS,
  onDrawOptionClick,
  setRadius,
  addBoresToMap,
} from './GroundwaterPageHelpers.jsx';

// Constant
import { SELECTION_LIMIT } from './GroundWaterSitesMap';
export const FILTERS = {
  id: 'GWNumber',
  apprv: 'ApprvNum',
  address: 'Address',
  coord: 'LatLong',
  poly: 'ByPolygon',
  rect: 'ByRectangle',
  circle: 'ByRadius',
};
const BTN_TYPE = 'REQUEST';
const MODAL_ID = 'BOREREPORTMODAL';

const GroundwaterDownloadFilters = ({
  clearShape,
  gwDataSelected,
  setGwDataSelected,
  layer,
  setLayer,
  setMarkers,
  setCircle,
}) => {
  // Context
  const { user, isLoggedIn } = useContext(UserContext);

  // Ref
  const longRef = useRef();
  const latRef = useRef();
  const addressRef = useRef();
  const circleRef = useRef();

  // States
  const [openLocationType, setLocationType] = useState(true);
  const [selectedItem, setSelectedItem] = useState('');
  const [formStatus, setFormStatus] = useState(STATUS.DEFAULT);
  const [apiStatus, setApiStatus] = useState(API_STATUS.LOAD);
  const [magicKey, setMagicKey] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  // Life Cycle
  useEffect(() => {
    (async () => {
      if (!isEmpty(gwDataSelected) && isLoggedIn) {
        setFormStatus(STATUS.VALID);
      }
    })();
  }, [isLoggedIn, selectedItem, gwDataSelected]);

  useEffect(() => {
    if (layer && layer.layerType === 'circle' && selectedItem === FILTERS.circle) {
      const curRadius = layer.layer.getRadius();
      if (Number(circleRef.current.value) !== Number(curRadius)) {
        circleRef.current.value = curRadius / 1000;
      }
    }
  }, [layer, selectedItem]);

  useEffect(() => {
    (async () => {
      if (magicKey) {
        const coords = await convertAddressToCoord(addressRef.current.value, magicKey);
        const boreIds = await convertCoordinateToBoreId(
          coords.x,
          coords.y,
          circleRef?.current?.value,
          setFormStatus,
          setCircle,
        );
        setGwDataSelected(boreIds);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [magicKey]);

  /**
   * Extract/Set bore ids immediately after the user's input
   */
  const debounced = debounce(async val => {
    let boreIds;
    let isValid = true;
    let coords;
    switch (selectedItem) {
      case FILTERS.id:
        boreIds = val ? val.split(',').map(ids => ids.trim()) : [];
        isValid = await addBoresToMap(boreIds, setFormStatus, setMarkers);
        break;
      case FILTERS.coord:
        boreIds = await convertCoordinateToBoreId(
          longRef?.current?.value,
          latRef?.current?.value,
          circleRef?.current?.value,
          setFormStatus,
          setCircle,
        );
        break;
      case FILTERS.address:
        coords = await convertAddressToCoord(addressRef.current.value, magicKey);
        boreIds = await convertCoordinateToBoreId(
          coords.x,
          coords.y,
          circleRef?.current?.value,
          setFormStatus,
          setCircle,
        );
        break;
      case FILTERS.apprv:
        boreIds = await convertLicenceToBoreIds(val, setFormStatus);
        isValid = await addBoresToMap(boreIds, setFormStatus, setMarkers);
        break;
      case FILTERS.circle:
        setRadius(val, layer, setLayer);
        break;
    }

    setGwDataSelected(boreIds && isValid ? boreIds : []);
  }, 200);

  // Components
  const radioItem = (itemId, itemName, content, onClick = () => {}) => {
    return {
      name: (
        <label className="radio-item">
          <input
            type="radio"
            name="ritem"
            value={itemId}
            onChange={e => {
              // reset graph
              controlDrawControl(e.target.value);
              clearShape();
              const cancelBtn = document.querySelector('[title="Cancel drawing"]');
              cancelBtn && cancelBtn.click();

              // reset state
              setFormStatus(STATUS.DEFAULT);
              setApiStatus(API_STATUS.LOAD);
              setMarkers([]);
              setCircle({});
              setGwDataSelected([]);
              setSuggestions([]);
              setSelectedItem(e.target.value);
              setMagicKey('');

              // onClick for individual
              onClick();
            }}
          />
          {itemName}
        </label>
      ),
      content,
      open: selectedItem === itemId,
    };
  };

  const inputItem = (placeholder, errorMsg, ref = null) => {
    return (
      <div className="download-filters-input">
        <input
          ref={ref}
          type="text"
          name="ritem"
          placeholder={placeholder}
          onChange={e => {
            setFormStatus(STATUS.DEFAULT);
            const val = e.target.value;
            debounced(val);
            if (selectedItem === FILTERS.address && ref === addressRef) {
              suggestQuery(val, setSuggestions);
            }
          }}
        />
        {formStatus === STATUS.ERROR && (
          <div className="download-filters-error">
            <CancelSVG customClass="download-filters-error-icon" />
            <p className="download-filters-error-text">{errorMsg}</p>
          </div>
        )}
        {!isEmpty(suggestions) && ref === addressRef && (
          <div className="download-filters-suggestion">
            {suggestions.map(suggestionItem => {
              return (
                <div
                  className="download-filters-suggestion-item"
                  key={suggestionItem.text}
                  onClick={async () => {
                    addressRef.current.value = suggestionItem.text;
                    setMagicKey(suggestionItem.magicKey);
                    setSuggestions([]);
                  }}
                >
                  {suggestionItem.text}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  // Config
  const FILTER_OPTIONS = [
    radioItem(
      FILTERS.id,
      'GW Number (Site IDs)',
      inputItem('E.g. GW12345,GW12346', 'The ID numbers seems to be wrong or does not exist.'),
    ),
    radioItem(
      FILTERS.apprv,
      'Approval Number',
      inputItem('Approval Number', 'The Approval number seems to be wrong or does not exist.'),
    ),
    radioItem(
      FILTERS.coord,
      'Latitude / Longitude Coordinates',
      <div>
        {inputItem('Latitude', 'The latitude seems to be wrong or does not exist.', latRef)}
        {inputItem('Longitude', 'The longitude seems to be wrong or does not exist.', longRef)}
        {inputItem(
          'Radius (km)',
          'The radius seems to be an invalid number. Please make sure you input a valid number',
          circleRef,
        )}
      </div>,
    ),
    radioItem(
      FILTERS.address,
      'Address',
      <div>
        {inputItem(
          'Address',
          'The address seems to be wrong or does not exist within NSW. Please make sure you selected a address from the list',
          addressRef,
        )}
        {inputItem(
          'Radius (km)',
          'The radius seems to be an invalid number. Please make sure you input a valid number',
          circleRef,
        )}
      </div>,
    ),
    radioItem(FILTERS.rect, 'Select by Rectangle', null, () => {
      onDrawOptionClick(FILTERS.rect);
    }),
    radioItem(FILTERS.poly, 'Select by Polygon', null, () => {
      onDrawOptionClick(FILTERS.poly);
    }),
    radioItem(
      FILTERS.circle,
      'Select by Radius (KM)',
      inputItem(
        'Radius (km)',
        'The radius seems to be an invalid number. Please make sure you input a valid number',
        circleRef,
      ),
      () => {
        onDrawOptionClick(FILTERS.circle);
      },
    ),
  ];

  const LOCATION_OPTION = [
    {
      name: 'Select location by',
      content: <GroundwaterDataFilters items={FILTER_OPTIONS} />,
      open: openLocationType,
      setOpen: setLocationType,
    },
  ];

  return (
    <>
      <RequestModal id={MODAL_ID} feedbackStatus={apiStatus} />
      <DataFilters
        items={LOCATION_OPTION}
        onBtnClick={async () => {
          if (formStatus === STATUS.VALID) {
            setApiStatus(API_STATUS.LOAD);
            await submitRequest(setApiStatus, gwDataSelected, user.email);
          }
        }}
        disabled={formStatus !== STATUS.VALID || gwDataSelected.length > SELECTION_LIMIT}
        warningMsg={
          'We are in the process of migrating all groundwater data, so some data is currently unavailable. If you are unable to find what you are looking for, please contact us via the Feedback button.'
        }
        btnLabel={BTN_TYPE}
        targetModalId={`#${MODAL_ID}`}
      />
    </>
  );
};

export default GroundwaterDownloadFilters;

GroundwaterDownloadFilters.propTypes = {
  clearShape: PropTypes.func,
  setGwDataSelected: PropTypes.func,
  gwDataSelected: PropTypes.arrayOf(PropTypes.string),
};
