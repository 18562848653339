// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './mapLegend.scss';
import './radioSelectionBox.scss';

const container = 'radio-selection';

export default function RadioSelectionBox({ onRadioOptionChange, dataType, radioItems }) {
  return (
    <>
      <div className="map-legend-space" />
      <div className={container}>
        <form>
          {radioItems.map((item, index) => (
            <div className={`${container}-label`} key={index}>
              <label>
                <input
                  type="radio"
                  name="options"
                  value={item.value}
                  defaultChecked={item.value === dataType}
                  onClick={e => onRadioOptionChange(e.target.value)}
                />
                &nbsp;{item.label}
              </label>
            </div>
          ))}
        </form>
      </div>
    </>
  );
}

RadioSelectionBox.propTypes = {
  onRadioOptionChange: PropTypes.func,
  dataType: PropTypes.string,
  radioItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};
