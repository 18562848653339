/* Library */
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

/* Styles */
import './riverDataSummary.scss';

/* Component */
import DataSummary from '../dataSummary/DataSummary';
import DataSummaryHeader from '../dataSummary/DataSummaryHeader';
import RiverDataSummaryHeader from './RiverDataSummaryHeader';
import RiverDataSummaryOverview from './RiverDataSummaryOverview';
import RiverDataSummaryTable from './RiverDataSummaryTable';
import RiverDataSummaryGraph from './RiverDataSummaryGraph';

/* Context */
import { MobileContext } from '../../contexts/MobileContext';

/* Helpers */
import { getSiteMetadata } from '../../helpers/WaterDataApiHelper';

/* Constants */
import HydrometricsConstants from '../../constants/HydrometricsConstants';
import Constants from '../../constants/Constants';
const component = 'river-data-summary';

export default function RiverDataSummary({ type, dataListLimit, stations, detailsLoading }) {
  const dataProps = HydrometricsConstants.RIVER_PROPS[type];
  const { isMobile } = useContext(MobileContext);
  const [stationId, setStationId] = useState(-1);
  const [activeHydrometric, setActiveHydrometric] = useState();
  const [dataSourceMsg, setDataSourceMsg] = useState('');
  const siteTypes = {
    stations: 'StreamGauge',
    gauge: 'StreamGauge',
    dam: 'Storage',
    weir: 'Weirs',
  };

  // Life Cycle
  useEffect(() => {
    (async () => {
      setDataSourceMsg('');
      if (stationId !== -1 && type !== 'bore') {
        const siteMetadata = await getSiteMetadata(stationId, siteTypes[type]);
        if (
          !isEmpty(siteMetadata) &&
          siteMetadata[0].owner &&
          siteMetadata[0].owner !== 'WaterNSW'
        ) {
          setDataSourceMsg(`Site and data is owned by and sourced from ${siteMetadata[0].owner}.`);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stationId]);

  return (
    <div className={component}>
      <DataSummaryHeader header={<RiverDataSummaryHeader stationType={type} />} />
      {!isEmpty(stations) &&
        stations
          .filter(item => Boolean(item.active) === true || Boolean(item.temporary) === true)
          .slice(0, dataListLimit ? dataListLimit : stations.length)
          .sort((a, b) => (a.order_index >= b.order_index ? 1 : -1))
          .map((station, stationIndex) => {
            const riverDataProps =
              station.water_data_variables &&
              station.water_data_variables.includes('StorageWaterLevel')
                ? dataProps.filter(item => item.waterDataKey !== 'StreamWaterLevel')
                : dataProps.filter(item => item.waterDataKey !== 'StorageWaterLevel');
            const specialSites = Constants.EXCLUDED_SITES.includes(station.station_id);
            return (
              <DataSummary
                key={stationIndex}
                id={station.station_id}
                selected={stationId === station.station_id}
                header={
                  <RiverDataSummaryOverview
                    station={station}
                    selected={stationId === station.station_Id}
                    stationType={type}
                    setStationId={setStationId}
                    detailsLoading={detailsLoading}
                  />
                }
                onExpand={(state, identifier) => {
                  if (station.active && state) {
                    setStationId(identifier);
                  } else {
                    setStationId(-1);
                  }
                }}
                clickable={isMobile ? station.active && !specialSites : station.active}
              >
                {stationId === station.station_id && (
                  <div className={`${component}-body`}>
                    {!specialSites && (
                      <RiverDataSummaryTable
                        index={stationIndex}
                        stationData={station}
                        stationType={type}
                        dataProps={riverDataProps}
                        detailsLoading={detailsLoading}
                      />
                    )}
                    {!isMobile && (
                      <RiverDataSummaryGraph
                        stationData={station}
                        stationType={type}
                        dataProps={riverDataProps}
                        activeHydrometric={activeHydrometric}
                        setActiveHydrometric={id => setActiveHydrometric(id)}
                      />
                    )}
                    <div className={`${component}-body-attribution-group`}>
                      {station.has_forecast && station.has_forecast.bom && !isMobile && (
                        <div className={`${component}-body-attribution`}>
                          Forecast data is sourced from the Bureau of Meteorology, updated about 2pm
                          daily.&nbsp;
                          <a
                            className="drought-body-link"
                            href="http://www.bom.gov.au/data-access/3rd-party-attribution.shtml"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Click here for details.
                          </a>
                        </div>
                      )}
                      {dataSourceMsg && (
                        <div className={`${component}-body-attribution`}>{dataSourceMsg}</div>
                      )}
                      {stationId === '206036' && (
                        <div className={`${component}-body-attribution`}>
                          Site is owned and operated by&nbsp;
                          <a
                            className="drought-body-link"
                            href="https://www.armidaleregional.nsw.gov.au/community/sport-and-recreation/lakes-and-dams"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Armidale Regional Council
                          </a>
                          .
                        </div>
                      )}
                      {type === 'gauge' && !specialSites && (
                        <div className={`${component}-body-attribution`}>
                          WaterNSW urges stakeholders to consult BoM for the&nbsp;
                          <a
                            className="drought-body-link"
                            href="http://www.bom.gov.au/nsw/flood/index.shtml"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            latest flood information.
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </DataSummary>
            );
          })}
    </div>
  );
}

RiverDataSummary.propTypes = {
  type: PropTypes.string,
  stations: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      lat: PropTypes.number,
      long: PropTypes.number,
      level: PropTypes.object,
      order_index: PropTypes.number,
      station_id: PropTypes.any,
      station_name: PropTypes.string,
      station_type: PropTypes.string,
      temperature: PropTypes.object,
      flow_rate: PropTypes.object,
      volume: PropTypes.object,
    }),
  ),
  dataListLimit: PropTypes.number,
};
