// Library
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

// Component
import GeneralDropdown from '../../dropDown/GeneralDropdown';

const StorageChartDropdown = ({ dams, onDamChange, allText = 'all storage', selected }) => {
  const [filteredDams, setFilteredDams] = useState([]);
  const [selectedDam, setSelectedDam] = useState({});

  useEffect(() => {
    const allDams = [];
    let filtered = dams.map(dam => {
      allDams.push(dam.station_id);
      return {
        id: dam.station_id,
        name: dam.station_name,
      };
    });

    if (allDams.length > 1) {
      const allDamsID = allDams.join();
      filtered.unshift({ id: allDamsID, name: allText });
    }
    const curSelected = selected ? filtered.find(el => el.id === selected) : filtered[0];
    setFilteredDams(filtered);
    setSelectedDam(curSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dams, selected]);

  return (
    <>
      <GeneralDropdown
        menuItem={filteredDams}
        onItemClick={item => {
          setSelectedDam(item);
          onDamChange(item.id);
        }}
        selectedItem={isEmpty(selectedDam) ? filteredDams[0] : selectedDam}
        buttonStyle="storage"
        menuItemStyle="storage"
      />
    </>
  );
};

export default StorageChartDropdown;

StorageChartDropdown.propTypes = {
  /**
   * Dams that need to be in the dropdown list
   */
  dams: PropTypes.arrayOf(PropTypes.object),
  /**
   * Click event when a dam is selected
   */
  onDamChange: PropTypes.func,
  allText: PropTypes.string,
  selected: PropTypes.string,
};
