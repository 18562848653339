// Libraries
import React, { useState, useEffect } from 'react';
import {
  Tooltip,
  XAxis,
  YAxis,
  LineChart,
  BarChart,
  Line,
  Bar,
  CartesianGrid,
  Legend,
  Brush,
} from 'recharts';
import { isEmpty, lowerCase, upperFirst } from 'lodash';
import moment from 'moment';

// Style
import './graphAndDownloadGraphItem.scss';

// Helpers
import { formatNumberDecimals } from '../../../../../helpers/Utils';

// Components
import ChartResponsiveContainer from '../../../../chartProperties/ChartResponsiveContainer';
import ToggleButton from '../../../../dataSummary/ToggleButton';

// Constants
import constants from '../DataDownloadConstants';
const component = 'graph-and-download-graph-item';

const GraphAndDownloadGraphItem = ({
  dataKey,
  dataValue,
  interval,
  setGraphType,
  toggleOptions,
}) => {
  const isManualData = interval.id === 'manual';
  const isRainfallData = dataKey === 'Rainfall';
  const decimals = dataValue[0].unit === 'ML' ? 0 : 3;
  const chartMargin = { top: 0, right: 50, bottom: 50, left: 30 };
  let newSites = Object.keys(Object.assign({}, ...dataValue));
  newSites = newSites.filter(el => el !== 'timeStamp' && el !== 'unit' && el !== 'variable');

  let opacity = {};
  newSites.map(site => {
    opacity[site] = 1;
  });
  const [graphOpacity, setGraphOpacity] = useState(opacity);

  // helper
  const dateLabelFormatter = (value, type) => {
    return moment(value).format(constants.DATE_FORMATS[interval.id][type]);
  };

  const onLegendClick = value => {
    const newOpacity = graphOpacity[value.dataKey] === 1 ? 0.1 : 1;
    setGraphOpacity({ ...graphOpacity, [value.dataKey]: newOpacity });
  };

  const formatLabel = label => {
    const formattedLabel = label.includes('-')
      ? `${label.split('-').slice(1).join('-')} Profile`
      : label;
    return label.toLowerCase() === 'ph' ? 'pH' : upperFirst(lowerCase(formattedLabel));
  };

  const formatName = label => {
    if (label.includes(' Hole ') || label.includes(' Pipe ')) {
      const split = label.split('-');
      const newName = `${split[0]}${split[1].replace(dataKey, '')}`;
      return newName;
    }
    const split = label.split('-')[1].split('.');
    const newName = `${split[0].slice(-1)}.${split[1]}`;
    return newName
      .replaceAll(/([A-Z])/g, ' $1')
      .replaceAll(/[^0-9](?=[0-9])/g, '$& ')
      .replaceAll('. ', '.')
      .replaceAll(' M ', 'M ')
      .trim()
      .toLowerCase();
  };

  // mini component
  const renderLineChart = () => {
    let lineArr = [];
    newSites.forEach((site, index) => {
      lineArr.push(
        <Line
          key={`line-chart-${index}`}
          name={site}
          type="monotone"
          strokeWidth={2}
          strokeOpacity={graphOpacity[site]}
          dataKey={site}
          stroke={constants.GRAPH_COLOR_CODES[index]}
          dot={isManualData ? { strokeWidth: 2, r: 1 } : false}
        />,
      );
    });
    return lineArr;
  };

  const renderBarChart = () => {
    let barArr = [];
    newSites.forEach((site, index) => {
      barArr.push(
        <Bar
          key={`bar-chart-${index}`}
          name={site}
          dataKey={site}
          fill={constants.GRAPH_COLOR_CODES[index]}
          hide={graphOpacity[site] !== 1}
        />,
      );
    });
    return barArr;
  };

  const renderXAxis = () => {
    return (
      <XAxis
        dataKey="timeStamp"
        tick={{ fill: 'black' }}
        allowDataOverflow
        tickFormatter={v => dateLabelFormatter(v, 'xAxis')}
      />
    );
  };
  const renderYAxis = () => {
    return (
      <YAxis
        axisLine={false}
        tick={{ fill: 'black' }}
        reversed={
          dataKey === 'GroundwaterDepthBelowSurfaceLevel' ||
          dataKey === 'BoreWaterLevelBelowMeasuringPt'
        }
        allowDataOverflow
        domain={['dataMin', 'dataMax']}
        tickFormatter={value => `${formatNumberDecimals(value, false, false, decimals)}`}
      />
    );
  };

  const renderLegend = () => {
    return (
      <Legend
        wrapperStyle={{
          bottom: 15,
          fontSize: 14,
          maxHeight: 75,
          overflowX: 'scroll',
          overflow: 'auto',
        }}
        align="left"
        iconSize={10}
        iconType="circle"
        formatter={value => {
          if (value.includes('-')) {
            return formatName(value);
          } else {
            return value;
          }
        }}
        onClick={value => onLegendClick(value)}
      />
    );
  };

  const renderBrush = () => {
    return <Brush height={25} />;
  };

  return (
    <>
      <div className={`${component}-header`}>
        <div className={`${component}-header-label`}>{`${formatLabel(
          dataKey,
          ' ',
        )} (${dataValue[0].unit.replace('(%)', '- %')})`}</div>
        {isRainfallData && !isEmpty(toggleOptions) && (
          <ToggleButton
            options={toggleOptions}
            onClick={e => setGraphType(e)}
            activeDefault={toggleOptions[0]}
          />
        )}
      </div>
      <ChartResponsiveContainer customHeight={{ desktop: 300, mobile: 400 }}>
        {isRainfallData ? (
          <BarChart width={850} data={dataValue} margin={chartMargin} barCategoryGap={0}>
            <CartesianGrid strokeDasharray="3 3" />
            {renderXAxis()}
            {renderYAxis()}
            <Tooltip
              labelFormatter={name => dateLabelFormatter(name, 'tooltip')}
              formatter={(value, name) => {
                return [
                  formatNumberDecimals(value, false, false, decimals) + dataValue[0].unit,
                  name,
                ];
              }}
            />
            {renderBarChart()}
            {renderLegend()}
            {renderBrush()}
          </BarChart>
        ) : (
          <LineChart width={850} data={dataValue} margin={chartMargin} className="supplychart">
            <CartesianGrid strokeDasharray="3 3" />
            {renderXAxis()}
            {renderYAxis()}
            <Tooltip
              labelFormatter={v => dateLabelFormatter(v, 'tooltip')}
              filterNull={true}
              formatter={(value, name) => {
                const formattedValue = formatNumberDecimals(value, false, false, decimals);
                if (name.includes('-')) {
                  return [
                    formattedValue + dataValue[0].unit.replace('Percentage (%)', '%'),
                    formatName(name),
                  ];
                } else {
                  return [formattedValue + dataValue[0].unit.replace('Percentage (%)', '%'), name];
                }
              }}
            />
            {renderLegend()}
            {renderLineChart()}
            {renderBrush()}
          </LineChart>
        )}
      </ChartResponsiveContainer>
    </>
  );
};

export default GraphAndDownloadGraphItem;
