export default Object.freeze({
  MURRAY_REGULATED_RIVER_WATER_SOURCE: 11904,
  MURRUMBIDGEE_REGULATED_RIVER_WATER_SOURCE: 11982,
  LACHLAN_REGULATED_RIVER_WATER_SOURCE: 11983,
  MACQUARIE_CUDGEGONG_RIVERS_WSID: 11984,
  GWYDIR_RIVER_WSID: 11985,
  LOWER_NAMOI_RIVER_WSID: 11986,
  LOWER_DARLING_REGULATED_RIVER_WATER_SOURCE: 12104,
  HUNTER_WSID: 12801,
  GREATER_SYDNEY: 12964,
  ALL_NSW: 12965,
  BORDER_RIVER_WSID: 14681,
  PEEL_RIVER_WSID: 15101,
  LACHLAN_FOLD_BELT_MDB_GROUNDWATER_WSID: 15630,
  SYDNEY_BASIN_CENTRAL_GROUNDWATER_WSID: 15870,
  SYDNEY_BASIN_NEPEAN_GROUNDWATER_WSID: 15872,
  BARWON_DARLING_WSID: 16001,
  FISH_RIVER_WATER_SOURCE: 16545,
  LOWER_MACQUIARIE_WSID: 16550,
  MARTHAGUY_CREEK_WSID: 16554,
  MEHI_RIVER_WSID: 16623,
  GINGHAM_WATERCOURSE_WSID: 16626,
  NEW_ENGLAND_FOLD_BELT_COAST_GROUNDWATER_WSID: 17812,
  SYDNEY_BASIN_NORTH_COAST_GROUNDWATER_WSID: 17815,
  LAKE_WYANGALA_ID: 412010,
  LAKE_CARGELLIGO_ID: 412107,
  LAKE_BREWSTER_ID: 412108,
  MENINDEE_LAKES_TOTAL_ID: 425049,
  LAKE_VICTORIA: 4261093,
  COCHRANE_DAM: 219033,
  GLENLYON_DAM: '416315A',
  LAKE_PAMAMAROO: '42510036',
  LAKE_COPI_HOLLOW: '42510037',
  OBERON_DAM: 421189,
});
