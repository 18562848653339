// Libraries
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

//context
import { MobileContext } from '../../contexts/MobileContext';

// Styles
import './dataFilters.scss';

// Components
import Expandbox from '../generalComponents/expandBox/ExpandBox';

const component = 'data-filters';

const DataFilters = ({
  items,
  onBtnClick,
  disabled,
  warningMsg,
  btnLabel,
  pageSource = '',
  targetModalId = '',
}) => {
  const { isMobile } = useContext(MobileContext);
  // Mini Component
  const renderFilter = filterItem => {
    return (
      <Expandbox
        key={filterItem.name}
        customHeaderContainerStyle={`${component}-item`}
        customArrowColor={`${component}-item-arrow`}
        customHeaderContent={filterItem.name}
        clickableSurface
        expandState={filterItem.open}
        handleExpandClick={state => {
          filterItem.setOpen(state);
        }}
        dropdown={true}
        customPage="archived"
      >
        <div className={`${component}-item-content`}>{filterItem.content}</div>
      </Expandbox>
    );
  };

  return (
    <>
      {isMobile && <div className={`${component}-title`}>SELECT PARAMETERS</div>}
      <div className={`${component}-container ${pageSource}`}>
        {items.map(item => renderFilter(item))}
        <button
          data-cy="archived-page-request-btn"
          className={`${component}-btn ${pageSource}`}
          onClick={onBtnClick}
          disabled={disabled}
          data-target={targetModalId}
          data-toggle="modal"
        >
          {btnLabel}
        </button>
        {warningMsg && <div className={`${component}-msg`}>{warningMsg}</div>}
      </div>
    </>
  );
};

export default DataFilters;

DataFilters.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      content: PropTypes.any,
      open: PropTypes.bool,
      setOpen: PropTypes.func,
    }),
  ),
  onBtnClick: PropTypes.func,
  disabled: PropTypes.bool,
  warningMsg: PropTypes.string,
  btnLabel: PropTypes.string,
  pageSource: PropTypes.string,
};
