// Libraries
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// Styles
import './mapLegend.scss';

// Components
import MapLegendLineItem from './MapLegendLineItem';
import MobileMapLegend from './MobileMapLegend';

// Context
import { MobileContext } from '../../contexts/MobileContext';

// Constant
const container = 'order-usage-map-legend';
export default function OrderUsageMapLegend({ legend }) {
  const { isMobile } = useContext(MobileContext);

  // component
  const desktopLegend = () => {
    return (
      <div className={`${container}`}>
        <div className="map-legend-space" />
        <div className={`map-legend`}>
          {legend.map((legend, index) => (
            <MapLegendLineItem
              key={index}
              strokeColor={legend.strokeColor}
              strokeWidth="7"
              legendText={legend.text}
            />
          ))}
        </div>
      </div>
    );
  };

  const mobileLegend = () => {
    return <MobileMapLegend legends={legend} />;
  };

  return <>{isMobile ? mobileLegend() : desktopLegend()}</>;
}

OrderUsageMapLegend.propTypes = {
  legend: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      strokeColor: PropTypes.string,
    }),
  ),
};
