import React, { useState, createContext } from 'react';
import { saveToLocalStorage } from '../helpers/LocalStorageHelper';

export const AppContext = createContext();

export default function AppContextProvider(props) {
  const [waterSource, updateWaterSource] = useState(() => {
    const localWaterSource = localStorage.getItem('curWaterSource');
    return localWaterSource && localWaterSource !== 'undefined' ? JSON.parse(localWaterSource) : {};
  });

  function setWaterSource(newWaterSource, history) {
    updateWaterSource(newWaterSource);
    saveToLocalStorage(newWaterSource, history);
  }

  return (
    <AppContext.Provider value={{ waterSource, setWaterSource }}>
      {props.children}
    </AppContext.Provider>
  );
}
