// Library
import React, { useState, useContext, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Cell } from 'recharts';
import { isEmpty } from 'lodash';

// Styles
//import "./waterBalanceGraph.scss";

// Components
import ChartContainer from '../chartProperties/ChartContainer';
import ChartTooltip from '../chartProperties/ChartTooltip';
import ChartResponsiveContainer from '../chartProperties/ChartResponsiveContainer';
import LicenceCategorySelector from '../allocation/LicenceCategorySelector';
import SelectionBtnsGroup from '../storage/childProperties/SelectionBtnsGroup';

// Assets
import config from '../../abstract/IntroText.json';

// Helpers
import { sumValuesFromMap } from '../../helpers/ArrayUtils';
import { createPayloadLicencedWaterBalance } from './ArrayUtilsAllocation';
import { currentFinancialYear } from '../../helpers/TimeUtils';
import { formatNumberDecimals } from '../../helpers/Utils';

// Context
import { MobileContext } from '../../contexts/MobileContext';

/* Constant */
const container = 'licenced-water-balance';
const chartColor = {
  consumptive: '#005daf',
  environmental: '#bed12a',
  consumptive2: '#4f81bd',
  environmental2: '#dbe686',
};

export default function LicencedWaterBalanceGraph({ data }) {
  const currYear = currentFinancialYear();
  const { isMobile } = useContext(MobileContext);
  const [chartData, setChartData] = useState(data);
  const [activeWaterYearData, setActiveWaterYearData] = useState();
  const [activeCategory, setActiveCategory] = useState('all');
  const [licCategoriesDropdown, setLicCategoriesDropdown] = useState();

  let showXAxis = chartData.length - 1;

  const barGroupDomainWaterAvailableLimit = chartData.map(i => Math.round(sumValuesFromMap(i)));

  const getVal = (obj, key, valueKey) => {
    const barKey = 'category_name';
    if (obj && obj[barKey] && obj[barKey] === key) {
      return obj[valueKey];
    }
    return null;
  };

  useLayoutEffect(() => {
    const waterYearData = data.find(yearItem => Number(yearItem.water_year) === Number(currYear));
    if (!isEmpty(waterYearData)) {
      setActiveWaterYearData(waterYearData);
      //Get the list of categories to populate the categories dropdown selector
      const dropdownList = Object.keys(waterYearData.resources).map(category => {
        return {
          id: category,
          category_shortname: category,
        };
      });
      setLicCategoriesDropdown(dropdownList);
      const payloadWaterBalance = createPayloadLicencedWaterBalance(
        waterYearData.resources,
        activeCategory,
      );
      setChartData(payloadWaterBalance);
    } else {
      setChartData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useLayoutEffect(() => {
    const waterYearData = data.find(yearItem => Number(yearItem.water_year) === Number(currYear));
    if (!isEmpty(waterYearData)) {
      setActiveWaterYearData(waterYearData);
      const payloadWaterBalance = createPayloadLicencedWaterBalance(
        waterYearData.resources,
        activeCategory,
      );
      setChartData(payloadWaterBalance);
    } else {
      setChartData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategory]);

  // Helpers
  const onCategoryChange = value => {
    if (value) {
      setActiveCategory(value);
    }
  };

  const renderCustomTooltip = payload => {
    return (
      <ChartTooltip payload={payload} isPayloadArray={false} titleKey="type" showTitle={false} />
    );
  };

  let usageSelectionBtn = activeWaterYearData && !isEmpty(activeWaterYearData.resources) && (
    <div className={`${container}-dropdown`}>
      <LicenceCategorySelector
        listOfCategories={licCategoriesDropdown}
        onCategoryChange={onCategoryChange}
        addAllOption={false}
      />
    </div>
  );

  return (
    <ChartContainer
      pageTitle={config.licencedWaterBalanceGraph.title}
      graphTitle={config.licencedWaterBalanceGraph.title}
      graphIntro={config.licencedWaterBalanceGraph.intro}
    >
      <SelectionBtnsGroup left={usageSelectionBtn} />
      <ChartResponsiveContainer
        customHeight={{ desktop: 600, mobile: 500 }}
        isEmptyChart={isEmpty(chartData)}
      >
        <BarChart
          data={chartData}
          layout={'horizontal'}
          margin={{
            bottom: showXAxis ? 15 : 0,
            top: 10,
            left: !isMobile ? 25 : 0,
          }}
          barSize={isMobile ? 20 : 140}
        >
          {!isMobile && <CartesianGrid strokeDasharray="3" horizontal={false} />}
          <YAxis
            interval={isMobile ? 'preserveStartEnd' : 'preserveEnd'}
            label={{
              value: 'Volume (GL)',
              angle: -90,
              dx: isMobile ? -25 : -35,
            }}
            type="number"
            domain={[0, Math.ceil(Math.max(...barGroupDomainWaterAvailableLimit))]}
            tickFormatter={v => formatNumberDecimals(v / 1000)}
            scale="linear"
          />

          <XAxis
            type="category"
            angle={-60}
            dy={70}
            height={150}
            dx={-30}
            dataKey={'type'}
            allowDecimals={false}
            interval={0}
            tick={{ fontSize: isMobile ? 12 : 14, width: isMobile ? 200 : 150 }}
          />
          <Tooltip content={e => renderCustomTooltip(e.payload)} cursor={{ fill: 'transparent' }} />
          <Bar dataKey="cumulative" stackId="a" fill="transparent" />
          <Bar
            dataKey={val => getVal(val, 'carryover', 'consumptive')}
            name={'Consumptive'}
            stackId="a"
            fill={chartColor.consumptive}
          />
          <Bar
            dataKey={val => getVal(val, 'carryover', 'environmental')}
            name={'Environmental'}
            stackId="a"
            fill={chartColor.environmental}
          />
          <Bar
            dataKey={val => getVal(val, 'water_allocated', 'consumptive')}
            name={'Consumptive'}
            stackId="a"
            fill={chartColor.consumptive}
          />
          <Bar
            dataKey={val => getVal(val, 'water_allocated', 'environmental')}
            name={'Environmental'}
            stackId="a"
            fill={chartColor.environmental}
          />
          <Bar
            dataKey={val => getVal(val, 'net_trading_volume', 'consumptive')}
            name={'Consumptive'}
            stackId="a"
            fill={chartColor.consumptive}
          >
            {chartData.map((entry, index) => {
              return (
                <Cell
                  key={`${entry} ${index}`}
                  fill={
                    entry.category_name === 'net_trading_volume' && entry.consumptive > 0
                      ? chartColor.consumptive
                      : chartColor.consumptive2
                  }
                />
              );
            })}
          </Bar>
          <Bar
            dataKey={val => getVal(val, 'net_trading_volume', 'environmental')}
            name={'Environmental'}
            stackId="a"
            fill={chartColor.environmental}
          >
            {chartData.map((entry, index) => {
              return (
                <Cell
                  key={`${entry} ${index}`}
                  fill={
                    entry.category_name === 'net_trading_volume' && entry.environmental > 0
                      ? chartColor.environmental
                      : chartColor.environmental2
                  }
                />
              );
            })}
          </Bar>
          <Bar
            dataKey={val => getVal(val, 'forfeited_volume', 'consumptive')}
            name={'Consumptive'}
            stackId="a"
            fill={
              chartData.category_name === 'forfeited_volume' && chartData.total_value > 0
                ? chartColor.consumptive
                : chartColor.consumptive2
            }
          />
          <Bar
            dataKey={val => getVal(val, 'forfeited_volume', 'environmental')}
            name={'Environmental'}
            stackId="a"
            fill={
              chartData.category_name === 'forfeited_volume' && chartData.environmental > 0
                ? chartColor.environmental
                : chartColor.environmental2
            }
          />
          <Bar
            dataKey={val => getVal(val, 'drought_suspension_volume', 'consumptive')}
            name={'Consumptive'}
            stackId="a"
            fill={
              chartData.category_name === 'drought_suspension_volume' && chartData.total_value > 0
                ? chartColor.consumptive
                : chartColor.consumptive2
            }
          />
          <Bar
            dataKey={val => getVal(val, 'drought_suspension_volume', 'environmental')}
            name={'Environmental'}
            stackId="a"
            fill={chartColor.environmental}
          />
          <Bar
            dataKey={val => getVal(val, 'adjustments', 'environmental')}
            name={'Environmental'}
            stackId="a"
            fill={
              chartData.category_name === 'adjustments' && chartData.total_value > 0
                ? chartColor.environmental
                : chartColor.environmental2
            }
          >
            {chartData.map((entry, index) => {
              return (
                <Cell
                  key={`${entry} ${index}`}
                  fill={
                    entry.category_name === 'net_trading_volume' && entry.environmental > 0
                      ? chartColor.environmental
                      : chartColor.environmental2
                  }
                />
              );
            })}
          </Bar>
          <Bar
            dataKey={val => getVal(val, 'adjustments', 'consumptive')}
            name={'Consumptive'}
            stackId="a"
            fill={
              chartData.category_name === 'adjustments' && chartData.total_value > 0
                ? chartColor.consumptive
                : chartColor.consumptive2
            }
          >
            {chartData.map((entry, index) => (
              <>
                <Cell
                  key={`${entry} ${index}`}
                  fill={
                    entry.category_name === 'adjustments' && entry.total_value > 0
                      ? chartColor.consumptive
                      : chartColor.consumptive2
                  }
                />
              </>
            ))}
          </Bar>
          <Bar
            dataKey={val => getVal(val, 'water_usage', 'consumptive')}
            name={'Consumptive'}
            stackId="a"
            fill={chartColor.consumptive2}
          />
          <Bar
            dataKey={val => getVal(val, 'water_usage', 'environmental')}
            name={'Environmental'}
            stackId="a"
            fill={chartColor.environmental2}
          />
          <Bar
            dataKey={val => getVal(val, 'water_available', 'consumptive')}
            name={'Consumptive'}
            stackId="a"
            fill={chartColor.consumptive}
          />
          <Bar
            dataKey={val => getVal(val, 'water_available', 'environmental')}
            name={'Environmental'}
            stackId="a"
            fill={chartColor.environmental2}
          />
        </BarChart>
      </ChartResponsiveContainer>
    </ChartContainer>
  );
}

LicencedWaterBalanceGraph.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      water_year: PropTypes.string,
      resources: PropTypes.array,
    }),
  ),
};
