// Libraries
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import { GeoJSON } from 'react-leaflet';
import { isEmpty } from 'lodash';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';

// Styles
import './riverSection.scss';

// Context
import { AppContext } from '../../contexts/AppContext';
import { MobileContext } from '../../contexts/MobileContext';

// Components
import OrderUsagePopup from '../mapPopup/OrderUsagePopup';
import Modal from '../modal/Modal';

// Helpers
import { promiseXMLHttpRequestMapService, queryEsri } from '../../helpers/MapServiceApiHelper';
import { getOrderUsageData, getRiverFlowRate } from '../../helpers/ApiHelper';
import { getDatesByPeriod } from '../../helpers/TimeUtils';

// Assets
import constants from '../../constants/Constants.jsx';
import mapConstnats from '../../constants/MapFilterConstants';

const { MAP_FILTERS_OPTIONS } = mapConstnats;
const riverSectionLayerId = 5;

export default function RiverSection({ type, selected, setShowSpinner, setThreshold }) {
  const { waterSource } = useContext(AppContext);
  const { isMobile } = useContext(MobileContext);
  const [mapData, setMapData] = useState(Array);
  const [orderData, setOrderData] = useState(Array);
  const [flowRateData, setFlowRateData] = useState(Array);
  const [flowRateThreshold, setFlowRateThreshold] = useState(Array);
  const [modalData, setModalData] = useState(null);
  const [modalFeature, setModalFeature] = useState(null);
  const isOrderUsage = type === MAP_FILTERS_OPTIONS['ORDER-USAGE'].type;
  const isFlowRate = type === MAP_FILTERS_OPTIONS['FLOW-RATE'].type;

  const from = getDatesByPeriod(-7);
  const datesArray = Array.from({ length: 15 }, (v, index) => {
    return { date: moment(from).add(index, 'days').format('YYYY-MM-DD') };
  });

  useEffect(() => {
    if (waterSource.water_source_name) {
      queryEsri(constants.NSW_MAP_SERVICE_BASE_URL + riverSectionLayerId, '1=1', function (result) {
        runQuery(result);
      });
    } else {
      setMapData([]);
    }

    async function runQuery(query) {
      setShowSpinner(true);
      let queryResult = await promiseXMLHttpRequestMapService(query);
      if (isOrderUsage) {
        await getOrderUsageData(waterSource.water_source_id, function (data) {
          const result = data ? data.resources : [{}];
          setOrderData(result);
        });
      }
      if (isFlowRate) {
        await getRiverFlowRate(waterSource.water_source_id, function (data) {
          const result = data ? data.resources : [];
          createThreshold(result);
          setFlowRateData(result);
        });
      }
      setShowSpinner(false);
      setMapData(queryResult);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, selected, waterSource.water_source_name]);

  const createThreshold = data => {
    if (!isEmpty(data)) {
      let min;
      let max;

      data.forEach(dataItem => {
        const { flow_rate } = dataItem;
        if (min === undefined && max === undefined) {
          min = flow_rate;
          max = flow_rate;
        } else if (flow_rate < min) {
          min = flow_rate;
        } else if (flow_rate > max) {
          max = flow_rate;
        }
      });
      min = min === 0 ? 200 : min;
      const diff = max - min;
      const quarterDiff = diff / 3;
      const value1 = Math.ceil(min / 100) * 100;
      const value2 = Math.ceil((min + quarterDiff) / 1000) * 1000;
      const value3 = Math.ceil((min + quarterDiff * 2) / 1000) * 1000;
      const value4 = Math.ceil(max / 1000) * 1000;
      setFlowRateThreshold([value1, value2, value3, value4]);
      setThreshold([value1, value2, value3, value4]);
    }
  };

  const setGeoJsonStyle = feature => {
    let resultStyle = {};
    if (isOrderUsage) {
      resultStyle = setOrderUsageStyle(feature);
    }

    if (isFlowRate) {
      resultStyle = setFlowRateStlye(feature);
    }

    return resultStyle;
  };

  const setOrderUsageStyle = feature => {
    let today = moment();
    let dayBefore = today.subtract(1, 'days').format('YYYY-MM-DD');

    const sectionData = !isEmpty(orderData)
      ? orderData.find(i => i.reach_id === feature.properties.CAIRO_SECTION_CODE)
      : {};
    const sectionDataByDate =
      !isEmpty(sectionData) && sectionData.values.find(i => i.date === dayBefore);
    const usageOrderDiff = sectionDataByDate
      ? sectionDataByDate.order - sectionDataByDate.usage
      : 0;
    let strokeColor = '#004ba3';
    if (usageOrderDiff === 0) {
      strokeColor = '#0087ad';
    } else if (usageOrderDiff < 0) {
      strokeColor = '#76dcf8';
    }

    return {
      weight: 6,
      color: strokeColor,
      dashArray: 0,
      lineCap: 'butt',
    };
  };

  const setFlowRateStlye = feature => {
    const sectionData = !isEmpty(flowRateData)
      ? flowRateData.find(i => i.reach_id === feature.properties.CAIRO_SECTION_CODE)
      : {};

    const thresholdIndex = flowRateThreshold.findIndex(
      threshold => sectionData?.flow_rate <= threshold,
    );
    return {
      weight: 4 * (thresholdIndex + 1),
      color: '#004ba3',
      dashArray: 0,
      lineCap: 'butt',
    };
  };

  const dataParser = data => {
    const result = datesArray.map(item => {
      const isExist = data.values.find(dataItem => dataItem.date === item.date);
      return {
        date: item.date,
        order: isExist ? isExist.order : 0,
        usage: isExist ? isExist.usage : 0,
      };
    });
    return result;
  };

  const onEachFeature = (feature, layer) => {
    if (feature) {
      const popupOptions = { className: 'order-usage-popup' };
      const data = orderData.find(data => data.reach_id === feature.properties.CAIRO_SECTION_CODE);
      if (data && !isEmpty(data)) {
        data.values = dataParser(data);
      }

      const popupContentHtml = ReactDOMServer.renderToString(
        <OrderUsagePopup
          data={data}
          feature={feature}
          dataStatusMsg="No data is currently available for this station."
        />,
      );
      if (isMobile) {
        layer.on('click', () => {
          setModalData(data);
          setModalFeature(feature);
          document.getElementById('river-section-modal-div').click();
        });
      } else {
        layer.bindPopup(popupContentHtml, popupOptions);
      }
    }
  };

  return (
    !isEmpty(mapData) &&
    (!isEmpty(orderData) || !isEmpty(flowRateData)) && (
      <div className="river-section">
        {isMobile && (
          <>
            <Modal
              id="river-section-modal"
              body={
                modalData &&
                modalFeature && (
                  <OrderUsagePopup
                    data={modalData}
                    feature={modalFeature}
                    dataStatusMsg="No data is currently available for this station."
                  />
                )
              }
            />
            <div
              id="river-section-modal-div"
              data-toggle="modal"
              data-target="#river-section-modal"
            />
          </>
        )}
        <GeoJSON
          data={mapData}
          style={feature => setGeoJsonStyle(feature)}
          onEachFeature={isOrderUsage ? onEachFeature : () => {}}
          renderer={L.svg({ padding: 1 })}
        />
      </div>
    )
  );
}

RiverSection.propTypes = {
  type: PropTypes.string,
  selected: PropTypes.bool,
  setShowSpinner: PropTypes.func,
};
