// Library
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

// Style
import './favouriteBtn.scss';

// Components
import StarBorderSVG from '../../image/icons/StarBorderSVG';
import StarFullSVG from '../../image/icons/StarFullSVG';

// Context
import { MiniAppContext } from '../../contexts/MiniAppContext';

// Constants
const component = 'favourite-btn';

export default function FavouriteBtn({ siteRemoved, setSiteRemoved, setSiteAdded }) {
  const { station } = useContext(MiniAppContext);
  const activeSite = JSON.parse(localStorage.getItem('currentSite'));
  const localFavList = localStorage.getItem('favouritesList');
  const [favList, setFavList] = useState(
    localFavList && localFavList !== 'undefined'
      ? JSON.parse(localStorage.getItem('favouritesList'))
      : [],
  );
  const [added, setAdded] = useState(false);

  // Life Cycle
  useEffect(() => {
    const isSiteAdded = favList
      ? favList.find(item => item.station_id === station.station_id)
      : undefined;
    if (isSiteAdded) {
      setAdded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [station.station_id]);

  useEffect(() => {
    if (siteRemoved) {
      const newFavList = favList.filter(item => item.station_id !== activeSite.station_id);
      localStorage.setItem('favouritesList', JSON.stringify(newFavList));
      setFavList(newFavList);
      setAdded(false);
      setSiteRemoved(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteRemoved]);

  const onBtnClicked = () => {
    if (!added) {
      const newList = favList;
      newList.push(activeSite);
      setFavList(newList);
      setAdded(true);
      localStorage.setItem('favouritesList', JSON.stringify(favList));
      setSiteAdded(true);
    }
  };

  return (
    <a
      className={component}
      onClick={() => onBtnClicked()}
      data-toggle={added ? 'modal' : ''}
      data-target={added ? '#removeSiteConfirm' : ''}
    >
      {added ? (
        <StarFullSVG customStyle={`${component}-icon`} />
      ) : (
        <StarBorderSVG customStyle={`${component}-icon`} />
      )}
      <div className={`${component}-link`}>{added ? `Remove from ` : `Add to `} favourites</div>
    </a>
  );
}

FavouriteBtn.propTypes = {
  siteRemoved: PropTypes.bool,
  setSiteRemoved: PropTypes.func,
  setSiteAdded: PropTypes.func,
};
