// Libraries
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// Styles
import './stateOverviewMapLegend.scss';

// Context
import { MapFilterContext } from '../../../contexts/MapFilterContext';

// Components
import ResourcesMapLegend from './ResourcesMapLegend';
import ClimateMapLegend from '../ClimateMapLegend';
import DroughtStatusMapLegend from '../DroughtStatusMapLegend';

// Constant
import mapFilterConstant from '../../../constants/MapFilterConstants';
const { RAINFALL, DROUGHT_STATUS, WATER_QUALITY } = mapFilterConstant.MAP_POPUP_ITEMS;
const container = 'state-overview-map-legend';

export default function StateOverviewMapLegend({ overviewType, rainfallDataType }) {
  const { filterSelected, filterProps } = useContext(MapFilterContext);

  const renderMapLegend = () => {
    switch (filterSelected) {
      case RAINFALL.name:
        return (
          <div className={`${container}-rainfall`}>
            <ClimateMapLegend
              climateType={rainfallDataType}
              legendTitle={filterProps.title}
              legendScale={filterProps.scale}
              legendScaleLabel={filterProps.scaleLabel}
              legendScaleColor={filterProps.scaleColor}
              colorBarClassName={filterProps.colorBarClassName}
            />
          </div>
        );
      case DROUGHT_STATUS.name:
        return (
          <div className={`${container}-drought-status`}>
            <DroughtStatusMapLegend />
          </div>
        );
      default:
        return <ResourcesMapLegend overviewType={overviewType} />;
    }
  };

  return (
    <div className={container}>{filterSelected !== WATER_QUALITY.name && renderMapLegend()}</div>
  );
}

StateOverviewMapLegend.propTypes = {
  overviewType: PropTypes.string,
  rainfallDataType: PropTypes.string,
};
