// Libraries
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Styles
import './hourlyUpdates.scss';

// Components
import HourlyUpdateItem from './HourlyUpdateItem';
import InfoButton from '../../informationButton/InformationButton';
import ExpandBox from '../../generalComponents/expandBox/ExpandBox';
import HourlyUpdatesGraph from './HourlyUpdatesGraph';

// Context
import { AppContext } from '../../../contexts/AppContext';

// Helper
import { getDatesByPeriod, getCurrentDate } from '../../../helpers/TimeUtils';
import { formatData } from '../../../helpers/WaterDataUtils';
import { sortArrayByKeyAscDateTime } from '../../../helpers/Utils';
import { fetchChartDamStorageData } from '../../storage/parserHelper';

// Constant
import apiConstant from '../../../constants/WaterDataAPIConstants';
import Constants from '../../../constants/Constants';

export default function HourlyUpdates({ data, station, collapsedIds, updateHourlyUpdateIds }) {
  const { waterSource } = useContext(AppContext);
  const container = 'hourly-updates';
  const siteId = station.station_id;
  let expand = !collapsedIds.includes(station?.station_id);
  const isGreaterSydney = waterSource.water_source_id === Constants.GREATER_SYDNEY_WSID;

  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    (async () => {
      if (siteId && expand) {
        const startDate = getDatesByPeriod(-1, apiConstant.API_DATE_FORMAT, 'days');
        const endDate = getCurrentDate(apiConstant.API_DATE_FORMAT);
        let data = await fetchChartDamStorageData(
          [station],
          'hourly',
          startDate,
          endDate,
          'TotalStorageVolume,ActiveStorageVolume,StorageInflow,StorageReleases',
          'AutoQC',
          isGreaterSydney,
        );
        data = formatData(data, false, false, '', 'siteId', station.dead_storage);
        setGraphData(sortArrayByKeyAscDateTime(data, 'timeStamp', apiConstant.API_DATE_FORMAT));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [station, expand, collapsedIds]);

  // mini component
  const header = () => {
    return (
      <div>
        Hourly Update
        <InfoButton targetDataId="#hourlyUpdatesModal" iconColor={`${container}-icon-info`} />
      </div>
    );
  };

  const toggleCollapse = identifier => {
    if (identifier === station?.station_id) {
      updateHourlyUpdateIds(identifier);
    }
  };

  return (
    <div className={`${container}-container`}>
      <ExpandBox
        customHeaderContainerStyle={expand ? `${container}-title` : `${container}-title-collapsed`}
        customHeaderContent={header()}
        expandState={expand}
        handleExpandClick={() => toggleCollapse(station?.station_id)}
        customArrowColor={`${container}-arrow`}
      >
        {
          <div className={`${container}-body`}>
            {data.map((item, index) => (
              <HourlyUpdateItem
                key={index}
                labelText={item.label}
                value={item.value}
                textUnit={item.unit}
                isUpdatedTime={item.label === 'updated time'}
                rounded={item.rounded}
                singleDecimal={
                  item?.propData ===
                    apiConstant.HYDROMETRIC_VARIABLES_MAP.ActiveStoragePercentage || false
                }
              />
            ))}
          </div>
        }
        <div className={`${container}-container-graph`}>
          <HourlyUpdatesGraph graphData={graphData} />
        </div>
      </ExpandBox>
    </div>
  );
}
HourlyUpdates.propTypes = {
  station: PropTypes.shape({
    lat: PropTypes.number,
    long: PropTypes.number,
    station_id: PropTypes.string,
    station_name: PropTypes.string,
  }),
  data: PropTypes.array,
  collapsedIds: PropTypes.array,
  updateHourlyUpdateIds: PropTypes.string,
};
