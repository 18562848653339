// Libraries
import { isArray, isEmpty, groupBy, uniqWith } from 'lodash';
import moment from 'moment';

// Helpers
import { mergeObjectsInUnique } from './ArrayUtils';
import { mergeByDate } from './Utils';

// Constants
import apiConstants from '../constants/WaterDataAPIConstants';
const WATER_DATA_VARIABLES = apiConstants.HYDROMETRIC_VARIABLES_MAP;

export const customizer = (objValue, srcValue) => {
  if (isArray(objValue)) {
    return objValue.concat(srcValue);
  }
};

export const processSurfaceWaterData = (data, varList) => {
  let processedResults = [];
  if (!isEmpty(data)) {
    data = data.map(resource =>
      resource.filter(item => {
        if (isEmpty(varList) || varList.includes(item.variableName)) {
          processedResults.push(item);
        }
      }),
    );
    processedResults = Object.entries(groupBy(processedResults, 'siteId')).map(([key, value]) => {
      let newResources = {};
      const grouped = mergeObjectsInUnique(value, 'variableName');
      grouped.map(item => {
        newResources[apiConstants.HYDROMETRIC_VARIABLES_MAP[item.variableName]] = item.value;
        newResources.date = item.timeStamp;
      });
      return { id: key, resources: [newResources] };
    });
  }
  return processedResults;
};

export const formatData = (
  data,
  multiSite = true,
  toDate = false,
  timeUnit,
  key = 'siteId',
  deadStorage = 0,
) => {
  const grouped = groupBy(data, key);
  let formatted = [];
  for (const groupedKey in grouped) {
    const groupedByDate = groupBy(grouped[groupedKey], 'timeStamp');
    const hasActiveStorage = grouped[groupedKey].find(
      item => item.variableName === 'ActiveStorageVolume',
    );
    for (const [keyByDate, valueByDate] of Object.entries(groupedByDate)) {
      const date = moment(keyByDate, 'DD-MMM-YYYY HH:mm');
      const timeStamp = timeUnit ? date.startOf(timeUnit).valueOf() : date.valueOf();
      const row = { timeStamp: toDate ? timeStamp : keyByDate };
      for (const item of valueByDate) {
        const keyName = multiSite
          ? item.variableName.includes('BelowSurface')
            ? item.siteId
            : item.uniqueSiteId
          : apiConstants.HYDROMETRIC_VARIABLES_MAP[item.variableName];
        if (!(hasActiveStorage && item.variableName === 'TotalStorageVolume')) {
          row[keyName] = item.value;
        }

        if (!hasActiveStorage && item.variableName === 'TotalStorageVolume') {
          const activeStorage = item.value - deadStorage;
          row[keyName] = activeStorage <= 0 ? 0 : activeStorage;
        }
        if (multiSite) row.unit = item.unitOfMeasure;
        if (multiSite) row.variable = item.variableName;
        if (!multiSite) row.siteId = item.siteId;
      }
      formatted.push(row);
    }
  }
  return formatted;
};

export const formatMetroDamStorageData = (data, variable, hydroKey, siteId) => {
  const grouped = groupBy(data, 'timeStamp');
  let formatted = [];
  for (const [key, value] of Object.entries(grouped)) {
    const row = { timeStamp: key };
    for (const item of value) {
      row.value = item[variable];
      row.unitOfMeasure = 'ML';
      row.variableName = hydroKey;
      row.siteId = siteId;
    }
    formatted.push(row);
  }
  return formatted;
};

// This function takes an array of objects containing a 'timeStamp' property, and returns the earliest timestamp as a Date object.
const findEarliestTimeStamp = data => {
  const earliestDate = data.reduce((earliest, current) => {
    const startDate = new Date(current.timeStamp.replace(/-/g, ' '));
    const currentTimestamp = startDate.getTime();
    return currentTimestamp < earliest ? currentTimestamp : earliest;
  }, Infinity);
  return new Date(earliestDate);
};

// This function takes an array of objects containing a 'timeStamp' property, and returns the latest timestamp as a Date object.
const findLatestTimeStamp = data => {
  const latestDate = data.reduce((latest, current) => {
    const startDate = new Date(current.timeStamp.replace(/-/g, ' '));
    const currentTimestamp = startDate.getTime();
    return currentTimestamp > latest ? currentTimestamp : latest;
  }, 0);
  return new Date(latestDate);
};

/**
 * Fills timestamp gaps in a data array for all siteIds at a given interval
 * @param {Array} data - An array of data objects, where each object has a timeStamp property and a siteId property
 * @param {Array} siteIds - An array of unique siteIds to fill timestamp gaps for
 * @param {string} interval - A string specifying the time interval for the filled timestamps (e.g., 'hourly', 'daily', etc.)
 * @returns {Array} An array of objects with filled timestamps and 0 values for any missing data points
 */
export const getTimeStampGaps = (data, siteIds, interval) => {
  const timeUnits = {
    '15-minute': 15,
    hourly: 60,
    daily: 24 * 60,
    monthly: 30 * 24 * 60,
    manual: 30 * 24 * 60,
  };
  // The reason to use UTC is  to disable Daylight Saving (DST) for moment date, as UTC has no DST
  const start = moment(findEarliestTimeStamp(data), apiConstants.API_DATE_FORMAT).utcOffset(
    '+10:00',
    true,
  );
  const end = moment(findLatestTimeStamp(data), apiConstants.API_DATE_FORMAT).utcOffset(
    '+10:00',
    true,
  );
  const timeUnit = timeUnits[interval];
  const gapData = [];
  for (let time = start; end.diff(time) > 0; time.add(moment.duration(timeUnit, 'minutes'))) {
    siteIds.forEach(siteId => {
      const specificEntity = data.find(
        entity =>
          entity.siteId === siteId &&
          time.format(apiConstants.API_DATE_FORMAT) === entity.timeStamp,
      );
      if (!specificEntity) {
        gapData.push({
          ...data[0],
          timeStamp: time.format(apiConstants.API_DATE_FORMAT),
          siteId,
          value: null,
        });
      }
    });
  }
  const unique = uniqWith(gapData, (arrObj, otherObj) => {
    return arrObj.timeStamp === otherObj.timeStamp && arrObj.siteId === otherObj.siteId;
  });
  return unique;
};

export const mergeSitesData = sitesData => {
  const mergedFilledData = sitesData.reduce((acc, curr) => {
    const existingData = acc.find(item => item.timeStamp === curr.timeStamp);
    if (existingData) {
      existingData[Object.keys(curr)[0]] = curr[Object.keys(curr)[0]];
    } else {
      acc.push(curr);
    }
    return acc;
  }, []);
  return mergedFilledData;
};

// map data from new api to old api for water data
export const dataMapFromWaterDataToStation = (data, includeRawData = false) => {
  const mapping = {
    ...WATER_DATA_VARIABLES,
    siteId: 'station_id',
    siteName: 'station_name',
  };
  const mappedArray = data.map(item => {
    const mappedLine = {
      date: moment(item.timeStamp, apiConstants.API_DATE_FORMAT).format(
        apiConstants.API_DATE_FORMAT,
      ),
    };
    Object.keys(mapping).forEach(key => {
      if (item[key] !== undefined) {
        if (!['siteId', 'siteName'].includes(key) && !includeRawData) {
          mappedLine.rawData = { ...item };
        }
        mappedLine[mapping[key]] = includeRawData ? { ...item } : item[key];
      }
    });
    return mappedLine;
  });
  return mappedArray;
};

export const formatRiverReportData = data => {
  let result = [];
  // eslint-disable-next-line no-unused-vars
  Object.entries(groupBy(data, 'variableName')).map(([variableKey, variableValue]) => {
    Object.entries(groupBy(variableValue, 'siteId')).map(([siteKey, siteValue]) => {
      const resources = dataMapFromWaterDataToStation(mergeByDate(siteValue, true), true);
      const target = result.findIndex(item => item.id === siteKey);
      if (target === -1) {
        result.push({ id: siteKey, resources });
      } else {
        result[target].resources = result[target].resources.map(itm => ({
          ...resources.find(item => item.date === itm.date && item),
          ...itm,
        }));
      }
    });
  });
  return result;
};

export const formatGroundwaterManualData = data => {
  let result = [];
  const formatted = data.map(item => {
    return { ...item, gwSiteId: `${item.siteId} Hole ${item.hole} Pipe ${item.pipe}` };
  });
  let gwSiteIds = [];
  Object.entries(groupBy(formatted, 'gwSiteId')).map(([dataKey, dataValue]) => {
    gwSiteIds.push(dataKey);
    dataValue.forEach(dataValueItem => {
      let exist = result.find(el => el.timeStamp === dataValueItem.timeStamp);
      if (exist) {
        exist[dataKey] = dataValueItem.value;
      } else {
        result.push({
          [dataKey]: dataValueItem.value,
          timeStamp: dataValueItem.timeStamp,
          unit: dataValueItem.unitOfMeasure,
          variable: dataValueItem.variableName,
        });
      }
    });
  });
  return result;
};
