/**
 * Sets the style of an DOM element
 */
export const setStyle = (element, style) => {
  let cssText = '';

  if (element.style.cssText) {
    cssText += element.style.cssText;
  }

  if (typeof style === 'string') {
    cssText += style;
  } else {
    for (let rule in style) {
      cssText += ''.concat(rule, ':').concat(style[rule], ';');
    }
  }
  element.style.cssText = cssText;
};

export const createElement = (tagname, attrs) => {
  let element = document.createElement(tagname);
  attrs = attrs || {};

  for (let k in attrs) {
    let v = attrs[k];
    if (k === 'style') {
      setStyle(element, v);
    } else {
      element[k] = v;
    }
  }
  return element;
};

/**
 * Add overlay layer to the page
 */
export const addOverlayLayer = targetElm => {
  const overlayLayer = createElement('div', { className: 'onboarding-overlay' });
  targetElm.appendChild(overlayLayer);
  return true;
};

/**
 * Iterates arrays and complete a function at the end
 */
export const forEach = (arr, forEachFnc, completeFnc) => {
  // in case arr is an empty query selector node list
  if (arr) {
    for (let i = 0, len = arr.length; i < len; i++) {
      forEachFnc(arr[i], i);
    }
  }
  if (typeof completeFnc === 'function') {
    completeFnc();
  }
};

/**
 * Get the screen dimensions
 */
export const getWinSize = () => {
  if (window.innerWidth !== undefined) {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  } else {
    const documentElement = document.documentElement;
    return {
      width: documentElement.clientWidth,
      height: documentElement.clientHeight,
    };
  }
};

/**
 * Set tooltip left so it doesn't go off the right side of the window
 */
export function checkRight(
  targetOffset,
  tooltipLayerStyleLeft,
  tooltipOffset,
  windowSize,
  tooltipLayer,
) {
  if (targetOffset.left + tooltipLayerStyleLeft + tooltipOffset.width > windowSize.width) {
    // off the right side of the window
    setStyle(tooltipLayer, {
      left: ''.concat(windowSize.width - tooltipOffset.width - targetOffset.left, 'px'),
    });
    return false;
  }
  setStyle(tooltipLayer, { left: ''.concat(tooltipLayerStyleLeft, 'px') });
  return true;
}

/**
 * Set tooltip right so it doesn't go off the left side of the window
 */
export function checkLeft(targetOffset, tooltipLayerStyleRight, tooltipOffset, tooltipLayer) {
  if (targetOffset.left + targetOffset.width - tooltipLayerStyleRight - tooltipOffset.width < 0) {
    // off the left side of the window
    setStyle(tooltipLayer, { left: ''.concat(-targetOffset.left, 'px') });
    return false;
  }
  setStyle(tooltipLayer, { right: ''.concat(tooltipLayerStyleRight, 'px') });
  return true;
}

/**
 * Remove an entry from a string array if it's there, does nothing if it isn't there.
 */
export const removeEntry = (stringArray, stringToRemove) => {
  if (stringArray.includes(stringToRemove)) {
    stringArray.splice(stringArray.indexOf(stringToRemove), 1);
  }
};

/**
 * Get the offset of the element
 */
export const getOffset = element => {
  const documentElement = document.documentElement;
  const scrollTop = documentElement.scrollTop;
  const scrollLeft = documentElement.scrollLeft;
  const x = element.getBoundingClientRect();
  let obj = { width: x.width, height: x.height };
  return Object.assign(obj, {
    top: x.top + scrollTop,
    left: x.left + scrollLeft,
  });
};

export const determineAutoPosition = (targetElement, tooltipLayer, desiredTooltipPosition) => {
  // Take a clone of position precedence. These will be the available
  const possiblePositions = ['bottom', 'top', 'right', 'left'].slice();

  const windowSize = getWinSize();
  const tooltipHeight = getOffset(tooltipLayer).height + 10;
  const tooltipWidth = getOffset(tooltipLayer).width + 20;
  const targetElementRect = targetElement.getBoundingClientRect(); // If we check all the possible areas, and there are no valid places for the tooltip, the element
  // must take up most of the screen real estate. Show the tooltip floating in the middle of the screen.

  let calculatedPosition = 'floating';
  /*
   * auto determine position
   */
  if (targetElementRect.bottom + tooltipHeight > windowSize.height) {
    removeEntry(possiblePositions, 'bottom');
  } // Check for space above

  if (targetElementRect.top - tooltipHeight < 0) {
    removeEntry(possiblePositions, 'top');
  } // Check for space to the right

  if (targetElementRect.right + tooltipWidth > windowSize.width) {
    removeEntry(possiblePositions, 'right');
  } // Check for space to the left

  if (targetElementRect.left - tooltipWidth < 0) {
    removeEntry(possiblePositions, 'left');
  }

  if (desiredTooltipPosition) {
    // should return 'bottom'
    desiredTooltipPosition = desiredTooltipPosition.split('-')[0];
  }

  if (possiblePositions.length) {
    if (possiblePositions.includes(desiredTooltipPosition)) {
      // If the requested position is in the list, choose that
      calculatedPosition = desiredTooltipPosition;
    } else {
      // Pick the first valid position, in order
      calculatedPosition = possiblePositions[0];
    }
  }

  return calculatedPosition;
};

/**
 * Exit from onboarding
 */
export const exitOnboarding = () => {
  localStorage.setItem('onboardingSkip', true);

  const overlayLayers = document.body.querySelectorAll('.onboarding-overlay');
  if (overlayLayers && overlayLayers.length) {
    forEach(overlayLayers, function (overlayLayer) {
      overlayLayer.parentNode.removeChild(overlayLayer);
    });
  }

  //remove all helper layers
  const helperLayer = document.body.querySelector('.onboarding-helperLayer');
  const referenceLayer = document.body.querySelector('.onboarding-tooltipReferenceLayer');
  setStyle(helperLayer, { opacity: '0' });
  window.setTimeout(function () {
    helperLayer.parentNode.removeChild(helperLayer);
  }, 400);
  referenceLayer.parentNode.removeChild(referenceLayer);
};
