// Library
import React, { useState, useContext, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend, LabelList } from 'recharts';
import { isEmpty, isEqual } from 'lodash';

// Styles
import './allocationGraph.scss';

// Components
import ChartContainer from '../chartProperties/ChartContainer';
import ChartLegend from '../chartProperties/AllocationChartLegend';
import ChartTooltip from '../chartProperties/ChartTooltip';
import ChartResponsiveContainer from '../chartProperties/ChartResponsiveContainer';
import InfoButton from '../informationButton/InformationButton';
import AppModalDealing from '../modal/Modal';
import DictionaryModalContent from '../modal/DictionaryModalContent';
import AllocationGraphModalData from '../modal/modalText/AllocationGraphModalData';
import SelectionBtnsGroup from '../storage/childProperties/SelectionBtnsGroup';
import HistoricalYearSelector from '../yearMultiSelector/HistoricalYearSelector';

// Assets
import config from '../../abstract/IntroText.json';

// Helpers
import {
  sumValuesFromMap,
  moveReservesLossesToEnd,
  sortAllocationGraph,
} from '../../helpers/ArrayUtils';
import {
  removeCategoryElement,
  groupDistributionsByCategory,
  sortArrayBasedOnAnotherArray,
} from './ArrayUtilsAllocation';
import { genListOfHistoricalYears, currentFinancialYear } from '../../helpers/TimeUtils';

// Context
import { MobileContext } from '../../contexts/MobileContext';
import { AppContext } from '../../contexts/AppContext';

export default function AllocationGraph({ data, noYearPicker = false, page = '' }) {
  const { waterSource } = useContext(AppContext);
  const { isMobile } = useContext(MobileContext);
  const [chartData, setChartData] = useState(data);
  const [activeYear, setActiveYear] = useState('actual');
  const [isActualYear, setIsActualYear] = useState(true);
  const [sortingArray, setSortingArray] = useState();
  const LICENCE_CATEGORY_RESERVES = 'Reserves';
  const LICENCE_CATEGORY_OUTFLOWS = 'Other outflows';
  const LICENCE_CATEGORY_SUPPLEMENTARY = 'Supplementary water';

  const getVal = (obj, key, priorYearKey) => {
    const barKey = priorYearKey ? 'water_available' : 'water_usage';
    if (obj.resources && obj.resources[key]) {
      return obj.resources[key][barKey];
    }
  };

  useLayoutEffect(() => {
    const year = activeYear === 'actual' ? currentFinancialYear() : activeYear;
    const financialYearData = data.find(yearItem => Number(yearItem.water_year) === Number(year));
    let sortedDistribGraphData = [];

    if (!isEmpty(financialYearData)) {
      //Parsing and decorating the distribution information for the allocation graph
      const groupedDistribGraphData = groupDistributionsByCategory(financialYearData.resources);
      sortedDistribGraphData = sortAllocationGraph(groupedDistribGraphData);

      if (activeYear === 'actual') {
        setSortingArray(sortedDistribGraphData);
      } else if (sortingArray) {
        const noReservesDistribGraphData = removeCategoryElement(
          groupedDistribGraphData,
          'category_shortname',
          LICENCE_CATEGORY_RESERVES,
        );
        const noOutflowsDistribGraphData = removeCategoryElement(
          noReservesDistribGraphData,
          'category_shortname',
          LICENCE_CATEGORY_OUTFLOWS,
        );
        //Remove the elements which contain inflow category within the resources
        const noInflowCategoriesAllocationData = noOutflowsDistribGraphData.filter(
          yearElement => !yearElement.resources.inflow,
        );

        sortedDistribGraphData = sortArrayBasedOnAnotherArray(
          sortingArray,
          noInflowCategoriesAllocationData,
          'category_shortname',
        );
      }
      const finalResultDistribGraphData = moveReservesLossesToEnd(sortedDistribGraphData);

      // Rempve water available for supplementary Task #79321
      const supplementary = finalResultDistribGraphData.find(
        item => item.category_shortname === LICENCE_CATEGORY_SUPPLEMENTARY,
      );
      delete supplementary?.resources?.consumptive?.water_available;
      delete supplementary?.resources?.environmental?.water_available;

      setChartData(finalResultDistribGraphData);
      setIsActualYear(activeYear !== 'actual' ? false : true);
    } else {
      setChartData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeYear, data]);

  const onYearChange = value => {
    if (value) {
      setActiveYear(value);
    }
  };

  const renderCustomizedLegend = payload => {
    const header = (
      <>
        <div className="legend-item-legend">
          <span>LEGEND</span>
          <InfoButton
            targetDataId="#allocationModalCenter"
            iconColor="info-button-dist-graph-legend"
          />
        </div>
        {
          <AppModalDealing
            id="allocationModalCenter"
            title="Legend"
            body={<DictionaryModalContent data={AllocationGraphModalData} />}
          />
        }
      </>
    );

    return <ChartLegend payload={payload} header={header} isMobile={isMobile} />;
  };

  const renderCustomTooltip = payload => {
    return (
      <ChartTooltip
        payload={filterEnv(payload).reverse()}
        isPayloadArray={false}
        titleKey="category_shortname"
      />
    );
  };

  const filterEnv = data => {
    const envUsed = 'Environmental used';
    const envAva = 'Environmental available';
    const environmental = data.filter(item => item.name === envUsed || item.name === envAva);
    const environmentalVal = environmental.reduce((acc, item) => {
      return item.value ? acc + item.value : acc;
    }, 0);
    let filteredData = data;
    if (!environmentalVal) {
      filteredData = filteredData.filter(item => item.name !== envUsed && item.name !== envAva);
    }

    return filteredData;
  };

  const getChartHeight = () => {
    let adjustment = isMobile ? 10 : 0;
    let height;
    switch (true) {
      case chartData.length === 1:
        height = 180;
        break;
      case chartData.length < 3:
        height = chartData.length * (100 + adjustment);
        break;
      case chartData.length < 5 && chartData.length > 2:
        height = chartData.length * (80 + adjustment);
        break;
      case chartData.length < 8 && chartData.length > 4:
        height = chartData.length * (60 + adjustment);
        break;
      default:
        height = chartData.length * (50 + adjustment);
        break;
    }
    return height;
  };

  const chartProps = [
    {
      name: 'Consumptive used',
      dataKey: 'consumptive',
      isPriorYearKey: !isActualYear,
      fillColor: '#004ba3',
      customizedLabel: isMobile,
      showBar: isActualYear,
    },
    {
      name: isActualYear ? 'Consumptive available' : 'Consumptive',
      dataKey: 'consumptive',
      isPriorYearKey: isActualYear,
      fillColor: isActualYear ? '#7FA9D2' : '#004ba3',
      customizedLabel: isMobile && !isActualYear,
      showBar: true,
    },
    {
      name: 'Environmental used',
      dataKey: 'environmental',
      isPriorYearKey: !isActualYear,
      fillColor: '#bed12a',
      customizedLabel: false,
      showBar: isActualYear,
    },
    {
      name: isActualYear ? 'Environmental available' : 'Environmental',
      dataKey: 'environmental',
      isPriorYearKey: isActualYear,
      fillColor: isActualYear ? '#DBE686' : '#bed12a',
      customizedLabel: false,
      showBar: true,
    },
    {
      name: 'Operational used',
      dataKey: 'operational',
      isPriorYearKey: !isActualYear,
      fillColor: '#ffc107',
      customizedLabel: false,
      showBar: isEqual(waterSource.water_source_type.sort(), ['REG']) && isActualYear,
    },
    {
      name: isActualYear ? 'Operational projected' : 'Operational',
      dataKey: 'operational',
      isPriorYearKey: isActualYear,
      fillColor: isActualYear ? '#FFDE8D' : '#ffc107',
      customizedLabel: false,
      showBar: isEqual(waterSource.water_source_type.sort(), ['REG']),
    },
  ];

  const barGroupDomainWaterAvailableLimit = chartData.map(i => Math.round(sumValuesFromMap(i)));

  return (
    <ChartContainer
      pageTitle={
        page === 'research' ? config.allocationGraph.historicalTitle : config.allocationGraph.title
      }
      graphIntro={
        isEqual(waterSource.water_source_type.sort(), ['REG'])
          ? config.allocationGraph.introREGWaterType
          : config.allocationGraph.introUNREGWaterType
      }
    >
      {!noYearPicker && (
        <SelectionBtnsGroup
          left={
            <HistoricalYearSelector
              listOfYears={genListOfHistoricalYears(data)}
              onBtnChange={onYearChange}
              yearSelected={activeYear}
              renderCurrentYear={true}
            />
          }
        />
      )}
      <ChartResponsiveContainer
        customHeight={{ desktop: getChartHeight() || 550, mobile: getChartHeight() || 400 }}
        isEmptyChart={isEmpty(chartData)}
      >
        <BarChart
          data={chartData}
          layout={'vertical'}
          margin={{
            bottom: isMobile ? 20 : 30,
            top: 10,
            left: !isMobile ? 25 : 0,
          }}
          barSize={25}
          stackOffset="sign"
        >
          {!isMobile && <CartesianGrid strokeDasharray="3" horizontal={false} />}
          <XAxis
            interval={isMobile ? 'preserveStartEnd' : 'preserveEnd'}
            label={{ value: 'Volume of water (GL)', dy: 23 }}
            type="number"
            dataKey={'water_available'}
            domain={[0, Math.ceil(Math.max(...barGroupDomainWaterAvailableLimit))]}
            tickFormatter={v => (v / 1000 <= 1 ? Math.round(v) / 1000 : Math.round(v / 1000))}
            scale="linear"
          />
          <YAxis
            tick={isMobile ? false : true}
            type="category"
            opacity={isMobile ? 0 : 1}
            style={{ display: isMobile ? 'none' : 'block' }}
            width={isMobile ? 0 : 200}
            dx={isMobile ? 0 : -20}
            dataKey={'category_shortname'}
            allowDecimals={false}
            interval={0}
          />
          <Tooltip content={e => renderCustomTooltip(e.payload)} cursor={{ fill: 'transparent' }} />
          {chartProps.map(
            item =>
              item.showBar && (
                <Bar
                  key={item.name}
                  dataKey={val => getVal(val, item.dataKey, item.isPriorYearKey)}
                  name={item.name}
                  stackId="a"
                  fill={item.fillColor}
                >
                  {item.customizedLabel && (
                    <LabelList
                      dx={-5}
                      dy={-20.9}
                      width={300}
                      dataKey={'category_shortname'}
                      position="insideLeft"
                    />
                  )}
                </Bar>
              ),
          )}
          <Legend
            wrapperStyle={{
              position: 'relative',
              bottom: isMobile ? '60px' : window.innerWidth < 1300 ? '50px' : '30px',
            }}
            content={e => renderCustomizedLegend(e.payload)}
          />
        </BarChart>
      </ChartResponsiveContainer>
    </ChartContainer>
  );
}

AllocationGraph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  noYearPicker: PropTypes.bool,
  page: PropTypes.string,
};
