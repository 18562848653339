// Library
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isEmpty, isString } from 'lodash';

// Styles
import './updateItem.scss';

// components
import Link from '../generalLink/GeneralLink';

// Context
import { MobileContext } from '../../contexts/MobileContext';

// Helpers
import { createMarkup } from '../../helpers/Utils';

export default function UpdateItem(props) {
  const { isMobile } = useContext(MobileContext);

  var divElement = document.createElement('div');
  divElement.innerHTML = props.content
    .replaceAll('h3', 'p')
    .replaceAll('h2', 'p')
    .replaceAll('h1', 'p');
  var elements = divElement.getElementsByTagName('*');

  for (var i = 0; i < elements.length; i++) {
    const styleAttr = elements[i].getAttribute('style') || '';
    //remove the style attribute if it contains font-size property
    if (styleAttr.includes('font-size') || styleAttr.includes('margin')) {
      elements[i].removeAttribute('style');
    }
  }
  const content = divElement.innerHTML;

  const getUpdateType = () => {
    switch (props.page) {
      case 'nswUpdates':
        return props.type && props.type;
      case 'restrictions':
        return props.waterSource.water_source_name;
      case 'tabUpdates':
        return props.type;
      case 'archivedUpdates':
        return props.type;
      default:
        return '';
    }
  };

  const itemTypeDesktop = () => {
    return (
      <div
        className={
          props.page === 'archivedUpdates'
            ? `update-item-type-div--archive`
            : `update-item-type-div`
        }
      >
        {!isEmpty(props.type) && props.type !== 'Water Quality' && (
          <div className="update-item-type">{getUpdateType()}</div>
        )}
        {props.component !== 'rules' && props.date && (
          <div className={`update-item-date ${props.page}`}>
            {moment(props.date, 'YYYY-MM-DD').format('DD MMM YYYY')}
          </div>
        )}
      </div>
    );
  };
  const itemTypeMobile = () => {
    return (
      <>
        {!isEmpty(props.type) && props.type !== 'Water Quality' && (
          <div className="update-item-type">{getUpdateType()}</div>
        )}
        {props.component !== 'rules' && props.date && (
          <div className={`update-item-date ${props.page}`}>
            {moment(props.date, 'YYYY-MM-DD').format('DD MMM YYYY')}
          </div>
        )}
      </>
    );
  };

  const restrictContent = (content, sizeRestriction) => {
    if (!content && !isString(content)) return content;
    const contentArr = content.split(' ');
    const stringLength = contentArr.length;
    return sizeRestriction < stringLength
      ? contentArr.slice(0, sizeRestriction).join(' ') + '... '
      : content;
  };

  return (
    <div className={`update-item`}>
      <div className="update-item-title-div">
        <div className="update-item-title">{props.title}</div>
        {props.component === 'rules' && props.date && (
          <div className={`update-item-issuedDate ${props.page}`}>
            Issued {moment(props.date, 'YYYY-MM-DD').format('Do MMMM YYYY')}
          </div>
        )}
      </div>
      {isMobile ? itemTypeMobile() : itemTypeDesktop()}
      <div
        className="update-item-content"
        dangerouslySetInnerHTML={createMarkup(
          props.contentWordsCount >= 0 && props.shortenBody
            ? restrictContent(content, props.contentWordsCount)
            : content,
        )}
      />
      {props.link && (
        <Link
          title={props.linkTitle}
          link={props.link}
          onLinkClick={() => props.onExternalSiteClick(props.link)}
          targetId={`#externalLinkNSWUpdates${props.targetId}`}
        />
      )}
    </div>
  );
}

UpdateItem.propTypes = {
  page: PropTypes.string,
  waterSource: PropTypes.object,
  onExternalSiteClick: PropTypes.func,
  type: PropTypes.string,
  component: PropTypes.string,
  date: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
  content: PropTypes.string,
  linkTitle: PropTypes.string,
  targetId: PropTypes.string,
  contentWordsCount: PropTypes.number,
};
