// Libraries
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isEmpty, toString } from 'lodash';

// Styles
import './waterQualityPopup.scss';
import './MapPopup.scss';

// Context
import { AppContext } from '../../contexts/AppContext';
import { MapContext } from '../../contexts/MapContext';
import { MobileContext } from '../../contexts/MobileContext';

// Component
import ViewDetailsLink from './viewDetailsLink/ViewDetailsLink';
import Loader from '../loader/Loader';
import ChartToDownloadPageLink from '../chartProperties/ChartToDownloadPageLink';
import UpdatedDate from './miniComponents/UpdatedDate';
import PopupBody from './miniComponents/PopupBody';

// Helpers
import { formatNumberDecimals, createMarkup } from '../../helpers/Utils';
import { getLatestSurfaceWaterData } from '../../helpers/WaterDataApiHelper';

// Constants
import constants from '../../constants/Constants.jsx';
import hydroDataConstant from '../../constants/HydrometricsConstants';
const { riverData } = constants.TAB_OPTIONS;
const component = 'water-quality-popup';
const STATUS_TEXT = {
  0: 'Green monitor',
  1: 'Amber alert',
  2: 'Red action',
};

export default function WaterQualityPopup({ data, type, timestamp, openPopup }) {
  const isAlgal = type === 'algal';
  const properties = data.properties;
  const station = data.station_id;
  const { waterSource } = useContext(AppContext);
  const { markerSelected } = useContext(MapContext);
  const { isMobile } = useContext(MobileContext);

  // State
  const [popupData, setPopupData] = useState();
  const [initialised, setInitialised] = useState(false);
  const [dataStatusMsg, setDataStatusMsg] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);

  // Life Cycle
  useEffect(() => {
    if (!initialised && !isAlgal && markerSelected && markerSelected.station_id === station) {
      try {
        (async () => {
          setShowSpinner(true);
          setDataStatusMsg('Loading...');
          const variables = [
            ...new Set([
              ...data.water_data_variables,
              'Conductivity',
              'WaterTemperature',
              'FlowRate',
            ]),
          ];
          let stationData = await getLatestSurfaceWaterData(
            toString(markerSelected.id),
            type,
            variables.join(','),
          );
          setPopupData(stationData);
          if (!isEmpty(stationData)) {
            setInitialised(true);
            setDataStatusMsg('');
          } else {
            setDataStatusMsg('No data is currently available for this station.');
          }
        })();
      } catch (error) {
        console.error(error);
      } finally {
        setShowSpinner(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timestamp, openPopup, markerSelected]);

  const checkValue = (value, decimals) => {
    return value || value === 0 ? formatNumberDecimals(value, false, false, decimals) : '-';
  };

  const prevResultsIcon = data => {
    return (
      <>
        {[1, 2, 3, 4, 5, 6].map(item => (
          <div className={`${component}-prev-results-icons-item-${data[`x${item}`]}`} key={item}>
            <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
              <circle cx="5" cy="5" r="5" />
            </svg>
          </div>
        ))}
      </>
    );
  };

  // Content
  const dataPropsAlgal = [
    {
      type: 'Current Algal level',
      data:
        properties && STATUS_TEXT[properties.current_status]
          ? STATUS_TEXT[properties.current_status]
          : '-',
      class: properties && `${component}-type-${properties.current_status}`,
    },
    {
      type: 'Previous Algal level (Last 6 results)',
      child: properties && prevResultsIcon(properties),
      class: `${component}-prev-results`,
    },
    {
      type: 'Potentially toxic bio',
      data: properties && checkValue(properties.current_tox_bio),
      unit: 'mm³/L',
    },
    {
      type: 'Potentially toxic count',
      data: properties && checkValue(properties.current_tox_count),
      unit: 'cells/mL',
    },
    {
      type: 'Cyanobacteria bio',
      data: properties && checkValue(properties.current_cyan_bio),
      unit: 'mm³/L',
    },
    {
      type: 'Cyanobacteria count',
      data: properties && checkValue(properties.current_cyan_count),
      unit: 'cells/mL',
    },
    {
      type: 'Dominant toxic species',
      data: properties && `<i>${properties.dom_tox !== null ? properties.dom_tox : '-'}</i>`,
    },
    {
      type: 'Date last sampled',
      data: properties && moment(properties.first_date).format('DD MMM YYYY'),
    },
  ];

  const dataPropsDO = hydroDataConstant.DATA_LABEL_FORMAT;

  const algalPopup = () => {
    return dataPropsAlgal.map((row, index) => (
      <div key={index} className={`${component}-content-algal ${row.class ? row.class : ''}`}>
        <div>{row.type}</div>
        {row.data && (
          <div
            className={`${component}-content-algal-value`}
            dangerouslySetInnerHTML={createMarkup(`${row.data} ${row.unit ? row.unit : ''}`)}
          />
        )}
        {row.child && <div className={`${component}-prev-results-icons`}>{row.child}</div>}
      </div>
    ));
  };

  const doPopup = () => {
    return (
      !isEmpty(popupData) && (
        <>
          {Object.keys(popupData[0])
            .sort()
            .map(
              (dataKey, index) =>
                dataPropsDO[dataKey] && (
                  <PopupBody
                    key={index}
                    title={dataPropsDO[dataKey].name}
                    data={popupData[0][dataKey]}
                    unit={dataPropsDO[dataKey].unit}
                    isSameDate={popupData[0][dataKey].timeStamp === popupData[0].date}
                    dataKey={dataKey}
                    decimals={dataPropsDO[dataKey].decimals}
                  />
                ),
            )}
          <UpdatedDate date={popupData[0].date} />
          <div id={`${component}-view-details`} className={`${component}-view-details`}>
            {constants.EXCLUDED_SITES.includes(data.station_id)
              ? !isMobile && (
                  <ChartToDownloadPageLink
                    siteId={station}
                    siteType="gauge"
                    interval="15-minute"
                    showIcon={false}
                    dataType="autoqc"
                    label="View more data"
                    timePeriod="7"
                    hydrometric="SPECIAL"
                  />
                )
              : waterSource.water_source_id !== constants.GREATER_SYDNEY_WSID && (
                  <ViewDetailsLink
                    elementId={station}
                    activeTab={riverData.text}
                    pathname={riverData.link}
                  />
                )}
          </div>
        </>
      )
    );
  };

  return (
    <div className={`map-popup-container ${component}`}>
      {showSpinner && !isAlgal && <Loader />}
      <div className={`${component}-title`}>
        {`${properties ? properties.site_name : data.station_name} (${
          properties ? properties.site_code : station
        })`}
      </div>
      <div className={`${component}-msg`}>{!isAlgal && dataStatusMsg}</div>
      {isAlgal ? algalPopup() : doPopup()}
    </div>
  );
}

WaterQualityPopup.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string,
  timestamp: PropTypes.string,
};
