// Libraries
import React, { useContext } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

// Styles
import './eventItem.scss';

// Assets
import locationIcon from '../../image/icons/locator_filled.png';

// Context
import { MapContext } from '../../contexts/MapContext';

// Helpers
import { createMarkup } from '../../helpers/Utils';

export default function EventItem(props) {
  const { toggleMarker } = useContext(MapContext);
  return (
    <div className="event-item">
      <div className="event-item-content-box">
        <div className="event-item-content">
          <div className="event-item-title">{props.title}</div>
          <div className="event-item-subdate">
            {moment(props.date, 'YYYY-MM-DD').format('DD MMM YYYY')}
          </div>
          {props.location && (
            <div className="event-item-location">
              <img src={locationIcon} className="event-item-icon" alt="Location pin icon" />
              <a
                onClick={() => {
                  toggleMarker(props, 'events');
                  window.scrollTo(0, 0);
                }}
              >
                {props.location}
              </a>
            </div>
          )}
          {props.body && (
            <div dangerouslySetInnerHTML={createMarkup(props.body)} className="event-item-body" />
          )}
          {props.link && (
            <div className="event-item-details">
              <a
                className="event-item-link"
                href={props.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                View details
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

EventItem.propTypes = {
  body: PropTypes.string,
  date: PropTypes.string,
  lat: PropTypes.number,
  long: PropTypes.number,
  link: PropTypes.string,
  location: PropTypes.string,
  station_id: PropTypes.any,
  title: PropTypes.string,
};
