// Library
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

// Style
import './generalDropdown.scss';

// Component
import ExpandBox from '../generalComponents/expandBox/ExpandBox';

// Constant
const container = 'general-dropdown';
const MULTIPLE_SELECTION_HEADER = 'MULTIPLE SELECTION';

const GeneralDropdown = ({
  menuItem,
  onItemClick,
  selectedItem,
  buttonStyle,
  menuStyle,
  menuItemStyle,
  customArrowColor,
  multipleSelection,
  disabled = false,
  borderClass,
}) => {
  const [selected, setSelected] = useState('');
  const [multipleSelected, setMultipleSelected] = useState([]);
  const [listOpen, setListOpen] = useState(false);

  useEffect(() => {
    setSelected(selectedItem);
    if (multipleSelected) {
      let listItems = [];
      if (selectedItem) {
        listItems.push(selectedItem.name);
        setMultipleSelected(listItems);
      }
    }
    window.addEventListener('click', closeList);

    return () => {
      window.removeEventListener('click', closeList);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  const closeList = () => {
    setListOpen(false);
  };

  const setSelectedItem = item => {
    if (item) {
      let listItemsSelected = multipleSelected;
      let headerText = '';

      if (!listItemsSelected.includes(item)) {
        listItemsSelected.push(item);
        headerText = item;
        if (item === selectedItem.name) {
          listItemsSelected = [];
        } else {
          listItemsSelected = listItemsSelected.filter(listItem => listItem !== selectedItem.name);
        }
      } else {
        if (item !== selectedItem.name) {
          listItemsSelected = listItemsSelected.filter(listItem => listItem !== item);
        } else {
          listItemsSelected = [selectedItem.name];
          headerText = selectedItem.name;
        }
      }
      if (isEmpty(listItemsSelected)) {
        listItemsSelected = [selectedItem.name];
        headerText = selectedItem.name;
      }

      if (listItemsSelected.length > 1) {
        headerText = MULTIPLE_SELECTION_HEADER;
      } else {
        headerText = listItemsSelected[0];
      }
      setSelected(headerText);
      setMultipleSelected(listItemsSelected);
    }
  };

  return (
    <div className={`${container}`}>
      <ExpandBox
        customHeaderContent={
          <div className="expandBox-title-container-text">
            {selected && (selected.name || selected.id || (multipleSelection && selected))}
          </div>
        }
        customHeaderContainerStyle={
          buttonStyle
            ? `${container}-button ${buttonStyle}`
            : `${container}-button ${
                menuItem.length < 2 ? `${container}-button-disabled` : `${container}-button-enabled`
              }`
        }
        borderClass={borderClass}
        expandState={listOpen}
        handleExpandClick={state => {
          setListOpen(state);
        }}
        disabled={disabled || menuItem.length < 2}
        clickableSurface
        customArrowColor={customArrowColor}
      >
        <div className={menuStyle ? menuStyle : `${container}-menu`}>
          {menuItem.map(item => {
            if (item === selected && !multipleSelection) {
              return null;
            }
            return (
              <div
                key={item.id}
                onClick={e => {
                  multipleSelection ? setSelectedItem(item.name) : setSelected(item);
                  setListOpen(false);
                  onItemClick(item, e);
                }}
                className={
                  menuItemStyle
                    ? `${container}-menu-item ${menuItemStyle}`
                    : `${container}-menu-item${
                        multipleSelected.includes(item.name) ? '-selected' : ''
                      }`
                }
                id={`${container}-menu-item`}
              >
                {multipleSelection ? (
                  <div className={`grid-container`}>
                    <div className="grid-item">
                      <input
                        readOnly
                        className="checkbox"
                        type="checkbox"
                        checked={multipleSelected.includes(item.name) ? true : false}
                      />
                    </div>
                    <div className="grid-item">
                      <span className="checkmark">{item.name}</span>
                    </div>
                  </div>
                ) : (
                  item.name || item.id
                )}
              </div>
            );
          })}
        </div>
      </ExpandBox>
    </div>
  );
};

export default GeneralDropdown;

GeneralDropdown.propTypes = {
  /**
   * Items within the list
   * Must be array of object that has name and id attribute
   */
  menuItem: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
  /**
   * Clickevent for each item in the list
   * It will pass item id to this function
   */
  onItemClick: PropTypes.func,
  /**
   * The item that is shown on the button
   */
  selectedItem: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  /**
   * Styling for button
   */
  buttonStyle: PropTypes.string,
  /**
   * Styling for dropdown container
   */
  menuStyle: PropTypes.string,
  /**
   * Styling for each individual items
   */
  menuItemStyle: PropTypes.string,
  /**
   * Styling for arrow icon
   */
  customArrowColor: PropTypes.string,
  /**
   * Boolean allowing multiple selection
   */
  multipleSelection: PropTypes.bool,
  /**
   * Boolean allowing dropdown click event
   */
  disabled: PropTypes.bool,
};
