// Library
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNil } from 'lodash';
import { sortArrayByKeyAsc } from '../../helpers/Utils';

// Styles
import './planSearchResult.scss';

// Context
import { MobileContext } from '../../contexts/MobileContext';

// Component
import ExpandBox from '../generalComponents/expandBox/ExpandBox';
import ExternalLinkModal from '../../components/modal/ExternalLinkModal';

const CONTAINER = 'plan-search-result';
const STATUS = {
  fail: 'fail',
  empty: 'empty',
  success: 'success',
  mobileEmpty: 'mobileEmpty',
};

const PlanSearchResult = ({ results = [], searchKeywords = '' }) => {
  const [sortedSections, setSortedSections] = useState([]);
  const { isMobile } = useContext(MobileContext);
  const [externalSiteLink, setExternalSiteLink] = useState('');

  // Life Cycle
  useEffect(() => {
    const sorted = sortArrayByKeyAsc(results, 'title');
    const filtered = filterResultsBasedOnInput(sorted, searchKeywords);
    const sections = categoriseResults(filtered);
    setSortedSections(sections);
  }, [results, searchKeywords]);

  // Helpers
  // Categorise results into section
  const categoriseResults = results => {
    const fin = [];
    const sections = {};
    let sectionCount = 0;

    // Checking for type
    if (!Array.isArray(results)) {
      return [];
    }

    // Split the results into section
    results.forEach(result => {
      if (isNil(result.title)) {
        return;
      }
      const firstLetter = result.title.slice(0, 1);
      if (Object.prototype.hasOwnProperty.call(sections, firstLetter)) {
        const section = sections[firstLetter];
        fin[section].plans.push(result);
      } else {
        sections[firstLetter] = sectionCount;
        fin.push({ sectionTitle: firstLetter, plans: [result] });
        sectionCount++;
      }
    });

    return fin;
  };

  // Filter out the results based on input
  const filterResultsBasedOnInput = (arr, keywords) => {
    // Checking for type
    if (!Array.isArray(arr)) {
      return [];
    }

    const fin = [];

    arr.forEach(element => {
      let re = new RegExp(keywords, 'gi');
      if (keywords && !element.title.match(re)) {
        return;
      }
      fin.push(element);
    });

    return fin;
  };

  // Determine the current status
  // Status is used for deciding which component to render
  const statusChecking = (res, sections, mobile, keyword) => {
    switch (true) {
      case isEmpty(res):
        return STATUS.fail;
      case isEmpty(sections):
        return STATUS.empty;
      case Boolean(keyword) || !mobile:
        return STATUS.success;
      case mobile && !keyword:
        return STATUS.mobileEmpty;
      default:
        return STATUS.fail;
    }
  };

  // Components
  const failMsg = () => {
    return (
      <div className={`${CONTAINER}-fail`}>
        <div className={`${CONTAINER}-fail-title`}>Failed to Load</div>
        <div className={`${CONTAINER}-fail-msg`}>
          We have encountered some issues. Please refresh the page and try again later.
        </div>
        <button className={`${CONTAINER}-fail-reload`} onClick={() => window.location.reload()}>
          Refresh Page
        </button>
      </div>
    );
  };

  const resultsArr = () => {
    return sortedSections.map(section => (
      <div className={`${CONTAINER}-container`} key={`section ${section.sectionTitle}`}>
        <p className={`${CONTAINER}-section-header`}>{section.sectionTitle}</p>
        {section.plans.map(plan => {
          if (plan.url) {
            return (
              <a
                key={plan.title}
                href={plan.url}
                className={`${CONTAINER}-plans`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {plan.title}
              </a>
            );
          } else {
            return (
              <p key={plan.title} className={`${CONTAINER}-plans ${CONTAINER}-disabled`}>
                {plan.title}
              </p>
            );
          }
        })}
      </div>
    ));
  };

  const mobileResultArray = () => {
    return sortedSections.map(section => (
      <div className={`${CONTAINER}-container`} key={`section ${section.sectionTitle}`}>
        <ExpandBox
          customHeaderContent={
            <p className={`${CONTAINER}-mobile-header`}>{section.sectionTitle}</p>
          }
          customHeaderContainerStyle={`${CONTAINER}-section-header`}
          mobileOnly
        >
          {section.plans.map(plan => {
            if (plan.url) {
              return (
                <a
                  key={plan.title}
                  href={plan.url}
                  className={`${CONTAINER}-plans`}
                  rel="noopener noreferrer"
                  target="_blank"
                  data-toggle="modal"
                  data-target="#externalLinkWSP"
                  onClick={() => setExternalSiteLink(plan.url)}
                >
                  {plan.title}
                </a>
              );
            } else {
              return (
                <p key={plan.title} className={`${CONTAINER}-plans ${CONTAINER}-disabled`}>
                  {plan.title}
                </p>
              );
            }
          })}
        </ExpandBox>
        <ExternalLinkModal link={externalSiteLink} modalId="externalLinkWSP" />
      </div>
    ));
  };

  const conditionalRendering = status => {
    switch (status) {
      case STATUS.fail:
        return failMsg();
      case STATUS.success:
        return resultsArr();
      case STATUS.empty:
        return <div className={`${CONTAINER}-text`}>Your search returned no results</div>;
      case STATUS.mobileEmpty:
        return mobileResultArray();
      default:
        return failMsg();
    }
  };

  return (
    <div className={`${CONTAINER}`}>
      {conditionalRendering(statusChecking(results, sortedSections, isMobile, searchKeywords))}
    </div>
  );
};

export default PlanSearchResult;

PlanSearchResult.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  searchKeywords: PropTypes.string,
};
