/* Library */
import React, { useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';

/* Style */
import './allocation.scss';

/* Component */
import ExpandBox from '../generalComponents/expandBox/ExpandBox';
import AllocationContent from './AllocationInfoContent';

/* Assest */
import { TabsContext } from '../../contexts/TabsContext ';

/* Helper */
import { formatNumberDecimals } from '../../helpers/Utils';
import { detailsInfoParser } from './ArrayUtilsAllocation';

const infoComponent = `allocation-info`;

export default function AllocationMobile({ allocationData, rulePathName, onItemClick }) {
  const [selectedRow, setSelectedRow] = useState('');
  const [detailsInfo, setDetailsInfo] = useState(allocationData[0]);
  const { setPositionCenter } = useContext(TabsContext);
  const detailsRef = useRef([]);
  const headerRef = useRef([]);

  // Helper
  const setItemsOnClick = (expanded, id, info, index) => {
    if (expanded) {
      setSelectedRow(id);
      const parsedInfo = detailsInfoParser(info);
      setDetailsInfo(parsedInfo);
      onItemClick(index);
    } else {
      setSelectedRow('');
      setDetailsInfo('');
    }
  };

  // Components
  const headerMaker = (row, index) => {
    return (
      <div
        ref={element => (headerRef.current[index] = element)}
        className={`allocation-row ${
          selectedRow === `allocation_row_${index}` ? `allocation-header-selected` : ``
        }`}
      >
        <div className={`${infoComponent}-title row-title`}>
          <span>{row.category_shortname}</span>
        </div>
        <div className={`${infoComponent}-title`}>
          <span>{Math.round(row.allocation_percentage) + ' %'}</span>
        </div>
        <div className={`${infoComponent}-title`}>
          <span>
            {row.category_shortname === 'Supplementary water'
              ? ''
              : formatNumberDecimals(row.water_available)}
          </span>
        </div>
      </div>
    );
  };

  return allocationData.map((row, index) => (
    <React.Fragment key={`${index}-allocation-list`}>
      <ExpandBox
        customHeaderContent={headerMaker(row, index)}
        customHeaderContainerStyle={`allocation-row-header ${
          selectedRow === `allocation_row_${index}` ? `allocation-header-selected` : ``
        }`}
        mobileOnly
        customArrowColor={
          selectedRow === `allocation_row_${index}`
            ? `allocation-body-arrow-selected`
            : `allocation-body-arrow`
        }
        uniqueIdentifier={`allocation_row_${index}`}
        expandState={selectedRow === `allocation_row_${index}`}
        handleExpandClick={(state, identifier) => {
          setItemsOnClick(state, identifier, row, index);
        }}
      >
        <div ref={element => (detailsRef.current[index] = element)} className={infoComponent}>
          <AllocationContent
            info={detailsInfo}
            pathName={rulePathName}
            setPositionCenter={setPositionCenter}
          />
        </div>
      </ExpandBox>
    </React.Fragment>
  ));
}

AllocationMobile.propTypes = {
  allocationData: PropTypes.array,
  rulePathName: PropTypes.string,
  onItemClick: PropTypes.func,
};
