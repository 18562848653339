import React, { useContext } from 'react';

// Components
import BodyContainer from './BodyContainer';
import TradingBody from '../../components/pageComponents/trading/TradingBody';
import InfoIconAndModal from '../../components/informationButton/InfoIconAndModal';

// Style
import './body.scss';

// Assests
import tradeModal from '../../components/modal/modalText/TradingTitleModalText.json';

// Context
import { AppContext } from '../../contexts/AppContext';

// Helper
import { findLastWord } from '../../helpers/Utils';

// Constants
import { trading } from '../../abstract/IntroText.json';
const container = 'trade-container';

const Trading = () => {
  const { waterSource } = useContext(AppContext);
  const { water_source_name } = waterSource;
  const { title, intro } = trading;
  const { modal } = tradeModal;

  return (
    <BodyContainer
      pageTitle={
        <div className={`${container}-pageTop`}>
          <span>
            {`${title} ${findLastWord(water_source_name).notLast}`}
            &nbsp;
            <InfoIconAndModal
              modalId="tradingTitleModal"
              modalContent={modal}
              lastWord={
                <span className={'page-body-title'}>
                  {findLastWord(water_source_name).last}
                  &nbsp;
                </span>
              }
            />
          </span>
        </div>
      }
      pageIntro={intro}
    >
      <TradingBody />
    </BodyContainer>
  );
};

export default Trading;
