import React from 'react';
import { useLocation } from 'react-router-dom';
import { get } from 'lodash';
import GenericError from '../components/generalComponents/error/Error';

// The top level component that will wrap our app's core features
export default function ErrorHandler({ children }) {
  const location = useLocation();
  const code = get(location.state, 'errorStatusCode');

  if (code >= 400) {
    return <GenericError />;
  }
  return children;
}
