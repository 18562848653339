// Libraries
import React, { useState, createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

// Helpers
import { findSiteIdWithoutType } from '../helpers/WaterDataApiHelper.jsx';
import { addInternalStationData } from '../helpers/Utils';
import { isMiniApp } from '../helpers/UrlGenerator.jsx';

export const MiniAppContext = createContext();

export default function MiniAppContextProvider({ children }) {
  const [station, updateStation] = useState({});
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const localStation = localStorage.getItem('currentSite');
    if (localStation && localStation !== 'undefined' && localStation.siteId) {
      setStation(JSON.parse(localStation));
    } else {
      const siteId = window.location.href.split('/').pop();
      if (isMiniApp() && siteId !== 'm') {
        (async () => {
          await findSiteIdWithoutType(siteId).then(async result => {
            if (!isEmpty(result)) {
              setStation(result[0]);
            } else {
              setStation({});
            }
          });
        })();
      } else {
        setStation({});
      }
    }
  }, []);

  async function setStation(newStation) {
    setIsReady(false);
    if (isMiniApp() && newStation && !isEmpty(newStation)) {
      const newStationData =
        newStation.station_type === 'dam' || newStation.siteType === 'Groundwater'
          ? newStation
          : await addInternalStationData(newStation);
      updateStation(newStationData);
      localStorage.setItem('currentSite', JSON.stringify(newStationData));
    }
    setIsReady(true);
  }

  return (
    <MiniAppContext.Provider value={{ station, setStation }}>
      {isReady ? children : null}
    </MiniAppContext.Provider>
  );
}

MiniAppContextProvider.propTypes = {
  children: PropTypes.any,
};
