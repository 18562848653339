// Library
import React, { useContext } from 'react';
import { isEmpty } from 'lodash';

// Styles
import './ratingsCurveTableItem.scss';

// Context
import { DownloadContext } from '../../../../../contexts/DownloadContext';

// Helper
import { formatNumberDecimals } from '../../../../../helpers/Utils';

// Constant
import constants from '../../../../../constants/HydrometricsConstants';
import dataConstants from './RatingsCurveConstants';

const RatingsCurveTableItem = ({ ratingData, levelRange }) => {
  const { ratingTableFormat } = useContext(DownloadContext);
  const isTable1 = ratingTableFormat.id === 'Tabular1';
  const tableHeader = dataConstants.TABLE_HEADERS[ratingTableFormat.id];
  const component = `ratings-curve-table-item${isTable1 ? '-1' : '-2'}`;

  if (isEmpty(ratingData) || isEmpty(ratingData.data)) return <></>;

  const data = ratingData.data;

  const formatFlow = flow => {
    const decimals = constants.DATA_LABEL_FORMAT.flow_rate.decimals;
    return Number.isNaN(flow) || !flow
      ? '-'
      : formatNumberDecimals(Number(flow), false, false, decimals);
  };

  const formatLevel = (level, decimals) => {
    return formatNumberDecimals(Number(level), false, false, decimals);
  };

  return (
    <div className={component}>
      <div className={`${component}-data`}>
        <table>
          <thead>
            <tr className={`${component}-header`}>
              {!isTable1 && <th className={`${component}-header-item`}>Level</th>}
              {tableHeader.map(headerItem => (
                <th className={`${component}-header-item`} key={headerItem}>
                  {headerItem}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {isTable1
              ? data.map((item, idx) => (
                  <tr key={idx}>
                    <td>{item.SiteId}</td>
                    <td>100</td>
                    <td>141</td>
                    <td>{`${item.TableNo}.${String(item.ReleaseNo).padStart(2, '0')}`}</td>
                    <td>{formatLevel(item.Level, 2)}</td>
                    <td>{formatFlow(item.Value1)}</td>
                    <td>130</td>
                  </tr>
                ))
              : levelRange.map((levelItem, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{formatLevel(levelItem, 1)}</td>
                      {tableHeader.map((headerItem, headerIdx) => {
                        const level =
                          Number(levelItem) < 0 || levelItem.includes('-')
                            ? Number((Number(levelItem) - headerItem).toFixed(2))
                            : Number((Number(levelItem) + headerItem).toFixed(2));
                        return (
                          <td key={headerIdx}>
                            {levelItem === '-0.0' && headerItem === 0
                              ? '-'
                              : formatFlow(
                                  data.find(dataItem => Number(dataItem.Level) === level)?.Value1,
                                )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RatingsCurveTableItem;
