// Libraries
import axios from 'axios';
import { isEmpty, orderBy } from 'lodash';
import util from 'util';
import { version } from '../../package.json';
import moment from 'moment';

// Helpers
import { currentFinancialYear, startDateOfFinancialYear, getCurrentDate } from './TimeUtils.jsx';
import { sortArrayByKeyAsc, toTitleCasePlus } from './Utils';
import { isMiniApp } from './UrlGenerator';
import { resolvePromises } from './WaterDataApiHelper';

// Constants
import constants from '../constants/Constants';
import mapFilterConstant from '../constants/MapFilterConstants';
const { ENVIRONMENT, RAINFALL, CONSTRAINTS } = mapFilterConstant.MAP_POPUP_ITEMS;
const OPERATION_FLOW_TARGET_TYPE = 'Flow Target';
const CONSTRAINTS_TYPE = 'Constraints';
const apiBaseURL = constants.BASE_URL;
const TIMEOUT = 15000;
const BARWON_DARLING_UNREG_WATER_SOURCE = 16001;
const signal = axios.CancelToken.source();

const sortStationByOrderIndex = data => {
  let rawData = data;
  rawData.sort((a, b) => {
    const AIndex = a.order_index ? a.order_index : 99999;
    const BIndex = b.order_index ? b.order_index : 99999;
    return AIndex - BIndex;
  });
  return rawData;
};

const setTitleCaseWaterSourceNames = watersourceList => {
  if (!isEmpty(watersourceList)) {
    watersourceList.forEach(waterSource => {
      waterSource.water_source_name = toTitleCasePlus({
        stringSentence: waterSource.water_source_name,
        allCapsWords: ['MDB', 'GAB', 'NSW', 'I', 'II', 'III', 'IV'],
      });
    });
  }
};

export const fetchDataFromApi = async (params, key) => {
  const res = await axios.get(apiBaseURL + params.url, {
    timeout: TIMEOUT,
    params: params.params,
    headers: params.headers,
    cancelToken: signal.token,
  });
  const result = res.data[key] || res.data;
  return result;
};

export const fetchDataFromStationsApi = async (params, key) => {
  const res = await axios.get(constants.WNSW_API_BASE_URL + params.url, {
    timeout: TIMEOUT,
    params: params.params,
    headers: {
      'Ocp-Apim-Subscription-Key': constants.WNSW_STATIONS_DATA_API_KEY,
    },
    cancelToken: signal.token,
  });
  const result = res.data[key] || res.data;
  return result;
};

export const getCommentaryByWSId = async waterSourceId => {
  const fromDate = moment().subtract(3, 'months').format('YYYY-MM-DD');
  const toDate = moment().format('YYYY-MM-DD');

  let res = await axios.get(
    `${apiBaseURL}${util.format(constants.WATER_SOURCE_COMMENTARY, waterSourceId)}`,
    { params: { startDate: fromDate, endDate: toDate } },
  );
  if (res.status === 200 && res.data.commentary && res.data.commentary[0]) {
    return res.data.commentary[0];
  }
  if (res.status === 400 || res.status === 404) {
    throw new Error('API Error');
  }
  return undefined;
};

/**
 * Returns the water source for the given id
 *
 * @param {*} waterSourceId id of the water source
 */
export const getWaterSourceById = async waterSourceId => {
  try {
    const res = await axios.get(
      `${apiBaseURL}${util.format(constants.WATER_SOURCE_BY_ID, waterSourceId)}`,
    );
    if (res.data.water_sources) {
      setTitleCaseWaterSourceNames(res.data.water_sources);

      return {
        data: res.data.water_sources[0] ? res.data.water_sources[0] : [],
      };
    } else {
      throw new Error('API Error');
    }
  } catch (error) {
    return error;
  }
};

/**
 *
 * @param {*} stationId
 */
export const getLatestResourcesByDamId = async stationId => {
  const params = {
    url: util.format(constants.STATION_DAM_SUMMARY, stationId),
  };
  try {
    let rawData = await fetchDataFromStationsApi(params, 'dams');
    if (rawData && rawData[0]) {
      return { data: rawData[0] };
    } else {
      return { data: { resources: [] } };
    }
  } catch (error) {
    return error;
  }
};

/**
 *
 * @param {*} waterSourceId
 * @param {*} from
 * @param {*} to
 * @param {*} years
 * @param {*} interval
 */
export const getDamResourcesByWaterSourceId = async (waterSourceId, from, to, years, interval) => {
  const params = {
    url: util.format(constants.STATION_DAM_RESOURCES, waterSourceId),
    params: { startDate: from, endDate: to, years, interval },
  };
  try {
    const res = await fetchDataFromStationsApi(params, 'water_sources');
    if (res && res[0]) {
      return { data: res[0].resources };
    } else {
      throw new Error('API Error');
    }
  } catch (error) {
    return error;
  }
};

export const getCumulativeInflowsByWaterSource = async (waterSourceId, years, from, to) => {
  const isBarwon = waterSourceId === BARWON_DARLING_UNREG_WATER_SOURCE;
  const params = {
    url: util.format(constants.WATER_SOURCE_STATIONS_INFLOWS, waterSourceId),
    params: { startDate: from, endDate: to, years },
  };
  try {
    let rawData = await fetchDataFromStationsApi(params, 'stations');
    if (!isEmpty(rawData) && !isEmpty(rawData[0]) && years) {
      return {
        data: rawData[0] ? rawData[0].resources : [],
      };
    } else if (rawData && !years) {
      rawData[0].resources.forEach((month, index, self) => {
        self[index].dams.forEach(dam => {
          self[index][dam.dam_id] = isBarwon ? dam.inflow : dam.cumulative_inflow;
        });
      });
      return rawData[0].resources;
    } else {
      throw new Error('API Error');
    }
  } catch (error) {
    return error;
  }
};

export const loadInitialData = async () => {
  if (!isMiniApp()) {
    const waterSourceData = await fetchAllWaterSource(
      `${apiBaseURL}${constants.WATER_SOURCE_BASE_URL}`,
    );
    if (waterSourceData && !isEmpty(waterSourceData) && waterSourceData[0].water_source_id) {
      const localVersion = localStorage.getItem('version');
      if (localVersion !== version) {
        localStorage.removeItem('curWaterSource');
        localStorage.setItem('version', version);
      }
      localStorage.setItem('waterSourceList', JSON.stringify(waterSourceData));
    }
  }
};

export const fetchAllWaterSource = async url => {
  try {
    const response = await axios.get(url);
    setTitleCaseWaterSourceNames(response.data.water_sources);
    return response.data.water_sources;
  } catch (error) {
    return error;
  }
};

export const getWaterSourceByType = (waterSourceType, setRegRiverList) => {
  const params = {
    url: constants.WATER_SOURCE_BASE_URL,
    params: waterSourceType && { type: waterSourceType },
  };
  fetchDataFromApi(params, 'water_sources').then(
    result => {
      if (result && !isEmpty(result)) {
        setTitleCaseWaterSourceNames(result);
        const sortedResult = sortArrayByKeyAsc(result, 'water_source_name');
        setRegRiverList(sortedResult);
      }
    },
    error => {
      return error;
    },
  );
};

export const getStationsForWaterSource = async (waterSource, type, setData, toSort = false) => {
  let url,
    key,
    paramsType = '';
  let results = [];
  if (type === ENVIRONMENT.name) {
    url = constants.WATER_SOURCE_CONSTRAINTS;
    key = 'water_sources';
    paramsType = OPERATION_FLOW_TARGET_TYPE;
  } else if (type === CONSTRAINTS.name) {
    url = constants.WATER_SOURCE_CONSTRAINTS;
    key = 'water_sources';
    paramsType = CONSTRAINTS_TYPE;
  } else if (type === RAINFALL.name) {
    url = constants.WATER_SOURCE_CONSTRAINTS;
    key = 'water_sources';
    paramsType = constants.STATION_TYPE[type];
  } else {
    url = constants.WATER_SOURCE_STATIONS;
    key = 'stations';
    paramsType = type;
  }
  const params = {
    url: util.format(url, waterSource.water_source_id),
    params: { type: paramsType },
  };
  if (key === 'stations') {
    await fetchDataFromStationsApi(params, key).then(result => (results = result));
  } else {
    await fetchDataFromApi(params, key).then(result => (results = result));
  }
  if (!isEmpty(results) && typeof results !== 'undefined') {
    let finalResult = results[0].resources;
    if (toSort) {
      finalResult = sortStationByOrderIndex(results[0].resources);
    }
    setData(finalResult);
    return finalResult;
  } else {
    setData([]);
    return [];
  }
};

export const getStationDataByStationId = async (url, type) => {
  const result = await fetchDataFromStationsApi({ url: url }, type);
  return !isEmpty(result) && typeof result !== 'undefined' ? result[0].resources : [];
};

export const getAllocCommentWaterSource = async (waterSource, setComment) => {
  const params = {
    url: util.format(constants.WATER_SOURCE_COMMENTARY_LATEST, waterSource.water_source_id),
  };
  fetchDataFromApi(params, 'commentary').then(
    result => {
      if (result) {
        setComment(!isEmpty(result) && typeof result !== 'undefined' ? result[0].resources : []);
      }
    },
    error => {
      return error;
    },
  );
};

export const getAllocDataWaterSource = async (
  waterSourceId,
  setAllocationData,
  from,
  to,
  all = false,
) => {
  const params = {
    url: util.format(constants.WATER_SOURCE_ALLOCATION, waterSourceId),
    params: {
      view: 'table',
      ...(from && { startYear: from }),
      ...(to && { endYear: to }),
    },
  };
  fetchDataFromApi(params, 'water_sources').then(
    result => {
      if (result && !isEmpty(result)) {
        const finalResult = all ? result : result[0].resources;
        setAllocationData(finalResult);
      } else {
        setAllocationData([]);
      }
    },
    error => {
      return error;
    },
  );
};

export const getDistributionDataWaterSource = async (
  waterSourceId,
  setDistributionData,
  from,
  to,
) => {
  const params = {
    url: util.format(constants.WATER_SOURCE_DISTRIBUTION, waterSourceId),
    params: {
      startYear: from ? from : currentFinancialYear() - 10,
      endYear: to ? to : currentFinancialYear(),
    },
  };
  fetchDataFromApi(params, 'water_sources').then(
    result => {
      if (result) {
        setDistributionData(
          !isEmpty(result) && typeof result !== 'undefined' ? result[0].resources : [],
        );
      } else {
        setDistributionData([]);
      }
    },
    error => {
      return error;
    },
  );
};

export const getBalanceDataWaterSource = async (waterSourceId, setBalanceData) => {
  const params = {
    url: util.format(constants.WATER_SOURCE_BALANCE, waterSourceId),
    params: {
      startYear: currentFinancialYear() - 10,
      endYear: currentFinancialYear() - 1,
    },
  };
  fetchDataFromApi(params, 'water_sources').then(
    result => {
      if (result) {
        setBalanceData(
          !isEmpty(result) && typeof result !== 'undefined' ? result[0].resources : [],
        );
      }
    },
    error => {
      setBalanceData([]);
      return error;
    },
  );
};

export const getConditionsWaterSource = async (waterSource, setConditionsData) => {
  const params = {
    url: util.format(constants.WATER_SOURCE_CONDITIONS, waterSource.water_source_id),
  };
  fetchDataFromApi(params, 'water_sources').then(
    result => {
      if (result) {
        setConditionsData(!isEmpty(result) && typeof result !== 'undefined' ? result[0] : []);
      }
    },
    error => {
      return error;
    },
  );
};

export const getOrderUsageData = async (waterSourceId, setData) => {
  const params = {
    url: util.format(constants.WATER_SOURCE_ORDER_USAGE, waterSourceId),
  };
  fetchDataFromApi(params, 'water_sources').then(
    result => {
      if (result) {
        setData(!isEmpty(result) && typeof result !== 'undefined' ? result[0] : []);
      }
    },
    error => {
      return error;
    },
  );
};

/**
 *
 * @param {*} waterSource
 * @param {*} setDrought
 */
export const getStatusFromWaterSource = async (waterSource, setDrought) => {
  const params = {
    url: util.format(constants.WATER_SOURCE_STATUS, waterSource.water_source_id),
  };
  fetchDataFromApi(params, 'water_sources').then(
    result => {
      if (result) {
        setDrought(!isEmpty(result) ? result[0].resources : []);
      } else {
        setDrought([]);
      }
    },
    error => {
      setDrought([]);
      return error;
    },
  );
};

export const getAnnouncement = (waterSourceId, startDate, endDate, setData) => {
  const params = {
    url: util.format(constants.WATER_SOURCE_ANNOUNCEMENT, waterSourceId),
    params: { startDate, endDate },
  };
  fetchDataFromApi(params, 'water_sources').then(
    result => {
      if (!isEmpty(result) && typeof result !== 'undefined') {
        setData(result[0].resources);
      } else {
        setData([]);
      }
    },
    () => {
      setData([]);
    },
  );
};

export const getStreamFlowForecastData = async (stationId, setData, setShowSpinner) => {
  const params = {
    url: util.format(constants.STATION_STREAMFLOW_FORECAST_URL, stationId),
  };
  await fetchDataFromStationsApi(params, 'stations').then(
    result => {
      if (!isEmpty(result) && typeof result !== 'undefined') {
        setData(result[0].resources);
        setShowSpinner && setShowSpinner(false);
      } else {
        setData([]);
        setShowSpinner && setShowSpinner(false);
      }
    },
    error => {
      return error;
    },
  );
};

export const getAnnouncementPdfById = async (waterSourceId, announcementId) => {
  const params = {
    url: util.format(constants.WATER_SOURCE_ANNOUNCEMENT_ID, waterSourceId, announcementId),
  };
  await axios({
    url: apiBaseURL + params.url,
    method: 'GET',
    responseType: 'blob',
  })
    .then(response => {
      const file = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(file);
      window.open(url);
    })
    .catch(err => {
      return err;
    });
};

export const getTradeDataById = async (water_source_id, from, to) => {
  const starting = from ? from : moment().subtract(12, 'months').format('YYYY-MM');
  const ending = to ? to : getCurrentDate('YYYY-MM');

  const params = {
    url: util.format(constants.WATER_SOURCE_TRADE, water_source_id),
    params: {
      startDate: starting,
      endDate: ending,
    },
  };

  const rawData = await fetchDataFromApi(params, 'water_sources');
  if (!isEmpty(rawData) && !isEmpty(rawData[0])) {
    return rawData[0].resources;
  }
  return [];
};

export const getUsageByWaterSourceFY = async (wsId, setData) => {
  const currentFY = currentFinancialYear();
  const params = {
    url: util.format(constants.WATER_SOURCE_ANNUAL_EXTRACTION, wsId),
    params: {
      startYear: currentFY - 10,
      endYear: currentFY - 1,
    },
  };
  fetchDataFromApi(params, 'water_sources').then(
    result => {
      if (result) {
        setData(!isEmpty(result) && typeof result !== 'undefined' ? result : []);
      }
    },
    error => {
      return error;
    },
  );
};

export const getRainfallDataById = async (water_source_id, from, to) => {
  const starting = from
    ? from
    : moment()
        .subtract(12 * 10, 'months')
        .format('YYYY-MM');
  const ending = to ? to : getCurrentDate('YYYY-MM');

  const params = {
    url: util.format(constants.WATER_SOURCE_RAINFALL, water_source_id),
    params: {
      from: starting,
      to: ending,
    },
  };

  const rawData = await fetchDataFromApi(params, 'water_sources');
  if (!isEmpty(rawData) && !isEmpty(rawData[0])) {
    return rawData[0].resources;
  }
  return [];
};

export const getUsageReleaseDataById = async (water_source_id, interval = 'daily', from, to) => {
  const starting = from
    ? from
    : moment()
        .subtract(12 * 10, 'months')
        .format('YYYY');
  const ending = to ? to : getCurrentDate('YYYY');

  const params = {
    url: util.format(constants.WATER_SOURCE_USAGE_RELEASE, water_source_id),
    params: {
      interval: interval,
      from: starting,
      to: ending,
    },
  };

  const rawData = await fetchDataFromApi(params, 'water_sources');
  if (!isEmpty(rawData) && !isEmpty(rawData[0])) {
    return rawData[0].resources;
  }
  return [];
};

export const getAllDamsForOverview = async (isSummary = false) => {
  try {
    const params = {
      url: isSummary ? constants.STATION_STORAGE_OVERVIEW : constants.STATION_DAMS_OVERVIEW,
    };
    const key = isSummary ? 'stations' : 'dams';
    const res = await fetchDataFromStationsApi(params, key);
    if (res && !isEmpty(res)) {
      return res;
    } else {
      return [];
    }
  } catch (error) {
    return false;
  }
};

/**
 *
 * @param {*} type
 */
export const getAllDamsResources = async type => {
  const params = {
    url: constants.STATION_ALL_DAM_LATEST_RESOURCES,
    params: { type },
  };
  try {
    const res = await fetchDataFromStationsApi(params, 'dams');
    if (res && !isEmpty(res)) {
      return res;
    } else {
      return [];
    }
  } catch (error) {
    return false;
  }
};

export const getAllInterValleyTrade = async (target = 'all') => {
  const starting = startDateOfFinancialYear();
  const ending = getCurrentDate('YYYY-MM-DD');

  const params = {
    url: util.format(constants.WATER_SOURCE_INTER_VALLEY_TRADE, target),
    params: {
      startDate: starting,
      endDate: ending,
    },
  };

  const rawData = await fetchDataFromApi(params, 'water_sources');
  if (!isEmpty(rawData) && !isEmpty(rawData[0])) {
    return rawData;
  }
  return [];
};

export const getOveviewSummary = async () => {
  const params = {
    url: util.format(constants.WATER_SOURCE_OVERVIEW_SUMMARY),
  };

  const rawData = await fetchDataFromApi(params, null);
  if (!isEmpty(rawData)) {
    return rawData;
  }
  return {};
};

export const getOveviewResources = async type => {
  const params = {
    url: util.format(constants.WATER_SOURCE_OVERVIEW_RESOURCES),
    params: {
      type: type,
    },
  };

  const rawData = await fetchDataFromApi(params, null);
  if (!isEmpty(rawData)) {
    return rawData;
  }
  return {};
};

export const getDamVolumeByWaterSourceId = async (waterSourceId, from, to) => {
  const fromDate = from ? from : moment().subtract(12, 'months').format('YYYY-MM');
  const params = {
    url: util.format(constants.WATER_SOURCE_STATIONS_DAM_VOLUME, waterSourceId),
    params: { startDate: fromDate, endDate: to },
  };

  const rawData = await fetchDataFromStationsApi(params, 'water_sources');
  if (!isEmpty(rawData) && !isEmpty(rawData[0])) {
    return rawData[0].resources;
  }
  return [];
};

export const getLicencedWaterBalanceByWaterSource = async (
  waterSourceId,
  setLicencedWaterBalance,
) => {
  const params = {
    url: util.format(constants.WATER_SOURCE_LICENCED_WATER_BALANCE, waterSourceId),
  };
  fetchDataFromApi(params, 'water_sources').then(
    result => {
      if (result) {
        setLicencedWaterBalance(
          !isEmpty(result) && typeof result !== 'undefined' ? result[0].resources : [],
        );
      } else {
        setLicencedWaterBalance([]);
      }
    },
    error => {
      setLicencedWaterBalance([]);
      return error;
    },
  );
};

export const getAllocForecastByWaterSource = async (waterSourceId, setForecastData) => {
  const params = {
    url: util.format(constants.WATER_SOURCE_ALLOCATION_FORECAST, waterSourceId),
  };
  fetchDataFromApi(params, 'water_sources').then(
    result => {
      if (result) {
        setForecastData(
          !isEmpty(result) && typeof result !== 'undefined' ? result[0].resources : [],
        );
      } else {
        setForecastData([]);
      }
    },
    error => {
      setForecastData([]);
      return error;
    },
  );
};

export const getRiverFlowRate = async (waterSourceId, setData) => {
  const params = {
    url: util.format(constants.WATER_SOURCE_FLOW_RATE, waterSourceId),
  };
  fetchDataFromApi(params, 'water_source').then(
    result => {
      if (result) {
        setData(
          typeof result !== 'undefined' && !isEmpty(result.water_sources)
            ? result.water_sources
            : [],
        );
      }
    },
    error => {
      return error;
    },
  );
};

export const getAccountingRules = async waterSource => {
  const params = {
    url: util.format(constants.WATER_SOURCE_ACCOUNTING_RULES, waterSource.water_source_id),
  };
  const rawData = await fetchDataFromApi(params, null);
  if (!isEmpty(rawData)) {
    return rawData.water_sources[0];
  }
  return {};
};

export const getAutocomplete = async text => {
  const params = {
    url: constants.GLOBAL_SEARCH_AUTCOMPLETE,
    params: { text: text },
  };
  const rawData = await fetchDataFromApi(params, null);
  if (!isEmpty(rawData)) {
    return rawData.search.result.hits[0];
  }
  return {};
};

export const getSearchResult = async text => {
  const params = {
    url: constants.GLOBAL_SEARCH,
    params: { text: text },
  };
  const rawData = await fetchDataFromApi(params, null);
  if (!isEmpty(rawData)) {
    return rawData.search.result.hits;
  }
  return {};
};

export const getSearchSuggestion = async text => {
  const params = {
    url: constants.GLOBAL_SEARCH_SUGGEST,
    params: { text: text },
  };
  const rawData = await fetchDataFromApi(params, null);
  if (!isEmpty(rawData)) {
    return rawData.search.result.hits;
  }
  return {};
};

/**
 *
 * @param {*} waterSourceId
 */
export const getRofCounter = async waterSourceId => {
  const params = {
    url: util.format(constants.WATER_SOURCE_ROF_COUNTER, waterSourceId),
  };
  const rawData = await fetchDataFromApi(params, 'water_sources');
  if (!isEmpty(rawData) && typeof rawData !== 'undefined') {
    return rawData[0];
  }
  return [];
};

export const getRiverVolumeWaterSource = async (waterSourceId, duration) => {
  const endpoint =
    duration === 'latest' ? constants.STATION_RIVER_VOLUME_LATEST : constants.STATION_RIVER_VOLUME;
  const params = {
    url: util.format(endpoint, waterSourceId),
    ...(duration !== 'latest' && {
      params: {
        duration: duration,
      },
    }),
  };
  const rawData = await fetchDataFromStationsApi(params, 'stations');
  if (!isEmpty(rawData) && typeof rawData !== 'undefined' && rawData[0]) {
    return rawData[0].resources;
  } else {
    return [];
  }
};

export const getTradeIvt = async (water_source_id, setData, period, from, to) => {
  const params = {
    url: util.format(constants.WATER_SOURCE_TRADE_IVT, water_source_id),
    params: {
      period: period,
      from: from,
      to: to,
    },
  };

  const rawData = await fetchDataFromApi(params, 'water_sources');
  if (!isEmpty(rawData) && !isEmpty(rawData[0])) {
    return setData(rawData[0].resources);
  }
  return [];
};

export const getLatestNetAllocationVolume = async (water_source_id, setData, isLatest) => {
  const apiUrl = `${constants.WATER_SOURCE_TRADE_NET_ALLOCATION_VOLUME}${
    isLatest ? '/latest' : ''
  }`;
  const params = {
    url: util.format(apiUrl, water_source_id),
  };

  const rawData = await fetchDataFromApi(params, 'water_sources');
  if (!isEmpty(rawData) && !isEmpty(rawData[0])) {
    return setData(rawData[0].resources);
  }
  return [];
};

export const getAllStationsByType = async (stationType, setData, stationId) => {
  const params = {
    url: constants.STATIONS_BASE_URL,
    params: {
      type: stationType,
      stationId,
    },
  };

  const rawData = await fetchDataFromStationsApi(params, 'stations');
  if (!isEmpty(rawData)) {
    const sortedStations = orderBy(
      rawData,
      [station => station.station_name.toLowerCase()],
      ['asc'],
    );
    return setData(sortedStations);
  }
  return [];
};

export const getStationsByID = async stations => {
  const siteIdsList = [];
  while (stations?.length) {
    siteIdsList.push(stations.splice(0, 30).join(','));
  }
  const sitesPromises = siteIdsList.map(async siteId => {
    const params = {
      url: `${constants.STATIONS_BASE_URL}/${siteId}`,
    };
    const rawData = await fetchDataFromStationsApi(params, 'stations');
    if (!isEmpty(rawData)) {
      return rawData;
    }
    return [];
  });
  let results = await resolvePromises(sitesPromises);
  return results.flat();
};

export const getDamsByID = async stations => {
  const siteIdsList = [];
  while (stations?.length) {
    siteIdsList.push(stations.splice(0, 30).join(','));
  }
  const sitesPromises = siteIdsList.map(async siteId => {
    const params = {
      url: util.format(constants.STATION_DAMS_INFO, siteId),
    };
    const rawData = await fetchDataFromStationsApi(params, 'dams');
    if (!isEmpty(rawData)) {
      return rawData;
    }
    return [];
  });
  let results = await resolvePromises(sitesPromises);
  return results.flat();
};

/**
 *
 * @param {*} id
 * @param {*} from
 * @param {*} to
 */
export const getDamResourcesById = async (id, from, to) => {
  const params = {
    url: util.format(constants.STATION_DAM_RESOURCES_BY_ID, id),
    params: { startDate: from, endDate: to },
  };
  try {
    let rawData = await fetchDataFromStationsApi(params, 'dams');
    if (rawData && rawData[0]) {
      return { data: rawData[0].resources };
    } else {
      return { data: [] };
    }
  } catch (error) {
    return error;
  }
};

/**
 *
 * @param {*} waterSource
 * @param {*} setIVTAppQueue
 */
export const getIVTAppQueueFromWaterSource = async (waterSource, setIVTAppQueue) => {
  const params = {
    url: util.format(
      constants.WATER_SOURCE_STATUS,
      // constants.WATER_SOURCE_TRADE_IVT_APPLICATION_QUEUE,
      waterSource.water_source_id,
    ),
  };
  fetchDataFromApi(params, 'water_sources').then(
    result => {
      if (result) {
        setIVTAppQueue([
          {
            value_date: '2021-11-11T00:00:00.000Z',
            application_id: '41231A',
            volume: 2123,
            status: 'waiting',
            cumulative_volume: 312442,
          },
        ]);
      } else {
        setIVTAppQueue([]);
      }
    },
    error => {
      setIVTAppQueue([]);
      return error;
    },
  );
};

export const getAzureB2CCurrentUserInfoById = async id => {
  try {
    const res = await axios({
      method: 'post',
      url: `${apiBaseURL}${constants.B2C_USER_INFO_BY_ID_URL}`,
      data: {
        id,
      },
    });
    if (res.data && res.data.success) {
      return true;
    } else {
      throw new Error(res.data ? res.data.message : 'API Error');
    }
  } catch (error) {
    return false;
  }
};

export const getSalinityReports = async (waterSource, year) => {
  try {
    const res = await axios.get(
      `${apiBaseURL}${util.format(constants.WATER_SOURCE_SALINITY_REPORT, waterSource)}`,
      { params: { year } },
    );
    if (res.data) {
      return res.data;
    } else {
      throw new Error(res.data ? res.data.message : 'API Error');
    }
  } catch (error) {
    return [];
  }
};

/**
 *
 * @param {*} waterSource
 * @param {*} stationType
 */
export const getStationForWaterSourceByType = async (waterSourceId, type, isMain = false) => {
  const params = {
    url: util.format(constants.WATER_SOURCE_STATIONS, waterSourceId),
    params: { type, is_main: isMain },
  };
  if (waterSourceId) {
    const result = await fetchDataFromStationsApi(params, 'stations');
    return result ? result[0]?.resources : [];
  } else {
    return [];
  }
};

/**
 *
 * @param {*} waterSource
 * @param {*} stationType
 */
export const getStationIdsForWaterSourceOfType = async (waterSource, stationType) => {
  const stationIds = [];
  const result = await getStationForWaterSourceByType(waterSource.water_source_id, stationType);
  if (!isEmpty(result)) {
    result.forEach(station => {
      stationIds.push(station.station_id);
    });
    return stationIds;
  }
  return [];
};

export const getBoreIdsForWaterSourceOfType = async waterSourceId => {
  const boreIds = [];
  const params = {
    url: util.format(constants.WATER_SOURCE_BORES, waterSourceId),
  };
  const result = await fetchDataFromStationsApi(params, 'stations');
  if (!isEmpty(result)) {
    result[0].bores.forEach(bore => {
      boreIds.push(bore.bore_id);
    });
    return boreIds;
  }
  return [];
};

export const getDamStorageForecastByWaterSource = async waterSourceId => {
  const params = {
    url: util.format(constants.STATION_DAM_FORECAST, waterSourceId),
  };
  const result = await fetchDataFromStationsApi(params, 'water_sources');
  return result[0]?.resources;
};

export const getStationsForHunter = async () => {
  const params = {
    url: util.format(constants.WATER_SOURCE_HRST_DISCHARGE_STATIONS),
  };
  const result = await fetchDataFromApi(params, 'stations');
  if (!isEmpty(result)) {
    return result;
  }
  return [];
};

export const getAllWaterSources = async () => {
  if (!isMiniApp()) {
    const waterSourceData = await fetchAllWaterSource(
      `${apiBaseURL}${constants.WATER_SOURCE_BASE_URL}/all`,
    );
    if (waterSourceData && !isEmpty(waterSourceData) && waterSourceData[0].water_source_id) {
      return waterSourceData;
    }
  }
};
