// Library
import React, { useContext } from 'react';
import { isEmpty } from 'lodash';
// Styles
import './pollutionMonitoringBody.scss';

// Component
import Loader from '../../loader/Loader';

// Context
import { MobileContext } from '../../../contexts/MobileContext';

// Helper
import { useCSVData, HEADERS } from './PollutionMonitoringHelper';

// Constant
const component = 'pollution-monitoring-body';
const DEAKTOP_HEADERS = [...Object.values(HEADERS)];
const MOBILE_HEADERS = [...Object.values(HEADERS).slice(0, 4)];

const PollutionMonitoringBody = () => {
  const { isMedium } = useContext(MobileContext);
  const { data, isLoading } = useCSVData();
  const HEADERS = isMedium ? MOBILE_HEADERS : DEAKTOP_HEADERS;

  const LoadingUI = () => <Loader />;
  const EmptyUI = () => <></>;
  const ContentUI = () => (
    <>
      {data.map((dataItem, ind) => {
        return (
          dataItem.Pollutant && (
            <tr className={`${component}-row`} key={`${dataItem[HEADERS.Sampled]}-${ind}`}>
              {HEADERS.map(colName => (
                <td
                  className={`${component}-row-item${ind < 3 ? '-notes' : ''}`}
                  key={`${dataItem[HEADERS.Sampled]}-${ind}-${colName}`}
                  colSpan={dataItem[colName] === 'Once a month (min. of 4 weeks)' ? 3 : 1}
                >
                  {dataItem[colName]}
                </td>
              ))}
            </tr>
          )
        );
      })}
    </>
  );
  const NoteUI = () => (
    <div className={`${component}-note`}>
      <p>Notes</p>
      <p>na - Data not yet available</p>
      <p>* - dates unavailable</p>
    </div>
  );

  return (
    <div className={component}>
      {isLoading ? (
        <LoadingUI />
      ) : (
        <table className={`${component}-table`}>
          <thead>
            <tr className={`${component}-header`}>
              {HEADERS.map(headerItem => (
                <th className={`${component}-header-item`} key={headerItem}>
                  {headerItem}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{isEmpty(data) ? <EmptyUI /> : <ContentUI />}</tbody>
        </table>
      )}
      <NoteUI />
    </div>
  );
};

export default PollutionMonitoringBody;
