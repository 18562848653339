import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import BackIcon from '../../image/backIcon.svg';
import './backLink.scss';

export default function BackLink() {
  let history = useHistory();

  return (
    <div className="back-link">
      <Link
        id="back-link"
        to={history.location.pathname}
        onClick={e => {
          e.preventDefault();
          history.goBack();
        }}
      >
        <img className="back-icon" src={BackIcon} alt="Icon for backward button" />
        Back
      </Link>
    </div>
  );
}
