// Library
import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import { BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts';
import { get, isEmpty } from 'lodash';

// Component
import ChartContainer from '../chartProperties/ChartContainer';
import ChartResponsiveContainer from '../chartProperties/ChartResponsiveContainer';
import SelectionBtnsGroup from '../storage/childProperties/SelectionBtnsGroup';
import GeneralDropdown from '../dropDown/GeneralDropdown';
import SelectionBtns from '../storage/childProperties/SelectionBtns';

// Context
import { MobileContext } from '../../contexts/MobileContext';
import { AppContext } from '../../contexts/AppContext';

// Assest
import { chartText } from './rainfallText.json';

// Helper
import { getRainfallDataById } from '../../helpers/ApiHelper';
import { formatNumberDecimals } from '../../helpers/Utils';
import { getCurrentDate } from '../../helpers/TimeUtils';

const RainfallGraph = () => {
  const [activeCatchment, setActiveCatchment] = useState({});
  const [catchmentList, setCatchmentList] = useState([]);
  const [activeYear, setActiveYear] = useState(1);
  const [unitType, setUnitType] = useState('ML');
  const [rawData, setRawData] = useState({});
  const [graphData, setGraphData] = useState([]);
  const { isMobile } = useContext(MobileContext);
  const { waterSource } = useContext(AppContext);

  // Helper
  const loadInitialData = data => {
    let rawCatchmentList = Object.keys(data);
    rawCatchmentList.sort();
    let processedCatchmentList = rawCatchmentList.map(catchment => {
      const regex = / Catchment/g;
      return {
        id: catchment,
        name: catchment.replace(regex, ''),
      };
    });

    let allCatchmentIndex;
    const allCatchment = processedCatchmentList.find((item, index) => {
      if (item.id.includes('All Sub-Catchments')) {
        allCatchmentIndex = index;
        return true;
      }
      return false;
    });

    if (allCatchmentIndex) {
      processedCatchmentList.splice(allCatchmentIndex, 1);
      processedCatchmentList.unshift(allCatchment);
    }

    const defaultActiveCatchment = processedCatchmentList[0];

    const sourceUnitType = get(data[defaultActiveCatchment.id], '[0].unit_type');

    setRawData(data);
    setCatchmentList(processedCatchmentList);
    setActiveCatchment(defaultActiveCatchment);
    setUnitType(sourceUnitType ? sourceUnitType.toLowerCase() : 'ml');
    filterAndSetData(data, defaultActiveCatchment, activeYear);
  };

  const filterAndSetData = (data, catchment, year) => {
    const targetCatchment = data[catchment.id];
    const endTime = getCurrentDate('YYYY-MM');
    const startTime = moment().subtract(year, 'years').format('YYYY-MM');
    const resultData = targetCatchment.filter(dateItem => {
      const curDate = dateItem.date;
      if (
        curDate &&
        moment(curDate).isSameOrBefore(endTime) &&
        moment(curDate).isSameOrAfter(startTime)
      ) {
        return true;
      }
      return false;
    });

    setGraphData(resultData);
  };

  // Lifecycle
  useEffect(() => {
    let unmounted = false;

    (async () => {
      const data = await getRainfallDataById(waterSource.water_source_id);

      if (!unmounted && !isEmpty(data)) {
        loadInitialData(data);
      }
    })();

    return () => {
      unmounted = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ChartContainer
      pageTitle={chartText.title}
      graphTitle={chartText.title}
      graphIntro={chartText.desc}
    >
      <SelectionBtnsGroup
        left={
          <GeneralDropdown
            menuItem={catchmentList}
            selectedItem={activeCatchment}
            onItemClick={item => {
              setActiveCatchment(item);
              filterAndSetData(rawData, item, activeYear);
            }}
          />
        }
        right={
          <SelectionBtns
            type="year"
            itemList={[1, 5, 10]}
            onBtnChange={year => {
              setActiveYear(year);
              filterAndSetData(rawData, activeCatchment, year);
            }}
            defaultValue={activeYear}
          />
        }
      />
      <ChartResponsiveContainer>
        <BarChart width={800} margin={{ left: 20, bottom: 50 }} data={graphData}>
          <XAxis
            interval={'preserveStartEnd'}
            dataKey="date"
            tickFormatter={v => moment(v, 'YYYY-MM').format('MMM YYYY')}
            angle={-45}
            dy={25}
          />
          <YAxis
            interval="preserveStartEnd"
            width={isMobile ? 15 : 60}
            angle={isMobile ? -60 : 0}
            label={isMobile ? '' : { value: `Rainfall (${unitType})`, angle: -90, dx: -35 }}
            tickFormatter={v => formatNumberDecimals(v)}
          />
          <Bar name="Rainfall" dataKey="value" fill="#0054a6" type="monotone" />
          <Tooltip
            labelFormatter={v => moment(v, 'YYYY-MM').format('MMMM YYYY')}
            formatter={(value, name) => [
              formatNumberDecimals(Number(value)) + ' ' + unitType,
              name,
            ]}
            cursor={{ fill: 'transparent' }}
          />
        </BarChart>
      </ChartResponsiveContainer>
    </ChartContainer>
  );
};

export default RainfallGraph;
