import React, { useState, useContext } from 'react';

// Styles
import './waterIntroduction.scss';
import '../../layout/grid.scss';

// Config
import config from './learn.json';

// Helpers
import { createMarkup } from '../../helpers/Utils';

// Component
import PageTop from '../pageTop/PageTop';
import ParagraphSection from '../paragraphSection/ParagraphSection';
import BackLink from '../backLink/BackLink';

// Context
import { MobileContext } from '../../contexts/MobileContext';

// Constant
const CONTAINER = 'introduction-container-content';

const WaterIntroduction = () => {
  const { isMobile } = useContext(MobileContext);
  const { waterIntroHeader, subSections, licenseSection } = config.introPage;
  const [expandLicense, setExpandLicense] = useState(false);
  const [expandUpper, setExpandUpper] = useState(false);

  /* Component */
  const mobileExpandButton = (state, setFnc) => {
    return (
      isMobile && (
        <div className={`${CONTAINER}-expand`}>
          <button className={`${CONTAINER}-expand-button`} onClick={() => setFnc(!state)}>
            {state ? 'See less' : 'See more'}
          </button>
        </div>
      )
    );
  };

  return (
    <div className={`${CONTAINER}`}>
      <div className={`${CONTAINER}-outer`}>
        <BackLink />
        <div className={`${CONTAINER}-header`}>
          <PageTop title={waterIntroHeader} />
        </div>
      </div>
      <div className={`${CONTAINER}-inner`}>
        <div className={`${CONTAINER}-inner-left col-lg-7`}>
          {subSections.map((subSection, index) => (
            <ParagraphSection
              key={subSection.title}
              sectionTitle={subSection.title}
              sectionContent={subSection.paragraphs}
              customClass={
                isMobile
                  ? `${CONTAINER}-easeIn ${
                      index < 1 || expandUpper ? `${CONTAINER}-show` : `${CONTAINER}-hidden`
                    }`
                  : ''
              }
            />
          ))}
          {mobileExpandButton(expandUpper, setExpandUpper)}
        </div>
        <div className={`${CONTAINER}-inner-right col-lg-6`}>
          <div className={`${CONTAINER}-license`}>
            <div className={`${CONTAINER}-license-header`}>{licenseSection.title}</div>
            <div className={`${CONTAINER}-license-content`}>
              <p dangerouslySetInnerHTML={createMarkup(licenseSection.intro)} />
              {licenseSection.licenses.map((license, index) => (
                <ParagraphSection
                  customClass={
                    isMobile
                      ? `${CONTAINER}-easeIn ${
                          index < 2 || expandLicense ? `${CONTAINER}-show` : `${CONTAINER}-hidden`
                        }`
                      : ``
                  }
                  key={license.title}
                  sectionTitle={license.title}
                  sectionContent={license.paragraphs}
                />
              ))}
              {mobileExpandButton(expandLicense, setExpandLicense)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WaterIntroduction;
