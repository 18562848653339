// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './loginModalBody.scss';

// Components
import LoginButton from '../login/LoginButton';
import azureB2CHelper from '../../helpers/AzureB2CHelper';

const component = 'login-modal-body';

export default function LoginModalBody({ action = 'set', type = 'alert' }) {
  return (
    <div data-testid={component} className={component}>
      {action && (
        <div className={`${component}-content`}>{`Please sign in to ${action} ${type}`}</div>
      )}
      <LoginButton />
      <hr />
      Don&apos;t have an account?
      <div className={`${component}-link-container`}>
        <a
          href="#!"
          onClick={e => {
            e.preventDefault();
            azureB2CHelper.redirectToSignUpPage();
          }}
        >
          Sign Up
        </a>
      </div>
    </div>
  );
}

LoginModalBody.propTypes = {
  action: PropTypes.string,
  type: PropTypes.string,
};
