// Library
import React from 'react';
import PropTypes from 'prop-types';
import { isObject, isArray, isString } from 'lodash';

// Styles
import './ParagraphSection.scss';

// Components
import Link from '../rules/RulesLink';

/**
 * Purpose
 * ParagraphSection is simply a styled component used for
 * displaying paragraph and its title
 *
 * Check out props section for params
 */

const ParagraphSection = ({ sectionTitle, sectionContent = ['lorem ipsum'], customClass = '' }) => {
  const createMarkup = data => {
    return { __html: data };
  };

  const sectionMaker = (paragraph, index) => {
    switch (true) {
      case isArray(paragraph):
        return (
          <div key={`paragraph-${index}`} className="paragraph-section-content-sentence">
            {paragraph.map((word, i) =>
              isObject(word) ? (
                <Link
                  key={`${sectionTitle}-${i}`}
                  link={word.link}
                  linkText={word.linkText}
                  text={word.text}
                  targetId={word.targetId}
                  disableMobileCheck={false}
                />
              ) : (
                sentenceMaker(word, i)
              ),
            )}
          </div>
        );
      case isString(paragraph):
        return (
          <p
            className="paragraph-section-content"
            key={`${sectionTitle}-${index}`}
            dangerouslySetInnerHTML={createMarkup(paragraph)}
          />
        );
      case isObject(paragraph):
        return (
          <Link
            key={`${sectionTitle}-${index}`}
            link={paragraph.link}
            linkText={paragraph.linkText}
            text={paragraph.text}
            targetId={paragraph.targetId}
            disableMobileCheck={false}
          />
        );
      default:
        break;
    }
  };

  const sentenceMaker = (word, index) => {
    switch (true) {
      case isString(word):
        return (
          <p
            className="paragraph-section-content-sentence-content"
            key={`${sectionTitle}-${index}`}
            dangerouslySetInnerHTML={createMarkup(word)}
          />
        );
      case isObject(word):
        return (
          <Link
            key={`${sectionTitle}-${index}`}
            link={word.link}
            linkText={word.linkText}
            text={word.text}
            disableMobileCheck
          />
        );
      default:
        break;
    }
  };

  return (
    <div className={`paragraph-section ${customClass}`}>
      <p dangerouslySetInnerHTML={createMarkup(sectionTitle)} className="paragraph-section-title" />
      {sectionContent.map((paragraph, index) => sectionMaker(paragraph, index))}
    </div>
  );
};

export default ParagraphSection;

/* Props */
ParagraphSection.propTypes = {
  sectionTitle: PropTypes.string,
  sectionContent: PropTypes.array,
  customClass: PropTypes.string,
};
