const GAUGE = 'StreamGauge';
const BORE = 'Groundwater';
const DAM = 'Storage';
const WEIR = 'Weirs';
const METRO_DAM = 'Metro dam storage';
const METEOROLOGICAL = 'Meteorological';
const UPDATES = 'Updates';
const LICENCES = 'Licences';
const TRADING = 'Trading';
const GRAPH_DOWNLOAD = 'Graph & Download';
const SITE_INFO = 'Details';
const RATING = 'Ratings Curve';
const FLOW_SUMMARY = 'Flow Summary';
const LOW_FLOW = 'Low Flow Analysis';
const CROSS_SECTION = 'Cross Section';
const RAINFALL_STATS = 'Rainfall Statistics';
const RAINFALL_SUMMARY = 'Rainfall Summary';
const GROUNDWATER_SITES = 'Bore Construction Reports';

export default Object.freeze({
  GAUGE: GAUGE,
  BORE: BORE,
  DAM: DAM,
  METRO_DAM: METRO_DAM,
  WEIR: WEIR,
  METEOROLOGICAL: METEOROLOGICAL,
  UPDATES: UPDATES,
  LICENCES: LICENCES,
  TRADING: TRADING,
  GROUNDWATER_SITES: GROUNDWATER_SITES,
  TAB_OPTIONS: [
    { id: GAUGE, name: 'Gauge', active: true },
    { id: BORE, name: 'Bore', active: true },
    { id: DAM, name: 'Dam', active: true },
    { id: METRO_DAM, name: 'Metro Dam', active: true },
    { id: WEIR, name: 'Weir', active: true },
    { id: METEOROLOGICAL, name: 'Meteorological', active: true },
    { id: UPDATES, name: 'Updates', active: true },
    { id: GROUNDWATER_SITES, name: GROUNDWATER_SITES, active: true },
    // { id: LICENCES, name: 'Licences', active: false },
    // { id: TRADING, name: 'Trading Prices', active: false },
  ],
  API_SITE_TYPE: {
    [GAUGE]: GAUGE,
    [BORE]: BORE,
    [DAM]: DAM,
    [METRO_DAM]: 'Storage',
    [WEIR]: WEIR,
    [METEOROLOGICAL]: METEOROLOGICAL,
  },
  GRAPH_DOWNLOAD: GRAPH_DOWNLOAD,
  SITE_INFO: SITE_INFO,
  RATING: RATING,
  FLOW_SUMMARY: FLOW_SUMMARY,
  LOW_FLOW: LOW_FLOW,
  CROSS_SECTION: CROSS_SECTION,
  RAINFALL_STATS: RAINFALL_STATS,
  RAINFALL_SUMMARY: RAINFALL_SUMMARY,
  STATION_INFO_TYPE: {
    [GAUGE]: [
      { id: GRAPH_DOWNLOAD, name: GRAPH_DOWNLOAD, active: true },
      { id: SITE_INFO, name: SITE_INFO, active: true },
      {
        id: RATING,
        name: RATING,
        active: true,
        popupText: 'Please select only one site at a time.',
      },
      // { id: FLOW_SUMMARY, name: FLOW_SUMMARY, active: false },
      // { id: LOW_FLOW, name: LOW_FLOW, active: false },
      // { id: CROSS_SECTION, name: CROSS_SECTION, active: false },
    ],
    [BORE]: [
      { id: GRAPH_DOWNLOAD, name: GRAPH_DOWNLOAD, active: true },
      { id: SITE_INFO, name: SITE_INFO, active: true },
    ],
    [WEIR]: [
      { id: GRAPH_DOWNLOAD, name: GRAPH_DOWNLOAD, active: true },
      { id: SITE_INFO, name: SITE_INFO, active: true },
    ],
    [DAM]: [
      { id: GRAPH_DOWNLOAD, name: GRAPH_DOWNLOAD, active: true },
      { id: SITE_INFO, name: SITE_INFO, active: true },
      {
        id: RATING,
        name: RATING,
        active: true,
        popupText: 'Please select only one site at a time.',
      },
    ],
    [METRO_DAM]: [
      { id: GRAPH_DOWNLOAD, name: GRAPH_DOWNLOAD, active: true },
      { id: SITE_INFO, name: SITE_INFO, active: true },
      // { id: RATING, name: RATING, active: false },
    ],
    [METEOROLOGICAL]: [
      { id: GRAPH_DOWNLOAD, name: GRAPH_DOWNLOAD, active: true },
      { id: SITE_INFO, name: SITE_INFO, active: true },
      // { id: RAINFALL_STATS, name: RAINFALL_STATS, active: false },
      // { id: RAINFALL_SUMMARY, name: RAINFALL_SUMMARY, active: false },
    ],
  },
  DATA_TYPES: [
    // { id: 'combined', name: 'Combined', disabled: false },
    { id: 'autoqc', name: 'Operational', disabled: false, subText: '(data since 1 Jan 2023)' },
    { id: 'archive', name: 'Archive', disabled: false, subText: '(long term data)' },
  ],
  INTERVAL_SELECTIONS: [
    { id: '15-minute', name: 'instantaneous', disabled: false },
    { id: 'hourly', name: 'hourly', disabled: false },
    { id: 'daily', name: 'daily', disabled: false },
    { id: 'monthly', name: 'monthly', disabled: false },
  ],
  TIME_PERIOD: [
    { id: '0', name: 'Custom date', disabled: false, unit: 'days', days: 0 },
    { id: '1', name: '1 Day', disabled: false, unit: 'days', days: 1 },
    { id: '7', name: '1 Week', disabled: false, unit: 'days', days: 7 },
    { id: '30', name: '1 Month', disabled: false, unit: 'months', months: 1 },
    { id: '365', name: '1 Year', disabled: false, unit: 'months', months: 12 },
    { id: '3650', name: '10 Years', disabled: false, unit: 'months', months: 120 },
    { id: '10950', name: '30 Years', disabled: false, unit: 'months', months: 360 },
    { id: '18250', name: '50 Years', disabled: false, unit: 'months', months: 600 },
  ],
  BUTTONS: {
    disabled: { type: 'DISABLED', displayName: 'GET' },
    get: { type: 'GET', displayName: 'GET' },
    request: { type: 'REQUEST', displayName: 'REQUEST' },
  },
  DATE_FORMAT: 'YYYY-MM-DD',
  NUM_OF_REQUESTS: 10,
  DOWNLOAD_LIMIT: {
    '15-minute': { days: 12 * 31, sites: 1, variables: 4, months: 12.025 },
    hourly: { days: 12 * 31, sites: 1, variables: 4, months: 12.025 },
    daily: { days: 601 * 31, sites: 20, variables: 4, months: 601 },
    monthly: { days: 601 * 31, sites: 20, variables: 4, months: 601 },
    manual: { days: 601 * 31, sites: 20, variables: 4, months: 601 },
  },
  DATE_FORMATS: {
    daily: {
      tooltip: 'YYYY-MM-DD 09:00:00',
      xAxis: 'DD MMM YYYY',
      unitName: 'day',
    },
    monthly: {
      tooltip: 'YYYY MMM',
      xAxis: 'YYYY MMM',
      unitName: 'month',
    },
    hourly: {
      tooltip: 'YYYY-MM-DD HH:mm:ss',
      xAxis: 'DD MMM HH:mm',
      unitName: 'hour',
    },
    '15-minute': {
      tooltip: 'YYYY-MM-DD HH:mm:ss',
      xAxis: 'DD MMM HH:mm',
      unitName: 'minute',
    },
    manual: {
      tooltip: 'YYYY-MM-DD HH:mm:ss',
      xAxis: 'DD MMM YYYY',
      unitName: 'day',
    },
  },
  METRO_DAM_VARIABLE: {
    Inflow: 'totalCatchmentInflow',
    Eflow: 'actualReleaseMade',
  },
  GRAPH_COLOR_CODES: [
    '#0054a6',
    '#f36c21',
    '#52d370',
    '#ffcd34',
    '#63cbe8',
    '#2677ad',
    '#7e3fe8',
    '#a63961',
    '#9fc0b0',
    '#605345',
    '#2885a5',
    '#cf7d19',
    '#c53b7b',
    '#245c98',
    '#1e7449',
    '#d12800',
    '#f36c21',
    '#a00ab9',
    '#db8e92',
    '#017fea',
  ],
});
