import apiConstants from '../../../../../constants/WaterDataAPIConstants';
import _, { isEmpty, groupBy, startCase, unionWith } from 'lodash';
import { formatData, formatGroundwaterManualData } from '../../../../../helpers/WaterDataUtils';
import { toSentenceCase } from '../../../../../helpers/Utils';
import moment from 'moment';
import constants from '../DataDownloadConstants';
import { getSiteDownloadData } from '../../../../../helpers/WaterDataApiHelper.jsx';

const dateTimeFormat = apiConstants.API_DATE_FORMAT;
const dateFormat = dateTimeFormat.split(' ')[0];

export const formatFromDate = date => {
  if (date) {
    return moment(date).format(`${dateFormat} HH:mm`);
  }

  return '';
};

export const formatToDate = date => {
  if (date) {
    if (moment(date).format(dateFormat) === moment().format(dateFormat)) {
      return moment(date).format(dateTimeFormat);
    } else {
      return moment(date).format(`${dateFormat} HH:mm`);
    }
  }

  return '';
};

export const formatDownloadData = (data, qualities, setDownloadData) => {
  data = data.map(item => {
    let result = {};
    Object.keys(item).map(
      itemKey =>
        (result[toSentenceCase(itemKey.replace(/([a-z])([A-Z])/g, '$1 $2'))] = item[itemKey]),
    );
    return result;
  });
  setDownloadData({ data, qualities });
};

export const formatGraphData = (data, interval, setGraphData) => {
  const newData = data.map(item => {
    const newVariableName = item.variableName.split('.')[0];
    const sliceIndex = item.variableName.includes('.') ? -1 : newVariableName.length;
    return {
      ...item,
      groupedVariables: item.variableName.includes('BelowSurface')
        ? `${item.siteId}-${newVariableName.slice(0, sliceIndex).replace('Measured', '')}`
        : item.variableName,
      siteId: `${item.siteId}-${item.variableName}`,
      uniqueSiteId: item.siteId,
    };
  });
  let grouped = groupBy(newData, 'groupedVariables');
  let timeGaps = {};
  Object.entries(grouped).map(([dataKey, dataValue]) => {
    timeGaps[dataKey] =
      interval === 'manual'
        ? formatGroundwaterManualData(dataValue).sort((a, b) => {
            return (
              moment(a.timeStamp, 'DD-MMM-YYYY HH:mm').valueOf() -
              moment(b.timeStamp, 'DD-MMM-YYYY HH:mm').valueOf()
            );
          })
        : dataValue;
  });
  if (interval === 'manual') {
    setGraphData(timeGaps);
  } else {
    for (let key in grouped) {
      const combined = unionWith(grouped[key], timeGaps[key], (arrObj, otherObj) => {
        return arrObj.timeStamp === otherObj.timeStamp && arrObj.siteId === otherObj.siteId;
      });
      const unit = constants.DATE_FORMATS[interval]?.unitName;
      const formatted = formatData(combined, true, true, unit, 'uniqueSiteId');
      let groupedResult = _(formatted).groupBy('timeStamp').map(_.spread(_.assign)).value();
      let sortedResult = groupedResult.sort((a, b) => {
        return a.timeStamp - b.timeStamp;
      });
      grouped[key] = sortedResult;
    }
    setGraphData(grouped);
  }
};

export const genDownloadData = (downloadData, downloadType, siteMetadata = [], interval) => {
  if (!downloadData && (!downloadData.data || isEmpty(downloadData.data))) {
    return { resources: [] };
  }
  const groupedMetadata = groupBy(siteMetadata, 'siteId');
  Object.entries(groupedMetadata).map(([key, value]) => {
    groupedMetadata[key] = [
      `Site ID: ${value[0].siteId}`,
      `Site name: ${value[0].siteName}`,
      `Site commence: ${value[0].siteCommence ? value[0].siteCommence : '-'}`,
      `Site cease: ${value[0].siteCease ? value[0].siteCease : '-'}`,
      `Zone: ${value[0].zone ? value[0].zone : '-'}`,
      `IPART Regulated: ${value[0].regulated ? (value[0].regulated === 1 ? 'REG' : 'UNREG') : '-'}`,
      `Current Site Hydrometric: ${
        value[0].active ? (value[0].active === 1 ? 'Active' : 'Not active') : '-'
      }`,
      `Latitude: ${value[0].latitude ? value[0].latitude : '-'}`,
      `Longitude: ${value[0].longitude ? value[0].longitude : '-'}`,
      `Lat/Long Datum: ${value[0].latLongDatum ? value[0].latLongDatum : '-'}`,
      `Elevation: ${value[0].elevation ? value[0].elevation : '-'}`,
      `Zero Gauge: ${value[0].zerogauge ? value[0].zerogauge : '-'}`,
      `Datum: ${value[0].datum ? value[0].datum : '-'}`,
      `Control: ${value[0].control ? value[0].control : '-'}`,
      `CTF Level: ${value[0].ctfLevel !== '' ? value[0].ctfLevel : '-'}`,
      `Variables monitored: ${
        !isEmpty(value[0].variablesMonitored)
          ? Object.values(value[0].variablesMonitored)
              .map(item => startCase(item).trim())
              .join(`, `)
          : '-'
      }`,
      `Water source: ${
        !isEmpty(value[0].waterSourceName)
          ? Object.values(value[0].waterSourceName).join(', ')
          : '-'
      }`,
    ];
  });

  const qualities = !isEmpty(downloadData.qualities)
    ? Object.entries(downloadData.qualities).map(([key, value]) => {
        return { qualityCode: key, qualityCodeDef: value };
      })
    : [];

  const groupedByStation = groupBy(downloadData.data, 'Site id');
  let newStationData = [];
  let dataKeys = ['Site ID', 'Timestamp'];
  if (interval === 'manual' && downloadType === 'Groundwater') {
    dataKeys = dataKeys.concat(['Hole', 'Pipe', 'Data source']);
  }
  Object.entries(groupedByStation).map(([stationKey, stationValue]) => {
    const groupedByVariable = groupBy(stationValue, 'Variable name');
    let newStationValue = [];
    Object.entries(groupedByVariable).map(([key, value]) => {
      let newValue = value.map(valueItem => {
        const unit = valueItem['Unit of measure'];
        const formattedKey = `${
          key.toLowerCase() === 'ph' ? 'pH' : startCase(key).trim()
        } (${unit})`;
        if (!dataKeys.find(dataKey => dataKey === formattedKey)) {
          dataKeys.push(formattedKey);
          dataKeys.push(`Quality code (${formattedKey.replace(` (${unit})`, '')})`);
        }
        let newValueItem = {
          'Site ID': stationKey,
          Timestamp: valueItem['Time stamp'],
          [formattedKey]: valueItem.Value,
          [`Quality code (${formattedKey.replace(` (${unit})`, '')})`]: valueItem['Quality code'],
          ...(valueItem['Hole'] && { Hole: valueItem['Hole'] }),
          ...(valueItem['Pipe'] && { Pipe: valueItem['Pipe'] }),
          ...(valueItem['Data source'] && { 'Data source': valueItem['Data source'] }),
        };
        return newValueItem;
      });
      newStationValue = _.values(
        _.merge(_.keyBy(newStationValue, 'Timestamp'), _.keyBy(newValue, 'Timestamp')),
      );
    });
    if (downloadType === 'StreamGauge') {
      const metaData = groupedMetadata[newStationValue[0]['Site ID']];
      if (metaData) {
        metaData.map((value, index) => {
          if (newStationValue[index]) {
            newStationValue[index]['Site info'] = value;
          } else {
            newStationValue.push({ 'Site info': value });
          }
        });
      }
    }
    newStationData = newStationData.concat(newStationValue);

    //Generate additional columns for quality code definition
    qualities.map((value, index) => {
      if (newStationData[index]) {
        newStationData[index][''] = null;
        newStationData[index]['Quality code'] = value.qualityCode;
        newStationData[index]['Quality code definition'] = value.qualityCodeDef;
        newStationData[index][''] = null;
      } else {
        newStationData.push({ '': null });
        newStationData.push({ 'Quality code': value.qualityCode });
        newStationData.push({ 'Quality code definition': value.qualityCodeDef });
        newStationData.push({ '': null });
      }
    });
  });
  // Generate the headers of the CSV file
  const headers = dataKeys.map(dataKey => {
    return { label: dataKey, key: dataKey };
  });
  headers.push({ label: '', key: '' });
  headers.push({ label: 'Quality code', key: 'Quality code' });
  headers.push({ label: 'Quality code definition', key: 'Quality code definition' });
  headers.push({ label: '', key: '' });
  if (downloadType === 'StreamGauge') headers.push({ label: 'Site info', key: 'Site info' });
  return { resources: newStationData, headers };
};

export const formatMetroDamStorageData = (data, variable, hydroKey, selectedStations) => {
  const formatted = data.map(dataItem => {
    const row = { timeStamp: dataItem.timeStamp };
    row.value = dataItem[variable];
    row.unitOfMeasure = 'ML';
    row.variableName = hydroKey;
    row.siteId = selectedStations.find(stationItem =>
      stationItem.name.includes(dataItem.storageName),
    )?.id;
    return row;
  });
  return formatted;
};

export const prepGraphParams = (hydrometric, stations, interval) => {
  const newHydrometric = hydrometric.map(item => {
    const newVariableName = item.name.split('.')[0];
    const sliceIndex = item.name.includes('.') ? -1 : newVariableName.length;
    return {
      ...item,
      id: newVariableName.slice(0, sliceIndex).replace('Measured', '').replaceAll(' ', ''),
    };
  });
  const hydrometricList = !isEmpty(hydrometric) ? hydrometric.map(item => item.id).join(',') : '';
  const formattedHydrometricList = !isEmpty(newHydrometric)
    ? Array.from(new Set(newHydrometric.map(item => item.id))).join(',')
    : '';
  const formattedHydrometric = hydrometricList.replace('AHD', '');
  const isFlowData = ['Inflow', 'Eflow'].includes(hydrometricList);
  const siteNames = isFlowData
    ? stations.map(item => item.name.split('-')[1].trim().split(' ')[0])
    : '';
  let siteIds = stations.map(item => item.id).join(',');
  const toShowFlag = (isFlowData || siteIds) && hydrometric && interval;
  const graphTitleSite = isFlowData ? siteNames : siteIds.split(',').join(', ');
  return {
    hydrometricList,
    formattedHydrometricList,
    formattedHydrometric,
    isFlowData,
    siteNames,
    siteIds,
    toShowFlag,
    graphTitleSite,
  };
};

export const prepGraphDataRequestParams = (
  downloadType,
  isFlowData,
  hydrometricList,
  interval,
  moreThanOneSite = false,
) => {
  const siteType = ['Groundwater', 'Meteorological'].includes(downloadType)
    ? downloadType.toLowerCase()
    : isFlowData
    ? hydrometricList.toLowerCase()
    : 'site';
  const dataInterval = ['daily', 'monthly', 'manual'].includes(interval.id)
    ? interval.id
    : 'download';
  const siteHydrometric = isFlowData ? '' : hydrometricList;
  let endpoint = isFlowData
    ? apiConstants.WATER_DATA_TIMESERIES[siteType]
    : ['Groundwater', 'Meteorological'].includes(downloadType) && moreThanOneSite
    ? apiConstants.WATER_DATA_TIMESERIES[siteType].monthly
    : apiConstants.WATER_DATA_TIMESERIES[siteType].download;

  return { siteType, dataInterval, siteHydrometric, endpoint };
};

export const fetchGraphData = async (
  siteIds,
  interval,
  siteHydrometric,
  from,
  to,
  endpoint,
  siteNames,
  dataType,
  isFlowData,
  downloadType,
  signal,
) => {
  try {
    let data = [];
    let qualities = {};
    const rainfallHydro = 'Rainfall';
    const hasRainfall = siteHydrometric.includes(rainfallHydro);
    const fromDate = formatFromDate(from);
    const toDate = formatToDate(to);
    const hydroList = siteHydrometric
      .split(',')
      .filter(el => el !== rainfallHydro)
      .join(',');

    if (!isFlowData) {
      const siteData =
        hydroList !== '' &&
        (await getSiteDownloadData(
          siteIds,
          interval.id,
          hydroList,
          fromDate,
          toDate,
          null,
          endpoint,
          siteNames,
          dataType.id,
          signal,
          downloadType.toLowerCase(),
        ));
      if (siteData && !isEmpty(siteData.records)) {
        data = data.concat(siteData.records);
        qualities = { ...qualities, ...siteData.qualities };
      }
      if (hasRainfall) {
        const params = prepGraphDataRequestParams(
          'Meteorological',
          false,
          rainfallHydro,
          interval,
          siteIds.split(',').length > 1,
        );
        const rainfallData = await getSiteDownloadData(
          siteIds,
          interval.id,
          rainfallHydro,
          fromDate,
          toDate,
          null,
          params.endpoint,
          siteNames,
          dataType.id,
          signal,
          downloadType.toLowerCase(),
        );
        if (rainfallData && !isEmpty(rainfallData.records)) {
          data = data.concat(rainfallData.records);
          qualities = { ...qualities, ...rainfallData.qualities };
        }
      }
    } else {
      const requests = siteNames.map(siteName => {
        return getSiteDownloadData(
          siteIds,
          interval.id,
          siteHydrometric,
          fromDate,
          toDate,
          null,
          endpoint,
          siteName,
          dataType.id,
          signal,
          downloadType.toLowerCase(),
          'records',
        );
      });

      const rawResult = await Promise.all(requests);
      data = rawResult.reduce((acc, item) => {
        acc = [...item, ...acc];
        return acc;
      }, []);
    }
    return { data, qualities };
  } catch (e) {
    return [];
  }
};
