// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './chartContainer.scss';

// Components
import ChartHeader from '../chartHeader/ChartHeader';

// Constant
const container = 'chart-container';

export default function ChartContainer({
  pageTitle,
  children,
  graphTitle,
  graphIntro,
  divId = '',
}) {
  return (
    <div id={divId} className={container}>
      <div className={`${container}-title row`}>
        <span>{pageTitle}</span>
      </div>
      <div className={`${container}-body`}>
        <div className={`${container}-chart`}>
          <ChartHeader title={graphTitle} desc={graphIntro} />
          {children}
        </div>
      </div>
    </div>
  );
}

ChartContainer.propTypes = {
  pageTitle: PropTypes.string,
  children: PropTypes.any,
  graphTitle: PropTypes.string,
  graphIntro: PropTypes.string,
  divId: PropTypes.string,
};
