import React from 'react';
import PropTypes from 'prop-types';

const DottedLineSvg = ({ strokeColor }) => {
  return (
    <svg viewBox="0 0 20 8" width="20" height="4" xmlns="http://www.w3.org/2000/svg">
      <path strokeWidth="8" strokeDasharray="5,5" d="M5 5 30 5" fill="none" stroke={strokeColor} />
    </svg>
  );
};

export default DottedLineSvg;

/* Props */
DottedLineSvg.propTypes = {
  /**
   * This change the fill color
   */
  strokeColor: PropTypes.string,
};
