// Library
import React, { useState, useEffect, useContext } from 'react';
import { isEmpty, isNil } from 'lodash';

// Style
import './waterSiteGraph.scss';

// Context
import { MiniAppContext } from '../../contexts/MiniAppContext';

// Components
import GeneralDropdown from '../dropDown/GeneralDropdown';
import DotSvg from './DotSvg';
import WaterSiteInfoLineChart from './WaterSiteInfoLineChart';

// Helper
import { formatDate, getMonthListByRange } from '../../helpers/TimeUtils';
import { getDamResourcesById } from '../../helpers/ApiHelper';
import { getSurfaceWaterData } from '../../helpers/WaterDataApiHelper';
import { formatNumberDecimals, sortArrayByKeyAsc } from '../../helpers/Utils';

// Constant
import WaterAPIConstant from '../../constants/WaterDataAPIConstants';
import constants from '../../constants/Constants';
import config from '../../configs/featureToggleConfig.json';
const component = 'water-site-graph';
const getDataFromWaterDataApi = config['water-data-api'].dams.active;

export default function StorageGraph() {
  const stateProps = {
    NSW: { dataKey: 'NSW', color: '#0054a6' },
    SA: { dataKey: 'SA', color: '#007AB5' },
    VIC: { dataKey: 'VIC', color: '#95B3D7' },
  };
  const currentMonth = {
    id: formatDate(new Date(), 'YYYY-MM-DD', 'YYYY-MM-DD'),
    name: formatDate(new Date(), 'YYYY-MM-DD', 'MMMM YYYY'),
  };
  const { station } = useContext(MiniAppContext);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [activeMonth, setActiveMonth] = useState(currentMonth);
  const [monthRange, setMonthRange] = useState([]);
  const [dropdownItems, setDropdownItems] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [volumeLabelItems, setVolumeLabelItems] = useState([]);

  // Life Cycle
  useEffect(() => {
    const range = getMonthListByRange(12, currentMonth.id, 'MMMM YYYY', 'month');
    getData(range);
    setDropdownItems(range);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [station]);

  // WaterData Source
  const parseWaterData = data => {
    const result = [];
    data.map(item => {
      const matched = result.find(el => el.timeStamp === item.timeStamp);
      if (!matched) {
        result.push({
          date: formatDate(item.timeStamp, WaterAPIConstant.API_DATE_FORMAT, 'YYYY-MM-DD'),
          NSW: item.value / 1000,
        });
      } else {
        matched['NSW'] = item.value;
      }
    });
    return result;
  };

  const fetchFromWaterData = async range => {
    const dateFormat = constants.STORAGE_DATE_FORMAT;
    const from = formatDate(range[0].id, 'YYYY-MM-DD', dateFormat);
    const to = formatDate(range[range.length - 1].id, 'YYYY-MM-DD', dateFormat);
    const result = await getSurfaceWaterData(
      station.station_id,
      'monthly',
      from,
      to,
      'ActiveStorageVolume',
    );

    return result ? parseWaterData(result) : [];
  };

  // WI Source
  const parseWIData = data => {
    const result = [];
    data.map(item => {
      const matched = result.find(el => el.date === item.date);
      if (!matched) {
        result.push({
          date: item.date,
          [item.state]: { value: item.volume / 1000 },
        });
      } else {
        matched[item.state] = { value: item.volume };
      }
    });
    return result.reverse();
  };

  const fetchFromWI = async range => {
    const from = formatDate(range[0].id, 'YYYY-MM-DD', 'YYYY-MM');
    const to = formatDate(range[range.length - 1].id, 'YYYY-MM-DD', 'YYYY-MM');
    const result = await getDamResourcesById(station.station_id, from, to);

    if (result.data) {
      const parsed = parseWIData(result.data);
      return parsed;
    } else {
      return [];
    }
  };

  /**
   *  Fetch and combine data from WI and WaterData
   * 	Only use data from WI if and only if the station is Lake Vic
   * @param {*} range
   * @returns {Array} result
   */
  const blendDataFromSources = async range => {
    let result;

    if (!getDataFromWaterDataApi || station.station_id == constants.LAKE_VICTORIA) {
      const WIData = await fetchFromWI(range);
      result = WIData;
    } else {
      const waterData = await fetchFromWaterData(range);
      result = waterData;
    }

    return result;
  };

  const getData = range => {
    (async () => {
      const result = await blendDataFromSources(range);
      setGraphData(result);
      setMonthRange(range);
      getVolumeLabel(result, range);
      setSelectedMonth(range[range.length - 1]);
      setActiveMonth(range[range.length - 1]);
    })();
  };

  // Volume Label
  const getVolumeLabel = (resourceData, range) => {
    const current = formatDate(range[range.length - 1].id, 'YYYY-MM-DD', 'YYYY-MM');

    const value = resourceData.find(
      item => formatDate(item.date, 'YYYY-MM-DD', 'YYYY-MM') === current,
    );

    if (!isEmpty(value)) {
      setVolumeLabelItems(genVolumeLabel(value));
    } else {
      setVolumeLabelItems(null);
    }
  };

  const genVolumeLabel = obj => {
    let result = [];
    if (Object.entries(obj).length > 2) {
      Object.entries(obj).map(item => {
        if (item[0] !== 'date') {
          result.push({
            label: `Storage Volume (${item[0]})`,
            value: item[1],
            color: stateProps[item[0]].color,
          });
        }
      });
    } else {
      result.push({
        label: `Storage Volume`,
        value: obj.NSW,
        color: stateProps.NSW.color,
      });
    }
    return sortArrayByKeyAsc(result, 'label');
  };

  // OnClick Event
  const onMonthSelected = month => {
    const range = getMonthListByRange(12, month.id, 'MMMM YYYY', 'month');
    setSelectedMonth(month);
    getData(range);
  };

  const onLineClicked = data => {
    if (data && data.activePayload && data.activePayload[0]) {
      const payload = data.activePayload[0].payload;
      setVolumeLabelItems(genVolumeLabel(payload));
      const dateItem = {
        id: formatDate(payload.date, 'YYYY-MM-DD', 'YYYY-MM-DD'),
        name: formatDate(payload.date, 'YYYY-MM-DD', 'MMMM YYYY'),
      };
      setActiveMonth(dateItem);
    }
  };

  // Mini Component
  const VolumeLabel = ({ index, item }) => {
    return (
      <React.Fragment key={index}>
        <div className={`${component}-subtitle`}>
          <div>
            <DotSvg fillColor={item.color} />
            <span>{item.label}</span>
          </div>
        </div>
        <div className={`${component}-volume`}>
          {`${!isNil(item?.value.value) ? formatNumberDecimals(item.value.value) : '-'} GL`}
        </div>
      </React.Fragment>
    );
  };

  const GraphMonthRange = () => {
    return (
      <div className={`${component}-storage-date`}>
        {monthRange.map((month, index) => (
          <div key={index}>
            {(index === 0 || index === monthRange.length - 1) &&
              formatDate(month.id, 'YYYY-MM-DD', 'YYYY')}
            <div
              className={
                formatDate(month.id, 'YYYY-MM-DD', 'YYYY-MM') ===
                formatDate(activeMonth.id, 'YYYY-MM-DD', 'YYYY-MM')
                  ? `${component}-storage-date-selected`
                  : ''
              }
            >
              {formatDate(month.id, 'YYYY-MM-DD', 'MMM')}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <div className={component}>
        <div className={`${component}-storage-title`}>12 months prior to</div>
        {!isEmpty(dropdownItems) && (
          <GeneralDropdown
            menuItem={dropdownItems}
            selectedItem={selectedMonth}
            onItemClick={item => onMonthSelected(item)}
            borderClass={'dam-storage'}
          />
        )}
        {!isEmpty(volumeLabelItems) &&
          volumeLabelItems.map((item, index) => <VolumeLabel key={index} item={item} />)}
        <GraphMonthRange />
        <div className={`${component}-graph`}>
          {!isEmpty(graphData) && (
            <WaterSiteInfoLineChart
              margin={{ right: 15, left: 15, top: 15, bottom: 15 }}
              data={graphData}
              chartProps={stateProps}
              onLineClicked={onLineClicked}
              unit={'GL'}
              yAxisWidth={20}
              tickOverflow={true}
            />
          )}
        </div>
      </div>
      <div className={`${component}-gap`} />
    </>
  );
}
