// Library
import { isBoolean } from 'lodash';
import React, { useState, createContext } from 'react';

// Helpers
import { setMapHeightById } from '../helpers/Utils';

export const MapContext = createContext();

export default function MapContextProvider(props) {
  const [markerSelected, setMarkerSelected] = useState();
  const [markerType, setMarkerType] = useState();
  const [mapExpanded, setMapExpanded] = useState(true);

  function toggleMarker(marker, type = '') {
    if (marker) {
      setMarkerSelected(marker);
      setMarkerType(type);
    }
  }

  function expandMap(expand) {
    if (!isBoolean(expand)) return;
    if (expand) {
      setMapHeightById('map-container', '75vh');
    } else {
      setMapHeightById('map-container', '360px');
    }
    setMapExpanded(expand);
  }

  return (
    <MapContext.Provider
      value={{ markerSelected, toggleMarker, markerType, mapExpanded, expandMap }}
    >
      {props.children}
    </MapContext.Provider>
  );
}
