// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './informationButton.scss';

// Assets
import InfoIcon from '../../image/icons/InfoIcon';

export default function InformationButton({ btnClassName, targetDataId, iconColor }) {
  return (
    <button
      type="button"
      className={`info-button ${btnClassName ? btnClassName : ''}`}
      data-toggle="modal"
      data-target={targetDataId}
    >
      <InfoIcon iconColor={iconColor} />
    </button>
  );
}

InformationButton.propTypes = {
  btnClassName: PropTypes.string,
  targetDataId: PropTypes.string,
  iconColor: PropTypes.string,
};
