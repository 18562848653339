// Libraries
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

// Styles
import './checkboxBtnListDropdown.scss';

// Component
import CheckboxList from '../checkboxBtnList/CheckboxBtnList';
import { Button } from '../button/Button';

// Helper
import { useOutsideClickDetect } from '../../../helpers/UIBehaviourHelper';

// Constant
const component = 'checkbox-list-dropdown';

/**
 * The purpose of the CheckboxListDropdown component is to provide a dropdown interface with a list of checkbox options.
 * It allows users to select multiple options from the list.
 */
const CheckboxListDropdown = ({
  options,
  onChange,
  selectedOptions,
  itemFormatter = item => item,
  limit = Infinity,
  customStyles,
  isOpen,
  onClear,
  onClose,
}) => {
  const containerRef = useRef(null);
  useOutsideClickDetect(containerRef, e => {
    if (e.defaultPrevented) return;
    onClose();
  });

  return (
    <>
      {isOpen && (
        <div className={`${component} ${customStyles}`} ref={containerRef}>
          <div className={`${component}-container`}>
            <CheckboxList
              options={options}
              onChange={onChange}
              selectedOptions={selectedOptions}
              itemFormatter={itemFormatter}
              limit={limit}
              customStyles={`${component}-options`}
            />
            <div className={`${component}-btn-grp`}>
              {onClear && (
                <Button customStyles={`${component}-btn-grp-item-sec`} onClick={onClear}>
                  Clear All
                </Button>
              )}
              <Button
                customStyles={`${component}-btn-grp-item-prim`}
                onClick={() => {
                  onClose();
                }}
              >
                Done
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CheckboxListDropdown;

CheckboxListDropdown.propTypes = {
  /**
   * An array of objects, each with id (a required string), name (a required string),
   * and disabled (an optional boolean) properties. This represents the list of checkbox
   * options that will be rendered.
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  /** A function that is called when checkbox is clicked */
  onChange: PropTypes.func.isRequired,
  /** An array of objects, each with id (a required string), name (a required string),
   * and disabled (an optional boolean) properties. This represents the currently selected
   * checkbox options.
   */
  selectedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  /** An optional function that formats the label for each checkbox option.
   * It takes an item as its argument and returns a string.
   * If not provided, it defaults to returning the name property of the item.
   */
  itemFormatter: PropTypes.func,
  /**
   * An optional number that represents the maximum number of checkboxes that can be selected.
   * Defaults to Infinity.
   */
  limit: PropTypes.number,
  /** Custom CSS class names for styling the component */
  customStyles: PropTypes.string,
  /** Boolean indicating whether the dropdown is open */
  isOpen: PropTypes.bool,
  /** Function called when "Clear All" button is clicked */
  onClear: PropTypes.func,
  /** Function called when the dropdown is closed */
  onClose: PropTypes.func,
};
