// Lib
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Style
import './generalTabs.scss';

// Component
import GeneralTabItem from './GeneralTabItem';
const component = 'general-tabs';

const GeneralTabs = ({ tabs, onSelect = () => {}, defaultTab = '' }) => {
  const [selected, setSelected] = useState(defaultTab || tabs[0].id);
  return (
    <div className={`${component}-container`}>
      {tabs.map(tab => (
        <GeneralTabItem
          key={tab.id}
          tab={tab}
          onSelect={id => {
            onSelect(id);
            setSelected(id);
          }}
          isSelected={selected === tab.id}
          isDisabled={!tab.active}
        />
      ))}
    </div>
  );
};

export default GeneralTabs;

GeneralTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      active: PropTypes.bool,
    }),
  ).isRequired,
  onSelect: PropTypes.func,
  defaultTab: PropTypes.string,
};
