export default Object.freeze({
  DAM: {
    date: 'date',
    inflow: 'inflow',
    release: 'release',
    environmentalRelease: 'environmental_release',
    volume: 'volume',
    volumePerc: 'volume_perc',
  },
  WEIR: {
    storage: 'storage',
    level: 'level',
  },
  GAUGE: {
    temperature: 'temperature',
    flow_rate: 'flow_rate',
    level: 'level',
    salinity: 'salinity',
    dissolved_oxygen: 'dissolved_oxygen',
    dissolved_oxygen_saturation: 'dissolved_oxygen_saturation',
    do_2: 'do_2',
    pH: 'pH',
    rainfall: 'rainfall',
    turbidity: 'turbidity',
  },
  WATER_QUALITY: {
    dissolved_oxygen: 'dissolved_oxygen',
    dissolved_oxygen_saturation: 'dissolved_oxygen_saturation',
    pH: 'pH',
  },
});
