// Library
import React from 'react';
import PropTypes from 'prop-types';

// Style
import './river.scss';
// Constant
const container = 'river-location-river';

const River = ({ x, y, rotate, name, style, background, color }) => {
  return (
    <>
      <rect x={x - 10} y={y - 15} width="100" height="22" fill={background ? background : 'none'} />
      <text
        transform={rotate ? `rotate(${rotate} ${x},${y})` : ''}
        x={x}
        y={y}
        className={`${container} ${container}-${style}`}
        fill={color}
      >
        {name}
      </text>
    </>
  );
};

export default River;

River.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  rotate: PropTypes.number,
  name: PropTypes.string,
  style: PropTypes.string,
  background: PropTypes.string,
};
