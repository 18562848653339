// Library
import { useState, useEffect } from 'react';
import papaparse from 'papaparse';

// Helper
import { fetchDataFromApi } from '../../../helpers/ApiHelper';

// Constant
import constants from '../../../constants/Constants';
export const HEADERS = {
  SAMPLED: 'Pollutant',
  PH: 'pH',
  BOD: 'BOD',
  TSS: 'Total suspended solids',
  RECEIVED: 'Received',
  PUBLISHED: 'Published',
};
const HEADERS_RENAMED = {
  SampleTS: HEADERS.SAMPLED,
  pH: HEADERS.PH,
  BOD: HEADERS.BOD,
  SS: HEADERS.TSS,
  max_DataEntryTS: HEADERS.RECEIVED,
  max_pubdate: HEADERS.PUBLISHED,
};

export const useCSVData = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { DUCKMALOI_WATER_QUALITY_DOWNLOAD_LINK } = constants;

  useEffect(() => {
    const fetchCSV = async () => {
      setIsLoading(true);
      try {
        // Fetch config
        const params = {
          url: DUCKMALOI_WATER_QUALITY_DOWNLOAD_LINK,
          headers: {
            'content-type': 'text/csv;charset=UTF-8',
            'Access-Control-Allow-Origin': '*',
          },
        };

        const result = await fetchDataFromApi(params);
        // Parse CSV string to array with customed header
        const parsed = papaparse.parse(result, {
          header: true,
          transformHeader: headerName => {
            return HEADERS_RENAMED[headerName];
          },
        });

        // Removed comments and notes
        const trimmed = parsed.data.slice(1);
        setData(trimmed);
      } catch (e) {
        setData([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCSV();
  }, [DUCKMALOI_WATER_QUALITY_DOWNLOAD_LINK]);

  return { data, isLoading };
};
