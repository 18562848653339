// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

// Styles
import './Modal.scss';

const Modal = ({ id, title, body, footer, footerClassName, onClose }) => {
  if (onClose) {
    $(`#${id}`).on('hide.bs.modal', () => {
      onClose();
    });
  }

  return (
    <div
      className="modal fade"
      id={id}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="globalModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header no-border">
            <div className="modal-title">{title}</div>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              id="dismissMe"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {body && (
            <div data-testid="modal-body" className="modal-body no-border">
              {body}
            </div>
          )}
          {footer && (
            <div className={`modal-footer no-border ${footerClassName ? footerClassName : ''}`}>
              {footer}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;

Modal.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  footer: PropTypes.element,
  footerClassName: PropTypes.string,
};
