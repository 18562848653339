// Library
import React, { useEffect, useState, useContext } from 'react';
import { PieChart, Pie, Label, Cell } from 'recharts';
import moment from 'moment';
import { isEmpty, intersection } from 'lodash';

// Style
import './waterSiteInfo.scss';

// Context
import { MiniAppContext } from '../../contexts/MiniAppContext';

// Components
import Loader from '../loader/Loader';
import DataQualityText from '../generalComponents/dataQuality/DataQualityText';

// Helper
import { formatNumberDecimals, toSentenceCase, sortArrayByKeyDesc } from '../../helpers/Utils';
import { getLatestResourcesByDamId } from '../../helpers/ApiHelper';
import { formatStationData, formatSurfaceWaterData } from '../../helpers/ArrayUtils';
import { getSurfaceWaterData, getGroundWaterData } from '../../helpers/WaterDataApiHelper';
import { getCurrentDate, getDatesByPeriod } from '../../helpers/TimeUtils';

// Constants
import apiConstant from '../../constants/WaterDataAPIConstants';
import hydroConstant from '../../constants/HydrometricsConstants';

const component = 'water-site-info';
const PIE_CHART_COLORS = ['#0054a6', '#e0eaf4'];
const DAM_STATION_DATA_KEY = ['volume', 'inflow', 'release'];

const siteTypes = {
  Weirs: 'weir',
  StreamGauge: 'gauge',
  Groundwater: 'bore',
  Storage: 'dam',
};

const datetimeFormat = {
  bore: { query: 'DD-MMM-YYYY 00:00', input: 'YYYY-MM-DD', output: 'MMM YYYY' },
  gauge: { query: 'DD-MMM-YYYY HH:mm', input: 'YYYY-MM-DD HH:mm', output: 'DD.MM.YYYY hh:mma' },
  weir: { query: 'DD-MMM-YYYY HH:mm', input: 'YYYY-MM-DD', output: 'DD.MM.YYYY hh:mma' },
  dam: { query: 'DD-MMM-YYYY 09:00', input: 'YYYY-MM-DD', output: 'DD.MM.YYYY 09:mma' },
};

export default function WaterSiteInfo() {
  const { station } = useContext(MiniAppContext);
  const [resources, setResources] = useState();
  const [percentage, setPercentage] = useState();
  const [showLoader, setShowLoader] = useState(true);
  const isMetroDam = Boolean(apiConstant.METRO_DAMS.find(item => item.id === station.station_id));
  const stationType = station.station_type || siteTypes[station.siteType];
  const dateFormatByType = datetimeFormat[stationType];

  // Life Cycle
  useEffect(() => {
    setShowLoader(true);
    setResources({});
    setPercentage();
  }, [station]);

  useEffect(() => {
    (async () => {
      if (stationType) {
        let result;
        let formattedData;
        const siteId = station.station_id;
        const variableList = station.water_data_variables;
        const variables = {
          weir: ['StorageWaterLevel', 'TotalStorageVolume'],
          dam: apiConstant.DAM_STORAGE_VARIABLES,
          gauge: variableList && variableList.filter(item => item !== 'Rainfall'),
          bore: ['GroundwaterDepthBelowSurfaceLevel'],
        };

        if (stationType !== 'dam') {
          const interval = stationType === 'bore' ? 'months' : 'days';
          const startDate = getDatesByPeriod(-6, dateFormatByType.query, interval);
          const endDate = getCurrentDate(dateFormatByType.query);
          const variableList = variables[stationType]?.join(',');
          let resources =
            stationType === 'bore'
              ? await getGroundWaterData(siteId, 'monthly', startDate, endDate, variableList)
              : await getSurfaceWaterData(siteId, 'latest', null, null, variableList, '', true);
          resources = resources.map(item => {
            return {
              ...item,
              timeStamp: moment(item.timeStamp, 'DD-MMM-YYYY hh:mm').format(dateFormatByType.input),
            };
          });
          result = { resources: sortArrayByKeyDesc(resources, 'timeStamp') };
          result = formattedData = formatSurfaceWaterData(
            result,
            siteId,
            station.station_name,
            null,
            'YYYY-MM-DD 09:00',
          );
          delete formattedData.inflow;
          delete formattedData.release;
          if (stationType !== 'bore') delete formattedData.depth;
        } else {
          result = await getLatestResourcesByDamId(siteId);
          if (result.data) {
            if (!isEmpty(result.data.resources)) {
              result.data.resources.map(item => {
                DAM_STATION_DATA_KEY.map(dataKey => {
                  item[dataKey] = item[dataKey] ? item[dataKey] : null;
                });
                return item;
              });
            }
            formattedData = formatStationData(result, siteId, station.station_name);
          }
        }
        if (formattedData.volume_perc) {
          const volPerc = formattedData.volume_perc.value;
          if (!Number.isNaN(volPerc)) {
            setPercentage([
              { volume_perc: volPerc >= 100 ? 100 : volPerc },
              { volume_perc: 100 - (volPerc >= 100 ? 100 : volPerc) },
            ]);
          }
        } else if (formattedData.volume) {
          const volPerc = ((formattedData.volume.value * 1000) / result.data.full_volume) * 100;
          setPercentage([
            { volume_perc: volPerc >= 100 ? 100 : volPerc },
            { volume_perc: 100 - (volPerc >= 100 ? 100 : volPerc) },
          ]);
          formattedData.volume_perc = { value: volPerc };
        } else {
          setPercentage([]);
        }
        setResources(formattedData);
      }
      setShowLoader(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [station.id, stationType]);

  const checkValue = (value, type, decimals) => {
    let formatted = '-';
    const singleDecimal = type === 'perc';
    if (!Number.isNaN(value)) {
      formatted = formatNumberDecimals(value, false, singleDecimal, decimals);
    }
    const unit = hydroConstant.RIVER_PROPS.gauge.find(v => v.dataKey === type);
    switch (type) {
      case 'perc':
        return formatted;
      case 'water_level':
        return `${formatted} m below ground`;
      case 'volume':
      case 'inflow':
      case 'release':
        return `${formatted} GL`;
      default:
        return `${formatted} ${unit?.unit}`;
    }
  };

  // component
  const SiteResourceDetails = () => {
    const displayResources = [
      'inflow',
      'release',
      'level',
      'flow_rate',
      'depth',
      'temperature',
      'salinity',
      'dissolved_oxygen_saturation',
      'dissolved_oxygen',
      'pH',
      'rainfall',
      'water_level',
    ];
    if (stationType === 'weir') displayResources.push('volume');
    const stationResources = Object.keys(resources);
    const isEmptyResources = isEmpty(intersection(displayResources, stationResources));
    return !isMetroDam ? (
      <div className={`${component}-resources`}>
        {!isEmptyResources ? (
          <>
            {stationResources.map(
              (item, index) =>
                displayResources.includes(item) &&
                resources[item].value !== '-' && (
                  <div key={index} className={`${component}-resources-item`}>
                    <div className={`${component}-resources-item-label`}>
                      {item === 'salinity'
                        ? 'Electrical conductivity @ 25deg. C'
                        : item.toLowerCase() === 'ph'
                        ? 'pH'
                        : toSentenceCase(item.replaceAll('_', ' '))}
                    </div>
                    <div className={`${component}-resources-item-value`}>
                      <DataQualityText qualityCode={resources[item].qualityCode}>
                        {checkValue(resources[item].value, item, resources[item].decimals)}
                      </DataQualityText>
                    </div>
                  </div>
                ),
            )}
            <div className={`${component}-date`}>
              {`Instantaneous values at ${moment(resources.date, dateFormatByType.input).format(
                dateFormatByType.output,
              )}`}
            </div>
          </>
        ) : (
          <div className={`${component}-msg`}>No latest data available for this site.</div>
        )}
      </div>
    ) : (
      resources.date && (
        <div className={`${component}-date`}>
          {`Updated: ${moment(resources.date, dateFormatByType.input).format(
            dateFormatByType.output,
          )}`}
        </div>
      )
    );
  };

  return (
    <div className={component}>
      {showLoader && <Loader width={'10px'} height={'40px'} />}
      {!isEmpty(resources) && (
        <>
          {resources.volume && ['dam'].includes(stationType) && (
            <div className={`${component}-volume`}>
              {`Volume: `}
              <span>
                <DataQualityText qualityCode={resources?.volume?.qualityCode}>
                  {checkValue(resources.volume.value, 'volume')}
                </DataQualityText>
              </span>
            </div>
          )}
          {!isEmpty(percentage) && stationType === 'dam' && (
            <PieChart width={window.innerWidth} height={95} margin={{ top: 80 }}>
              <Pie
                strokeWidth={0}
                data={percentage}
                startAngle={180}
                endAngle={0}
                innerRadius={55}
                outerRadius={85}
                fill="#8884d8"
                dataKey="volume_perc"
              >
                {percentage.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={PIE_CHART_COLORS[index]} />
                ))}
                <Label
                  value={`${checkValue(resources.volume_perc.value, 'perc')}%`}
                  position="center"
                  dy={-15}
                />
              </Pie>
            </PieChart>
          )}
          <SiteResourceDetails />
        </>
      )}
    </div>
  );
}
