import React from 'react';

const SVG = ({ width = '100%', height = '100%', viewBox = '0 0 1860.9 530.7' }) => (
  <svg id="WaterNSWLogoSVG"
    width={width}
    className="waternsw-logo-svg"
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={{ enableBackground: 'new 0 0 1860.9 530.7' }}
    xmlSpace="preserve"
  >
    <style type="text/css">
      {`
      .st0 { fill: #FFFFFF; }
      .st1 { fill: #FFCD34; }
      .st2 { fill: #63CBE8; }
      .st3 { fill: #4884C4; }
    `}
    </style>
    <g>
      <path class="st0" d="M1331.3,190.1h51c1.9,14.6,7.5,24.8,16.8,30.6c9.7,6.8,23.1,10.2,40.1,10.2c15.1,0,26.5-2.7,34.3-8   c7.8-5.3,11.7-13.1,11.7-23.3c0-15.5-21.9-28.2-65.6-37.9c0,0-38.1-8-56.9-19c-9.6-5.6-17.3-15.5-21.4-24   c-4.5-9.5-6.3-19.2-6.3-32.8c0-23.8,8.3-42.5,24.8-56.1c16.5-13.1,40.6-19.7,72.2-19.7c29.6,0,52.5,6.8,68.5,20.4   c16.5,15.1,25.3,34.7,26.2,59h-29.2c-11.2,0-19.2-5.1-24.1-15.3c-2.4-4.9-5.8-9-10.2-12.4c-8.7-6.3-20.7-9.5-35.7-9.5   c-14.6,0-25,2.7-31.3,8c-7.3,5.4-10.9,12.9-10.9,22.6c0,13.1,14.1,22.8,42.3,29.2c27.6,6.8,57.5,12.7,77.3,24.1   c9.7,5.8,17.2,13.4,22.6,22.6c5.3,10.2,8,21.4,8,33.5c0,26.2-8.7,46.7-26.2,61.2c-17.5,13.6-42.3,20.4-74.4,20.4   c-33.1,0-57.8-7-74.4-21.1C1342.4,238.7,1332.7,217.8,1331.3,190.1z" />
      <path class="st0" d="M733.7,267.4c7.9,0.6,15.5,1,22.9,1.1c9.5-12.2,18.8-24.9,27.8-38.3h-13.9c-9.7,0-14.6-4.1-14.6-12.4V109.9   h5.1c6.8,0,12.6-2.3,17.5-6.9c4.9-4.6,7.3-10.6,7.3-17.9V74.9H756V42.1c0-6.8-2.4-12.6-7.3-17.5c-4.9-4.9-10.5-7.3-16.8-7.3h-26.2   v214.3C705.7,254,715,265.9,733.7,267.4z" />
      <path class="st0" d="M323.4,242.6c6.3-0.6,12.7-1.1,19.2-1.5l33.8-165.4l33.2,165.6c10.1,0.7,20.2,1.7,30.3,3   c10.1,1.3,20.1,2.9,30.1,4.9l66-231.8H504c-14.1,0-22.6,6.3-25.5,19l-38.6,158.2L404.1,17.3h-55.4l-36.4,177.1l-43-177.1h-53.2   l66.5,231.2C295.6,246.1,309.3,244.1,323.4,242.6z" />
      <path class="st0" d="M1814.2,17.3c-14.1,0-22.6,6.3-25.5,19l-38.6,158.2l-35.7-177.1h-55.4l-36.4,177.1l-43-177.1h-53.2l72.2,250.8   h48.8l39.4-192.4l38.6,192.4h49.6l71.4-250.8H1814.2z" />
      <path class="st0" d="M1083.9,74.2c-13.1,0-25.9,1.7-38.3,5.1c-12.4,3.4-23.2,8.7-32.4,16c-9.7,7.8-17.4,18.1-23,31   c-5.6,12.9-8.4,28.8-8.4,47.7v94h51v-99.9c0-14.6,3.4-25.3,10.2-32.1c6.8-6.8,17.2-10.2,31.3-10.2h16.8V74.2c-1,0-2.2,0-3.6,0   C1086.1,74.2,1084.9,74.2,1083.9,74.2z" />
      <path class="st0" d="M1265.2,189.4l-102-172.8h-27.7c-7.3,0.5-13.5,3.3-18.6,8.4c-5.1,5.1-7.7,11.3-7.7,18.6v224.5h51V95.3   L1263,268.1h53.2V16.6h-51V189.4z" />
      <path class="st0" d="M925.7,208.3h36.4c-5.4,20.4-15.6,36-30.6,46.7c-15.1,11.2-34,16.8-56.9,16.8c-27.7,0-49.3-9-64.9-27   c-15.6-17.5-23.3-42.5-23.3-75.1c0-31.1,7.5-55.4,22.6-72.9c15.5-18,37.2-27,64.9-27c29.2,0,51.8,8.7,67.8,26.2   c15.5,18,23.3,43,23.3,75.1c0,1.9,0,3.6,0,5.1c0,1.5-0.3,7.1-0.7,8H838.2c0.5,15.1,4.1,26.2,10.9,33.5c6.3,7.8,15.8,11.7,28.4,11.7   c8.3,0,15.3-1.7,21.1-5.1c6.6-4.1,10.3-10,13.5-12.4C915.3,209.6,919.8,208.3,925.7,208.3z M838.2,151.5h74.4   c-1-13.1-4.4-22.8-10.2-29.2c-6.3-6.8-15.3-10.2-27-10.2c-10.7,0-19.4,3.4-26.2,10.2C842.3,129.1,838.7,138.8,838.2,151.5z" />
      <path class="st0" d="M649.2,260.1c-10.2,5.4-21,8.6-32.4,9.8c-11.4,1.2-21.5,1.8-30.3,1.8c-17.5,0-32.8-4.7-45.9-14.2   c-13.1-9.5-19.7-25.1-19.7-47c0-20.4,5.9-35,17.9-43.7c11.9-8.7,27.1-14.6,45.6-17.5c4.3-0.7,16-2.4,20.4-2.9   c21.4-2.9,32.1-9.2,32.1-19c0-7.3-3.8-12-11.3-14.2c-7.5-2.2-14.5-3.3-20.8-3.3c-6.8,0-12.9,1-18.2,2.9c-5.4,1.9-9.2,5.6-11.7,10.9   H526c1.9-15.1,8.7-27.4,20.4-37.2c13.1-11.2,31.8-16.8,56.1-16.8c27.2,0,47.6,4.6,61.2,13.9c14.1,9.7,21.1,23.3,21.1,40.8v66.3   c0,19.4-3.5,34.6-10.6,45.6C667.3,247.3,658.9,255.2,649.2,260.1z M637.6,170.4c-6.8,3.4-16.3,6.6-28.4,9.5l-16.8,3.6   c-5.4,1.5-9.8,3.3-13.4,5.3c-3.9,2.2-6.9,5.7-8.6,9.9c-1.4,3.5-2.1,6.9-2.1,10.4c0,7.3,2.2,13.1,6.6,17.5c4.4,3.9,10.7,5.8,19,5.8   c13.6,0,24.3-3.9,32.1-11.7c7.8-7.8,11.7-18.5,11.7-32.1V170.4z" />
    </g>
    <g>
      <path class="st1" d="M13.9,268.1c47.7,0,93.1,9.8,134.4,27.4c3.1-1.7,6.8-3.6,11.1-5.7c7.6-3.8,17-8.3,28.1-12.8   c11-4.6,23.6-9.3,37.8-13.8c3.6-1.2,7.4-2.3,11.3-3.4c-19.1-12.9-34-25.7-41.6-32.7c-21.3-19.9-89.4-99.8-99.7-118.3L68.7,17.3   c0,0-35.1,0-54.8,0C13.9,48.5,13.9,235.8,13.9,268.1L13.9,268.1z" />
    </g>
    <path class="st2" d="M472.6,476.8c29.5-11.1,60.7-25.1,92.3-42.9C356.7,261,158.4,300,158.4,300  C323.2,329.6,436.3,437.6,472.6,476.8z" />
    <path class="st3" d="M341.8,511.4c28.9-4.6,65.3-12.4,105.6-25.7C318.7,338,168.9,305.1,168.9,305.1c0,0,0,0,0,0  C251.4,347.1,314.6,421.4,341.8,511.4z" />
    <path class="st0" d="M585.5,421.8c26.1-16,52.3-34.6,78-56.3c-272.1-166.5-510.8-68.1-510.8-68.1C370.8,257.9,539.4,383,585.5,421.8  z" />
  </svg>
);

export default SVG;
