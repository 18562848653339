// Lib
import { isEmpty } from 'lodash';

// Util
import {
  getDataDownloadRequest,
  submitBoreReportRequest,
  sendNotificationEmail,
} from '../../../../helpers/ServicesApiHelper';
import {
  geocodeQueryApi,
  queryMapServiceIdentify,
  queryMapServiceQuery,
} from '../../../../helpers/MapServiceApiHelper';

// Constant
import constants from '../../../../constants/Constants';
import { API_STATUS } from '../../../modal/RequestModal';
import circleToPolygon from 'circle-to-polygon';
import { FILTERS } from './GroundwaterDownloadFilters';
export const STATUS = {
  DEFAULT: 'DEFAULT',
  VALID: 'VALID_REQUEST',
  ERROR: 'ERROR',
};

/**
 * Check for any existing request
 * @returns {bool}
 */
const checkDuplicates = async (ids, userEmail) => {
  const requestList = await getDataDownloadRequest(userEmail);
  let duplicate = {};
  if (!isEmpty(requestList)) {
    duplicate = requestList.find(item => {
      return item.station_id === ids;
    });
  }

  return !isEmpty(duplicate);
};

export const submitRequest = async (setApiStatus, gwDataSelected, userEmail) => {
  // Check for whether is an identical request within the DB
  const ids = Array.isArray(gwDataSelected) ? gwDataSelected.join(',') : gwDataSelected;
  const isDuplicated = await checkDuplicates(ids, userEmail);

  if (!isDuplicated) {
    const success = await submitBoreReportRequest(userEmail, ids);
    if (success === true) {
      setApiStatus(API_STATUS.SUCCESS);
      sendNotificationEmail(userEmail);
    } else {
      setApiStatus(API_STATUS.FAIL);
    }
  } else {
    setApiStatus(API_STATUS.DUP);
  }
};

export const suggestQuery = async (terms, setSuggestions) => {
  const result = await geocodeQueryApi(
    { text: terms, countryCode: 'au', maxSuggestions: 3, category: 'City,Address' },
    constants.GEOCODE_ARCGIS_SUGGEST_URL,
  );

  setSuggestions(result.suggestions);
};

/**
 * This function is for converting the long lat to relevant bores' ids
 * @param {string} lng
 * @param {string} lat
 * @returns boreIds
 */
export const convertCoordinateToBoreId = async (lng, lat, radius, setFormStatus, setCircle) => {
  try {
    if (lng && lat && radius) {
      const convertLng = Number(lng);
      const convertLat = Number(lat);
      const convertRadius = Number(radius) * 1000;
      if (!isFinite(convertLng) || !isFinite(convertLat) || !isFinite(convertRadius))
        throw new Error('Not number');

      let polygon = circleToPolygon([convertLng, convertLat], convertRadius, 36);
      const geometryInput = {
        rings: polygon.coordinates,
      };

      const result = await queryMapServiceIdentify(geometryInput, `bores`, 'esriGeometryPolygon');
      const bores = new Set(result?.data?.results?.map(item => item.value));
      setCircle({
        pos: [convertLat, convertLng],
        radius: convertRadius,
      });
      return Array.from(bores);
    } else {
      return [];
    }
  } catch (e) {
    setFormStatus(STATUS.ERROR);
  }
};

/**
 * This function is for converting an existing address to relevatn bores' ids
 * @param {string} address
 * @param {string} magicKey
 * @returns boreIds
 */
export const convertAddressToCoord = async (address, magicKey) => {
  if (magicKey) {
    const result = await geocodeQueryApi(
      { singleLine: address, magicKey: magicKey },
      constants.GEOCODE_ARCGIS_FIND_ADR_URL,
    );
    const coord = result?.candidates[0]?.location;

    return coord;
  } else {
    return [];
  }
};

/**
 * Fetch bores' ids based on the licence number
 * @param {string} licence
 * @returns boreIds
 */
export const convertLicenceToBoreIds = async (licence, setFormStatus) => {
  try {
    if (licence) {
      const result = await queryMapServiceQuery(`${constants.TELEMETERED_BORES_MAP_SERVICE}`, {
        returnGeometry: false,
        outFields: 'PARENT_SITE',
        f: 'pjson',
        where: `LICENCE='${licence}'`,
      });
      const extracted = result?.data?.features
        ? result.data.features.map(item => item.attributes.PARENT_SITE)
        : [];
      const filtered = extracted.filter(item => item);
      if (isEmpty(filtered)) {
        throw new Error('No corresponding licence');
      } else {
        return filtered;
      }
    } else {
      return [];
    }
  } catch (e) {
    setFormStatus(STATUS.ERROR);
    return [];
  }
};

export const controlDrawControl = option => {
  const DRAW_OPTIONS = [FILTERS.rect, FILTERS.circle, FILTERS.poly];
  const el = document.querySelector('.leaflet-control');
  if (el) {
    if (!DRAW_OPTIONS.includes(option)) {
      el.style.opacity = '0%';
      el.style.zIndex = -1;
    } else {
      el.style.opacity = '100%';
      el.style.zIndex = 1000;
    }
  }
};

export const onDrawOptionClick = shape => {
  const DRAW_OPTIONS = {
    [FILTERS.rect]: 'rectangle',
    [FILTERS.circle]: 'circle',
    [FILTERS.poly]: 'polygon',
  };
  const showDrawOption = DRAW_OPTIONS[shape];
  if (showDrawOption) {
    const hideDrawOptions = Object.keys(DRAW_OPTIONS).reduce((acc, option) => {
      if (option !== shape) acc.push(DRAW_OPTIONS[option]);
      return acc;
    }, []);
    hideDrawOptions.forEach(item => {
      const hideEl = document.querySelector(`.leaflet-draw-draw-${item}`);
      hideEl.style.opacity = '0%';
      hideEl.style.position = 'absolute';
      hideEl.style.zIndex = -1;
    });
    const showEl = document.querySelector(`.leaflet-draw-draw-${showDrawOption}`);
    showEl.style.opacity = '100%';
    showEl.style.position = 'unset';
    showEl.style.zIndex = 1000;
    showEl.click();
  }
};

export const setRadius = (radius, layer, setLayer) => {
  if (isFinite(Number(radius)) && layer) {
    const newLayer = layer.layer.setRadius(radius * 1000);
    setLayer({ ...layer, layer: newLayer });
  }
};

export const findBoreLocationById = async (ids, setFormStatus) => {
  try {
    const locations = [];
    for (let id of ids) {
      const geometry = await queryMapServiceQuery(`${constants.TELEMETERED_BORES_MAP_SERVICE}`, {
        returnGeometry: false,
        outFields: 'LONGITUDE,LATITUDE',
        f: 'pjson',
        where: `PARENT_SITE='${id}'`,
        geometryType: 'esriGeometryPoint',
      });

      if (!isEmpty(geometry.data.features)) {
        const { attributes } = geometry.data.features[0];
        locations.push([attributes.LATITUDE, attributes.LONGITUDE]);
      } else {
        throw new Error('bore not found');
      }
    }

    return locations;
  } catch (e) {
    setFormStatus(STATUS.ERROR);
    return [];
  }
};

export const addBoresToMap = async (ids, setFormStatus, setMarkers) => {
  const locations = await findBoreLocationById(ids, setFormStatus);
  setMarkers(locations);

  return locations.length === ids.length;
};
