// Libraries
import React, { useContext } from 'react';

// Styles
import './mapLegend.scss';

// Constants
import constants from '../../constants/MapFilterConstants';

// Components
import MapLegendLineItem from './MapLegendLineItem';
import MobileMapLegend from './MobileMapLegend';

// Context
import { MobileContext } from '../../contexts/MobileContext';

export default function FlowClassMapLegend() {
  const { isMobile } = useContext(MobileContext);

  const desktopLegend = () => {
    return (
      <div className="map-legend flow-class-map-legend" id="map-legend">
        <div className="flow-class-map-legend-section">
          <div className="map-legend-type">Flow class</div>
          {constants.FLOW_CLASS_LEGEND.map((legend, index) => (
            <MapLegendLineItem
              key={index}
              strokeColor={legend.strokeColor}
              strokeWidth={legend.strokeWidth}
              legendText={legend.text}
            />
          ))}
        </div>
        <div className="flow-class-map-legend-section">
          <div className="map-legend-type">Restriction Type</div>
          {constants.RESTRICTION_TYPE_LEGEND.map((legend, index) => (
            <MapLegendLineItem
              key={index}
              strokeColor={legend.strokeColor}
              strokeWidth={legend.strokeWidth}
              legendText={legend.text}
            />
          ))}
        </div>
      </div>
    );
  };

  const mobileLegend = () => {
    return (
      <MobileMapLegend
        legends={[...constants.FLOW_CLASS_LEGEND, ...constants.RESTRICTION_TYPE_LEGEND]}
      />
    );
  };
  return <>{isMobile ? mobileLegend() : desktopLegend()}</>;
}
