// Libraries
import React, { useEffect, useState, useRef } from 'react';
import L from 'leaflet';
import { GeoJSON } from 'react-leaflet';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

// Assets
import constants from '../../../constants/Constants.jsx';

// Helpers
import { getOveviewResources } from '../../../helpers/ApiHelper';
import { promiseXMLHttpRequestMapService, queryEsri } from '../../../helpers/MapServiceApiHelper';

const TYPE = 'status';
const MAP_LAYER_ID = '2';
const DROUGHT_STATUS_COLOR = {
  d1: '#1c9300',
  d2: '#fcbe00',
  d3: '#ff5c00',
  d4: '#d12800',
};

export default function DroughtStatusRiverSection({ setShowSpinner, selected }) {
  const geoJsonRef = useRef(null);
  const [data, setData] = useState([]);
  const [mapData, setMapData] = useState(Array);

  useEffect(() => {
    let unmounted = false;
    (async () => {
      if (!unmounted && selected) {
        setShowSpinner(true);
        const resources = await getOveviewResources(TYPE);
        const filtered = resources.filter(item => !['d0', 'd1'].includes(item.status));
        setData(filtered);
        const filteredWaterSources = filtered.map(item => item.id);
        queryEsri(
          constants.NSW_MAP_SERVICE_BASE_URL + MAP_LAYER_ID,
          'WS_LOCATION_ID IN (' + filteredWaterSources.join(',') + ')',
          function (result) {
            runQuery(result);
          },
        );
      } else {
        setData([]);
        setMapData([]);
      }
    })();

    async function runQuery(query) {
      let queryResult = await promiseXMLHttpRequestMapService(query);
      if (!isEmpty(queryResult.features)) {
        setMapData(queryResult);
      }
      setShowSpinner(false);
    }

    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const setGeoJsonStyle = feature => {
    let style = {
      color: '#0054a6',
    };
    const waterSource = data.find(item => item.id === feature.properties.WS_LOCATION_ID);
    style.color = waterSource ? DROUGHT_STATUS_COLOR[waterSource.status] : style.color;
    return style;
  };

  return (
    !isEmpty(mapData) && (
      <GeoJSON
        ref={geoJsonRef}
        data={mapData}
        style={feature => setGeoJsonStyle(feature)}
        renderer={L.svg({ padding: 1 })}
        weight={5}
      />
    )
  );
}

DroughtStatusRiverSection.propTypes = {
  setShowSpinner: PropTypes.func,
  selected: PropTypes.bool,
};
