// Library
import React, { useState, useContext, useRef } from 'react';

// Style
import './groundwaterDownload.scss';

// Component
import HistoricalDataLayout from '../../../../layout/content/HistoricalDataLayout';
import LoginModalBody from '../../../alert/LoginModalBody';
import GroundWaterSitesMap from './GroundWaterSitesMap';
import GroundwaterDownloadFilters from './GroundwaterDownloadFilters';

// Context
import { UserContext } from '../../../../contexts/UserContext';

const component = 'groundwater-download';

const GroundwaterDownload = () => {
  const { isLoggedIn } = useContext(UserContext);
  const mapRef = useRef(null);
  const [gwDataSelected, setGwDataSelected] = useState([]);
  const [leafletLayer, setLeafletLayer] = useState(null);
  const [markers, setMarkers] = useState([]);
  const [circle, setCircle] = useState({});
  // Method to remove all shapes every time the user wants to draw something new on the map
  const clearShape = () => {
    setGwDataSelected([]);
    const drawnItems = mapRef.current.leafletElement._layers;
    if (Object.keys(drawnItems).length > 2) {
      const mapLayerId = Object.keys(drawnItems)[0];
      Object.keys(drawnItems).forEach((layerid, index) => {
        if (index === 0) {
          const layers = drawnItems[mapLayerId]._layers;
          Object.keys(layers).forEach(layerid => {
            const layer = drawnItems[layerid];
            layer && mapRef.current.leafletElement.removeLayer(layer);
          });
        }
      });
    }
  };

  return (
    <>
      {isLoggedIn ? (
        <HistoricalDataLayout
          left={
            <GroundwaterDownloadFilters
              clearShape={clearShape}
              gwDataSelected={gwDataSelected}
              setGwDataSelected={setGwDataSelected}
              layer={leafletLayer}
              setLayer={setLeafletLayer}
              setMarkers={setMarkers}
              setCircle={setCircle}
            />
          }
          right={
            <GroundWaterSitesMap
              mapRef={mapRef}
              clearShape={clearShape}
              gwDataSelected={gwDataSelected}
              setGwDataSelected={setGwDataSelected}
              layer={leafletLayer}
              setLayer={setLeafletLayer}
              markers={markers}
              circle={circle}
            />
          }
        />
      ) : (
        <div className={`${component}-not-auth`}>
          <LoginModalBody action="use" type="this feature" />
        </div>
      )}
    </>
  );
};

export default GroundwaterDownload;
