// Library
import React, { useState } from 'react';

// Style
import './videoItem.scss';

// Component
import Modal from '../../../modal/Modal';

// Constant
const component = 'video-item';
const VideoItem = ({ vidTitle, vidId }) => {
  const [mountPlayer, setMountPlayer] = useState(false);
  return (
    <div className={component}>
      <VideoPreview vidTitle={vidTitle} vidId={vidId} setMountPlayer={setMountPlayer} />
      <VideoPlayer vidId={vidId} mountPlayer={mountPlayer} setMountPlayer={setMountPlayer} />
    </div>
  );
};

const VideoPreview = ({ vidTitle, vidId, setMountPlayer }) => {
  return (
    <div
      className={`${component}-preview`}
      data-toggle="modal"
      data-target={`#video-modal-${vidId}`}
      onClick={() => {
        setMountPlayer(true);
      }}
    >
      <div className={`${component}-preview-container`}>
        <img
          className={`${component}-preview-img`}
          src={`http://i.ytimg.com/vi/${vidId}/mqdefault.jpg`}
        />
        <div className={`${component}-preview-play-btn`}>
          <div className={`${component}-preview-play-btn-triangle`} />
        </div>
      </div>
      <div className={`${component}-title`}>{vidTitle}</div>
    </div>
  );
};

const VideoPlayer = ({ vidId, mountPlayer, setMountPlayer }) => {
  return (
    <Modal
      id={`video-modal-${vidId}`}
      body={
        <>
          {mountPlayer && (
            <div className={`${component}-vid-wrapper`}>
              <iframe
                className={`${component}-vid`}
                src={`https://www.youtube.com/embed/${vidId}`}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </div>
          )}
        </>
      }
      onClose={() => {
        setMountPlayer(false);
      }}
    />
  );
};

export default VideoItem;
