// Library
import React from 'react';
import PropTypes from 'prop-types';

// Component
import RiverStation from './locations/RiverStation';
import River from './locations/River';
import RiverTown from './locations/RiverTown';

const RiverLocation = ({
  x,
  y,
  rotate,
  name,
  value,
  fullPercentage,
  unit,
  locationType,
  style,
  textX,
  textY,
  url,
  background,
  detailsLoading,
  stateView = false,
}) => {
  const locations = () => {
    switch (locationType) {
      case 'river':
        return (
          <River x={x} y={y} rotate={rotate} name={name} style={style} background={background} />
        );
      case 'town':
        return <RiverTown x={x} y={y} name={name} textX={textX} textY={textY} />;
      default:
        return (
          <RiverStation
            x={x}
            y={y}
            name={name}
            value={value}
            unit={unit}
            fullPercentage={fullPercentage}
            locationType={locationType}
            url={url}
            background={background}
            stateView={stateView}
            detailsLoading={detailsLoading}
          />
        );
    }
  };
  return <>{locations()}</>;
};

export default RiverLocation;

RiverLocation.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  rotate: PropTypes.number,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  unit: PropTypes.string,
  locationType: PropTypes.string,
  style: PropTypes.string,
  textX: PropTypes.number,
  textY: PropTypes.number,
  url: PropTypes.string,
  background: PropTypes.string,
  stateView: PropTypes.bool,
  fullPercentage: PropTypes.number,
};
