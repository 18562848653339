// Library
import React from 'react';

// Component
import UsageGraph from './UsageGraph';

const UsageBody = () => {
  return (
    <div>
      <UsageGraph />
    </div>
  );
};

export default UsageBody;
