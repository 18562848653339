// Libraries
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Tooltip, XAxis, Legend, YAxis, ReferenceLine, LineChart, Line } from 'recharts';
import { cloneDeep, isEmpty, isNull } from 'lodash';

// Styles
import './lossesGraph.scss';

// Context
import { AppContext } from '../../contexts/AppContext';
import { MobileContext } from '../../contexts/MobileContext';

// Components
import ChartContainer from '../chartProperties/ChartContainer';
import YearMultiSelector from '../yearMultiSelector/YearMultiSelector';
import SelectionBtnsGroup from '../storage/childProperties/SelectionBtnsGroup';
import ChartResponsiveContainer from '../chartProperties/ChartResponsiveContainer';

// Helpers
import { genListOfFiscalYears, formatNumberDecimals } from '../../helpers/Utils';
import { genGraphDataForFiscalYear } from '../../helpers/ProcessDataHelper';
import { getLossesWaterSource, formatApiResponse } from '../../helpers/LossesApiHelper.jsx';
import { findLastIndex } from '../../helpers/ArrayUtils';

const COLOR_CODES = [
  '#0054a6',
  '#ffcd34',
  '#63cbe8',
  '#bed12a',
  '#91b0c1',
  '#DC7520',
  '#801D34',
  '#56801D',
];

export default function AnnualLosses({
  chartData,
  siteId,
  siteName,
  desc,
  title,
  chartLabel,
  dataKey,
  yearSelector,
  defaultYears,
  type,
  multiplier,
}) {
  const { waterSource } = useContext(AppContext);
  const { isMobile } = useContext(MobileContext);
  const [formattedData, setFormattedData] = useState([]);
  const [emptyChart, setEmptyChart] = useState(false);
  const [activeYears, setActiveYears] = useState(defaultYears);
  const [modified, setModified] = useState(false);
  const listOfYears = genListOfFiscalYears('losses', waterSource.water_source_id);
  const chartProp = {
    budgeted: { name: 'Budgeted', color: '#91b0c1', dot: { strokeWidth: 2 } },
    projected: {
      name: 'Revised',
      color: '#000',
      dot: false,
      strokeDasharray: '15 15',
    },
    required: { name: 'Required', color: '#91b0c1', dot: { strokeWidth: 2 } },
    loss: {
      name: 'End-of-system loss',
      color: '#DC7520',
      dot: { strokeDasharray: '0 0', strokeWidth: 2 },
      strokeDasharray: '15 15',
    },
  };

  useEffect(() => {
    if (!isEmpty(chartData)) {
      const formatted = formatData(chartData);
      setFormattedData(formatted);
      setEmptyChart(false);
    } else {
      setFormattedData([]);
      setEmptyChart(true);
    }
    if (typeof defaultYears !== 'undefined') {
      setActiveYears(defaultYears.map(year => year.value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData]);

  useEffect(() => {
    if (modified) {
      (async () => {
        try {
          await getLossesWaterSource(waterSource, activeYears.join(), result => {
            if (!isEmpty(result)) {
              const targetChartData = result[siteId];
              const formatted = formatData(targetChartData);
              setFormattedData(formatted);
              setEmptyChart(false);
            } else {
              setFormattedData([]);
              setEmptyChart(true);
            }
          });
        } catch (error) {
          return error;
        }
      })();
    }

    return () => {
      setModified(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterSource, activeYears, modified]);

  const formatData = data => {
    data.resources = genGraphDataForFiscalYear(data.resources, false);
    const cleanedArray = cloneDeep(data.resources).filter(function (item) {
      delete item.month;
      return true;
    });

    const lastIndexOfActual =
      dataKey !== 'actual' ? findLastIndex(cleanedArray, 'actual', null) : -1;

    const formatted = formatApiResponse(data, 'losses', dataKey, lastIndexOfActual);
    return formatted;
  };

  const onYearChange = value => {
    if (value && value.length) {
      setActiveYears(value.map(year => year.value));
    } else {
      setEmptyChart(true);
      setActiveYears([]);
    }

    if (!modified) {
      setModified(true);
    }
  };

  const drawLinesForLosses = () => {
    let lineArr = [];
    let dataSeries = !emptyChart ? formattedData.resources || [] : [];
    let dataSet = dataSeries[0];
    if (dataSet) {
      Object.keys(dataSet).forEach((yearKey, index) => {
        if (yearKey.includes('-')) {
          //valid data for shown
          if (dataKey.includes('actual') && yearKey.includes('actual')) {
            const colorIndex = activeYears
              ? activeYears.indexOf(Number(yearKey.split('-')[0].split('/')[1]))
              : 0;
            lineArr.push(
              <Line
                name={yearKey.split('-')[0]}
                type="monotone"
                strokeWidth={2}
                dataKey={`${yearKey}`}
                stroke={
                  dataKey === 'actual' ? COLOR_CODES[colorIndex] : COLOR_CODES[colorIndex + 1]
                }
                key={`line-chart-${index}`}
                dot={{ strokeWidth: 3 }}
              />,
            );
          }
          if (dataKey.includes('budgeted/projected') || dataKey.includes('required/loss')) {
            dataKey.split('/').map(keyItem => {
              if (keyItem !== 'actual') {
                const chartPropItem = chartProp[keyItem];
                if (yearKey.includes(keyItem)) {
                  lineArr.push(
                    <Line
                      name={chartPropItem.name}
                      type="monotone"
                      strokeWidth={3}
                      dataKey={`${yearKey}`}
                      stroke={chartPropItem.color}
                      key={`line-chart-${index}-budgeted`}
                      dot={chartPropItem.dot}
                      strokeDasharray={chartPropItem.strokeDasharray}
                    />,
                  );
                }
              }
            });
          }
        }
      });
    }
    return lineArr;
  };

  const formatTooltip = (value, name, item) => {
    let toRemove = false;
    let actual;
    let projected;
    Object.keys(item.payload).forEach(key => {
      if (key.includes('-actual')) {
        actual = item.payload[key];
      } else if (key.includes('-projected')) {
        projected = item.payload[key];
      }
    });

    toRemove = !isNull(actual) && !isNull(projected);
    if (dataKey !== 'actual' && toRemove && name.includes('Revised')) {
      return [];
    }
    return type === 'natural'
      ? [formatNumberDecimals(Number(value / multiplier)) + ' GL', name]
      : [formatNumberDecimals(Math.round(value)) + ' ML/d', name];
  };

  const pageTitle = `${title} ${
    formattedData.site_name ? formattedData.site_name.split(' ')[0] : siteName
  }`;

  return (
    <ChartContainer pageTitle={pageTitle} graphTitle={formattedData.site_name} graphIntro={desc}>
      {yearSelector && (
        <SelectionBtnsGroup
          right={
            <YearMultiSelector
              defaultYears={defaultYears}
              listOfYears={listOfYears}
              onYearChange={onYearChange}
            />
          }
        />
      )}
      <ChartResponsiveContainer isEmptyChart={isEmpty(formattedData.resources)}>
        <LineChart
          width={800}
          data={formattedData.resources}
          margin={{ top: isMobile ? 30 : 10, right: 15, bottom: 60, left: 20 }}
          className="supplychart"
        >
          <XAxis dataKey="month" tick={{ fill: 'black' }} angle={-40} dy={25} />
          <YAxis
            label={{
              value: chartLabel,
              angle: -90,
              dx: -35,
            }}
            tickFormatter={v => formatNumberDecimals(Math.round(v / multiplier))}
            tick={{ fill: 'black' }}
          />
          <Tooltip
            labelFormatter={name => moment(name, 'YYYY-MMM').format('MMMM')}
            formatter={(value, name, props) => formatTooltip(value, name, props)}
          />
          <ReferenceLine y={0} stroke="#000" strokeDasharray="3 3" />
          <Legend wrapperStyle={{ bottom: 0 }} align="left" iconSize={30} />
          {drawLinesForLosses()}
        </LineChart>
      </ChartResponsiveContainer>
    </ChartContainer>
  );
}

AnnualLosses.propTypes = {
  siteId: PropTypes.number,
  siteName: PropTypes.string,
  desc: PropTypes.string,
  title: PropTypes.string,
  chartLabel: PropTypes.string,
  dataKey: PropTypes.string,
  yearSelector: PropTypes.bool,
  chartData: PropTypes.object,
  defaultYears: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.string,
  multiplier: PropTypes.number,
};
