// Libraries
import APIConstant from './APIResponseConstant';

const RIVER_HYDROMETRIC_INFORMATION = {
  weir: [
    {
      id: 1,
      name: 'volume',
      dataKey: 'volume',
      waterDataKey: 'TotalStorageVolume',
      unit: 'GL',
      division: 1000,
      content: [],
    },
    {
      id: 2,
      name: 'percentage full',
      dataKey: 'volume_perc',
      waterDataKey: 'ActiveStoragePercentage',
      unit: '%',
      division: 1,
      content: [],
    },
    {
      id: 3,
      name: 'reservoir level',
      dataKey: 'level',
      waterDataKey: 'StorageWaterLevel',
      unit: 'm',
      division: 1,
      summaryHeader: 'Level (m)',
      content: [],
    },
  ],
  gauge: [
    {
      id: 1,
      name: 'flow rate',
      dataKey: APIConstant.GAUGE.flow_rate,
      waterDataKey: 'FlowRate',
      unit: 'ML/d',
      division: 1,
      forecast: true,
      forecastDataKey: 'flow_rate_forecast',
      content: [],
    },
    {
      id: 2,
      name: 'river level',
      dataKey: APIConstant.GAUGE.level,
      waterDataKey: 'StreamWaterLevel',
      unit: 'm',
      division: 1,
      summaryHeader: 'Level (m)',
      content: [],
      abbreviation: 'Level',
    },
    {
      id: 2,
      name: 'storage level',
      dataKey: APIConstant.GAUGE.level,
      waterDataKey: 'StorageWaterLevel',
      unit: 'm',
      division: 1,
      summaryHeader: 'Level (m)',
      content: [],
      abbreviation: 'Level',
    },
    {
      id: 3,
      name: 'water temperature',
      dataKey: APIConstant.GAUGE.temperature,
      waterDataKey: 'WaterTemperature',
      unit: '°C',
      division: 1,
      content: [],
      abbreviation: 'Temp.',
    },
    {
      id: 4,
      name: 'Electrical Conductivity @ 25deg. C',
      dataKey: APIConstant.GAUGE.salinity,
      waterDataKey: 'Conductivity',
      unit: 'μS/cm',
      division: 1,
      content: [],
    },
    {
      id: 5,
      name: 'dissolved oxygen',
      dataKey: APIConstant.WATER_QUALITY.dissolved_oxygen,
      waterDataKey: 'DissolvedOxygen',
      unit: 'mg/L',
      division: 1,
      content: [],
      abbreviation: 'DO',
    },
    {
      id: 6,
      name: 'dissolved oxygen saturation',
      dataKey: APIConstant.WATER_QUALITY.dissolved_oxygen_saturation,
      waterDataKey: 'DissolvedOxygenSaturation',
      unit: '%',
      division: 1,
      content: [],
      abbreviation: 'DO Sat.',
    },
    {
      id: 7,
      name: 'pH',
      dataKey: APIConstant.WATER_QUALITY.pH,
      waterDataKey: 'pH',
      unit: '',
      division: 1,
      content: [],
      noCap: true,
    },
    {
      id: 8,
      name: 'rainfall',
      dataKey: APIConstant.GAUGE.rainfall,
      waterDataKey: 'Rainfall',
      unit: 'mm',
      division: 1,
      content: [],
      noCap: true,
    },
    {
      id: 9,
      name: 'turbidity',
      dataKey: APIConstant.GAUGE.turbidity,
      waterDataKey: 'Turbidity',
      unit: 'NTU',
      division: 1,
      content: [],
      noCap: true,
    },
    {
      id: 10,
      name: 'DO Mg/L @ 2.0m',
      dataKey: APIConstant.GAUGE.do_2,
      waterDataKey: 'DissolvedOxygen2.0MBelowSurface',
      unit: 'mg/L',
      division: 1,
      content: [],
      abbreviation: 'DO',
    },
  ],
  bore: [
    {
      id: 1,
      name: 'water level',
      dataKey: 'water_level',
      waterDataKey: 'GroundwaterDepthBelowSurfaceLevel',
      unit: 'm below ground',
      division: 1,
      content: [],
    },
  ],
};

const LABEL_FORMAT = {
  flow_rate: { name: 'Flow rate', decimals: 1, unit: 'ML/d' },
  volume: { name: 'Volume', decimals: 1, unit: ' GL' },
  volume_perc: { name: 'Percentage full', decimals: 1, unit: '%' },
  temperature: { name: 'Water temperature', decimals: 1, unit: ' °C' },
  salinity: { name: 'Electrical Conductivity @ 25deg. C', decimals: 0, unit: 'μS/cm' },
  level: { name: 'River level', decimals: 3, unit: 'm' },
  water_level: { name: 'Water level', decimals: 2, unit: 'm below ground' },
  dissolved_oxygen_saturation: { name: 'DO saturation', decimals: 1, unit: '%' },
  dissolved_oxygen: { name: 'Dissolved oxygen', decimals: 1, unit: 'mg/L' },
  pH: { name: 'pH', decimals: 1, unit: '' },
  rainfall: { name: 'Rainfall', decimals: 1, unit: 'mm' },
  hrsts_status: { name: 'Status', decimals: 0, unit: '' },
  turbidity: { name: 'Turbidity', decimals: 1, unit: 'NTU' },
  do_1: { name: 'DO Mg/L @ 0.75m', decimals: 1, unit: 'mg/L' },
  do_2: { name: 'DO Mg/L @ 2.0m', decimals: 1, unit: 'mg/L' },
  do_3: { name: 'DO Mg/L @ 3.0m', decimals: 1, unit: 'mg/L' },
};

const LABEL_FORMAT_WEIR = {
  volume: { name: 'Volume', decimals: 1, unit: ' GL' },
  volume_perc: { name: 'Percentage full', decimals: 1, unit: '%' },
  level: { name: 'Reservoir level', decimals: 3, unit: 'm' },
};

const LABEL_FORMAT_BORE = {
  water_level: { name: 'Water level', decimals: 2, unit: 'm below ground' },
};

export default Object.freeze({
  RIVER_PROPS: RIVER_HYDROMETRIC_INFORMATION,
  DATA_LABEL_FORMAT: LABEL_FORMAT,
  WEIR_DATA_LABEL_FORMAT: LABEL_FORMAT_WEIR,
  BORE_DATA_LABEL_FORMAT: LABEL_FORMAT_BORE,
});
