// Library
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { get, isBoolean, isEmpty } from 'lodash';
// Styles
import './stateOverviewSummary.scss';

// Components
import Loader from '../../../components/loader/Loader';
import ExpandBox from '../../generalComponents/expandBox/ExpandBox';
import StorageOverview from './StorageOverview';
import AvailabilityOverview from './AvailabilityOverview';
import TradeOverview from './TradeOverview';

// Assest
import InfoButton from '../../informationButton/InformationButton';
import text from './stateOverview.json';
import TradeSVG from '../../../image/icons/TradeSVG';
import AvailabilitySVG from '../../../image/icons/AvailabilitySVG';
import DamSVG from '../../../image/icons/DamSVG';

// Helpers
import { getOveviewSummary } from '../../../helpers/ApiHelper';
import { formatNumberDecimals } from '../../../helpers/Utils';
import { parseSurfaceWaterResponse } from './DataHelper';

// Context
import { MobileContext } from '../../../contexts/MobileContext';
import { MapContext } from '../../../contexts/MapContext';

// Constant
import stateConstant from './StateOverviewConstants';
const container = 'state-overview-summary';
const { STORAGE, AVAILABILITY, TRADES } = stateConstant;
const toggleOptions = [STORAGE, AVAILABILITY, TRADES];

const icons = (type, name) => {
  switch (type) {
    case TRADES.type:
      return <TradeSVG customClass={name} />;
    case AVAILABILITY.type:
      return <AvailabilitySVG customClass={name} />;
    case STORAGE.type:
      return <DamSVG customClass={name} />;
    default:
      return <TradeSVG customClass={name} />;
  }
};

const StateOverviewSummary = ({ overviewType, setOverViewType, hide }) => {
  const { isLarge } = useContext(MobileContext);
  const { mapExpanded, expandMap } = useContext(MapContext);
  const [overviewData, setOverviewData] = useState({});
  const [showSpinner, setShowSpinner] = useState(true);
  const [expandState, setExpandState] = useState(true);

  //Life Cycle
  useEffect(() => {
    let unmounted = false;
    loadSummaryData(unmounted);
    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isBoolean(mapExpanded) && !mapExpanded) {
      setExpandState(mapExpanded);
    }
  }, [mapExpanded]);

  // Helpers
  const loadSummaryData = async unmounted => {
    if (!unmounted) setShowSpinner(true);
    const summaryData = await getOveviewSummary();

    if (!unmounted) {
      setOverviewData(summaryData);
      setShowSpinner(false);
    }
  };

  const getValue = (data, option) => {
    let value;
    switch (option.type) {
      case STORAGE.type:
        value = Math.round(
          (get(data, `${option.type}.${option.dataKey1}`) /
            get(data, `${option.type}.${option.dataKey2}`)) *
            100,
        );
        break;
      case AVAILABILITY.type:
      case TRADES.type:
        value = get(data, `${option.type}.${option.dataKey}`) / 1000;
        break;
      default:
        value = get(data, `${option.type}.${option.dataKey}`);
        break;
    }
    return formatNumberDecimals(value);
  };

  const handleClick = state => {
    setExpandState(state);
    if (state) expandMap(true);
  };

  // Component
  const buttonGroup = () => {
    return (
      <div className={`${container}-overview`}>
        {toggleOptions.map(option => (
          <button
            key={option.type}
            className={`${
              option.type === overviewType
                ? `${container}-overview-button-selected`
                : `${container}-overview-button`
            }`}
            onClick={() => {
              setOverViewType(option.type);
            }}
          >
            {icons(
              option.type,
              option.type === overviewType
                ? `${container}-overview-button-selected-icon`
                : `${container}-overview-button-icon`,
            )}
            <div className={`${container}-overview-button-text`}>
              <div className={`${container}-overview-button-value`}>
                {`${option.monetary ? '$' : ''}${getValue(overviewData, option)}`}
                <span className={`${container}-overview-button-unit`}>{option.unit}</span>
              </div>
              <div>{option.text}</div>
            </div>
          </button>
        ))}
      </div>
    );
  };

  const headerMaker = () => {
    return (
      <>
        <div className={`${container}-title`}>
          <div className={`${container}-title-text`}>{text.title}</div>
          <InfoButton targetDataId={`#stateOverviewModal`} iconColor={`${container}-title-icon`} />
        </div>
        {buttonGroup()}
      </>
    );
  };

  const contentMaker = () => {
    switch (overviewType) {
      case TRADES.type:
        return <TradeOverview data={overviewData[overviewType]} />;
      case AVAILABILITY.type:
        return (
          <AvailabilityOverview data={parseSurfaceWaterResponse(overviewData[overviewType])} />
        );
      case STORAGE.type:
        return <StorageOverview data={overviewData[overviewType]} />;
      default:
        return;
    }
  };

  return (
    <div className={hide ? `${container}-hide` : ''}>
      {!isLarge && (
        <div className={`${container}`}>
          {showSpinner ? (
            <Loader width={'10px'} height={'40px'} />
          ) : (
            !isEmpty(overviewData) && (
              <ExpandBox
                customHeaderContainerStyle={`${container}-header`}
                customHeaderContent={headerMaker()}
                customArrowColor={`${container}-title-arrow`}
                expandState={expandState}
                handleExpandClick={state => handleClick(state)}
              >
                {contentMaker()}
              </ExpandBox>
            )
          )}
        </div>
      )}
    </div>
  );
};

export default StateOverviewSummary;

StateOverviewSummary.propTypes = {
  overviewType: PropTypes.string,
  setOverViewType: PropTypes.func,
};
