import React from 'react';

/* Styles */
import './riverDataSummaryHeader.scss';

const HEADER_PROPS = {
  weir: ['Volume (GL)', 'Level (m)'],
  gauge: ['Flow rate (ML/d)', 'Level (m)', 'Dissolved Oxygen (mg/L)'],
  bore: ['Level (m)'],
};

const component = 'river-data-summary-header';

const RiverDataSummaryHeader = ({ stationType }) => {
  const header = HEADER_PROPS[stationType];
  return (
    <div className={`${component}`}>
      <div className={`priority-1`}>Site No.</div>
      <div className={`priority-2`}>Location</div>
      <div className={`priority-3`}>Map view</div>
      {header.map((headerItem, idx) => (
        <div className={`priority-${idx + 4}`} key={idx}>
          {headerItem}
        </div>
      ))}
      <div className={`priority-7`}>Updated</div>
      {stationType === 'gauge' && <div className={`priority-8`}>Flow rate forecast</div>}
    </div>
  );
};

export default RiverDataSummaryHeader;
