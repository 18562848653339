// Libraries
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ResponsiveContainer, ComposedChart, XAxis, YAxis, Tooltip, Area, Line } from 'recharts';

// Helpers
import { formatNumberDecimals, toSentenceCase } from '../../../helpers/Utils';

// Constants
import Constants from '../../../constants/Constants';

export default function HourlyUpdatesGraph({ graphData }) {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart data={graphData}>
        <XAxis dataKey="timeStamp" tick={false} tickLine={false} axisLine={false} />
        <YAxis
          type="number"
          tick={false}
          tickLine={false}
          axisLine={false}
          domain={['dataMin', 'dataMax']}
        />
        <Tooltip
          labelFormatter={date =>
            moment(date, Constants.WATER_DATA_API_DATE_FORMAT).format('YYYY-MM-DD HH:mm')
          }
          formatter={(value, name) => [
            formatNumberDecimals(Number(value / 1000), false, false, 3) + ' GL',
            toSentenceCase(name),
          ]}
        />
        <Area dataKey="volume" stroke="#cccccc" fill="#cccccc" dot={true} />
        <Line dataKey="inflow" stroke="#0054a6" fill="#0054a6" dot={true} />
        <Line dataKey="release" stroke="#f36c2180" fill="#f36c2180" dot={true} />
      </ComposedChart>
    </ResponsiveContainer>
  );
}
HourlyUpdatesGraph.propTypes = {
  graphData: PropTypes.array,
};
