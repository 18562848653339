// Library
import React, { useEffect, useState, useContext } from 'react';
import { isEmpty, startCase, groupBy } from 'lodash';

// Styles
import './manageRequestBody.scss';

// Components
import BodyContainer from '../bodyContainer/PageBodyContainer';
import ManageBody from '../ManageBody';
import DownloadSVG from '../../../image/icons/DownloadSVG';
import Pagination from '../../generalPagination/Pagination';
import LoginModalBody from '../../alert/LoginModalBody';

// Context
import { UserContext } from '../../../contexts/UserContext';

// Helpers
import azureB2CHelper from '../../../helpers/AzureB2CHelper';
import { getDataDownloadRequest } from '../../../helpers/ServicesApiHelper';

const component = 'manage-request';
const HEADER_ITEMS = {
  0: ['ID', 'Requested data download', 'Request date', 'Status', 'Download'],
  1: ['ID', 'Requested bore construction reports', 'Request date', 'Status', 'Download'],
};
const RESULT_PER_PAGE = 10;

const ManageRequestBody = () => {
  const { isLoggedIn } = useContext(UserContext);
  const [curPage, setCurPage] = useState({});
  const [requestItems, setRequestItems] = useState([]);

  useEffect(() => {
    (async () => {
      getData();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async () => {
    setRequestItems([]);
    const email = await azureB2CHelper.getUserEmail();
    const data = await getDataDownloadRequest(email);
    const grouped = groupBy(data, 'request_type');
    Object.values(grouped).map((item, index) => {
      setCurPage(prevState => {
        return { ...prevState, [index]: 1 };
      });
    });
    setRequestItems(grouped);
  };

  const sliceResult = (items, page) => {
    return items.slice((page - 1) * RESULT_PER_PAGE, page * RESULT_PER_PAGE);
  };

  const buildDescription = item => {
    if (item.request_type === 'hdd') {
      const varList = item.variables
        .split(',')
        .map(varItem => startCase(varItem))
        .join(', ');
      // const siteList = item.station_id.split(',').length > 1 ? 'multiple sites' : item.station_id;
      return `${startCase(item.interval)} data for ${item.station_id.replaceAll(
        ',',
        ', ',
      )} for ${varList}`;
    }
    const boreList = item.station_id.split(',').length > 1 ? 'multiple bores' : item.station_id;
    return `Bore construction reports request for ${boreList}`;
  };

  return isLoggedIn ? (
    <BodyContainer selected="request">
      {!isEmpty(requestItems) &&
        Object.values(requestItems).map((requestItem, index) => (
          <div key={index} className={component}>
            <ManageBody headers={HEADER_ITEMS[index]}>
              {sliceResult(requestItem, curPage[index]).map((item, index) => (
                <div className={`${component}-body`} key={index}>
                  <div>{item.request_id}</div>
                  <div className={`${component}-body-desc`}>{buildDescription(item)}</div>
                  <div>{item.created_datetime.split('T')[0]}</div>
                  <div>{item.status}</div>
                  <a
                    className={`${component}-download-link${
                      item.status !== 'READY' ? '-disabled' : ''
                    }`}
                    href={item.download_link}
                  >
                    <DownloadSVG />
                  </a>
                </div>
              ))}
            </ManageBody>
            <Pagination
              onPageChange={value => {
                setCurPage(prevState => {
                  return { ...prevState, [index]: value };
                });
              }}
              totalCount={requestItem.length}
              currentPage={curPage[index]}
              pageSize={RESULT_PER_PAGE}
            />
          </div>
        ))}
    </BodyContainer>
  ) : (
    <div className={`${component}-not-auth`}>
      <LoginModalBody action="view" type="request" />
    </div>
  );
};

export default ManageRequestBody;
