// Library
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

// Style
import './allocation.scss';

// Assets
import licenses from './allocationLicense.json';

const component = `allocation-info`;

export default function AllocationInfoContent({ info, pathName, setPositionCenter }) {
  return (
    <div className={`${component}-container`}>
      <div className={`${component}-container-title`}>
        <div className={`${component}-container-title-text`}>{info.cat_name}</div>
        {licenses[info.cat_name] && (
          <div
            className={`${component}-container-subtitle`}
            data-toggle="modal"
            data-target="#allocationModal"
          >
            {' '}
            What is this?
          </div>
        )}
      </div>
      <div>
        {`There are currently ${info.access_license} ${info.cat_name} licences. `}
        {info.env_access_license !== '0'
          ? `${info.env_access_license} of these are partially or totally held for the environment.* `
          : 'None are held for the environment.* '}
        {`In the current water year, `}
        {info.water_allocated !== '0'
          ? `${info.water_allocated} ML has been allocated${
              info.env_water_allocated !== '0'
                ? `, of which ${info.env_water_allocated} ML is for the environment.`
                : '.'
            }`
          : 'no water has been allocated.'}
        {` ${info.carryover} `}
        {info.water_usage !== '0' && typeof info.water_usage !== 'undefined'
          ? `Usage (including orders) this year has been ${info.water_usage} ML`
          : 'There has been no usage this year'}
        {info.cat_name === 'Supplementary water'
          ? `.`
          : ` and ${info.water_available} ML is available to use.`}
      </div>
      <div className={`${component}-container-note`}>
        *Environment volumes may not include e-water held within third parties, see&nbsp;
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.dpie.nsw.gov.au/water/environmental-water-management-in-nsw/environmental-water-data/held-environmental-water-data/held-environmental-water-licences-register"
        >
          Environmental Water Licences Register
        </a>
        &nbsp;for more details
      </div>
      <div className={`${component}-container-url`}>
        <NavLink
          className="allocation-link-item"
          to={{
            pathname: pathName,
            hash: info.cat_name ? info.cat_name.replace('&', 'and') : info.cat_name,
          }}
          onClick={
            setPositionCenter
              ? () => {
                  setPositionCenter('rules');
                }
              : undefined
          }
        >
          View water rules &gt;
        </NavLink>
      </div>
    </div>
  );
}

AllocationInfoContent.propTypes = {
  info: PropTypes.shape({
    access_license: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    adjustments: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    carryover: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    cat_name: PropTypes.string,
    env_access_license: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    env_shares: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    shares: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    water_allocated: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    env_water_allocated: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    water_available: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    water_usage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  pathName: PropTypes.string,
  setPositionCenter: PropTypes.func,
};
