import React from 'react';
import PropTypes from 'prop-types';

const InfoSVG = ({ iconColor, onBtnClick }) => {
  return (
    <svg
      onClick={onBtnClick}
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      className={`${iconColor ? iconColor : ''}`}
      fill="white"
    >
      <path
        id="prefix__np_information_544649_000000"
        d="M12.355 5.981a6.374 6.374 0 1 1-6.374 6.374 6.383 6.383 0 0 1 6.374-6.374m0-.981a7.355 7.355 0 1 0 7.355 7.355A7.346 7.346 0 0 0 12.355 5zm.49 10.656V11.6a.49.49 0 0 0-.981 0v4.054a.482.482 0 0 0 .49.49.492.492 0 0 0 .491-.488zm-.49-7.028a.654.654 0 1 0 .654.654.656.656 0 0 0-.654-.654z"
        transform="translate(-5 -5)"
      />
    </svg>
  );
};

export default InfoSVG;

InfoSVG.propTypes = {
  iconColor: PropTypes.string,
  onBtnClick: PropTypes.func,
};
