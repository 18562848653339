// Library
import React, { forwardRef } from 'react';

// Style
import './graphAndDownloadDatePicker.scss';

// Asset
import CalendarSVG from '../../../../../../image/icons/CalendarSVG';

// Component
import DatePicker from 'react-datepicker';

// Constant
const component = 'graph-and-download-date-picker';
const DATE_PICKER_FORMAT = 'dd-MM-yyyy';

const GraphAndDownloadDatePicker = ({ selectedDate, setSelectedDate, disabled = false }) => {
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className={`${component}-btn${disabled ? ' disabled' : ''}`}
      onClick={onClick}
      ref={ref}
      disabled={disabled}
    >
      <CalendarSVG customClass={`${component}-btn-icon`} />
      {value}
    </button>
  ));
  CustomInput.displayName = 'DateButton';

  return (
    <div className={`${component}`}>
      <DatePicker
        selected={selectedDate}
        onChange={setSelectedDate}
        customInput={<CustomInput />}
        dateFormat={DATE_PICKER_FORMAT}
        maxDate={new Date()}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        disabled={disabled}
      />
    </div>
  );
};

export default GraphAndDownloadDatePicker;
