// Library
import React from 'react';
import PropTypes from 'prop-types';

// Style
import './feedbackButton.scss';

const FeedbackButton = ({ formState, onBtnClick }) => {
  return (
    <>
      <button
        data-testid="feedback-btn"
        className={`feedback-btn ${formState && `feedback-btn-expand`}`}
        onClick={() => {
          onBtnClick(prev => !prev);
        }}
      >
        <div data-testid="feedback-btnName" className="feedback-btn-text">
          Feedback
        </div>
      </button>
    </>
  );
};

export default FeedbackButton;

FeedbackButton.propTypes = {
  formState: PropTypes.bool.isRequired,
  onBtnClick: PropTypes.func.isRequired,
};
