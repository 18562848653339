// Libraries
import React, { useEffect, useContext, useState } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

// Styles
import './waterQualityMarker.scss';

// Components
import MapPopup from '../mapPopup/MapPopup';

// Context
import { AppContext } from '../../contexts/AppContext';
import { MobileContext } from '../../contexts/MobileContext';
import { MapContext } from '../../contexts/MapContext';

// Helpers
import {
  promiseXMLHttpRequestMapService,
  queryEsri,
  generateMarkerIcon,
} from '../../helpers/MapServiceApiHelper';
import { getStationsForWaterSource } from '../../helpers/ApiHelper.jsx';

// Assets
import waterQualityTeal from '../../image/icons/waterQuality_teal.png';
import waterQualityGreen from '../../image/icons/waterQuality_green.png';
import waterQualityAmber from '../../image/icons/waterQuality_amber.png';
import waterQualityRed from '../../image/icons/waterQuality_red.png';
import waterQualityDarkBlue from '../../image/icons/waterQuality_darkBlue.png';
import waterQualityLightBlue from '../../image/icons/waterQuality_lightBlue.png';
import waterQualityGrayBlue from '../../image/icons/waterQuality_grayBlue.png';

// Constants
import constants from '../../constants/Constants.jsx';
const component = 'water-quality-marker';
const DISSOLVED_OXYGEN_TYPE = 'dissolved-oxygen';
const ALGAL_TYPE = 'algal';

export default function WaterQualityMarker({ setShowSpinner, selected, markerClusterRef, mapRef }) {
  const { waterSource } = useContext(AppContext);
  const { isMobile } = useContext(MobileContext);
  const { markerSelected } = useContext(MapContext);
  const [algalData, setAlgalData] = useState([]);
  const [dissolvedData, setDissolvedData] = useState([]);

  // Lifecycle
  useEffect(() => {
    let unmounted = false;
    setAlgalData([]);
    setDissolvedData([]);
    if (selected) {
      if (!isEmpty(waterSource)) {
        getStationsForWaterSource(waterSource, 'water_quality', setDissolvedData);
        getStationsForWaterSource(waterSource, 'algal_lab_sample', function (result) {
          queryEsri(constants.ALGAL_ALERTS_MAP, '1=1', function (queryResult) {
            if (!unmounted) {
              runQuery(queryResult, result);
            }
          });
        });
      } else {
        queryEsri(constants.ALGAL_ALERTS_MAP, '1=1', function (queryResult) {
          if (!unmounted) {
            runQuery(queryResult);
          }
        });
      }
    }

    async function runQuery(query, result) {
      let queryResult = await promiseXMLHttpRequestMapService(query);
      if (!isEmpty(queryResult.features)) {
        if (!isEmpty(result)) {
          const filteredSites = result.map(item => {
            return item.station_id;
          });
          const filtered = queryResult.features.filter(item =>
            filteredSites.includes(item.properties.site_code),
          );
          filtered.map(item => {
            const matched = result.find(site => site.station_id === item.properties.site_code);
            item.properties.site_name = matched.station_name;
          });
          setAlgalData(filtered);
        } else {
          setAlgalData(queryResult.features);
        }
      }
      setShowSpinner(false);
    }
    return function cleanUp() {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  const setMarkerIcon = (feature, type) => {
    let iconUrl = waterQualityTeal;
    const sizeConfig = isMobile ? constants.MAP_MARKER_SIZE_MOBILE : constants.MAP_MARKER_SIZE;
    if (type === ALGAL_TYPE && feature.properties) {
      switch (feature.properties.current_status) {
        case '0':
          iconUrl = waterQualityGreen;
          break;
        case '1':
          iconUrl = waterQualityAmber;
          break;
        case '2':
          iconUrl = waterQualityRed;
          break;
        default:
          iconUrl = waterQualityTeal;
          break;
      }
    } else if (feature.water_data_variables.includes('DissolvedOxygen')) {
      iconUrl = waterQualityDarkBlue;
    } else if (feature.water_data_variables.includes('Conductivity')) {
      iconUrl = waterQualityLightBlue;
    } else {
      iconUrl = waterQualityGrayBlue;
    }
    const className = `${component}-${type}${
      type === ALGAL_TYPE && feature.properties ? `-${feature.properties.current_status}` : ''
    }`;
    const markerIcon = generateMarkerIcon(iconUrl, sizeConfig, className);
    return markerIcon;
  };

  const getPosition = feature => {
    if (feature.geometry) {
      return [feature.geometry.coordinates[1], feature.geometry.coordinates[0]];
    } else {
      return [feature.lat, feature.long];
    }
  };

  return (
    <>
      {!isEmpty(algalData) &&
        algalData.map((feature, index) => (
          <MapPopup
            contentStyle={`marker-popup-header ${component}-popup`}
            markerClusterRef={markerClusterRef}
            mapRef={mapRef}
            key={index}
            type={ALGAL_TYPE}
            openPopup={
              markerSelected &&
              markerSelected.station_id === feature.site_code &&
              markerSelected.type === ALGAL_TYPE
            }
            position={getPosition(feature)}
            data={feature}
            title="Water quality"
            custIcon={setMarkerIcon(feature, ALGAL_TYPE)}
            markerType={ALGAL_TYPE}
            setShowSpinner={setShowSpinner}
          />
        ))}
      {!isEmpty(dissolvedData) &&
        dissolvedData.map((feature, index) => (
          <MapPopup
            contentStyle={`marker-popup-header ${component}-popup`}
            markerClusterRef={markerClusterRef}
            mapRef={mapRef}
            key={index}
            type={DISSOLVED_OXYGEN_TYPE}
            openPopup={
              markerSelected &&
              markerSelected.station_id === feature.site_code &&
              markerSelected.type === DISSOLVED_OXYGEN_TYPE
            }
            position={getPosition(feature)}
            data={feature}
            title="Water quality"
            custIcon={setMarkerIcon(feature, DISSOLVED_OXYGEN_TYPE)}
            markerType={DISSOLVED_OXYGEN_TYPE}
            setShowSpinner={setShowSpinner}
          />
        ))}
    </>
  );
}

WaterQualityMarker.propTypes = {
  setShowSpinner: PropTypes.func,
  type: PropTypes.string,
  selected: PropTypes.bool,
  markerClusterRef: PropTypes.object,
  mapRef: PropTypes.object,
};
