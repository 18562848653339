// Libraries
import React, { useEffect, useContext, useState } from 'react';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';

// Context
import { AppContext } from '../../contexts/AppContext';
import { MapContext } from '../../contexts/MapContext';

// Components
import ArrowCircleSVG from '../../image/icons/ArrowCircleSVG';

// Styles
import './neighbouringNavigationArrow.scss';

/* Constant */
import neighbouringWS from './waterSourcesNeighbours.json';
import constants from '../../constants/Constants';
const container = 'arrow-neighbour';

export default function NeighbouringNavigationArrow({ visible }) {
  // Context
  const { waterSource, setWaterSource } = useContext(AppContext);
  const { expandMap } = useContext(MapContext);

  const history = useHistory();

  // State
  const [waterSourceNeighbours, setWaterSourceNeighbours] = useState(
    neighbouringWS[waterSource.water_source_id],
  );
  const [visibleArrows, setVisibleArrows] = useState(visible);
  const [arrowFocused, setArrowFocused] = useState();

  useEffect(() => {
    setWaterSourceNeighbours(neighbouringWS[waterSource.water_source_id]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterSource]);

  useEffect(() => {
    setVisibleArrows(visible);
  }, [visible]);

  const mouseClicked = waterSourceId => {
    const waterSourceList = JSON.parse(localStorage.getItem('waterSourceList'));
    if (!isEmpty(waterSourceList)) {
      const newWaterSource = waterSourceList.find(
        ws => Number(ws.water_source_id) === Number(waterSourceId),
      );
      setWaterSource(newWaterSource, history);
      expandMap(true);
    }
  };

  const renderWaterSourceName = waterSourceId => {
    const waterSourceList = JSON.parse(localStorage.getItem('waterSourceList'));
    if (!isEmpty(waterSourceList)) {
      const newWaterSource = waterSourceList.find(
        ws => Number(ws.water_source_id) === Number(waterSourceId),
      );
      return newWaterSource.water_source_name;
    }
  };

  const mouseOver = value => {
    setArrowFocused(value);
  };

  const mouseLeave = () => {
    setArrowFocused(-1);
  };

  return (
    <>
      {waterSourceNeighbours &&
        visibleArrows &&
        Object.entries(waterSourceNeighbours).map(([key, value], index) => {
          return (
            <div
              key={index}
              className={`${container}-${key}
              ${
                key === 'bottom' &&
                waterSource.water_source_id ===
                  constants.BARWON_DARLING_UNREGULATED_RIVER_WATER_SOURCE
                  ? ' barwon-arrow'
                  : ''
              }`}
              onClick={() => {
                mouseClicked(value);
              }}
              onMouseEnter={() => {
                mouseOver(value);
              }}
              onMouseLeave={() => {
                mouseLeave();
              }}
            >
              <div className={`${container}-label${arrowFocused === value ? ' show' : ''}`}>
                {renderWaterSourceName(value)}
              </div>
              <div key={index} className={`${container}-${key}-icon`}>
                <ArrowCircleSVG />
              </div>
            </div>
          );
        })}
    </>
  );
}
