// Library
import React from 'react';

// Component
import BodyContainer from '../../layout/body/BodyContainer';
import GlobalSearchBody from '../../components/globalSearch/GlobalSearchBody';

const GlobalSearch = () => {
  return (
    <BodyContainer page>
      <GlobalSearchBody />
    </BodyContainer>
  );
};

export default GlobalSearch;
