// Libraries
import * as esri from 'esri-leaflet';
import L from 'leaflet';
import axios from 'axios';
import { isEmpty } from 'lodash';
import * as qs from 'qs';

// Assets
import constants from '../constants/Constants';

export const promiseXMLHttpRequestMapService = query => {
  return new Promise(resolve => {
    query.run((error, featureCollection) => {
      if (error) {
        resolve(error);
      }
      resolve(featureCollection);
    });
  });
};

export const queryEsri = async (url, whereStr, processData) => {
  let query = esri.query({ url: url, useCors: false }).precision(5).where(whereStr).limit(1500);

  /**
   * Add offset to few water sources with complicated features
   * for simiplification purpose
   */
  const regex = /WS_WATER_LOCATION_ID=(\w{1,5})/;
  const match = whereStr.match(regex);
  if (!isEmpty(match) && constants.WATER_WITH_COMPLICATE_FEATURES.includes(parseInt(match[1]))) {
    query.params.maxAllowableOffset = 0.005;
  }

  processData(query);
};

export const geocodeQueryApi = async (param, url) => {
  const result = await axios
    .get(url, {
      timeout: 15000,
      params: { ...param, f: 'pjson' },
    })
    .then(response => {
      return response.data;
    })
    .catch(() => {
      return null;
    });
  return result;
};

/**
 * Map Service Identify Operation
 */
export const queryMapServiceIdentify = async (
  geometry,
  serviceType,
  geometryType = 'esriGeometryPoint',
  coverDistance = '0,-83.8991,167.2714,30.93597',
) => {
  const serviceTypeConfig = {
    NSW: {
      url: constants.NSW_MAP_SERVICE_IDENTIFY,
      layers: 'all:2,6,7',
      attribution: '',
    },
    GS: {
      url: constants.GREATER_SYDNEY_MAP_IDENTIFY,
      layers: 'all:2',
      attribution: '',
    },
    bores: {
      url: constants.TELEMETERED_BORES_MAP_SERVICE.slice(0, -2) + '/identify',
      layers: `all:${constants.TELEMETERED_BORES_MAP_SERVICE.split('/').at(-1)}`,
      attribution: 'Telemetered bores',
    },
  };

  const { url, layers, attribution } = serviceTypeConfig[serviceType];

  try {
    const params = {
      geometry: JSON.stringify(geometry),
      returnGeometry: false,
      geometryType: geometryType,
      layers: layers,
      tolerance: 1,
      mapExtent: coverDistance,
      imageDisplay: '600,550,9',
      f: 'pjson',
      attribution: attribution,
    };
    const timeout = 15000;

    const result = await axios.get(url, {
      timeout: timeout,
      params: params,
      paramsSerializer: params => {
        return qs.stringify(params);
      },
    });

    return result;
  } catch (error) {
    return null;
  }
};

// Double query is used to have a better selection for the default watersource
export const doubleQueryMapServiceIdentify = async geometry => {
  const wideQuery = await queryNSWMapIdentify(geometry, '0,-83.8991,167.2714,30.93597');
  const narrowQuery = await queryNSWMapIdentify(geometry, '0,-5,27,10');
  const defaultRegSelection = narrowQuery?.results.find(
    elem => elem.layerName === 'Regulated River Water Sources',
  );
  const defaultUnregSelection = narrowQuery?.results.find(
    elem => elem.layerName === 'Surface Water Sources',
  );
  const defaultSelection = !isEmpty(defaultRegSelection)
    ? defaultRegSelection
    : defaultUnregSelection;
  const result = wideQuery?.results.map(item => {
    if (
      item?.attributes.WS_WATER_LOCATION_ID === defaultSelection?.attributes.WS_WATER_LOCATION_ID
    ) {
      return { ...item, tag: 'prioritized' };
    }
    return item;
  });
  return { results: result };
};

export const queryNSWMapIdentify = async (geometry, coverDistance) => {
  const NSWRes = await queryMapServiceIdentify(geometry, 'NSW', null, coverDistance);
  const GSRes = await queryMapServiceIdentify(geometry, 'GS', null, coverDistance);
  const result = [NSWRes, GSRes].reduce(
    (accumulator, response) => {
      const data = response.data;
      if (!data.error) {
        accumulator.results = [...accumulator.results, ...data.results];
      }
      return accumulator;
    },
    { results: [] },
  );
  return result;
};

/**
 * Map Service Query Operation
 */

export const queryMapServiceQuery = async (url, queryParams) => {
  try {
    const params = {
      ...queryParams,
    };
    const timeout = 15000;

    const result = await axios.get(`${url}/query`, {
      timeout: timeout,
      params: params,
      paramsSerializer: params => {
        return qs.stringify(params);
      },
    });

    return result;
  } catch (error) {
    return null;
  }
};

/**
 * Icon Generation
 */

export const generateMarkerIcon = (icon, sizeConfig, className) => {
  return L.icon({
    iconUrl: icon ? icon : '',
    iconSize: sizeConfig.iconSize,
    iconAnchor: sizeConfig.iconAnchor,
    className: className,
  });
};

export const generateDivIcon = (icon, sizeConfig, className) => {
  return L.divIcon({
    html: icon ? icon : '',
    iconSize: sizeConfig.iconSize,
    iconAnchor: sizeConfig.iconAnchor,
    className: className,
  });
};
