// Libraries
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { isArray, isEmpty } from 'lodash';

// Context
import { AppContext } from '../../contexts/AppContext';
import { MobileContext } from '../../contexts/MobileContext';
import { MapContext } from '../../contexts/MapContext';

// Styles
import './body.scss';
import '../grid.scss';

// Components
import BodyContainer from './BodyContainer';
import StorageBody from '../../components/storage/Storage';
import ExternalLinkModal from '../../components/modal/ExternalLinkModal';
import InfoIconAndModal from '../../components/informationButton/InfoIconAndModal';
import InlineStorageWeir from '../../components/storage/InlineStorageWeir';
import PageTop from '../../components/pageTop/PageTop';
import DataQualityLegend from '../../components/generalComponents/dataQuality/DataQualityLegend';

// Assets
import config from '../../abstract/IntroText.json';
import constants from '../../constants/Constants';
import gsModalText from '../../components/modal/modalText/GreaterSydneyModalText.json';
import storageModalText from '../../components/modal/modalText/StorageModalText.json';

// Helpers
import { getCommentaryByWSId } from '../../helpers/ApiHelper';
import { handleGenericError, findLastWord } from '../../helpers/Utils';

// Constant
const container = 'storage-container';

export default function Storage() {
  const { waterSource } = useContext(AppContext);
  const { isMobile } = useContext(MobileContext);
  const { mapExpanded } = useContext(MapContext);
  const [commentary, setCommentary] = useState(null);
  const [externalLink, setExternalLink] = useState('');
  const [hasWeirs, setHasWeirs] = useState(true);
  const history = useHistory();
  const isGreaterSydney = waterSource.water_source_id === constants.GREATER_SYDNEY_WSID;
  gsModalText.modal.title = isGreaterSydney
    ? `Understanding ${waterSource.water_source_name}`
    : `Understanding Sydney Water Drinking Catchment`;
  const { modal } = isGreaterSydney ? gsModalText : storageModalText.dams;

  useEffect(() => {
    (async () => {
      try {
        const commentary = await getCommentaryByWSId(waterSource.water_source_id);

        if (commentary && isArray(commentary.resources)) {
          const latestCommentary = commentary.resources
            .filter(r => r.type === 'Storage')
            .reverse()[0];
          if (latestCommentary) {
            setCommentary(latestCommentary.body);
          }
        }
      } catch (err) {
        handleGenericError(err, history);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterSource]);

  let title = `DAMS IN ${waterSource.water_source_name}`;
  let intro = commentary;

  switch (waterSource.water_source_id) {
    case constants.MURRAY_REGULATED_RIVER_WATER_SOURCE:
      title = config.storage.titleMurray;
      intro = [commentary, config.storage.introMurray];
      break;
    case constants.LOWER_DARLING_REGULATED_RIVER_WATER_SOURCE:
      intro = [commentary, config.storage.introLower];
      break;
    case constants.BARWON_DARLING_UNREGULATED_RIVER_WATER_SOURCE:
      intro = [commentary, config.storage.introBarwon];
      break;
    default:
      break;
  }

  const pageTopTitle = (type, modalId, modalContent, exact) => {
    const text = exact ? type : `${type} IN ${waterSource.water_source_name}`;
    const { last, notLast } = findLastWord(text);
    return (
      <div className={`${container}-pageTop`}>
        <span className={`${container}-title`}>
          {notLast}
          &nbsp;
          <InfoIconAndModal
            modalId={modalId}
            modalContent={modalContent}
            lastWord={
              <span className={'page-body-title'}>
                {last}
                &nbsp;
              </span>
            }
          />
        </span>
      </div>
    );
  };

  const externalLinkModal = modalId => {
    return <ExternalLinkModal link={externalLink} modalId={modalId} />;
  };

  return (
    <div className={`${container}`}>
      <BodyContainer>
        <div className={`storage-weir-container${hasWeirs ? '' : 'null'}`}>
          {hasWeirs && (
            <>
              <PageTop
                title={pageTopTitle('WEIRS', 'weirsTitleModal', storageModalText.weirs)}
                setModalLink={link => setExternalLink(link)}
                modalTargetId="#externalLinkWeirs"
              />
              {!mapExpanded && (
                <div className={`storage-weir-container-quality-legend `}>
                  <DataQualityLegend />
                </div>
              )}
              {isMobile && externalLinkModal('externalLinkWeirs')}
            </>
          )}
          <InlineStorageWeir setHasWeirs={setHasWeirs} />
        </div>
        {!isEmpty(waterSource.dams) && (
          <>
            <PageTop
              title={pageTopTitle(title, 'damsTitleModal', modal, true)}
              intro={!Array.isArray(intro) && !isGreaterSydney ? intro : ''}
              setModalLink={link => setExternalLink(link)}
              modalTargetId="#externalLinkStorage"
            />
            <StorageBody commentary={intro} />
            {isMobile && externalLinkModal('externalLinkStorage')}
          </>
        )}
      </BodyContainer>
    </div>
  );
}
