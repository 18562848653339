// Library
import React from 'react';
import PropTypes from 'prop-types';

// Style
import './alertResponse.scss';

// Helpers
import { getDatesByPeriod } from '../../helpers/TimeUtils';
import TaskAltSVG from '../../image/icons/TaskAlt';

// Constant
const container = 'alert-response';

const AlertResponse = ({ show, responseTitleText, responseBodyText, responseType }) => {
  return (
    <>
      {show && (
        <div className={`${container} ${show && `${container}-show`}`}>
          {responseType === 'create' && (
            <div>
              <TaskAltSVG />
            </div>
          )}
          <div className={`${container}-title-text`}>{responseTitleText}</div>
          {responseType === 'create' && (
            <div className={`${container}-sub-text`}>
              <p>Edit or unsubscribe in My Alerts.</p>
              <p>
                <span>Note:</span> Your subscription will end on{' '}
                {getDatesByPeriod(3, 'DD-MMM-YYYY', 'months')}.
              </p>
            </div>
          )}
          <div className={`${container}-body-text`}>{responseBodyText}</div>
          <div className={`${container}-button ${container}-button-ok`} data-dismiss="modal">
            Ok
          </div>
        </div>
      )}
    </>
  );
};

export default AlertResponse;

AlertResponse.propTypes = {
  show: PropTypes.bool.isRequired,
  responseTitleText: PropTypes.string,
  responseBodyText: PropTypes.string,
  responseType: PropTypes.string,
};
