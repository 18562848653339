// Library
import React from 'react';
import PropTypes from 'prop-types';

// Style
import './MapIcon.scss';

// Assests
import searchMapIcon from '../../image/icons/search_location.png';

const MapIcon = ({ onClick }) => {
  return (
    <div className={`data-summary-map-icon-container`} onClick={onClick}>
      <img className={`data-summary-map-icon-img`} src={searchMapIcon} alt="Information icon" />
    </div>
  );
};

export default MapIcon;

/* Props */
MapIcon.propTypes = {
  /**
   * This triggers the onclick function passed down
   * It expects a function
   */
  onClick: PropTypes.func,
};
