// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';

// Styles
import './chartTooltip.scss';

// Helpers
import { sumValuesFromArrayMap, sumValuesFromMap } from '../../helpers/ArrayUtils';
import { formatNumberDecimals } from '../../helpers/Utils';
import { formatLabelWaterYear, formatDate } from '../../helpers/TimeUtils';

/* Constant */
const LICENCED_WATER_KEY = 'Licenced water';
const WATER_BALANCE_GRAPH = 'water_balance';
export default function ChartTooltip({
  payload,
  graphType,
  subElementKey,
  titleKey,
  titleText,
  isPayloadArray,
  setAbsoluteValues = false,
  showTotal = true,
  noStartCase = false,
  murrayGraph = false,
  isDaily = false,
  showTitle = true,
  decimals = null,
}) {
  if (payload && payload[0] && payload[0].payload) {
    if (murrayGraph) {
      const { date, storage, ...rest } = payload[0].payload;
      payload[0].payload = { resources: { rest }, storage, date };
    }
    const payloadData = payload[0].payload;

    const renderTotalValue = () => {
      if (murrayGraph) {
        const titleTooltip = formatDate(
          payloadData.date,
          isDaily ? 'YYYY-MM-DD' : 'YYYY-MM',
          isDaily ? 'DD MMMM YYYY' : 'MMMM YYYY',
        );
        const total =
          payloadData[subElementKey] && typeof payloadData[subElementKey] === 'object'
            ? Object.values(payloadData[subElementKey]).reduce((a, b) => a + b)
            : payloadData[subElementKey];
        return `${titleTooltip}${
          showTotal && total ? ': ' + formatNumberDecimals(total / 1000) + ' GL' : ''
        }`;
      } else {
        const titleTooltip = payloadData[titleKey];
        const formattedArrayPayload =
          sumValuesFromArrayMap(payloadData[subElementKey]) > 10000
            ? formatNumberDecimals(sumValuesFromArrayMap(payloadData[subElementKey]) / 1000)
            : formatNumberDecimals(
                Number(sumValuesFromArrayMap(payloadData[subElementKey]) / 1000).toFixed(2),
              );
        const formattedMapPayload =
          sumValuesFromMap(payloadData) > 10000
            ? formatNumberDecimals(sumValuesFromMap(payloadData) / 1000)
            : formatNumberDecimals(Number(sumValuesFromMap(payloadData) / 1000).toFixed(2));
        return showTitle
          ? isPayloadArray
            ? `${titleText} ${formatLabelWaterYear(titleTooltip)}${
                showTotal ? ': ' + formattedArrayPayload + ' GL' : ''
              }`
            : `${titleTooltip}${showTotal ? ': ' + formattedMapPayload + ' GL' : ''}`
          : '';
      }
    };

    const formatSetStartCase = (noStartCase, name) => {
      return `${noStartCase ? name : startCase(name)}`;
    };

    const checkAndSetAbsoluteValues = (setAbsoluteValues, payloadValue) => {
      let valueToReturn = 0;

      if (setAbsoluteValues) {
        if (payloadValue > 10000) {
          valueToReturn = Math.round(Math.abs(payloadValue) / 1000);
        } else {
          valueToReturn = Math.abs(Number(payloadValue / 1000)).toFixed(2);
        }
      } else {
        if (payloadValue > 10000) {
          valueToReturn = Math.round(payloadValue / 1000);
        } else {
          valueToReturn = Number(payloadValue / 1000).toFixed(2);
        }
      }

      return formatNumberDecimals(valueToReturn);
    };

    return (
      <div className="chart-tooltip">
        <ul className="chart-tooltip-item-ul">
          <li className="list-no-style-type">{renderTotalValue()}</li>
          {payload.reverse().map(
            (data, index) =>
              !(
                (graphType === WATER_BALANCE_GRAPH) &
                (data.payload.type !== LICENCED_WATER_KEY)
              ) && (
                <li
                  key={index}
                  className="list-no-style-type chart-tooltip-item"
                  style={{ color: data.color }}
                >
                  {graphType && graphType === 'annual_extraction'
                    ? data.name !== 'Usage'
                      ? `${data.name}: ${formatNumberDecimals(
                          data.value,
                          false,
                          false,
                          decimals,
                        )} m`
                      : `${data.name}: ${checkAndSetAbsoluteValues(false, data.value)} GL`
                    : `${formatSetStartCase(noStartCase, data.name)}: ${checkAndSetAbsoluteValues(
                        setAbsoluteValues,
                        data.value,
                      )} GL`}
                </li>
              ),
          )}
        </ul>
      </div>
    );
  } else {
    return [];
  }
}

ChartTooltip.propTypes = {
  payload: PropTypes.array,
  subElementKey: PropTypes.string,
  titleKey: PropTypes.string,
  titleText: PropTypes.string,
  isPayloadArray: PropTypes.bool,
  setAbsoluteValues: PropTypes.bool,
  showTotal: PropTypes.bool,
  noStartCase: PropTypes.bool,
  murrayGraph: PropTypes.bool,
  isDaily: PropTypes.bool,
  showTitle: PropTypes.bool,
};
