/* Library */
import React, { useState, createContext, useEffect } from 'react';
import PropTypes from 'prop-types';

/* Constant */
import constants from '../constants/Constants';

export const MobileContext = createContext();

/**
 * Written with extenstion - Better Comments
 * ! window.screen.orientation is not supported by iOS
 * ! window.orientation is supported by all, but it is deprecated.
 * * This context will most likely need updating in near future
 */

export default function MobileContextProvider(props) {
  const { SCREEN_SM, SCREEN_MD, SCREEN_LG, SCREEN_XL } = constants;
  const [isMobile, setMobile] = useState(window.innerWidth < SCREEN_SM);
  const [isMedium, setMedium] = useState(window.innerWidth <= SCREEN_MD);
  const [isLarge, setLarge] = useState(window.innerWidth < SCREEN_LG);
  const [isXL, setXL] = useState(window.innerWidth < SCREEN_XL);

  const checkOrientation = degree => {
    if (degree === -90 || degree === 90) {
      return constants.LANDSCAPE;
    } else {
      return constants.PORTRAIT;
    }
  };

  const isScreenGood = () => {
    if (window.screen.orientation) {
      return window.screen.orientation.type;
    } else if (Number.isFinite(window.orientation)) {
      return checkOrientation(window.orientation);
    } else {
      return constants.PORTRAIT;
    }
  };

  const [orientation, setOrientation] = useState(isScreenGood());

  useEffect(() => {
    viewportUpdate();
    window.addEventListener('orientationchange', orientationUpdate);
    window.addEventListener('resize', viewportUpdate);
    return () => {
      window.removeEventListener('resize', viewportUpdate);
      window.removeEventListener('orientationchange', orientationUpdate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const viewportUpdate = () => {
    setMobile(window.innerWidth < SCREEN_SM);
    setMedium(window.innerWidth <= SCREEN_MD);
    setLarge(window.innerWidth < SCREEN_LG);
    setXL(window.innerWidth < SCREEN_XL);
  };

  const orientationUpdate = () => {
    setOrientation(isScreenGood());
  };

  return (
    <MobileContext.Provider
      value={{
        isMobile,
        isMedium,
        isLarge,
        isXL,
        orientation,
      }}
    >
      {props.children}
    </MobileContext.Provider>
  );
}

MobileContextProvider.propTypes = {
  children: PropTypes.element,
};
