import React from 'react';
import Select from 'react-select';
import './yearMultiSelector.scss';

export default function YearMultiSelector(props) {
  return (
    <Select
      isMulti
      defaultValue={props.defaultYears}
      isSearchable={false}
      name="years"
      placeholder="Select years"
      className="basic-multi-select"
      classNamePrefix="select"
      options={props.listOfYears}
      onChange={props.onYearChange}
      theme={theme => ({
        ...theme,
        borderRadius: 10,
        colors: {
          ...theme.colors,
          primary: '#0054a6',
        },
        spacing: {
          baseUnit: 5,
          controlHeight: 46,
          menuGutter: 10,
        },
      })}
    />
  );
}
