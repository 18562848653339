// Libraries
import React, { useEffect, useState, useContext } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

// Context
import { AppContext } from '../../contexts/AppContext';

// Styles
import './mapPinLocation.scss';

// Assets
import directionIcon from '../../image/icons/direction.png';

// Helper
import { updateDisplayById } from '../../helpers/Utils';
import { geocodeQueryApi } from '../../helpers/MapServiceApiHelper';

// Assets
import constants from '../../constants/Constants';

const container = 'map-pin-location';

export default function MapPinLocation({ onNavigateClick, pinLocation }) {
  const { waterSource } = useContext(AppContext);
  const [address, setAddress] = useState(Object);
  const [errMsg, setErrMsg] = useState(null);

  const onCloseButtonClicked = () => {
    updateDisplayById(container, 'none');
  };

  useEffect(() => {
    let mounted = true;
    setAddress({});
    setErrMsg(null);
    if (!isEmpty(pinLocation)) {
      updateDisplayById(container, 'block');
      (async () => {
        const result = await geocodeQueryApi(
          { location: pinLocation.lng + ',' + pinLocation.lat, forStorage: false },
          constants.GEOCODE_ARCGIS_REVERSE_GEOCODE_URL,
        );
        if (mounted) {
          if (result && result.address) {
            setAddress(result);
          } else if (result && result.error) {
            setErrMsg(result.error.details[0]);
          } else {
            setErrMsg('Service is currently unavailable now, please try again later.');
          }
        }
      })();
    }
    return () => (mounted = false);
  }, [pinLocation, waterSource]);

  const getAddress = address => {
    switch (true) {
      case address.Address:
        return address.Address;
      case address.ShortLabel && isNaN(address.ShortLabel):
        return address.ShortLabel;
      default:
        return address.City;
    }
  };

  return (
    <div className={`${container}`} id={`${container}`}>
      <button
        type="button"
        className={`${container}-close close`}
        onClick={() => onCloseButtonClicked()}
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <div className="row">
        <div className={`${container}-address col-8 col-sm-9`}>
          {address.address || errMsg ? (
            <>
              <div className={`${errMsg ? `${container}-err-msg` : ''}`}>
                {address.address ? getAddress(address.address) : errMsg}
              </div>
              <div>{address.address && `${address.address.Region} ${address.address.Postal}`}</div>
            </>
          ) : (
            <div className={`loading-bar`} />
          )}

          <div className={`${container}-coordinates`}>
            {`${Number(pinLocation.lat).toFixed(5)}, ${Number(pinLocation.lng).toFixed(5)}`}
          </div>
        </div>
        <div
          className={`${container}-navigate ${
            errMsg || !address.address ? 'disabled' : ''
          } col-4 col-sm-3`}
          onClick={onNavigateClick}
        >
          <img src={directionIcon} alt="Direction" className={`${container}-navigate-icon`} />
          <a href="#">Go Here</a>
        </div>
      </div>
    </div>
  );
}

MapPinLocation.propTypes = {
  onNavigateClick: PropTypes.func,
  pinLocation: PropTypes.object,
};
