// Libraries
import React, { useEffect, useContext, useState, memo } from 'react';
import { groupBy, isEmpty } from 'lodash';
import moment from 'moment';

// Styles
import './MapFilterMarker.scss';
import '../mapPopup/MapPopup.scss';

// Context
import { AppContext } from '../../contexts/AppContext';
import { MapContext } from '../../contexts/MapContext';
import { MobileContext } from '../../contexts/MobileContext';

// Components
import MapPopup from '../mapPopup/MapPopup';

// Assets
import weirIcon from '../../image/icons/mapMarker/weir.svg';
import damStorageIcon from '../../image/icons/mapMarker/dam.png';
import riverGaugeIcon from '../../image/icons/mapMarker/gauge.svg';
import environmentIcon from '../../image/icons/mapMarker/environment.png';
import constraintsIcon from '../../image/icons/mapMarker/constraints.png';
import rainfallIcon from '../../image/icons/mapMarker/rainfall.png';
import pumpIcon from '../../image/icons/mapMarker/pump.png';
import boreIcon from '../../image/icons/mapMarker/bore.png';

// Helpers
import {
  getStationsForWaterSource,
  getBoreIdsForWaterSourceOfType,
} from '../../helpers/ApiHelper.jsx';
import {
  promiseXMLHttpRequestMapService,
  queryEsri,
  generateMarkerIcon,
} from '../../helpers/MapServiceApiHelper';
import { isWaterSourceREG, mergeBoreDataAndMetaData } from '../../helpers/Utils';
import { formatBoresData } from '../../helpers/ArrayUtils';
import { getCurrentDate } from '../../helpers/TimeUtils';
import { getMultiSiteMetadata, getMultiGroundLevelData } from '../../helpers/WaterDataApiHelper';

// Constant
import mapFilterConstant from '../../constants/MapFilterConstants';
import constants from '../../constants/Constants.jsx';
const { WEIR, BORE, DAM, STATIONS, PUMP } = mapFilterConstant.MAP_POPUP_ITEMS;
const LICENSED_WORK_LAYER_ID = 0;
const STATIONS_NOT_TO_SHOW = ['A4261093'];

export default memo(function MapFilterMarker({
  selected,
  type,
  setShowSpinner,
  markerClusterRef,
  mapRef,
}) {
  const { markerSelected } = useContext(MapContext);
  const { waterSource } = useContext(AppContext);
  const { isMobile } = useContext(MobileContext);
  const [data, setData] = useState([]);

  const mapFilterProps = {
    dam: {
      icon: [damStorageIcon, damStorageIcon],
      className: 'map-marker-dam-storage',
    },
    gauge: {
      icon: [
        riverGaugeIcon,
        waterSource && (isWaterSourceREG(waterSource) ? riverGaugeIcon : boreIcon),
      ],
      className: 'map-marker-river-gauge',
    },
    environment: {
      icon: [environmentIcon, environmentIcon],
      className: 'map-marker-environment',
    },
    constraints: {
      icon: [constraintsIcon, constraintsIcon],
      className: 'map-marker-constraints',
    },
    rainfall: {
      icon: [rainfallIcon, rainfallIcon],
      className: 'map-marker-rainfall',
    },
    pump: { icon: [pumpIcon, pumpIcon], className: 'map-marker-pump' },
    weir: { icon: [weirIcon, weirIcon], className: 'map-marker-weir' },
  };

  useEffect(() => {
    let unmounted = false;
    setData([]);
    if (waterSource.water_source_id && selected) {
      setShowSpinner(true);
      if (type === PUMP.name) {
        const queryString = `WS_ID='${waterSource.water_source_id}' AND (WORK_TYPE_GROUP='EXTRACTION WORKS GW' OR WORK_TYPE_GROUP='DIVERSION WORKS - PUMPS')`;
        queryEsri(
          constants.NSW_MAP_SERVICE_BASE_URL + LICENSED_WORK_LAYER_ID,
          queryString,
          function (result) {
            if (!unmounted) {
              runQuery(result);
            }
          },
        );
      } else {
        getStationsForWaterSource(waterSource, type, function (result) {
          const grouped = groupBy(result, station =>
            station.station_type === WEIR.name ? WEIR.name : STATIONS.name,
          );
          if (!isEmpty(grouped.stations)) {
            const stationIcon = type ? mapFilterProps[type].icon[0] : '';
            updateState(grouped.stations, stationIcon, STATIONS.name, unmounted);
          }
          setShowSpinner(false);
        });

        // Fetch from watersource/summary for weir details
        if (type === DAM.name) {
          getStationsForWaterSource(waterSource, WEIR.name, function (result) {
            const grouped = groupBy(result, station =>
              station.station_type === WEIR.name ? WEIR.name : STATIONS.name,
            );
            if (!isEmpty(grouped[WEIR.name])) {
              const weirIcon = mapFilterProps.weir.icon[0];
              const weirClassName = `${mapFilterProps.weir.className}`;
              updateState(grouped.weir, weirIcon, WEIR.name, unmounted, weirClassName);
            }
            setShowSpinner(false);
          });
        }

        if (!isWaterSourceREG(waterSource)) {
          if (!unmounted) {
            getBoreData();
          }
        }
      }
    }

    async function getBoreData() {
      const dateFormat = constants.STORAGE_DATE_FORMAT;
      const startDate = moment().subtract(1, 'years').add(1, 'months').format(dateFormat);
      const endDate = getCurrentDate(dateFormat);
      const boreIds = await getBoreIdsForWaterSourceOfType(waterSource.water_source_id);
      const boreMetaData = await getMultiSiteMetadata([...boreIds], BORE.siteType);
      const boreData = await getMultiGroundLevelData(boreIds, startDate, endDate, 'monthly');
      const mergedBoreInfo = mergeBoreDataAndMetaData(boreData, boreMetaData);
      const result = formatBoresData(mergedBoreInfo);
      const icon = type ? mapFilterProps[type].icon[1] : '';
      updateState(result, icon, BORE.name, unmounted);
      setShowSpinner(false);
    }

    async function runQuery(query) {
      let queryResult = await promiseXMLHttpRequestMapService(query);
      if (!isEmpty(queryResult.features)) {
        const icon = type ? mapFilterProps[type].icon[0] : '';
        updateState(queryResult.features, icon, STATIONS.name, unmounted);
      }
      setShowSpinner(false);
    }
    return function cleanUp() {
      unmounted = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, selected, waterSource.water_source_id]);

  // Helper
  const updateState = (result, icon, key, unmounted, specialClassName) => {
    let className;
    if (specialClassName) {
      className = specialClassName;
    } else {
      className = type ? `${mapFilterProps[type].className}` : '';
    }
    const sizeConfig = isMobile ? constants.MAP_MARKER_SIZE_MOBILE : constants.MAP_MARKER_SIZE;
    const markerIcon = generateMarkerIcon(icon, sizeConfig, `${className} map-marker-base`);
    const newDataState = {
      [key]: {
        data:
          type === PUMP.name
            ? result
            : result.filter(item =>
                key === 'bore'
                  ? item
                  : item.active === true || (item.active === false && item.commentary !== ''),
              ),
        icon: markerIcon,
        className: className,
      },
    };

    if (!unmounted) {
      setData(prevState => [...prevState, newDataState]);
    }
  };

  return (
    !isEmpty(data) &&
    data.map(
      dataType =>
        !isEmpty(Object.values(dataType)[0]) &&
        Object.values(dataType)[0].data.map(
          (item, index) =>
            !STATIONS_NOT_TO_SHOW.includes(item.station_id) && (
              <MapPopup
                contentStyle={`${Object.values(dataType)[0].className} marker-popup-header`}
                markerClusterRef={markerClusterRef}
                mapRef={mapRef}
                key={index}
                type={type}
                openPopup={
                  markerSelected &&
                  (markerSelected.station_id === item.station_id ||
                    markerSelected.work_number === item.station_id) &&
                  markerSelected.type === item.type
                }
                position={[
                  item.lat || item.geometry.coordinates[0][1],
                  item.long || item.geometry.coordinates[0][0],
                ]}
                data={item}
                custIcon={Object.values(dataType)[0].icon}
                markerType={Object.keys(dataType)[0]}
                setShowSpinner={setShowSpinner}
              />
            ),
        ),
    )
  );
});
