// Library
import React, { useRef, useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';

// Styyle
import './accountingRules.scss';

// Component
import ExpandBox from '../generalComponents/expandBox/ExpandBox';
import Modal from '../modal/Modal';
import AccountingRulesModalData from '../modal/modalText/AccountingRulesModalData';
import DictionaryModalContent from '../modal/DictionaryModalContent';
import InfoButton from '../informationButton/InformationButton';

// Helper
import { getAccountingRules } from '../../helpers/ApiHelper';
import { createMarkup } from '../../helpers/Utils';
import { createTarget, scrollToFirstMatch } from '../../helpers/UrlGenerator';

// Context
import { AppContext } from '../../contexts/AppContext';

// Constant
const container = 'accounting-rules';
const SECTIONS = [
  'aquifer',
  'stock',
  'general security',
  'high security',
  'salinity',
  'unregulated',
  'local water utility',
  'major utility',
  'coleambally conveyance',
  'murrumbidgee conveyance',
  'conveyance',
  'general security b',
  'general security a',
  'high security',
  'domestic & stock',
  'supplementary water',
];

const SECTIONS_ORDER = {
  'general security': 1,
  'general security b': 2,
  'general security a': 3,
  aquifer: 4,
  'high security': 5,
  'supplementary water': 6,
  conveyance: 7,
  'local water utility': 8,
  'domestic and stock': 9,
};

const RULES_ORDER = {
  'accounting method': 1,
  allocation: 2,
  'account limit': 3,
  'carryover limit': 4,
  'use limit': 5,
  'access to uncontrolled flow': 6,
  'usage limit on uncontrolled flow': 7,
};

const AccountingRules = () => {
  // Context
  const targetEl = useRef(null);
  const location = decodeURI(useLocation().hash);
  const { waterSource } = useContext(AppContext);
  // State
  const [data, setData] = useState([]);
  const [target, setTarget] = useState({});
  const [defaultRules, setDefaultRules] = useState('');

  // Life Cycle
  useEffect(() => {
    createTarget(setRules, SECTIONS, location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let unmounted = false;
    loadRulesData(unmounted);
    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterSource]);

  // Helpers
  const setRules = res => {
    setTarget(res);
    setDefaultRules(res.key);
  };

  const loadRulesData = async unmounted => {
    const res = await getAccountingRules(waterSource);
    if (!unmounted && !isEmpty(res)) {
      const sorted = sortByAlphabeticalOrder(res.resources, 'licence');
      setData(sorted);
    }
  };

  const sortByAlphabeticalOrder = (data, target) => {
    const sorted = data.sort((a, b) => {
      var nameA = a[target].toUpperCase();
      var nameB = b[target].toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    return sorted;
  };

  const sortBySpecialOrder = (data, target, sortingArray) => {
    const sorted = data.sort((a, b) => {
      var ruleOrderA = sortingArray[a[target].toLowerCase().trim()] || 99;
      var ruleOrderB = sortingArray[b[target].toLowerCase().trim()] || 99;
      return ruleOrderA - ruleOrderB;
    });
    return sorted;
  };
  // Mini Component
  const ruleHeader = category => {
    return (
      <>
        <div
          ref={category.toLowerCase().includes(target.key) ? targetEl : null}
          className="conditions-inner-body-category-name"
        >
          {category.toUpperCase()}
        </div>
        {category.toLowerCase().includes(target.key) && scrollToFirstMatch(target, targetEl)}
      </>
    );
  };

  return (
    <>
      {!isEmpty(data) && (
        <div className={container}>
          <div className={`${container}-title`}>
            <span>Accounting Rules</span>
            <InfoButton targetDataId="#accountingModalCenter" />
          </div>
          <Modal
            id="accountingModalCenter"
            title="Understanding water rules"
            body={<DictionaryModalContent data={AccountingRulesModalData} />}
          />
          <div className={`${container}-body`}>
            {sortBySpecialOrder(data, 'licence', SECTIONS_ORDER).map(dataItem => (
              <ExpandBox
                key={dataItem.licence}
                clickableSurface
                customHeaderContent={<>{ruleHeader(dataItem.licence)}</>}
                customHeaderContainerStyle={`${container}-body-licence`}
                customArrowColor={`${container}-body-licence-arrow`}
                expandState={dataItem.licence.toLowerCase().includes(defaultRules)}
              >
                {sortBySpecialOrder(dataItem.rules, 'rule_name', RULES_ORDER).map(rule => (
                  <div
                    key={`${dataItem.licence} ${rule.rule_name}`}
                    className={`${container}-body-rules`}
                  >
                    <div className={`${container}-body-rules-title`}>{rule.rule_name}</div>
                    <div
                      className={`${container}-body-rules-content`}
                      dangerouslySetInnerHTML={createMarkup(rule.rule_content)}
                    />
                  </div>
                ))}
              </ExpandBox>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default AccountingRules;
