// Library
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

// Style
import './globalSearchBarMini.scss';

// Components
import SiteLink from '../siteLink/SiteLink';

// Assets
import historyIcon from '../../image/icons/historyIcon.svg';
import searchGreyIcon from '../../image/icons/search_grey.svg';

// Helper
import { findSiteIdWithoutType } from '../../helpers/WaterDataApiHelper.jsx';

// Constant
const container = 'global-search-bar-mini';

const GlobalSearchBarMini = ({
  placeholder = 'Find gauge or monitoring bore',
  stations,
  setFilteredStations,
}) => {
  const history = useHistory();

  // State
  const [recommended, setRecommended] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [openRecommend, setOpenRecommend] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    //global search site
    const controller = new AbortController();
    const signal = controller.signal;
    if (searchTerm && searchTerm.length > 2 && isEmpty(stations)) {
      setMessage('Loading...');
      (async () => {
        let data = await findSiteIdWithoutType(searchTerm, signal);
        setRecommended(data);
        setOpenRecommend(true);
        if (isEmpty(data)) {
          setMessage('Your search returned no results');
        } else {
          setMessage('');
        }
      })();
    }

    //search stations
    if (searchTerm && stations) {
      const filteredStations = stations.filter(station => {
        let lowerStationName = station.station_name.toLowerCase();
        let lowerSearchTerm = searchTerm.toLowerCase();
        return (
          lowerStationName.includes(lowerSearchTerm) || station.station_id.includes(searchTerm)
        );
      });
      setFilteredStations(filteredStations);
    }

    return () => {
      controller.abort();
      setMessage('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, stations]);

  return (
    <div className={container}>
      <input
        id={`${container}-input`}
        className={`${container}-input`}
        placeholder={placeholder}
        value={searchTerm}
        autoComplete="off"
        onChange={event => {
          setRecommended([]);
          setSearchTerm(event.target.value);
          // setOpenRecommend(type === 'global');
        }}
      />
      {searchTerm && searchTerm.length > 2 && (
        <div className={`${container}-suggestion${!isEmpty(stations) ? '-hide' : ''}`}>
          <div className={`${container}-suggestion-msg`}>
            {isEmpty(recommended) && isEmpty(stations) && message}
          </div>
          {openRecommend &&
            !isEmpty(recommended) &&
            recommended.map((suggestion, index) => {
              return !stations || !isEmpty(stations) ? (
                <SiteLink key={index} site={suggestion} />
              ) : (
                <div
                  key={suggestion.content}
                  className={`${container}-suggestion-item`}
                  onClick={() => {
                    setSearchTerm(suggestion.content);
                    setOpenRecommend(false);
                    history.push('/search');
                  }}
                >
                  <img
                    alt="suggestion icon"
                    className={`${container}-suggestion-icon`}
                    src={suggestion.type === 'history' ? historyIcon : searchGreyIcon}
                  />
                  <div
                    className={`${container}-suggestion-text`}
                    style={{
                      fontWeight: suggestion.type === 'history' ? 'bold' : 'normal',
                    }}
                  >
                    {suggestion.content}
                  </div>
                  {suggestion.type === 'history' && (
                    <div className={`${container}-suggestion-remove`}>Remove</div>
                  )}
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default GlobalSearchBarMini;

GlobalSearchBarMini.propTypes = {
  placeholder: PropTypes.string,
  items: PropTypes.array,
  setFilteredItems: PropTypes.func,
  setOpenFilter: PropTypes.func,
  localSearchTerm: PropTypes.string,
  type: PropTypes.string,
};
