// Libraries
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

// Styles
import './mobileMapLegend.scss';

// Components
import MapLegendLineItem from './MapLegendLineItem';
import DoubleArrowSVG from '../../image/icons/DoubleArrowSVG';
// Constant
const container = 'mobile-map-legend';

const MobileMapLegend = ({ legends }) => {
  const [hideLegends, setHideLegends] = useState(
    document.getElementById('map-container') &&
      document.getElementById('map-container').style.height === '35vh',
  );
  const [hideLeft, setHideLeft] = useState(true);
  const [hideRight, setHideRight] = useState(false);
  const [shownLegendsIndex, setShownLegendsIndex] = useState(0);
  const [shownLegends, setShownLegends] = useState([]);

  // Life Cycle
  useEffect(() => {
    window.addEventListener('click', screenOnClick);
    if (!isEmpty(legends)) {
      setShownLegends(legends.slice(shownLegendsIndex, shownLegendsIndex + 2));
    }
    return () => {
      window.removeEventListener('click', screenOnClick);
    };
  }, [shownLegendsIndex, legends]);

  // Helper
  const arrowOnClick = direction => {
    setShownLegendsIndex(prevState => boundryDetection(prevState, direction));
  };

  const screenOnClick = () => {
    const result = document.getElementById('map-container').style.height === '35vh';
    setHideLegends(result);
  };

  const boundryDetection = (prevValue, direction) => {
    const destination = prevValue + direction;
    setHideLeft(false);
    setHideRight(false);
    if (destination <= 0) {
      setHideLeft(true);
      return 0;
    } else if (destination > legends.length - 3) {
      setHideRight(true);
      return destination > legends.length - 1 ? prevValue : destination;
    } else {
      return destination;
    }
  };

  return (
    <div className={`${container}`}>
      {!hideLegends && (
        <div className={`map-legend`}>
          <div onClick={() => arrowOnClick(-2)}>
            <DoubleArrowSVG
              customArrowClass={`${container}-arrow-left ${hideLeft && `${container}-arrow-hide`}`}
            />
          </div>
          {!isEmpty(shownLegends) &&
            shownLegends.map((legend, index) => (
              <MapLegendLineItem
                key={index}
                strokeColor={legend.strokeColor}
                strokeWidth={legend.strokeWidth ? legend.strokeWidth : '7'}
                legendText={legend.text}
              />
            ))}
          <div onClick={() => arrowOnClick(2)}>
            <DoubleArrowSVG
              customArrowClass={`${container}-arrow-right ${
                hideRight && `${container}-arrow-hide`
              }`}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileMapLegend;

MobileMapLegend.propTypes = {
  legends: PropTypes.array,
};
