// Library
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// Style
import './feedbackFormItem.scss';

// Context
import { MobileContext } from '../../contexts/MobileContext';

// Component
import GeneralDropdown from '../dropDown/GeneralDropdown';

// Constant
import { FEEDBACK_TYPES } from './FeedbackForm';
const container = 'feedback-form-item';

const FeedbackFormItem = ({ label, type, field, setField }) => {
  const { isMobile } = useContext(MobileContext);

  const onTypeChange = item => {
    setField({ value: { type: item.type, name: item.name }, error: false });
  };
  return (
    <div className={`${container}`}>
      <label className={`${container}-label`}> {label} </label>
      {type === 'textarea' ? (
        <textarea
          className={`${container}-input ${container}-textarea ${
            field.error && `${container}-input-error`
          }`}
          rows={isMobile ? 3 : 7}
          value={field.value}
          onChange={e => {
            setField({ value: e.target.value });
          }}
        />
      ) : type === 'dropdown' ? (
        <div className={`${container}-dropdown`}>
          <GeneralDropdown
            menuItem={FEEDBACK_TYPES}
            onItemClick={(item, event) => {
              onTypeChange(item);
              event.preventDefault();
            }}
            selectedItem={{ id: field.value.type, name: field.value.name }}
          />
        </div>
      ) : (
        <input
          type={type}
          className={`${container}-input ${field.error && `${container}-input-error`}`}
          value={field.value}
          onChange={e => {
            setField({ value: e.target.value, error: false });
          }}
        />
      )}
      {field.error && <div className={`${container}-error`}>{field.errorMsg}</div>}
    </div>
  );
};

export default FeedbackFormItem;

FeedbackFormItem.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  field: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    error: PropTypes.bool,
    errorMsg: PropTypes.string,
  }),
  setField: PropTypes.func,
};
