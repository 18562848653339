import React from 'react';
import './DictionaryModalContent.scss';

// Helpers
import { createMarkup } from '../../helpers/Utils';

export default function DictionaryModalContent(props) {
  // Components
  const modalMaker = (name, text) => {
    return <div dangerouslySetInnerHTML={createMarkup(text)} className={name} />;
  };

  return (
    <>
      {props.data.map((content, i) => (
        <div key={i}>
          {content.heading && modalMaker('heading', content.heading)}
          {content.desc && modalMaker('desc', content.desc)}
          {content.subDesc && modalMaker('subDesc', content.subDesc)}
          {content.subList && (
            <div className="subList">
              <ul>
                {content.subList.map((content, index) => (
                  <li dangerouslySetInnerHTML={createMarkup(content.listItem)} key={index} />
                ))}
              </ul>
            </div>
          )}
        </div>
      ))}
    </>
  );
}
