// Libraries
import React, { useContext } from 'react';
import { isEmpty } from 'lodash';

// Styles
import './flowRateMapLegend.scss';

// Context
import { MobileContext } from '../../contexts/MobileContext';

const container = 'flow-rate-map-legend';

export default function FlowRateMapLegend({ threshold }) {
  let PROPS = [{ className: 'flow-rate-1', label: '0' }];
  if (!isEmpty(threshold)) {
    threshold.map((item, index) => {
      PROPS.push({ className: `flow-rate-${index + 2}`, label: item.toLocaleString() });
    });
  }
  const LEGENDLABEL = 'Flow Rate';
  const { isLarge } = useContext(MobileContext);

  return (
    <>
      {!isLarge && PROPS && (
        <div className={`${container}`}>
          {LEGENDLABEL}
          <div className={`${container}-scale`}>
            <div className={`${container}-scale-right`}>
              {PROPS.map((prop, index) => (
                <div key={index} className={`${container}-scale-right-rectangle`}>
                  <div className={`${container}-scale-right-${prop.className}`}>
                    <div className={`${container}-scale-right-${prop.className}-color`} />
                  </div>
                  <hr />
                </div>
              ))}
            </div>
            <div className={`${container}-scale-left`}>
              {PROPS.map((prop, index) => (
                <div key={index} className={`${container}-scale-left-label`}>
                  {prop.label}
                </div>
              ))}
            </div>
            <div className={`${container}-scale-label`}>{`${LEGENDLABEL} (ML/d)`}</div>
          </div>
        </div>
      )}
    </>
  );
}
