// Libraries
import React, { useState, useContext, useLayoutEffect } from 'react';
import { isEmpty } from 'lodash';

// Styles
import './body.scss';
import '../grid.scss';

// Context
import { AppContext } from '../../contexts/AppContext';
import { MapContext } from '../../contexts/MapContext';

// Components
import BodyContainer from './BodyContainer';
import RiverDataSummary from '../../components/riverData/RiverDataSummary';
import InfoIconAndModal from '../../components/informationButton/InfoIconAndModal';
import DataRefresh from '../../components/dataSummary/DataRefresh';
import ToggleButton from '../../components/dataSummary/ToggleButton';
import RiverSchematic from '../../components/riverSchematic/RiverSchematic';
import GeneralDropdown from '../../components/dropDown/GeneralDropdown';
import RiverLateralHydrograph from '../../components/riverLateralHydrograph/RiverLateralHydrograph';
import Loader from '../../components/loader/Loader';
import DataQualityLegend from '../../components/generalComponents/dataQuality/DataQualityLegend';

// Assets
import introText from '../../abstract/IntroText.json';
import riverModal from '../../components/modal/modalText/RiverAquiferTitleModalText.json';
import schematics from '../../components/riverSchematic/schematics/index';

// Helpers
import { findLastWord } from '../../helpers/Utils';

import {
  GetBoresData,
  GetGaugesList,
  GetWeirsList,
  GetDamsList,
  GetGaugesHydrometrics,
  GetWeirsHydrometrics,
  GetDamsHydrometrics,
} from '../../helpers/ReactQueryHelper';

// Constant
import constants from '../../constants/Constants';
import WaterSourceConstants from '../../constants/WaterSourceConstants';
import config from '../../configs/featureToggleConfig.json';
import mapFilterConstant from '../../constants/MapFilterConstants';
import RiverDataReportTable from '../../components/riverDataReportTable/RiverDataReportTable';
const popupItems = mapFilterConstant.MAP_POPUP_ITEMS;
const { GAUGE, BORE } = popupItems;
const TEXT = introText.riverData;
const [SYSTEM, LIST, VOLUME, TABLE] = ['SYSTEM', 'LIST', 'VOLUMETRIC', 'TABLE'];
const TOGGLE_OPTIONS = [
  { id: 'LATEST DAILY', name: 'LATEST DAILY' },
  { id: 'LAST 30 DAYS', name: 'LAST 30 DAYS' },
  { id: 'LAST 365 DAYS', name: 'LAST 365 DAYS' },
];
const DURATIONS = [
  { name: TOGGLE_OPTIONS[0].name, value: 'latest' },
  { name: TOGGLE_OPTIONS[1].name, value: 30 },
  { name: TOGGLE_OPTIONS[2].name, value: 365 },
];
const container = 'river-aquifer-container';
const VARIABLE_MAPPING = {
  gauge: {
    [SYSTEM]: 'FlowRate',
    [LIST]: 'FlowRate,StreamWaterLevel,DissolvedOxygen,StorageWaterLevel',
    [TABLE]: 'FlowRate,StreamWaterLevel,StorageWaterLevel',
  },
  dam: { [SYSTEM]: 'ActiveStorageVolume' },
  weir: { [SYSTEM]: 'TotalStorageVolume' },
};
const getDataFromWaterDataApi = config['water-data-api'].active;

export default function RiverOrAquiferData() {
  const { hash } = window.location;

  // Context
  const { waterSource } = useContext(AppContext);
  const { mapExpanded } = useContext(MapContext);
  const { water_source_id, water_source_type } = waterSource;

  // State
  const [view, setView] = useState('');
  const [duration, setDuration] = useState(
    DURATIONS.find(item => item.name === TOGGLE_OPTIONS[0].name).value,
  );

  // Constant
  const { regulated, unregulated, groundwater } = constants.WATER_SOURCE_TYPE;
  const { modal } = riverModal;
  const schematicsAvailableWS = Object.keys(schematics);
  const isSchematicAvailable = schematicsAvailableWS.includes(water_source_id?.toString());
  const isVolumetricAvailale = water_source_id === WaterSourceConstants.BARWON_DARLING_WSID; //||water_source_id === WaterSourceConstants.LOWER_MACQUIARIE_WSID;
  const DROPDOWN_OPTIONS = [
    { id: LIST, name: LIST },
    ...(isSchematicAvailable ? [{ id: SYSTEM, name: SYSTEM }] : []),
    ...(isSchematicAvailable ? [{ id: TABLE, name: TABLE }] : []),
    ...(isVolumetricAvailale ? [{ id: VOLUME, name: VOLUME }] : []),
  ];
  const validViews = [SYSTEM, LIST, VOLUME, TABLE];

  const isRegulatedOrUnregulated =
    water_source_type?.includes(regulated) || water_source_type?.includes(unregulated);

  const basicBoresData = GetBoresData({
    waterSourceId: water_source_id,
    enabled: water_source_type?.includes(groundwater) && view !== '',
  });

  const basicGaugesData = GetGaugesList({
    waterSourceId: water_source_id,
    enabled: isRegulatedOrUnregulated && view !== '',
  });

  const basicWeirsData = GetWeirsList({
    waterSourceId: water_source_id,
    enabled: isRegulatedOrUnregulated && view === SYSTEM,
  });

  const basicDamsData = GetDamsList({
    waterSourceId: water_source_id,
    enabled: isRegulatedOrUnregulated && view === SYSTEM,
  });

  const gaugesData = GetGaugesHydrometrics({
    gaugesList: basicGaugesData.isSuccess ? basicGaugesData.data : [],
    enabled: basicGaugesData.isSuccess && view !== VOLUME,
  });

  const weirsData = GetWeirsHydrometrics({
    weirsList: basicWeirsData.isSuccess ? basicWeirsData.data : [],
    view: view,
    enabled: basicWeirsData.isSuccess,
  });

  const damsData = GetDamsHydrometrics({
    damsList: basicDamsData.isSuccess ? basicDamsData.data : [],
    view: view,
    enabled: basicDamsData.isSuccess,
  });

  useLayoutEffect(() => {
    if (isEmpty(water_source_type)) {
      return;
    }
    const hashValue = hash ? hash.replace('#', '').replace('-', ' ').toUpperCase() : null;
    setView(validViews.includes(hashValue) ? hashValue : LIST);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash]);

  const refetchData = () => {
    if (view === LIST || view === TABLE) {
      basicGaugesData.remove();
      gaugesData.remove();
      basicGaugesData.refetch();
      gaugesData.refetch();
    } else {
      basicGaugesData.remove();
      basicDamsData.remove();
      basicWeirsData.remove();
      gaugesData.remove();
      damsData.remove();
      weirsData.remove();
      basicGaugesData.refetch();
      gaugesData.refetch();
      basicDamsData.refetch();
      damsData.refetch();
      basicWeirsData.refetch();
      weirsData.refetch();
    }
  };

  const showSelectedView = (view, isSchematic) => {
    switch (view) {
      case LIST:
        return listView();
      case SYSTEM:
        return isSchematic ? systemView() : listView();
      case TABLE:
        return tableView();
      case VOLUME:
        return volumentricView();
      default:
        return listView();
    }
  };

  const isBasicsLoading = view => {
    if (getDataFromWaterDataApi) {
      if (view === LIST || view === TABLE) {
        return isRegulatedOrUnregulated ? basicGaugesData.isLoading : basicBoresData.isLoading;
      }
      return basicGaugesData.isLoading || basicDamsData.isLoading || basicWeirsData.isLoading;
    }
  };

  const isDetailsLoading = view => {
    if (getDataFromWaterDataApi) {
      if (view === LIST) {
        return gaugesData.isLoading;
      } else {
        return damsData.isLoading || weirsData.isLoading || gaugesData.isLoading;
      }
    } else {
      return basicGaugesData.isLoading || basicDamsData.isLoading || basicWeirsData.isLoading;
    }
  };

  const getPageTitle = () => (
    <div className={`${container}-pageTop`}>
      <span>
        {`${TEXT.title} ${findLastWord(waterSource.water_source_name).notLast}`}
        &nbsp;
        <InfoIconAndModal
          modalId="riverTitleModal"
          modalContent={modal}
          lastWord={
            <span className={'page-body-title'}>
              {findLastWord(waterSource.water_source_name).last}
              &nbsp;
            </span>
          }
        />
      </span>
    </div>
  );

  // Mini Component
  const menuLeft = menuOptions => {
    if (view) {
      const selected = { id: view, name: view };
      return (
        <div className={`${container}-header-left`}>
          <div className={`${container}-header-left-title`}>Select view type:</div>
          <ToggleButton
            options={menuOptions.map(item => item.id)}
            onClick={option => {
              setView(option);
              window.location.hash = `#${option.toLowerCase().replace(' ', '-')}`;
            }}
            activeDefault={selected.id}
          />
        </div>
      );
    }
  };

  const menuRight = () => {
    return (
      <div className={`${container}-header-right`}>
        <GeneralDropdown
          menuItem={TOGGLE_OPTIONS}
          onItemClick={option =>
            setDuration(DURATIONS.find(item => item.name === option.name).value)
          }
          selectedItem={TOGGLE_OPTIONS[0]}
        />
      </div>
    );
  };

  const listView = () => {
    return (
      <>
        {isBasicsLoading(view) ? (
          <Loader staticPosition />
        ) : (
          !isEmpty(basicGaugesData.data) && (
            <>
              <div className="roa-station-title"> RIVER GAUGES </div>
              <RiverDataSummary
                stations={
                  getDataFromWaterDataApi
                    ? isEmpty(gaugesData.data)
                      ? basicGaugesData.data
                      : gaugesData.data
                    : basicGaugesData.data
                }
                type={GAUGE.name}
                detailsLoading={isDetailsLoading(view)}
              />
            </>
          )
        )}
        {!isEmpty(basicBoresData.data) && (
          <>
            <div className="roa-station-title">GROUNDWATER BORES </div>
            <RiverDataSummary stations={basicBoresData.data} type={BORE.name} />
          </>
        )}
      </>
    );
  };

  const systemView = () => {
    return (
      <>
        {isBasicsLoading(view) ? (
          <Loader staticPosition />
        ) : (
          <RiverSchematic
            gauges={
              getDataFromWaterDataApi && !isEmpty(gaugesData.data)
                ? gaugesData.data
                : basicGaugesData.data
            }
            dams={!isEmpty(damsData.data) ? damsData.data : basicDamsData.data}
            weirs={
              getDataFromWaterDataApi && !isEmpty(weirsData.data)
                ? weirsData.data
                : basicWeirsData.data
            }
            detailsLoading={isDetailsLoading(view)}
          />
        )}
      </>
    );
  };

  const tableView = () => {
    return isBasicsLoading(view) ? (
      <Loader staticPosition />
    ) : (
      <RiverDataReportTable
        gauges={basicGaugesData.data}
        hydrometrics={VARIABLE_MAPPING[GAUGE.name][view]}
      />
    );
  };

  const volumentricView = () => {
    return <RiverLateralHydrograph duration={duration} />;
  };

  return (
    <BodyContainer
      pageTitle={getPageTitle()}
      menuLeft={isSchematicAvailable || isVolumetricAvailale ? menuLeft(DROPDOWN_OPTIONS) : null}
      menuRight={view === VOLUME ? menuRight() : null}
      headerRight={
        <>
          {view !== VOLUME && <DataRefresh forceUpdate={view => refetchData(view)} />}
          {(view === LIST || view === TABLE) && !mapExpanded && (
            <div className={`${container}-header-right-legend-container`}>
              <DataQualityLegend />
            </div>
          )}
        </>
      }
    >
      {showSelectedView(view, isSchematicAvailable)}
    </BodyContainer>
  );
}
