// Library
import React, { useState, useContext, useEffect } from 'react';

// Styles
import './graphAndDownloadSubmitBtn.scss';

// Context
import { UserContext } from '../../../../../../contexts/UserContext';
import { DownloadContext } from '../../../../../../contexts/DownloadContext';
// Component
import { Button } from '../../../../../generalComponents/button/Button';
import Modal from '../../../../../modal/Modal';
import RequestModal, { API_STATUS } from '../../../../../modal/RequestModal.jsx';
import LoginButton from '../../../../../login/LoginButton';

// Helper
import { determinBtnType, onGetBtnClick, onRequestBtnClick } from './GraphAndDownloadHelper';

// Constant
import constant from '../../DataDownloadConstants';
const component = 'graph-and-download-submit-btn';

// Mini Component
const ModalFooter = () => {
  return (
    <div className={`download-filters-footer`}>
      <LoginButton customStyle={'download-filters-signin'} checkLogingStatus={false} />
      <div data-dismiss={'modal'} className={'download-filters-cancel'}>
        Cancel
      </div>
    </div>
  );
};

const GraphAndDownloadSubmitBtn = () => {
  const {
    selectedStations,
    hydrometric,
    dataType,
    interval,
    startDate,
    endDate,
    setGenGraph,
    autoFetch,
  } = useContext(DownloadContext);
  const { user, isLoggedIn } = useContext(UserContext);
  const [apiStatus, setApiStatus] = useState(API_STATUS.LOAD);
  const btnType = determinBtnType(
    selectedStations,
    hydrometric,
    dataType,
    interval,
    startDate,
    endDate,
  );
  const isLoginWarning = !isLoggedIn && btnType === constant.BUTTONS.request;
  const isDisabled = btnType.type === constant.BUTTONS.disabled.type;
  const btnStyles = isDisabled ? `${component}-disabled` : `${component}`;
  const modalId = isLoginWarning ? `hdd-warning-modal` : `hdd-submit-modal`;
  const btnClickHandler =
    btnType.type === constant.BUTTONS.request.type
      ? () => {
          onRequestBtnClick(
            selectedStations,
            hydrometric,
            dataType,
            interval,
            startDate,
            endDate,
            setApiStatus,
            user,
          );
        }
      : btnType.type === constant.BUTTONS.get.type
      ? () => {
          onGetBtnClick(setGenGraph);
        }
      : () => {};

  useEffect(() => {
    if (btnType.type === constant.BUTTONS.get.type && autoFetch) {
      btnClickHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [btnType, autoFetch]);

  return (
    <>
      {isLoginWarning && (
        <Modal
          id={`hdd-warning-modal`}
          title="Limits exceeded" // may be need to use constants
          body="You have requested too much data. You can either cancel and change your selection, or sign in to request this data and have it mailed to you. Changing your selection should include reducing date range, frequency and number of sites."
          footer={<ModalFooter />}
          containerClassName={`hdd-warning-modal`}
          footerClassName={'download-filters'}
        />
      )}
      {btnType === constant.BUTTONS.request && (
        <RequestModal id={`hdd-submit-modal`} feedbackStatus={apiStatus} />
      )}
      <RequestModal id={modalId} feedbackStatus={apiStatus} />
      <Button
        customStyles={btnStyles}
        onClick={btnClickHandler}
        targetModalId={btnType === constant.BUTTONS.request && modalId}
        isDisabled={isDisabled}
      >
        {btnType.displayName}
      </Button>
    </>
  );
};

export default GraphAndDownloadSubmitBtn;
