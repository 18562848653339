// Library
import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { isEmpty } from 'lodash';

// Styles
import './ratingsCurveGraphItem.scss';

// Component
import ChartResponsiveContainer from '../../../../chartProperties/ChartResponsiveContainer';

// Helpers
import { formatNumberDecimals } from '../../../../../helpers/Utils';

// Constant
import constants from '../DataDownloadConstants';
const component = 'ratings-curve-graph-item';

const RatingsCurveGraphItem = ({ ratingData }) => {
  if (isEmpty(ratingData) || isEmpty(ratingData.data)) return <></>;

  let xDomain, xTicks, yDomain, yTicks, slicedData;
  const minDataInd = ratingData.data.findIndex(item => item['Value1'] > 0 && item['Level'] > 0);
  if (minDataInd !== -1) {
    const minData = ratingData.data[minDataInd];
    const maxData = ratingData.data[ratingData.data.length - 1];

    const findDomain = dataKey => {
      return [
        Math.pow(10, Math.floor(Math.log10(minData[dataKey]))),
        Math.pow(10, Math.ceil(Math.log10(maxData[dataKey]))),
      ];
    };

    const genTicks = domain => {
      const min = domain[0];
      const max = domain[1];
      let ticks = [];
      if (min > 0 && max > 0) {
        for (let i = Math.log10(min); i <= Math.log10(max); i++) {
          ticks.push(Math.pow(10, i));
        }
      } else {
        ticks.push(0);
      }

      return ticks;
    };

    xDomain = findDomain('Value1');
    xTicks = genTicks(xDomain);
    yDomain = findDomain('Level');
    yTicks = genTicks(yDomain);
    slicedData = ratingData.data.slice(minDataInd);
  }

  return minDataInd !== -1 ? (
    <>
      <ChartResponsiveContainer customHeight={{ desktop: 300, mobile: 400 }}>
        <LineChart
          width={850}
          data={slicedData}
          margin={{ top: 10, right: 10, bottom: 50, left: 15 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="Value1"
            tick={{ fill: 'black', dy: 5 }}
            label={{
              value: `Discharge (ML/d)`,
              angle: 0,
              dy: 25,
            }}
            tickFormatter={v => v}
            type="number"
            domain={xDomain}
            ticks={xTicks}
            scale="log"
          />
          <YAxis
            label={{
              value: `Level (Metres)`,
              angle: -90,
              dx: -35,
            }}
            tick={{ fill: 'black', dx: -5 }}
            tickFormatter={v => formatNumberDecimals(v)}
            type="number"
            scale="log"
            domain={yDomain}
            ticks={yTicks}
          />
          <Tooltip
            labelFormatter={v => v}
            filterNull={true}
            formatter={(value, name) => {
              return [value, name];
            }}
          />
          <Line
            type="monotone"
            strokeWidth={2}
            dataKey={'Level'}
            stroke={constants.GRAPH_COLOR_CODES[0]}
            dot={false}
          />
        </LineChart>
      </ChartResponsiveContainer>
    </>
  ) : (
    <div className={`${component}-msg`}>Data is not available for selected site.</div>
  );
};

export default RatingsCurveGraphItem;
