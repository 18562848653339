// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './resultContent.scss';

// Constant
const container = 'result-content';

export default function ResultContent({ children }) {
  return <div className={`${container}`}>{children}</div>;
}

ResultContent.propTypes = {
  children: PropTypes.any,
};
