import React from 'react';
import PropTypes from 'prop-types';

const DotSvg = ({ fillColor }) => {
  return (
    <svg viewBox="0 0 8 8" width="8" height="8" xmlns="http://www.w3.org/2000/svg">
      <circle cx="4" cy="4" r="4" fill={fillColor} />
    </svg>
  );
};

export default DotSvg;

/* Props */
DotSvg.propTypes = {
  /**
   * This change the fill color
   */
  fillColor: PropTypes.string,
};
