// Library
import moment from 'moment';
import { isEmpty, isNil } from 'lodash';

// Constants
import constants from '../../constants/Constants.jsx';
import apiConstants from '../../constants/WaterDataAPIConstants';

// Helpers
import {
  getSurfaceWaterData,
  getFlowData,
  aggregateDailyintoMonthly,
} from '../../helpers/WaterDataApiHelper';

/**
 *
 * @param {*} data
 */
export const generateCumulativeInflowStructure = (data, activeDams) => {
  const result = Object.keys(data)
    .filter(key => key === activeDams.join())
    .map(key => {
      return data[key].map(date => ({
        date: date.date,
        total: date.total,
        [key]: date.inflow,
        dams: [
          {
            dam_id: key,
            dam_name: 'Inflow',
            inflow: date.inflow,
          },
        ],
      }));
    })[0];
  return result;
};

export const dateFormatter = (dataObj, inputDateFormat, outputDateFormat) => {
  if (!dataObj?.date) return 'Updated: -';
  return `Updated: ${moment(dataObj?.date, inputDateFormat).format(outputDateFormat)}`;
};

export const formatDailyUpdate = (data, station, isGreaterSydney, isTotal = false) => {
  let dataProps = [
    { key: 'volume_perc', label: 'pct full', rounded: false, singleDecimal: true },
    {
      key: 'volume',
      label: `${
        constants.TOTAL_STORAGE_MENINDEE_LAKES_FLAG &&
        station &&
        Number(station.station_id) === constants.MENINDEE_LAKES_TOTAL_ID
          ? 'Total volume'
          : 'volume'
      }`,
      rounded: false,
      singleDecimal: false,
    },
  ];

  if (!isTotal) {
    dataProps = dataProps.concat([
      { key: 'inflow', label: 'inflow', rounded: false, singleDecimal: false },
      ...(isGreaterSydney
        ? [{ key: 'eflow', label: 'env release', rounded: false, singleDecimal: false }]
        : []),
      ...(!isGreaterSydney
        ? [{ key: 'release', label: 'release', rounded: false, singleDecimal: false }]
        : []),
    ]);
  }
  let updateItems = dataProps.map(item => {
    const dataItem = data ? data[item.key] : { value: '-', isLatest: false, unit: '' };
    if (dataItem) {
      return {
        label: item.label,
        value: dataItem.value,
        isLatest: dataItem.isLatest,
        unit: dataItem.unit,
        rounded: item.rounded,
        singleDecimal: item.singleDecimal,
      };
    }
  });
  updateItems = updateItems.filter(item => typeof item !== 'undefined');
  return updateItems;
};

export const fetchFlowData = async (dams, startDate, endDate, flowType, isDaily = false) => {
  const dataProp = {
    inflow: { valueKey: 'totalCatchmentInflow', variableName: apiConstants.API_FIELDS.Inflow },
    eflow: { valueKey: 'actualReleaseMade', variableName: apiConstants.API_FIELDS.Release },
  };
  const flowData = [];
  const requests = dams.map(async dam => {
    const res = await getFlowData(dam.station_name, 'Instantaneous', startDate, endDate, flowType);
    const formatted = res
      ? res.map(dataItem => {
          return {
            timeStamp: dataItem.timeStamp,
            siteId: dam.station_id,
            unitOfMeasure: 'ML',
            value: dataItem[dataProp[flowType].valueKey],
            variableName: dataProp[flowType].variableName,
          };
        })
      : [];
    return isDaily ? formatted : aggregateDailyintoMonthly(formatted);
  });
  const inflowData = await Promise.all(requests);
  flowData.push(...inflowData);
  return flowData;
};

export const blendFlowData = (flowData, data, stationId) => {
  let result = data;
  if (flowData) {
    const targetFlowData = flowData.find(flowItem => {
      return !isEmpty(flowItem) && flowItem[0].siteId === stationId;
    });
    if (!isNil(targetFlowData)) result.push(...targetFlowData);
  }
  return result;
};

export const fetchChartDamStorageData = async (
  dams,
  interval,
  startDate,
  endDate,
  variables,
  dataType,
  flowData = false,
) => {
  const damIds = !isEmpty(dams) ? dams.map(item => item.station_id) : [];
  let chartDataRes = await getSurfaceWaterData(
    damIds.join(','),
    interval,
    startDate,
    endDate,
    variables,
    dataType,
  );
  let inflowData, eflowData;
  if (flowData) {
    inflowData = await fetchFlowData(dams || [], startDate, endDate, 'inflow');
    eflowData = await fetchFlowData(dams || [], startDate, endDate, 'eflow');
  }
  dams.map(damItem => {
    const damId = damItem.station_id;
    // Blend in flow data
    chartDataRes = blendFlowData(inflowData, chartDataRes, damId);
    chartDataRes = blendFlowData(eflowData, chartDataRes, damId);
  });
  return chartDataRes;
};
