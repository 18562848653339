import moment from 'moment';
import { isEmpty } from 'lodash';

export const getFiscalYear = date => {
  if (!isEmpty(date)) {
    let fiscalYear = getFiscalYearDate(date);
    return `July 
      ${fiscalYear[0]} - June ${fiscalYear[1]}`;
  }
};

export const getFiscalYearDate = date => {
  return [
    date.month() < 6 ? date.year() - 1 : date.year(),
    date.month() < 6 ? date.year() : date.year() + 1,
  ];
};

export const currentFinancialYear = () => {
  return moment().quarter() < 3 ? moment().year() : moment().add(1, 'years').year();
};

export const startDateOfFinancialYear = theDate => {
  if (!theDate) {
    theDate = moment();
  }
  return moment(theDate).quarter() > 2
    ? moment(theDate).format('YYYY-07-01')
    : moment(theDate).subtract(1, 'years').format('YYYY-07-01');
};
export const endDateOfFinancialYear = theDate => {
  let dateFormat = 'YYYY-06-30';
  if (!theDate) {
    theDate = moment();
  }
  return moment(theDate).quarter() > 2
    ? moment(theDate).add(1, 'years').format(dateFormat)
    : moment(theDate).format(dateFormat);
};

export const formatLabelWaterYear = (year, format) => {
  const firstFormat = isEmpty(format) ? 'YYYY' : format[0];
  const secondFormat = isEmpty(format) ? 'YY' : format[1];
  return (
    moment(year, 'YYYY').subtract(1, 'year').format(firstFormat) +
    '/' +
    moment(year, 'YYYY').format(secondFormat)
  );
};

export const formatDate = (date, formatIn = 'YYYY-MM', formatPOut = 'YYYY-MM-DD') =>
  moment(date, formatIn).format(formatPOut);

export const setTimeOutForPromise = (ms, promise) => {
  const timeout = new Promise(resolve => {
    setTimeout(resolve, ms, { code: 500 });
  });

  return Promise.race([timeout, promise]);
};

export const getCurrentDate = (format = 'YYYY-MM-DD') => moment().format(format);

export const genListOfHistoricalYears = historicalData => {
  let yearList = [];

  if (!isEmpty(historicalData)) {
    let thisYear = currentFinancialYear();
    let maxOffset = thisYear - 10;
    let filteredHistoricalData = historicalData.filter(
      element => element.water_year > maxOffset && Number(element.water_year) !== thisYear,
    );

    filteredHistoricalData.forEach(element => {
      var yearLabel = formatLabelWaterYear(Number(element.water_year));
      var yearValue = Number(element.water_year) - 1 + ':' + element.water_year;

      yearList.push({
        label: yearLabel,
        value: yearValue,
        year: element.water_year,
      });
    });
  }

  return yearList;
};

export const getDatesByPeriod = (period, format = 'YYYY-MM-DD', unit = 'days') =>
  moment().add(unit, period).format(format);

export const getMonthListByRange = (range, dateFrom, format, unit, inputFormat) => {
  const dateFormat = unit === 'month' ? 'YYYY-MM-01' : format;
  let date = moment(dateFrom, inputFormat).format(dateFormat);
  const results = [];
  for (let i = range; i > 0; i--) {
    results.push({
      id: date,
      name: unit === 'month' ? formatDate(date, inputFormat, format) : date,
    });
    date = moment(date, dateFormat).subtract(1, unit).format(dateFormat);
  }
  return results.reverse();
};

export const getLastDateOfMonth = (format = 'YYYY-MM-DD') => {
  return moment(getCurrentDate('YYYY-MM-01')).subtract(1, 'days').format(format);
};

export const formatTimeZone = date => {
  return new Date(date).toLocaleString('en-US', {
    timeZone: 'Australia/Sydney',
  });
};

export const getWaterYearList = startYear => {
  const currentYear = getFiscalYearDate(moment())[1];
  const count = currentYear - startYear + 1;
  let yearList = [];
  for (let i = 1; i <= count; i++) {
    yearList.push({
      id: i + '',
      name: startYear + i - 2 + '/' + (startYear + i - 1),
      year: startYear + i - 1,
    });
  }
  return yearList;
};

/**
 * Returns the rounded down timestap on an specified step (roundStep)
 * For day it is rounded to 9:59am
 *
 * @param {string} timestamp The timestamp to be rounded down
 * @param {string} timeStampFormat The timestamp format
 * @param {string} roundStep The step for rounding down
 *
 */
export const roundDownTimeStamp = (
  timeStamp,
  timeStampFormat,
  roundStep,
  timeOutputFormat = '',
) => {
  let result;
  let timeFormat;
  if (!timeStamp) return '';
  switch (roundStep) {
    case 'daily':
      {
        if (moment(timeStamp, timeStampFormat).hour() < 9) {
          result = moment(timeStamp, timeStampFormat).subtract(1, 'days');
          timeFormat = 'DD-MMM-YYYY 09:59';
        } else {
          result = moment(timeStamp, timeStampFormat);
          timeFormat = 'DD-MMM-YYYY HH:mm';
        }
      }
      break;
    case 'hourly':
      {
        result = moment(timeStamp, timeStampFormat);
        timeFormat = 'DD-MMM-YYYY HH:00';
      }
      break;

    case '15-minute':
      {
        const minuteQuarter = Math.floor(Number(moment(timeStamp, timeStampFormat).minute()) / 15);
        result = moment(timeStamp, timeStampFormat);
        timeFormat = `DD-MMM-YYYY HH:${minuteQuarter === 0 ? '00' : minuteQuarter * 15}`;
      }
      break;
    case 'monthly':
      {
        result = moment(timeStamp, timeStampFormat);
        timeFormat = 'DD-MMM-YYYY 00:00';
      }
      break;
    default:
      return timeStamp;
  }
  timeFormat = timeOutputFormat || timeFormat;
  return result.format(timeFormat);
};

export const isLeapYear = year => {
  if (year % 400 === 0) {
    return true;
  } else if (year % 100 === 0) {
    return false;
  } else if (year % 4 === 0) {
    return true;
  } else {
    return false;
  }
};
