// Libraries
import React, { useState } from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

// Styles
import './App.scss';

// Abstract
import ErrorHandler from './abstract/ErrorHandler';
import { PageView } from './helpers/GAHelper';

// Pages
import HomeDashboardRoutes from './pages/home/HomeDashboardRoutes.jsx';
import AllNswUpdates from './pages/update/AllNswUpdates.jsx';
import Learn from './pages/introduction/Learn';
import Snapshot from './pages/snapshot/Snapshot';
import Download from './pages/download/Download';
import GlobalSearch from './pages/globalSearch/GlobalSearch';
import HomeMini from './pages/home/HomeMini';
import FavouritesMini from './pages/favourites/FavouritesMini';
import ManageAlerts from './pages/manageUser/ManageAlerts';
import ManageRequests from './pages/manageUser/ManageRequests';
import ArchivedUpdates from './pages/update/ArchivedUpdates';

// Layouts
import Rules from './layout/body/Rules';
import Outlook from './layout/body/Outlook';
import Losses from './layout/body/Losses.jsx';
import Body from './layout/body/HomeBody.jsx';
import Glossary from './layout/body/Glossary';
import Updates from './layout/body/Updates.jsx';
import Storage from './layout/body/Storage.jsx';
import ErrorBody from './layout/body/ErrorBody';
import Header from './layout/header/Header.jsx';
import Footer from './layout/footer/Footer.jsx';
import Allocation from './layout/body/Allocation';
import FlowClass from './layout/body/FlowClass';
import RiverOrAquiferData from './layout/body/RiverOrAquiferData';
import Feedback from './layout/feedback/Feedback';
import Rainfall from './layout/body/Rainfall';
import Trading from './layout/body/Trading';
import Research from './layout/body/Research';
import Usage from './layout/body/Usage';
import Sites from './layout/body/Sites';
import SiteInfo from './layout/body/SiteInfo';
import Salinity from './layout/body/Salinity';
import PollutionMonitoring from './layout/body/PollutionMonitoring';

// Components
import MaintenanceBanner from './components/generalComponents/banner/MaintenanceBanner';

// Context
import AppContextProvider from './contexts/AppContext';
import MapContextProvider from './contexts/MapContext';
import MobileContextProvider from './contexts/MobileContext';
import TabsContextProvider from './contexts/TabsContext ';
import ExpandBoxContextProvider from './contexts/ExpandBoxContext';
import MapFilterContextProvider from './contexts/MapFilterContext';
import SearchContextProvider from './contexts/SearchContext';
import MiniAppContextProvider from './contexts/MiniAppContext';
import UserContextProvider from './contexts/UserContext';
import StationAlertCtxProvider from './contexts/StationAlertContext';
import DownloadContextProvider from './contexts/DownloadContext';
import ReactQueryProvider from './contexts/reactQuery/ReactQueryProvider';

// Helpers
import { checkPathname, isMiniApp } from './helpers/UrlGenerator.jsx';
import { loadInitialData } from './helpers/ApiHelper.jsx';

import Constants from './constants/Constants';
const { SCREEN_SM } = Constants;
const tagManagerArgs = {
  gtmId: Constants.GTM_TAG,
};
console.log('NODE_ENV:', process.env.NODE_ENV);
console.log('REACT_APP_ENV:', process.env.REACT_APP_ENV);
console.log('REACT_APP_ENV:', process.env.TESTING);

ReactGA.initialize(Constants.GTAG_ID);

TagManager.initialize(tagManagerArgs);

function App() {
  loadInitialData();
  // if there is a current water source in the localstorage, set it in the local state
  const local = localStorage.getItem('curWaterSource');
  const [curWaterSource] = useState(
    local && local !== 'undefined' ? JSON.parse(localStorage.getItem('curWaterSource')) : undefined,
  );
  const createHistory = require('history').createBrowserHistory;
  const history = createHistory();

  PageView(window.location.pathname + window.location.search);

  checkPathname(history, curWaterSource);

  const renderFeedback = () => {
    if (!isMiniApp()) {
      return <Feedback />;
    }
  };

  const renderMiniSite = (path, child) => {
    if (window.innerWidth < SCREEN_SM) {
      return <Route exact path={path} component={child} />;
    } else {
      if (isMiniApp()) {
        return <Redirect to="/" component={child} />;
      }
    }
  };

  return (
    <div className="app">
      <ReactQueryProvider>
        <AppContextProvider>
          <MapContextProvider>
            <ExpandBoxContextProvider>
              <MobileContextProvider>
                <TabsContextProvider>
                  <MapFilterContextProvider>
                    <SearchContextProvider>
                      <MiniAppContextProvider>
                        <UserContextProvider>
                          <StationAlertCtxProvider>
                            <DownloadContextProvider>
                              <Router history={history}>
                                <MaintenanceBanner />
                                <Header />
                                <ErrorHandler>
                                  {renderFeedback()}
                                  <Switch>
                                    <HomeDashboardRoutes exact path={`/`} component={Body} />
                                    {/* routes starts from '/m' means light-version */}
                                    {renderMiniSite('/m', HomeMini)}
                                    <Route path="/m/favourites" component={FavouritesMini} />
                                    <Route path="/m/sites" component={Sites} />
                                    <Route exact path={'/m/site/:id'} component={SiteInfo} />
                                    <Route path="/nsw-update" component={AllNswUpdates} />
                                    <Route path="/archived-updates" component={ArchivedUpdates} />
                                    <Route
                                      path={['/m/glossary', '/glossary']}
                                      component={Glossary}
                                    />
                                    <Route path="/site-error" component={ErrorBody} />
                                    <Route path="/learn" component={Learn} />
                                    <Route path="/snapshot" component={Snapshot} />
                                    <Route path="/download" component={Download} />
                                    <Route
                                      exact
                                      path={['/my-alert', '/m/my-alert']}
                                      component={ManageAlerts}
                                    />
                                    <Route path="/my-request" component={ManageRequests} />
                                    <Route
                                      path={['/m/search', '/search']}
                                      component={GlobalSearch}
                                    />
                                    <HomeDashboardRoutes
                                      exact
                                      path={[`/:id/updates`, `/:id`]}
                                      component={Updates}
                                    />
                                    <HomeDashboardRoutes
                                      exact
                                      path={`/:id/storage`}
                                      component={Storage}
                                    />
                                    <HomeDashboardRoutes
                                      exact
                                      path={`/:id/outlook`}
                                      component={Outlook}
                                    />
                                    <HomeDashboardRoutes
                                      exact
                                      path={`/:id/allocation`}
                                      component={Allocation}
                                    />
                                    <HomeDashboardRoutes
                                      exact
                                      path={`/:id/rules`}
                                      component={Rules}
                                    />
                                    <HomeDashboardRoutes
                                      exact
                                      path={`/:id/losses`}
                                      component={Losses}
                                    />
                                    <HomeDashboardRoutes
                                      exact
                                      path={`/:id/flow-class`}
                                      component={FlowClass}
                                    />
                                    <HomeDashboardRoutes
                                      exact
                                      path={`/:id/river-data`}
                                      component={RiverOrAquiferData}
                                    />
                                    <HomeDashboardRoutes
                                      exact
                                      path={`/:id/rainfall`}
                                      component={Rainfall}
                                    />
                                    <HomeDashboardRoutes
                                      exact
                                      path={`/:id/research`}
                                      component={Research}
                                    />
                                    <HomeDashboardRoutes
                                      exact
                                      path={`/:id/hrsts`}
                                      component={Salinity}
                                    />
                                    <HomeDashboardRoutes
                                      exact
                                      path={`/:id/trading`}
                                      component={Trading}
                                    />
                                    <HomeDashboardRoutes
                                      exact
                                      path={`/:id/usage`}
                                      component={Usage}
                                    />
                                    <HomeDashboardRoutes
                                      exact
                                      path={`/:id/pollution-monitoring`}
                                      component={PollutionMonitoring}
                                    />
                                  </Switch>
                                </ErrorHandler>
                                <Footer />
                              </Router>
                            </DownloadContextProvider>
                          </StationAlertCtxProvider>
                        </UserContextProvider>
                      </MiniAppContextProvider>
                    </SearchContextProvider>
                  </MapFilterContextProvider>
                </TabsContextProvider>
              </MobileContextProvider>
            </ExpandBoxContextProvider>
          </MapContextProvider>
        </AppContextProvider>
      </ReactQueryProvider>
    </div>
  );
}

export default App;
