// Libraries
import React from 'react';
import data from './data.json';
import { updateDisplayById } from '../../../helpers/Utils';

// Styles
import '../../modal/Modal.scss';
import './errorModal.scss';

export default function ErrorModal(props) {
  const onCloseButtonClicked = () => {
    updateDisplayById('map-error-modal', 'none');
  };

  const container = 'error-modal';

  return (
    <div className={`${container}-container`}>
      <div className={`${container}-item`}>
        <div className={`${container}-content`}>
          <div className={`${container}-header`}>
            <div className={`${container}-title`}>{data[props.type].title}</div>
            <button
              type="button"
              className="close"
              onClick={() => onCloseButtonClicked()}
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className={`${container}-body`}>{data[props.type].text}</div>
          {data[props.type].link && (
            <div
              className={`${container}-refresh-btn`}
              onClick={() => props.onRefreshClick()}
              data-dismiss="modal"
            >
              <span>{data[props.type].link}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
