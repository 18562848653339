import React from 'react';
import PropTypes from 'prop-types';

const ArrowDropDown = ({ customStyle }) => {
  return (
    <svg
      className={customStyle}
      xmlns="http://www.w3.org/2000/svg"
      height="30px"
      viewBox="0 0 24 24"
      width="30px"
      fill="#000000"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M7 10l5 5 5-5z" />
    </svg>
  );
};

export default ArrowDropDown;

/* Props */
ArrowDropDown.propTypes = {
  /**
   * This change the header arrow's styling
   * It expects string that represent a css class
   * Example can be seen in restrictions.scss, .restrictions-body-arrow
   *
   * Side Note:
   * Change both height and width to control size
   * Change fill to change color
   */
  customStyle: PropTypes.string,
};
