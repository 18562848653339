// Libraries
import React from 'react';
import PropTypes from 'prop-types';

const FailedCrossSVG = ({ customClass }) => {
  return (
    <svg
      width="42.7px"
      height="42.7px"
      viewBox="0 0 1024 1024"
      className="icon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={customClass ? customClass : ' '}
        d="M557.248 608l135.744-135.744-45.248-45.248-135.68 135.744-135.808-135.68-45.248 45.184L466.752 608l-135.68 135.68 45.184 45.312L512 653.248l135.744 135.744 45.248-45.248L557.312 608zM704 192h160v736H160V192h160v64h384v-64zm-320 0V96h256v96H384z"
      />
    </svg>
  );
};

export default FailedCrossSVG;

/* Props */
FailedCrossSVG.propTypes = {
  customClass: PropTypes.string,
};
