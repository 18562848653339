import React, { useState, useMemo, useContext, useEffect } from 'react';

/* Style */
import './update.scss';
import '../../layout/grid.scss';

/* Library */
import { useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';

/* Context */
import { MobileContext } from '../../contexts/MobileContext';

/* Component */
import UpdateItem from '../../components/updates/UpdateItem';
import ExternalLinkModal from '../../components/modal/ExternalLinkModal';
import HistoricalDataLayout from '../../layout/content/HistoricalDataLayout';
import UpdateFilters from '../../components/pageComponents/archivedUpdates/UpdateFilters';
import GlobalSearchBar from '../../components/globalSearch/GlobalSearchBar';
import Pagination from '../../components/generalPagination/Pagination';

/* Constant */
const component = 'archived-updates';
const resultPerPage = 7;

export default function ArchivedUpdates() {
  const { pathname } = useLocation();
  const { isMobile } = useContext(MobileContext);
  const [externalSiteLink, setExternalSiteLink] = useState('');
  //selections
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [waterSources, setWaterSources] = useState([]);
  const [waterPlans, setWaterPlans] = useState([]);
  //pagination rendering
  const [updates, setUpdates] = useState([]);
  const [filteredUpdates, setFilteredUpdates] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [hasCallApi, setHasCallApi] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const currentUpdates = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * resultPerPage;
    const lastPageIndex = firstPageIndex + resultPerPage;
    if (isEmpty(filteredUpdates) && !isEmpty(updates)) {
      return updates.slice(firstPageIndex, lastPageIndex);
    }
    if (!isEmpty(filteredUpdates)) {
      return filteredUpdates.slice(firstPageIndex, lastPageIndex);
    }
  }, [currentPage, updates, filteredUpdates]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    //if has searchTerm in searchBar on archived update page
    if (openFilter || hasCallApi) {
      setCurrentPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredUpdates, hasCallApi]);

  const onExternalSiteClick = link => {
    setExternalSiteLink(link);
  };

  const noticeContent = () => {
    return (
      <>
        <div>
          {!isEmpty(updates) && !isMobile && (
            <GlobalSearchBar
              placeholder=""
              updates={updates}
              setFilteredUpdates={setFilteredUpdates}
              setOpenFilter={setOpenFilter}
              type="archived"
            />
          )}
          {!isEmpty(updates) && isMobile && (
            <div className={`${component}-result-title`}>Archived updates results</div>
          )}
          {!isEmpty(currentUpdates) &&
            currentUpdates.map((update, index) => (
              <UpdateItem
                key={index}
                page="archivedUpdates"
                onExternalSiteClick={onExternalSiteClick}
                type={update.type}
                title={update.title}
                date={update.date}
                expiryDate={update.expiry_date}
                content={update.body}
                link={update.url}
                linkTitle="View update"
                targetId=""
              />
            ))}

          <ExternalLinkModal link={externalSiteLink} modalId="externalLinkNSWUpdates" />
        </div>
        <div>
          {!isEmpty(updates) && (
            <Pagination
              className="archived"
              currentPage={currentPage}
              totalCount={isEmpty(filteredUpdates) ? updates.length : filteredUpdates.length}
              pageSize={resultPerPage}
              onPageChange={page => setCurrentPage(page)}
              openFilter={openFilter}
            />
          )}
        </div>
      </>
    );
  };

  const desktopGeneralNotice = () => {
    return <div className={`${component}-body`}>{noticeContent()}</div>;
  };

  return (
    <div className={component}>
      <HistoricalDataLayout
        left={
          <UpdateFilters
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            waterSources={waterSources}
            setWaterSources={setWaterSources}
            waterPlans={waterPlans}
            setWaterPlans={setWaterPlans}
            setUpdates={setUpdates}
            hasCallApi={hasCallApi}
            setHasCallApi={setHasCallApi}
          />
        }
        right={desktopGeneralNotice()}
      />
    </div>
  );
}
