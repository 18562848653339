// Libraries
import React from 'react';
import util from 'util';
import PropTypes from 'prop-types';

// Styles
import './MapPopup.scss';
import './flowClassPopup.scss';

// Helpers
import { toTitleCase } from '../../helpers/Utils';

// Constants
import constants from '../../constants/Constants';

export default function FlowClassPopup({ feature, rofCounter }) {
  const properties = feature.properties;

  const getFlowClassText = flowClass => {
    if (!flowClass) {
      return '-';
    }
    return toTitleCase(flowClass.replace(/_/g, ' '));
  };

  const getRestrictionText = (rof, s324) => {
    switch (rof + '|' + s324) {
      case 'Y|N':
        return 'Resumption of flow after prolonged drought';
      case 'N|Y':
        return 'Cease to pump order';
      case 'N|N':
        return 'None';
      default:
        break;
    }
  };

  const getRofCounter = value => {
    switch (true) {
      case value >= 90:
        return '90+';
      default:
        return value;
    }
  };

  const dataProps = [
    {
      title: 'Flow class',
      value: properties && getFlowClassText(properties.FLOW_CLASS),
    },
    {
      title: 'Additional restrictions',
      value:
        properties &&
        getRestrictionText(properties.RESTRICTED_BY_ROF, properties.RESTRICTED_BY_S324),
    },
    {
      title: 'Announcement',
      id: properties.ANNOUNCEMENT_ID,
    },
    ...(rofCounter > 0
      ? [
          {
            title: 'Days till first-flows protected',
            value: getRofCounter(rofCounter),
          },
        ]
      : []),
  ];

  const renderFlowClassValue = (id, value) => {
    if (id) {
      return (
        <a
          className="storage-intro-link"
          target="_blank"
          rel="noopener noreferrer"
          href={util.format(constants.WATER_SOURCE_ANNOUNCEMENT_ID, properties.WS_ID, id)}
        >
          Click Here
        </a>
      );
    } else {
      return value;
    }
  };

  const renderPopupTitle = mz => {
    if (!mz) {
      return '';
    }
    return toTitleCase(mz.replace('MANAGEMENT ZONE', ''));
  };

  return (
    <div className="order-usage-popup">
      <div className="map-popup-header flow-class">Management Zone</div>
      {feature && (
        <div className="map-popup-container">
          <div className="map-popup-body-title">{renderPopupTitle(properties.MANAGEMENT_ZONE)}</div>
          {dataProps.map((row, index) => (
            <div className="row station-data-popup-body flow-class-popup" key={index}>
              <div className="col-8 flow-class-title">{row.title}</div>
              <div className="col-4 flow-class-value">
                {renderFlowClassValue(row.id, row.value)}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

FlowClassPopup.propTypes = {
  feature: PropTypes.object,
  rofCounter: PropTypes.number,
};
