// Libraries
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

// Styles
import './logo.scss';

// Context
import { AppContext } from '../../contexts/AppContext';

// Assets
import LogoSvg from './LogoSvg';

export default function Logo(props) {
  const { setWaterSource } = useContext(AppContext);

  const onLogoClick = () => {
    localStorage.removeItem('curWaterSource');
    setWaterSource({});
  };

  return (
    <Link to="" onClick={() => onLogoClick()}>
      <div className="logo">
        <div className={props.className}>
          <LogoSvg />
        </div>
      </div>
    </Link>
  );
}
