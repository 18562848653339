// Library
import React from 'react';

// Component
import GeneralDropdown from '../dropDown/GeneralDropdown';

// Constants
export const dropdownItem = {
  gauge: [
    { id: '7 days', name: 'PAST 7 DAYS', dateFormat: 'DD MMM' },
    { id: '1 months', name: 'PAST MONTH', dateFormat: 'DD MMM' },
    { id: '6 months', name: 'PAST 6 MONTHS', dateFormat: 'DD-MMM-YY' },
    { id: '1 years', name: 'PAST YEAR', dateFormat: 'DD-MMM-YY' },
    { id: '5 years', name: 'PAST 5 YEARS', dateFormat: 'DD-MMM-YY' },
    { id: '10 years', name: 'PAST 10 YEARS', dateFormat: 'DD-MMM-YY' },
  ],
  weir: [
    { id: '7 days', name: 'PAST 7 DAYS', dateFormat: 'DD MMM' },
    { id: '1 months', name: 'PAST MONTH', dateFormat: 'DD MMM' },
    { id: '6 months', name: 'PAST 6 MONTHS', dateFormat: 'DD-MMM-YY' },
    { id: '1 years', name: 'PAST YEAR', dateFormat: 'DD-MMM-YY' },
    { id: '5 years', name: 'PAST 5 YEARS', dateFormat: 'DD-MMM-YY' },
    { id: '10 years', name: 'PAST 10 YEARS', dateFormat: 'DD-MMM-YY' },
  ],
  bore: [
    { id: '1 years', name: 'PAST YEAR', dateFormat: 'DD-MMM-YY' },
    { id: '2 years', name: 'PAST 2 YEARS', dateFormat: 'DD-MMM-YY' },
    { id: '3 years', name: 'PAST 3 YEARS', dateFormat: 'DD-MMM-YY' },
    { id: '5 years', name: 'PAST 5 YEARS', dateFormat: 'DD-MMM-YY' },
  ],
};

const RiverDataSummaryTimeDropdown = ({ timeSpan, onTimeSpanChange, stationType }) => {
  return (
    <GeneralDropdown
      menuItem={dropdownItem[stationType]}
      selectedItem={timeSpan}
      onItemClick={item => {
        onTimeSpanChange(item);
      }}
    />
  );
};

export default RiverDataSummaryTimeDropdown;
