// Libraries
import React, { useState, useContext, useLayoutEffect } from 'react';
import { ComposedChart, XAxis, YAxis, Tooltip, Bar, Legend, ReferenceLine } from 'recharts';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import moment from 'moment';

// Components
import ChartContainer from '../chartProperties/ChartContainer';
import ChartResponsiveContainer from '../chartProperties/ChartResponsiveContainer';
import SelectionBtnsGroup from '../../components/storage/childProperties/SelectionBtnsGroup';
import GeneralDropdown from '../dropDown/GeneralDropdown';
import ChartLegend from '../chartProperties/ChartLegend';

// Assets
import config from '../../abstract/IntroText.json';

//Context
import { MobileContext } from '../../contexts/MobileContext.jsx';

export default function ForecastGraph({ data }) {
  const { isMobile } = useContext(MobileContext);
  const toggleOptions = data.map(filter => {
    return {
      id: filter.category_name.toUpperCase(),
      name: filter.category_name,
    };
  });
  const [resourceActive, setResourceActive] = useState(toggleOptions[0]);
  const [graphData, setGraphData] = useState([]);
  const [actualAllocation, setActualAllocation] = useState();
  const [showToggle, setShowToggle] = useState(false);

  useLayoutEffect(() => {
    let dataGraphActive = [];
    setGraphData([]);
    if (!isEmpty(data)) {
      if (toggleOptions.length > 1) {
        dataGraphActive = data.filter(filter => filter.category_name === resourceActive.name)[0]
          .resources;
        setShowToggle(true);
      } else {
        dataGraphActive = data[0].resources;
      }
      setActualAllocation(dataGraphActive[0].allocation);
      dataGraphActive = dataGraphActive.filter(element => !element.allocation);
    }
    setGraphData(dataGraphActive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceActive, data]);

  const renderCustomTooltip = ({ payload }) => {
    if (payload && payload[0] && payload[0].payload) {
      return (
        <div className="ci-recent-chart-tooltip">
          <ul className="ci-recent-chart-tooltip-item-ul">
            {payload.map(
              (data, index) =>
                (data.name === 'Actual' || data.payload.allocation_forecast) && (
                  <li
                    key={index}
                    className="list-no-style-type custom-recharts-tooltip-item"
                    style={{ color: data.color }}
                  >
                    {`${data.name}: ${Math.round(data.value)} %`}
                  </li>
                ),
            )}
          </ul>
        </div>
      );
    } else {
      return null;
    }
  };

  const getVal = (obj, key) => {
    if (obj.allocation_forecast && obj.allocation_forecast[key] !== null) {
      return obj.allocation_forecast[key];
    }
  };

  const renderCusomizedLegend = e => {
    return <ChartLegend payload={e.payload} legendType="square" />;
  };
  const renderReferenceLineLabel = (v, text) => {
    const { viewBox } = v;
    let x;
    if (isMobile) {
      x = viewBox.width / 4;
    } else {
      x = viewBox.width / 2;
    }
    const y = viewBox.y + (actualAllocation > 80 ? +20 : -10);
    return (
      <text className="svg-reference-line-label" x={x} y={y}>
        {text}
      </text>
    );
  };

  const chartProps = [
    {
      name: 'Wet scenario',
      dataKey: 'wet',
      fillColor: '#005daf',
      showLine: true,
    },
    {
      name: 'Average scenario',
      dataKey: 'average',
      fillColor: '#2bcee0',
      showLine: true,
    },
    {
      name: 'Dry scenario',
      dataKey: 'dry',
      fillColor: '#c5d328',
      showLine: true,
    },
    {
      name: 'Extremely dry scenario',
      dataKey: 'extremely_dry',
      fillColor: '#b1c1c8',
      showLine: true,
    },
  ];

  return (
    <ChartContainer
      pageTitle={config.allocForecastGraph.title}
      graphTitle={config.allocForecastGraph.title}
      graphIntro={config.allocForecastGraph.intro}
    >
      {showToggle && (
        <SelectionBtnsGroup
          left={
            <GeneralDropdown
              menuItem={toggleOptions}
              onItemClick={e => setResourceActive(e)}
              selectedItem={toggleOptions.find(
                resourceItem => resourceItem.id === resourceActive.id,
              )}
            />
          }
        />
      )}
      <ChartResponsiveContainer
        key={resourceActive.name}
        customHeight={{ desktop: 400, mobile: 400 }}
        isEmptyChart={isEmpty(graphData)}
      >
        <ComposedChart
          key={resourceActive.name}
          data={graphData}
          margin={{ left: isMobile ? 0 : 35, bottom: isMobile ? 20 : 70 }}
        >
          <XAxis
            interval={'preserveStartEnd'}
            dataKey="date"
            dy={25}
            tick={{ fill: 'black' }}
            tickFormatter={v => moment(v).format('DD MMM YYYY')}
          />
          <YAxis
            type="number"
            label={{
              value: `${resourceActive.name} allocation (%)`,
              angle: -90,
              dx: isMobile ? -25 : -55,
            }}
            tickFormatter={v => v}
            domain={[0, Math.round(actualAllocation)]}
          />
          {chartProps.map((item, index) => (
            <Bar
              key={`${item.name} - ${index}`}
              name={item.name}
              barSize={40}
              dataKey={val => getVal(val, item.dataKey)}
              fill={item.fillColor}
              radius={[5, 5, 0, 0]}
              yAxisId={0}
            />
          ))}
          <ReferenceLine
            y={actualAllocation}
            label={v =>
              renderReferenceLineLabel(v, `Current allocation ${Math.round(actualAllocation)} %`)
            }
            stroke="red"
            isFront={true}
            strokeDasharray="10 10"
          />

          <Tooltip
            content={payload => renderCustomTooltip(payload)}
            cursor={{ fill: 'transparent' }}
          />
          <Legend wrapperStyle={{ bottom: isMobile ? 0 : 10 }} content={renderCusomizedLegend} />
        </ComposedChart>
      </ChartResponsiveContainer>
    </ChartContainer>
  );
}

ForecastGraph.propTypes = {
  data: PropTypes.arrayOf(Object),
  payload: PropTypes.array,
};
