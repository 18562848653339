// Library
import React from 'react';
import PropTypes from 'prop-types';

// Style
import './storageOverview.scss';

// Helper
import { formatNumberDecimals } from '../../../helpers/Utils';

// Component
import DataSummaryBar from '../../dataSummary/DataSummaryBar';
import StateOverviewTable from './StateOverviewTable';
const container = 'state-overview-trade';

const TradeOverview = ({ data }) => {
  // Constant
  const text = {
    total_allocation: {
      monthly: 'Allocation traded this month',
      yearly: 'Allocation traded year to date',
    },
    surface_water: 'Surface water',
    groundwater: 'Groundwater',
  };
  const colors = {
    total_allocation: 'black',
    surface_water: '#3e7a95',
    groundwater: '#f36c21',
  };
  const columns = [
    {
      type: 'rowHeader',
      text: 'rowHeader',
      hide: true,
    },
    {
      type: 'volume',
      text: 'Volume (GL)',
    },
    {
      type: 'value',
      text: 'Value traded ($)',
    },
  ];

  // data
  const monthlyData = data.monthly;
  const yearlyData = data.yearly;
  const yearlyRowData = { ...yearlyData };
  delete yearlyRowData.surface_water;
  delete yearlyRowData.groundwater;

  // Component
  const genRows = (data, type) => {
    return [
      Object.keys(data).map(key => {
        return (
          <span key={key} className={`${container}-header`} style={{ color: colors[key] }}>
            {text[key][type] ? text[key][type] : text[key]}
          </span>
        );
      }),
      Object.keys(data).map(key => {
        return !data[key].volume ? 0 : data[key].volume / 1000;
      }),
      Object.keys(data).map(key => {
        return !data[key].volume
          ? '$0'
          : '$' + formatNumberDecimals((data[key].price / 100) * data[key].volume);
      }),
    ];
  };

  const genBars = (data, type) => {
    return Object.keys(monthlyData).reduce((acc, key) => {
      if (key !== 'total_allocation') {
        acc.push({
          type: text[key][type] ? text[key][type] : text[key],
          value: data[key].volume,
          color: colors[key],
        });
      }
      return acc;
    }, []);
  };

  return (
    <div>
      <StateOverviewTable columns={columns} rows={genRows(monthlyData, 'monthly')} />
      <DataSummaryBar
        bars={genBars(monthlyData, 'monthly')}
        maximum={data.yearly.total_allocation.volume}
        noBorder
      />
      <StateOverviewTable columns={columns} rows={genRows(yearlyRowData, 'yearly')} />
      <DataSummaryBar
        bars={genBars(yearlyData, 'yearly')}
        maximum={data.yearly.total_allocation.volume}
        noBorder
      />
    </div>
  );
};

export default TradeOverview;

TradeOverview.propTypes = {
  data: PropTypes.shape({
    yearly: PropTypes.shape({
      total_allocation: PropTypes.shape({
        volume: PropTypes.number,
        price: PropTypes.number,
      }),
      surface_water: PropTypes.shape({
        volume: PropTypes.number,
        price: PropTypes.number,
      }),
      groundwater: PropTypes.shape({
        volume: PropTypes.number,
        price: PropTypes.number,
      }),
    }),
    monthly: PropTypes.shape({
      total_allocation: PropTypes.shape({
        volume: PropTypes.number,
        price: PropTypes.number,
      }),
      surface_water: PropTypes.shape({
        volume: PropTypes.number,
        price: PropTypes.number,
      }),
      groundwater: PropTypes.shape({
        volume: PropTypes.number,
        price: PropTypes.number,
      }),
    }),
  }),
};
