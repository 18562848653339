import React from 'react';
import PropTypes from 'prop-types';

const DownloadSVG = ({ customStyle }) => {
  return (
    <svg
      className={` ${customStyle ? customStyle : ''}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="#0054a6"
      width="30px"
      height="30px"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z" />
    </svg>
  );
};

export default DownloadSVG;

DownloadSVG.propTypes = {
  customStyle: PropTypes.string,
};
