// Library
import React from 'react';

// Styles
import './dataQualityMobileLegend.scss';

// Constant
// import { STATUS } from '../../../constants/DataQualityConstant';
import config from '../../../configs/featureToggleConfig.json';
const toShow = config['quality_code'].active;
const component = 'data-quality-mobile-legend';

const DataQualityMobileLegend = () => {
  return (
    toShow && (
      <div className={component}>
        <div>
          <div className={`${component}-title`}>
            <span>Data legend</span>
          </div>
          <div className={`${component}-status`}>
            {/* <div className={`${component}-status-item`}>
              <div className={`${component}-status-normal-icon`} />
              <span className={`${component}-status-normal-text`}>{STATUS.NORMAL.statusName}</span>
            </div> */}
            <div className={`${component}-status-item`}>
              <div className={`${component}-status-anomaly-icon`} />
              <span className={`${component}-status-anomaly-text`}>
                Requires further user validation
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default DataQualityMobileLegend;
