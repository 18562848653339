export default Object.freeze({
  STORAGE: {
    percentageLabel: 'Percentage full (%)',
    percentage: [
      { className: 'storage-perc-full-1', label: '80 - 100' },
      { className: 'storage-perc-full-2', label: '60 - 79' },
      { className: 'storage-perc-full-3', label: '40 - 59' },
      { className: 'storage-perc-full-4', label: '20 - 39' },
      { className: 'storage-perc-full-5', label: '0 - 19' },
    ],
    volumeLabel: 'Max. Capacity (GL)',
    volume: [
      { className: 'storage-volume-1', label: '0' },
      { className: 'storage-volume-2', label: '150' },
      { className: 'storage-volume-3', label: '500' },
      { className: 'storage-volume-4', label: '1,500' },
      { className: 'storage-volume-5', label: '4,000' },
    ],
    legend: [{ label: 'Current volume', className: 'storage-volume-dot' }],
  },
  AVAILABILITY: {
    percentageLabel: 'Available (% of allocated)',
    percentage: [
      {
        className: 'availability-ground-perc-full-1',
        className2: 'availability-surface-perc-full-1',
        label: '80 - 100',
      },
      {
        className: 'availability-ground-perc-full-2',
        className2: 'availability-surface-perc-full-2',
        label: '60 - 79',
      },
      {
        className: 'availability-ground-perc-full-3',
        className2: 'availability-surface-perc-full-3',
        label: '40 - 59',
      },
      {
        className: 'availability-ground-perc-full-4',
        className2: 'availability-surface-perc-full-4',
        label: '20 - 39',
      },
      {
        className: 'availability-ground-perc-full-5',
        className2: 'availability-surface-perc-full-5',
        label: '0 - 19',
      },
    ],
    volumeLabel: 'Allocated (GL)',
    volume: [
      { className: 'availability-volume-1', label: '0' },
      { className: 'availability-volume-2', label: '150' },
      { className: 'availability-volume-3', label: '500' },
      { className: 'availability-volume-4', label: '1,000' },
      { className: 'availability-volume-5', label: '2,500' },
    ],
    legend: [
      { label: 'Surface water', className: 'surfacewater-dot' },
      { label: 'Groundwater', className: 'groundwater-dot' },
    ],
  },
  TRADES: {
    percentageLabel: 'Avg. Price ($ / ML)',
    percentage: [
      {
        className: 'trades-ground-perc-full-1',
        className2: 'trades-surface-perc-full-1',
        label: '800 +',
      },
      {
        className: 'trades-ground-perc-full-2',
        className2: 'trades-surface-perc-full-2',
        label: '400 - 799',
      },
      {
        className: 'trades-ground-perc-full-3',
        className2: 'trades-surface-perc-full-3',
        label: '200 - 399',
      },
      {
        className: 'trades-ground-perc-full-4',
        className2: 'trades-surface-perc-full-4',
        label: '100 - 199',
      },
      {
        className: 'trades-ground-perc-full-5',
        className2: 'trades-surface-perc-full-5',
        label: '0 - 99',
      },
    ],
    volumeLabel: 'Volume traded (ML)',
    volume: [
      { className: 'trades-volume-1', label: '0' },
      { className: 'trades-volume-2', label: '50' },
      { className: 'trades-volume-3', label: '500' },
      { className: 'trades-volume-4', label: '5,000' },
      { className: 'trades-volume-5', label: '25,000' },
    ],
    legend: [
      { label: 'Surface water', className: 'surfacewater-dot' },
      { label: 'Groundwater', className: 'groundwater-dot' },
    ],
  },
});
