// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import './chartIcon.scss';

// Constant
const container = 'chart-icon';

export default function ChartIcon({ children, disabled }) {
  return <div className={`${container}${disabled ? '-disabled' : ''}`}>{children}</div>;
}

ChartIcon.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
};
