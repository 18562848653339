// Library
import React, { useState, useEffect, useContext } from 'react';
import { Map, TileLayer, ZoomControl, FeatureGroup, Marker, Circle } from 'react-leaflet';
import { isEmpty } from 'lodash';
import { EditControl } from 'react-leaflet-draw';
import PropTypes from 'prop-types';
import circleToPolygon from 'circle-to-polygon';
import * as esri from 'esri-leaflet';

// Styles
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import './groundWaterSitesMap.scss';

// Component
import Loader from '../../../loader/Loader';

// Context
import { MobileContext } from '../../../../contexts/MobileContext';

// Util
import {
  queryMapServiceIdentify,
  generateMarkerIcon,
} from '../../../../helpers/MapServiceApiHelper';

// Assets
import boreIcon from '../../../../image/icons/mapMarker/bore.png';
import locatorIcon from '../../../../image/icons/locator_filled.png';
import CancelSVG from '../../../../image/icons/CancelSVG';

// Constant
import Constants from '../../../../constants/Constants';
const MAP_INITIAL_POSITION = [-32.8, 147];
const MAP_OPACITY = 0.8;
export const SELECTION_LIMIT = 200;

const GroundWaterSitesMap = ({
  mapRef,
  clearShape,
  gwDataSelected,
  setGwDataSelected,
  layer,
  setLayer,
  markers,
  circle,
}) => {
  const { isMobile } = useContext(MobileContext);
  const [showSpinner, setShowSpinner] = useState(false);
  const [editableFG, setEditableFG] = useState(null);

  // Life cycle
  // After initializing the editable feature group (once the reference is set up)
  // we call the map service to retrieve the map with all bores as an image
  useEffect(() => {
    if (editableFG) {
      setShowSpinner(true);
      if (mapRef && mapRef.current && mapRef.current.leafletElement) {
        const map = mapRef.current.leafletElement;
        const layer = esri.dynamicMapLayer({
          url: Constants.TELEMETERED_BORES_MAP_SERVICE.slice(0, -2),
          layers: [Constants.TELEMETERED_BORES_MAP_SERVICE.split('/').at(-1)],
          attribution: 'Groundwater sites',
          opacity: MAP_OPACITY,
          useCors: false,
        });
        layer.on('load', () => {
          setShowSpinner(false);
        });
        layer.addTo(map);
      }
    }
  }, [editableFG, mapRef]);

  useEffect(() => {
    if (layer) {
      getGeometryData(layer.layer, layer.layerType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layer]);

  useEffect(() => {
    if (!isEmpty(circle)) {
      const map = mapRef?.current?.leafletElement;
      const minZoom = 6;
      const maxZoom = 10;
      const zoomLevel = Math.max(Math.min(800 / (circle.radius / 1000), maxZoom), minZoom);
      map && map.flyTo(circle.pos, zoomLevel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [circle]);

  // Helpers
  const onFeatureGroupReady = reactFGref => {
    // store the ref for future access to content
    setEditableFG(reactFGref);
  };

  const convertCircletoGeoJSON = layer => {
    const { lat, lng } = layer.getLatLng();
    let polygon = circleToPolygon([lng, lat], layer.getRadius(), 36);
    const geometryInput = {
      rings: polygon.coordinates,
    };

    return { geometryInput, geometryType: 'esriGeometryPolygon' };
  };

  const convertShapetoGeoJSON = (layerType, layer) => {
    let geometryInput, geometryType;
    switch (layerType) {
      case 'circle':
        ({ geometryInput, geometryType } = convertCircletoGeoJSON(layer));
        break;
      case 'polygon':
        geometryInput = { rings: layer.toGeoJSON().geometry.coordinates };
        geometryType = 'esriGeometryPolygon';
        break;
      case 'rectangle':
        geometryInput = {
          xmin: layer.getLatLngs()[0][0].lng,
          ymin: layer.getLatLngs()[0][0].lat,
          xmax: layer.getLatLngs()[0][3].lng,
          ymax: layer.getLatLngs()[0][1].lat,
        };
        geometryType = 'esriGeometryEnvelope';

        break;
      default:
        break;
    }

    return { geometryInput, geometryType };
  };

  const getGeometryData = async (layer, curLayerType) => {
    setShowSpinner(true);
    try {
      const { geometryInput, geometryType } = convertShapetoGeoJSON(curLayerType, layer);
      const identifyResult = await queryMapServiceIdentify(geometryInput, `bores`, geometryType);
      if (identifyResult?.data?.results) {
        const uniqueIds = new Set(identifyResult.data.results.map(item => item.value));
        setGwDataSelected(Array.from(uniqueIds));
      }
    } catch (e) {
      setGwDataSelected([]);
    } finally {
      setShowSpinner(false);
    }
  };

  const _onCreate = e => {
    setLayer({ layer: e.layer, layerType: e.layerType });
  };

  const _onEdited = e => {
    setLayer(prev => {
      return { ...prev, layer: e?.layers?.getLayers()[0] };
    });
  };

  const _onDeleted = e => {
    // Remove if there are layers deleted
    if (!isEmpty(e?.layers?.getLayers())) {
      setGwDataSelected([]);
      setLayer(null);
    }
  };

  // Component
  const SelectedGWText = () => {
    const genText = () => {
      switch (true) {
        case isEmpty(gwDataSelected):
          return <></>;
        case gwDataSelected.length === 0:
          return (
            <>
              <CancelSVG customClass={'download-map-container-msgSelection-icon'} />
              <p>
                There are<b> no sites </b> within your selection.
              </p>
            </>
          );
        case gwDataSelected.length > SELECTION_LIMIT:
          return (
            <>
              <CancelSVG customClass={'download-map-container-msgSelection-icon'} />
              <p>
                You have selected <b>more than {SELECTION_LIMIT} sites</b>. Please reduce the scope.
              </p>
            </>
          );
        default:
          return (
            <p>
              <b>{gwDataSelected.length}</b> sites selected.
            </p>
          );
      }
    };

    return <div className="download-map-container-msgSelection">{genText()}</div>;
  };

  const setMarkerIcon = (icon, size) => {
    const sizeConfig = size
      ? size
      : isMobile
      ? Constants.MAP_MARKER_SIZE_MOBILE
      : Constants.MAP_MARKER_SIZE;
    const markerIcon = generateMarkerIcon(icon, sizeConfig, '');

    return markerIcon;
  };

  return (
    <div className="download-map-container">
      <SelectedGWText />
      {showSpinner && <Loader />}
      <Map
        id="download-map"
        center={MAP_INITIAL_POSITION}
        zoom={6}
        className="download-map"
        zoomControl={false}
        useFlyTo={true}
        ref={mapRef}
        minZoom={5}
        attributionControl={false}
      >
        <FeatureGroup
          ref={featureGroupRef => {
            onFeatureGroupReady(featureGroupRef);
          }}
        >
          <EditControl
            position="topright"
            onCreated={_onCreate}
            onDrawStart={clearShape}
            onEdited={_onEdited}
            onDeleted={_onDeleted}
            draw={{
              circle: true,
              polyline: false,
              rectangle: true,
              polygon: true,
              circlemarker: false,
              marker: false,
            }}
          />
        </FeatureGroup>
        <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}" />
        <ZoomControl position="bottomright" />
        {markers.map(position => (
          <Marker key={position} position={position} icon={setMarkerIcon(boreIcon)}></Marker>
        ))}
        {!isEmpty(circle) && (
          <Circle center={circle.pos} radius={circle.radius}>
            <Marker
              position={circle.pos}
              icon={setMarkerIcon(locatorIcon, { iconSize: [26, 30], iconAnchor: [13, 15] })}
            />
          </Circle>
        )}
      </Map>
    </div>
  );
};

export default GroundWaterSitesMap;

GroundWaterSitesMap.propTypes = {
  mapRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.object })]),
  clearShape: PropTypes.func,
  setGwDataSelected: PropTypes.func,
  gwDataSelected: PropTypes.arrayOf(PropTypes.string),
};
