// Library
import React, { useState, useEffect, useContext } from 'react';
import { isEmpty, toString } from 'lodash';

// Styles
import './tradingBody.scss';
// Context
import { AppContext } from '../../../contexts/AppContext';
import { MobileContext } from '../../../contexts/MobileContext';

// Component
import TradingMarket from './TradingMarket';
import InterValleyTrade from './InterValleyTrade';
import ReportTable from '../../../components/reportTable/ReportTable';
import InterValleyTransfer from './InterValleyTransfer';
import TradingLimitVolume from './TradingLimitVolume';
import InterValleyTradeApplications from './InterValleyTradeApplications';
import { FeatureToggle, On } from '../../featureToggle/FeatureToggle';

// Assest
import text from './tradingText.json';

// Helper
import {
  getTradeDataById,
  getAllocDataWaterSource,
  getAllInterValleyTrade,
  getIVTAppQueueFromWaterSource,
} from '../../../helpers/ApiHelper';
import { currentFinancialYear } from '../../../helpers/TimeUtils.jsx';
import { replaceWaterSourceLiteral } from '../../../helpers/Utils';

// Constant
import constant from '../../../constants/Constants';
const { MURRUMBIDGEE_REGULATED_RIVER_WATER_SOURCE } = constant;
const container = 'trading-body';
const PERMITTEDIVT = {
  11904: [12961, 11982, 12962],
  11982: [11904, 12961, 12962],
  11986: [12105],
  12105: [11986],
  12961: [11904, 11982],
  12962: [11904, 11982],
  12963: [14681],
  14681: [12963],
};

const TradingBody = () => {
  const { waterSource } = useContext(AppContext);
  const { isLarge } = useContext(MobileContext);
  const { allocation, entitlement } = text;
  const [shareData, setShareData] = useState();
  const [fetchedShare, setFetchedShare] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [allocationData, setAllocationData] = useState([]);
  const [entitlementData, setEntitlementData] = useState([]);
  const [interValleyTradeData, setInterValleyTradeData] = useState([]);
  const [interValleyTradeAppQueueData, setInterValleyTradeAppQueueData] = useState([]);
  const [isInterValley, setIsInterValley] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableHeaderLabel, setTableHeaderLabel] = useState([]);
  const colors = [
    '#004486',
    '#1c7f28',
    '#328cff',
    '#33a594',
    '#b50000',
    '#7b34ff',
    '#f44fda',
    '#ff5c00',
  ];

  // Helper
  const loadTradeData = async () => {
    setShowSpinner(true);
    const data = await getTradeDataById(waterSource.water_source_id);
    const tempAllocation = [];
    const tempEntitlement = [];
    if (!isEmpty(data)) {
      data.forEach(category => {
        if (category.market_type.toLowerCase() === 'allocation') {
          tempAllocation.push(category);
        } else {
          tempEntitlement.push(category);
        }
      });
    }

    if (!isEmpty(shareData)) {
      const categories = shareData[0].resources;
      categories.forEach(category => {
        const target = tempEntitlement.find(
          trade => trade.category_type.toLowerCase() === category.category_name.toLowerCase(),
        );
        if (target) {
          target.available_shares = category.number_shares;
          target.category_shortname = category.category_shortname;
        }
      });
    }

    setAllocationData(tempAllocation);
    setEntitlementData(tempEntitlement);
    setShowSpinner(false);
  };

  const formatTableData = data => {
    const permittedTradingGroup = PERMITTEDIVT[waterSource.water_source_id]
      ? [waterSource.water_source_id, ...PERMITTEDIVT[waterSource.water_source_id]]
      : [waterSource.water_source_id];
    const tradingWaterSource = [];

    data.forEach(item => {
      const isInGroup = permittedTradingGroup.findIndex(source => source === item.water_source_id);
      if (isInGroup !== -1) {
        tradingWaterSource.push({
          text: replaceWaterSourceLiteral(item.water_source_name),
          id: item.water_source_id,
        });
      }
    });
    const formattedData = [];
    data.forEach(({ water_source_id, water_source_name, resources }, index) => {
      const isInGroup = permittedTradingGroup.findIndex(source => source === water_source_id);
      if (isInGroup !== -1) {
        resources = tradingWaterSource.map(item => {
          const matched = resources.find(dest => dest.dest_water_source_id === item.id);
          const permitted =
            PERMITTEDIVT[water_source_id] &&
            PERMITTEDIVT[water_source_id].findIndex(permittedWS => permittedWS === item.id);
          return {
            id: item.id,
            value: permitted !== -1 ? (matched ? matched.export_volume : 0) : '-',
          };
        });
        formattedData.push({
          water_source_id,
          water_source_name: replaceWaterSourceLiteral(water_source_name),
          color: colors[index],
          resources: resources,
        });
      }
    });
    setTableData(formattedData);
    tradingWaterSource.unshift({
      text: 'From/To Water Source (X/Y)',
      id: null,
    });
    setTableHeaderLabel(tradingWaterSource);
  };

  const sortList = list => {
    if (!isEmpty(list) && !isEmpty(interValleyTradeData)) {
      const sorted = interValleyTradeData.sort(function (a, b) {
        return (
          list.indexOf(replaceWaterSourceLiteral(a.water_source_name)) -
          list.indexOf(replaceWaterSourceLiteral(b.water_source_name))
        );
      });
      formatTableData(sorted);
    }
  };

  const filterInvalidIVT = data => {
    const permitted = Object.keys(PERMITTEDIVT);
    let filteredData = data.filter(item => {
      const found = permitted.findIndex(ws => Number(ws) === item.water_source_id);
      if (found === -1) {
        return false;
      }
      return true;
    });
    filteredData.map(dest => {
      dest.resources.forEach(function (item, index, object) {
        if (!permitted.includes(toString(item.dest_water_source_id))) {
          object.splice(index, 1);
        }
      });
    });
    return filteredData;
  };

  // Life Cycle
  useEffect(() => {
    if (fetchedShare) {
      loadTradeData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterSource, shareData]);

  useEffect(() => {
    getAllocDataWaterSource(waterSource.water_source_id, setShareData, currentFinancialYear());
    setFetchedShare(true);

    return () => {
      setFetchedShare(false);
      setShareData(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let unmounted = false;
    setInterValleyTradeData([]);
    setInterValleyTradeData([]);
    (async () => {
      getIVTAppQueueFromWaterSource(waterSource, setInterValleyTradeAppQueueData);
      const data = await getAllInterValleyTrade();
      if (!unmounted) {
        setInterValleyTradeData(filterInvalidIVT(data));
      }
    })();
    return () => {
      unmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterSource, isLarge]);
  return (
    <div className={container}>
      {waterSource.water_source_id === MURRUMBIDGEE_REGULATED_RIVER_WATER_SOURCE && (
        <FeatureToggle feature="ivt">
          <On>
            <InterValleyTransfer />
          </On>
        </FeatureToggle>
      )}
      <FeatureToggle feature="trading-limit-volume">
        <On>
          <TradingLimitVolume />
        </On>
      </FeatureToggle>
      {waterSource.water_source_id === MURRUMBIDGEE_REGULATED_RIVER_WATER_SOURCE && (
        <FeatureToggle feature="ivt-application-queue">
          <On>
            <InterValleyTradeApplications data={interValleyTradeAppQueueData} />
          </On>
        </FeatureToggle>
      )}
      <TradingMarket
        title={allocation.title}
        intro={allocation.intro}
        data={allocationData}
        text={allocation}
        showSpinner={showSpinner}
        type="allocation"
      />
      <TradingMarket
        title={entitlement.title}
        intro={entitlement.intro}
        data={entitlementData}
        text={entitlement}
        showSpinner={showSpinner}
        type="entitlement"
      />
      {!isEmpty(interValleyTradeData) && !isLarge && (
        <>
          <InterValleyTrade
            data={interValleyTradeData}
            colors={colors}
            setIsInterValley={setIsInterValley}
            setSortedNameList={sortList}
          />
          {isInterValley && (
            <>
              <ReportTable
                data={tableData}
                headerLabel={tableHeaderLabel}
                xAxisHeader="To water source (ML)"
                yAxisHeader="From water source (ML)"
                rowNumber={tableData.length + 1}
                page={false}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default TradingBody;
