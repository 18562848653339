// Library
import axios from 'axios';
import { isEmpty } from 'lodash';
import util from 'util';

// Helpers
import azureB2CHelper from '../helpers/AzureB2CHelper';

// Constants
import constants from '../constants/Constants';

const apiBaseURL = constants.BASE_URL;
const endpoint = '/api/services/subscriptions';
const signal = axios.CancelToken.source();

/**
 * Returns the token
 */
export const getSubscriptionsByUserID = async (userId, alertType, setAlerts, stationIds = null) => {
  const stationIdParam =
    stationIds && alertType !== 'station' ? { wsId: stationIds } : stationIds && { stationIds };
  const token = await azureB2CHelper.getToken();
  const res = await axios.get(apiBaseURL + endpoint, {
    params: { userId, alertType, ...(stationIdParam && stationIdParam) },
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    cancelToken: signal.token,
  });
  const result = res?.data?.alerts || res?.data;
  if (result) {
    setAlerts(!isEmpty(result) && typeof result !== 'undefined' ? result : []);
  } else {
    setAlerts([]);
  }
};

/**
 * make the post call
 */
export const postSubscription = async data => {
  try {
    const token = await azureB2CHelper.getToken();
    const res = await axios({
      method: 'post',
      url: `${apiBaseURL}${endpoint}`,
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': data.length,
        Authorization: 'Bearer ' + token,
      },
      data,
    });
    if (res.data && res.data.success) {
      return true;
    } else {
      throw new Error(res.data ? res.data.message : 'API Error');
    }
  } catch (error) {
    return false;
  }
};

/**
 * make the post call to remove an alert
 */
export const removeAlert = async (alertId, userId) => {
  try {
    const token = await azureB2CHelper.getToken();
    const res = await axios({
      method: 'post',
      url: util.format(`${apiBaseURL}/api/services/alerts/%s/remove`, alertId),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
      },
      data: { alertId, userId },
    });
    if (res.data && res.data.success) {
      return true;
    } else {
      throw new Error(res.data ? res.data.message : 'API Error');
    }
  } catch (error) {
    return false;
  }
};

/**
 * make the post call to remove an alert
 */
export const editAlert = async data => {
  try {
    const token = await azureB2CHelper.getToken();
    const res = await axios({
      method: 'post',
      url: util.format(`${apiBaseURL}/api/services/alerts/%s/edit`, data.alertId),
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': data.length,
        Authorization: 'Bearer ' + token,
      },
      data,
    });

    if (res.data && res.data.success) {
      return true;
    } else {
      throw new Error(res.data ? res.data.message : 'API Error');
    }
  } catch (error) {
    return false;
  }
};
