// Library
import React, { useContext } from 'react';
import { debounce, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

// Style
import './stationSelectInput.scss';

// Context
import { DownloadContext } from '../../../../../../contexts/DownloadContext';

// Component
import { Chip } from '../../../../../generalComponents/chip/Chip';

// Constant
import constants from '../../DataDownloadConstants';
const component = 'station-select-input';

const StationSelectInput = ({ inputRef, setSearchKeyword }) => {
  const { selectedStations, setSelectedStations, setStationInfoType } = useContext(DownloadContext);
  const placeholder = !isEmpty(selectedStations) ? 'Type another site to compare' : 'Type a site';

  const debounced = debounce(v => {
    setSearchKeyword(v);
  }, 0);

  return (
    <span className={`${component}`}>
      <input
        ref={inputRef}
        className={`${component}-input`}
        placeholder={placeholder}
        autoComplete="off"
        onChange={e => {
          debounced(e.target.value);
        }}
      />
      {!isEmpty(selectedStations) && (
        <div className={`${component}-chip-container`}>
          {selectedStations.map(station => (
            <Chip
              key={station.name}
              customStyles={`${component}-chip-item`}
              displayName={station.name}
              onDelete={(id, e) => {
                e.preventDefault(); // for passing a flag to avoid being considered as outside click after deletion
                setSelectedStations(prev => {
                  const filtered = prev.filter(item => item.name !== id);
                  if (filtered.length < 1) {
                    setStationInfoType('');
                  }
                  return filtered;
                });
              }}
              isDeletable
            />
          ))}
        </div>
      )}
    </span>
  );
};

export default StationSelectInput;

StationSelectInput.propTypes = {
  /** A ref object to the input element used in this component */
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  /** A function to set the search keyword to be used in searching for stations */
  setSearchKeyword: PropTypes.func.isRequired,
};
