import React from 'react';
import { ScaleLoader } from 'react-spinners';
import './loader.scss';

export default function Loader(props) {
  return (
    <React.Fragment>
      <div className={props.staticPosition ? 'spinner-wrapper-static' : 'spinner-wrapper'}>
        <div className="map-spinner-item">
          <ScaleLoader
            color="#0054a6"
            width={`${props.width ? props.width : '10px'}`}
            height={`${props.height ? props.height : '40px'}`}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
