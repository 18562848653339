// Libraries
import React, { useState, useEffect } from 'react';
import moment from 'moment';

// Styles
import './maintenanceBanner.scss';

// Helpers
import { getWebsiteStatus } from '../../../helpers/ServicesApiHelper';
import { createMarkup } from '../../../helpers/Utils';

const container = 'maintainance-banner';
const dateFormat = 'YYYY-MM-DDTHH:mm:ss.sssZ';

const MaintenanceBanner = () => {
  const [bannerMessage, setBannerMessage] = useState();

  useEffect(() => {
    checkSiteAvailability().then(result => {
      setBannerMessage(result);
    });
  }, []);

  const checkSiteAvailability = async () => {
    let message;
    const status = await getWebsiteStatus();
    if (status.from_datetime) {
      const from = moment(status.from_datetime, dateFormat);
      const to = moment(status.to_datetime, dateFormat);
      if (moment().isBetween(from, to)) {
        message = status.message;
      }
    }
    return message;
  };

  function handleClose() {
    setBannerMessage(false);
  }

  return bannerMessage ? (
    <div className={`${container}-container`}>
      <div
        className={`${container}-message-text`}
        dangerouslySetInnerHTML={createMarkup(bannerMessage)}
      />
      <div>
        <button onClick={handleClose} className={`${container}-close-button`}>
          &#10005;
        </button>
      </div>
    </div>
  ) : null;
};

export default MaintenanceBanner;
