// Library
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// Style
import './riverSchematicLegend.scss';

// Component
import DataQualityLegend from '../generalComponents/dataQuality/DataQualityLegend';

// Assests
import gaugeIcon from '../../image/icons/mapMarker/gauge.svg';
import damIcon from '../../image/icons/dam.svg';
import weirIcon from '../../image/icons/mapMarker/weir.svg';
import dischargeIcon from '../../image/icons/mapMarker/discharge-site.png';

// Context
import { MapContext } from '../../contexts/MapContext';
import { AppContext } from '../../contexts/AppContext';

// Constant
const ITEMPADDING = 30;
const INLINESPACE = 35;

const component = 'river-schematic-legend';

const RiverSchematicLegend = ({ x, y, dataLegendX, dataLegendY }) => {
  const { mapExpanded } = useContext(MapContext);
  const { waterSource } = useContext(AppContext);

  // Constant
  const height = 330;
  const width = 260;
  const LEGENDITEMS = [
    {
      text: 'River gauge',
      color: '#4b555a',
      type: 'rect',
      icon: gaugeIcon,
      adjustment: {
        x: 1,
        y: 0,
      },
    },
    ...(waterSource.water_source_id === 12801
      ? [
          {
            text: 'Discharge site',
            color: '#3680A0',
            type: 'rect',
            icon: dischargeIcon,
            adjustment: {
              x: 0,
              y: 0,
            },
          },
        ]
      : []),
    {
      text: 'Dam',
      color: '#0054a6',
      type: 'rect',
      icon: damIcon,
      adjustment: {
        x: -6,
        y: -7,
      },
    },
    {
      text: 'Storage weir',
      color: '#0054a6',
      type: 'rect',
      icon: weirIcon,
      adjustment: {
        x: -2,
        y: 0,
      },
    },
    {
      text: 'Irrigation scheme',
      color: '#d1ddeb',
      type: 'rect',
      adjustment: {
        x: 1,
        y: 0,
      },
    },
    {
      text: 'Wetland',
      color: '#ecf1c6',
      type: 'rect',
      adjustment: {
        x: 1,
        y: 0,
      },
    },
    {
      text: 'River',
      color: '#63CBE8',
      type: 'line',
      adjustment: {
        x: 5,
        y: 0,
      },
    },
    {
      text: 'Unregulated',
      color: '#4472C4',
      type: 'line',
      adjustment: {
        x: 5,
        y: 0,
      },
    },
  ];

  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${width} ${height}`}
        width={width}
        height={height}
        x={x}
        y={y}
      >
        <rect fill="white" width={width} height={height} rx="10" />
        {LEGENDITEMS.map((item, index) => (
          <React.Fragment key={index}>
            {item.type === 'rect' ? (
              <rect
                x={ITEMPADDING}
                y={ITEMPADDING + index * INLINESPACE}
                fill={item.color}
                width="28"
                height="28"
                rx="10"
              />
            ) : (
              <line
                x1={ITEMPADDING}
                y1={45 + index * INLINESPACE}
                x2={60}
                y2={45 + index * INLINESPACE}
                strokeDasharray="0"
                strokeLinecap="round"
                stroke={item.color}
                strokeWidth="8"
              />
            )}
            {item.icon && (
              <image
                x={ITEMPADDING + item.adjustment.x}
                y={ITEMPADDING + item.adjustment.y + index * INLINESPACE}
                href={item.icon}
              />
            )}
            <text fill="#4b555a" x={90} y={50 + index * INLINESPACE}>
              {item.text}
            </text>
          </React.Fragment>
        ))}
      </svg>
      {!mapExpanded && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 ${225} ${300}`}
          width={225}
          height={200}
          x={dataLegendX}
          y={dataLegendY}
        >
          <foreignObject
            x={-25}
            y={0}
            width={360}
            height={200}
            className={`${component}-data-quality`}
          >
            <DataQualityLegend />
          </foreignObject>
        </svg>
      )}
    </>
  );
};

export default RiverSchematicLegend;

RiverSchematicLegend.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  name: PropTypes.string,
  value: PropTypes.number,
  unit: PropTypes.string,
  locationType: PropTypes.string,
  url: PropTypes.string,
  background: PropTypes.string,
};
