// Libraries
import React, { useState, useContext, useEffect } from 'react';
import { debounce, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

// Styles
import './locator.scss';

// Context
import { AppContext } from '../../contexts/AppContext';

// Assets
import locationIcon from '../../image/icons/locator_filled.png';
import locatorIcon from '../../image/icons/locate.png';
import constants from '../../constants/Constants';

// Helpers
import { geocodeQueryApi, doubleQueryMapServiceIdentify } from '../../helpers/MapServiceApiHelper';
import { Event } from '../../helpers/GAHelper';

export default function Locator(props) {
  const { waterSource } = useContext(AppContext);
  const [itemList, setItemList] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    setIsMenuOpen(false);
    if (isEmpty(waterSource)) {
      clearInput();
      setItemList([]);
      props.locationGeometry([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterSource]);

  const debouncedQuery = debounce(e => {
    suggestQuery(e);
  }, 500);

  const onInputChange = event => {
    debouncedQuery(event);
  };

  const suggestQuery = async params => {
    const result = await geocodeQueryApi(
      { text: params, countryCode: 'au', maxSuggestions: 3, category: 'City,Address' },
      constants.GEOCODE_ARCGIS_SUGGEST_URL,
    );
    setIsMenuOpen(true);
    if (result) setItemList(result.suggestions);
  };

  const clearInput = () => {
    document.getElementById('locator-input').value = '';
    setItemList([]);
  };

  const onLocationSelect = async e => {
    const locationName = e.text.split(',')[0];
    document.getElementById('locator-input').value = locationName;
    setItemList([]);

    const result = await geocodeQueryApi(
      { singleLine: e.text, magicKey: e.magicKey },
      constants.GEOCODE_ARCGIS_FIND_ADR_URL,
    );
    const geometry = !isEmpty(result.candidates) && result.candidates[0].location;
    if (!isEmpty(geometry)) identifyMapServiceQuery(geometry);

    Event(
      waterSource.water_source_name,
      `Location Search Selected: ${e.text}`,
      constants.INTERACTION_TYPE.location,
    );
  };

  const identifyMapServiceQuery = async geometry => {
    props.setShowSpinner(true);
    const identifyResult = await doubleQueryMapServiceIdentify(geometry);
    if (identifyResult) {
      props.dropdownList(identifyResult.results);
    }
    props.locationGeometry(geometry);
    props.setShowSpinner(false);
  };

  const onLocatorClick = () => {
    props.onLocatorClick(true);
    setIsMenuOpen(false);
  };

  const onInputFocus = () => {
    setIsMenuOpen(true);
  };

  const onInputBlur = () => {
    setTimeout(function () {
      setIsMenuOpen(false);
    }, 200);
  };

  return (
    <div className="locator-container">
      <input
        ref={props.inputRef}
        id="locator-input"
        className="locator-input"
        placeholder="Find location"
        autoComplete="off"
        onChange={event => event.target.value && onInputChange(event.target.value)}
        onFocus={() => onInputFocus()}
        onBlur={() => onInputBlur()}
      />
      <button className="clear-btn btn bg-transparent" onClick={clearInput}>
        Clear
      </button>
      {isMenuOpen && (
        <div className="locator-input-menu">
          {!isEmpty(itemList) && (
            <div className="locator-item-list">
              {itemList.map((location, index) => (
                <div
                  key={index}
                  className="locator-item-wrapper"
                  onClick={() => onLocationSelect(location)}
                >
                  <img src={locationIcon} className="locator-item-icon" alt="Locator item icon" />
                  <span className="locator-item-label">{location.text}</span>
                </div>
              ))}
            </div>
          )}
          <div className="locator-item-list">
            <div
              id="find-locator-item"
              className="locator-item-wrapper"
              onClick={() => onLocatorClick()}
            >
              <img src={locatorIcon} className="locator-icon" alt="Locator icon" />
              <span className="locator-item-label">Find your location</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

Locator.propTypes = {
  dropdownList: PropTypes.func,
  locationGeometry: PropTypes.func,
  onLocatorClick: PropTypes.func,
};
