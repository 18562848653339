// Library
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Style
import './feedbackForm.scss';

// Context
import { MobileContext } from '../../contexts/MobileContext';

// Component
import FeedbackFormItem from './FeedbackFormItem';
import FeedbackResponse from './FeedbackResponse';

// Assest
import CancelSVG from '../../image/icons/CancelSVG';

// Helper
import { emailValidation } from './FeedbackValidation';
import { handleSubmit } from './FeedbackSubmit';

// Constant
const container = 'feedback-form';
export const FEEDBACK_TYPES = [
  { type: 1, name: 'General enquiry' },
  { type: 2, name: 'Report a data issue' },
  { type: 3, name: 'Raise a data request' },
];

const FeedbackForm = ({ show, setShow }) => {
  // Context
  const { isMobile } = useContext(MobileContext);

  // State
  const [nameField, setName] = useState({
    value: '',
    error: false,
    errorMsg: '',
  });
  const [emailField, setEmail] = useState({
    value: '',
    error: false,
    errorMsg: '',
  });
  const [feedbackTypeField, setFeedbackType] = useState({
    value: FEEDBACK_TYPES[0],
    error: false,
    errorMsg: '',
  });
  const [messageField, setMessage] = useState({
    value: '',
    error: false,
    errorMsg: '',
  });
  const [honeypot, setHoneypot] = useState('');
  const [showResponseMsg, setShowResponseMsg] = useState(false);
  const [responseType, setResponseType] = useState('fail');

  // Form
  const formStructure = [
    {
      label: 'Name',
      key: 'name',
      type: 'text',
      field: nameField,
      setField: setName,
      error: nameField.error,
      validate: true,
    },
    {
      label: 'Email',
      key: 'email',
      type: 'email',
      field: emailField,
      setField: setEmail,
      error: emailField.error,
      validation: emailValidation,
      validate: true,
    },
    {
      label: 'Feedback type',
      key: 'feedbackType',
      type: 'dropdown',
      field: feedbackTypeField,
      setField: setFeedbackType,
      error: feedbackTypeField.error,
      validate: false,
    },
    {
      label: 'Message',
      key: 'message',
      type: 'textarea',
      field: messageField,
      setField: setMessage,
      error: messageField.error,
      validate: true,
    },
  ];

  // Lifecycle
  useEffect(() => {
    if (!show) {
      setShowResponseMsg(false);
    }
    return () => {
      setShowResponseMsg(false);
    };
  }, [show]);

  // Component
  const formComponent = () => {
    return (
      <form className={`${container} ${show && `${container}-show`}`} noValidate>
        <FeedbackResponse
          show={showResponseMsg}
          setShow={setShowResponseMsg}
          responseType={responseType}
        />
        {isMobile && (
          <CancelSVG
            customClass={`${container}-cancel`}
            onBtnClick={() => {
              setShow(false);
            }}
          />
        )}
        <div className={`${container}-header`}> Feedback </div>
        <div className={`${container}-subHeader`}>We appreciate any feedback for WaterInsights</div>
        {formStructure.map(formItem => {
          return (
            <FeedbackFormItem
              key={formItem.label}
              label={formItem.label}
              type={formItem.type}
              field={formItem.field}
              setField={formItem.setField}
            />
          );
        })}
        <input
          type="text"
          className={`${container}-honeypot`}
          value={honeypot}
          onChange={e => {
            setHoneypot(e.target.value);
          }}
        />
        <input
          type="submit"
          className={`${container}-submit`}
          value={'Submit Feedback'}
          onClick={e => {
            handleSubmit(
              e,
              formStructure,
              { value: honeypot, setValue: setHoneypot },
              setShowResponseMsg,
              setResponseType,
            );
          }}
        />
      </form>
    );
  };

  const mobileForm = () => {
    return (
      <>
        {show && (
          <div className={`${container}-modal`}>
            <div
              className={`${container}-modal-background`}
              onClick={() => {
                setShow(false);
              }}
            />
            {formComponent()}
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {isMobile && mobileForm()}
      {!isMobile && formComponent()}
    </>
  );
};

export default FeedbackForm;

FeedbackForm.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
};
