// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

// Styles
import './dataQualityText.scss';

// Constant
import DataQualityConstant, { STATUS } from '../../../constants/DataQualityConstant';
import config from '../../../configs/featureToggleConfig.json';
const toShow = config['quality_code'].active;
const component = 'data-quality-text';

const DataQualityText = ({ children, qualityCode, retainOriginal, isMarked = true }) => {
  const { NORMAL, ANOMALY, SUSPECT } = STATUS;
  const qualityObj = DataQualityConstant[qualityCode];

  const genContent = () => {
    switch (true) {
      case qualityObj?.statusName === NORMAL.statusName:
        return <>{children}</>;
      case qualityObj?.statusName === ANOMALY.statusName:
        return (
          <span className={`${component}-status-anomaly`}>
            {children}
            {isMarked && '*'}
          </span>
        );
      case qualityObj?.statusName === SUSPECT.statusName:
        return (
          <span className={`${component}-status-suspect`}>{retainOriginal ? children : '-'}</span>
        );
      default:
        return <>{children}</>;
    }
  };

  return <>{!isEmpty(qualityObj) && toShow ? genContent() : children}</>;
};

export default DataQualityText;

DataQualityText.propTypes = {
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  qualityCode: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  retainOriginal: PropTypes.bool,
  isMarked: PropTypes.bool,
};
